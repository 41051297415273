import {useNavigation, useRoute} from '@react-navigation/native';
import {Auth} from 'aws-amplify';
import React, {useEffect} from 'react';
import Countdown, {CountdownApi} from 'react-countdown';
import {useFormContext} from 'react-hook-form';
import {Linking, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {
  EmailFormData,
  MFAFormData,
  schemaEmailFormData,
  schemaMFAFormData,
} from '../../components/Auth/schema';
import {
  Container,
  FormBlock,
  Header,
  HelperText,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../../components/Elements';
import {AuthContainer} from '../../container';
import {UserInfoContainer} from '../../container/UserInfoContainer';
import {ERROR_MSG, useCustomNav} from '../../helper';
import {AccountMenu} from '../../navigation/AccountMenu';
import {Colors, CommonStyles} from '../../theme';
import {AccessManageContainer} from '../Settings/AccessManageContainer';
import {AccountContainer} from './AccountContainer';

const LABEL_LOADING = '確認コードのチェック中';

const EmailSubmit: React.FC = () => {
  const container = AccountContainer;
  // TODO: stripe側の変更
  // TODO: 未検証で更新されるので、その対応
  // https://zenn.dev/dove/articles/78ecf08b51ee0c
  const {changeEmail, step, error} = container.useContainer();
  const {userInfo, update} = UserInfoContainer.useContainer();
  const {updateEmail} = AccessManageContainer.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<EmailFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const helperText = errors.email?.message
    ? ERROR_MSG.common.form
    : error
    ? ERROR_MSG.common.dump(error)
    : '';
  const isLoading = step === 'changeEmail';
  const disabled = isLoading || !watch('email') || !!errors.email;

  return (
    <View>
      <TrimaLoadingButton
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(async (data) => {
          const oldEmail = await changeEmail(data);
          await update({id: userInfo?.id, ...data});
          await updateEmail({email: data.email, oldEmail});
        }, onSubmitError)}
        loading={isLoading}
        loadingIndicator={'更新中'}
      >
        メールアドレスを変更
      </TrimaLoadingButton>
      <HelperText type="error">{helperText}</HelperText>
    </View>
  );
};

const VerifySubmit: React.FC = () => {
  const navigation = useNavigation();
  const {checkAuthUser} = AuthContainer.useContainer();
  const {challengeMFA, step, error} = AccountContainer.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<MFAFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const helperText = errors.verificationCode?.message
    ? ERROR_MSG.common.form
    : error
    ? ERROR_MSG.common.dump(error)
    : '';
  const isLoading = step === 'challenging';
  const disabled =
    isLoading || !watch('verificationCode') || !!errors.verificationCode;

  return (
    <View>
      <TrimaLoadingButton
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(async (data) => {
          challengeMFA(data, navigation);
          await checkAuthUser();
        }, onSubmitError)}
        loading={isLoading}
        loadingIndicator={LABEL_LOADING}
      >
        送信
      </TrimaLoadingButton>
      <HelperText type="error">{helperText}</HelperText>
    </View>
  );
};

const EmailChangeForm: React.FC = () => {
  const navigator = useCustomNav();
  const goBack = () => {
    navigator.goBack('AccountMain');
  };

  return (
    <View>
      <VForm.Provider<EmailFormData> schema={schemaEmailFormData}>
        <VForm.Text<EmailFormData>
          name="email"
          label="新しいメールアドレス"
          required
        />
        <VMargin />
        <EmailSubmit />
      </VForm.Provider>
      <VMargin />
      <TrimaButton variant="outlined" onClick={() => goBack()}>
        キャンセル
      </TrimaButton>
    </View>
  );
};

const MFAForm: React.FC = () => {
  return (
    <View>
      <Text>メールに記載されている確認コードを入力してください。</Text>
      <VMargin />
      <VForm.Provider<MFAFormData> schema={schemaMFAFormData}>
        <VForm.Text<MFAFormData>
          name="verificationCode"
          label="確認コード"
          required
        />
        <VerifySubmit />
      </VForm.Provider>
    </View>
  );
};

const AccountSelector: React.FC = () => {
  const {step} = AccountContainer.useContainer();
  switch (step) {
    case 'init':
    case 'changeEmail':
      return <EmailChangeForm />;
    case 'mfa':
    case 'challenging':
      return (
        <View>
          <MFAForm />
          <VMargin />
          <ReSendCode />
        </View>
      );
    default:
      return null; // ここには来ないはず
  }
};

const ReSendCode: React.FC = () => {
  const {reSendVerificationCode, resetState} = AccountContainer.useContainer();
  // 60秒セット
  const timerSeconds = 60000;
  const [date, setDate] = React.useState<number>(Date.now() + timerSeconds);

  const renderer = ({
    api,
    total,
    completed,
  }: {
    api: CountdownApi;
    total: number;
    completed: boolean;
  }) => {
    return (
      <View>
        <TrimaLoadingButton
          variant="outlined"
          disabled={api.isStarted()}
          onClick={() => {
            // タイマースタート
            api.start();
            // タイマー再セット
            setDate(Date.now() + timerSeconds);
            reSendVerificationCode('email');
          }}
        >
          確認コードの再送
        </TrimaLoadingButton>
        {!api.isStarted() ||
          (!completed && (
            <span style={{color: Colors.accent}}>
              {total / 1000}
              秒後に再送できます
            </span>
          ))}
      </View>
    );
  };

  return (
    <View>
      <Text>メールが届かない場合</Text>
      <VMargin />
      <Text>
        メールが届かない場合は
        <Text
          style={CommonStyles.linkText}
          onPress={() =>
            Linking.openURL(
              'https://support.shop.trip-mile.com/hc/ja/articles/4407344339085',
            )
          }
        >
          こちら
        </Text>
        の設定をご確認ください。
      </Text>
      <Text>以下のボタンから、確認コードの再送が可能です。</Text>
      <VMargin />
      <Countdown date={date} renderer={renderer} autoStart={false} />
      <VMargin />
      <Text>
        新しいメールアドレスが間違っている可能性がございます。以下のボタンから前のページに戻って再指定をお願いします。
      </Text>
      <VMargin />
      <TrimaButton variant="outlined" onClick={() => resetState()}>
        メールアドレスの指定に戻る
      </TrimaButton>
    </View>
  );
};

const ChangeEmailComponent: React.FC = React.memo(() => {
  const [user, setUser] = React.useState<any>();

  useEffect(() => {
    async function fetchData() {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setUser(cognitoUser);
    }
    fetchData();
  }, []);

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>メールアドレスの変更</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          {!user ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <FormBlock>
                <MaxWidth maxWidth={586}>
                  <Text>現在登録されているメールアドレス</Text>
                  <Text style={CommonStyles.margin.left}>
                    {user.attributes.email}
                  </Text>
                  <VMargin />
                  <AccountContainer.Provider>
                    <AccountSelector />
                  </AccountContainer.Provider>
                </MaxWidth>
              </FormBlock>
            </View>
          )}
        </View>
      </MaxWidth>
    </Container>
  );
});

export const ChangeEmail: React.FC = () => {
  const route = useRoute<any>();
  const [showHeader, setShowHeader] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (route.params?.header) {
      setShowHeader(route.params?.header);
    }
  }, [route.params]);
  if (showHeader) {
    return (
      <View>
        <Header rightItem={<AccountMenu />} />
        <ChangeEmailComponent />
      </View>
    );
  } else {
    return <ChangeEmailComponent />;
  }
};
