import GoogleMapReact from 'google-map-react';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {ShopContainer} from '../../container';
import {LngLat} from '../../helper';
import {ShopVisitorResponse, SingleMeshCountDto} from '../../service';
import {Colors, CommonStyles} from '../../theme';
import CenterMark from '../../_proto/parts/map/CenterMark';
import {GoogleApiRepository} from '../../_proto/services/GoogleApiRepository';
import {MeshRepository} from '../../_proto/services/MeshRepositoy';
import {LngLatIPC} from '../../_proto/services/ZipAddressRepository';
import {ButtonGroup, Overlay, Text} from '../Elements';

const BUTTONS_RESIDENCE = ['居住地', '勤務地'];

export const CustomerVisitorMap: React.FC<{
  response?: Pick<ShopVisitorResponse, 'mesh'> | 'loading' | 'error';
}> = React.memo(({response}) => {
  const [center] = React.useState(
    ShopContainer.useContainer().selected?.location ?? LngLatIPC,
  );
  const [index, setIndex] = React.useState<number>(0);
  let isOverLay: boolean;
  let meshes: SingleMeshCountDto[] = [];
  if (
    response === 'loading' ||
    response === 'error' ||
    !response ||
    !response.mesh
  ) {
    isOverLay = true;
  } else {
    isOverLay = false;
    meshes = (index ? response.mesh.office : response.mesh.residence) ?? [];
  }
  const isEmpty = meshes.length === 0;
  return (
    <View>
      <View style={styles.buttonGroupResidence}>
        <ButtonGroup
          buttons={BUTTONS_RESIDENCE}
          selectedIndex={index}
          onPress={setIndex}
        />
      </View>
      <View style={styles.mapContainer}>
        <Map meshes={meshes} center={center} />
        {(isOverLay || isEmpty) && (
          <Overlay>
            {response === 'loading' ? (
              <ActivityIndicator size={40} />
            ) : response === 'error' ? (
              <Text>データの取得に失敗しました</Text>
            ) : (
              <Text>データがありません。</Text>
            )}
          </Overlay>
        )}
      </View>
    </View>
  );
});

type MapProp = {
  center?: LngLat;
  meshes: SingleMeshCountDto[];
};

const MAP_KEY = {key: GoogleApiRepository.KEY};
const MAP_OPTION = {
  keyboardShortcuts: false,
  styles: [
    {
      elementType: 'geometry',
      stylers: [{color: '#f5f5f5'}],
    },
    {
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{color: '#616161'}],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{color: '#f5f5f5'}],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [{color: '#bdbdbd'}],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{color: '#eeeeee'}],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{color: '#757575'}],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{color: '#e5e5e5'}],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9e9e9e'}],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{color: '#ffffff'}],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [{color: '#757575'}],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{color: '#dadada'}],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{color: '#616161'}],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9e9e9e'}],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [{color: '#e5e5e5'}],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [{color: '#eeeeee'}],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{color: '#c9c9c9'}],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9e9e9e'}],
    },
  ],
};

type MapRefs = {
  map: any;
  maps: any;
};

type ColoredMesh = {mesh: string; transparent: number};
function mappingTransparent(count: number, maxCount: number): number {
  const rate = maxCount ? count / maxCount : 1;
  return rate >= 0.8
    ? 0.8
    : rate >= 0.7
    ? 0.7
    : rate >= 0.6
    ? 0.6
    : rate >= 0.5
    ? 0.5
    : rate >= 0.3
    ? 0.3
    : rate >= 0.1
    ? 0.1
    : 0;
}

function convertColoredMesh(meshes: SingleMeshCountDto[]): ColoredMesh[] {
  let maxCountMesh: SingleMeshCountDto = {count: 0, mesh: ''};
  meshes.forEach((mesh) => {
    if (mesh.count > maxCountMesh.count) {
      maxCountMesh = mesh;
    }
  });
  if (!maxCountMesh.mesh) {
    return []; // メッシュなし
  }
  return meshes
    .map((mesh) => ({
      mesh: mesh.mesh,
      transparent: mappingTransparent(mesh.count, maxCountMesh.count),
    }))
    .filter(({transparent}) => !!transparent);
}

const Map: React.FC<MapProp> = React.memo(({center, meshes}) => {
  // 地図インスタンス参照
  const mapRefs = React.useRef<MapRefs>({map: null, maps: null});
  // 描画済レクタングルリスト
  const meshRects = React.useRef<{setMap(u?: unknown): unknown}[]>([]);

  const drawRect = React.useCallback(() => {
    meshRects.current.forEach((rect) => rect.setMap(undefined));
    meshRects.current = [];

    // メッシュの描画
    const meshList: string[] = [];
    const {map, maps} = mapRefs.current;
    if (map && maps) {
      const list = convertColoredMesh(meshes);
      list.forEach(({mesh, transparent}) => {
        const b = MeshRepository.boundOfMesh(mesh);
        const rect = new maps.Rectangle({
          map,
          bounds: b,
          fillColor: Colors.accent,
          fillOpacity: transparent,
          strokeWeight: 0,
          clickable: false,
        });
        meshList.push(mesh);
        meshRects.current.push(rect);
      });
      // 地図の範囲指定を反映
      const bounds = MeshRepository.boundOfMeshs(meshList);
      if (center) {
        // 店舗位置もとりあえず含める
        bounds.west = Math.min(bounds.west, center.lng);
        bounds.east = Math.max(bounds.east, center.lng);
        bounds.north = Math.max(bounds.north, center.lat);
        bounds.south = Math.min(bounds.south, center.lat);
      }
      map.fitBounds(bounds);
    }
  }, [center, meshes]);

  React.useEffect(() => {
    drawRect();
  }, [drawRect]);

  const handleApiLoaded = (map: any, maps: any) => {
    mapRefs.current = {
      map,
      maps,
    };
    drawRect();
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={MAP_KEY}
      center={center}
      defaultZoom={14}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
      options={MAP_OPTION}
    >
      <CenterMark {...center} style={styles.pinOffset} />
    </GoogleMapReact>
  );
});

const styles = StyleSheet.create({
  buttonGroupResidence: {
    width: 240,
    ...CommonStyles.align.self.end,
  },
  mapContainer: {
    height: 300,
    alignSelf: 'stretch',
  },
  // 左上起点で決まるみたいなので、そこが中央下部になるようにオフセット
  pinOffset: {
    position: 'absolute',
    top: -44,
    left: -16,
  },
});
