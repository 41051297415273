import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {SubmitErrorHandler, useFormContext} from 'react-hook-form';
import {FlatList, TouchableOpacity, View} from 'react-native';
import {ActivityIndicator, Checkbox} from 'react-native-paper';
import {BRAKE_POINT} from '../../../components';
import {
  BackLink,
  Container,
  FormBlock,
  HelperText,
  MaxWidth,
  Menu,
  Text,
  TrimaLoadingButton,
  VForm,
  VMargin,
  yup,
  yupUtil,
} from '../../../components/Elements';
import {MainRouteProp} from '../../../navigation/MainScreen';
import {Colors, CommonStyles} from '../../../theme';
import {ExcludeVisitorsContainer} from './ExcludeVisitorsContainer';

type RProp = MainRouteProp<'ExcludeVisitorsMain'>;

export type ExcludeVisitorsFormData = {
  invitedCode?: string;
};

export const schemaExcludeVisitorsFormData: yup.SchemaOf<ExcludeVisitorsFormData> =
  yup.object().shape({
    invitedCode: yupUtil.stringRequired(),
  });

const Submit: React.FC<{shopId: string}> = (shopId) => {
  const {visitors, isCreateLoading, create, error} =
    ExcludeVisitorsContainer.useContainer();
  const [showError, setShowError] = React.useState<string | null>();
  const {
    handleSubmit,
    formState: {errors},
    reset,
  } = useFormContext<ExcludeVisitorsFormData>();
  React.useEffect(() => {
    if (!errors.invitedCode) {
      setShowError(null);
    }
  }, [errors.invitedCode]);

  const onSubmit = async (data: ExcludeVisitorsFormData) => {
    setShowError(null);
    if (visitors.length >= 10) {
      setShowError('登録できる件数は10件までです');
    } else if (
      visitors.some((visitor) => visitor.invitedCode === data.invitedCode)
    ) {
      setShowError('すでに登録されています');
    } else {
      await create({...data, ...shopId});
      reset({
        invitedCode: '',
      });
    }
  };
  const onError: SubmitErrorHandler<ExcludeVisitorsFormData> = () => {
    setShowError('フォームの入力に誤りがあります');
  };

  return (
    <View>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(onSubmit, onError)}
        disabled={isCreateLoading}
        loading={isCreateLoading}
        loadingPosition="start">
        登録
      </TrimaLoadingButton>
      <HelperText type="error">{showError || error?.message}</HelperText>
    </View>
  );
};

const VisitorsComponent = ({id}: {id: string | undefined}) => {
  const {isDeleteLoading, visitors, getData, deleteData} =
    ExcludeVisitorsContainer.useContainer();
  const [error, setError] = React.useState<Error | undefined>();
  const [deleteError, setDeleteError] = React.useState<Error | undefined>();
  const [ids, setIds] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    async function fetchData() {
      if (id) {
        try {
          await getData(id);
        } catch (err: any) {
          setError(err);
        }
      } else {
        setError(Error('対象IDが指定されていません'));
      }
    }
    fetchData();
  }, [id, getData]);

  const isChecked = (itemId: string) => {
    const isThere = ids.includes(itemId);
    return isThere;
  };

  const toggleChecked = (itemId: string) => {
    const newIds = [...ids, itemId];

    if (isChecked(itemId)) {
      setIds(ids.filter((id) => id !== itemId));
      console.log('ids', ids);
    } else {
      setIds(newIds);
      console.log('new[ids', ids);
    }
  };

  const onPress = () => {
    setDeleteError(undefined);
    try {
      deleteData(ids);
    } catch (err: any) {
      setDeleteError(err);
    } finally {
      setIds([]);
    }
  };

  return (
    <FormBlock>
      <MaxWidth maxWidth={586}>
        <Text>
          来店者として集計されるトリマユーザーを10人まで除外することができます。
        </Text>
        <Text>
          従業員の方がもしトリマをお使いの場合は、ご登録いただくことで、来店者として集計されなくなります。
        </Text>
        <VMargin />
        {id !== undefined && (
          <VForm.Provider<ExcludeVisitorsFormData>
            schema={schemaExcludeVisitorsFormData}>
            <VForm.Text<ExcludeVisitorsFormData>
              name="invitedCode"
              label="招待コード"
            />
            {error ? <Text>{error.message}</Text> : <Submit shopId={id} />}
          </VForm.Provider>
        )}

        <VMargin />
        <View>
          <Text>来店集計除外トリマアカウント</Text>
          {error ? (
            <Text>{error.message}</Text>
          ) : !visitors ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              {visitors.length === 0 ? (
                <View>
                  <VMargin />
                  <Text>{'　'}（設定なし）</Text>
                </View>
              ) : (
                <FlatList
                  data={visitors.filter((visitor: any) => visitor.id)}
                  renderItem={({item}) => (
                    <TouchableOpacity onPress={() => toggleChecked(item.id)}>
                      <View
                        style={[
                          CommonStyles.flex.row,
                          CommonStyles.flex.crossCenter,
                        ]}>
                        <Checkbox
                          color={Colors.delete}
                          status={isChecked(item.id) ? 'checked' : 'unchecked'}
                          onPress={() => toggleChecked(item.id)}
                        />
                        <Text>{item.invitedCode}</Text>
                      </View>
                    </TouchableOpacity>
                  )}
                />
              )}
            </View>
          )}
        </View>
        <VMargin />
        <MaxWidth maxWidth={586}>
          <TrimaLoadingButton
            color="error"
            variant="contained"
            onClick={onPress}
            disabled={isDeleteLoading || ids.length === 0}
            loading={isDeleteLoading}
            loadingPosition="start">
            選択した招待コードを削除
          </TrimaLoadingButton>
          <HelperText type="error">{deleteError?.message}</HelperText>
        </MaxWidth>
      </MaxWidth>
    </FormBlock>
  );
};

export const Main: React.FC = React.memo(() => {
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const navigation = useNavigation();
  const cancel = () => {
    navigation.goBack();
  };

  return (
    <Container notScroll>
      <MaxWidth maxWidth={BRAKE_POINT.desktop} style={CommonStyles.padding.all}>
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label="店舗情報に戻る" onClick={cancel} />
        </Box>
        <Menu>来店集計除外トリマアカウント</Menu>
        <VMargin />
        <ExcludeVisitorsContainer.Provider>
          <VisitorsComponent id={id} />
        </ExcludeVisitorsContainer.Provider>
      </MaxWidth>
    </Container>
  );
});

export const ExcludeVisitors = {
  Main,
};
