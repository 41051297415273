export default {
  // from XD
  base: '#E6E6E6',
  main: '#7E57C2',
  mainShadow: '#4C3575',
  header: '#3C3648',
  headerShadow: '#262031',
  accent: '#FF6F00',
  lightgray: '#F9F9F9',
  gray: '#D6D6D6',
  darkgray: '#B7B7B7',
  black: '#333333',
  white: '#FFFFFF',
  delete: '#D13B3B',
};

export const trimaColor = {
  main: '#FFC107',
  mainShadow: '#B38705',
  sub: '#FF6F00',
  accent: '#7E56C2',
  accentShadow: '#4C3575',
  shadow: '#B7B7B7',
  background: '#E6E6E6',
};
