import React from 'react';
import {SaasReportRepository, ShopVisitorResponse} from '../../service';
import {View} from 'react-native';
import {MaxWidth, Text, VMargin, WithHint} from '../Elements';
import {CommonStyles} from '../../theme';
import {CustomerVisitor} from './CustomerVisitor';

// ダッシュボード表示用の来店者リピート率サマリ表示
export const CustomerRepeatSummary: React.FC<{
  shopId: string;
}> = React.memo(({shopId}) => {
  const [response, setResponse] = React.useState<
    ShopVisitorResponse | 'loading' | 'error'
  >('loading');

  React.useEffect(() => {
    if (!shopId) {
      return;
    }
    SaasReportRepository.getShopVisitor(shopId, 'visitor')
      .then(setResponse)
      .catch(() => setResponse('error'));
  }, [shopId]);

  return (
    <View>
      <WithHint id="reportRepeater">
        <Text>来店者リピート率</Text>
      </WithHint>
      <VMargin />
      <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
        <CustomerVisitor.Table response={response} />
        <VMargin />
        <CustomerVisitor.Graph response={response} isSummary />
      </MaxWidth>
    </View>
  );
});
