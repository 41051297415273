import axios from 'axios';
import dayjs from 'dayjs';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';

type Article = {
  created_at: string;
  html_url: string;
  title: string;
};
const sections = ['360013989932', '360014089171'];

const useZendeskContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [articles, setArticles] = useState<Array<Article> | undefined>();

  const getArticles = useCallback(async () => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const results = await Promise.all(
        sections.map((section) => {
          return axios.get(
            `https://support.shop.trip-mile.com/api/v2/help_center/ja/sections/${section}/articles.json?sort_by=created_at&sort_order=desc&per_page=5`,
          );
        }),
      );
      const articleArray: Array<Article> = [];
      results.map((result) =>
        result.data.articles.forEach((article: Article) => {
          articleArray.push(article);
        }),
      );
      // 記事を作成日時で降順ソート
      articleArray.sort((a, b) => {
        return dayjs(b.created_at).unix() - dayjs(a.created_at).unix();
      });
      setArticles(articleArray);
      return articleArray;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  return {
    isCreateLoading,
    error,
    articles,
    getArticles,
  };
};

export const ZendeskContainer = createContainer(useZendeskContainer);
