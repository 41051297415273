import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {ShopContainer, convertShop} from '../../container';
import {Colors, CommonStyles, trimaColor} from '../../theme';
import {AppBookmarkDummyButton} from '../CouponPreview/SmallDummyButton';
import {ButtonGroup, MaxWidth, Text, VMargin} from '../Elements';
import {useResponsive} from '../Responsive';
import {PreviewComponent} from '../Shop/ShopPreview';
import {Detail, Header, Summary} from './common';
import {NewsInfo, NewsInfoInput, convert} from './types';

// 擬似スクリーンの切り替えボタン名
const BUTTONS = ['一覧画面', '詳細画面'];

/**
 * クーポンのプレビュー表示
 */
export const Preview: React.FC<{
  news?: NewsInfoInput;
  showShop?: boolean;
}> = React.memo<{news?: NewsInfoInput; showShop?: boolean}>(
  ({news, showShop}) => {
    const {isMobile} = useResponsive();
    // お知らせと店舗の情報を取得して、表示は子コンポーネントに任せる
    const [screen, setScreen] = React.useState<number>(1);
    const [info, setInfo] = React.useState<NewsInfo | undefined>();
    const {selected} = ShopContainer.useContainer();

    React.useEffect(() => {
      convert(news).then(setInfo);
    }, [news]);

    if (!info || !selected) {
      return (
        <View style={isMobile ? styles.xsWidth : styles.desktopWidth}>
          <ActivityIndicator size={40} />
        </View>
      );
    }

    return (
      <View style={isMobile ? styles.xsWidth : styles.desktopWidth}>
        {/* 擬似スクリーン切り替えボタン */}
        <ButtonGroup
          selectedIndex={screen}
          onPress={setScreen}
          buttons={BUTTONS}
        />
        {screen === 0 && <TopTabDummy />}
        <View
          style={
            isMobile
              ? [styles.dummyScreen, styles.xsWidth]
              : [styles.dummyScreen, styles.desktopWidth]
          }
        >
          {screen === 0 ? (
            // 一覧画面
            <View>
              <View style={CommonStyles.flex.row}>
                <AppBookmarkDummyButton />
              </View>
              <View style={CommonStyles.margin.top} />
              <Header shop={selected} />
              <Summary news={info} />
              <View style={CommonStyles.margin.top} />
              <Header shop={selected} />
              <Summary news={info} />
            </View>
          ) : (
            // 詳細画面
            <View>
              <Header shop={selected} />
              <Detail news={info} />
              {showShop && (
                <View>
                  <VMargin />
                  <MaxWidth maxWidth={isMobile ? 288 : 358}>
                    <PreviewComponent shop={convertShop(selected)} />
                  </MaxWidth>
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    );
  },
);

const TopTabDummy = React.memo(() => {
  return (
    <View style={styles.topTab}>
      <View style={[styles.tab, styles.focused]}>
        <Text style={styles.tabLabel}>トリマ限定</Text>
      </View>
      <View style={styles.tab}>
        <Text style={styles.tabLabel}>全国チェーン</Text>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  desktopWidth: {
    width: 390,
  },
  xsWidth: {
    width: 311,
  },
  container: {
    width: '100%',
  },
  topTab: {
    height: 50,
    borderBottomWidth: 2,
    backgroundColor: trimaColor.main,
    borderBottomColor: trimaColor.mainShadow,
    flexDirection: 'row',
  },
  tab: {
    ...CommonStyles.flex.full,
    ...CommonStyles.flex.center,
  },
  tabLabel: {fontSize: 13, color: Colors.white},
  focused: {
    backgroundColor: trimaColor.sub,
    borderRadius: 22,
    margin: 4,
  },
  dummyScreen: {
    backgroundColor: trimaColor.background,
    ...CommonStyles.padding.vertical,
    alignItems: 'center',
  },
});
