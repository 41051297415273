import {ChevronRight} from '@mui/icons-material';
import {Divider, Link} from '@mui/material';
import {PhoneNumberFormat} from 'google-libphonenumber';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {Image, StyleSheet, View} from 'react-native';
import HyperLink from 'react-native-hyperlink';
import {ActivityIndicator} from 'react-native-paper';
import Swiper from 'react-native-web-swiper';
import {FullAddress, SaaSShop, ShopCategory} from '../../API';
import CenterMarkerMap from '../../_proto/parts/map/CenterMarkerMap';
import {MapContainer} from '../../_proto/parts/map/MapContainer';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {LngLat, LngLatIPC} from '../../_proto/services/ZipAddressRepository';
import defaultIcon from '../../assets/icon_shop.png';
import noImage from '../../assets/no-image.png';
import {useResponsive} from '../../components';
import {shopCategoryLabels} from '../../container';
import {Helpers} from '../../helper';
import {getImages} from '../../service';
import {Colors, CommonStyles, trimaColor} from '../../theme';
import {CvLinkDummyButton} from '../CouponPreview/PrimaryDummyButton';
import {
  AppText,
  FormBlock,
  MaxWidth,
  Submenu,
  Text,
  VMargin,
} from '../Elements';
import {formatPhoneNumber} from '../Elements/utility';
import {ShopRegisterContainer} from '../Register/container';
import {ShopFormData} from '../Register/shopSchema';

// 各画面で使えるようにクーポン作成画面のデータorDBからのデータどちらもOKにする
export type ShopInfoInput =
  | SaaSShop
  | {
      data: ShopFormData;
      images: SaaSImageContents[];
      icon: SaaSImageContents | undefined;
    };

export type ShopInfo = {
  name: string;
  text?: string;
  address: string;
  phone?: string;
  biztimes: string;
  holidays: string;
  url?: string;
  legalUrl?: string;
  category: ShopCategory;
  images: SaaSImageContents[];
  icon?: string;
  location: LngLat;
};

const couponInfoDefault: ShopInfo = {
  name: '',
  images: [],
  icon: defaultIcon,
  address: '',
  location: LngLatIPC,
  phone: '',
  biztimes: '',
  holidays: '',
  category: ShopCategory.GOURMET,
};

async function convert(info?: ShopInfoInput): Promise<ShopInfo> {
  if (!info) {
    return couponInfoDefault;
  }
  if ('data' in info) {
    return {
      name: info.data.name ?? couponInfoDefault.name,
      text: info.data.text,
      icon: info.icon?.uri ?? couponInfoDefault.icon,
      images: info.images,
      address: dumpAddress({
        __typename: 'FullAddress',
        pref: info.data.pref,
        city: info.data.city,
        detail: info.data.detail,
        building: info.data.building,
      }),
      location: info.data.location ?? couponInfoDefault.location,
      phone: info.data.phone ?? couponInfoDefault.phone,
      biztimes: info.data.bizHours ?? couponInfoDefault.biztimes,
      holidays: info.data.holidays ?? couponInfoDefault.holidays,
      url: info.data.url,
      legalUrl: info.data.legalUrl,
      category: info.data.category ?? couponInfoDefault.category,
    };
  } else {
    return {
      name: info.name ?? couponInfoDefault.name,
      text: info.text ?? undefined,
      icon: info.icon && info.icon !== '' ? info.icon : couponInfoDefault.icon,
      images: await getImages(info.imageUrls),
      address: dumpAddress(
        info.address ?? {
          __typename: 'FullAddress',
          pref: '',
          city: '',
          detail: '',
          building: '',
        },
      ),
      location:
        info.location && info.location.lat && info.location.lon
          ? {
              lat: info.location.lat,
              lng: info.location.lon,
            }
          : couponInfoDefault.location,
      phone: info.phone ?? couponInfoDefault.phone,
      biztimes: info.biztimes ?? couponInfoDefault.biztimes,
      holidays: info.holidays ?? couponInfoDefault.holidays,
      url: info.url ?? couponInfoDefault.url,
      legalUrl: info.legalUrl ?? couponInfoDefault.legalUrl,
      category: info.category ?? couponInfoDefault.category,
    };
  }
}

function dumpAddress(address: FullAddress): string {
  return `${address.pref || ''}${address.city || ''}${address.detail || ''}${
    address.building || ''
  }`;
}

export const ShopPreview: React.FC = () => {
  const {watch, setValue, trigger} = useFormContext<ShopFormData>();
  const {images, icon} = ShopRegisterContainer.useContainer();
  React.useEffect(() => {
    const imageEnabled = images.length > 0;
    setValue('imageEnabled', imageEnabled);
    if (imageEnabled) {
      // 追加した際にエラーを消すためにトリガーをかける
      trigger('imageEnabled');
    }
  }, [images, setValue, trigger]);
  const data = watch();
  return (
    <MaxWidth maxWidth={390}>
      <Submenu style={styles.previewTitle}>店舗情報プレビュー</Submenu>
      <View style={styles.previewBack}>
        <View style={{marginVertical: 16, marginHorizontal: 12}}>
          <PreviewComponent shop={{data, images, icon}} />
        </View>
      </View>
    </MaxWidth>
  );
};

export const ShopPreviewDisplay: React.FC<{shop: SaaSShop}> = ({shop}) => {
  return (
    <MaxWidth maxWidth={390}>
      <Submenu style={styles.previewTitle}>店舗情報プレビュー</Submenu>
      <View style={styles.previewBack}>
        <View style={{...CommonStyles.margin.all}}>
          <PreviewComponent shop={shop} />
        </View>
      </View>
    </MaxWidth>
  );
};

export const PreviewComponent: React.FC<{
  shop?: ShopInfoInput;
}> = React.memo<{shop?: ShopInfoInput}>(({shop}) => {
  const {isMobile} = useResponsive();
  const [info, setInfo] = React.useState<ShopInfo | undefined>();

  React.useEffect(() => {
    convert(shop).then(setInfo);
  }, [shop]);

  if (!info) {
    return <ActivityIndicator size={40} />;
  }

  return (
    <FormBlock style={styles.form}>
      <View
        style={
          isMobile
            ? [styles.bodyCommon, styles.xsWidth]
            : [styles.bodyCommon, styles.detail, styles.desktopWidth]
        }
      >
        <ShopSummaryHeader shop={info} />
        <VMargin />
        <View>
          <ImageSwiper images={info.images} />
        </View>

        <ShopText text={info.text} />
        {info.legalUrl && info.url && (
          <>
            <VMargin />
            <CvLinkDummyButton text={'Webサイト'} url={info.url} />
          </>
        )}
        {!info.legalUrl && (
          <>
            <VMargin />
            <ShopDetailElement
              title={'所在地'}
              value={info.address}
              arrow={true}
            />
            <VMargin />
            <View style={styles.map}>
              <MapContainer.Provider>
                <CenterMarkerMap center={info.location} zoom={16} freezed />
              </MapContainer.Provider>
            </View>
          </>
        )}

        <VMargin />
        {info.phone && (
          <ShopDetailElement
            title={'電話番号'}
            value={formatPhoneNumber(info.phone, PhoneNumberFormat.NATIONAL)}
            arrow={true}
          />
        )}
        <ShopDetailElement title={'営業時間'} value={info.biztimes} />
        <ShopDetailElement title={'休業日'} value={info.holidays} />
        {info.url && !info.legalUrl && (
          <ShopUrlElement title={'Webサイト'} value={info.url} arrow={true} />
        )}
        {info.legalUrl && (
          <ShopLegalUrlElement value={info.legalUrl} arrow={true} />
        )}
        <ShopDetailElement
          title={'カテゴリ'}
          value={shopCategoryLabels[info.category]}
        />
        <Caution />
      </View>
    </FormBlock>
  );
});

const ShopSummaryHeader: React.FC<{shop: ShopInfo}> = ({shop}) => {
  return (
    <View style={styles.header}>
      <View style={styles.avatar}>
        <Image source={{uri: shop.icon ?? defaultIcon}} style={styles.icon} />
      </View>
      <View style={styles.shopInfo}>
        <View style={styles.shopNameContainer}>
          <AppText style={styles.shopName} numberOfLines={1}>
            {shop.name}
          </AppText>
          <div style={{height: 12}} />
        </View>
      </View>
    </View>
  );
};

const DummyImages: SaaSImageContents[] = [{uri: noImage, key: 'no_image'}];
const ImageSwiper = React.memo<{images?: SaaSImageContents[]}>(({images}) => {
  const {isMobile} = useResponsive();
  const imageList = images && images.length ? images : DummyImages;
  // keyを変えてやらないとSwiperの更新がされないっぽいのでkeyを生成して渡す
  const swiperKey = imageList.map(({key}) => key).join();
  const swappable = imageList.length > 1;
  return (
    <View
      style={
        isMobile
          ? styles.imageSwiperContentMobile
          : styles.imageSwiperContentDesktop
      }
    >
      <Swiper
        key={swiperKey}
        loop
        controlsEnabled={swappable}
        gesturesEnabled={() => swappable}
        controlsProps={{
          nextTitle: '',
          prevTitle: '',
          dotActiveStyle: styles.swiperDotActive,
        }}
      >
        {imageList.map((image) => (
          <Image
            style={
              isMobile ? styles.imageSwiperMobile : styles.imageSwiperDesktop
            }
            source={{uri: image.uri}}
            key={image.key}
          />
        ))}
      </Swiper>
    </View>
  );
});

// 店舗紹介テキスト
const ShopText = React.memo<{text?: string}>(({text}) => {
  if (!text) {
    return null;
  }
  return (
    <View style={styles.marginTop}>
      <HyperLink
        linkStyle={{color: trimaColor.accent}}
        onPress={(url) => Helpers.openURL(url)}
      >
        <AppText style={styles.couponText}>{text}</AppText>
      </HyperLink>
    </View>
  );
});

const ShopDetailElement: React.FC<{
  title: string;
  value: string;
  arrow?: boolean;
}> = ({title, value, arrow}) => {
  return (
    <View>
      <Divider />
      <View style={styles.detailArea}>
        <View style={styles.titleArea}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.valueAndArrowArea}>
          <View style={styles.valueArea}>
            <AppText style={styles.value}>{value}</AppText>
          </View>
          {arrow && <ChevronRight style={{color: Colors.gray}} />}
        </View>
      </View>
    </View>
  );
};

const ShopUrlElement: React.FC<{
  title: string;
  value: string;
  arrow?: boolean;
}> = ({title, value, arrow}) => {
  return (
    <View>
      <Divider />
      <View style={styles.detailArea}>
        <View style={styles.titleArea}>
          <Text style={styles.title}>{title}</Text>
        </View>
        <View style={styles.valueAndArrowArea}>
          <View style={styles.valueArea}>
            <Link
              sx={{height: '24px'}}
              href={value}
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              <AppText style={styles.value}>{value}</AppText>
            </Link>
          </View>
          {arrow && <ChevronRight style={{color: Colors.gray}} />}
        </View>
      </View>
    </View>
  );
};

const ShopLegalUrlElement: React.FC<{
  value: string;
  arrow?: boolean;
}> = ({value, arrow}) => {
  return (
    <View>
      <Divider />
      <View style={styles.detailArea}>
        <View style={styles.valueAndArrowArea}>
          <View style={styles.valueArea}>
            <Link
              sx={{height: '24px'}}
              href={value}
              target="_blank"
              rel="noopener"
              underline="hover"
            >
              <Text style={{fontSize: 12 * appleFontRatio}}>
                {'特定商取引法に基づく表記のURL'}
              </Text>
            </Link>
          </View>
          {arrow && <ChevronRight style={{color: Colors.gray}} />}
        </View>
      </View>
    </View>
  );
};

const cautionText =
  '※最新の情報とは異なる可能性がございます。必ず事前にご確認の上、ご利用ください。';
const Caution: React.FC = () => {
  return (
    <View>
      <Text style={styles.caution}>{cautionText}</Text>
    </View>
  );
};

const appleFontRatio = 0.935;
const couponRadius = 16;
const iconSize = 40;
const margin = 16;
const marginMiddle = 8;
const swipeImageWidth = 286 - margin * 2;
const swipeImageHeight = (swipeImageWidth * 3) / 4;
const swipeImageDesktopWidth = 358 - margin * 2;
const swipeImageDesktopHeight = (swipeImageDesktopWidth * 3) / 4;
const buttonBaseHeight = 36;
const buttonShadowHeight = 4;
const buttonHeight = buttonBaseHeight + buttonShadowHeight;
const buttonBorderWidth = 1;
const styles = StyleSheet.create({
  desktopWidth: {
    width: 342,
  },
  xsWidth: {
    maxWidth: 288,
  },
  preview: {
    flex: 1,
    alignItems: 'center',
  },
  previewTitle: {
    alignSelf: 'flex-start',
  },
  previewBack: {
    backgroundColor: Colors.base,
  },
  form: {
    borderRadius: 16,
  },
  pickersContainer: {
    ...CommonStyles.flex.row,
    flexWrap: 'wrap',
  },
  picker: {
    margin: 3,
  },
  select: {
    zIndex: 100,
  },
  discountInput: {
    zIndex: 50,
    marginTop: -4,
    paddingTop: 15,
    paddingHorizontal: 8,
    marginBottom: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  rowButton: {
    width: 200,
  },
  errorMargin: {minHeight: 22},
  container: {
    ...CommonStyles.formBlock,
    ...CommonStyles.margin.all,
    width: 311,
  },
  buttonHeight: {
    height: buttonHeight,
  },
  shadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonHeight,
    borderRadius: buttonHeight / 2,
    backgroundColor: trimaColor.shadow,
  },
  body: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonBaseHeight,
    borderWidth: buttonBorderWidth,
    borderRadius: buttonHeight / 2,
    borderColor: trimaColor.shadow,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
    fontWeight: 'normal',
    color: trimaColor.accent,
  },
  marginTop: {
    marginTop: 16,
  },
  header: {
    width: '100%',
    height: 72,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: couponRadius,
    borderTopRightRadius: couponRadius,
    overflow: 'hidden',
  },
  avatar: {
    width: iconSize,
    height: iconSize,
    borderRadius: iconSize / 2,
    overflow: 'hidden',
  },
  icon: {
    width: iconSize,
    height: iconSize,
  },
  shopInfo: {
    height: iconSize,
    marginLeft: marginMiddle,
    flex: 1,
    justifyContent: 'flex-end',
  },
  shopNameContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  shopName: {
    fontSize: 14 * appleFontRatio,
    lineHeight: 16,
  },
  couponText: {
    fontSize: 16 * appleFontRatio,
    lineHeight: 20,
  },
  detailArea: {
    flexDirection: 'row',
    marginTop: margin,
    marginBottom: margin,
  },
  titleArea: {
    width: 69,
  },
  valueAndArrowArea: {
    flexDirection: 'row',
    flex: 1,
  },
  arrowArea: {
    width: 20,
  },
  valueArea: {
    flex: 1,
  },
  title: {
    fontSize: 12 * appleFontRatio,
    color: trimaColor.shadow,
  },
  value: {
    fontSize: 12 * appleFontRatio,
    color: Colors.black,
  },
  listArrow: {
    fontSize: 20 * appleFontRatio,
    color: Colors.gray,
  },
  introduction: {
    fontSize: 16 * appleFontRatio,
    color: Colors.black,
    marginBottom: margin,
  },
  caution: {
    fontSize: 12 * appleFontRatio,
    color: trimaColor.shadow,
  },
  map: {
    ...CommonStyles.fullWidth,
    height: 120,
  },
  detail: {
    paddingRight: 16,
  },
  arrow: {color: Colors.gray, fontSize: 20},
  bodyCommon: {
    backgroundColor: Colors.lightgray,
    borderBottomLeftRadius: couponRadius,
    borderBottomRightRadius: couponRadius,
  },
  imageSwiperDesktop: {
    overflow: 'hidden',
    width: swipeImageDesktopWidth,
    height: swipeImageDesktopHeight,
  },
  imageSwiperContentDesktop: {
    overflow: 'hidden',
    width: swipeImageDesktopWidth,
    // swiperのdots位置調整
    height: swipeImageDesktopHeight + 30,
  },
  imageSwiperMobile: {
    overflow: 'hidden',
    width: swipeImageWidth,
    height: swipeImageHeight,
  },
  imageSwiperContentMobile: {
    overflow: 'hidden',
    width: swipeImageWidth,
    // swiperのdots位置調整
    height: swipeImageHeight + 30,
  },
  swiperDotActive: {
    backgroundColor: trimaColor.main,
  },
});
