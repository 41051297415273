import React from 'react';
import {useWidth} from '../Responsive';
import {Dialog, Portal} from 'react-native-paper';
import {View} from 'react-native';

export type DialogProps = React.ComponentProps<typeof Dialog> & {
  title?: string;
};
// 画面幅600以降は幅を広げず固定幅
export const DialogBase: React.FC<DialogProps> = ({
  title,
  children,
  style,
  ...dialogProps
}) => {
  const {windowWidth} = useWidth();
  const widthStyle =
    windowWidth > 600 ? {width: 568, alignSelf: 'center' as const} : undefined;

  return (
    <Portal>
      <Dialog {...dialogProps} style={[widthStyle, style]}>
        {title ? <Dialog.Title>{title}</Dialog.Title> : <View />}
        <Dialog.Content>{children}</Dialog.Content>
      </Dialog>
    </Portal>
  );
};
