import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Delete,
  Edit as EditIcon,
  FileCopyOutlined,
  MoreVert,
  Send,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  List as MuiList,
  Menu as MuiMenu,
  Typography,
} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator, Dialog, Portal} from 'react-native-paper';
import {BRAKE_POINT} from '../../components';
import {
  BackLink,
  Container,
  MaxWidth,
  Menu,
  MinText,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {useWidth} from '../../components/Responsive';
import {ShopContainer, ShopsContainer} from '../../container';
import {NewsContainer} from '../../container/NewsContainer';
import {ERROR_MSG} from '../../helper';
import {
  MainNavigationProp,
  MainRouteProp,
  ScreenType,
} from '../../navigation/MainScreen';
import {axiosHelper} from '../../service';
import {Colors, CommonStyles, Message} from '../../theme';
import {Copy} from './Copy';
import {Create} from './Create';
import {Detail} from './Detail';
import {Edit} from './Edit';

type NProp = MainNavigationProp<'NewsMain'>;
type RProp = MainRouteProp<'NewsMain'>;

type ListItem = {
  title: string;
  name: string;
  used: boolean;
  delivery: string;
  id: string;
  archive: boolean;
};

const NewsDeleteDialog: React.FC<{
  visible: boolean;
  onDismiss?(): void;
  onError?(): void;
  item: ListItem;
}> = ({visible, onDismiss, onError, item}) => {
  const [busy, setBusy] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string | undefined>();
  const {deleteData} = NewsContainer.useContainer();
  const removed = () => onDismiss && onDismiss();
  const cancel = () => onDismiss && onDismiss();
  const errored = () => onError && onError();
  const remove = async () => {
    setBusy(true);
    try {
      await deleteData(item.id);
      removed();
    } catch (err: any) {
      setMessage(axiosHelper.commonErrorHandler(err));
    } finally {
      setBusy(false);
    }
  };
  const render = () => {
    // エラー時
    if (message) {
      return (
        <View>
          <Text>広告の削除に失敗しました。</Text>
          <Text>{message}</Text>
          <VMargin />
          <TrimaButton variant="outlined" onClick={errored}>
            {Message.BackToList}
          </TrimaButton>
        </View>
      );
    }
    return (
      <View>
        <Text>■タイトル（管理用）</Text>
        <Text>{item.title}</Text>
        <Text>■広告内容</Text>
        <Text>{item.name}</Text>
        <Text>■配信回数と閲覧回数</Text>
        <Text>{item.delivery}</Text>
        <VMargin />
        <TrimaLoadingButton
          variant="contained"
          color="error"
          onClick={remove}
          disabled={busy}
          loading={busy}>
          削除
        </TrimaLoadingButton>
        <VMargin />
        <TrimaButton variant="outlined" onClick={cancel} disabled={busy}>
          キャンセル
        </TrimaButton>
      </View>
    );
  };
  const {windowWidth} = useWidth();
  const widthStyle =
    windowWidth > 600 ? {width: 568, alignSelf: 'center' as const} : undefined;

  return (
    <Portal>
      <Dialog
        visible={visible}
        onDismiss={cancel}
        dismissable={false}
        style={widthStyle}>
        <Dialog.Title style={styles.deleteText}>
          広告を削除しますか？
        </Dialog.Title>
        <Dialog.Content>{render()}</Dialog.Content>
      </Dialog>
    </Portal>
  );
};

const AddButton: React.FC = () => {
  const navigation = useNavigation();
  const gotoCreate = () => navigation.navigate('NewsCreate');
  return (
    <Box pt={1}>
      <TrimaButton
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={gotoCreate}>
        広告を新規作成
      </TrimaButton>
    </Box>
  );
};

const Heading: React.FC<{from?: ScreenType}> = ({from}) => {
  const navigation = useNavigation<NProp>();
  const cancel = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('CreativesCreate');
    }
  };
  if (!from) {
    // 規定
    return (
      <WithHint id="newsMain">
        <Menu>作成済みの広告</Menu>
      </WithHint>
    );
  } else {
    // とりあえず配信からくるケースを実装
    return (
      <View>
        {from === 'CreativesCreate' && (
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label="配信する画面に戻る" onClick={cancel} />
          </Box>
        )}
        <Menu>作成済みの広告の選択</Menu>
      </View>
    );
  }
};

const Footer: React.FC<{from?: ScreenType}> = ({from}) => {
  // 他の画面から来た場合は新規ボタンは表示しない
  return !from ? <AddButton /> : null;
};

const Main: React.FC = () => {
  const {selected} = ShopContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const [errorMsg, setError] = React.useState<string>('');
  const {isGetLoading, news, getData, getGroupData, groupNews} =
    NewsContainer.useContainer();
  // 遷移元の設定
  const route = useRoute<RProp>();
  const fromScreen = route.params?.from;
  const [from] = React.useState<ScreenType | undefined>(fromScreen);

  React.useEffect(() => {
    async function fetchData() {
      if (selected) {
        try {
          if (from === 'CreativesGroupCreate') {
            await getGroupData(
              shopList
                .filter((shop) => shop.groupId === selected?.groupId)
                .map((shop: any) => shop.id),
            );
          } else {
            await getData(selected.id);
          }
        } catch (err: any) {
          setError(ERROR_MSG.common.dump(err));
        }
      }
    }
    fetchData();
  }, [selected, getData, shopList, getGroupData]);

  return (
    <Container style={CommonStyles.padding.all}>
      <MaxWidth maxWidth={BRAKE_POINT.desktop}>
        <Heading from={from} />
        <View style={CommonStyles.margin.top} />
        <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
          <View style={CommonStyles.flex.full}>
            {errorMsg ? (
              <Text>{errorMsg}</Text>
            ) : !news || isGetLoading ? (
              <ActivityIndicator size={60} />
            ) : (
              <View>
                <Text>未使用</Text>
                <VMargin />
                <Footer from={from} />
                <VMargin />
                <MuiList>
                  {(shopList.filter(
                    (shop) => shop.groupId === selected?.groupId,
                  ).length > 0
                    ? groupNews.flat().filter((news) => !news.used)
                    : news.filter((news) => !news.used)
                  ).map((news: any) => (
                    <React.Fragment key={news.id}>
                      <NewsListItem news={news} from={from} />
                      <Box p={0.5} />
                    </React.Fragment>
                  ))}
                </MuiList>
                <VMargin />
                <Text>使用済み</Text>
                <VMargin />
                <MuiList>
                  {(shopList.filter(
                    (shop) => shop.groupId === selected?.groupId,
                  ).length > 0
                    ? groupNews.flat().filter((news) => news.used)
                    : news.filter((news) => news.used)
                  ).map((news: any) => (
                    <React.Fragment key={news.id}>
                      <NewsListItem news={news} from={from} />
                      <Box p={0.5} />
                    </React.Fragment>
                  ))}
                </MuiList>
              </View>
            )}
          </View>
        </MaxWidth>
      </MaxWidth>
    </Container>
  );
};

const NewsListItem: React.FC<{
  news: any;
  from?: ScreenType;
}> = React.memo(({news, from}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDialog, setDialog] = React.useState<boolean>(false);

  const navigation = useNavigation<NProp>();
  const goto = () => {
    if (from === 'CreativesCreate' || from === 'CreativesGroupCreate') {
      // 配信作成画面からきたので、選択されたクーポン情報を渡して戻る。
      navigation.navigate(from, {type: 'news', adsId: news.id});
    } else {
      // 通常は遷移ページへ
      navigation.navigate('NewsDetail', {id: news.id});
    }
  };
  const gotoEdit = () =>
    navigation.navigate('NewsEdit', {id: news.id, from: 'NewsMain'});
  const gotoCopy = () =>
    navigation.navigate('NewsCopy', {id: news.id, from: 'NewsMain'});
  const gotoCreative = () =>
    navigation.navigate('CreativesCreate', {type: 'news', adsId: news.id});
  const gotoDash = () => navigation.navigate('DashBoard');
  const onDismiss = () => {
    setDialog(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const visiblePopup = !from;

  return (
    <>
      <ListItem
        sx={{
          '&.MuiListItem-root': {
            padding: 0,
            bgcolor: 'background.paper',
            border: `1px solid ${Colors.main}`,
          },
        }}
        secondaryAction={
          visiblePopup && (
            <>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <MoreVert color="primary" />
              </IconButton>
              <MuiMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={gotoEdit}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Typography variant="inherit" color="primary">
                    編集する
                  </Typography>
                </MenuItem>
                <MenuItem onClick={gotoCopy}>
                  <ListItemIcon>
                    <FileCopyOutlined fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Typography variant="inherit" color="primary">
                    複製する
                  </Typography>
                </MenuItem>
                <MenuItem onClick={gotoCreative}>
                  <ListItemIcon>
                    <Send fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Typography variant="inherit" color="primary">
                    配信する
                  </Typography>
                </MenuItem>
                {!news.used && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setDialog(true);
                    }}>
                    <ListItemIcon>
                      <Delete fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit" color="primary">
                      削除する
                    </Typography>
                  </MenuItem>
                )}
              </MuiMenu>
            </>
          )
        }>
        <ListItemButton
          onClick={goto}
          sx={{
            '&.MuiButtonBase-root': {
              py: 2,
            },
          }}>
          <ListItemText
            id={news.id}
            primary={
              <>
                <Box display="flex" flexDirection="row">
                  <Typography
                    style={{fontWeight: 500, color: Colors.main}}
                    variant="subtitle1"
                    component="span">
                    {news.title}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block">
                    {news.name}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    variant="caption"
                    component="span"
                    display="block">
                    <MinText>{news.delivery}</MinText>
                  </Typography>
                </Box>
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      <NewsDeleteDialog
        visible={showDialog}
        item={news}
        onDismiss={onDismiss}
        onError={gotoDash}
      />
    </>
  );
});

const styles = StyleSheet.create({
  newButton: {
    ...CommonStyles.margin.top,
    height: 40,
  },
  delete: {
    backgroundColor: Colors.delete,
  },
  deleteText: {
    color: Colors.delete,
  },
});

export const News = {
  Main,
  Copy,
  Create,
  Detail,
  Edit,
};
