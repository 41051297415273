import {
  SaaSImageContents,
  SaaSRepository,
} from '../_proto/services/SaaSRepository';

/**
 * DB上の画像格納情報から、SaaSImageContentsへ変換
 * @param imageUrls
 */
export async function getImages(
  imageUrls?: Array<string | null> | null,
): Promise<SaaSImageContents[]> {
  try {
    if (imageUrls) {
      const urls = imageUrls.filter((value) => value) as string[];
      if (urls.length) {
        return await Promise.all(
          urls.map(async (value, index) => {
            return {
              uri: await SaaSRepository.fetchImage(value),
              key: `image_${index}`,
            };
          }),
        );
      }
    }
  } finally {
    // Todo エラー時
  }
  return [];
}
