import API, {graphqlOperation, GraphQLResult} from '@aws-amplify/api';
import {Auth} from 'aws-amplify';
import axios from 'axios';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {AppConfig} from '../_proto/config/AppConfig';
import {SaaSBillingRepository} from '../_proto/services/SaaSBillingRepository';
import {SaaSDeliveryRepository} from '../_proto/services/SaaSDeliveryRepository';
import {
  GetPaymentInfoQuery,
  SaaSOwner,
  UpdateSaaSPaymentInfoInput,
} from '../API';
import {getPaymentInfo} from '../graphql/queries';
import {graphQLService} from '../service';

const usePaymentInfoContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<any>();
  const [error, setError] = useState<Error | undefined>();
  const [customerId, setCustomerId] = useState<string | undefined>();
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const [invitedCode, setInvitedCode] = useState<string>();

  const createPaymentMethod = useCallback(
    async (stripe: any, cardElement: any, name: string, owner: any) => {
      const user = await Auth.currentAuthenticatedUser();
      // Use your card Element with other Stripe.js APIs
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          email: user.attributes.email,
          phone: owner?.chargePhone ? owner.chargePhone : undefined,
          address: {
            postal_code: owner?.address?.zip ? owner.address.zip : undefined,
          },
        },
      });
      console.log(payload);
      setPaymentMethod(payload.paymentMethod);
      console.log('paymentMethod', paymentMethod);
      return payload;
    },
    [paymentMethod],
  );

  const addCard = useCallback(
    async (customerId: string, paymentMethodId: string) => {
      await SaaSBillingRepository.addPaymentMethod({
        customerId,
        paymentMethodId,
      });
    },
    [],
  );

  const createSubscription = useCallback(
    async (
      owner: SaaSOwner,
      priceId: string,
      paymentMethodId: string,
      stripe: any = undefined,
    ) => {
      if (!owner || !paymentMethodId) {
        throw new Error();
      }
      const user = await Auth.currentAuthenticatedUser();
      // 加盟店登録時のクレジットカード登録の場合
      let result;

      const {charge, address, hojin} = owner;
      if (!customerId && !paymentInfo?.customerId) {
        const data = {
          email: user.attributes.email,
          name: `${charge?.last}${charge?.first}`,
          ownerId: owner?.id,
          invitedCode,
          address,
          hojin,
        };
        result = await SaaSBillingRepository.createCustomer(data);
        setCustomerId(result.id);
      }

      const data = {
        ownerId: owner?.id,
        customerId: customerId || result?.id || paymentInfo.customerId,
        paymentMethodId,
        priceId,
      };
      // Stripe用サブスクリプション登録
      const subscriptionResult: any =
        await SaaSBillingRepository.createCardSubscription(data);
      console.log(subscriptionResult);

      const setupIntent = subscriptionResult.pending_setup_intent;
      if (setupIntent && setupIntent.status === 'requires_action' && stripe) {
        // 3Dセキュア処理
        const confirmCardResult = await stripe.confirmCardSetup(
          setupIntent.client_secret,
          {
            payment_method: paymentMethodId,
          },
        );
        if (confirmCardResult.error) {
          console.log('[error]', confirmCardResult.error);
          setError(confirmCardResult.error);
          // TODO: subscription削除
          // throw confirmCardResult.error;
        }
      }

      // 支払い状態を有効に設定
      const updatePaymentInfo = await graphQLService.updatePaymentInfo({
        id: owner.id,
        billing: true,
      });
      console.log('updatePaymentInfo', updatePaymentInfo);

      setPaymentInfo(updatePaymentInfo);
    },
    [customerId, invitedCode, paymentInfo],
  );

  const createPaidCustomer = useCallback(
    async (owner: any, paymentMethod: any) => {
      setCreateLoading(true);
      setError(undefined);
      let result;
      try {
        if (!owner) {
          throw new Error();
        }
        const user = await Auth.currentAuthenticatedUser();
        if (!customerId) {
          const data = {
            email: user.attributes.email,
            name: `${owner.charge?.last}${owner.charge?.first}`,
            ownerId: owner.id,
            invitedCode,
          };
          result = await SaaSBillingRepository.createCustomer(data);
          setCustomerId(result.id);
        }
        const data = {
          b2bMemberId: customerId || result.id,
          paymentMethod,
          companyName: owner.hojin,
          companyNameKana: owner.hojinKana,
          representativeSei: owner.representative?.last,
          representativeMei: owner.representative?.first,
          representativeSeiKana: owner.representativeKana?.last,
          representativeMeiKana: owner.representativeKana?.first,
          zipCode: owner.address?.zip,
          prefecture: owner.address?.pref,
          address1: owner.address?.city,
          address2: owner.address?.detail,
          address3: owner.address?.building,
          clerkSei: owner.charge?.last,
          clerkMei: owner.charge?.first,
          clerkSeiKana: owner.chargeKana?.last,
          clerkMeiKana: owner.chargeKana?.last,
          tel: owner.chargePhone,
          email: user.attributes.email,
          url1: owner.url,
          ownerId: owner.id,
        };
        await SaaSBillingRepository.createPaidCustomer(data);

        // TODO: createPaidCustomerしたときにDBの値を返していないので、一旦取得
        const updatePaymentInfo = await graphQLService.getPaymentInfo(owner.id);
        setPaymentInfo(updatePaymentInfo);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [customerId, invitedCode],
  );

  const createPaidSubscription = useCallback(
    async (owner: any, priceId: any) => {
      console.log('paymentInfo', paymentInfo);
      setCreateLoading(true);
      setError(undefined);
      try {
        if (!owner) {
          throw new Error();
        }
        const user = await Auth.currentAuthenticatedUser();
        console.log('user', user);
        const data = {
          customerId: paymentInfo.customerId,
          priceId,
          ownerId: owner.id,
        };
        // Paid用サブスクリプション登録
        const subscriptionResult =
          await SaaSBillingRepository.createPaidSubscription(data);

        console.log(subscriptionResult);

        // 支払い状態を有効に設定
        const result = await graphQLService.updatePaymentInfo({
          id: owner.id,
          billing: true,
        });
        setPaymentInfo(result);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [paymentInfo],
  );

  const fetchPaymentInfo = useCallback(async (id: string) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = (await API.graphql(
        graphqlOperation(getPaymentInfo, {id}),
      )) as GraphQLResult<GetPaymentInfoQuery>;
      if (!result.data) {
        throw new Error('[getPaymentInfo] result.data is undefined');
      }
      setPaymentInfo(result.data.getPaymentInfo);
      return result.data.getPaymentInfo;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const checkPromotionCode = useCallback(async (id: string | undefined) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = await axios.get(
        `${AppConfig.SaaSBackend}${AppConfig.SaaSInviteCodeCheckEndpoint}/${id}`,
      );
      if (!result.data) {
        throw new Error('無効な招待コードです');
      }
      setInvitedCode(id);
      return result;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const clearPromotionCode = useCallback(() => {
    setInvitedCode(undefined);
  }, []);

  const clearData = useCallback(() => {
    setPaymentMethod(undefined);
    setCustomerId(undefined);
    setPaymentInfo(undefined);
    setInvitedCode(undefined);
  }, []);

  const suspendUse = useCallback(
    async (data: any, owner: any) => {
      try {
        setCreateLoading(true);
        if (!owner?.id) {
          throw new Error();
        }
        // 配信チェック
        const creatives = await SaaSDeliveryRepository.getActiveCreatives(
          owner.id,
        );
        if (
          creatives.data.deliveries.length > 0 ||
          creatives.data.news.length > 0
        ) {
          throw new Error(
            '配信中/配信開始前の広告があるため利用の停止はできません',
          );
        }
        const user = await Auth.currentAuthenticatedUser(); // ログイン中のユーザー情報

        // パスワードチェック
        await Auth.signIn(user.attributes.email, data.password);

        // Subscriptionの停止
        const subscriptionResult: any =
          await SaaSBillingRepository.cancelSubscription({
            subscriptionId: paymentInfo.subscriptionId,
            customerId: paymentInfo.customerId,
          });
        console.log(subscriptionResult);

        await graphQLService.updatePaymentInfo({
          id: owner.id,
          billing: false,
        });
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [paymentInfo],
  );

  const update = useCallback(async (input: UpdateSaaSPaymentInfoInput) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = await graphQLService.updatePaymentInfo(input);
      setPaymentInfo(result);
      return result;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  return {
    error,
    isCreateLoading,
    createPaymentMethod,
    addCard,
    createSubscription,
    fetchPaymentInfo,
    createPaidCustomer,
    createPaidSubscription,
    paymentInfo,
    paymentMethod,
    checkPromotionCode,
    invitedCode,
    clearPromotionCode,
    clearData,
    suspendUse,
    update,
  };
};

export const PaymentInfoContainer = createContainer(usePaymentInfoContainer);
