import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {FlatList, Linking, StyleSheet, Text, View} from 'react-native';
import {ActivityIndicator, DataTable} from 'react-native-paper';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import {
  Container,
  FormBlock,
  List,
  MaxWidth,
  Menu,
  VMargin,
} from '../../components/Elements';
import {PaymentInfoContainer} from '../../container';
import {StripeContainer} from '../../container/StripeContainer';
import {Colors, CommonStyles} from '../../theme';

const formatter = new Intl.NumberFormat('ja-JP');
const reducer = (previousValue: any, currentValue: any) =>
  previousValue + currentValue;

const Item: React.FC<{item: any}> = ({item}) => {
  return (
    <List.Item onPress={() => Linking.openURL(item.hosted_invoice_url)}>
      <View>
        <DataTable style={styles.dataTable}>
          <DataTable.Row style={styles.dataTableRow}>
            <DataTable.Cell>
              {dayjs.unix(item.period_start).format('YYYY/MM')}
            </DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row style={styles.dataTableRow}>
            <DataTable.Cell>配信人数：</DataTable.Cell>
            <DataTable.Cell>
              {formatter.format(
                item.lines.data
                  .map((lineItem: any) => lineItem.quantity)
                  .reduce(reducer),
              )}{' '}
              人
            </DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row style={styles.dataTableRow}>
            <DataTable.Cell>お支払い金額：</DataTable.Cell>
            <DataTable.Cell>
              {formatter.format(item.total)} 円(税込)
            </DataTable.Cell>
          </DataTable.Row>
        </DataTable>
        <View style={CommonStyles.flex.crossEnd}>
          <VMargin />
          <Text style={{color: Colors.main}}>
            請求書 <FontAwesomeIcon style={styles.icon} name="chevron-right" />
          </Text>
        </View>
      </View>
    </List.Item>
  );
};

const InvoicesComponent: React.FC = () => {
  const {paymentInfo} = PaymentInfoContainer.useContainer();
  const {customerId, subscriptionId} = paymentInfo;
  const [error, setError] = React.useState<Error | undefined>();
  const {upcomingInvoice, getUpcomingInvoice, invoices, getListInvoices} =
    StripeContainer.useContainer();

  useEffect(() => {
    async function fetchData() {
      if (!subscriptionId) {
        // サブスクリプションキャンセル済みの場合
        setError(Error('今月の利用はありません'));
      } else {
        if (!upcomingInvoice) {
          const data = {
            customerId,
            subscriptionId,
          };
          await getUpcomingInvoice(data);
        }
      }
    }
    fetchData();
  }, [customerId, subscriptionId, getUpcomingInvoice, upcomingInvoice]);

  useEffect(() => {
    async function fetchData() {
      const data = {
        customerId,
        limit: 20,
      };
      if (!invoices) {
        await getListInvoices(data);
      }
    }
    fetchData();
  }, [customerId, getListInvoices, invoices]);

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>請求情報</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          <View>
            <FormBlock>
              <MaxWidth maxWidth={586}>
                <Text>今月の利用状況</Text>
                <VMargin />
                {error ? (
                  <View style={styles.dataArea}>
                    <Text>{error.message}</Text>
                  </View>
                ) : !upcomingInvoice ? (
                  <ActivityIndicator size={60} />
                ) : (
                  <View style={styles.dataArea}>
                    <DataTable style={styles.dataTable}>
                      <DataTable.Row style={CommonStyles.borderless}>
                        <DataTable.Cell>配信人数：</DataTable.Cell>
                        <DataTable.Cell>
                          {formatter.format(
                            upcomingInvoice.lines.data
                              .map((lineItem: any) => lineItem.quantity)
                              .reduce(reducer),
                          )}{' '}
                          人
                        </DataTable.Cell>
                      </DataTable.Row>
                      <DataTable.Row style={CommonStyles.borderless}>
                        <DataTable.Cell>お支払い金額：</DataTable.Cell>
                        <DataTable.Cell>
                          {formatter.format(upcomingInvoice.total)} 円(税込)
                        </DataTable.Cell>
                      </DataTable.Row>
                    </DataTable>
                  </View>
                )}
              </MaxWidth>
            </FormBlock>
            <VMargin />
            <MaxWidth maxWidth={586}>
              <Text>お支払い履歴</Text>
              <VMargin />
              {!invoices ? (
                <ActivityIndicator size={60} />
              ) : (
                <View>
                  <FlatList
                    data={invoices.data.filter((invoice: any) => invoice.id)}
                    keyExtractor={(invoice) => invoice.id}
                    ItemSeparatorComponent={List.Separator}
                    renderItem={(invoice) => <Item item={invoice.item} />}
                  />
                  <VMargin />
                </View>
              )}
            </MaxWidth>
          </View>
        </View>
      </MaxWidth>
    </Container>
  );
};

const Main: React.FC<{route: any}> = () => {
  return <InvoicesComponent />;
};

const styles = StyleSheet.create({
  dataArea: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.base,
    ...CommonStyles.padding.all,
    ...CommonStyles.flex.center,
  },
  dataTable: {
    width: 280,
  },
  dataTableRow: {
    borderBottomWidth: 0,
    minHeight: 30,
  },
  border: {
    borderWidth: 1,
    borderColor: Colors.gray,
  },
  icon: {color: Colors.main, fontSize: 16},
});

export const Invoices = {
  Main,
};
