import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import dayjs from 'dayjs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {SaaSCoupon, SaaSDeliveryTarget, SaaSNews} from '../../API';
import {BRAKE_POINT, CreativeSettings, useResponsive} from '../../components';
import {Preview as CouponPreview} from '../../components/CouponPreview';
import {TargetCreative, getUsedMoney} from '../../components/Creatives/schema';
import {
  BackLink,
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {Preview as NewsPreview} from '../../components/NewsPreview';
import {TargetsPreview} from '../../components/TargetsPreview';
import {Helpers} from '../../helper';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {getDeliveryStatus, graphQLService} from '../../service';
import {Colors, CommonStyles, Message} from '../../theme';

type NProp = MainNavigationProp<'CreativesDetail'>;
type RProp = MainRouteProp<'CreativesDetail'>;

export const Detail: React.FC = React.memo(() => {
  const [detailTarget, setDetailTarget] = React.useState<TargetCreative>();
  const [error, setError] = React.useState<Error | undefined>();
  const {isMobile} = useResponsive();
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const type = route.params?.type;
  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('CreativesMain');
    }
  };
  const gotoEdit = () => navigation.navigate('CreativesEdit', {type, id});

  // const gotoNewsEdit = (id: string) => navigation.navigate('NewsEdit', {id});

  // 配信情報の取得
  React.useEffect(() => {
    if (id && type) {
      if (type === 'coupon') {
        graphQLService
          .getDeliveryHistory(id)
          .then((value) => {
            setDetailTarget({type: 'coupon', creative: value});
          })
          .catch(setError);
      } else if (type === 'news') {
        graphQLService
          .getNewsHistory(id)
          .then((value) => {
            setDetailTarget({type: 'news', creative: value});
          })
          .catch(setError);
      } else {
        setError(Error('不正なタイプパラメータが指定されています'));
      }
    } else {
      setError(Error('対象ID/タイプが指定されていません'));
    }
  }, [id, type]);

  // エラー時
  if (error) {
    return (
      <View>
        <Text>エラーが発生しました</Text>
        <Text>{error.message}</Text>
      </View>
    );
  }

  // 取得前
  if (!detailTarget) {
    return <ActivityIndicator size={40} />;
  }

  const directionStyle = isMobile ? {} : CommonStyles.flex.row;
  const blockStyle = isMobile ? {} : {width: 399};

  // クーポンとニュースで違うところ
  let state;
  let coupon;
  let news;
  if (detailTarget.type === 'coupon') {
    state = detailTarget.creative.couponState;
    if (detailTarget.creative.couponDef) {
      coupon = JSON.parse(detailTarget.creative.couponDef) as SaaSCoupon;
    }
  } else {
    state = detailTarget.creative.newsState;
    if (detailTarget.creative.newsDef) {
      news = JSON.parse(detailTarget.creative.newsDef) as SaaSNews;
    }
  }

  // 同じところ
  const creative = detailTarget.creative;
  const target = creative.targetDef
    ? (JSON.parse(creative.targetDef) as SaaSDeliveryTarget)
    : undefined;
  const startAt = creative.startAt
    ? dayjs(creative.startAt).utcOffset(9).format('YYYY/MM/DD HH:mm')
    : undefined;
  const endAt = creative.endAt
    ? dayjs(creative.endAt).utcOffset(9).format('YYYY/MM/DD HH:mm')
    : undefined;
  const budget =
    creative.budget === undefined || creative.budget === null
      ? undefined
      : creative.budget === 0
      ? '上限なし'
      : `${Helpers.sepComma(creative.budget)}円まで`;
  const usedMoney = Helpers.sepComma(getUsedMoney(creative));

  let showDelete = false;
  if (
    creative.balance !== undefined &&
    creative.balance !== null &&
    creative.budget !== undefined &&
    creative.budget !== null &&
    creative.startAt &&
    creative.endAt
  ) {
    const status = getDeliveryStatus({
      balance: creative.balance,
      budget: creative.budget,
      start: dayjs(startAt),
      end: dayjs(endAt),
      state,
    });
    showDelete = status === 'before' || status === 'active';
  }
  return (
    <Container>
      <MaxWidth
        maxWidth={BRAKE_POINT.desktop + 16}
        style={CommonStyles.margin.all}
      >
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label={Message.BackToList} onClick={goBack} />
        </Box>
        <Menu>配信設定</Menu>
        <VMargin />
        <View style={directionStyle}>
          <FormBlock style={blockStyle}>
            <Text>配信内容</Text>
            <VMargin />
            <View style={CommonStyles.flex.center}>
              {coupon && (
                <CouponPreview
                  coupon={coupon}
                  showShop={true}
                  end={dayjs(creative.endAt)}
                />
              )}
              {news && <NewsPreview news={news} showShop={true} />}
            </View>
          </FormBlock>
          <View style={[CommonStyles.margin.top, CommonStyles.margin.left]} />
          <View style={CommonStyles.flex.full}>
            <FormBlock>
              <Text>配信先</Text>
              <VMargin />
              {/*Todo 配信対象者数の更新*/}
              <TargetsPreview.Summary target={target} />
            </FormBlock>
            <VMargin />
            <FormBlock>
              <WithHint id="creativeTerm">
                <Text>配信期間（広告有効期間）</Text>
              </WithHint>
              <VMargin />
              <Text>開始日時</Text>
              <Text>{startAt}</Text>
              <VMargin />
              <Text>終了日時</Text>
              <Text>{endAt}</Text>
              <VMargin />
              <WithHint id="creativeBudgetLimit">
                <Text>予算上限</Text>
              </WithHint>
              <Text>{budget}</Text>
              <VMargin />
              <View style={styles.used}>
                <Text>この配信の使用金額：</Text>
                <Text style={styles.count}>{usedMoney}</Text>
                <Text>円</Text>
              </View>
              <VMargin />
              <Text>タイトル（管理用）</Text>
              <Text>{creative.title}</Text>
              <VMargin />
              <TrimaButton variant="contained" onClick={gotoEdit}>
                配信期間・予算上限の変更
              </TrimaButton>
            </FormBlock>
            <FormBlock style={styles.hint}>
              <Text>
                配信内容の変更は「作成済みの広告」画面で、この配信で使用している広告を選択し、編集ボタンを押してください。
              </Text>
            </FormBlock>
          </View>
        </View>
        {showDelete && (
          <MaxWidth maxWidth={586} style={CommonStyles.margin.all}>
            <CreativeSettings.Stop target={detailTarget} onDone={goBack} />
          </MaxWidth>
        )}
      </MaxWidth>
    </Container>
  );
});

const styles = StyleSheet.create({
  used: {
    backgroundColor: Colors.base,
    ...CommonStyles.padding.all,
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.center,
  },
  count: {
    width: 120,
    textAlign: 'center',
  },
  hint: {
    backgroundColor: Colors.base,
  },
});
