import {Auth} from 'aws-amplify';
import axios from 'axios';
import dayjs from 'dayjs';
import {SaaSCoupon as SaaSCouponEntity} from '../../API';
import {listCoupon} from '../../graphql/queries';
import {AppConfig} from '../config/AppConfig';
import {GraphQlRepository} from './GraphQlRepository';
import {SaaSImageContents, SaaSRepository} from './SaaSRepository';
const TAG = 'SaaSCouponRepository';

export const CouponTypes = {
  byPrice: 'BY_PRICE',
  byPercent: 'BY_PERCENT',
  fromPrice: 'FROM_PRICE',
  byHalf: 'BY_HALF',
  byFree: 'BY_FREE',
  byText: 'BY_TEXT',
};

export type SaaSCouponDiscount = {
  discount: string;
  params: string[];
};

export type SaaSCouponKey = {
  id: string;
  name: string;
};

export type SaaSCouponDefine = {
  name: string;
  title: string;
  rule?: string;
  text?: string;
  usage: string;
  discountType: string;
  byPrice?: number;
  byPercent?: number;
  priceFrom?: number;
  priceTo?: number;
  freeText?: string;
  editState: string;
  shopId: string;
  ownerId: string;
  cvLinkUrl?: string;
  cvLinkText?: string;
  cvPhone?: string;
};

export type SaaSCoupon = {
  def: SaaSCouponDefine;
  images: SaaSImageContents[];
  barcode: string;
};

export class SaaSCouponRepository {
  static async post(coupon: SaaSCoupon): Promise<any> {
    console.log(TAG, coupon);

    const data = new FormData();

    data.append('json', new Blob([JSON.stringify(coupon.def)]), 'coupon');
    await Promise.all(
      coupon.images.map(async (image) => {
        const blob = new Blob([image.uri]);
        data.append('images', blob, image.key);
      }),
    );
    if (coupon.barcode) {
      const barcodeBlob = new Blob([coupon.barcode]);
      data.append('barcode', barcodeBlob, 'barcode');
    }

    const config = await SaaSRepository.formAxioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.CouponEndPoint}`,
      data,
      config,
    );

    console.log(TAG, 'upload', result);

    return result;
  }

  static async put(coupon: SaaSCoupon, origin: any): Promise<any> {
    console.log(TAG, coupon);

    const data = new FormData();

    data.append('json', new Blob([JSON.stringify(coupon.def)]), 'coupon');
    await Promise.all(
      coupon.images.map(async (image) => {
        const blob = new Blob([image.uri]);
        data.append('images', blob, image.key);
      }),
    );
    if (coupon.barcode) {
      const barcodeBlob = new Blob([coupon.barcode]);
      data.append('barcode', barcodeBlob, 'barcode');
    }

    const config = await SaaSRepository.formAxioConfig();

    const result = await axios.put(
      `${AppConfig.SaaSBackend}${AppConfig.CouponEndPoint}/${origin.id}`,
      data,
      config,
    );

    console.log(TAG, 'upload', result);

    return result;
  }

  static async remove(id: string): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.delete(
      `${AppConfig.SaaSBackend}${AppConfig.CouponEndPoint}/${id}`,
      config,
    );

    console.log(TAG, 'delete', result);
    return result;
  }

  static async archive(id: string): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.put(
      `${AppConfig.SaaSBackend}${AppConfig.CouponEndPoint}/${id}/archive`,
      {},
      config,
    );

    console.log(TAG, 'archive', result);
    return result;
  }

  static async queryCoupons(shopId: string): Promise<SaaSCouponEntity[]> {
    const user = await Auth.currentUserPoolUser();
    console.log(TAG, 'user', user);

    const result = await GraphQlRepository.query(listCoupon, 'listCoupon', {
      shopId,
      sortDirection: 'DESC',
    });

    return result;
  }

  static async getCouponCount(id: string): Promise<{
    couponId: string;
    deliveries: number;
    users: number;
  }> {
    console.log(TAG, '[getCouponCount]', id);

    const config = await SaaSRepository.axioConfig();
    const random = dayjs().unix();
    const result = await axios.get(
      `${AppConfig.SaaSBackend}${AppConfig.CouponCountEndPoint}/${id}/${random}`,
      config,
    );

    console.log(TAG, '[getCouponCount] result', result);
    const data = result.data;
    if (data) {
      const couponId = data.couponId;
      const deliveries = Number(data.deliveries);
      const users = Number(data.users);
      return {couponId, deliveries, users};
    }

    throw '[getCouponCount] field error';
  }
}
