import {Box} from '@mui/material';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {Container} from '../../components';
import {SignInContainer} from '../../components/Auth/SignInContainer';
import {Header, TrimaButton} from '../../components/Elements';
import {AccountMenu} from '../../navigation/AccountMenu';
import {useOwnerSelector} from '../../navigation/OwnerSelector';
import {CommonStyles} from '../../theme';

const Main: React.FC = () => {
  const {show} = useOwnerSelector();
  const {prevRoute, setRoute} = SignInContainer.useContainer();
  const navigation = useNavigation();
  React.useEffect(() => {
    if (prevRoute) {
      setRoute(undefined);
      navigation.navigate(prevRoute.name, {...prevRoute.params});
    } else {
      show({addButton: true});
    }
  }, [show, prevRoute, navigation, setRoute]);
  return (
    <Container>
      <Header rightItem={<AccountMenu />} />
      <View style={[CommonStyles.flex.center, CommonStyles.margin.top]}>
        <Box sx={{width: 568}}>
          <TrimaButton
            variant="contained"
            onClick={() => show({addButton: true})}
          >
            企業アカウントを選択して下さい
          </TrimaButton>
        </Box>
      </View>
    </Container>
  );
};

export const SelectOwner = {
  Main,
};
