import React from 'react';
import {Image, Linking, StyleSheet, View} from 'react-native';
import agency_error from '../../assets/agency_error.svg';
import {CommonStyles} from '../../theme';
import {FormBlock, Logo, MaxWidth, Text, VMargin} from '../Elements';
import {useResponsive} from '../Responsive';

export const AgencyError: React.FC = () => {
  const {isDesktop} = useResponsive();
  const height = isDesktop ? 120 : 70;
  const scale = 3.65;
  const trimaWidth = 44.93 * scale;
  const trimaHeight = 37.5 * scale;

  return (
    <View>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <View style={styles.title}>
          <Logo height={height} />
        </View>
        <VMargin />
        <MaxWidth maxWidth={586}>
          <FormBlock>
            <Text style={CommonStyles.text.center}>
              このページはご利用いただけません
            </Text>
            <VMargin />
            <View style={styles.title}>
              <Image
                source={{
                  uri: agency_error,
                  width: trimaWidth,
                  height: trimaHeight,
                }}
              />
            </View>

            <VMargin />
            <Text>このページで問題が発生しています。</Text>
            <VMargin />
            <Text>可能性のある原因</Text>
            <Text>・リンクに問題がある</Text>
            <Text>・一時的にアクセスできない状況にある</Text>
            <VMargin />
            <Text>
              再読み込みをしても解決しない場合は
              <Text
                style={CommonStyles.linkText}
                onPress={() =>
                  Linking.openURL(
                    'https://support.shop.trip-mile.com/hc/ja/requests/new',
                  )
                }
              >
                こちら
              </Text>
              からお問い合わせをお願いいたします。
            </Text>
          </FormBlock>
          <VMargin />
        </MaxWidth>
      </MaxWidth>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    alignItems: 'center',
    ...CommonStyles.padding.all,
  },
});
