import {useState} from 'react';
import {createContainer} from 'unstated-next';
import {LngLat} from '../../services/ZipAddressRepository';

type MapState = {
  center: LngLat | null;
  setCenter: any;
};

export function useMap(): MapState {
  const [center, setCenter] = useState(null);

  return {
    center,
    setCenter,
  };
}

export const MapContainer = createContainer(useMap);
