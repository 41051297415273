import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSTargetRepository} from '../../_proto/services/SaaSTargetRepository';

type SearchListItem = {
  id: string;
  name: string;
  prefs: Array<{
    prefCode: string;
    prefName: string;
    cities: Array<{
      id: string;
      cityName: string;
      prefCode: string;
      prefName: string;
    }>;
  }>;
};

const useCitySearchContainer = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<Array<SearchListItem>>([]);
  const [word, setWord] = useState<string>('');
  const [error, setError] = useState<Error | undefined>();
  const [isClear, setIsClear] = useState<boolean>(false);

  const search = useCallback(async (data: {word: string}) => {
    setLoading(true);
    setError(undefined);
    setIsClear(false);

    try {
      setWord(data.word);
      const result = await SaaSTargetRepository.searchCityPoiByName(data.word);
      setList(
        result.map((item) => {
          const prefs = item.prefs.map((pref) => {
            const cities = pref.cities.map((city) => {
              const {id, name, prefCode} = city;
              return {id, prefCode, cityName: name, prefName: pref.name};
            });
            return {prefCode: pref.id, prefName: pref.name, cities};
          });
          return {...item, prefs};
        }),
      );
    } catch (err: any) {
      console.log(err.errors[0].message);
      setError(new Error(err.errors[0].message));
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const clearSearch = useCallback(() => {
    setIsClear(true);
    setWord('');
    setList([]);
  }, []);

  return {
    isLoading,
    error,
    list,
    word,
    search,
    clearSearch,
    isClear,
  };
};

export const CitySearchContainer = createContainer(useCitySearchContainer);
