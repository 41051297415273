import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {graphQLService} from '../service';

const useShopsContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [shopList, setShopList] = useState<any[]>([]);
  const [error, setError] = useState<Error | undefined>();
  const [shopId, setShopId] = useState<string | undefined>();

  const fetchShops = useCallback(async (ownerId: string) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const shops = await graphQLService.getShopList(ownerId);
      setShopList(shops);
      return shops;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const setGroup2Shop = useCallback(
    async ({
      id,
      groupId,
      ownerId,
    }: {
      id: string;
      groupId?: string | null;
      ownerId?: string;
    }) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        const updateShop = await graphQLService.setGroup2Shop({
          id,
          groupId,
          ownerId,
        });
        console.log('shopList', shopList);
        const newShops = shopList.map((shop) => {
          if (shop.id === id) {
            return updateShop;
          } else {
            return shop;
          }
        });
        console.log('newShops', newShops);
        setShopList(newShops);
        return updateShop;
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [shopList],
  );

  const unsetGroup2Shop = useCallback(
    async ({id}: {id: string}) => {
      const newShops = shopList.map((shop) => {
        if (shop.groupId === id) {
          return {...shop, groupId: null};
        } else {
          return shop;
        }
      });
      console.log('newShops', newShops);
      setShopList(newShops);
    },
    [shopList],
  );

  return {
    isCreateLoading,
    error,
    shopList,
    setShopList,
    fetchShops,
    shopId,
    setShopId,
    setGroup2Shop,
    unsetGroup2Shop,
  };
};

export const ShopsContainer = createContainer(useShopsContainer);
