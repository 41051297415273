import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import * as yup from 'yup';
import {DeleteSaaSShopGroupInput, UpdateSaaSShopGroupInput} from '../API';
import {yupUtil} from '../components/Elements';
import {graphQLService} from '../service';

export type ShopGroupFormData = {
  name: string;
};

export const schemaShopGroupFormData: yup.SchemaOf<ShopGroupFormData> = yup
  .object()
  .shape({
    name: yupUtil.stringRequired(30),
  });

const useShopGroupsContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [shopGroups, setShopGroups] = useState<Array<any>>([]);
  const [error, setError] = useState<Error | undefined>();
  const [selectedShopGroup, setSelectedShopGroup] = useState<any | undefined>(
    shopGroups.length < 2 ? shopGroups[0] : undefined,
  );

  const create = useCallback(
    async (
      data: ShopGroupFormData & {
        ownerId: string;
      },
    ) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        const result = await graphQLService.createShopGroup(data);
        console.log('result', result);
        setShopGroups([result, ...shopGroups]);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [shopGroups],
  );

  const update = useCallback(
    async (data: UpdateSaaSShopGroupInput) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        const result = await graphQLService.updateShopGroup(data);
        console.log('result', result);
        const newShopGroup = shopGroups.map((shopGroup) => {
          if (shopGroup.id === result.id) {
            return result;
          } else {
            return shopGroup;
          }
        });
        console.log('newShopGroup', newShopGroup);
        setShopGroups([...newShopGroup]);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [shopGroups],
  );

  const remove = useCallback(
    async (data: DeleteSaaSShopGroupInput) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        const result = await graphQLService.deleteShopGroup(data);
        console.log('result', result);
        console.log('shopGroups', shopGroups);
        const newShopGroup = shopGroups.filter((shopGroup) => {
          return shopGroup.id !== result.id;
        });
        setShopGroups(newShopGroup);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [shopGroups],
  );

  const fetchShopGroups = useCallback(async (id: string) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = await graphQLService.getShopGroupList(id);
      setShopGroups(result);
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const setShopGroup = useCallback(
    async (id: string | null) => {
      const found = shopGroups.find((shopGroup) => shopGroup.id === id);
      console.log('id', id);
      console.log('shopGroups', shopGroups);
      console.log('found', found);
      setSelectedShopGroup(found);
    },
    [shopGroups],
  );

  return {
    isCreateLoading,
    shopGroups,
    error,
    create,
    update,
    remove,
    fetchShopGroups,
    setShopGroup,
    selectedShopGroup,
  };
};

export const ShopGroupsContainer = createContainer(useShopGroupsContainer);
