import {
  AgeReportDto,
  DailyVisitorReportDto,
  FollowerReportDto,
  ShopVisitorResponse,
  SingleMeshCountDto,
} from '../../service';

export const DUMMY_MALE: AgeReportDto[] = [
  {range: 1, count: 40},
  {range: 2, count: 30},
  {range: 3, count: 20},
  {range: 4, count: 20},
  {range: 5, count: 30},
  {range: 6, count: 20},
  {range: 7, count: 10},
];

export const DUMMY_FEMALE: AgeReportDto[] = [
  {range: 1, count: 24},
  {range: 2, count: 13},
  {range: 3, count: 33},
  {range: 4, count: 43},
  {range: 5, count: 13},
  {range: 6, count: 23},
  {range: 7, count: 13},
];

export const DUMMY_AGE_USER = {
  male: DUMMY_MALE,
  female: DUMMY_FEMALE,
};

export const DUMMY_VISITOR_REPORTS: DailyVisitorReportDto[] = [
  {stamp: '2021-06-01', visitor: 301, repeater: 27},
  {stamp: '2021-06-02', visitor: 346, repeater: 13},
  {stamp: '2021-06-03', visitor: 334, repeater: 11},
  {stamp: '2021-06-04', visitor: 215, repeater: 18},
  {stamp: '2021-06-05', visitor: 274, repeater: 17},
  {stamp: '2021-06-06', visitor: 202, repeater: 20},
  {stamp: '2021-06-07', visitor: 311, repeater: 6},
  {stamp: '2021-06-08', visitor: 178, repeater: 6},
  {stamp: '2021-06-09', visitor: 254, repeater: 3},
  {stamp: '2021-06-10', visitor: 182, repeater: 0},
  {stamp: '2021-06-11', visitor: 286, repeater: 9},
  {stamp: '2021-06-12', visitor: 170, repeater: 19},
  {stamp: '2021-06-13', visitor: 170, repeater: 8},
  {stamp: '2021-06-14', visitor: 115, repeater: 19},
  {stamp: '2021-06-15', visitor: 311, repeater: 13},
  {stamp: '2021-06-16', visitor: 67, repeater: 25},
  {stamp: '2021-06-17', visitor: 66, repeater: 8},
  {stamp: '2021-06-18', visitor: 109, repeater: 2},
  {stamp: '2021-06-19', visitor: 346, repeater: 29},
  {stamp: '2021-06-20', visitor: 152, repeater: 26},
  {stamp: '2021-06-21', visitor: 265, repeater: 7},
  {stamp: '2021-06-22', visitor: 78, repeater: 0},
  {stamp: '2021-06-23', visitor: 230, repeater: 15},
  {stamp: '2021-06-24', visitor: 68, repeater: 21},
  {stamp: '2021-06-25', visitor: 293, repeater: 6},
  {stamp: '2021-06-26', visitor: 176, repeater: 24},
  {stamp: '2021-06-27', visitor: 207, repeater: 11},
  {stamp: '2021-06-28', visitor: 81, repeater: 14},
  {stamp: '2021-06-29', visitor: 334, repeater: 5},
  {stamp: '2021-06-30', visitor: 272, repeater: 8},
  {stamp: '2021-07-01', visitor: 342, repeater: 5},
  {stamp: '2021-07-02', visitor: 120, repeater: 0},
  {stamp: '2021-07-03', visitor: 108, repeater: 6},
  {stamp: '2021-07-04', visitor: 63, repeater: 15},
  {stamp: '2021-07-05', visitor: 252, repeater: 7},
  {stamp: '2021-07-06', visitor: 63, repeater: 8},
  {stamp: '2021-07-07', visitor: 290, repeater: 27},
  {stamp: '2021-07-08', visitor: 95, repeater: 23},
  {stamp: '2021-07-09', visitor: 328, repeater: 20},
  {stamp: '2021-07-10', visitor: 180, repeater: 23},
  {stamp: '2021-07-11', visitor: 309, repeater: 0},
  {stamp: '2021-07-12', visitor: 324, repeater: 25},
  {stamp: '2021-07-13', visitor: 136, repeater: 27},
  {stamp: '2021-07-14', visitor: 108, repeater: 11},
  {stamp: '2021-07-15', visitor: 176, repeater: 17},
  {stamp: '2021-07-16', visitor: 185, repeater: 6},
  {stamp: '2021-07-17', visitor: 230, repeater: 20},
  {stamp: '2021-07-18', visitor: 132, repeater: 28},
  {stamp: '2021-07-19', visitor: 159, repeater: 11},
  {stamp: '2021-07-20', visitor: 231, repeater: 3},
  {stamp: '2021-07-21', visitor: 145, repeater: 29},
  {stamp: '2021-07-22', visitor: 289, repeater: 1},
  {stamp: '2021-07-23', visitor: 57, repeater: 11},
  {stamp: '2021-07-24', visitor: 123, repeater: 25},
  {stamp: '2021-07-25', visitor: 178, repeater: 11},
  {stamp: '2021-07-26', visitor: 107, repeater: 27},
  {stamp: '2021-07-27', visitor: 52, repeater: 16},
  {stamp: '2021-07-28', visitor: 200, repeater: 7},
  {stamp: '2021-07-29', visitor: 199, repeater: 27},
  {stamp: '2021-07-30', visitor: 177, repeater: 11},
  {stamp: '2021-08-01', visitor: 205, repeater: 1},
  {stamp: '2021-08-02', visitor: 121, repeater: 7},
  {stamp: '2021-08-03', visitor: 176, repeater: 14},
  {stamp: '2021-08-04', visitor: 109, repeater: 23},
  {stamp: '2021-08-05', visitor: 272, repeater: 17},
  {stamp: '2021-08-06', visitor: 185, repeater: 18},
  {stamp: '2021-08-07', visitor: 103, repeater: 28},
  {stamp: '2021-08-08', visitor: 66, repeater: 14},
  {stamp: '2021-08-09', visitor: 248, repeater: 19},
  {stamp: '2021-08-10', visitor: 272, repeater: 15},
  {stamp: '2021-08-11', visitor: 308, repeater: 29},
  {stamp: '2021-08-12', visitor: 149, repeater: 4},
  {stamp: '2021-08-13', visitor: 101, repeater: 17},
  {stamp: '2021-08-14', visitor: 186, repeater: 7},
  {stamp: '2021-08-15', visitor: 301, repeater: 10},
  {stamp: '2021-08-16', visitor: 125, repeater: 16},
  {stamp: '2021-08-17', visitor: 205, repeater: 13},
  {stamp: '2021-08-18', visitor: 71, repeater: 28},
  {stamp: '2021-08-19', visitor: 243, repeater: 25},
  {stamp: '2021-08-20', visitor: 120, repeater: 5},
  {stamp: '2021-08-21', visitor: 276, repeater: 15},
  {stamp: '2021-08-22', visitor: 144, repeater: 25},
  {stamp: '2021-08-23', visitor: 196, repeater: 2},
  {stamp: '2021-08-24', visitor: 59, repeater: 17},
  {stamp: '2021-08-25', visitor: 283, repeater: 29},
  {stamp: '2021-08-26', visitor: 336, repeater: 0},
  {stamp: '2021-08-27', visitor: 51, repeater: 8},
  {stamp: '2021-08-28', visitor: 125, repeater: 23},
  {stamp: '2021-08-29', visitor: 153, repeater: 15},
  {stamp: '2021-08-30', visitor: 84, repeater: 1},
  {stamp: '2021-09-01', visitor: 79, repeater: 1},
  {stamp: '2021-09-02', visitor: 336, repeater: 4},
  {stamp: '2021-09-03', visitor: 120, repeater: 11},
  {stamp: '2021-09-04', visitor: 129, repeater: 23},
  {stamp: '2021-09-05', visitor: 337, repeater: 29},
  {stamp: '2021-09-06', visitor: 88, repeater: 16},
  {stamp: '2021-09-07', visitor: 113, repeater: 27},
  {stamp: '2021-09-08', visitor: 340, repeater: 1},
  {stamp: '2021-09-09', visitor: 261, repeater: 0},
  {stamp: '2021-09-10', visitor: 317, repeater: 20},
  {stamp: '2021-09-11', visitor: 328, repeater: 17},
  {stamp: '2021-09-12', visitor: 340, repeater: 2},
  {stamp: '2021-09-13', visitor: 202, repeater: 20},
  {stamp: '2021-09-14', visitor: 278, repeater: 5},
  {stamp: '2021-09-15', visitor: 79, repeater: 28},
  {stamp: '2021-09-16', visitor: 248, repeater: 9},
  {stamp: '2021-09-17', visitor: 96, repeater: 21},
  {stamp: '2021-09-18', visitor: 203, repeater: 13},
  {stamp: '2021-09-19', visitor: 109, repeater: 29},
  {stamp: '2021-09-20', visitor: 150, repeater: 4},
  {stamp: '2021-09-21', visitor: 143, repeater: 24},
  {stamp: '2021-09-22', visitor: 104, repeater: 22},
  {stamp: '2021-09-23', visitor: 185, repeater: 25},
  {stamp: '2021-09-24', visitor: 320, repeater: 9},
  {stamp: '2021-09-25', visitor: 86, repeater: 24},
  {stamp: '2021-09-26', visitor: 233, repeater: 9},
  {stamp: '2021-09-27', visitor: 251, repeater: 25},
  {stamp: '2021-09-28', visitor: 293, repeater: 14},
  {stamp: '2021-09-29', visitor: 240, repeater: 3},
  {stamp: '2021-09-30', visitor: 308, repeater: 11},
];

export const DUMMY_FOLLOWER_REPORT: FollowerReportDto[] = [
  {stamp: '2021-06-01', follower: 20, followed: 16, unfollowed: 2},
  {stamp: '2021-06-02', follower: 34, followed: 16, unfollowed: 0},
  {stamp: '2021-06-03', follower: 50, followed: 6, unfollowed: 12},
  {stamp: '2021-06-04', follower: 44, followed: 3, unfollowed: 3},
  {stamp: '2021-06-05', follower: 44, followed: 10, unfollowed: 10},
  {stamp: '2021-06-06', follower: 44, followed: 13, unfollowed: 3},
  {stamp: '2021-06-07', follower: 54, followed: 15, unfollowed: 5},
  {stamp: '2021-06-08', follower: 64, followed: 17, unfollowed: 4},
  {stamp: '2021-06-09', follower: 77, followed: 13, unfollowed: 11},
  {stamp: '2021-06-10', follower: 79, followed: 5, unfollowed: 2},
  {stamp: '2021-06-11', follower: 82, followed: 17, unfollowed: 7},
  {stamp: '2021-06-12', follower: 92, followed: 19, unfollowed: 10},
  {stamp: '2021-06-13', follower: 101, followed: 15, unfollowed: 14},
  {stamp: '2021-06-14', follower: 102, followed: 16, unfollowed: 2},
  {stamp: '2021-06-15', follower: 116, followed: 4, unfollowed: 11},
  {stamp: '2021-06-16', follower: 109, followed: 13, unfollowed: 9},
  {stamp: '2021-06-17', follower: 113, followed: 3, unfollowed: 0},
  {stamp: '2021-06-18', follower: 116, followed: 22, unfollowed: 12},
  {stamp: '2021-06-19', follower: 126, followed: 11, unfollowed: 4},
  {stamp: '2021-06-20', follower: 133, followed: 5, unfollowed: 3},
  {stamp: '2021-06-21', follower: 135, followed: 13, unfollowed: 13},
  {stamp: '2021-06-22', follower: 135, followed: 21, unfollowed: 12},
  {stamp: '2021-06-23', follower: 144, followed: 15, unfollowed: 14},
  {stamp: '2021-06-24', follower: 145, followed: 20, unfollowed: 1},
  {stamp: '2021-06-25', follower: 164, followed: 15, unfollowed: 1},
  {stamp: '2021-06-26', follower: 178, followed: 21, unfollowed: 9},
  {stamp: '2021-06-27', follower: 190, followed: 21, unfollowed: 2},
  {stamp: '2021-06-28', follower: 209, followed: 8, unfollowed: 1},
  {stamp: '2021-06-29', follower: 216, followed: 10, unfollowed: 9},
  {stamp: '2021-06-30', follower: 217, followed: 20, unfollowed: 9},
  {stamp: '2021-07-01', follower: 228, followed: 16, unfollowed: 10},
  {stamp: '2021-07-02', follower: 234, followed: 14, unfollowed: 11},
  {stamp: '2021-07-03', follower: 237, followed: 10, unfollowed: 7},
  {stamp: '2021-07-04', follower: 240, followed: 16, unfollowed: 14},
  {stamp: '2021-07-05', follower: 242, followed: 12, unfollowed: 2},
  {stamp: '2021-07-06', follower: 252, followed: 3, unfollowed: 14},
  {stamp: '2021-07-07', follower: 241, followed: 9, unfollowed: 11},
  {stamp: '2021-07-08', follower: 239, followed: 6, unfollowed: 3},
  {stamp: '2021-07-09', follower: 242, followed: 7, unfollowed: 14},
  {stamp: '2021-07-10', follower: 235, followed: 19, unfollowed: 4},
  {stamp: '2021-07-11', follower: 250, followed: 8, unfollowed: 6},
  {stamp: '2021-07-12', follower: 252, followed: 22, unfollowed: 3},
  {stamp: '2021-07-13', follower: 271, followed: 12, unfollowed: 9},
  {stamp: '2021-07-14', follower: 274, followed: 4, unfollowed: 11},
  {stamp: '2021-07-15', follower: 267, followed: 18, unfollowed: 8},
  {stamp: '2021-07-16', follower: 277, followed: 3, unfollowed: 10},
  {stamp: '2021-07-17', follower: 270, followed: 17, unfollowed: 8},
  {stamp: '2021-07-18', follower: 279, followed: 15, unfollowed: 0},
  {stamp: '2021-07-19', follower: 294, followed: 7, unfollowed: 3},
  {stamp: '2021-07-20', follower: 298, followed: 13, unfollowed: 14},
  {stamp: '2021-07-21', follower: 297, followed: 20, unfollowed: 4},
  {stamp: '2021-07-22', follower: 313, followed: 10, unfollowed: 14},
  {stamp: '2021-07-23', follower: 309, followed: 7, unfollowed: 7},
  {stamp: '2021-07-24', follower: 309, followed: 22, unfollowed: 13},
  {stamp: '2021-07-25', follower: 318, followed: 12, unfollowed: 14},
  {stamp: '2021-07-26', follower: 316, followed: 22, unfollowed: 9},
  {stamp: '2021-07-27', follower: 329, followed: 20, unfollowed: 13},
  {stamp: '2021-07-28', follower: 336, followed: 22, unfollowed: 10},
  {stamp: '2021-07-29', follower: 348, followed: 6, unfollowed: 12},
  {stamp: '2021-07-30', follower: 342, followed: 5, unfollowed: 7},
  {stamp: '2021-07-31', follower: 340, followed: 17, unfollowed: 1},
  {stamp: '2021-08-01', follower: 356, followed: 18, unfollowed: 7},
  {stamp: '2021-08-02', follower: 367, followed: 7, unfollowed: 13},
  {stamp: '2021-08-03', follower: 361, followed: 12, unfollowed: 3},
  {stamp: '2021-08-04', follower: 370, followed: 5, unfollowed: 10},
  {stamp: '2021-08-05', follower: 365, followed: 13, unfollowed: 5},
  {stamp: '2021-08-06', follower: 373, followed: 9, unfollowed: 12},
  {stamp: '2021-08-07', follower: 370, followed: 8, unfollowed: 0},
  {stamp: '2021-08-08', follower: 378, followed: 13, unfollowed: 11},
  {stamp: '2021-08-09', follower: 380, followed: 10, unfollowed: 7},
  {stamp: '2021-08-10', follower: 383, followed: 9, unfollowed: 9},
  {stamp: '2021-08-11', follower: 383, followed: 3, unfollowed: 4},
  {stamp: '2021-08-12', follower: 382, followed: 4, unfollowed: 9},
  {stamp: '2021-08-13', follower: 377, followed: 18, unfollowed: 7},
  {stamp: '2021-08-14', follower: 388, followed: 3, unfollowed: 13},
  {stamp: '2021-08-15', follower: 378, followed: 7, unfollowed: 10},
  {stamp: '2021-08-16', follower: 375, followed: 9, unfollowed: 1},
  {stamp: '2021-08-17', follower: 383, followed: 20, unfollowed: 4},
  {stamp: '2021-08-18', follower: 399, followed: 21, unfollowed: 3},
  {stamp: '2021-08-19', follower: 417, followed: 18, unfollowed: 0},
  {stamp: '2021-08-20', follower: 435, followed: 13, unfollowed: 8},
  {stamp: '2021-08-21', follower: 440, followed: 15, unfollowed: 9},
  {stamp: '2021-08-22', follower: 446, followed: 3, unfollowed: 11},
  {stamp: '2021-08-23', follower: 438, followed: 14, unfollowed: 9},
  {stamp: '2021-08-24', follower: 443, followed: 5, unfollowed: 2},
  {stamp: '2021-08-25', follower: 446, followed: 8, unfollowed: 8},
  {stamp: '2021-08-26', follower: 446, followed: 20, unfollowed: 3},
  {stamp: '2021-08-27', follower: 463, followed: 19, unfollowed: 3},
  {stamp: '2021-08-28', follower: 479, followed: 5, unfollowed: 3},
  {stamp: '2021-08-29', follower: 481, followed: 12, unfollowed: 13},
  {stamp: '2021-08-30', follower: 480, followed: 19, unfollowed: 2},
  {stamp: '2021-08-31', follower: 497, followed: 20, unfollowed: 5},
  {stamp: '2021-09-01', follower: 512, followed: 16, unfollowed: 3},
  {stamp: '2021-09-02', follower: 525, followed: 15, unfollowed: 9},
  {stamp: '2021-09-03', follower: 531, followed: 18, unfollowed: 9},
  {stamp: '2021-09-04', follower: 540, followed: 7, unfollowed: 5},
  {stamp: '2021-09-05', follower: 542, followed: 11, unfollowed: 0},
  {stamp: '2021-09-06', follower: 553, followed: 4, unfollowed: 5},
  {stamp: '2021-09-07', follower: 552, followed: 4, unfollowed: 2},
  {stamp: '2021-09-08', follower: 554, followed: 4, unfollowed: 13},
  {stamp: '2021-09-09', follower: 545, followed: 4, unfollowed: 13},
  {stamp: '2021-09-10', follower: 536, followed: 18, unfollowed: 2},
  {stamp: '2021-09-11', follower: 552, followed: 21, unfollowed: 14},
  {stamp: '2021-09-12', follower: 559, followed: 13, unfollowed: 6},
  {stamp: '2021-09-13', follower: 566, followed: 8, unfollowed: 4},
  {stamp: '2021-09-14', follower: 570, followed: 10, unfollowed: 3},
  {stamp: '2021-09-15', follower: 577, followed: 20, unfollowed: 4},
  {stamp: '2021-09-16', follower: 593, followed: 5, unfollowed: 1},
  {stamp: '2021-09-17', follower: 597, followed: 3, unfollowed: 8},
  {stamp: '2021-09-18', follower: 592, followed: 20, unfollowed: 10},
  {stamp: '2021-09-19', follower: 602, followed: 5, unfollowed: 0},
  {stamp: '2021-09-20', follower: 607, followed: 4, unfollowed: 7},
  {stamp: '2021-09-21', follower: 604, followed: 20, unfollowed: 6},
  {stamp: '2021-09-22', follower: 618, followed: 17, unfollowed: 6},
  {stamp: '2021-09-23', follower: 629, followed: 6, unfollowed: 4},
  {stamp: '2021-09-24', follower: 631, followed: 12, unfollowed: 2},
  {stamp: '2021-09-25', follower: 641, followed: 20, unfollowed: 14},
  {stamp: '2021-09-26', follower: 647, followed: 8, unfollowed: 7},
  {stamp: '2021-09-27', follower: 648, followed: 18, unfollowed: 3},
  {stamp: '2021-09-28', follower: 663, followed: 13, unfollowed: 5},
  {stamp: '2021-09-29', follower: 671, followed: 20, unfollowed: 9},
  {stamp: '2021-09-30', follower: 682, followed: 16, unfollowed: 0},
];

const RESIDENCE_MESHES: SingleMeshCountDto[] = [
  {mesh: '533945691', count: 5},
  {mesh: '533945692', count: 11},
  {mesh: '533945693', count: 33},
  {mesh: '533945694', count: 51},
  {mesh: '533945791', count: 100},
  {mesh: '533945792', count: 81},
  {mesh: '533945793', count: 75},
  {mesh: '533945794', count: 62},
];

const OFFICE_MESHES: SingleMeshCountDto[] = [
  {mesh: '533936731', count: 5},
  {mesh: '533936732', count: 11},
  {mesh: '533936733', count: 33},
  {mesh: '533936734', count: 51},
  {mesh: '533936831', count: 100},
  {mesh: '533936832', count: 81},
  {mesh: '533936833', count: 75},
  {mesh: '533936834', count: 62},
];

export const DUMMY_VISITOR_RESPONSE: ShopVisitorResponse = {
  visitor: {
    visitor: 1234,
    repeater: 56,
    reports: DUMMY_VISITOR_REPORTS,
  },
  user: {
    male: DUMMY_MALE,
    female: DUMMY_FEMALE,
    other: [],
  },
  mesh: {
    residence: RESIDENCE_MESHES,
    office: OFFICE_MESHES,
  },
  follower: DUMMY_FOLLOWER_REPORT,
};
