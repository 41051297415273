import {SaaSTargetRepository} from '../../../../_proto/services/SaaSTargetRepository';
import _ from 'lodash';
import {MasterCode, Station, StationCode, TrainLine} from '../../types';

/**
 * 新コードかどうか
 * @param id 駅のID
 */
export function isMasterCode(id: string): boolean {
  return id.length === 8;
}

// 駅コード → 鉄道区分コード
export function getCategoryCode(code: StationCode): StationCode {
  return code.substring(0, 2);
}

// 駅コード → 路線コード
export function getTrainLineCodeFromStationCode(
  code: StationCode,
): StationCode {
  return code.substring(0, 8);
}

const STATION_NAME_SEPARATOR = '-';

// 駅コード＆駅名のダンプ
export function dumpStation(code: StationCode, name?: string): string {
  return name ? `${code}${STATION_NAME_SEPARATOR}${name}` : code;
}

// ダンプから駅コード、駅名取り出し
export function splitStationDump(stationDump: string): {
  code: StationCode | MasterCode;
  name: string;
} {
  const arr = stationDump.split(STATION_NAME_SEPARATOR);
  const code = arr.shift() ?? '';
  return {code, name: arr.join('')};
}

export async function getTrainLineList(
  categoryId: string,
): Promise<TrainLine[]> {
  const result = (await SaaSTargetRepository.fetchStationChild(
    categoryId,
  )) as Partial<TrainLine>[];
  return result
    .map((value) => _.pick(value, ['id', 'name', 'parent', 'mimi']))
    .filter((t) => t && t.id && t.name) as TrainLine[];
}

export async function getStationList(lineId: string): Promise<Station[]> {
  const result = (await SaaSTargetRepository.fetchStationChild(
    lineId,
  )) as Partial<Station>[];
  return result
    .map((value) => _.pick(value, ['id', 'name', 'parent', 'routeId']))
    .filter((s) => s && s.id && s.name) as Station[];
}
