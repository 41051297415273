import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImageManipulator from 'expo-image-manipulator';
import * as Picker from 'expo-image-picker';
import React from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Image, StyleSheet, View, ViewStyle} from 'react-native';
import {Checkbox, Text, TouchableRipple, useTheme} from 'react-native-paper';
import image5575 from '../../assets/coupon/5575.png';
import image5576 from '../../assets/coupon/5576.png';
import image5577 from '../../assets/coupon/5577.png';
import image5582 from '../../assets/coupon/5582.png';
import image5583 from '../../assets/coupon/5583.png';
import image5584 from '../../assets/coupon/5584.png';
import {CommonStyles} from '../../theme';
import {
  DialogBase,
  FontAwesomeIcon,
  MaxWidth,
  Menu,
  Sheet,
  TrimaButton,
  VMargin,
} from '../Elements';
import {useHeight} from '../Responsive';
import {ImagePickerContainer} from './container';

// 一部の実装はWebでしか動かないので、本来はweb.tsxと整理したほうがいい。

const MAX_PX = 1456;
const ASPECT_DEFAULT = 4 / 3;
const thumbnailSize = {width: 120, height: 90};

export const ImagePicker: React.FC<{
  index?: number;
  image?: {key: string; uri: string};
  disabled?: boolean;
  size?: {width: number; height: number};
  style?: ViewStyle;
  aspect?: number;
  maxPx?: number;
  onPicked(uri: string): void;
  onRemove?(): void;
  onChange?(uri: string, index?: number): void;
  showDialog?: boolean;
}> = ({
  index,
  image,
  disabled,
  size,
  style,
  aspect = ASPECT_DEFAULT,
  maxPx = MAX_PX,
  onPicked,
  onRemove,
  onChange,
  showDialog = true,
}) => {
  return (
    <View style={[styles.size, style, size]}>
      {image && (
        <Thumbnail
          index={index}
          image={image}
          size={size}
          maxPx={maxPx}
          aspect={aspect}
          onRemove={onRemove}
          onChange={onChange}
        />
      )}
      {!image && index !== undefined && disabled && (
        <LabeledBox index={index} />
      )}
      {!image && !disabled && (
        <ImagePickerContainer.Provider>
          <PickButton
            onPicked={onPicked}
            maxPx={maxPx}
            aspect={aspect}
            mode={'add'}
            showDialog={showDialog}
          />
        </ImagePickerContainer.Provider>
      )}
    </View>
  );
};

const Thumbnail: React.FC<{
  index?: number;
  image: {key: string; uri: string};
  size?: {width: number; height: number};
  aspect: number;
  maxPx: number;
  onRemove?(): void;
  onChange?(uri: string, index?: number): void;
}> = ({index, image, size, aspect, maxPx, onRemove, onChange}) => {
  const [remover, setRemover] = React.useState(false);
  // 削除コールバックが登録されている時だけ、タッチ可能にする
  const onPress = onRemove ? () => setRemover(true) : undefined;
  return (
    <TouchableRipple style={CommonStyles.full} onPress={onPress}>
      <View>
        <Image
          style={[styles.size, size]}
          source={image}
          resizeMode="contain"
        />
        <SelectMenuDialog
          index={index}
          selectImage={image}
          size={size}
          maxPx={maxPx}
          aspect={aspect}
          visible={remover}
          onRemove={() => {
            setRemover(false);
            onRemove && onRemove();
          }}
          onChange={onChange}
          onCancel={() => setRemover(false)}
        />
      </View>
    </TouchableRipple>
  );
};

const SelectMenuDialog: React.FC<{
  index?: number;
  selectImage: {key: string; uri: string};
  size?: {width: number; height: number};
  aspect: number;
  maxPx: number;
  visible: boolean;
  onRemove?(): void;
  onChange?(uri: string, index?: number): void;
  onCancel?(): void;
}> = ({
  index,
  selectImage,
  aspect,
  maxPx,
  visible,
  onRemove,
  onChange,
  onCancel,
}) => {
  return (
    <DialogBase visible={visible} title="写真の変更" onDismiss={onCancel}>
      <View style={[CommonStyles.flex.full, CommonStyles.flex.center]}>
        <Image
          source={selectImage}
          style={styles.removeSize}
          resizeMode="center"
        />
      </View>
      <View style={CommonStyles.margin.bottom} />
      <ImagePickerContainer.Provider>
        <PickButton
          maxPx={maxPx}
          aspect={aspect}
          mode={'change'}
          onCancel={onCancel}
          onChange={onChange}
          index={index}
        />
      </ImagePickerContainer.Provider>
      <View style={CommonStyles.margin.bottom} />
      <TrimaButton color="error" variant="contained" onClick={onRemove}>
        写真の削除
      </TrimaButton>
      <View style={CommonStyles.margin.bottom} />
      <TrimaButton variant="outlined" onClick={onCancel}>
        キャンセル
      </TrimaButton>
      <View style={CommonStyles.margin.left} />
    </DialogBase>
  );
};

const LabeledBox: React.FC<{index: number}> = ({index}) => {
  const {colors} = useTheme();
  const borderColor = colors.disabled;
  const backgroundColor = colors.disabled;
  const color = colors.background;
  return (
    <View style={[CommonStyles.full, styles.border, {borderColor}]}>
      <View style={[styles.numberLabel, {backgroundColor}]}>
        <Text style={{color}}>{index + 1}</Text>
      </View>
    </View>
  );
};

const HelpDialog: React.FC<{
  open: boolean;
  onClose(): void;
  pickImage(): void;
}> = ({open, onClose, pickImage}) => {
  const [checked, setChecked] = React.useState(false);
  const handleClose = () => {
    onClose();
  };
  const select = async () => {
    await AsyncStorage.setItem(
      'next-shown',
      JSON.stringify({
        couponImageDialog: false,
      }),
    );
    pickImage();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        画像セレクトのおすすめ
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <View>
            <Typography variant="body1">悪い例</Typography>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item xs={4}>
                <Image style={thumbnailSize} source={{uri: image5577}} />
                <Typography variant="body1" align="center">
                  美味しく
                  <br />
                  なさそう
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Image style={thumbnailSize} source={{uri: image5576}} />
                <Typography variant="body1" align="center">
                  暗い
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Image style={thumbnailSize} source={{uri: image5575}} />
                <Typography variant="body1" align="center">
                  何屋さんか
                  <br />
                  分かりにくい
                </Typography>
              </Grid>
            </Grid>
            <VMargin />
            <Typography variant="subtitle1">良い例</Typography>
            <Grid container justifyContent="space-between">
              <Grid item xs={4}>
                <Image style={thumbnailSize} source={{uri: image5584}} />
                <Typography variant="body1" align="center">
                  美味しそう
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Image style={thumbnailSize} source={{uri: image5583}} />
                <Typography variant="body1" align="center">
                  何が安いのか
                  <br />
                  わかりやすい
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Image style={thumbnailSize} source={{uri: image5582}} />
                <Typography variant="body1" align="center">
                  何屋さんか
                  <br />
                  わかりやすい
                </Typography>
              </Grid>
            </Grid>
            <VMargin />
            <View
              style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}
            >
              <Checkbox
                status={checked ? 'checked' : 'unchecked'}
                onPress={() => setChecked(!checked)}
              />
              <Text>次回以降表示しない</Text>
            </View>
            <VMargin />
            <TrimaButton variant="contained" onClick={select}>
              写真を選ぶ
            </TrimaButton>
            <VMargin />
            <TrimaButton variant="outlined" onClick={handleClose}>
              キャンセル
            </TrimaButton>
          </View>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const PickButton: React.FC<{
  aspect: number;
  maxPx: number;
  onPicked?(uri: string): void;
  mode: string;
  onCancel?(): void;
  onChange?(uri: string, index?: number): void;
  index?: number;
  showDialog?: boolean;
}> = ({
  aspect,
  maxPx,
  onPicked,
  mode,
  onCancel,
  onChange,
  index = 0,
  showDialog = true,
}) => {
  const {colors} = useTheme();
  const borderColor = colors.primary;
  const {baseImage, setBaseImage, image, clear} =
    ImagePickerContainer.useContainer();
  const [open, setOpen] = React.useState(false);

  // image が格納された通知する
  React.useEffect(() => {
    if (image && image.base64) {
      if (mode === 'add') {
        onPicked && onPicked(image.base64);
      } else {
        onChange && onChange(image.base64, index);
        onCancel && onCancel();
      }
      clear();
    }
  }, [image, onPicked, clear, mode, onChange, index, onCancel]);

  // ユーザーが画像選択したらトリミング開始のためにbaseImageとして保持
  const pickImage = async () => {
    handleClose();
    const result = await Picker.launchImageLibraryAsync({
      mediaTypes: Picker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    });
    if (!result.cancelled) {
      setBaseImage(result);
    }
  };

  const onPress = async () => {
    const show = await AsyncStorage.getItem('next-shown');
    if (
      showDialog &&
      (!JSON.parse(show as string) ||
        JSON.parse(show as string).couponImageDialog)
    ) {
      handleClickOpen();
    } else {
      pickImage();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* 画像が選択された状態の場合はトリミングモード */}
      {baseImage && <Trimming aspect={aspect} maxPx={maxPx} />}
      {mode === 'add' ? (
        <React.Fragment>
          <TouchableRipple
            style={[
              CommonStyles.full,
              CommonStyles.flex.center,
              styles.border,
              {borderColor},
            ]}
            onPress={onPress}
          >
            <FontAwesomeIcon
              name="picture-o"
              size={36}
              color={colors.primary}
            />
          </TouchableRipple>
        </React.Fragment>
      ) : (
        <TrimaButton variant="contained" onClick={onPress}>
          写真の変更
        </TrimaButton>
      )}
      <HelpDialog open={open} onClose={handleClose} pickImage={pickImage} />
    </React.Fragment>
  );
};

function getCropParam(
  crop: ReactCrop.Crop,
  scaleX = 1,
  scaleY = 1,
):
  | {originX: number; originY: number; width: number; height: number}
  | undefined {
  if (
    crop.unit === 'px' &&
    crop.x !== undefined &&
    crop.y !== undefined &&
    crop.width &&
    crop.height
  ) {
    const {x, y, width, height} = crop;
    return {
      originX: x * scaleX,
      originY: y * scaleY,
      width: width * scaleX,
      height: height * scaleY,
    };
  }
  return undefined;
}

function getScale(image?: HTMLImageElement): {x: number; y: number} {
  if (!image) {
    return {x: 1, y: 1};
  }
  return {
    x: image.naturalWidth / image.width,
    y: image.naturalHeight / image.height,
  };
}

function getResizeParam(
  width: number,
  height: number,
  maxPx: number,
): {width: number; height: number} {
  if (width <= maxPx && height <= maxPx) {
    return {width, height};
  }

  const ratio = Math.min(
    maxPx / Math.max(width, 1),
    maxPx / Math.max(height, 1),
  );

  return {width: width * ratio, height: height * ratio};
}

function calcCropSize(image: HTMLImageElement, aspect: number): ReactCrop.Crop {
  const aspectHeight = image.width / aspect; // 幅基準でアスペクト比適用した高さ
  const size =
    aspectHeight > image.height
      ? {
          // width基準だと実際のheightを超える場合は、height基準で指定
          width: image.height * aspect,
          height: image.height,
        }
      : {width: image.width, height: aspectHeight};
  return {aspect, ...size, unit: 'px', x: 0, y: 0};
}

const Trimming: React.FC<{
  aspect: number;
  maxPx: number;
}> = ({aspect, maxPx}) => {
  const {colors} = useTheme();
  const [crop, setCrop] = React.useState<ReactCrop.Crop>({aspect});
  const {baseImage, setImage, clear} = ImagePickerContainer.useContainer();
  const imageElem = React.useRef<HTMLImageElement>();
  const {windowHeight} = useHeight();
  // Crop指定範囲の最大高さ
  const heightLimit = windowHeight - 180;
  const onImageLoaded = React.useCallback(
    (image: HTMLImageElement) => {
      imageElem.current = image;
      // 画像が読み込まれたら、初期Crop範囲の設定を行う
      const calcCrop = calcCropSize(image, aspect);
      const ratio = heightLimit / image.height;
      if (ratio < 1 && calcCrop.width && calcCrop.height) {
        // この後、高さ方向のために画像全体が縮小されるので、crop領域も縮小しておく
        calcCrop.width = Math.floor(calcCrop.width * ratio);
        calcCrop.height = Math.floor(calcCrop.height * ratio);
      }
      setCrop(calcCrop);
      return false;
    },
    [aspect, heightLimit],
  );

  if (!baseImage) {
    // トリミング元が設定されていないなら何もすることがない
    return null;
  }

  const onSubmit = async () => {
    // ReactCrop.Cropは表示座標に対する数値が入ってくるため、
    // 表示画像が実画像から縮尺されている場合は計算して戻す必要がある。
    const scale = getScale(imageElem.current);
    const croppingParam = getCropParam(crop, scale.x, scale.y);
    if (!croppingParam) {
      return;
    }
    const mid = await ImageManipulator.manipulateAsync(baseImage.uri, [
      {crop: croppingParam},
    ]);

    const result = await ImageManipulator.manipulateAsync(
      mid.uri,
      [
        {
          resize: getResizeParam(
            croppingParam.width,
            croppingParam.height,
            maxPx,
          ),
        },
      ],
      {compress: 0.85, format: ImageManipulator.SaveFormat.JPEG},
    );

    setImage(result);
  };

  // クリップ領域のサイズ
  const cropArea = {
    // 画面に収まる高さと画像の実サイズ高さのうち、小さい方をエリアの高さとして採用
    height: Math.min(imageElem.current?.height ?? 99999, heightLimit),
    alignSelf: 'center' as const,
    borderWidth: 1,
    borderColor: colors.primary,
  };
  // 画像の表示サイズ（横は自動で変化するっぽいので、縦を指定
  const imageStyle = {
    height: imageElem.current?.height
      ? Math.min(imageElem.current.height, heightLimit)
      : undefined,
  };
  const isCropped = !!getCropParam(crop);

  // 画像サイズがクロップ領域より小さくなった場合に領域の再計算が必要
  if (
    imageElem.current &&
    ((crop.width ?? 0) > imageElem.current.width ||
      (crop.height ?? 0) > imageElem.current.height)
  ) {
    setCrop(calcCropSize(imageElem.current, aspect));
  }

  const cropBack = {backgroundColor: 'black' as const};

  return (
    <Sheet isVisible={!!baseImage} onDismiss={clear}>
      <View style={[CommonStyles.margin.all]}>
        <Menu>画像のトリミング</Menu>
        <VMargin />
        <View style={cropArea}>
          <ReactCrop
            imageStyle={imageStyle}
            src={baseImage.uri}
            onImageLoaded={onImageLoaded}
            crop={crop}
            onChange={setCrop}
            style={cropBack}
          />
        </View>
        <VMargin />
        <MaxWidth maxWidth={568}>
          <TrimaButton
            variant="contained"
            onClick={onSubmit}
            disabled={!isCropped}
          >
            確定
          </TrimaButton>
        </MaxWidth>
      </View>
    </Sheet>
  );
};

const styles = StyleSheet.create({
  size: {
    width: 57,
    height: 57,
  },
  removeSize: {
    width: '100%',
    height: 120,
  },
  border: {
    borderWidth: 1,
  },
  action: {
    justifyContent: 'center',
  },
  numberLabel: {
    width: 22,
    height: 22,
    position: 'absolute',
    top: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
