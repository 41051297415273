import React from 'react';
import {StyleSheet, View} from 'react-native';
import {DiscountType} from '../../API';
import {Helpers} from '../../helper';
import {CommonStyles, trimaColor} from '../../theme';
import {AppText, FontAwesomeIcon} from '../Elements';
import {Discount} from './types';

export const DiscountLabel = React.memo<{
  discount: Discount;
  isSmall?: boolean;
}>(({discount, isSmall}) => {
  const fStyle = isSmall ? smallTextStyle : bigTextStyle;
  switch (discount.type) {
    case DiscountType.BY_FREE:
      return <AppText style={fStyle.topic}>無料</AppText>;
    case DiscountType.BY_HALF:
      return <AppText style={fStyle.topic}>半額</AppText>;
    case DiscountType.BY_TEXT:
      return (
        <AppText style={fStyle.freeText} numberOfLines={2}>
          {discount.freeText}
        </AppText>
      );
    case DiscountType.BY_PRICE:
      return (
        <View style={[CommonStyles.flex.row, CommonStyles.flex.crossEnd]}>
          <AppText style={fStyle.topic}>
            {Helpers.sepComma(discount.param[0])}
          </AppText>
          <AppText style={fStyle.postfixPrice}>円引き</AppText>
        </View>
      );
    case DiscountType.BY_PERCENT:
      return (
        <View style={[CommonStyles.flex.row, CommonStyles.flex.crossEnd]}>
          <AppText style={fStyle.topic}>{discount.param[0]}</AppText>
          <AppText style={fStyle.postfixPercent}>%OFF</AppText>
        </View>
      );
    case DiscountType.FROM_PRICE:
      return (
        <View style={[CommonStyles.flex.row, CommonStyles.flex.crossEnd]}>
          <AppText style={fStyle.from}>
            {Helpers.sepComma(discount.param[0])}
          </AppText>
          <AppText style={fStyle.fromPostfix}>円</AppText>
          <FontAwesomeIcon style={fStyle.arrow} name="play" />
          <AppText style={fStyle.to}>
            {Helpers.sepComma(discount.param[1])}
          </AppText>
          <AppText style={fStyle.toPostfix}>円</AppText>
        </View>
      );
    default:
      // ここにはこない
      return null;
  }
});

// Todo とりあえずMac Safariでそれっぽく見えるように設定しただけなので要調整
const appleFontRatio = 0.935;
const smallTextStyle = StyleSheet.create({
  freeText: {
    fontSize: 13 * appleFontRatio,
    lineHeight: 16,
    color: trimaColor.sub,
  },
  topic: {
    fontSize: 24 * appleFontRatio,
    lineHeight: 32,
    color: trimaColor.sub,
  },
  postfixPrice: {
    fontSize: 14 * appleFontRatio,
    lineHeight: 19,
    color: trimaColor.sub,
    marginLeft: 8,
    marginBottom: 6,
  },
  postfixPercent: {
    fontSize: 16 * appleFontRatio,
    lineHeight: 21,
    color: trimaColor.sub,
    marginLeft: 4,
    marginBottom: 6,
  },
  from: {
    fontSize: 12 * appleFontRatio,
    lineHeight: 16,
    marginBottom: 2,
  },
  fromPostfix: {
    fontSize: 8 * appleFontRatio,
    lineHeight: 11,
    marginLeft: 1,
    marginBottom: 4,
  },
  arrow: {
    fontSize: 8 * appleFontRatio,
    color: trimaColor.sub,
    marginHorizontal: 4,
    marginBottom: 8,
  },
  to: {
    fontSize: 18 * appleFontRatio,
    lineHeight: 24,
    color: trimaColor.sub,
  },
  toPostfix: {
    fontSize: 12 * appleFontRatio,
    lineHeight: 16,
    marginLeft: 4,
    marginBottom: 2,
    color: trimaColor.sub,
  },
});

const bigTextStyle = StyleSheet.create({
  freeText: {
    fontSize: 28 * appleFontRatio,
    lineHeight: 33,
    color: trimaColor.sub,
  },
  topic: {
    fontSize: 32 * appleFontRatio,
    lineHeight: 43,
    color: trimaColor.sub,
  },
  postfixPrice: {
    fontSize: 20 * appleFontRatio,
    lineHeight: 27,
    color: trimaColor.sub,
    marginLeft: 16,
    marginBottom: 8,
  },
  postfixPercent: {
    fontSize: 20 * appleFontRatio,
    lineHeight: 27,
    color: trimaColor.sub,
    marginLeft: 4,
    marginBottom: 6,
  },
  from: {
    fontSize: 20 * appleFontRatio,
    lineHeight: 27,
    marginBottom: 12,
  },
  fromPostfix: {
    fontSize: 14 * appleFontRatio,
    lineHeight: 19,
    marginLeft: 6,
    marginBottom: 14,
  },
  arrow: {
    fontSize: 12 * appleFontRatio,
    color: trimaColor.sub,
    marginHorizontal: 6,
    marginBottom: 18,
  },
  to: {
    fontSize: 32 * appleFontRatio,
    lineHeight: 43,
    color: trimaColor.sub,
  },
  toPostfix: {
    fontSize: 20 * appleFontRatio,
    lineHeight: 27,
    marginLeft: 10,
    marginBottom: 6,
    color: trimaColor.sub,
  },
});
