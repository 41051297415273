import AsyncStorage from '@react-native-async-storage/async-storage';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {OwnerStatus, PaymentType} from '../API';
import {
  OwnerContainer,
  PaymentInfoContainer,
  ShopGroupsContainer,
  ShopsContainer,
} from '../container';
import {AccessRoleContainer} from './AccessRoleContainer';

const useStepNavigationContainer = () => {
  const {fetchOwner} = OwnerContainer.useContainer();
  const {fetchPaymentInfo} = PaymentInfoContainer.useContainer();
  const {fetchShops, setShopId} = ShopsContainer.useContainer();
  const {fetchShopGroups} = ShopGroupsContainer.useContainer();
  const {fetchAccessRole} = AccessRoleContainer.useContainer();

  const [step, setStep] = useState<any>('init');
  const [toScreen, set2Screen] = useState<any>();

  const setStep2OwnerRegist = useCallback(() => {
    setStep('unregisteredOwner');
  }, []);

  const setStep2PaymentRegist = useCallback(() => {
    setStep('unregisteredPayment');
  }, []);

  const setStep2PaymentReview = useCallback(() => {
    setStep('paymentReviewed');
  }, []);

  const setRegisterDone = useCallback(() => {
    setStep('registerDone');
  }, []);

  const setStep2ShopRegist = useCallback(() => {
    setStep('unregisteredShop');
  }, []);

  const setStep2Main = useCallback(() => {
    setStep('main');
  }, []);

  const setStep2SelectOwner = useCallback(() => {
    setStep('unselectedOwner');
  }, []);

  const setStep2SignOut = useCallback(() => {
    setStep('signOut');
  }, []);

  const setStep2Loading = useCallback(() => {
    setStep('loading');
  }, []);

  const setStep2Suspended = useCallback(() => {
    setStep('suspended');
  }, []);

  const checkInitData = useCallback(
    async (ownerId: string, userId: string, toScreen?: string) => {
      setStep2Loading();
      const [owner, paymentInfo, shopList, shopId] = await Promise.all([
        fetchOwner(ownerId),
        fetchPaymentInfo(ownerId),
        fetchShops(ownerId),
        AsyncStorage.getItem('shop'),
        fetchAccessRole(userId, ownerId),
        fetchShopGroups(ownerId),
      ]);
      if (!owner) {
        return setStep2OwnerRegist();
      }
      if (
        owner.status === OwnerStatus.PAUSED ||
        owner.status === OwnerStatus.BANNED
      ) {
        return setStep2Suspended();
      }
      if (!paymentInfo) {
        return setStep2PaymentRegist();
      }
      if (!paymentInfo.subscriptionId) {
        if (paymentInfo.paymentType === PaymentType.PAID) {
          return setStep2PaymentReview();
        } else {
          return setStep2PaymentRegist();
        }
      }
      if (shopList.length === 0) {
        return setStep2ShopRegist();
      } else {
        if (toScreen) {
          set2Screen(toScreen);
        }
        setShopId(shopId as string);
        // setShopGroup(
        //   shopList.find((shop) => shop.id === shopId)?.groupId as string,
        // );
        return setStep2Main();
      }
    },
    [
      setStep2Loading,
      fetchOwner,
      fetchPaymentInfo,
      fetchShops,
      fetchShopGroups,
      fetchAccessRole,
      setStep2OwnerRegist,
      setStep2PaymentRegist,
      setStep2PaymentReview,
      setStep2ShopRegist,
      setStep2Main,
      setStep2Suspended,
      setShopId,
      // setShopGroup,
    ],
  );

  const clear2Screen = useCallback(() => {
    set2Screen(undefined);
  }, []);

  return {
    step,
    toScreen,
    setStep2Loading,
    setStep2OwnerRegist,
    setStep2PaymentRegist,
    setStep2PaymentReview,
    setRegisterDone,
    setStep2ShopRegist,
    setStep2Main,
    setStep2SelectOwner,
    setStep2SignOut,
    setStep2Suspended,
    checkInitData,
    clear2Screen,
  };
};

export const StepNavigationContainer = createContainer(
  useStepNavigationContainer,
);
