import awsmobile from '../../aws-exports';
const TAG = 'AppConfig';

const EnvBridge: {[key: string]: string} = {
  'ap-northeast-1_H7pn2QMlU': 'sandbox',
  'ap-northeast-1_M8ovfNBg0': 'dev',
  'ap-northeast-1_e6d7LkiKI': 'staging',
  'ap-northeast-1_QyH86NP64': 'prod',
};

const Stage = EnvBridge[awsmobile.aws_user_pools_id] || 'dev';

console.log(TAG, Stage);

interface Env {
  SaaSBackend: string;
  SaaSHeavyBackend: string;
  Stage: string;
  StripePromise: string;
  StripePriceId: string;
  PaidPriceId: string;
  TrimaAppCouponBackend: string;
}

const EnvSandbox: Env = {
  SaaSBackend: 'https://api-sandbox.dev-shop.trip-mile.com',
  SaaSHeavyBackend: 'https://api-heavy.sandbox-shop.trip-mile.com',
  Stage,
  StripePromise:
    'pk_test_51Id8KeFQo9Hz6HfSrg2Hd48YGA4TRSGHGp9o73n0qy71t2vrUsZwHiqkMESKuORHOwPagZtZ7D19vmKBFSJ3GTn200hbfILtFI',
  StripePriceId: 'price_1LkHnEFQo9Hz6HfSHE3qa4nY',
  PaidPriceId: 'price_1LkHoYFQo9Hz6HfSUNmTHSXP',
  TrimaAppCouponBackend: 'https://dev-api-coupon.trip-mile.com',
};

const EnvDev: Env = {
  SaaSBackend: 'https://api2.dev-shop.trip-mile.com',
  SaaSHeavyBackend: 'https://api2.dev-shop.trip-mile.com',
  Stage,
  StripePromise:
    'pk_test_51Id8KeFQo9Hz6HfSrg2Hd48YGA4TRSGHGp9o73n0qy71t2vrUsZwHiqkMESKuORHOwPagZtZ7D19vmKBFSJ3GTn200hbfILtFI',
  StripePriceId: 'price_1LkHnEFQo9Hz6HfSHE3qa4nY',
  PaidPriceId: 'price_1LkHoYFQo9Hz6HfSUNmTHSXP',
  TrimaAppCouponBackend: 'https://dev-api-coupon.trip-mile.com',
};

const EnvStaging: Env = {
  SaaSBackend: 'https://api2.staging-shop.trip-mile.com',
  SaaSHeavyBackend: 'https://api2.staging-shop.trip-mile.com',
  Stage,
  StripePromise:
    'pk_test_51Id8KeFQo9Hz6HfSrg2Hd48YGA4TRSGHGp9o73n0qy71t2vrUsZwHiqkMESKuORHOwPagZtZ7D19vmKBFSJ3GTn200hbfILtFI',
  StripePriceId: 'price_1LkHnEFQo9Hz6HfSHE3qa4nY',
  PaidPriceId: 'price_1LkHoYFQo9Hz6HfSUNmTHSXP',
  TrimaAppCouponBackend: 'https://staging-api-coupon.trip-mile.com',
};

const EnvProd: Env = {
  SaaSBackend: 'https://api2.shop.trip-mile.com',
  SaaSHeavyBackend: 'https://api2.shop.trip-mile.com',
  Stage,
  StripePromise:
    'pk_live_51Id8KeFQo9Hz6HfSeP0yRMlYI7SUAQJ7TsDQLiEV8pzQsCWAvbeRdFcc1N6IyIzAuI9umZaIOhJfHO40xKmSX7zK00RPDGBcNQ',
  StripePriceId: 'price_1Ln9snFQo9Hz6HfSQqS8ezMv',
  PaidPriceId: 'price_1Ln9taFQo9Hz6HfSCds9YGLh',
  TrimaAppCouponBackend: 'https://api-coupon.trip-mile.com',
};

const Envs: any = {
  sandbox: EnvSandbox,
  dev: EnvDev,
  staging: EnvStaging,
  prod: EnvProd,
};

export const AppConfig = {
  ...Envs[Stage],
  CouponEndPoint: '/api/contents/v1/coupon',
  NewsEndPoint: '/api/contents/v1/news',
  ShopEndPoint: '/api/setting/v1/shop',
  ImageEndPoint: '/api/contents/v1',
  PreRegistEndPoint: '/api/auth/v1/pre-regist',
  ConfirmRegistEndPoint: '/api/auth/v1/confirm-regist',
  AddGroupEndPoint: '/api/auth/v1/add-group',
  RemoveGroupEndPoint: '/api/auth/v1/remove-group',
  MeshTargetEndPoint: '/api/target/v1/meshs',
  MeshStayTargetEndPoint: '/api/target/v1/meshs-stay',
  StationTargetEndPoint: '/api/target/v1/stations',
  SearchStationPoiEndPoint: '/api/target/v1/station-poi',
  SearchCityPoiEndPoint: '/api/target/v1/city-poi',
  DeliveryCouponEndPoint: '/api/delivery/v1/coupon',
  DeliveryCouponUpdateEndPoint: '/api/delivery/v1/coupon/update',
  DeliveryCouponViewCountEndPoint: '/api/delivery/v1/coupon/view-count',
  DeliveryNewsEndPoint: '/api/delivery/v1/news',
  DeliveryNewsUpdateEndPoint: '/api/delivery/v1/news/update',
  DeliveryNewsViweCountEndPoint: '/api/delivery/v1/news/view-count',
  GetActiveCreativesEndPoint: '/api/delivery/v1/get-active-creatives',
  PaymentInfoEndPoint: '/api/billing/v1',
  GooglePlaceEndPoint: '/api/misc/v1/google-place',
  GoogleAddrSuggestEndPoint: '/api/misc/v1/google-addr-autocomplete',
  GoogleAddrDetailEndPoint: '/api/misc/v1/google-addr-detail',
  CouponCountEndPoint: '/api/statistics/v1/coupon-counts',
  CouponTargetCountEndPoint: '/api/statistics/v1/coupon-target-counts',
  NewsCountEndPoint: '/api/statistics/v1/news-counts',
  NewsTargetCountEndPoint: '/api/statistics/v1/news-target-counts',
  ReportShopDailyEndPoint: '/api/report/v1/shop-daily-range',
  ReportDeliveryDailyEndPoint: '/api/report/v1/delivery-daily-report',
  ReportShopDeliverySummaryEndPoint:
    '/api/report/v1/shop-delivery-summary-cache',
  ReportShopDeliveryDetailEndpoint: '/api/report/v1/shop-delivery-detail',
  DeliveryUserAnalyzeEndpoint: '/api/user-analyze/v1/delivery',
  ReportShopVisitor: '/api/report/v1/shop/visitor',
  // InviteCodeCheckEndpoint: '/api/coupon/v1/check-invitation',
  SaaSInviteCodeCheckEndpoint: '/api/misc/v1/check-invitation',
  SaaSUserInfoByInviteCodeEndpoint: '/api/misc/v1/user',

  AgencyFetchEndPoint: '/api/agency/v1/code',

  MinTargetCount: 50,
};
