import API, {GraphQLResult, graphqlOperation} from '@aws-amplify/api';
import {Box, Card, CardContent, Link} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import {Auth} from 'aws-amplify';
import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {AccessManageStatus, UpdateAccessManageMutation} from '../../API';
import {BRAKE_POINT} from '../../components';
import {InviteContainer} from '../../components/Auth/InviteContainer';
import {Logo, MaxWidth, Text} from '../../components/Elements';
import {StepNavigationContainer} from '../../container';
import {updateAccessManage} from '../../graphql/mutations';
import {Colors, CommonStyles} from '../../theme';

const InviteComponent: React.FC = () => {
  const navigation = useNavigation();
  const {params} = useRoute();
  const {fetchInvite} = InviteContainer.useContainer();
  const [error, setError] = React.useState<Error | undefined>();
  const {setStep2SelectOwner} = StepNavigationContainer.useContainer();

  useEffect(() => {
    async function fetchData() {
      const user = await Auth.currentUserInfo();
      if (!user) {
        navigation.navigate('SignIn');
      } else if (params && (params as any).invite) {
        try {
          const res = await fetchInvite((params as any).invite);
          if (dayjs(res.createdAt).add(1, 'd') < dayjs()) {
            throw new Error(
              '招待メールが送信されてから24時間以上経過したため、無効となっています。\n招待メールに記載の招待者様に、再度招待していただくようご連絡をお願いします。',
            );
          }
          if (
            res.email === user.attributes.email &&
            res.status === AccessManageStatus.INIT
          ) {
            const result = (await API.graphql(
              graphqlOperation(updateAccessManage, {
                input: {
                  id: (params as any).invite,
                  userId: user.username,
                  status: AccessManageStatus.DONE,
                },
              }),
            )) as GraphQLResult<UpdateAccessManageMutation>;
            console.log(result);
            if (!result.data) {
              throw new Error('[updateAccessManage] result.data is undefined');
            }
            setStep2SelectOwner();
            navigation.navigate('SelectOwner');
          } else {
            throw new Error('エラーが発生しました');
          }
        } catch (err: any) {
          setError(err);
        }
      }
    }
    fetchData();
  }, [params, fetchInvite, setStep2SelectOwner, navigation]);

  return (
    <MaxWidth maxWidth={BRAKE_POINT.desktop}>
      {(params as any).invite ? (
        !error ? (
          <ActivityIndicator />
        ) : (
          <>
            <Box p={2} />
            <Card>
              <CardContent>
                <Text>{error.message}</Text>
              </CardContent>
            </Card>
          </>
        )
      ) : (
        <React.Fragment />
      )}
    </MaxWidth>
  );
};

export const Invite: React.FC = React.memo(() => {
  return (
    <View>
      <View style={styles.header}>
        <Link href="/">
          <Logo height={48} isDark />
        </Link>
      </View>
      <InviteContainer.Provider>
        <InviteComponent />
      </InviteContainer.Provider>
    </View>
  );
});

const styles = StyleSheet.create({
  header: {
    height: 60,
    backgroundColor: Colors.header,
    ...CommonStyles.flex.center,
  },
});
