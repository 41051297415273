import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Delete,
  FileCopyOutlined,
  MoreVert,
  Edit as MuiEdit,
  Send,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  List as MuiList,
  Menu as MuiMenu,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import dayjs from 'dayjs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator, Dialog, Portal} from 'react-native-paper';
import {ReactComponent as Copy2GroupIcon} from '../../assets/copy2group.svg';
import {BRAKE_POINT} from '../../components';
import {
  BackLink,
  Container,
  MaxWidth,
  Menu,
  MinText,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {useWidth} from '../../components/Responsive';
import {
  CouponsContainer,
  NewsContainer,
  ShopContainer,
  ShopsContainer,
} from '../../container';
import {Coupon} from '../../container/CouponsContainer';
import {News} from '../../container/NewsContainer';
import {ERROR_MSG} from '../../helper';
import {
  MainNavigationProp,
  MainRouteProp,
  ScreenType,
} from '../../navigation/MainScreen';
import {axiosHelper} from '../../service';
import {Colors, CommonStyles, Message} from '../../theme';
import {Copy} from './Copy';
import {Create} from './Create';
import {Detail} from './Detail';
import {Edit} from './Edit';

type NProp = MainNavigationProp<'CouponsMain'>;
type RProp = MainRouteProp<'CouponsMain'>;

type ListItem = Coupon | News;

const CouponDeleteDialog: React.FC<{
  visible: boolean;
  onDismiss?(): void;
  onError?(): void;
  item: ListItem;
}> = ({visible, onDismiss, onError, item}) => {
  const [busy, setBusy] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string | undefined>();
  const route = useRoute<RProp>();
  const fromScreen = route.params?.from;
  const [from] = React.useState<ScreenType | undefined>(fromScreen);
  const {deleteData: deleteCoupon, deleteGroupData: deleteGroupCoupon} =
    CouponsContainer.useContainer();
  const {deleteData: deleteNews, deleteGroupData: deleteGroupNews} =
    NewsContainer.useContainer();
  const removed = () => onDismiss && onDismiss();
  const cancel = () => onDismiss && onDismiss();
  const errored = () => onError && onError();
  const remove = async () => {
    setBusy(true);
    try {
      if (item.type === 'coupon') {
        from === 'CreativesGroupCreate'
          ? await deleteGroupCoupon(item.id)
          : await deleteCoupon(item.id);
      } else {
        from === 'CreativesGroupCreate'
          ? await deleteGroupNews(item.id)
          : await deleteNews(item.id);
      }
      removed();
    } catch (err: any) {
      setMessage(axiosHelper.commonErrorHandler(err));
    } finally {
      setBusy(false);
    }
  };
  const render = () => {
    // エラー時
    if (message) {
      return (
        <View>
          <Text>広告の削除に失敗しました。</Text>
          <Text>{message}</Text>
          <VMargin />
          <TrimaButton variant="outlined" onClick={errored}>
            {Message.BackToList}
          </TrimaButton>
        </View>
      );
    }
    return (
      <View>
        <Text>■タイトル（管理用）</Text>
        <Text>{item.title}</Text>
        <Text>■広告内容</Text>
        <Text>{item.name}</Text>
        <Text>■配信回数と閲覧回数</Text>
        <Text>{item.delivery}</Text>
        <VMargin />
        <TrimaLoadingButton
          variant="contained"
          color="error"
          onClick={remove}
          disabled={busy}
          loading={busy}>
          削除
        </TrimaLoadingButton>
        <VMargin />
        <TrimaButton variant="outlined" onClick={cancel} disabled={busy}>
          キャンセル
        </TrimaButton>
      </View>
    );
  };
  const {windowWidth} = useWidth();
  const widthStyle =
    windowWidth > 600 ? {width: 568, alignSelf: 'center' as const} : undefined;

  return (
    <Portal>
      <Dialog
        visible={visible}
        onDismiss={cancel}
        dismissable={false}
        style={widthStyle}>
        <Dialog.Title style={styles.deleteText}>
          広告を削除しますか？
        </Dialog.Title>
        <Dialog.Content>{render()}</Dialog.Content>
      </Dialog>
    </Portal>
  );
};

const AddButton: React.FC = () => {
  const navigation = useNavigation();
  const gotoCreate = () => navigation.navigate('CouponsCreate');
  return (
    <Box pt={1}>
      <TrimaButton
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={gotoCreate}>
        広告を新規作成
      </TrimaButton>
    </Box>
  );
};

const Heading: React.FC<{from?: ScreenType}> = ({from}) => {
  const navigation = useNavigation<NProp>();
  const cancel = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('CreativesCreate');
    }
  };
  if (!from) {
    // 規定
    return (
      <WithHint id="couponsMain">
        <Menu>作成済みの広告</Menu>
      </WithHint>
    );
  } else {
    // とりあえず配信からくるケースを実装
    return (
      <View>
        {from === 'CreativesCreate' && (
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label="配信する画面に戻る" onClick={cancel} />
          </Box>
        )}
        <Menu>作成済みの広告の選択</Menu>
      </View>
    );
  }
};

const Footer: React.FC<{from?: ScreenType}> = ({from}) => {
  // 他の画面から来た場合は新規ボタンは表示しない
  return !from ? <AddButton /> : null;
};

function sortFunc(a: {createdAt: string}, b: {createdAt: string}): number {
  return dayjs(a.createdAt) > dayjs(b.createdAt)
    ? -1
    : dayjs(a.createdAt) < dayjs(b.createdAt)
    ? 1
    : 0;
}

const Main: React.FC = () => {
  const {selected} = ShopContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const [errorMsg, setError] = React.useState<string>('');
  const [creatives, setCreatives] = React.useState<Array<any>>([]);
  const [groupCreatives, setGroupCreatives] = React.useState<Array<any>>([]);
  // 遷移元の設定
  const route = useRoute<RProp>();
  const fromScreen = route.params?.from;
  const [from] = React.useState<ScreenType | undefined>(fromScreen);
  const {
    isGetLoading: isGetLoadingCoupons,
    coupons,
    getData: getDataCoupons,
    getGroupData: getGroupDataCoupons,
    groupCoupons,
  } = CouponsContainer.useContainer();
  const {
    isGetLoading: isGetLoadingNews,
    news,
    getData: getDataNews,
    getGroupData: getGroupDataNews,
    groupNews,
  } = NewsContainer.useContainer();

  React.useEffect(() => {
    async function fetchData() {
      if (selected) {
        try {
          if (from === 'CreativesGroupCreate') {
            await Promise.all([
              getGroupDataCoupons(
                shopList
                  .filter((shop) => shop.groupId === selected?.groupId)
                  .map((shop: any) => shop.id),
              ),
              getGroupDataNews(
                shopList
                  .filter((shop) => shop.groupId === selected?.groupId)
                  .map((shop: any) => shop.id),
              ),
            ]);
          } else {
            await Promise.all([
              getDataCoupons(selected.id),
              getDataNews(selected.id),
            ]);
          }
        } catch (err: any) {
          setError(ERROR_MSG.common.dump(err));
        }
      }
    }
    fetchData();
  }, [
    getDataCoupons,
    getGroupDataCoupons,
    selected,
    shopList,
    getGroupDataNews,
    getDataNews,
  ]);

  React.useEffect(() => {
    setCreatives([...coupons, ...news]);
    setGroupCreatives([...groupCoupons, ...groupNews]);
  }, [coupons, groupCoupons, news, groupNews]);

  return (
    <Container style={CommonStyles.padding.all}>
      <MaxWidth maxWidth={BRAKE_POINT.desktop}>
        <Heading from={from} />
        <View style={CommonStyles.margin.top} />
        <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
          <View>
            {errorMsg ? (
              <Text>{errorMsg}</Text>
            ) : !coupons || isGetLoadingCoupons || isGetLoadingNews ? (
              <ActivityIndicator size={60} />
            ) : (
              <View>
                <Text>未使用</Text>
                <VMargin />
                <Footer from={from} />
                <VMargin />
                <MuiList
                  sx={{
                    width: '100%',
                    '&.MuiList-root': {padding: 0},
                  }}>
                  {(from === 'CreativesGroupCreate'
                    ? groupCreatives
                        .flat()
                        .filter((groupCreative) => !groupCreative.used)
                        .sort(sortFunc)
                    : creatives
                        .filter((creative) => !creative.used)
                        .sort(sortFunc)
                  ).map((creative: any) => (
                    <React.Fragment key={creative.id}>
                      <CouponListItem creative={creative} from={from} />
                      <Box p={0.5} />
                    </React.Fragment>
                  ))}
                </MuiList>
                <VMargin />
                <Text>使用済み</Text>
                <VMargin />
                <MuiList>
                  {(from === 'CreativesGroupCreate'
                    ? groupCreatives
                        .flat()
                        .filter((groupCreative) => groupCreative.used)
                        .sort(sortFunc)
                    : creatives
                        .filter((creative) => creative.used)
                        .sort(sortFunc)
                  ).map((creative: any) => (
                    <React.Fragment key={creative.id}>
                      <CouponListItem creative={creative} from={from} />
                      <Box p={0.5} />
                    </React.Fragment>
                  ))}
                </MuiList>
              </View>
            )}
          </View>
        </MaxWidth>
      </MaxWidth>
    </Container>
  );
};

const CouponCopyDialog: React.FC<{
  visible: boolean;
  onDismiss?(): void;
  onError?(): void;
  item: ListItem;
}> = ({visible, onDismiss, item}) => {
  const navigation = useNavigation<NProp>();
  const {selected} = ShopContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const {checkedIds, setCheckedIds} = CouponsContainer.useContainer();
  const cancel = () => {
    onDismiss && onDismiss();
    setCheckedIds(
      shopList
        .filter((shop) => shop.id === selected?.id)
        .map((shop) => shop.id),
    );
  };
  const copy = async () => {
    navigation.navigate(item.type === 'coupon' ? 'CouponsCopy' : 'NewsCopy', {
      id: item.id,
      from: 'CouponsMain',
      group: 'on',
    });
  };

  React.useEffect(() => {
    setCheckedIds(
      shopList
        .filter((shop) => shop.id === selected?.id)
        .map((shop) => shop.id),
    );
  }, [shopList]);

  const render = () => {
    return (
      <View>
        <Text>グループ内の店舗に複製ができます。</Text>
        <Box px={2}>
          <FormControlLabel
            label="すべてを選択"
            control={
              <Checkbox
                checked={
                  checkedIds.length ===
                  shopList.filter((shop) => shop.groupId === selected?.groupId)
                    .length
                }
                indeterminate={
                  checkedIds.length > 0 &&
                  checkedIds.length <
                    shopList.filter(
                      (shop) => shop.groupId === selected?.groupId,
                    ).length
                }
                onChange={() => {
                  if (
                    checkedIds.length !==
                    shopList.filter(
                      (shop) => shop.groupId === selected?.groupId,
                    ).length
                  ) {
                    setCheckedIds(
                      shopList
                        .filter((shop) => {
                          if (selected && selected.groupId) {
                            return shop.groupId === selected.groupId;
                          } else {
                            return shop.groupId === null;
                          }
                        })
                        .map((shop) => shop.id),
                    );
                  } else {
                    setCheckedIds([]);
                  }
                }}
              />
            }
          />
        </Box>
        <Box
          mb={2}
          sx={{
            border: `1px solid ${Colors.darkgray}`,
          }}>
          <Stack
            pl={2}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              maxHeight: '40vh',
              height: '100%',
            }}>
            <Stack flex={1} overflow="auto">
              {shopList
                .filter((shop) => {
                  if (selected && selected.groupId) {
                    return shop.groupId === selected.groupId;
                  } else {
                    return shop.groupId === null;
                  }
                })
                .map((shop) => {
                  return (
                    <FormControlLabel
                      label={shop.name}
                      control={
                        <Checkbox
                          id={shop.id}
                          checked={checkedIds.includes(shop.id)}
                          onChange={(event) => {
                            const target = event.target;
                            setCheckedIds(
                              checkedIds.includes(target.id)
                                ? checkedIds.filter(
                                    (item) => item !== target.id,
                                  )
                                : [...checkedIds, target.id],
                            );
                          }}
                        />
                      }
                    />
                  );
                })}
            </Stack>
          </Stack>
        </Box>
        <TrimaLoadingButton
          disabled={checkedIds.length === 0}
          variant="contained"
          color="primary"
          onClick={copy}>
          複製
        </TrimaLoadingButton>
        <VMargin />
        <TrimaButton variant="outlined" onClick={cancel}>
          キャンセル
        </TrimaButton>
      </View>
    );
  };
  const {windowWidth} = useWidth();
  const widthStyle =
    windowWidth > 600 ? {width: 568, alignSelf: 'center' as const} : undefined;

  return (
    <Portal>
      <Dialog
        visible={visible}
        onDismiss={cancel}
        dismissable={false}
        style={widthStyle}>
        <Dialog.Title>他店舗を選択</Dialog.Title>
        <Dialog.Content>{render()}</Dialog.Content>
      </Dialog>
    </Portal>
  );
};

const CouponListItem: React.FC<{
  creative: any;
  from?: ScreenType;
}> = React.memo(({creative, from}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDialog, setDialog] = React.useState<boolean>(false);
  const [showCopyDialog, setCopyDialog] = React.useState<boolean>(false);
  const {selected} = ShopContainer.useContainer();

  const navigation = useNavigation<NProp>();
  const goto = () => {
    if (from === 'CreativesCreate' || from === 'CreativesGroupCreate') {
      // 配信作成画面からきたので、選択されたクーポン情報を渡して戻る。
      navigation.navigate(from, {type: creative.type, adsId: creative.id});
    } else {
      // 通常は遷移ページへ
      navigation.navigate(
        creative.type === 'coupon' ? 'CouponsDetail' : 'NewsDetail',
        {id: creative.id},
      );
    }
  };
  const gotoEdit = () =>
    navigation.navigate(
      creative.type === 'coupon' ? 'CouponsEdit' : 'NewsEdit',
      {id: creative.id, from: 'CouponsMain'},
    );
  const gotoCopy = () =>
    navigation.navigate(
      creative.type === 'coupon' ? 'CouponsCopy' : 'NewsCopy',
      {id: creative.id, from: 'CouponsMain'},
    );
  const gotoCreative = () =>
    navigation.navigate('CreativesCreate', {
      type: creative.type,
      adsId: creative.id,
    });
  const gotoDash = () => navigation.navigate('DashBoard');
  const onDismiss = () => {
    setDialog(false);
  };
  const onCopyDismiss = () => {
    setCopyDialog(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const visiblePopup = !from;

  return (
    <>
      <ListItem
        sx={{
          '&.MuiListItem-root': {
            padding: 0,
            bgcolor: 'background.paper',
            border: `1px solid ${Colors.main}`,
          },
        }}
        secondaryAction={
          visiblePopup && (
            <>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <MoreVert color="primary" />
              </IconButton>
              <MuiMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={gotoEdit}>
                  <ListItemIcon>
                    <MuiEdit fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{color: 'primary.main'}}>
                    編集する
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={gotoCopy}>
                  <ListItemIcon>
                    <FileCopyOutlined fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{color: 'primary.main'}}>
                    複製する
                  </ListItemText>
                </MenuItem>
                <MenuItem
                  disabled={!selected?.groupId}
                  onClick={() => {
                    handleClose();
                    setCopyDialog(true);
                  }}>
                  <ListItemIcon>
                    <SvgIcon component={Copy2GroupIcon}></SvgIcon>
                  </ListItemIcon>
                  <ListItemText sx={{color: 'primary.main'}}>
                    他店舗に
                    <br />
                    複製する
                  </ListItemText>
                </MenuItem>
                <MenuItem onClick={gotoCreative}>
                  <ListItemIcon>
                    <Send fontSize="small" color="primary" />
                  </ListItemIcon>
                  <ListItemText sx={{color: 'primary.main'}}>
                    配信する
                  </ListItemText>
                </MenuItem>
                {!creative.used && (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setDialog(true);
                    }}>
                    <ListItemIcon>
                      <Delete fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText sx={{color: 'primary.main'}}>
                      削除する
                    </ListItemText>
                  </MenuItem>
                )}
              </MuiMenu>
            </>
          )
        }
        disablePadding>
        <ListItemButton
          onClick={goto}
          sx={{
            '&.MuiButtonBase-root': {
              py: 2,
            },
          }}>
          <ListItemText
            id={creative.id}
            primary={
              <>
                <Box display="flex" flexDirection="row">
                  <Typography
                    style={{fontWeight: 500, color: Colors.main}}
                    variant="subtitle1"
                    component="span">
                    {creative.title}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block">
                    {creative.name}
                  </Typography>
                </Box>
                <Box pt={0.5}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block">
                    {creative.price}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    variant="caption"
                    component="span"
                    display="block">
                    <MinText>{creative.delivery}</MinText>
                  </Typography>
                </Box>
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      <CouponDeleteDialog
        visible={showDialog}
        item={creative}
        onDismiss={onDismiss}
        onError={gotoDash}
      />
      <CouponCopyDialog
        visible={showCopyDialog}
        item={creative}
        onDismiss={onCopyDismiss}
        onError={gotoDash}
      />
    </>
  );
});

const styles = StyleSheet.create({
  newButton: {
    ...CommonStyles.margin.top,
    height: 40,
  },
  delete: {
    backgroundColor: Colors.delete,
  },
  deleteText: {
    color: Colors.delete,
  },
});

export const Coupons = {
  Main,
  Copy,
  Create,
  Detail,
  Edit,
};
