// ヘルパー関数を置くつもり
import dayjs from 'dayjs';
import _ from 'lodash';
import {openURL} from './inner';

function sepComma(price: number): string {
  return String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

function percent(dividend: number, divisor: number, precision: number): number {
  if (!divisor) {
    return 0; // 割れないのでとりあえず0を返す
  }
  return _.round(_.divide(dividend, divisor) * 100, precision);
}

const DAY_FORMAT = 'YYYY/MM/DD';
const INVALID_DATE = '不明';
function toJapanDay(date: string): string {
  if (!date) {
    return INVALID_DATE;
  }
  const dj = dayjs(date);
  return dj.isValid() ? dj.utcOffset(9).format(DAY_FORMAT) : INVALID_DATE;
}

const DAY_TIME_FORMAT = 'YYYY/MM/DD HH:mm';
function toJapanDayTime(date: string): string {
  if (!date) {
    return INVALID_DATE;
  }
  const dj = dayjs(date);
  return dj.isValid() ? dj.utcOffset(9).format(DAY_TIME_FORMAT) : INVALID_DATE;
}

export const Helpers = {
  openURL,
  sepComma,
  percent,
  toJapanDay,
  toJapanDayTime,
};

// 適切な置き場所があればそちらへ
export * from './errors';
export * from './hooks';
export * from './navigation';
export * from './queue';
export * from './types';
