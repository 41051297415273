import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
  createTheme as MuiCreateTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React, {ReactElement} from 'react';
import {AppRegistry, Platform} from 'react-native';
import {DefaultTheme, Provider as PaperProvider} from 'react-native-paper';
import {BrowserRouter} from 'react-router-dom';
import './font';
import {Navigation} from './navigation';
import {Colors} from './theme';
import {AppConfig} from './_proto/config/AppConfig';

if (AppConfig.Stage === 'prod') {
  console.log = () => null;
  console.info = () => null;
  console.debug = () => null;
  console.warn = () => null;
  console.error = () => null;
}

console.log('loadStripe', AppConfig.StripePromise);
const stripePromise = loadStripe(AppConfig.StripePromise);

const theme = {
  ...DefaultTheme,
  dark: false,
  roundness: 8,
  colors: {
    ...DefaultTheme.colors,
    primary: Colors.main,
    accent: Colors.accent,
    background: Colors.white,
    surface: Colors.base,
    text: Colors.black,
    disabled: Colors.darkgray,
  },
};

const muiTheme = MuiCreateTheme({
  palette: {
    primary: {
      main: Colors.main,
    },
    secondary: {
      main: Colors.accent,
    },
    error: {
      main: Colors.delete,
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Hiragino Kaku Gothic ProN"',
      'Meiryo',
      'sans-serif',
    ].join(','),
    // Account for base font-size of 62.5%.
    // htmlFontSize: 10,
  },
});

export function App(): ReactElement {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <PaperProvider theme={theme}>
            <MuiThemeProvider theme={muiTheme}>
              <CssBaseline />
              <GlobalStyles
                styles={{
                  // html: {fontSize: '62.5%'},
                  body: {
                    fontFamily: [
                      'Roboto',
                      '"Helvetica Neue"',
                      'Arial',
                      '"Hiragino Kaku Gothic ProN"',
                      'Meiryo',
                      'sans-serif',
                    ],
                  },
                }}
              />
              <Navigation />
            </MuiThemeProvider>
          </PaperProvider>
        </Elements>
      </BrowserRouter>
    </React.StrictMode>
  );
}

AppRegistry.registerComponent('example', () => App);
if (Platform.OS === 'web') {
  AppRegistry.runApplication('example', {
    rootTag: document.getElementById('root'),
  });
}
