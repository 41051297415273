import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {BRAKE_POINT, CreativeSettings, useResponsive} from '../../components';
import {
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {CommonStyles} from '../../theme';

type NProp = MainNavigationProp<'CreativesCreate'>;
type RProp = MainRouteProp<'CreativesCreate'>;

export const Create: React.FC = React.memo(() => {
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const {isMobile} = useResponsive();
  const directionStyle = isMobile ? {} : CommonStyles.flex.row;
  const blockStyle = isMobile ? {} : {width: 399};
  const ads =
    route.params && route.params.type && route.params.adsId
      ? {
          type: route.params.type,
          id: route.params.adsId,
        }
      : undefined;
  const targetId = route.params?.targetId;
  if (ads) {
    // 一度取り出したらあとは不要（逆に他のを選択した時に邪魔しても困るので）クリア。
    navigation.setParams({adsId: undefined});
  }
  if (targetId) {
    console.log('[targetId]', targetId);
    navigation.setParams({targetId: undefined});
  }
  return (
    <Container>
      <CreativeSettings.Provider ads={ads} targetId={targetId}>
        <MaxWidth
          maxWidth={BRAKE_POINT.desktop + 16}
          style={CommonStyles.margin.all}
        >
          <WithHint id="creativesCreate">
            <Menu>配信する</Menu>
          </WithHint>
          <VMargin />
          <View style={directionStyle}>
            <FormBlock style={blockStyle}>
              <CreativeSettings.AdsContent
                onSelect={(type, id) => {
                  switch (type) {
                    case 'couponNew':
                      return navigation.navigate('CouponsCreate', {
                        from: 'CreativesCreate',
                      });
                    case 'couponList':
                      return navigation.navigate('CouponsMain', {
                        from: 'CreativesCreate',
                      });
                    case 'couponEdit':
                      return navigation.navigate('CouponsEdit', {
                        from: 'CreativesCreate',
                        id,
                      });
                  }
                }}
              />
            </FormBlock>
            <View style={[CommonStyles.margin.top, CommonStyles.margin.left]} />
            <View style={CommonStyles.flex.full}>
              <FormBlock>
                <CreativeSettings.TargetContent
                  onSelect={(type, id) => {
                    switch (type) {
                      case 'list':
                        return navigation.navigate('TargetsMain', {
                          from: 'CreativesCreate',
                        });
                      case 'new':
                        return navigation.navigate('TargetsCreate', {
                          from: 'CreativesCreate',
                        });
                      case 'edit':
                        return navigation.navigate('TargetsEdit', {
                          from: 'CreativesCreate',
                          id,
                        });
                    }
                  }}
                />
              </FormBlock>
              <VMargin />
              <CreativeSettings.TermForm />
            </View>
          </View>
          <CreativeSettings.Submit
            onDone={() => navigation.navigate('CreativesMain')}
          />
        </MaxWidth>
      </CreativeSettings.Provider>
    </Container>
  );
});
