import merge from 'deepmerge';
import {CouponUsage, DiscountType} from '../../API';
import {
  RadioProps,
  SelectProps,
  STRING_LIMIT,
  TextInputProps,
  yup,
  yupUtil,
} from '../Elements';

// フォームの型
export type FormData = {
  id?: string; // データのID（ユーザーへのフォームなしで裏で保持）
  name: string;
  rule?: string;
  text?: string;
  title: string;
  discountType: DiscountType;
  [DiscountType.BY_PRICE]?: number;
  [DiscountType.BY_PERCENT]?: number;
  priceFrom?: number;
  priceTo?: number;
  freeText?: string;
  usage?: CouponUsage;
  // 画像添付判定用
  imageEnabled: boolean;
  cvLinkUrl?: string | null;
  cvLinkText?: string;
  cvPhone?: string;
};

// 文字数上限
const limit: Pick<
  Required<{[P in keyof FormData]: number}>,
  'name' | 'rule' | 'text' | 'title' | 'freeText' | 'cvLinkText'
> = {
  name: 26,
  rule: 150,
  text: 2000,
  title: STRING_LIMIT.title,
  freeText: 18,
  cvLinkText: 18,
};

// フォームのルール
export const schemaFormData: yup.SchemaOf<FormData> = yup.object().shape({
  id: yupUtil.string(),
  name: yupUtil.stringRequired(limit.name),
  rule: yupUtil.string(limit.rule),
  text: yupUtil.string(limit.text),
  title: yupUtil.stringRequired(limit.title),
  discountType: yup
    .mixed<DiscountType>()
    .oneOf(Object.values(DiscountType))
    .required(),
  // DiscountType の指定によって必須となるフォームが変わるので、whenを用いて条件付きのバリデーションを設定
  [DiscountType.BY_PRICE]: yup.mixed().when('discountType', {
    is: DiscountType.BY_PRICE,
    then: yupUtil.priceRequired(),
  }),
  [DiscountType.BY_PERCENT]: yup.mixed().when('discountType', {
    is: DiscountType.BY_PERCENT,
    then: yupUtil.percentRequired(),
  }),
  priceFrom: yup.mixed().when('discountType', {
    is: DiscountType.FROM_PRICE,
    then: yupUtil.priceRequired(),
  }),
  priceTo: yup.mixed().when('discountType', {
    is: DiscountType.FROM_PRICE,
    then: yupUtil
      .priceRequired()
      .test(
        'comparePriceFrom',
        '値引き後の金額が値引き前の金額より多いか、同額になっています',
        (value, context) => {
          const priceFrom: string | number | undefined =
            context.parent.priceFrom;
          const pFrom = Number(priceFrom);
          if (isNaN(pFrom)) {
            return true;
          }
          const pTo = Number(value);
          if (isNaN(pTo)) {
            return true;
          }
          return pTo < pFrom;
        },
      ),
  }),
  freeText: yup.mixed().when('discountType', {
    is: DiscountType.BY_TEXT,
    then: yupUtil.stringRequired(limit.freeText),
  }),
  usage: yup.mixed<CouponUsage>().oneOf(Object.values(CouponUsage)).required(),
  imageEnabled: yupUtil.imageRequired(),
  cvLinkUrl: yupUtil.url(STRING_LIMIT.url),
  cvLinkText: yup.mixed().when('cvLinkUrl', {
    is: (value: string) => !!value,
    then: yupUtil.stringRequired(limit.cvLinkText),
  }),
  cvPhone: yupUtil.phoneOptional(),
});

// TextInput表示関連データ
const maxLengthList = Object.fromEntries(
  // limit から map で maxLength の指定を生成
  Object.entries(limit).map(([_, value]) => [_, {maxLength: value}]),
);
const formTexts: {
  [P in keyof Omit<FormData, 'imageEnabled'>]: TextInputProps<FormData>;
} = {
  name: {
    name: 'name',
    label: '見出し1（対象商品・サービス）',
    placeholder: '生ビール',
    withHint: 'couponsHead',
    required: true,
  },
  rule: {
    name: 'rule',
    label: '利用条件',
    notRequired: true,
    placeholder: '他のクーポンと併用不可',
    withHint: 'couponCondition',
    multiline: true,
  },
  text: {
    name: 'text',
    label: '詳しい説明',
    notRequired: true,
    placeholder:
      '1会計につき4名様まで、1杯目の生ビール（中ジョッキ）の税込金額から30％割引いたします。ご注文時にこの画面を提示してください。店舗公式サイトはこちら https://support.shop.trip-mile.com/hc/ja ※外部サイトのリンクを貼ることができます。',
    withHint: 'couponDescription',
    multiline: true,
    style: {height: 320},
  },
  title: {
    name: 'title',
    label: 'タイトル（管理用）',
    placeholder: '生ビール30%OFF・何回でも',
    required: true,
  },
  discountType: {name: 'discountType'},
  [DiscountType.BY_PRICE]: {
    name: DiscountType.BY_PRICE,
    label: '値引き内容',
    postfix: '　円引き',
    placeholder: '100',
    required: true,
  },
  [DiscountType.BY_PERCENT]: {
    name: DiscountType.BY_PERCENT,
    label: '値引き内容',
    postfix: '　％OFF',
    placeholder: '30',
    required: true,
  },
  priceFrom: {
    name: 'priceFrom',
    label: '値引き前',
    postfix: '　円　',
    required: true,
  },
  priceTo: {
    name: 'priceTo',
    label: '値引き後',
    postfix: '　円　',
    required: true,
  },
  freeText: {
    name: 'freeText',
    label: 'その他内容',
    placeholder: 'テキスト',
    required: true,
  },
  cvLinkUrl: {
    name: 'cvLinkUrl',
    label: 'ランディングページURL',
    placeholder: 'https://geot.jp/',
    withHint: 'couponReserve',
    notRequired: true,
  },
  cvLinkText: {
    name: 'cvLinkText',
    label: 'ボタンラベル',
    placeholder: '詳しくはこちら',
    notRequired: true,
  },
  cvPhone: {
    name: 'cvPhone',
    label: '問い合わせ電話番号',
    placeholder: '09012341234',
    notRequired: true,
  },
};

// deepmerge で maxLength をくっつけて利用
export const formInfos = merge(formTexts, maxLengthList);

// Radio表示関連データ
export const usageInfo: RadioProps<FormData> = {
  name: 'usage',
  label: 'クーポン利用可能回数',
  items: {
    [CouponUsage.INFINITE]: '何回でも利用可',
    [CouponUsage.ONETIME]: '１回のみ利用可',
  },
  defaultValue: CouponUsage.INFINITE,
  withHint: 'couponUsed',
};

const discountTypeLabels: {[P in DiscountType]: string} = {
  BY_PRICE: '〇〇円引き',
  BY_PERCENT: '〇〇％OFF',
  FROM_PRICE: '〇〇円⇨〇〇円',
  BY_HALF: '半額',
  BY_FREE: '無料',
  BY_TEXT: 'フリーテキスト',
};
const discountTypeList: {label: string; value: DiscountType}[] = Object.values(
  DiscountType,
)
  .map((value) => {
    return {label: discountTypeLabels[value], value};
  })
  .filter((v) => v.label);

export const discountTypeInfo: SelectProps<FormData> = {
  name: 'discountType',
  label: '見出し2（特典内容）',
  options: discountTypeList,
  defaultValue: DiscountType.BY_TEXT,
  withHint: 'discount',
  required: true,
};
