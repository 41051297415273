import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {useTheme} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {AppConfig} from '../../_proto/config/AppConfig';
import {Helpers} from '../../helper';
import {Colors, CommonStyles} from '../../theme';
import {FormBlock, MinText, Text, VMargin} from '../Elements';
import {isUnderLimit} from './commonFunc';
import {TargetingUserCount} from './types';

const styleBaseBack = {backgroundColor: Colors.base};

function getDisplayCount(
  target?: TargetingUserCount,
): string | undefined | number {
  return target === 'error'
    ? 'エラー'
    : typeof target === 'string' || target === undefined
    ? undefined // ActivityIndicator 出したり
    : target === 0
    ? '- - -　人'
    : target < AppConfig.MinTargetCount
    ? `${AppConfig.MinTargetCount}人未満`
    : `${Helpers.sepComma(Math.floor(target / 10) * 10)} 人`;
}

export const PersonBadge: React.FC<{target: TargetingUserCount}> = React.memo(
  ({target}) => {
    const {colors} = useTheme();
    const count = getDisplayCount(target);
    return (
      <View style={styles.badge}>
        <>
          <MinText style={styles.text} numberOfLines={1}>
            {'対象者：'}
          </MinText>
          {!count ? (
            <ActivityIndicator size={16} color={colors.text} />
          ) : (
            <MinText style={styles.text} numberOfLines={1}>
              {count}
            </MinText>
          )}
        </>
      </View>
    );
  },
);

export const PersonInfo: React.FC<{
  target?: TargetingUserCount;
}> = ({target}) => {
  const count = isUnderLimit(target ?? 'init')
    ? undefined
    : Math.floor((target as number) / 10) * 10;
  const estimatedArray = [
    {
      days: 1,
      estimatedViewers: Math.round(count ? count * 0.1 : 0),
      estimatedAmount: Math.round(count ? count * 0.1 : 0) * 3,
    },
    {
      days: 7,
      estimatedViewers: Math.round(count ? count * 0.2 : 0),
      estimatedAmount: Math.round(count ? count * 0.2 : 0) * 3,
    },
    {
      days: 14,
      estimatedViewers: Math.round(count ? count * 0.24 : 0),
      estimatedAmount: Math.round(count ? count * 0.24 : 0) * 3,
    },
    {
      days: 21,
      estimatedViewers: Math.round(count ? count * 0.27 : 0),
      estimatedAmount: Math.round(count ? count * 0.27 : 0) * 3,
    },
    {
      days: 28,
      estimatedViewers: Math.round(count ? count * 0.3 : 0),
      estimatedAmount: Math.round(count ? count * 0.3 : 0) * 3,
    },
  ];
  return (
    <View>
      <View style={[CommonStyles.flex.row, CommonStyles.flex.center]}>
        <Text>配信対象者：</Text>
        <Text style={styles.personNumber}>
          {count ? Helpers.sepComma(count) : '- - -'}
        </Text>
        <Text>人</Text>
      </View>
      <VMargin />
      <FormBlock>
        <TableContainer style={{width: '100%'}}>
          <Table
            sx={{border: `1px solid ${Colors.base}`, minWidth: 600}}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow style={styleBaseBack}>
                <TableCell
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  align="center"
                  style={{width: 100}}
                >
                  配信開始日
                  <br />
                  からの日数
                </TableCell>
                {estimatedArray.map((estimated) => {
                  return (
                    <TableCell
                      sx={{p: 1, border: `1px solid ${Colors.base}`}}
                      align="center"
                    >
                      {estimated.days}日後
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover>
                <TableCell
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  align="center"
                >
                  推定
                  <br />
                  閲覧者数（人）
                </TableCell>
                {estimatedArray.map((estimated) => {
                  return (
                    <TableCell
                      sx={{p: 1, border: `1px solid ${Colors.base}`}}
                      align="right"
                    >
                      {count
                        ? Helpers.sepComma(estimated.estimatedViewers)
                        : '---'}
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow hover>
                <TableCell
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  align="center"
                >
                  推定広告料
                  <br />
                  （円）
                </TableCell>
                {estimatedArray.map((estimated) => {
                  return (
                    <TableCell
                      sx={{p: 1, border: `1px solid ${Colors.base}`}}
                      align="right"
                    >
                      {count
                        ? Helpers.sepComma(estimated.estimatedAmount)
                        : '---'}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </FormBlock>
    </View>
  );
};

// 対象者数
export const TargetCounter: React.FC<{count: TargetingUserCount}> = ({
  count,
}) => {
  return (
    <View style={styles.target}>
      <PersonBadge target={count} />
    </View>
  );
};

const styles = StyleSheet.create({
  badge: {
    backgroundColor: Colors.accent,
    width: 140,
    height: 30,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 15,
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.between,
    ...CommonStyles.flex.crossCenter,
  },
  text: {
    color: Colors.white,
  },
  textDisabled: {
    color: Colors.darkgray,
  },
  personNumber: {
    width: 130,
    textAlign: 'center',
  },
  target: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
});
