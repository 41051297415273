import {Auth} from 'aws-amplify';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {EmailFormData, PasswordForgotFormData} from './schema';

export type PasswordForgotStep =
  | 'init'
  | 'mfa'
  | 'passwordForgot'
  | 'passwordForgotSubmit'
  | 'done';

type UsePasswordForgot = {
  step: PasswordForgotStep;
  error?: Error;
  phoneNumber: string;
  forgotPassword(data: EmailFormData): void;
  forgotPasswordSubmit(data: PasswordForgotFormData, navigation: any): void;
  resetStep: () => void;
};

function useSignIn(): UsePasswordForgot {
  const [step, setStep] = useState<PasswordForgotStep>('init');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<Error | undefined>();
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const forgotPassword = useCallback(async (data: EmailFormData) => {
    setStep('passwordForgot');
    setEmail(data.email);
    try {
      const user = await Auth.forgotPassword(data.email);
      console.log(user);
      setPhoneNumber(user.CodeDeliveryDetails.Destination);
      setStep('mfa');
    } catch (err: any) {
      setError(err);
    }
    return;
  }, []);

  const forgotPasswordSubmit = useCallback(
    async (data: PasswordForgotFormData, navigation: any) => {
      setStep('passwordForgotSubmit');
      try {
        const user = await Auth.forgotPasswordSubmit(
          email,
          data.verificationCode,
          data.newPassword,
        );
        console.log(user);
        setStep('done');
        navigation.navigate('SignIn');
      } catch (err: any) {
        setError(err);
      }
      return;
    },
    [email],
  );

  const resetStep = () => {
    setStep('init');
  };

  return {
    step,
    error,
    phoneNumber,
    forgotPassword,
    forgotPasswordSubmit,
    resetStep,
  };
}

export const PasswordForgotContainer = createContainer(useSignIn);
