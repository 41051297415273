import dayjs from 'dayjs';
import {GetSaaSDeliveryHistoryQuery, GetSaaSNewsHistoryQuery} from '../../API';
import {DeliveryStatus, getDeliveryStatus} from '../../service';
import {
  RadioProps,
  STRING_LIMIT,
  TextInputProps,
  yup,
  yupUtil,
} from '../Elements';

export type AdsType = 'coupon' | 'news';
export type AdsInfo = {
  type?: AdsType;
  id?: string;
};

export function checkAdsInfo(ads: AdsInfo | undefined): AdsType | undefined {
  if (ads) {
    if (ads.type === 'coupon' && ads.id) {
      return 'coupon';
    } else if (ads.type === 'news' && ads.id) {
      return 'news';
    }
  }
  return undefined;
}

// 配信は新規と編集で必須フィールドがだいぶ異なるので別に扱う
export type CreativeUpdateForm = {
  id?: string;
  startAt: string;
  endAt: string;
  useBudget: boolean;
  budget?: number;
  title: string; // 管理用タイトル
  targetUsers?: number; // 使用金額計算用不可視フォーム
};

export type CreativeForm = {
  ads?: AdsInfo;
  adsUpdated?: boolean; // 更新フラグ用不可視フォーム
  targetId?: string;
  targetUpdated?: boolean; // 更新フラグ用不可視フォーム
} & CreativeUpdateForm;

export type CreativeGroupForm = {
  id?: string;
  startAt: string;
  endAt: string;
  title: string; // 管理用タイトル
  ads?: AdsInfo;
  adsUpdated?: boolean; // 更新フラグ用不可視フォーム
  groups: Array<{enable: boolean; budget?: number; noUpperLimit: boolean}>;
};

export const defaultAds = {type: 'none', id: ''};

export const schemaCreativeForm: yup.SchemaOf<CreativeForm> = yup
  .object()
  .shape({
    id: yupUtil.string(),
    ads: yup
      .object({
        type: yup.mixed<AdsType>().oneOf(['coupon', 'news']).required(), // 仮
        id: yupUtil.stringRequired(),
      })
      .default(defaultAds)
      .required(),
    adsUpdated: yup.boolean(),
    targetId: yupUtil.stringRequired(),
    targetUpdated: yup.boolean(),
    startAt: yupUtil
      .stringRequired()
      .test(
        'compareEndAt',
        '終了日時より前に設定してください。',
        (value, context) => {
          const endAt: string | undefined = context.parent.endAt;
          if (!endAt) {
            return true;
          }
          const end = dayjs(endAt);
          if (!end.isValid()) {
            return true;
          }
          return end.isAfter(value);
        },
      ),
    endAt: yupUtil
      .stringRequired()
      .test('needAfterNow', '過去の日時が設定されています。', (value) =>
        dayjs().isBefore(value),
      )
      .test(
        'longTermCheck',
        '配信期間は90日以下で設定してください。',
        (value, context) => {
          const startAt: string | undefined = context.parent.startAt;
          if (!startAt) {
            return true;
          }
          const start = dayjs(startAt);
          if (!start.isValid()) {
            return true;
          }
          return start.add(90, 'day').isAfter(value);
        },
      ),
    useBudget: yupUtil.booleanRequired(),
    budget: yup.mixed().when('useBudget', {is: true, then: yupUtil.budget()}),
    title: yupUtil.stringRequired(STRING_LIMIT.title),
    targetUsers: yup.number(),
  });

export const schemaCreativeGroupForm: yup.SchemaOf<CreativeGroupForm> = yup
  .object()
  .shape({
    id: yupUtil.string(),
    ads: yup
      .object({
        type: yup.mixed<AdsType>().oneOf(['coupon', 'news']).required(), // 仮
        id: yupUtil.stringRequired(),
      })
      .default(defaultAds)
      .required(),
    adsUpdated: yup.boolean(),
    targetId: yupUtil.string(),
    targetUpdated: yup.boolean(),
    startAt: yupUtil
      .stringRequired()
      .test(
        'compareEndAt',
        '終了日時より前に設定してください。',
        (value, context) => {
          const endAt: string | undefined = context.parent.endAt;
          if (!endAt) {
            return true;
          }
          const end = dayjs(endAt);
          if (!end.isValid()) {
            return true;
          }
          return end.isAfter(value);
        },
      ),
    endAt: yupUtil
      .stringRequired()
      .test('needAfterNow', '過去の日時が設定されています。', (value) =>
        dayjs().isBefore(value),
      )
      .test(
        'longTermCheck',
        '配信期間は90日以下で設定してください。',
        (value, context) => {
          const startAt: string | undefined = context.parent.startAt;
          if (!startAt) {
            return true;
          }
          const start = dayjs(startAt);
          if (!start.isValid()) {
            return true;
          }
          return start.add(90, 'day').isAfter(value);
        },
      ),
    title: yupUtil.stringRequired(STRING_LIMIT.title),
    targetUsers: yup.number(),
    groups: yup.array().of(
      yup.object().shape({
        enable: yupUtil.booleanRequired(),
        budget: yup.mixed().when(['enable', 'noUpperLimit'], {
          is: (enable: boolean, noUpperLimit: boolean) =>
            enable && !noUpperLimit,
          then: yupUtil.budget(),
        }),
        noUpperLimit: yupUtil.booleanRequired(),
      }),
    ),
  });

export const schemaCreativeUpdateForm: yup.SchemaOf<CreativeUpdateForm> = yup
  .object()
  .shape({
    id: yupUtil.string(),
    startAt: yupUtil
      .stringRequired()
      .test(
        'compareEndAt',
        '終了日時より前に設定してください。',
        (value, context) => {
          const endAt: string | undefined = context.parent.endAt;
          if (!endAt) {
            return true;
          }
          const end = dayjs(endAt);
          if (!end.isValid()) {
            return true;
          }
          return end.isAfter(value);
        },
      ),
    endAt: yupUtil
      .stringRequired()
      .test('beforeNow', '過去の日時が設定されています。', (value) =>
        dayjs().isBefore(value),
      )
      .test(
        'longTermCheck',
        '配信期間は90日以下で設定してください。',
        (value, context) => {
          const startAt: string | undefined = context.parent.startAt;
          if (!startAt) {
            return true;
          }
          const start = dayjs(startAt);
          if (!start.isValid()) {
            return true;
          }
          return start.add(90, 'day').isAfter(value);
        },
      ),
    useBudget: yupUtil.booleanRequired(),
    budget: yup.mixed().when('useBudget', {is: true, then: yupUtil.budget()}),
    title: yupUtil.stringRequired(STRING_LIMIT.title),
    targetUsers: yup.number(),
  });

export const budgetRadio: RadioProps<Pick<CreativeForm, 'useBudget'>> = {
  name: 'useBudget',
  label: '予算上限',
  items: {false: '上限なし', true: '上限を設定する'},
  defaultValue: true,
  withHint: 'creativeBudgetLimit',
};

export const titleInput: TextInputProps<Pick<CreativeForm, 'title'>> = {
  name: 'title',
  label: 'タイトル（管理用）',
  placeholder: '夏季限定・生ビールクーポン',
  maxLength: STRING_LIMIT.title,
  required: true,
};

export type DeliveryCouponHistoryItem = NonNullable<
  GetSaaSDeliveryHistoryQuery['getSaaSDeliveryHistory']
>;

export type DeliveryNewsHistoryItem = NonNullable<
  GetSaaSNewsHistoryQuery['getSaaSNewsHistory']
>;

export type TargetCreative =
  | undefined
  | {
      type: 'coupon';
      creative: DeliveryCouponHistoryItem;
    }
  | {
      type: 'news';
      creative: DeliveryNewsHistoryItem;
    };

export type CreativeRestored = Omit<CreativeForm, 'ads' | 'targetId'> & {
  status: DeliveryStatus;
  groups: Array<any>;
};

export function convertForRestore(
  item: DeliveryCouponHistoryItem | DeliveryNewsHistoryItem,
): CreativeRestored {
  const startAt = dayjs(item.startAt);
  const endAt = dayjs(item.endAt);
  if (
    !item.id ||
    item.budget === null ||
    item.budget === undefined ||
    !item.startAt ||
    !item.endAt ||
    !startAt.isValid() ||
    !endAt.isValid()
  ) {
    throw '[convertForRestore] invalid creative data';
  }
  let state;
  if ('couponState' in item) {
    state = item.couponState;
  } else if ('newsState' in item) {
    state = item.newsState;
  }

  const status = getDeliveryStatus({
    budget: item.budget,
    balance: item.balance ?? 0,
    start: startAt,
    end: endAt,
    state,
  });

  // ads, targetId あたり復帰は大変なので、必要になるまで保留
  return {
    id: item.id,
    startAt: startAt.toISOString(),
    endAt: endAt.toISOString(),
    title: item.title ?? '',
    budget: item.budget,
    useBudget: item.budget !== 0,
    status,
    groups: [],
  };
}

/**
 * 使用金額の算出
 * @param item
 * @memo ここじゃなくて service においた方がいいかも
 */
export function getUsedMoney(
  item:
    | DeliveryCouponHistoryItem
    | DeliveryNewsHistoryItem
    | {budget?: number | null; balance?: number | null},
): number {
  const {budget, balance} = item;
  if (
    budget === null ||
    budget === undefined ||
    balance === null ||
    balance === undefined
  ) {
    throw '[getUsedMoney] invalid creative data';
  }
  return budget - balance;
}
