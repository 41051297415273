import React from 'react';
import {useFormContext} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import AddressPickerMap from '../../_proto/parts/map/AddressPickerMap';
import CenterMarkerMap from '../../_proto/parts/map/CenterMarkerMap';
import {MapContainer} from '../../_proto/parts/map/MapContainer';
import {LngLatIPC} from '../../_proto/services/ZipAddressRepository';
import {Colors, CommonStyles} from '../../theme';
import {Sheet, Text, TrimaLoadingButton, VMargin, WithHint} from '../Elements';
import {AddressPlace, PlacePickerContainer} from './container';

// AddressPlace 型を含むフォーム内でのみ利用可能
export const PlacePicker: React.FC = () => {
  const {
    watch,
    formState: {errors},
  } = useFormContext<AddressPlace>();
  const {refine, showRefine, onRefineDone, searchLonLat, searching} =
    PlacePickerContainer.useContainer();

  const lnglat = watch('location') ?? LngLatIPC;
  const addr = {
    prefId: '',
    pref: watch('pref') ?? '',
    city: watch('city') ?? '',
  };
  const detail = watch('detail');
  const building = watch('building');

  return refine ? (
    <MapContainer.Provider>
      <Sheet isVisible={refine} onDismiss={() => onRefineDone(null)}>
        <AddressPickerMap
          onSubmit={onRefineDone}
          address={addr}
          detail={detail}
          building={building}
          center={lnglat}
        />
      </Sheet>
    </MapContainer.Provider>
  ) : (
    <View>
      <View>
        <TrimaLoadingButton
          variant="outlined"
          onClick={searchLonLat}
          disabled={searching}
          loading={searching}
          loadingPosition="start"
        >
          住所を所在地プレビューに反映する
        </TrimaLoadingButton>
        <VMargin />
        {errors.location?.lat && (
          <View style={styles.warning}>
            <Text style={styles.text}>{errors.location.lat.message}</Text>
          </View>
        )}
        <WithHint id="locationPreview">
          <Text>所在地プレビュー</Text>
        </WithHint>
        <VMargin />
        <View style={styles.map}>
          <MapContainer.Provider>
            <CenterMarkerMap center={lnglat} zoom={16} freezed />
          </MapContainer.Provider>
        </View>
        <VMargin />
        <TrimaLoadingButton
          variant="outlined"
          disabled={searching}
          loading={searching}
          loadingPosition="start"
          onClick={showRefine}
        >
          位置情報の修正
        </TrimaLoadingButton>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  map: {
    ...CommonStyles.fullWidth,
    height: 335,
  },
  warning: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.darkgray,
    ...CommonStyles.padding.all,
    paddingHorizontal: 50,
    ...CommonStyles.margin.bottom,
  },
  text: {
    color: Colors.accent,
    textAlign: 'center',
  },
});
