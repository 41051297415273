import React from 'react';
import { DimensionValue, StyleSheet, View, ViewStyle } from 'react-native';

type CenterMarkerProp = {
  lng?: number;
  lat?: number;
  style?: ViewStyle;
};

const CenterMark: React.FC<CenterMarkerProp> = ({style}) => {
  return (
    <View style={[styles.pin, style]}>
      <svg
        id="コンポーネント_109_2"
        data-name="コンポーネント 109 – 2"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="46.503"
        viewBox="0 0 32 46.503"
      >
        <ellipse
          id="楕円形_716"
          data-name="楕円形 716"
          cx="9.6"
          cy="1.632"
          rx="9.6"
          ry="1.632"
          transform="translate(6.4 43.24)"
          fill="rgba(0,0,0,0.5)"
        />
        <g
          id="グループ_3160"
          data-name="グループ 3160"
          transform="translate(0 0)"
        >
          <path
            id="パス_2702"
            data-name="パス 2702"
            d="M6.361.989a1.274,1.274,0,0,1,2.484,0L15.2,26.936H0Z"
            transform="translate(23.602 44.875) rotate(180)"
            fill="#4c3575"
          />
          <ellipse
            id="楕円形_715"
            data-name="楕円形 715"
            cx="16"
            cy="16.317"
            rx="16"
            ry="16.317"
            fill="#7e57c2"
          />
          <path
            id="Icon_awesome-store-alt"
            data-name="Icon awesome-store-alt"
            d="M8.684,10.624H3.475V6.2H1.738V13.28a.876.876,0,0,0,.868.885H9.552a.876.876,0,0,0,.868-.885V6.2H8.684Zm8.535-6.69L14.9.393A.868.868,0,0,0,14.18,0H3.187a.86.86,0,0,0-.722.393L.151,3.934A.886.886,0,0,0,.873,5.312H16.5A.888.888,0,0,0,17.218,3.934Zm-3.326,9.789a.44.44,0,0,0,.434.443h.868a.44.44,0,0,0,.434-.443V6.2H13.892Z"
            transform="translate(7.316 9.234)"
            fill="#fff"
          />
        </g>
      </svg>
    </View>
  );
};

const styles = StyleSheet.create({
  pin: {
    position: 'absolute',
    top: 'calc(50% - 44px)' as DimensionValue,
    left: 'calc(50% - 16px)' as DimensionValue,
  },
});

export default CenterMark;
