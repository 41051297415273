import axios from 'axios';
import {AppConfig} from '../config/AppConfig';
import {SaaSRepository} from './SaaSRepository';

export class SaaSBillingRepository {
  static async createCustomer(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/create-customer`,
      data,
      config,
    );
    return result.data;
  }

  static async updateCustomer(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/update-customer`,
      data,
      config,
    );
    return result.data;
  }

  static async createCardSubscription(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/create-subscription`,
      data,
      config,
    );
    return result.data;
  }

  static async createPaidCustomer(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/create-paid-customer`,
      data,
      config,
    );
    return result.data;
  }

  static async getPaidStatus(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/get-paid-customer-status`,
      data,
      config,
    );
    return result.data;
  }

  static async createPaidSubscription(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/create-paid-subscription`,
      data,
      config,
    );
    return result.data;
  }

  static async retrieveCustomerPaymentMethod(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/retrieve-customer-payment-method`,
      data,
      config,
    );
    return result.data;
  }

  static async createCustomerPortalSession(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/create-customer-portal-session`,
      data,
      config,
    );
    return result.data;
  }

  static async cancelSubscription(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/cancel-subscription`,
      data,
      config,
    );
    return result.data;
  }

  static async notCancelSubscription(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/not-cancel-subscription`,
      data,
      config,
    );
    return result.data;
  }

  static async retrieveUpcomingInvoice(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/retrieve-upcoming-invoice`,
      data,
      config,
    );
    return result.data;
  }

  static async getListInvoices(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/get-list-invoices`,
      data,
      config,
    );
    return result.data;
  }

  static async getCustomer(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/get-stripe-custoemer`,
      data,
      config,
    );
    return result.data;
  }

  static async getListPaymentMethods(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/get-list-paymentmethods`,
      data,
      config,
    );
    return result.data;
  }

  static async addPaymentMethod(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/add-paymentmethod`,
      data,
      config,
    );
    return result.data;
  }

  static async updatePaymentMethod(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/update-paymentmethod`,
      data,
      config,
    );
    return result.data;
  }

  static async removePaymentMethod(data: any): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.PaymentInfoEndPoint}/remove-paymentmethod`,
      data,
      config,
    );
    return result.data;
  }
}
