import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useTheme} from 'react-native-paper';
import {Colors, CommonStyles} from '../../theme';

export const Overlay: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {colors} = useTheme();
  const backdrop = {backgroundColor: colors.backdrop};
  return (
    <View style={[backdrop, styles.overlay]}>
      <View style={styles.cationArea}>{children}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    ...CommonStyles.flex.center,
  },
  cationArea: {
    backgroundColor: Colors.white,
    ...CommonStyles.padding.all,
  },
});
