import React from 'react';
import {StyleSheet, View} from 'react-native';
import {IconButton, TouchableRipple} from 'react-native-paper';
import {Colors, CommonStyles} from '../../theme';
import {Text} from './Text';

// クーポン一覧とかの一覧ページに使う共通デザインを想定

const Item: React.FC<{
  onPress?(): void;
  popup?: React.ReactElement;
  visiblePopup?: boolean;
  children: React.ReactNode;
}> = ({onPress, visiblePopup, popup, children}) => {
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  return (
    <TouchableRipple onPress={onPress}>
      <View style={styles.item}>
        {children}
        {popup && visiblePopup && (
          <View style={styles.menuArea}>
            <View style={styles.centering}>
              <IconButton
                icon="dots-vertical"
                size={32}
                color={Colors.main}
                onPress={() => setShowPopup((prev) => !prev)}
              />
              <View style={styles.popupArea}>{showPopup && popup}</View>
            </View>
          </View>
        )}
      </View>
    </TouchableRipple>
  );
};

const Separator: React.FC = () => {
  return <View style={styles.separator} />;
};

export type PopupItem = {label: string; icon: string; onPress(): void};

const Title: React.FC<React.ComponentProps<typeof Text>> = ({
  style,
  children,
  ...rest
}) => {
  return (
    <Text style={[styles.textTitle, style]} {...rest}>
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  item: {
    backgroundColor: Colors.lightgray,
    borderColor: Colors.main,
    borderWidth: 1,
    ...CommonStyles.padding.all,
    ...CommonStyles.align.self.stretch,
  },
  separator: {
    height: 4,
    backgroundColor: Colors.base,
  },
  menuArea: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
  },
  centering: {
    flex: 1,
    ...CommonStyles.flex.center,
  },
  popupArea: {
    position: 'absolute',
    top: 20,
    right: 60,
    width: 120,
  },
  popup: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.darkgray,
    ...CommonStyles.flex.full,
    ...CommonStyles.align.self.stretch,
    ...CommonStyles.padding.all,
    ...CommonStyles.flex.center,
  },
  textButton: {
    height: 30,
  },
  textTitle: {
    color: Colors.main,
    numberOfLines: 1,
  },
});

export const List = {
  Item: Item,
  Separator: Separator,
  Title: Title,
};
