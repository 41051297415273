import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog as MuiDialog,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import {StyleSheet, View, ViewProps, ViewStyle} from 'react-native';
import {Dialog, HelperText, Portal, useTheme} from 'react-native-paper';
import {EditState} from '../../API';
import {SaaSNewsRepository} from '../../_proto/services/SaaSNewsRepository';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {CouponsContainer} from '../../container';
import {Helpers} from '../../helper';
import {MainScreenParams} from '../../navigation/MainScreen';
import {axiosHelper} from '../../service';
import {Colors, CommonStyles} from '../../theme';
import {
  DoubleButtons,
  MaxWidth,
  RequiredNotice,
  Submenu,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
  WithHint,
} from '../Elements';
import {ImagePicker} from '../ImagePicker';
import {Preview as PreviewComponent} from '../NewsPreview';
import {NewsCreator} from './container';
import {FormData, formInfos, schemaFormData} from './schema';

const Provider: React.FC<{
  mode?: 'edit' | 'new';
  editTarget?: {
    forms: FormData;
    images: SaaSImageContents[];
    editState?: EditState;
  };
  children?: React.ReactNode;
}> = ({mode = 'new', editTarget, children}) => {
  const {forms, images, editState} =
    mode === 'edit' && editTarget
      ? editTarget
      : {forms: undefined, images: undefined, editState: undefined};
  return (
    <VForm.Provider<FormData> schema={schemaFormData} defaultValues={forms}>
      <NewsCreator.Provider initialState={{images, forceState: editState}}>
        {children}
      </NewsCreator.Provider>
    </VForm.Provider>
  );
};

// Todo: ヒントボタンは仮試作でここに置いてある
const ImagePickers: React.FC = () => {
  const {addImage, images, removeImage, changeImage} =
    NewsCreator.useContainer();
  const {
    formState: {errors},
  } = useFormContext<FormData>();
  return (
    <View>
      <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
        <WithHint id="newsPhoto">
          <Text>写真</Text>
        </WithHint>
        <RequiredNotice />
      </View>
      <View style={CommonStyles.flex.row}>
        {[...Array(5)].map((_, i) => {
          return (
            <ImagePicker
              index={i}
              key={'picker_' + i}
              image={images[i]}
              style={styles.picker}
              disabled={i !== images.length}
              onPicked={addImage}
              onRemove={() => removeImage(i)}
              onChange={changeImage}
            />
          );
        })}
      </View>
      <HelperText type="error" style={styles.errorMargin}>
        {errors.imageEnabled?.message}
      </HelperText>
    </View>
  );
};

const Forms: React.FC = () => {
  const {dark} = useTheme();
  const back = dark ? {} : {backgroundColor: Colors.gray};
  return (
    <View style={CommonStyles.fullWidth}>
      <VForm.Text<FormData> {...formInfos.name} />
      <View style={CommonStyles.margin.top} />
      <ImagePickers />
      <VForm.Text<FormData> {...formInfos.text} />
      <VForm.Text<FormData> {...formInfos.cvLinkUrl} />
      <View style={[styles.discountInput, back]}>
        <VForm.Text<FormData> {...formInfos.cvLinkText} />
      </View>
      <VForm.Text<FormData> {...formInfos.cvPhone} />
    </View>
  );
};

const TitleForm: React.FC = () => {
  return (
    <View style={CommonStyles.fullWidth}>
      <VForm.Text<FormData> {...formInfos.title} />
    </View>
  );
};

export type NewsSubmitType =
  | 'editing'
  | 'fixed'
  | 'fixAndGo'
  | 'fixAndCreative';

const LABEL: {[P in NewsSubmitType]: string} = {
  editing: '一時保存',
  fixed: '保存して終了',
  fixAndGo: '保存して配信',
  fixAndCreative: '保存して戻る',
};

const SubmitButton: React.FC<{
  type: NewsSubmitType;
  disabled: boolean;
  onStartSubmit(): void;
  onFinishSubmit(type: NewsSubmitType, success: boolean, reason?: string): void;
  style?: ViewStyle;
  editState?: EditState;
  group?: 'on';
}> = ({
  type,
  disabled,
  onStartSubmit,
  onFinishSubmit,
  style,
  editState,
  group,
}) => {
  const {checkedIds} = CouponsContainer.useContainer();
  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isFormError, setFormError] = React.useState<boolean>(false);
  const [deliveryLabel, setDeliveryLabel] = React.useState<string>('');
  const {submit} = NewsCreator.useContainer();
  const {
    setValue,
    handleSubmit,
    formState: {errors},
  } = useFormContext<FormData>();
  // エラー監視
  const nowError = errors && Object.keys(errors).length > 0;
  if (isFormError && !nowError) {
    // エラーが消えたら落とす
    setFormError(nowError);
  }
  const mode =
    type === 'fixAndGo' || type === 'fixAndCreative' ? 'contained' : 'outlined';
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    onStartSubmit();
    let success = false;
    let reason: string | undefined;
    setLoading(true);
    try {
      if (group !== 'on') {
        const news = await submit(
          data,
          type === 'editing' ? EditState.EDITING : EditState.FIXED,
        );
        // 保存したらIDが生成されるので保持
        setValue('id', news.id);
        success = true;
      } else {
        checkedIds.forEach(async (shopId: string) => {
          await submit(data, EditState.FIXED, shopId);
        });
        success = true;
      }
    } catch (err: any) {
      reason = axiosHelper.commonErrorHandler(err);
      console.log('[News] post error', err);
    } finally {
      setOpen(false);
      setLoading(false);
      onFinishSubmit(type, success, reason);
    }
  };
  const onSubmitError: SubmitErrorHandler<FormData> = () => {
    setFormError(true);
  };
  const openDialog: SubmitHandler<FormData> = async (data) => {
    if (editState === EditState.DELIVERED) {
      if (data.id) {
        const count = await SaaSNewsRepository.getNewsCount(data?.id);
        const delivery = `${count.deliveries}回の配信で${Helpers.sepComma(
          count.users,
        )}人が閲覧済み`;
        console.log(delivery);
        setDeliveryLabel(delivery);
      }
      setOpen(true);
    } else {
      await onSubmit(data);
    }
  };
  const hideDialog = () => {
    setOpen(false);
  };

  return (
    <View style={style}>
      <TrimaLoadingButton
        variant={mode}
        loading={isLoading}
        loadingPosition="start"
        disabled={disabled || isLoading}
        // Todo ボタンごとの押下後の振る舞いの実装
        onClick={handleSubmit((data) => openDialog(data), onSubmitError)}>
        {LABEL[type]}
      </TrimaLoadingButton>
      <HelperText type="error">
        {isFormError && 'フォームの入力に誤りがあります'}
      </HelperText>
      <MuiDialog
        open={open}
        onClose={hideDialog}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          <Typography variant="inherit" color="secondary">
            配信中の広告を変更しますか？
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">
              この広告は既に配信が開始されています。
            </Typography>
            <Typography variant="body1">
              変更内容は配信済みの広告にも反映されます。
            </Typography>
            <VMargin />
            <Typography variant="body1">■配信回数と閲覧回数</Typography>
            <Typography variant="body1">{deliveryLabel}</Typography>
            <VMargin />
            <TrimaLoadingButton
              color="error"
              variant="contained"
              onClick={handleSubmit((data) => onSubmit(data), onSubmitError)}
              disabled={isLoading}
              loading={isLoading}
              loadingPosition="start">
              変更
            </TrimaLoadingButton>
            <VMargin />
            <TrimaButton variant="outlined" onClick={hideDialog}>
              キャンセル
            </TrimaButton>
          </DialogContentText>
        </DialogContent>
      </MuiDialog>
    </View>
  );
};

const Dummy: React.FC = () => null;

type Result = {
  success: boolean;
  reason?: string;
};

const Submit: React.FC<{
  from?: keyof MainScreenParams;
  onSubmit(type: NewsSubmitType, newsId?: string): void;
  editTarget?: {
    forms: FormData;
    images: SaaSImageContents[];
    editState?: EditState;
  };
  group?: 'on';
}> = ({from, onSubmit, editTarget, group}) => {
  const [disabled, setDisabled] = React.useState(false);
  const [result, setResult] = React.useState<Result | undefined>();
  const {getValues} = useFormContext<FormData>();
  const param = {
    disabled,
    onStartSubmit: () => setDisabled(true),
    onFinishSubmit: (
      type: NewsSubmitType,
      success: boolean,
      reason?: string,
    ) => {
      if (type !== 'editing' && success) {
        // 保存して遷移するときはとりあえずダイアログは出さないで遷移
        const newsId = getValues('id') ?? undefined;
        onSubmit(type, newsId);
      } else {
        setResult({success, reason});
      }
      setDisabled(false);
    },
  };
  const hideDialog = () => setResult(undefined);
  return (
    <View style={CommonStyles.fullWidth}>
      {from === 'CreativesCreate' || from === 'CreativesGroupCreate' ? (
        <DoubleButtons
          button1={<Dummy />}
          button2={
            <SubmitButton
              type="fixAndCreative"
              {...param}
              editState={editTarget?.editState}
            />
          }
        />
      ) : group !== 'on' ? (
        <DoubleButtons
          button1={
            <SubmitButton
              type="fixed"
              {...param}
              editState={editTarget?.editState}
            />
          }
          button2={
            <SubmitButton
              type="fixAndGo"
              {...param}
              editState={editTarget?.editState}
            />
          }
        />
      ) : (
        <MaxWidth maxWidth={586}>
          <SubmitButton
            type="fixed"
            {...param}
            editState={editTarget?.editState}
            group={group}
          />
        </MaxWidth>
      )}
      <Portal>
        <Dialog visible={!!result} onDismiss={hideDialog}>
          <Dialog.Title>
            {result?.success
              ? '保存しました'
              : `保存に失敗しました ${result?.reason}`}
          </Dialog.Title>
          <Dialog.Actions>
            <TrimaButton variant="outlined" onClick={hideDialog}>
              OK
            </TrimaButton>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

const Preview: React.FC<{
  style?: ViewProps;
}> = ({style}) => {
  const {clearErrors, watch, setValue, trigger} = useFormContext<FormData>();
  const {images} = NewsCreator.useContainer();
  React.useEffect(() => {
    const imageEnabled = images.length > 0;
    setValue('imageEnabled', imageEnabled);
    if (imageEnabled) {
      // 追加した際にエラーを消すためにトリガーをかける
      trigger('imageEnabled');
    }
  }, [clearErrors, images, setValue, trigger]);
  const data = watch();
  return (
    <View style={[styles.preview, style]}>
      <Submenu style={styles.previewTitle}>プレビュー</Submenu>
      <PreviewComponent news={{data, images}} showShop={true} />
    </View>
  );
};

export const News = {
  Provider,
  Forms,
  TitleForm,
  Submit,
  Preview,
};

const styles = StyleSheet.create({
  preview: {
    flex: 1,
    alignItems: 'center',
  },
  previewTitle: {
    alignSelf: 'flex-start',
  },
  picker: {
    margin: 1,
  },
  select: {
    zIndex: 100,
  },
  discountInput: {
    zIndex: 50,
    marginTop: -4,
    paddingTop: 15,
    paddingHorizontal: 8,
    marginBottom: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  rowButton: {
    width: 200,
  },
  errorMargin: {minHeight: 22},
});
