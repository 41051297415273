import API, {graphqlOperation, GraphQLResult} from '@aws-amplify/api';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {ListAccessManageByUserQuery} from '../API';
import {listAccessManageByUser} from '../graphql/queries';

const useAccessRoleContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [accessRole, setAccessRole] = useState<string | null | undefined>();
  const [error, setError] = useState<Error | undefined>();

  const fetchAccessRole = useCallback(
    async (userId: string, ownerId: string) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        const result = (await API.graphql(
          graphqlOperation(listAccessManageByUser, {userId}),
        )) as GraphQLResult<ListAccessManageByUserQuery>;
        if (!result.data || !result.data.listAccessManageByUser) {
          throw new Error('[listAccessManageByUser] result.data is undefined');
        }
        if (
          result.data.listAccessManageByUser.items &&
          result.data.listAccessManageByUser.items.length > 0
        ) {
          const accessManage = result.data.listAccessManageByUser.items.find(
            (item) => item?.ownerId === ownerId,
          );
          setAccessRole(accessManage?.role);
        }

        return result.data.listAccessManageByUser;
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [],
  );

  return {
    isCreateLoading,
    error,
    accessRole,
    fetchAccessRole,
  };
};

export const AccessRoleContainer = createContainer(useAccessRoleContainer);
