import {Auth} from 'aws-amplify';
import {parsePhoneNumber} from 'libphonenumber-js';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSBillingRepository} from '../../_proto/services/SaaSBillingRepository';
import {
  EmailFormData,
  MFAFormData,
  PasswordChangeFormData,
  PhoneFormData,
} from '../../components/Auth/schema';
import {PaymentInfoContainer} from '../../container';

// Todo 型をちゃんと設定する
type Params = any;
type User = any;

export type AccountStep =
  | 'init' // 初期状態
  | 'mfa' // MFA
  | 'challenging' // 確認コードチェック
  | 'changePhone' // 電話番号の変更 Todo
  | 'changeEmail' // Emailの変更
  | 'done'; // 処理完了

type RegistrationInfo = {
  email?: string;
  params?: Params;
  user?: User;
};

type State = {step: AccountStep; info: RegistrationInfo};

type UseAccount = {
  // 状態
  step: AccountStep;
  error?: Error | (Error & {code: string});
  loading: boolean;
  // 処理
  challengeMFA(code: MFAFormData, navigation: any): void; // MFA認証
  changePhone(data: PhoneFormData): void; // 電話番号の変更画面へ
  changeEmail(data: EmailFormData): Promise<string>;
  reSendVerificationCode(type: string): void;
  changePassword(data: PasswordChangeFormData, navigation: any): void;
  resetState(): void;
  oldEmail: string;
  // clearError(): void; // エラーのクリア
};

// 本コンテナではstepが進むごとにデータを追加していく
function updateState(
  step: AccountStep,
  addInfo: RegistrationInfo = {},
): (prevState: State) => State {
  return (prevState: State) => ({step, info: {...prevState.info, ...addInfo}});
}

// 各種登録処理コンテナー
function useAccount(): UseAccount {
  // const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [state, setState] = useState<State>({step: 'init', info: {}});
  const [loading, setloading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [oldEmail, setOldEmail] = useState<string>('');

  const paymentInfoContainer = PaymentInfoContainer.useContainer();

  const challengeMFA = useCallback(
    async (data: MFAFormData, navigation: any) => {
      setError(undefined);
      setState(updateState('challenging'));
      const user = await Auth.currentAuthenticatedUser();
      const attrName = !user.attributes.phone_number_verified
        ? 'phone_number'
        : 'email';
      try {
        const result = await Auth.verifyCurrentUserAttributeSubmit(
          attrName,
          data.verificationCode,
        );
        console.log(result);
        if (attrName === 'email' && paymentInfoContainer.paymentInfo) {
          await SaaSBillingRepository.updateCustomer({
            customerId: paymentInfoContainer.paymentInfo.customerId,
            email,
          });
        }
        setState(updateState('init'));
        navigation.navigate('AccountMain');
      } catch (err: any) {
        setError(err);
        // 失敗時は状態を戻す
        setState(updateState('mfa'));
      }
    },
    [paymentInfoContainer, email],
  );

  const changePhone = useCallback(async (data: any) => {
    try {
      setError(undefined);
      setState(updateState('changePhone'));
      const user = await Auth.currentAuthenticatedUser();
      const phoneNumber = parsePhoneNumber(data.phone, 'JP');
      // 電話番号の変更
      const result = await Auth.updateUserAttributes(user, {
        phone_number: phoneNumber.number,
      });
      setState(updateState('mfa', {user: result}));
    } catch (err: any) {
      setError(err);
      // 失敗時は状態を戻す
      setState(updateState('init'));
    }
  }, []);

  const changeEmail = useCallback(async (data: any) => {
    try {
      setError(undefined);
      setState(updateState('changeEmail'));
      const user = await Auth.currentAuthenticatedUser();
      setOldEmail(user.attributes.email);
      // Emailの変更
      const result = await Auth.updateUserAttributes(user, {
        email: data.email,
      });
      setEmail(data.email);
      setState(updateState('mfa', {user: result}));
      return user.attributes.email;
    } catch (err: any) {
      setError(err);
      // 失敗時は状態を戻す
      setState(updateState('init'));
    }
  }, []);

  const reSendVerificationCode = useCallback(async (type: any) => {
    if (type === 'phone') {
      await Auth.verifyCurrentUserAttribute('phone_number');
    } else {
      await Auth.verifyCurrentUserAttribute('email');
    }
  }, []);

  const changePassword = useCallback(async (data: any, navigation: any) => {
    try {
      setloading(true);
      const user = await Auth.currentAuthenticatedUser(); // ログイン中のユーザー情報
      await Auth.changePassword(user, data.oldPassword, data.newPassword);
      setloading(false);
      navigation.navigate('AccountMain');
    } catch (err: any) {
      setError(err);
      // 失敗時は状態を戻す
      setloading(false);
    }
  }, []);

  const resetState = useCallback(async () => {
    setState(updateState('init'));
  }, []);

  return {
    step: state.step,
    error,
    loading,
    challengeMFA,
    changePhone,
    reSendVerificationCode,
    changePassword,
    changeEmail,
    resetState,
    oldEmail,
    // clearError,
  };
}

export const AccountContainer = createContainer(useAccount);
