import {Close} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import {Auth} from 'aws-amplify';
import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {Linking, StyleSheet, TouchableOpacity, View} from 'react-native';
import {Checkbox, RadioButton, Text} from 'react-native-paper';
import {CardForm} from '../../components/Payment/CardForm';
import {
  OwnerContainer,
  PaymentInfoContainer,
  StepNavigationContainer,
} from '../../container';
import {isAxiosError} from '../../helper/errors';
import {AccountMenu} from '../../navigation/AccountMenu';
import {Colors, CommonStyles, Fonts} from '../../theme';
import {
  FormBlock,
  Header,
  HelperText,
  MaxWidth,
  Menu,
  RegisterContainer,
  TextInputProps,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
  yup,
  yupUtil,
} from '../Elements';

const CREDIT_VALUE = 'credit';
const BANK_TRANSFAR_VALUE = 'bank-transfer';
const ACCOUNT_TRANSFAR_VALUE = 'account-transfer';
const CONVENIENCE_VALUE = 'convenience';

type PromotionCodeFormData = {
  promotionCode?: string;
};

const formInfos: {
  [P in keyof Required<PromotionCodeFormData>]: TextInputProps<PromotionCodeFormData>;
} = {
  promotionCode: {
    name: 'promotionCode',
    label: '招待コード',
    notRequired: true,
    withHint: 'inviteCode',
  },
};

const schemaPromotionCodeForm: yup.SchemaOf<PromotionCodeFormData> = yup
  .object()
  .shape({
    promotionCode: yupUtil.string(),
  });

// Stripe Elementsのオプション
// const CARD_OPTIONS = {
//   style: {
//     base: {
//       fontSize: '16px',
//       color: '#32325d',
//       fontFamily:
//         '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
//       fontSmoothing: 'antialPiased',
//       '::placeholder': {
//         color: '#a0aec0',
//       },
//     },
//   },
//   hidePostalCode: true,
// };

const ConfirmDialog: React.FC<{
  disabled: boolean;
  loading: boolean;
  visible: boolean;
  onConfirm(): void;
  onClose(): void;
}> = ({disabled, loading, visible, onConfirm, onClose}) => {
  const [user, setUser] = React.useState<any>();
  const {owner} = OwnerContainer.useContainer();
  useEffect(() => {
    async function fetchData() {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    }
    fetchData();
  }, []);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      onClose={handleCancel}
      open={visible}
      PaperProps={{
        style: {
          margin: '16px',
        },
      }}>
      <DialogContent>
        <View>
          <VMargin />
          <Text style={[styles.accentText, Fonts.bold]}>
            Paid(ペイド)の審査を開始しますか？
          </Text>
          <VMargin />
          <Text>
            審査のためPaidから、以下の
            <Text style={styles.accentText}>
              電話番号かメールアドレスにご連絡いたします
            </Text>
            ので、ご対応をお願いいたします。
          </Text>
          <VMargin />
          <Text>電話番号：{owner?.chargePhone}</Text>
          <Text>メールアドレス：{user?.attributes.email}</Text>
          <VMargin />
          <TrimaButton variant="contained" onClick={handleCancel}>
            キャンセル
          </TrimaButton>
          <VMargin />
          <TrimaLoadingButton
            variant="contained"
            disabled={disabled}
            loading={loading}
            loadingPosition="start"
            onClick={handleOk}>
            Paid(ペイド)の審査を開始する
          </TrimaLoadingButton>
        </View>
      </DialogContent>
      <VMargin />
    </Dialog>
  );
};

const Submit: React.FC = () => {
  const {checkPromotionCode, isCreateLoading} =
    PaymentInfoContainer.useContainer();
  const {
    handleSubmit,
    watch,
    formState: {errors},
    setValue,
    setError,
  } = useFormContext<PromotionCodeFormData>();
  const isLoading = isCreateLoading;
  const disabled =
    isLoading || !watch('promotionCode') || !!errors.promotionCode;

  const onSubmit = async (data: PromotionCodeFormData) => {
    try {
      await checkPromotionCode(data?.promotionCode);
      setValue('promotionCode', '');
    } catch (err: any) {
      setError('promotionCode', {
        type: 'server',
        message: err.message,
      });
    }
  };

  return (
    <View>
      <LoadingButton
        sx={{height: 40, width: 100, marginTop: '28px', marginLeft: 1}}
        variant="contained"
        onClick={handleSubmit(onSubmit)}
        disabled={disabled}
        loading={isLoading}>
        適用
      </LoadingButton>
    </View>
  );
};

const PromorionCodeForm: React.FC = () => {
  const {invitedCode, clearPromotionCode} = PaymentInfoContainer.useContainer();
  return (
    <View>
      <FormBlock>
        <MaxWidth maxWidth={586}>
          <VForm.Provider<PromotionCodeFormData>
            schema={schemaPromotionCodeForm}>
            <View style={CommonStyles.flex.row}>
              <View style={CommonStyles.flex.full}>
                <VForm.Text<PromotionCodeFormData>
                  {...formInfos.promotionCode}
                />
              </View>
              <Submit />
            </View>
          </VForm.Provider>
          {!!invitedCode && (
            <Card>
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Typography variant="h5">初月 ￥5,000 割引</Typography>
                  <IconButton
                    aria-label="cancel-code"
                    onClick={() => clearPromotionCode()}>
                    <Close />
                  </IconButton>
                </Grid>

                <Typography color="textSecondary">
                  招待コード: {invitedCode}
                </Typography>
              </CardContent>
            </Card>
          )}
        </MaxWidth>
      </FormBlock>
    </View>
  );
};

export const PaymentRegisterComponent: React.FC = () => {
  const {owner} = OwnerContainer.useContainer();
  const {createPaidCustomer} = PaymentInfoContainer.useContainer();
  const {setStep2PaymentReview} = StepNavigationContainer.useContainer();
  const [value, setValue] = React.useState(CREDIT_VALUE);
  const [error, setError]: any = React.useState(null);
  // const [cardComplete, setCardComplete] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const [visible, setVisible] = React.useState<boolean>(false);
  const showDialog = () => {
    setError(null);
    setVisible(true);
  };
  const hideDialog = () => {
    setVisible(false);
  };

  const createPaid = async () => {
    setProcessing(true);
    setError(null);
    let paymentMethod;
    if (value === BANK_TRANSFAR_VALUE) {
      paymentMethod = '1';
    } else if (value === ACCOUNT_TRANSFAR_VALUE) {
      paymentMethod = '2';
    } else {
      paymentMethod = '3';
    }
    try {
      await createPaidCustomer(owner, paymentMethod);
      setStep2PaymentReview();
    } catch (err: any) {
      if (isAxiosError(err)) {
        console.log(err.response);
        setError(err.response?.data);
      } else {
        console.log(err);
        setError(err);
      }
    }
    setProcessing(false);
  };

  const [checked, setChecked] = React.useState(false);

  const helper = error ? `エラーが発生しました（${error.message}）` : '';

  return (
    <View>
      <Header rightItem={<AccountMenu />} />
      <RegisterContainer step="payment" label="料金の説明">
        <MaxWidth maxWidth={586}>
          <View style={CommonStyles.flex.row}>
            <View style={CommonStyles.flex.full}>
              <Typography variant="subtitle1" component="span">
                初期費用
              </Typography>
            </View>
            <View style={CommonStyles.margin.left} />
            <View style={CommonStyles.flex.full}>
              <Typography variant="subtitle1" component="span">
                0円
              </Typography>
            </View>
          </View>
          <VMargin />
          <View style={CommonStyles.flex.row}>
            <View style={CommonStyles.flex.full}>
              <Typography variant="subtitle1" component="span">
                広告配信料
              </Typography>
            </View>
            <View style={CommonStyles.margin.left} />
            <View style={CommonStyles.flex.full}>
              <Typography variant="subtitle1" component="span">
                広告閲覧人数※あたり3円（税込3.3円）
              </Typography>
            </View>
          </View>
          <Text style={Fonts.caption}>
            ※1つの広告の詳細画面を閲覧した人数ごとのカウントです。同じ人が同じ広告を何度閲覧しても1人とカウントされます。
          </Text>
        </MaxWidth>
      </RegisterContainer>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.horizontal}>
        <Menu>お支払い情報の選択</Menu>
        <VMargin />
        <FormBlock>
          <MaxWidth maxWidth={586}>
            <RadioButton.Group
              onValueChange={(newValue) => setValue(newValue)}
              value={value}>
              <View style={styles.radioList}>
                <View style={styles.radios}>
                  <RadioButton value={CREDIT_VALUE} />
                  <TouchableOpacity
                    style={styles.radioItem}
                    onPress={() => {
                      setValue(CREDIT_VALUE);
                    }}>
                    <Text>クレジットカード</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.radios}>
                  <RadioButton value={BANK_TRANSFAR_VALUE} />
                  <TouchableOpacity
                    style={styles.radioItem}
                    onPress={() => {
                      setValue(BANK_TRANSFAR_VALUE);
                    }}>
                    <Text>銀行振込</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.radios}>
                  <RadioButton value={ACCOUNT_TRANSFAR_VALUE} />
                  <TouchableOpacity
                    style={styles.radioItem}
                    onPress={() => {
                      setValue(ACCOUNT_TRANSFAR_VALUE);
                    }}>
                    <Text>口座振替</Text>
                  </TouchableOpacity>
                </View>
                <View style={styles.radios}>
                  <RadioButton value={CONVENIENCE_VALUE} />
                  <TouchableOpacity
                    style={styles.radioItem}
                    onPress={() => {
                      setValue(CONVENIENCE_VALUE);
                    }}>
                    <Text>コンビニ支払い</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </RadioButton.Group>
          </MaxWidth>
        </FormBlock>
        <VMargin />
        <Menu>招待コードの登録</Menu>
        <VMargin />
        <PromorionCodeForm />
        <VMargin />
        {value === CREDIT_VALUE ? (
          <Menu>クレジットカードの登録</Menu>
        ) : (
          <Menu>Paidでのお支払い</Menu>
        )}
        <VMargin />
        <FormBlock>
          <MaxWidth maxWidth={586}>
            {value === CREDIT_VALUE ? (
              <CardForm />
            ) : (
              <View>
                <Text>
                  Paid(ペイド)は、株式会社ラクーンフィナンシャルが運営する法人・個人事業主向け後払い決済サービスです。
                  「銀行振込」「口座振替」「コンビニ払い」をご利用の場合、Paidでのお支払いとなります。
                </Text>
                <VMargin />
                <Text
                  style={CommonStyles.linkText}
                  onPress={() =>
                    Linking.openURL('https://paid.jp/v/contents/pre/buyer/')
                  }>
                  Paidについて詳しく確認する
                </Text>
                <VMargin />
                <View style={styles.noticeContainer}>
                  <Text style={Fonts.caption}>注意事項</Text>
                  <VMargin />
                  <Text style={Fonts.caption}>
                    ・ご利用前にPaidによる審査が行われます。審査は通常1～3営業日程度で、
                    <Text style={styles.accentText}>
                      Paidからお電話またはメールにてご連絡
                    </Text>
                    があります。
                  </Text>
                  <Text style={Fonts.caption}>
                    ・登録いただいた企業情報の内容は、Paidを運営する株式会社ラクーンフィナンシャルにも提供されます。
                  </Text>
                  <Text style={Fonts.caption}>
                    ・審査状況により別途書類をご提出いただく場合がございます。
                  </Text>
                  <Text style={Fonts.caption}>
                    ・審査結果は、トリマビジネスアカウント登録時にご登録いただいたメールアドレス宛に、Paidから連絡されます。
                  </Text>
                  <Text style={Fonts.caption}>
                    ・審査の結果によってはご利用いただけない場合がございます。
                  </Text>
                </View>
                <VMargin />
                <View>
                  <View
                    style={[
                      CommonStyles.flex.row,
                      CommonStyles.flex.crossCenter,
                    ]}>
                    <Checkbox
                      status={checked ? 'checked' : 'unchecked'}
                      onPress={() => setChecked(!checked)}
                    />
                    <Text>
                      Paidの
                      <Text
                        style={CommonStyles.linkText}
                        onPress={() =>
                          Linking.openURL(
                            'https://paid.jp/v/contents/pre/guide/rules.jsp',
                          )
                        }>
                        利用規約
                      </Text>
                      および
                      <Text
                        style={CommonStyles.linkText}
                        onPress={() =>
                          Linking.openURL(
                            'https://paid.jp/v/contents/pre/guide/privacy.jsp',
                          )
                        }>
                        プライバシー・ステートメント
                      </Text>
                      に同意
                    </Text>
                  </View>
                  <VMargin />
                  <TrimaLoadingButton
                    variant="contained"
                    onClick={() => {
                      showDialog();
                    }}
                    disabled={!checked || processing}
                    loading={processing}
                    loadingPosition="start">
                    Paidの審査を開始
                  </TrimaLoadingButton>
                  <ConfirmDialog
                    disabled={!checked || processing}
                    loading={processing}
                    visible={visible}
                    onConfirm={() => createPaid()}
                    onClose={hideDialog}
                  />
                  <HelperText type="error">{helper}</HelperText>
                </View>
              </View>
            )}
          </MaxWidth>
        </FormBlock>
      </MaxWidth>
    </View>
  );
};

const styles = StyleSheet.create({
  noticeContainer: {
    borderWidth: 1,
    borderColor: Colors.gray,
    backgroundColor: Colors.white,
    ...CommonStyles.padding.all,
  },
  radioList: {
    borderWidth: 1,
    borderColor: Colors.gray,
    backgroundColor: Colors.white,
  },
  radios: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: Colors.gray,
  },
  radioItem: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'stretch',
  },
  accentText: {
    color: Colors.accent,
  },
  codeSubmit: {
    height: 40,
    width: 100,
    marginTop: 28,
    ...CommonStyles.margin.left,
  },
});
