import {Close} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';
import {BottomSheet} from 'react-native-elements';
import HyperLink from 'react-native-hyperlink';
import {Helpers} from '../../helper';
import {Colors, CommonStyles, trimaColor} from '../../theme';
import {useHeight} from '../Responsive';
import {Text} from './Text';

// 共通で使われる並びを抽出して共通利用できるように
export const DoubleButtons: React.FC<{
  button1?: React.ReactElement;
  button2?: React.ReactElement;
}> = ({button1, button2}) => {
  return (
    <View style={CommonStyles.align.self.stretch}>
      <MaxWidth maxWidth={586}>
        {button1}
        <View style={CommonStyles.margin.top} />
        {button2}
      </MaxWidth>
    </View>
  );
};

// maxWidthまでは横幅いっぱい、maxWidth以上はmaxWidth固定で中央寄せされるコンテナ
export const MaxWidth: React.FC<{maxWidth: number} & ViewProps> = (props) => {
  const {children, maxWidth, style, ...rest} = props;
  const add = {flex: 1, maxWidth};
  return (
    <View
      style={[
        CommonStyles.flex.full,
        CommonStyles.flex.row,
        CommonStyles.flex.mainCenter,
      ]}>
      <View style={[add, style]} {...rest}>
        {children}
      </View>
    </View>
  );
};

export const FormBlock: React.FC<ViewProps> = (props) => {
  const {children, style, ...rest} = props;
  return (
    <View style={[CommonStyles.formBlock, style]} {...rest}>
      {children}
    </View>
  );
};

export const SheetOuter: React.FC<{
  isVisible: boolean;
  children: React.ReactNode;
}> = ({isVisible, children}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <BottomSheet isVisible={isVisible} modalProps={{}}>
      {children}
    </BottomSheet>
  );
};

export const SheetInner: React.FC<{
  onDismiss?(): void;
  children: React.ReactNode;
}> = ({onDismiss, children}) => {
  const {windowHeight} = useHeight();
  return (
    <MaxWidth
      maxWidth={736}
      style={[{backgroundColor: Colors.lightgray, height: windowHeight}]}>
      {children}
      <View style={styles.buttonArea}>
        <IconButton
          sx={{fontSize: 32, color: Colors.darkgray}}
          onClick={onDismiss}>
          <Close sx={{fontSize: 32}} />
        </IconButton>
      </View>
    </MaxWidth>
  );
};

export const Sheet: React.FC<{
  isVisible: boolean;
  onDismiss?(): void;
  children: React.ReactNode;
}> = ({isVisible, onDismiss, children}) => {
  return (
    <SheetOuter isVisible={isVisible}>
      <SheetInner onDismiss={onDismiss}>{children}</SheetInner>
    </SheetOuter>
  );
};

export const VMargin: React.FC = () => <View style={CommonStyles.margin.top} />;
export const VMarginCompact: React.FC = () => (
  <View style={CommonStyles.marginCompact.top} />
);

export const Paragraph: React.FC<{
  label: string;
  value?: string | null;
  style?: ViewProps['style'];
  isLink?: boolean;
}> = ({label, value, style, isLink}) => {
  return (
    <View style={style}>
      <Text>{label}</Text>
      <VMargin />
      <View style={CommonStyles.flex.row}>
        <View>
          <Text>{'　'}</Text>
        </View>
        <View style={CommonStyles.flex.full}>
          {isLink ? (
            <HyperLink
              linkStyle={{color: trimaColor.accent}}
              onPress={(url) => Helpers.openURL(url)}>
              <Text>{value}</Text>
            </HyperLink>
          ) : (
            <Text>{value}</Text>
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tablet: {
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.mainCenter,
  },
  vButton: {
    width: 200,
  },
  space: {
    width: 30,
  },
  vBorder: {
    alignSelf: 'stretch',
    height: 56,
    borderRightWidth: 1,
    borderColor: '#707070',
  },
  buttonArea: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
