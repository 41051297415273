import {Box, Grid, Link, Typography} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import {parsePhoneNumber} from 'libphonenumber-js';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {
  Container,
  FormBlock,
  Header,
  MaxWidth,
  TrimaButton,
  WithHint,
} from '../../components/Elements';
import {AdminContainer, AuthContainer, OwnerContainer} from '../../container';
import {UserInfoContainer} from '../../container/UserInfoContainer';
import {AccountMenu} from '../../navigation/AccountMenu';
import {SaasOwnerQuery, graphQLService} from '../../service';
import {CommonStyles} from '../../theme';
import {ChangeEmail} from './ChangeEmail';
import {ChangeName} from './ChangeName';
import {ChangePassword} from './ChangePassword';
import {ChangePhoneNumber} from './ChangePhoneNumber';
import {ChangeSendEmail} from './ChangeSendEmail';
import {Delete} from './Delete';

const SUPPORT_SITE_URL = 'https://support.shop.trip-mile.com/hc/ja';

const AccountComponent: React.FC = () => {
  const navigation = useNavigation();
  const gotoChangePhoneNumber = () => {
    navigation.navigate('AccountChangePhoneNumber');
  };
  const gotoChangePassword = () => {
    navigation.navigate('AccountChangePassword');
  };
  const gotoChangeEmail = () => {
    navigation.navigate('AccountChangeEmail');
  };
  const gotoChangeName = () => {
    navigation.navigate('AccountChangeName');
  };
  const gotoChangeSendEmail = () => {
    navigation.navigate('AccountChangeSendEmail');
  };

  const {user, signOut, checkAuthUser} = AuthContainer.useContainer();
  const {userInfo, update} = UserInfoContainer.useContainer();
  const {owner} = OwnerContainer.useContainer();
  const [ownerList, setOwnerList] = React.useState<
    undefined | SaasOwnerQuery[]
  >();
  const {isAdmin, userId} = AdminContainer.useContainer();
  console.log('userInfo', userInfo);

  useEffect(() => {
    async function fetchData() {
      await checkAuthUser();
    }
    fetchData();
  }, [checkAuthUser]);

  useEffect(() => {
    async function fetchData() {
      if (!userInfo?.sendReportEmail) {
        await update({
          id: userInfo?.id,
          sendReportEmail: {
            endOfDelivery: true,
            shortBalance: true,
          },
        });
      }
      if (!isAdmin) {
        const fetchedOwners = await graphQLService.getListOwner(user.username);
        setOwnerList(fetchedOwners);
      } else {
        const fetchedOwners = await graphQLService.getListOwner(userId);
        setOwnerList(fetchedOwners);
      }
    }
    fetchData();
  }, [user, isAdmin, userId, userInfo, update]);
  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <WithHint id="accountMain">
          <Typography variant="h6">アカウント設定</Typography>
        </WithHint>
        <Box m={1} />
        <View style={CommonStyles.flex.full}>
          {!user || owner === null || ownerList === undefined ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <FormBlock>
                <MaxWidth maxWidth={586}>
                  <Box fontWeight="fontWeightBold">アカウント情報</Box>
                  <Box m={1} />
                  <Typography variant="subtitle2">所属企業名</Typography>
                  {ownerList?.map((owner) => {
                    return (
                      <Box pl={2}>
                        <Typography variant="body1">{owner?.hojin}</Typography>
                      </Box>
                    );
                  })}
                  {ownerList.length === 0 && (
                    <Box pl={2}>
                      <Typography variant="body1">---</Typography>
                    </Box>
                  )}
                  <Box m={1} />
                  <Typography variant="subtitle2">携帯電話番号</Typography>
                  <Box pl={2}>
                    <Typography variant="body1">
                      {parsePhoneNumber(
                        user.attributes.phone_number,
                      ).formatNational()}
                    </Typography>
                  </Box>
                  <Box m={1} />
                  <TrimaButton
                    variant="outlined"
                    onClick={() => gotoChangePhoneNumber()}
                  >
                    携帯電話番号の変更
                  </TrimaButton>
                  <Box m={1} />
                  <Typography variant="subtitle2">Eメール</Typography>
                  <Box pl={2}>
                    <Typography variant="body1">
                      {user.attributes.email}
                    </Typography>
                  </Box>
                  <Box m={1} />
                  <TrimaButton
                    variant="outlined"
                    onClick={() => gotoChangeEmail()}
                  >
                    メールアドレスの変更
                  </TrimaButton>
                  <Box m={1} />
                  <Typography variant="subtitle2">パスワード</Typography>
                  <Box pl={2}>
                    <Typography variant="body1">********</Typography>
                  </Box>
                  <Box m={1} />
                  <TrimaButton
                    variant="outlined"
                    onClick={() => gotoChangePassword()}
                  >
                    パスワードの変更
                  </TrimaButton>
                  <Box m={1} />
                  <Typography variant="subtitle2">名前</Typography>
                  <Box pl={2}>
                    <Typography variant="body1">
                      {userInfo?.familyName} {userInfo?.givenName}
                    </Typography>
                  </Box>
                  <Box m={1} />
                  <TrimaButton
                    variant="outlined"
                    onClick={() => gotoChangeName()}
                  >
                    名前の変更
                  </TrimaButton>
                </MaxWidth>
              </FormBlock>
              <Box m={1} />
              <FormBlock>
                <MaxWidth maxWidth={586}>
                  <Box fontWeight="fontWeightBold">メール送信設定</Box>
                  <Box m={1} />
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid>
                      <Typography>広告配信結果レポートメール</Typography>
                    </Grid>
                    <Grid>
                      <Typography>
                        {userInfo?.sendReportEmail?.endOfDelivery
                          ? '送信する'
                          : '送信しない'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid>
                      <Typography>予算上限到達通知メール</Typography>
                    </Grid>
                    <Grid>
                      <Typography>
                        {userInfo?.sendReportEmail?.shortBalance
                          ? '送信する'
                          : '送信しない'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box m={1} />
                  <TrimaButton
                    variant="outlined"
                    onClick={() => gotoChangeSendEmail()}
                  >
                    メール送信設定の変更
                  </TrimaButton>
                </MaxWidth>
              </FormBlock>
              <Box m={1} />
              <MaxWidth maxWidth={586}>
                <TrimaButton
                  variant="contained"
                  onClick={async () => {
                    signOut();
                  }}
                >
                  ログアウト
                </TrimaButton>
              </MaxWidth>
              <Box m={1} />
              <MaxWidth maxWidth={586}>
                <Typography variant="body1">
                  トリマビジネスアカウントの削除をご希望の方は
                  <Link
                    href={SUPPORT_SITE_URL}
                    target="_blank"
                    rel="noopener"
                    underline="hover"
                  >
                    こちら
                  </Link>
                  からお問い合わせをお願いいたします。
                </Typography>
                <Typography>
                  ※未払いの利用料金が残っている場合は、お支払いが全て完了した後に削除されます。
                </Typography>
              </MaxWidth>
            </View>
          )}
        </View>
      </MaxWidth>
    </Container>
  );
};

export const Main: React.FC = () => {
  const route = useRoute<any>();
  const [showHeader, setShowHeader] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (route.params?.header) {
      setShowHeader(route.params?.header);
    }
  }, [route.params]);
  if (showHeader) {
    return (
      <View>
        <Header rightItem={<AccountMenu />} />
        <AccountComponent />
      </View>
    );
  } else {
    return <AccountComponent />;
  }
};

export const Account = {
  ChangePhoneNumber,
  Main,
  ChangePassword,
  ChangeEmail,
  ChangeName,
  ChangeSendEmail,
  Delete,
};
