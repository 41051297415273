import {CreateSaaSOwnerInput} from '../../API';
import {OmitGenerated} from '../../helper';
import {
  PLACEHOLDER,
  STRING_LIMIT,
  TextInputProps,
  yup,
  yupUtil,
} from '../Elements';
import {AddressPick} from '../PlacePicker';

export type OwnerFormData = {
  hojinName: string;
  hojinYomi: string;
  representativeNameFamily: string;
  representativeNameFirst: string;
  representativeYomiFamily: string;
  representativeYomiFirst: string;
  url?: string | null;
  chargeDept?: string;
  chargeNameFamily: string;
  chargeNameFirst: string;
  chargeYomiFamily: string;
  chargeYomiFirst: string;
  chargePhone: string;
} & AddressPick;

export const schemaOwnerForm: yup.SchemaOf<OwnerFormData> = yup.object().shape({
  hojinName: yupUtil.stringRequired(STRING_LIMIT.hojinName),
  hojinYomi: yupUtil.kanaRequired(STRING_LIMIT.hojinYomi),
  representativeNameFamily: yupUtil.stringRequired(STRING_LIMIT.nameCommon),
  representativeNameFirst: yupUtil.stringRequired(STRING_LIMIT.nameCommon),
  representativeYomiFamily: yupUtil.kanaRequired(STRING_LIMIT.nameCommon),
  representativeYomiFirst: yupUtil.kanaRequired(STRING_LIMIT.nameCommon),
  zip: yupUtil.zipRequired(),
  pref: yupUtil.stringRequired(),
  city: yupUtil.stringRequired(),
  detail: yupUtil.stringRequired(STRING_LIMIT.detail),
  building: yupUtil.string(STRING_LIMIT.building),
  url: yupUtil.url(STRING_LIMIT.url),
  chargeDept: yupUtil.string(STRING_LIMIT.nameCommon),
  chargeNameFamily: yupUtil.stringRequired(STRING_LIMIT.nameCommon),
  chargeNameFirst: yupUtil.stringRequired(STRING_LIMIT.nameCommon),
  chargeYomiFamily: yupUtil.kanaRequired(STRING_LIMIT.nameCommon),
  chargeYomiFirst: yupUtil.kanaRequired(STRING_LIMIT.nameCommon),
  chargePhone: yupUtil.phone(),
});

export const ownerFormInfos: {
  [P in keyof Required<OwnerFormData>]: TextInputProps<OwnerFormData>;
} = {
  hojinName: {
    name: 'hojinName',
    label: '法人名',
    maxLength: STRING_LIMIT.hojinName,
    ...PLACEHOLDER.hojinName,
    required: true,
  },
  hojinYomi: {
    name: 'hojinYomi',
    label: '法人名（カナ）',
    maxLength: STRING_LIMIT.hojinYomi,
    ...PLACEHOLDER.hojinYomi,
    required: true,
  },
  representativeNameFamily: {
    name: 'representativeNameFamily',
    placeholder: '山田',
    maxLength: STRING_LIMIT.nameCommon,
  },
  representativeNameFirst: {
    name: 'representativeNameFirst',
    placeholder: '太郎',
    maxLength: STRING_LIMIT.nameCommon,
  },
  representativeYomiFamily: {
    name: 'representativeYomiFamily',
    placeholder: 'ヤマダ',
    maxLength: STRING_LIMIT.nameCommon,
  },
  representativeYomiFirst: {
    name: 'representativeYomiFirst',
    placeholder: 'タロウ',
    maxLength: STRING_LIMIT.nameCommon,
  },
  zip: {
    name: 'zip',
    label: '郵便番号',
    ...PLACEHOLDER.zip,
    required: true,
  },
  pref: {
    name: 'pref',
    label: '都道府県',
    ...PLACEHOLDER.pref,
    required: true,
  },
  city: {
    name: 'city',
    label: '市区町村',
    ...PLACEHOLDER.city,
    required: true,
  },
  detail: {
    name: 'detail',
    label: '町・番地',
    maxLength: STRING_LIMIT.detail,
    ...PLACEHOLDER.detail,
    required: true,
  },
  building: {
    name: 'building',
    label: '建物名称',
    maxLength: STRING_LIMIT.building,
    ...PLACEHOLDER.building,
    notRequired: true,
  },
  url: {
    name: 'url',
    label: 'URL',
    notRequired: true,
    maxLength: STRING_LIMIT.url,
    ...PLACEHOLDER.url,
  },
  chargeDept: {
    name: 'chargeDept',
    label: '担当部署／支店名',
    placeholder: 'トリマグループ',
    maxLength: STRING_LIMIT.nameCommon,
    notRequired: true,
  },
  chargeNameFamily: {
    name: 'chargeNameFamily',
    placeholder: '田中',
    maxLength: STRING_LIMIT.nameCommon,
  },
  chargeNameFirst: {
    name: 'chargeNameFirst',
    placeholder: '花子',
    maxLength: STRING_LIMIT.nameCommon,
  },
  chargeYomiFamily: {
    name: 'chargeYomiFamily',
    placeholder: 'タナカ',
    maxLength: STRING_LIMIT.nameCommon,
  },
  chargeYomiFirst: {
    name: 'chargeYomiFirst',
    placeholder: 'ハナコ',
    maxLength: STRING_LIMIT.nameCommon,
  },
  chargePhone: {
    name: 'chargePhone',
    label: '担当者電話番号',
    ...PLACEHOLDER.phone,
    required: true,
  },
};

export function convert(
  formData: OwnerFormData,
): OmitGenerated<CreateSaaSOwnerInput> {
  return {
    hojin: formData.hojinName,
    hojinKana: formData.hojinYomi,
    representative: {
      first: formData.representativeNameFirst,
      last: formData.representativeNameFamily,
    },
    representativeKana: {
      first: formData.representativeYomiFirst,
      last: formData.representativeYomiFamily,
    },
    address: {
      zip: formData.zip,
      pref: formData.pref,
      city: formData.city,
      detail: formData.detail,
      building: formData.building,
    },
    url: formData.url ?? null,
    chargeDept: formData.chargeDept ?? null,
    charge: {
      first: formData.chargeNameFirst,
      last: formData.chargeNameFamily,
    },
    chargeKana: {
      first: formData.chargeYomiFirst,
      last: formData.chargeYomiFamily,
    },
    chargePhone: formData.chargePhone,
  };
}
