import {yup, yupUtil} from '../Elements';

// サインアップ画面
export type SignUpFormData = {
  email: string;
  familyName: string;
  givenName: string;
  phone: string;
  password: string;
};

export const schemaResistForm = yup.object().shape({
  email: yupUtil.stringRequired(),
  familyName: yupUtil.stringRequired(128),
  givenName: yupUtil.stringRequired(128),
  phone: yupUtil.phone(),
  password: yupUtil.password(),
});

// SignIn
export type SignInFormData = {
  email: string;
  password: string;
};

export const schemaSignInFormData = yup.object().shape({
  email: yupUtil.stringRequired(),
  password: yupUtil.stringRequired(),
});

// MFA認証
export type MFAFormData = {
  verificationCode: string;
};

export const schemaMFAFormData = yup.object().shape({
  verificationCode: yupUtil.stringRequired(),
});

// 電話番号変更
export type PhoneFormData = {
  phone: string;
};

export const schemaPhoneFormData = yup.object().shape({
  phone: yupUtil.phone(),
});

export const schemaPhoneSameCheck = (formattedNumber: string) =>
  yup.object().shape({
    phone: yupUtil.phoneSameCheck(formattedNumber),
  });

export type EmailFormData = {
  email: string;
};

export const schemaEmailFormData = yup.object().shape({
  email: yupUtil.email(),
});

export type PasswordChangeFormData = {
  oldPassword: string;
  newPassword: string;
};

export const schemaPasswordChangeFormData = yup.object().shape({
  oldPassword: yupUtil.password(),
  newPassword: yupUtil.password(),
});

export type ConfirmFormData = {
  password: string;
};

export const schemaConfirmFormData = yup.object().shape({
  password: yupUtil.stringRequired(),
});

export type PasswordForgotFormData = {
  verificationCode: string;
  newPassword: string;
};

export const schemaPasswordForgotFormData = yup.object().shape({
  verificationCode: yupUtil.stringRequired(),
  newPassword: yupUtil.password(),
});

export type NameFormData = {
  familyName: string;
  givenName: string;
};

export const schemaNameFormData = yup.object().shape({
  familyName: yupUtil.stringRequired(128),
  givenName: yupUtil.stringRequired(128),
});
