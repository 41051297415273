import {
  CalcMode,
  MeshCode,
  ResidenceInitialState,
  ResidenceUsersByMesh,
  TargetingUserCount,
} from '../../types';
import _ from 'lodash';
import {createContainer} from 'unstated-next';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useShopId} from '../../../../container';
import {calcUserCount, isEmptyMeshList, isUnderLimit} from '../../commonFunc';
import {checkResidence, useResidenceFilter} from './residenceFilters';
import {TargetingContainer} from '../../TargetingContainer';
import {makeResidenceFetcher} from '../userFetchFuncs';
import {useAreaContainerBase} from '../../hooks';
import {useLimitCaution} from '../LimitCautionDialog';
import {CHOICE_LIMIT} from '../../schema';
import {QueueHelper} from '../../../../helper';

type SelectedMeshes = {[P in MeshCode]: 'working' | 'done'};

const queue = QueueHelper.getQueue();

// 表示用データに変換
function toSelected(residence: ResidenceUsersByMesh): SelectedMeshes {
  const selected: SelectedMeshes = {};
  _.forEach(residence, (__, key) => {
    selected[key] = 'done';
  });
  return selected;
}

type UseResidence = {
  userCount: TargetingUserCount; // 配信対象人数
  selectedMesh: SelectedMeshes; // 選択メッシュ
  canConfirm: boolean; // confirm可能状態かどうか
  calcMode: CalcMode;
  selectMesh(meshCode: MeshCode): void;
  selectMeshes(meshes: MeshCode[]): void; // 複数選択（半径一括用）
  confirm(): void;
};

// ロジックは useStayHistory同等なので、コメントはそっち参照
function useResidence(initialState?: ResidenceInitialState): UseResidence {
  const mountRef = useRef(false);
  const {shopId} = useShopId();
  const {attrFilter, otherUsers, otherChoiceCount, userCount, setCount} =
    useAreaContainerBase(initialState);
  const residenceUsersRef = useRef<ResidenceUsersByMesh>(
    initialState?.residenceUsers
      ? _.cloneDeep(initialState.residenceUsers)
      : {},
  );
  // 操作の排他用モード
  const calcModeRef = useRef<CalcMode>('none');
  const [calcMode, setCalcMode] = useState<CalcMode>('none');
  const setMode = useCallback((mode: CalcMode) => {
    calcModeRef.current = mode;
    setCalcMode(mode);
  }, []);

  // 選択中エリア情報
  const selectedMeshRef = useRef<SelectedMeshes>(
    toSelected(residenceUsersRef.current),
  );
  const [selectedMesh, setSelected] = useState<SelectedMeshes>(
    selectedMeshRef.current,
  );
  const feedbackSelected = useCallback(
    () => setSelected({...selectedMeshRef.current}),
    [],
  );
  // 選択数関係
  const getChoiceCountAll = useCallback<() => number>(() => {
    return otherChoiceCount + Object.keys(selectedMeshRef.current).length;
  }, [otherChoiceCount]);
  const {showLimitCaution} = useLimitCaution();

  const calcCount = useCallback(() => {
    if (!QueueHelper.isFinish(queue)) {
      return; // 処理中メッシュがあるのでまだ計算しない
    }
    const sum = calcUserCount([residenceUsersRef.current], otherUsers);
    if (QueueHelper.isFinish(queue)) {
      setCount(sum); // タスクに割り込まれてなければ反映
      setMode('none');
    }
  }, [otherUsers, setCount, setMode]);

  const {residenceFilter} = useResidenceFilter();
  const residenceFilterRef = useRef(residenceFilter);
  useEffect(() => {
    residenceFilterRef.current = residenceFilter;
    if (!mountRef.current) {
      return; // 初回マウント時はスルー
    }
    setMode('form');
    setCount('calculating');
    _.forOwn(residenceUsersRef.current, (users) => {
      for (let i = 0; i < users.length; i++) {
        checkResidence(users[i], residenceFilter);
      }
    });
    calcCount();
  }, [calcCount, setMode, residenceFilter, setCount]);

  // メッシュ単位処理（タスクキューに積まれる処理）
  type Worker = () => Promise<boolean>;
  const makeWorker = useCallback<(meshes: MeshCode[]) => Worker>(
    (meshes: MeshCode[]) => {
      return makeResidenceFetcher(
        meshes,
        residenceUsersRef.current,
        shopId,
        attrFilter,
        residenceFilterRef.current,
      );
    },
    [attrFilter, shopId],
  );

  // メッシュ選択時の処理
  const selectMesh = useCallback(
    (meshCode: MeshCode) => {
      if (calcModeRef.current === 'form') {
        return;
      }
      if (selectedMeshRef.current[meshCode] === 'done') {
        delete selectedMeshRef.current[meshCode];
        delete residenceUsersRef.current[meshCode];
        feedbackSelected();
        calcCount();
      } else if (!selectedMeshRef.current[meshCode]) {
        if (getChoiceCountAll() < CHOICE_LIMIT) {
          setMode('map');
          selectedMeshRef.current[meshCode] = 'working';
          feedbackSelected();
          setCount('calculating');
          queue.add(makeWorker([meshCode])).then((success) => {
            if (success) {
              selectedMeshRef.current[meshCode] = 'done';
              // working / done で見た目に変化を出さないなら反映不要
              // feedbackSelected();
            } else {
              // 取得失敗したみたいなので、消す
              delete selectedMeshRef.current[meshCode];
              feedbackSelected();
            }
            calcCount();
          });
        } else {
          // 選択数上限
          showLimitCaution();
        }
      }
    },
    [
      calcCount,
      feedbackSelected,
      getChoiceCountAll,
      makeWorker,
      setCount,
      setMode,
      showLimitCaution,
    ],
  );

  const selectMeshes = useCallback<UseResidence['selectMeshes']>(
    (meshes) => {
      // 全部で何メッシュ選択状態になるのかチェック
      const count =
        _.uniq(_.concat(meshes, Object.keys(selectedMeshRef.current))).length +
        otherChoiceCount;
      if (count > CHOICE_LIMIT) {
        // 選択すると上限を超過するので、ダイアログだけ表示する
        showLimitCaution();
      } else {
        // 一括選択の場合、「選択」だけして、「選択解除」はしない
        meshes.forEach((meshCode) => {
          if (!selectedMeshRef.current[meshCode]) {
            selectMesh(meshCode);
          }
        });
      }
    },
    [otherChoiceCount, selectMesh, showLimitCaution],
  );

  // 完了処理関係
  const canConfirm =
    !isUnderLimit(userCount) && !isEmptyMeshList(residenceUsersRef.current);
  const {confirmResidence} = TargetingContainer.useContainer();
  const confirm = useCallback(() => {
    confirmResidence(residenceUsersRef.current, userCount);
  }, [confirmResidence, userCount]);

  // 初回マウント完了を記録
  useEffect(() => {
    mountRef.current = true;
  }, []);

  return {
    selectedMesh,
    selectMesh,
    selectMeshes,
    canConfirm,
    calcMode,
    userCount,
    confirm,
  };
}

export const ResidenceContainer = createContainer(useResidence);
