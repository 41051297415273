import React from 'react';
import {StyleSheet, View, ViewProps} from 'react-native';
import {Colors, CommonStyles} from '../../theme';

export const DataArea: React.FC<ViewProps> = (prop) => {
  const {style, children, ...rest} = prop;
  return (
    <View style={[styles.dataArea, style]} {...rest}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  dataArea: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.base,
    ...CommonStyles.padding.all,
    ...CommonStyles.flex.center,
  },
});
