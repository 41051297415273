import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSTargetRepository} from '../../_proto/services/SaaSTargetRepository';
import {StationSearchFormData} from './area/station/schema';

const useStationSearchContainer = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<Array<any>>([]);
  const [word, setWord] = useState<string>('');
  const [error, setError] = useState<Error | undefined>();
  const [isClear, setIsClear] = useState<boolean>(false);

  const search = useCallback(async (data: StationSearchFormData) => {
    setLoading(true);
    setError(undefined);
    setIsClear(false);

    try {
      setWord(data.word);
      const result = await SaaSTargetRepository.searchStationPoiByName(
        data.word,
      );
      setList(
        result.map((item) => {
          const lines = item.lines.map((line) => {
            const stations = line.stations.map((station) => {
              return {...station, routeId: station.parent};
            });
            return {...line, stations};
          });
          return {...item, lines};
        }),
      );
    } catch (err: any) {
      console.log(err.errors[0].message);
      setError(new Error(err.errors[0].message));
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const clear = useCallback(() => {
    setIsClear(true);
    setList([]);
  }, []);

  return {
    isLoading,
    error,
    list,
    word,
    search,
    clear,
    isClear,
  };
};

export const StationSearchContainer = createContainer(
  useStationSearchContainer,
);
