// 鉄道会社
import {useCallback, useRef, useState} from 'react';
import {createContainer} from 'unstated-next';
import {
  SaaSTargetRepository,
  StationCategories,
} from '../../_proto/services/SaaSTargetRepository';
import {
  getCategoryCode,
  getStationList,
  getTrainLineList,
  isMasterCode,
} from './area/station/stationFunc';
import {
  MasterCode,
  Station,
  StationCode,
  StationsByLine,
  TrainLine,
} from './types';

type CategoriesState = StationCategories | 'loading' | 'error';

export type UseStationCache = {
  categories?: CategoriesState;
  fetchCategories(): void;
  getTrainLine(categoryId: StationCode): Promise<TrainLine[]>;
  getStation(lineId: StationCode): Promise<Station[]>;

  // 非Promise版
  getTrainLineNoAsync(categoryId: StationCode): TrainLine[] | undefined;
  getStationNoAsync(lineId: StationCode): Station[] | undefined;

  // 路線名、駅名のsetter/getter（プレビュー用）
  getTrainLineName(lineId: StationCode): string | undefined;
  getStationName(code: StationCode | MasterCode): string | undefined;
  setStationName(code: MasterCode, name: string, routeId?: StationCode): void;

  // 路線名の遅延更新用
  updateTrainLines(lines: StationCode[]): Promise<void>;

  // 新旧コード移行対応用
  getMasterCode(code: StationCode, name?: string): Promise<MasterCode>;
  getStationCode(code: MasterCode, name?: string): Promise<StationCode>;
  getTrainLineCode(code: MasterCode | StationCode): StationCode | undefined;
  /**
   * 駅リストからプレビュー表示用の路線/駅リストへ変換。
   * 新コードの場合は路線コードの取得が必要なので、本コンテナから機能を提供
   * @param stations 駅リスト（新コード）
   */
  makeStationsByLine(stations: MasterCode[]): StationsByLine;
  setStationCache(station: {
    id: string;
    name: string;
    routeId?: StationCode;
  }): void;
};

// 鉄道区分、路線、駅リストのデータをキャッシュする。
// 配信先作成・編集以外にも配信先詳細画面などでも表示に必要になるデータなので、
// 画面を跨いでも保持できるようにデータだけの独立したコンテナで管理する
function useStationCache(): UseStationCache {
  // カテゴリリスト
  const [categories, setCategories] = useState<CategoriesState>();
  // 路線リスト・駅リストののキャッシュ
  const trainLineCache = useRef<{[P in StationCode]: TrainLine[]}>({});
  const stationListCache = useRef<{[P in StationCode]: Station[]}>({});
  const stationCache = useRef<{
    [P in MasterCode | StationCode]: {name: string; routeId?: StationCode};
  }>({});

  const getTrainLineCode = useCallback<UseStationCache['getTrainLineCode']>(
    (code) => {
      if (isMasterCode(code)) {
        const routeId = (stationCache.current[code] ?? {}).routeId;
        return routeId ? routeId.substring(0, 8) : undefined;
      } else {
        return code.substring(0, 8);
      }
    },
    [],
  );

  const fetchCategories = useCallback<
    UseStationCache['fetchCategories']
  >(() => {
    if (!categories || categories === 'error') {
      setCategories('loading');
      SaaSTargetRepository.fetchRouteCagetories()
        .then(setCategories)
        .catch((err) => {
          console.log(err);
          setCategories('error');
        });
    }
  }, [categories]);

  // 路線リストの提供
  const getTrainLine = useCallback<UseStationCache['getTrainLine']>(
    async (categoryId) => {
      if (trainLineCache.current[categoryId]) {
        return trainLineCache.current[categoryId];
      }
      return await getTrainLineList(categoryId)
        .then((list) => {
          trainLineCache.current[categoryId] = list;
          return list;
        })
        .catch(() => []);
    },
    [],
  );

  // 駅リストの提供
  const getStation = useCallback<UseStationCache['getStation']>(
    async (lineId) => {
      if (stationListCache.current[lineId]) {
        return stationListCache.current[lineId];
      }
      return await getStationList(lineId)
        .then((list) => {
          const filtered = list.filter((station) => isMasterCode(station.id));
          stationListCache.current[lineId] = filtered;
          // MasterCode方式だと、MasterCodeから路線名をたどってくるのが難しいので、逆引きできるようにしておく;
          filtered.forEach((station) => {
            stationCache.current[station.id] = {
              name: station.name,
              routeId: station.routeId,
            };
          });
          return filtered;
        })
        .catch(() => []);
    },
    [],
  );

  const setStationCache = useCallback<UseStationCache['setStationCache']>(
    (station) => {
      stationCache.current[station.id] = {
        name: station.name,
        routeId: station.routeId,
      };
    },
    [],
  );

  const getTrainLineNoAsync = useCallback<
    UseStationCache['getTrainLineNoAsync']
  >((categoryId) => trainLineCache.current[categoryId], []);

  const getStationNoAsync = useCallback<UseStationCache['getStationNoAsync']>(
    (lineId) => stationListCache.current[lineId],
    [],
  );

  // 名称関係関数
  const setStationName = useCallback<UseStationCache['setStationName']>(
    (code, name, routeId) => {
      if (code && name) {
        stationCache.current[code] = {
          name,
          routeId: routeId ? routeId : isMasterCode(code) ? undefined : code,
        };
      }
    },
    [],
  );

  const getTrainLineName = useCallback<UseStationCache['getTrainLineName']>(
    (lineId) => {
      const category = trainLineCache.current[getCategoryCode(lineId)] ?? [];
      const trainLine = category.find((elem) => elem.id === lineId);
      return trainLine ? trainLine.name : undefined;
    },
    [],
  );
  const getStationName = useCallback<UseStationCache['getStationName']>(
    (code) => {
      const res = stationCache.current[code];
      if (res) {
        return res.name;
      }
      if (isMasterCode(code)) {
        // 取得済みの全路線から検索
        let name: string | undefined;
        Object.values(stationListCache.current).some((stationList) =>
          stationList.some((station) => {
            const matched = station.id === code;
            if (matched) {
              name = station.name;
            }
            return matched;
          }),
        );
        return name;
      } else {
        let station: Station | undefined;
        const targetCode = getTrainLineCode(code);
        if (targetCode) {
          const trainLine = stationListCache.current[targetCode] ?? [];
          station = trainLine.find((elem) => elem.id === code);
        }
        return station ? station.name : undefined;
      }
    },
    [getTrainLineCode],
  );

  const updateTrainLines = useCallback<UseStationCache['updateTrainLines']>(
    async (lines) => {
      const tasks: Promise<unknown>[] = [];
      lines.forEach((line) => {
        tasks.push(getTrainLine(getCategoryCode(line)).catch(() => undefined));
      });
      // キャッシュにデータを貯めるのが目的なので、返り値は特に見ない
      await Promise.all(tasks);
      return;
    },
    [getTrainLine],
  );

  const getMasterCode = useCallback<UseStationCache['getMasterCode']>(
    async (code, name) => {
      const lineCode = getTrainLineCode(code);
      if (lineCode) {
        const stations = await getStation(lineCode);
        // まずは駅名で判定
        if (name) {
          const station = stations.find((s) => s.name === name);
          if (station) {
            return station.id;
          }
        }
        // 駅名が一致しないなら駅コードで判定
        const match = stations.find((s) => s.routeId === code);
        if (match) {
          return match.id;
        }
      }
      // 見つからない
      return '';
    },
    [getStation, getTrainLineCode],
  );

  const getStationCode = useCallback<UseStationCache['getStationCode']>(
    async (code, name) => {
      const fetched = await SaaSTargetRepository.fetchRouteId(code);
      const sName = fetched?.name ?? name;
      if (fetched && sName) {
        // 取得できたらキャッシュに追加しておく
        setStationName(fetched.id, sName, fetched.routeId);
        fetched.routeId && setStationName(fetched.routeId, sName);
      }
      return fetched?.routeId ?? fetched?.id ?? '';
    },
    [setStationName],
  );

  const makeStationsByLine = useCallback<UseStationCache['makeStationsByLine']>(
    (stations) => {
      stations.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
      const ret: StationsByLine = {};
      stations.forEach((code) => {
        const line = getTrainLineCode(code);
        if (line) {
          if (ret[line]) {
            ret[line].push(code);
          } else {
            ret[line] = [code];
          }
        }
      });
      return ret;
    },
    [getTrainLineCode],
  );

  return {
    categories,
    fetchCategories,
    getTrainLine,
    getStation,
    getTrainLineNoAsync,
    getStationNoAsync,
    getTrainLineName,
    getStationName,
    setStationName,
    getTrainLineCode,
    updateTrainLines,
    getMasterCode,
    getStationCode,
    makeStationsByLine,
    setStationCache,
  };
}

export const StationCacheContainer = createContainer(useStationCache);
