/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type OwnerInput = {
  id?: string | null;
  hojin?: string | null;
  hojinKana?: string | null;
  representative?: FullNameInput | null;
  representativeKana?: FullNameInput | null;
  address?: FullAddressInput | null;
  url?: string | null;
  chargeDept?: string | null;
  charge?: FullNameInput | null;
  chargeKana?: FullNameInput | null;
  chargePhone?: string | null;
  status?: OwnerStatus | null;
  agentCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type FullNameInput = {
  first?: string | null;
  last?: string | null;
};

export type FullAddressInput = {
  zip?: string | null;
  pref?: string | null;
  city?: string | null;
  detail?: string | null;
  building?: string | null;
};

export enum OwnerStatus {
  ENABLED = 'ENABLED',
  PAUSED = 'PAUSED',
  BANNED = 'BANNED',
}

export type SaaSOwner = {
  __typename: 'SaaSOwner';
  id: string;
  hojin?: string | null;
  hojinKana?: string | null;
  representative?: FullName | null;
  representativeKana?: FullName | null;
  address?: FullAddress | null;
  url?: string | null;
  chargeDept?: string | null;
  charge?: FullName | null;
  chargeKana?: FullName | null;
  chargePhone?: string | null;
  status?: OwnerStatus | null;
  agentCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type FullName = {
  __typename: 'FullName';
  first?: string | null;
  last?: string | null;
};

export type FullAddress = {
  __typename: 'FullAddress';
  zip?: string | null;
  pref?: string | null;
  city?: string | null;
  detail?: string | null;
  building?: string | null;
};

export type PaymentInfoInput = {
  id?: string | null;
  customerId?: string | null;
  paymentMethodId?: string | null;
  paymentType?: PaymentType | null;
  subscriptionId?: string | null;
  subscriptionItemIds?: Array<string | null> | null;
  priceIds?: Array<string | null> | null;
  billing?: boolean | null;
  invitedCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum PaymentType {
  STRIPE = 'STRIPE',
  PAID = 'PAID',
}

export type SaaSPaymentInfo = {
  __typename: 'SaaSPaymentInfo';
  id: string;
  customerId?: string | null;
  paymentMethodId?: string | null;
  paymentType?: PaymentType | null;
  subscriptionId?: string | null;
  subscriptionItemIds?: Array<string | null> | null;
  priceIds?: Array<string | null> | null;
  billing?: boolean | null;
  invitedCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type AccessManageInput = {
  id?: string | null;
  ownerId?: string | null;
  userId?: string | null;
  email?: string | null;
  addUserId?: string | null;
  role?: AccessManageRole | null;
  status?: AccessManageStatus | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum AccessManageRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
}

export enum AccessManageStatus {
  INIT = 'INIT',
  CONFIRM = 'CONFIRM',
  DONE = 'DONE',
}

export type SaaSAccessManage = {
  __typename: 'SaaSAccessManage';
  id: string;
  ownerId?: string | null;
  userId?: string | null;
  email?: string | null;
  addUserId?: string | null;
  role?: AccessManageRole | null;
  status?: AccessManageStatus | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateAccessManageEmailInput = {
  email?: string | null;
  oldEmail?: string | null;
};

export type DeleteAccessManageInput = {
  id?: string | null;
};

export type MigrateOwnerAccessInput = {
  id?: string | null;
};

export type DeliveryTargetInput = {
  id?: string | null;
  ownerId?: string | null;
  shopId?: string | null;
  title?: string | null;
  areaOption?: AreaOptionInput | null;
  stayOption?: StayOptionInput | null;
  stationOption?: Array<string | null> | null;
  cityOption?: Array<CityOptionInput | null> | null;
  userOption?: UserOptionInput | null;
  users?: number | null;
  editState?: EditState | null;
  archive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type AreaOptionInput = {
  areaType?: AreaType | null;
  meshs?: Array<string | null> | null;
};

export enum AreaType {
  HOME = 'HOME',
  OFFICE = 'OFFICE',
  BOTH = 'BOTH',
}

export type StayOptionInput = {
  weekDays?: Array<number | null> | null;
  hours?: Array<number | null> | null;
  meshs?: Array<string | null> | null;
};

export type CityOptionInput = {
  prefCode?: string | null;
  cityCode?: string | null;
  prefName?: string | null;
  cityName?: string | null;
};

export type UserOptionInput = {
  gender?: Gender | null;
  ageRange?: RangeOptionInput | null;
  married?: boolean | null;
  residence?: Array<Residence | null> | null;
  movingMethod?: Array<MovingMethod | null> | null;
  childrenTogether?: number | null;
  childAge?: RangeOptionInput | null;
  incomeRange?: RangeOptionInput | null;
  householdIncomeRange?: RangeOptionInput | null;
  familyTogether?: number | null;
  address?: Array<number | null> | null;
  job?: Array<number | null> | null;
  interest?: Array<number | null> | null;
  followed?: boolean | null;
  visited?: boolean | null;
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export type RangeOptionInput = {
  lower?: number | null;
  upper?: number | null;
};

export enum Residence {
  MY_HOUSE = 'MY_HOUSE',
  MY_APARTMENT = 'MY_APARTMENT',
  RENT_HOUSE = 'RENT_HOUSE',
  RENT_APARTMENT = 'RENT_APARTMENT',
  COMPANY_HOUSING = 'COMPANY_HOUSING',
  OTHER = 'OTHER',
}

export enum MovingMethod {
  TRAIN = 'TRAIN',
  BUS = 'BUS',
  WALK = 'WALK',
  CAR = 'CAR',
  BICYCLE = 'BICYCLE',
  MOTORCYCLE = 'MOTORCYCLE',
  NONE = 'NONE',
  OTHER = 'OTHER',
}

export enum EditState {
  EDITING = 'EDITING',
  FIXED = 'FIXED',
  DELIVERED = 'DELIVERED',
}

export type SaaSDeliveryTarget = {
  __typename: 'SaaSDeliveryTarget';
  id: string;
  ownerId?: string | null;
  shopId?: string | null;
  title?: string | null;
  areaOption?: AreaOption | null;
  stayOption?: StayOption | null;
  stationOption?: Array<string | null> | null;
  cityOption?: Array<CityOption | null> | null;
  userOption?: UserOption | null;
  users?: number | null;
  editState?: EditState | null;
  archive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type AreaOption = {
  __typename: 'AreaOption';
  areaType?: AreaType | null;
  meshs?: Array<string | null> | null;
};

export type StayOption = {
  __typename: 'StayOption';
  weekDays?: Array<number | null> | null;
  hours?: Array<number | null> | null;
  meshs?: Array<string | null> | null;
};

export type CityOption = {
  __typename: 'CityOption';
  prefCode?: string | null;
  cityCode?: string | null;
  prefName?: string | null;
  cityName?: string | null;
};

export type UserOption = {
  __typename: 'UserOption';
  gender?: Gender | null;
  ageRange?: RangeOption | null;
  married?: boolean | null;
  residence?: Array<Residence | null> | null;
  movingMethod?: Array<MovingMethod | null> | null;
  childrenTogether?: number | null;
  childYoungest?: RangeOption | null;
  incomeRange?: RangeOption | null;
  householdIncomeRange?: RangeOption | null;
  familyTogether?: number | null;
  address?: Array<number | null> | null;
  job?: Array<number | null> | null;
  interest?: Array<number | null> | null;
  childAge?: RangeOption | null;
  followed?: boolean | null;
  visited?: boolean | null;
};

export type RangeOption = {
  __typename: 'RangeOption';
  lower?: number | null;
  upper?: number | null;
};

export type ShopGroupInput = {
  id?: string | null;
  ownerId?: string | null;
  name?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type SaaSShopGroup = {
  __typename: 'SaaSShopGroup';
  id: string;
  ownerId?: string | null;
  name?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteShopGroupInput = {
  id?: string | null;
};

export type SetGroup2ShopInput = {
  id?: string | null;
  groupId?: string | null;
  ownerId?: string | null;
};

export type SaaSShop = {
  __typename: 'SaaSShop';
  id: string;
  ownerId?: string | null;
  groupId?: string | null;
  name?: string | null;
  text?: string | null;
  address?: FullAddress | null;
  phone?: string | null;
  biztimes?: string | null;
  holidays?: string | null;
  url?: string | null;
  legalUrl?: string | null;
  category?: ShopCategory | null;
  imageUrls?: Array<string | null> | null;
  icon?: string | null;
  location?: Location | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  shopState?: ShopState | null;
};

export enum ShopCategory {
  GOURMET = 'GOURMET',
  GOURMET_DELIVERY = 'GOURMET_DELIVERY',
  CAFE = 'CAFE',
  SUPERMARKET = 'SUPERMARKET',
  DRUGSTORE = 'DRUGSTORE',
  FASHION = 'FASHION',
  SHOPPING = 'SHOPPING',
  LEISURE = 'LEISURE',
  SCHOOL = 'SCHOOL',
  ENTERTAINMENT = 'ENTERTAINMENT',
  SALON = 'SALON',
  MASSAGE = 'MASSAGE',
  CLEANING = 'CLEANING',
  OTHER = 'OTHER',
}

export type Location = {
  __typename: 'Location';
  lat?: number | null;
  lon?: number | null;
};

export enum ShopState {
  BETA_FREEZE = 'BETA_FREEZE',
  BANNED = 'BANNED',
}

export type DeleteDeliveryTargetInput = {
  id?: string | null;
};

export type CreateSaaSOwnerInput = {
  id?: string | null;
  hojin?: string | null;
  hojinKana?: string | null;
  representative?: FullNameInput | null;
  representativeKana?: FullNameInput | null;
  address?: FullAddressInput | null;
  url?: string | null;
  chargeDept?: string | null;
  charge?: FullNameInput | null;
  chargeKana?: FullNameInput | null;
  chargePhone?: string | null;
  status?: OwnerStatus | null;
  agentCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSOwnerConditionInput = {
  hojin?: ModelStringInput | null;
  hojinKana?: ModelStringInput | null;
  url?: ModelStringInput | null;
  chargeDept?: ModelStringInput | null;
  chargePhone?: ModelStringInput | null;
  status?: ModelOwnerStatusInput | null;
  agentCode?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSOwnerConditionInput | null> | null;
  or?: Array<ModelSaaSOwnerConditionInput | null> | null;
  not?: ModelSaaSOwnerConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null',
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelOwnerStatusInput = {
  eq?: OwnerStatus | null;
  ne?: OwnerStatus | null;
};

export type UpdateSaaSOwnerInput = {
  id: string;
  hojin?: string | null;
  hojinKana?: string | null;
  representative?: FullNameInput | null;
  representativeKana?: FullNameInput | null;
  address?: FullAddressInput | null;
  url?: string | null;
  chargeDept?: string | null;
  charge?: FullNameInput | null;
  chargeKana?: FullNameInput | null;
  chargePhone?: string | null;
  status?: OwnerStatus | null;
  agentCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSaaSOwnerInput = {
  id: string;
};

export type CreateSaaSShopInput = {
  id?: string | null;
  ownerId?: string | null;
  groupId?: string | null;
  name?: string | null;
  text?: string | null;
  address?: FullAddressInput | null;
  phone?: string | null;
  biztimes?: string | null;
  holidays?: string | null;
  url?: string | null;
  legalUrl?: string | null;
  category?: ShopCategory | null;
  imageUrls?: Array<string | null> | null;
  icon?: string | null;
  location?: LocationInput | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  shopState?: ShopState | null;
};

export type LocationInput = {
  lat?: number | null;
  lon?: number | null;
};

export type ModelSaaSShopConditionInput = {
  ownerId?: ModelIDInput | null;
  groupId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  text?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  biztimes?: ModelStringInput | null;
  holidays?: ModelStringInput | null;
  url?: ModelStringInput | null;
  legalUrl?: ModelStringInput | null;
  category?: ModelShopCategoryInput | null;
  imageUrls?: ModelStringInput | null;
  icon?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  shopState?: ModelShopStateInput | null;
  and?: Array<ModelSaaSShopConditionInput | null> | null;
  or?: Array<ModelSaaSShopConditionInput | null> | null;
  not?: ModelSaaSShopConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelShopCategoryInput = {
  eq?: ShopCategory | null;
  ne?: ShopCategory | null;
};

export type ModelShopStateInput = {
  eq?: ShopState | null;
  ne?: ShopState | null;
};

export type UpdateSaaSShopInput = {
  id: string;
  ownerId?: string | null;
  groupId?: string | null;
  name?: string | null;
  text?: string | null;
  address?: FullAddressInput | null;
  phone?: string | null;
  biztimes?: string | null;
  holidays?: string | null;
  url?: string | null;
  legalUrl?: string | null;
  category?: ShopCategory | null;
  imageUrls?: Array<string | null> | null;
  icon?: string | null;
  location?: LocationInput | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  shopState?: ShopState | null;
};

export type DeleteSaaSShopInput = {
  id: string;
};

export type CreateSaaSShopGroupInput = {
  id?: string | null;
  ownerId?: string | null;
  name?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSShopGroupConditionInput = {
  ownerId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSShopGroupConditionInput | null> | null;
  or?: Array<ModelSaaSShopGroupConditionInput | null> | null;
  not?: ModelSaaSShopGroupConditionInput | null;
};

export type UpdateSaaSShopGroupInput = {
  id: string;
  ownerId?: string | null;
  name?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSaaSShopGroupInput = {
  id: string;
};

export type CreateSaaSShopExcludeVisitorInput = {
  id?: string | null;
  shopId?: string | null;
  ownerId?: string | null;
  invitedCode?: string | null;
  userId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSShopExcludeVisitorConditionInput = {
  shopId?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  invitedCode?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSShopExcludeVisitorConditionInput | null> | null;
  or?: Array<ModelSaaSShopExcludeVisitorConditionInput | null> | null;
  not?: ModelSaaSShopExcludeVisitorConditionInput | null;
};

export type SaaSShopExcludeVisitor = {
  __typename: 'SaaSShopExcludeVisitor';
  id: string;
  shopId?: string | null;
  ownerId?: string | null;
  invitedCode?: string | null;
  userId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateSaaSShopExcludeVisitorInput = {
  id: string;
  shopId?: string | null;
  ownerId?: string | null;
  invitedCode?: string | null;
  userId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSaaSShopExcludeVisitorInput = {
  id: string;
};

export type CreateSaaSPaymentInfoInput = {
  id?: string | null;
  customerId?: string | null;
  paymentMethodId?: string | null;
  paymentType?: PaymentType | null;
  subscriptionId?: string | null;
  subscriptionItemIds?: Array<string | null> | null;
  priceIds?: Array<string | null> | null;
  billing?: boolean | null;
  invitedCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSPaymentInfoConditionInput = {
  customerId?: ModelIDInput | null;
  paymentMethodId?: ModelIDInput | null;
  paymentType?: ModelPaymentTypeInput | null;
  subscriptionId?: ModelIDInput | null;
  subscriptionItemIds?: ModelIDInput | null;
  priceIds?: ModelIDInput | null;
  billing?: ModelBooleanInput | null;
  invitedCode?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSPaymentInfoConditionInput | null> | null;
  or?: Array<ModelSaaSPaymentInfoConditionInput | null> | null;
  not?: ModelSaaSPaymentInfoConditionInput | null;
};

export type ModelPaymentTypeInput = {
  eq?: PaymentType | null;
  ne?: PaymentType | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateSaaSPaymentInfoInput = {
  id: string;
  customerId?: string | null;
  paymentMethodId?: string | null;
  paymentType?: PaymentType | null;
  subscriptionId?: string | null;
  subscriptionItemIds?: Array<string | null> | null;
  priceIds?: Array<string | null> | null;
  billing?: boolean | null;
  invitedCode?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSaaSPaymentInfoInput = {
  id: string;
};

export type CreateSaaSDeliveryTargetInput = {
  id?: string | null;
  ownerId?: string | null;
  shopId?: string | null;
  title?: string | null;
  areaOption?: AreaOptionInput | null;
  stayOption?: StayOptionInput | null;
  stationOption?: Array<string | null> | null;
  cityOption?: Array<CityOptionInput | null> | null;
  userOption?: UserOptionInput | null;
  users?: number | null;
  editState?: EditState | null;
  archive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSDeliveryTargetConditionInput = {
  ownerId?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  stationOption?: ModelStringInput | null;
  users?: ModelIntInput | null;
  editState?: ModelEditStateInput | null;
  archive?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSDeliveryTargetConditionInput | null> | null;
  or?: Array<ModelSaaSDeliveryTargetConditionInput | null> | null;
  not?: ModelSaaSDeliveryTargetConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelEditStateInput = {
  eq?: EditState | null;
  ne?: EditState | null;
};

export type UpdateSaaSDeliveryTargetInput = {
  id: string;
  ownerId?: string | null;
  shopId?: string | null;
  title?: string | null;
  areaOption?: AreaOptionInput | null;
  stayOption?: StayOptionInput | null;
  stationOption?: Array<string | null> | null;
  cityOption?: Array<CityOptionInput | null> | null;
  userOption?: UserOptionInput | null;
  users?: number | null;
  editState?: EditState | null;
  archive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSaaSDeliveryTargetInput = {
  id: string;
};

export type CreateSaaSAnalyticsInput = {
  id?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSAnalyticsConditionInput = {
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSAnalyticsConditionInput | null> | null;
  or?: Array<ModelSaaSAnalyticsConditionInput | null> | null;
  not?: ModelSaaSAnalyticsConditionInput | null;
};

export type SaaSAnalytics = {
  __typename: 'SaaSAnalytics';
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type UpdateSaaSAnalyticsInput = {
  id: string;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSaaSAnalyticsInput = {
  id: string;
};

export type CreateSaaSUserInfoInput = {
  id?: string | null;
  email?: string | null;
  familyName?: string | null;
  givenName?: string | null;
  agentCode?: string | null;
  reseller?: boolean | null;
  sendReportEmail?: SendReportEmailInput | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type SendReportEmailInput = {
  endOfDelivery?: boolean | null;
  shortBalance?: boolean | null;
};

export type ModelSaaSUserInfoConditionInput = {
  email?: ModelStringInput | null;
  familyName?: ModelStringInput | null;
  givenName?: ModelStringInput | null;
  agentCode?: ModelStringInput | null;
  reseller?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSUserInfoConditionInput | null> | null;
  or?: Array<ModelSaaSUserInfoConditionInput | null> | null;
  not?: ModelSaaSUserInfoConditionInput | null;
};

export type SaaSUserInfo = {
  __typename: 'SaaSUserInfo';
  id: string;
  email?: string | null;
  familyName?: string | null;
  givenName?: string | null;
  agentCode?: string | null;
  reseller?: boolean | null;
  sendReportEmail?: SendReportEmail | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type SendReportEmail = {
  __typename: 'SendReportEmail';
  endOfDelivery?: boolean | null;
  shortBalance?: boolean | null;
};

export type UpdateSaaSUserInfoInput = {
  id: string;
  email?: string | null;
  familyName?: string | null;
  givenName?: string | null;
  agentCode?: string | null;
  reseller?: boolean | null;
  sendReportEmail?: SendReportEmailInput | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSaaSUserInfoInput = {
  id: string;
};

export type CreateSaaSAccessManageInput = {
  id?: string | null;
  ownerId?: string | null;
  userId?: string | null;
  email?: string | null;
  addUserId?: string | null;
  role?: AccessManageRole | null;
  status?: AccessManageStatus | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSAccessManageConditionInput = {
  ownerId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  email?: ModelStringInput | null;
  addUserId?: ModelIDInput | null;
  role?: ModelAccessManageRoleInput | null;
  status?: ModelAccessManageStatusInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSAccessManageConditionInput | null> | null;
  or?: Array<ModelSaaSAccessManageConditionInput | null> | null;
  not?: ModelSaaSAccessManageConditionInput | null;
};

export type ModelAccessManageRoleInput = {
  eq?: AccessManageRole | null;
  ne?: AccessManageRole | null;
};

export type ModelAccessManageStatusInput = {
  eq?: AccessManageStatus | null;
  ne?: AccessManageStatus | null;
};

export type UpdateSaaSAccessManageInput = {
  id: string;
  ownerId?: string | null;
  userId?: string | null;
  email?: string | null;
  addUserId?: string | null;
  role?: AccessManageRole | null;
  status?: AccessManageStatus | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteSaaSAccessManageInput = {
  id: string;
};

export type SaaSCoupon = {
  __typename: 'SaaSCoupon';
  id: string;
  ownerId?: string | null;
  shopId?: string | null;
  name?: string | null;
  text?: string | null;
  title?: string | null;
  rule?: string | null;
  discount?: Discount | null;
  imageUrls?: Array<string | null> | null;
  barcodeUrl?: string | null;
  usage?: CouponUsage | null;
  editState?: EditState | null;
  cvLink?: CvLink | null;
  cvPhone?: string | null;
  archive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type Discount = {
  __typename: 'Discount';
  type?: DiscountType | null;
  param?: Array<number | null> | null;
  freeText?: string | null;
};

export enum DiscountType {
  BY_PRICE = 'BY_PRICE',
  BY_PERCENT = 'BY_PERCENT',
  FROM_PRICE = 'FROM_PRICE',
  BY_HALF = 'BY_HALF',
  BY_FREE = 'BY_FREE',
  BY_TEXT = 'BY_TEXT',
}

export enum CouponUsage {
  ONETIME = 'ONETIME',
  INFINITE = 'INFINITE',
}

export type CvLink = {
  __typename: 'CvLink';
  url?: string | null;
  text?: string | null;
};

export type ListCoupon = {
  __typename: 'ListCoupon';
  items?: Array<SaaSCoupon | null> | null;
  nextToken?: string | null;
};

export type SaaSNews = {
  __typename: 'SaaSNews';
  id: string;
  ownerId?: string | null;
  shopId?: string | null;
  name?: string | null;
  text?: string | null;
  title?: string | null;
  imageUrls?: Array<string | null> | null;
  editState?: EditState | null;
  cvLink?: CvLink | null;
  cvPhone?: string | null;
  archive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListNews = {
  __typename: 'ListNews';
  items?: Array<SaaSNews | null> | null;
  nextToken?: string | null;
};

export type ListDeliveryTarget = {
  __typename: 'ListDeliveryTarget';
  items?: Array<SaaSDeliveryTarget | null> | null;
  nextToken?: string | null;
};

export type ListShop = {
  __typename: 'ListShop';
  items?: Array<SaaSShop | null> | null;
  nextToken?: string | null;
};

export type ListShopGroup = {
  __typename: 'ListShopGroup';
  items?: Array<SaaSShopGroup | null> | null;
  nextToken?: string | null;
};

export type ListOwner = {
  __typename: 'ListOwner';
  items?: Array<SaaSOwner | null> | null;
  nextToken?: string | null;
};

export type SaaSDeliveryHistory = {
  __typename: 'SaaSDeliveryHistory';
  id: string;
  ownerId?: string | null;
  shopId?: string | null;
  couponId?: string | null;
  couponDef?: string | null;
  targetId?: string | null;
  targetDef?: string | null;
  title?: string | null;
  budget?: number | null;
  balance?: number | null;
  couponState?: CouponState | null;
  userOption?: UserOption | null;
  startAt?: string | null;
  endAt?: string | null;
  deliveryArea?: DeliveryArea | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum CouponState {
  DISPATCHING = 'DISPATCHING',
  ACTIVATE = 'ACTIVATE',
  UPDATING = 'UPDATING',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  BETA_FREEZE = 'BETA_FREEZE',
  BANNED = 'BANNED',
}

export enum DeliveryArea {
  AREA_ALL = 'AREA_ALL',
}

export type ListDeliveryHistory = {
  __typename: 'ListDeliveryHistory';
  items?: Array<SaaSDeliveryHistory | null> | null;
  nextToken?: string | null;
};

export type SaaSNewsHistory = {
  __typename: 'SaaSNewsHistory';
  id: string;
  ownerId?: string | null;
  shopId?: string | null;
  newsId?: string | null;
  newsDef?: string | null;
  targetId?: string | null;
  targetDef?: string | null;
  title?: string | null;
  budget?: number | null;
  balance?: number | null;
  newsState?: NewsState | null;
  userOption?: UserOption | null;
  startAt?: string | null;
  endAt?: string | null;
  deliveryArea?: DeliveryArea | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum NewsState {
  DISPATCHING = 'DISPATCHING',
  ACTIVATE = 'ACTIVATE',
  UPDATING = 'UPDATING',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  BETA_FREEZE = 'BETA_FREEZE',
  BANNED = 'BANNED',
}

export type ListNewsHistory = {
  __typename: 'ListNewsHistory';
  items?: Array<SaaSNewsHistory | null> | null;
  nextToken?: string | null;
};

export type ListAccessManageWithName = {
  __typename: 'ListAccessManageWithName';
  items?: Array<SaaSAccessManageWithName | null> | null;
  nextToken?: string | null;
};

export type SaaSAccessManageWithName = {
  __typename: 'SaaSAccessManageWithName';
  id: string;
  ownerId?: string | null;
  userId?: string | null;
  email?: string | null;
  addUserId?: string | null;
  role?: AccessManageRole | null;
  status?: AccessManageStatus | null;
  familyName?: string | null;
  givenName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ListAccessManage = {
  __typename: 'ListAccessManage';
  items?: Array<SaaSAccessManage | null> | null;
  nextToken?: string | null;
};

export type ModelSaaSOwnerFilterInput = {
  id?: ModelIDInput | null;
  hojin?: ModelStringInput | null;
  hojinKana?: ModelStringInput | null;
  url?: ModelStringInput | null;
  chargeDept?: ModelStringInput | null;
  chargePhone?: ModelStringInput | null;
  status?: ModelOwnerStatusInput | null;
  agentCode?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSOwnerFilterInput | null> | null;
  or?: Array<ModelSaaSOwnerFilterInput | null> | null;
  not?: ModelSaaSOwnerFilterInput | null;
};

export type ModelSaaSOwnerConnection = {
  __typename: 'ModelSaaSOwnerConnection';
  items: Array<SaaSOwner | null>;
  nextToken?: string | null;
};

export type SaaSArea = {
  __typename: 'SaaSArea';
  id: string;
  meshId?: string | null;
  userId?: string | null;
  areaType?: AreaType | null;
  stamp?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  unixStamp?: number | null;
};

export type ModelSaaSAreaFilterInput = {
  id?: ModelIDInput | null;
  meshId?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  areaType?: ModelAreaTypeInput | null;
  stamp?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  unixStamp?: ModelIntInput | null;
  and?: Array<ModelSaaSAreaFilterInput | null> | null;
  or?: Array<ModelSaaSAreaFilterInput | null> | null;
  not?: ModelSaaSAreaFilterInput | null;
};

export type ModelAreaTypeInput = {
  eq?: AreaType | null;
  ne?: AreaType | null;
};

export type ModelSaaSAreaConnection = {
  __typename: 'ModelSaaSAreaConnection';
  items: Array<SaaSArea | null>;
  nextToken?: string | null;
};

export type ModelIDKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type SaaSStay = {
  __typename: 'SaaSStay';
  id: string;
  meshId?: string | null;
  userId?: string | null;
  stayOffsets?: Array<number | null> | null;
  stamp?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  unixStamp?: number | null;
};

export type ModelSaaSStayFilterInput = {
  id?: ModelIDInput | null;
  meshId?: ModelStringInput | null;
  userId?: ModelIDInput | null;
  stayOffsets?: ModelIntInput | null;
  stamp?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  unixStamp?: ModelIntInput | null;
  and?: Array<ModelSaaSStayFilterInput | null> | null;
  or?: Array<ModelSaaSStayFilterInput | null> | null;
  not?: ModelSaaSStayFilterInput | null;
};

export type ModelSaaSStayConnection = {
  __typename: 'ModelSaaSStayConnection';
  items: Array<SaaSStay | null>;
  nextToken?: string | null;
};

export type SaaSStationPoi = {
  __typename: 'SaaSStationPoi';
  id: string;
  routeId?: string | null;
  poiType?: PoiType | null;
  parent?: string | null;
  seq?: number | null;
  name?: string | null;
  parentName?: string | null;
  innerId?: string | null;
  yomi?: string | null;
  mimi?: string | null;
  unixStamp?: number | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum PoiType {
  TRAIN_CATEGORY = 'TRAIN_CATEGORY',
  TRAIN_ROUTE = 'TRAIN_ROUTE',
  TRAIN_STATION = 'TRAIN_STATION',
}

export type ModelSaaSStationPoiFilterInput = {
  id?: ModelIDInput | null;
  routeId?: ModelStringInput | null;
  poiType?: ModelPoiTypeInput | null;
  parent?: ModelIDInput | null;
  seq?: ModelIntInput | null;
  name?: ModelStringInput | null;
  parentName?: ModelStringInput | null;
  innerId?: ModelIDInput | null;
  yomi?: ModelStringInput | null;
  mimi?: ModelStringInput | null;
  unixStamp?: ModelIntInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSStationPoiFilterInput | null> | null;
  or?: Array<ModelSaaSStationPoiFilterInput | null> | null;
  not?: ModelSaaSStationPoiFilterInput | null;
};

export type ModelPoiTypeInput = {
  eq?: PoiType | null;
  ne?: PoiType | null;
};

export type ModelSaaSStationPoiConnection = {
  __typename: 'ModelSaaSStationPoiConnection';
  items: Array<SaaSStationPoi | null>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type SaaSStation = {
  __typename: 'SaaSStation';
  id: string;
  userId?: string | null;
  routeId?: string | null;
  stationId?: string | null;
  masterId?: string | null;
  innerPoiId?: string | null;
  entryDays?: number | null;
  stamp?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  unixStamp?: number | null;
};

export type ModelSaaSStationFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  routeId?: ModelStringInput | null;
  stationId?: ModelStringInput | null;
  masterId?: ModelStringInput | null;
  innerPoiId?: ModelIDInput | null;
  entryDays?: ModelIntInput | null;
  stamp?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  unixStamp?: ModelIntInput | null;
  and?: Array<ModelSaaSStationFilterInput | null> | null;
  or?: Array<ModelSaaSStationFilterInput | null> | null;
  not?: ModelSaaSStationFilterInput | null;
};

export type ModelSaaSStationConnection = {
  __typename: 'ModelSaaSStationConnection';
  items: Array<SaaSStation | null>;
  nextToken?: string | null;
};

export type SaaSVisitor = {
  __typename: 'SaaSVisitor';
  id: string;
  userId?: string | null;
  shopId?: string | null;
  deliveryType?: DeliveryType | null;
  deliveryId?: string | null;
  stamp?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum DeliveryType {
  COUPON = 'COUPON',
  NEWS = 'NEWS',
}

export type ModelSaaSVisitorFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  shopId?: ModelStringInput | null;
  deliveryType?: ModelDeliveryTypeInput | null;
  deliveryId?: ModelIDInput | null;
  stamp?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSVisitorFilterInput | null> | null;
  or?: Array<ModelSaaSVisitorFilterInput | null> | null;
  not?: ModelSaaSVisitorFilterInput | null;
};

export type ModelDeliveryTypeInput = {
  eq?: DeliveryType | null;
  ne?: DeliveryType | null;
};

export type ModelSaaSVisitorConnection = {
  __typename: 'ModelSaaSVisitorConnection';
  items: Array<SaaSVisitor | null>;
  nextToken?: string | null;
};

export type SaaSCityPoi = {
  __typename: 'SaaSCityPoi';
  id: string;
  prefCode?: string | null;
  cityName?: string | null;
  prefName?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSCityPoiFilterInput = {
  id?: ModelIDInput | null;
  prefCode?: ModelStringInput | null;
  cityName?: ModelStringInput | null;
  prefName?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSCityPoiFilterInput | null> | null;
  or?: Array<ModelSaaSCityPoiFilterInput | null> | null;
  not?: ModelSaaSCityPoiFilterInput | null;
};

export type ModelSaaSCityPoiConnection = {
  __typename: 'ModelSaaSCityPoiConnection';
  items: Array<SaaSCityPoi | null>;
  nextToken?: string | null;
};

export type SaaSCityUserCount = {
  __typename: 'SaaSCityUserCount';
  id: string;
  cityCode?: string | null;
  prefCode?: string | null;
  userCount?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  stamp?: string | null;
  unixStamp?: number | null;
};

export type ModelSaaSCityUserCountFilterInput = {
  id?: ModelIDInput | null;
  cityCode?: ModelStringInput | null;
  prefCode?: ModelStringInput | null;
  userCount?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  stamp?: ModelStringInput | null;
  unixStamp?: ModelIntInput | null;
  and?: Array<ModelSaaSCityUserCountFilterInput | null> | null;
  or?: Array<ModelSaaSCityUserCountFilterInput | null> | null;
  not?: ModelSaaSCityUserCountFilterInput | null;
};

export type ModelSaaSCityUserCountConnection = {
  __typename: 'ModelSaaSCityUserCountConnection';
  items: Array<SaaSCityUserCount | null>;
  nextToken?: string | null;
};

export type SaaSUser = {
  __typename: 'SaaSUser';
  id: string;
  dotmoneyId?: string | null;
  job?: number | null;
  gender?: string | null;
  age?: number | null;
  married?: boolean | null;
  residence?: string | null;
  movingMethod?: string | null;
  childrenTogether?: number | null;
  childYoungest?: number | null;
  income?: number | null;
  householdIncome?: number | null;
  familyTogether?: number | null;
  address?: number | null;
  cityCode?: string | null;
  prefCode?: string | null;
  interest?: Array<number | null> | null;
  childAge?: Array<number | null> | null;
  stamp?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  unixStamp?: number | null;
};

export type ModelSaaSUserFilterInput = {
  id?: ModelIDInput | null;
  dotmoneyId?: ModelStringInput | null;
  job?: ModelIntInput | null;
  gender?: ModelStringInput | null;
  age?: ModelIntInput | null;
  married?: ModelBooleanInput | null;
  residence?: ModelStringInput | null;
  movingMethod?: ModelStringInput | null;
  childrenTogether?: ModelIntInput | null;
  childYoungest?: ModelIntInput | null;
  income?: ModelIntInput | null;
  householdIncome?: ModelIntInput | null;
  familyTogether?: ModelIntInput | null;
  address?: ModelIntInput | null;
  cityCode?: ModelStringInput | null;
  prefCode?: ModelStringInput | null;
  interest?: ModelIntInput | null;
  childAge?: ModelIntInput | null;
  stamp?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  unixStamp?: ModelIntInput | null;
  and?: Array<ModelSaaSUserFilterInput | null> | null;
  or?: Array<ModelSaaSUserFilterInput | null> | null;
  not?: ModelSaaSUserFilterInput | null;
};

export type ModelSaaSUserConnection = {
  __typename: 'ModelSaaSUserConnection';
  items: Array<SaaSUser | null>;
  nextToken?: string | null;
};

export type SearchableSaaSUserFilterInput = {
  id?: SearchableIDFilterInput | null;
  dotmoneyId?: SearchableStringFilterInput | null;
  job?: SearchableIntFilterInput | null;
  gender?: SearchableStringFilterInput | null;
  age?: SearchableIntFilterInput | null;
  married?: SearchableBooleanFilterInput | null;
  residence?: SearchableStringFilterInput | null;
  movingMethod?: SearchableStringFilterInput | null;
  childrenTogether?: SearchableIntFilterInput | null;
  childYoungest?: SearchableIntFilterInput | null;
  income?: SearchableIntFilterInput | null;
  householdIncome?: SearchableIntFilterInput | null;
  familyTogether?: SearchableIntFilterInput | null;
  address?: SearchableIntFilterInput | null;
  cityCode?: SearchableStringFilterInput | null;
  prefCode?: SearchableStringFilterInput | null;
  interest?: SearchableIntFilterInput | null;
  childAge?: SearchableIntFilterInput | null;
  stamp?: SearchableStringFilterInput | null;
  createdAt?: SearchableStringFilterInput | null;
  updatedAt?: SearchableStringFilterInput | null;
  unixStamp?: SearchableIntFilterInput | null;
  and?: Array<SearchableSaaSUserFilterInput | null> | null;
  or?: Array<SearchableSaaSUserFilterInput | null> | null;
  not?: SearchableSaaSUserFilterInput | null;
};

export type SearchableIDFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableStringFilterInput = {
  ne?: string | null;
  gt?: string | null;
  lt?: string | null;
  gte?: string | null;
  lte?: string | null;
  eq?: string | null;
  match?: string | null;
  matchPhrase?: string | null;
  matchPhrasePrefix?: string | null;
  multiMatch?: string | null;
  exists?: boolean | null;
  wildcard?: string | null;
  regexp?: string | null;
  range?: Array<string | null> | null;
};

export type SearchableIntFilterInput = {
  ne?: number | null;
  gt?: number | null;
  lt?: number | null;
  gte?: number | null;
  lte?: number | null;
  eq?: number | null;
  range?: Array<number | null> | null;
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null;
  ne?: boolean | null;
};

export type SearchableSaaSUserSortInput = {
  field?: SearchableSaaSUserSortableFields | null;
  direction?: SearchableSortDirection | null;
};

export enum SearchableSaaSUserSortableFields {
  id = 'id',
  dotmoneyId = 'dotmoneyId',
  job = 'job',
  gender = 'gender',
  age = 'age',
  married = 'married',
  residence = 'residence',
  movingMethod = 'movingMethod',
  childrenTogether = 'childrenTogether',
  childYoungest = 'childYoungest',
  income = 'income',
  householdIncome = 'householdIncome',
  familyTogether = 'familyTogether',
  address = 'address',
  cityCode = 'cityCode',
  prefCode = 'prefCode',
  interest = 'interest',
  childAge = 'childAge',
  stamp = 'stamp',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  unixStamp = 'unixStamp',
}

export enum SearchableSortDirection {
  asc = 'asc',
  desc = 'desc',
}

export type SearchableSaaSUserAggregationInput = {
  name: string;
  type: SearchableAggregateType;
  field: SearchableSaaSUserAggregateField;
};

export enum SearchableAggregateType {
  terms = 'terms',
  avg = 'avg',
  min = 'min',
  max = 'max',
  sum = 'sum',
}

export enum SearchableSaaSUserAggregateField {
  id = 'id',
  dotmoneyId = 'dotmoneyId',
  job = 'job',
  gender = 'gender',
  age = 'age',
  married = 'married',
  residence = 'residence',
  movingMethod = 'movingMethod',
  childrenTogether = 'childrenTogether',
  childYoungest = 'childYoungest',
  income = 'income',
  householdIncome = 'householdIncome',
  familyTogether = 'familyTogether',
  address = 'address',
  cityCode = 'cityCode',
  prefCode = 'prefCode',
  interest = 'interest',
  childAge = 'childAge',
  stamp = 'stamp',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  unixStamp = 'unixStamp',
}

export type SearchableSaaSUserConnection = {
  __typename: 'SearchableSaaSUserConnection';
  items: Array<SaaSUser | null>;
  nextToken?: string | null;
  total?: number | null;
  aggregateItems: Array<SearchableAggregateResult | null>;
};

export type SearchableAggregateResult = {
  __typename: 'SearchableAggregateResult';
  name: string;
  result?: SearchableAggregateGenericResult | null;
};

export type SearchableAggregateGenericResult =
  | SearchableAggregateScalarResult
  | SearchableAggregateBucketResult;

export type SearchableAggregateScalarResult = {
  __typename: 'SearchableAggregateScalarResult';
  value: number;
};

export type SearchableAggregateBucketResult = {
  __typename: 'SearchableAggregateBucketResult';
  buckets?: Array<SearchableAggregateBucketResultItem | null> | null;
};

export type SearchableAggregateBucketResultItem = {
  __typename: 'SearchableAggregateBucketResultItem';
  key: string;
  doc_count: number;
};

export type SaaSSocial = {
  __typename: 'SaaSSocial';
  id: string;
  shopId?: string | null;
  userId?: string | null;
  userHashId?: string | null;
  followed?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSSocialFilterInput = {
  id?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  userHashId?: ModelIDInput | null;
  followed?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSSocialFilterInput | null> | null;
  or?: Array<ModelSaaSSocialFilterInput | null> | null;
  not?: ModelSaaSSocialFilterInput | null;
};

export type ModelSaaSSocialConnection = {
  __typename: 'ModelSaaSSocialConnection';
  items: Array<SaaSSocial | null>;
  nextToken?: string | null;
};

export type SaaSSocialLog = {
  __typename: 'SaaSSocialLog';
  id: string;
  shopId?: string | null;
  userId?: string | null;
  stamp?: string | null;
  action?: SocialAction | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum SocialAction {
  FOLLOW_SHOP = 'FOLLOW_SHOP',
  UNFOLLOW_SHOP = 'UNFOLLOW_SHOP',
}

export type ModelSaaSSocialLogFilterInput = {
  id?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  stamp?: ModelStringInput | null;
  action?: ModelSocialActionInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSSocialLogFilterInput | null> | null;
  or?: Array<ModelSaaSSocialLogFilterInput | null> | null;
  not?: ModelSaaSSocialLogFilterInput | null;
};

export type ModelSocialActionInput = {
  eq?: SocialAction | null;
  ne?: SocialAction | null;
};

export type ModelSaaSSocialLogConnection = {
  __typename: 'ModelSaaSSocialLogConnection';
  items: Array<SaaSSocialLog | null>;
  nextToken?: string | null;
};

export type ModelSaaSShopFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  groupId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  text?: ModelStringInput | null;
  phone?: ModelStringInput | null;
  biztimes?: ModelStringInput | null;
  holidays?: ModelStringInput | null;
  url?: ModelStringInput | null;
  legalUrl?: ModelStringInput | null;
  category?: ModelShopCategoryInput | null;
  imageUrls?: ModelStringInput | null;
  icon?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  shopState?: ModelShopStateInput | null;
  and?: Array<ModelSaaSShopFilterInput | null> | null;
  or?: Array<ModelSaaSShopFilterInput | null> | null;
  not?: ModelSaaSShopFilterInput | null;
};

export type ModelSaaSShopConnection = {
  __typename: 'ModelSaaSShopConnection';
  items: Array<SaaSShop | null>;
  nextToken?: string | null;
};

export type ModelSaaSShopGroupFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSShopGroupFilterInput | null> | null;
  or?: Array<ModelSaaSShopGroupFilterInput | null> | null;
  not?: ModelSaaSShopGroupFilterInput | null;
};

export type ModelSaaSShopGroupConnection = {
  __typename: 'ModelSaaSShopGroupConnection';
  items: Array<SaaSShopGroup | null>;
  nextToken?: string | null;
};

export type ModelSaaSShopExcludeVisitorFilterInput = {
  id?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  invitedCode?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSShopExcludeVisitorFilterInput | null> | null;
  or?: Array<ModelSaaSShopExcludeVisitorFilterInput | null> | null;
  not?: ModelSaaSShopExcludeVisitorFilterInput | null;
};

export type ModelSaaSShopExcludeVisitorConnection = {
  __typename: 'ModelSaaSShopExcludeVisitorConnection';
  items: Array<SaaSShopExcludeVisitor | null>;
  nextToken?: string | null;
};

export type ModelSaaSPaymentInfoFilterInput = {
  id?: ModelIDInput | null;
  customerId?: ModelIDInput | null;
  paymentMethodId?: ModelIDInput | null;
  paymentType?: ModelPaymentTypeInput | null;
  subscriptionId?: ModelIDInput | null;
  subscriptionItemIds?: ModelIDInput | null;
  priceIds?: ModelIDInput | null;
  billing?: ModelBooleanInput | null;
  invitedCode?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSPaymentInfoFilterInput | null> | null;
  or?: Array<ModelSaaSPaymentInfoFilterInput | null> | null;
  not?: ModelSaaSPaymentInfoFilterInput | null;
};

export type ModelSaaSPaymentInfoConnection = {
  __typename: 'ModelSaaSPaymentInfoConnection';
  items: Array<SaaSPaymentInfo | null>;
  nextToken?: string | null;
};

export type ModelSaaSCouponFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  shopId?: ModelStringInput | null;
  name?: ModelStringInput | null;
  text?: ModelStringInput | null;
  title?: ModelStringInput | null;
  rule?: ModelStringInput | null;
  imageUrls?: ModelStringInput | null;
  barcodeUrl?: ModelStringInput | null;
  usage?: ModelCouponUsageInput | null;
  editState?: ModelEditStateInput | null;
  cvPhone?: ModelStringInput | null;
  archive?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSCouponFilterInput | null> | null;
  or?: Array<ModelSaaSCouponFilterInput | null> | null;
  not?: ModelSaaSCouponFilterInput | null;
};

export type ModelCouponUsageInput = {
  eq?: CouponUsage | null;
  ne?: CouponUsage | null;
};

export type ModelSaaSCouponConnection = {
  __typename: 'ModelSaaSCouponConnection';
  items: Array<SaaSCoupon | null>;
  nextToken?: string | null;
};

export type ModelSaaSNewsFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  name?: ModelStringInput | null;
  text?: ModelStringInput | null;
  title?: ModelStringInput | null;
  imageUrls?: ModelStringInput | null;
  editState?: ModelEditStateInput | null;
  cvPhone?: ModelStringInput | null;
  archive?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNewsFilterInput | null> | null;
  or?: Array<ModelSaaSNewsFilterInput | null> | null;
  not?: ModelSaaSNewsFilterInput | null;
};

export type ModelSaaSNewsConnection = {
  __typename: 'ModelSaaSNewsConnection';
  items: Array<SaaSNews | null>;
  nextToken?: string | null;
};

export type ModelSaaSDeliveryTargetFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  stationOption?: ModelStringInput | null;
  users?: ModelIntInput | null;
  editState?: ModelEditStateInput | null;
  archive?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSDeliveryTargetFilterInput | null> | null;
  or?: Array<ModelSaaSDeliveryTargetFilterInput | null> | null;
  not?: ModelSaaSDeliveryTargetFilterInput | null;
};

export type ModelSaaSDeliveryTargetConnection = {
  __typename: 'ModelSaaSDeliveryTargetConnection';
  items: Array<SaaSDeliveryTarget | null>;
  nextToken?: string | null;
};

export type ModelSaaSNewsHistoryFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  newsId?: ModelIDInput | null;
  newsDef?: ModelStringInput | null;
  targetId?: ModelIDInput | null;
  targetDef?: ModelStringInput | null;
  title?: ModelStringInput | null;
  budget?: ModelFloatInput | null;
  balance?: ModelFloatInput | null;
  newsState?: ModelNewsStateInput | null;
  startAt?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  deliveryArea?: ModelDeliveryAreaInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNewsHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSNewsHistoryFilterInput | null> | null;
  not?: ModelSaaSNewsHistoryFilterInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelNewsStateInput = {
  eq?: NewsState | null;
  ne?: NewsState | null;
};

export type ModelDeliveryAreaInput = {
  eq?: DeliveryArea | null;
  ne?: DeliveryArea | null;
};

export type ModelSaaSNewsHistoryConnection = {
  __typename: 'ModelSaaSNewsHistoryConnection';
  items: Array<SaaSNewsHistory | null>;
  nextToken?: string | null;
};

export type SaaSNewsLog = {
  __typename: 'SaaSNewsLog';
  id: string;
  shopId?: string | null;
  newsHistoryId?: string | null;
  stamp?: string | null;
  deliveryAt?: string | null;
  userId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSNewsLogFilterInput = {
  id?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  newsHistoryId?: ModelIDInput | null;
  stamp?: ModelStringInput | null;
  deliveryAt?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNewsLogFilterInput | null> | null;
  or?: Array<ModelSaaSNewsLogFilterInput | null> | null;
  not?: ModelSaaSNewsLogFilterInput | null;
};

export type ModelSaaSNewsLogConnection = {
  __typename: 'ModelSaaSNewsLogConnection';
  items: Array<SaaSNewsLog | null>;
  nextToken?: string | null;
};

export type SaaSNewsAreaHistory = {
  __typename: 'SaaSNewsAreaHistory';
  id: string;
  deliveryId?: string | null;
  meshId?: string | null;
  startAt?: string | null;
  endAt?: string | null;
  areaOption?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSNewsAreaHistoryFilterInput = {
  id?: ModelIDInput | null;
  deliveryId?: ModelIDInput | null;
  meshId?: ModelStringInput | null;
  startAt?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  areaOption?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNewsAreaHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSNewsAreaHistoryFilterInput | null> | null;
  not?: ModelSaaSNewsAreaHistoryFilterInput | null;
};

export type ModelSaaSNewsAreaHistoryConnection = {
  __typename: 'ModelSaaSNewsAreaHistoryConnection';
  items: Array<SaaSNewsAreaHistory | null>;
  nextToken?: string | null;
};

export type SaaSNewsStayHistory = {
  __typename: 'SaaSNewsStayHistory';
  id: string;
  deliveryId?: string | null;
  meshId?: string | null;
  startAt?: string | null;
  endAt?: string | null;
  stayOption?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSNewsStayHistoryFilterInput = {
  id?: ModelIDInput | null;
  deliveryId?: ModelIDInput | null;
  meshId?: ModelStringInput | null;
  startAt?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  stayOption?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNewsStayHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSNewsStayHistoryFilterInput | null> | null;
  not?: ModelSaaSNewsStayHistoryFilterInput | null;
};

export type ModelSaaSNewsStayHistoryConnection = {
  __typename: 'ModelSaaSNewsStayHistoryConnection';
  items: Array<SaaSNewsStayHistory | null>;
  nextToken?: string | null;
};

export type SaaSNewsStationHistory = {
  __typename: 'SaaSNewsStationHistory';
  id: string;
  deliveryId?: string | null;
  stationId?: string | null;
  startAt?: string | null;
  endAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSNewsStationHistoryFilterInput = {
  id?: ModelIDInput | null;
  deliveryId?: ModelIDInput | null;
  stationId?: ModelStringInput | null;
  startAt?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNewsStationHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSNewsStationHistoryFilterInput | null> | null;
  not?: ModelSaaSNewsStationHistoryFilterInput | null;
};

export type ModelSaaSNewsStationHistoryConnection = {
  __typename: 'ModelSaaSNewsStationHistoryConnection';
  items: Array<SaaSNewsStationHistory | null>;
  nextToken?: string | null;
};

export type SaaSNewsBillingHistory = {
  __typename: 'SaaSNewsBillingHistory';
  id: string;
  userId?: string | null;
  stamp?: string | null;
  deliveryId?: string | null;
  amount?: number | null;
  feedbackState?: NewsFeedbackState | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum NewsFeedbackState {
  VIEWED = 'VIEWED',
  KEPT = 'KEPT',
  RELEASED = 'RELEASED',
  DISABLED = 'DISABLED',
}

export type ModelSaaSNewsBillingHistoryFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  stamp?: ModelStringInput | null;
  deliveryId?: ModelIDInput | null;
  amount?: ModelIntInput | null;
  feedbackState?: ModelNewsFeedbackStateInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNewsBillingHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSNewsBillingHistoryFilterInput | null> | null;
  not?: ModelSaaSNewsBillingHistoryFilterInput | null;
};

export type ModelNewsFeedbackStateInput = {
  eq?: NewsFeedbackState | null;
  ne?: NewsFeedbackState | null;
};

export type ModelSaaSNewsBillingHistoryConnection = {
  __typename: 'ModelSaaSNewsBillingHistoryConnection';
  items: Array<SaaSNewsBillingHistory | null>;
  nextToken?: string | null;
};

export type SaaSNewsFeedbackHistory = {
  __typename: 'SaaSNewsFeedbackHistory';
  id: string;
  userId?: string | null;
  deliveryId?: string | null;
  stamp?: string | null;
  feedbackType?: NewsFeedbackType | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum NewsFeedbackType {
  VIEW = 'VIEW',
  KEEP = 'KEEP',
  RELEASE = 'RELEASE',
  CVLINK = 'CVLINK',
  CVPHONE = 'CVPHONE',
}

export type ModelSaaSNewsFeedbackHistoryFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  deliveryId?: ModelIDInput | null;
  stamp?: ModelStringInput | null;
  feedbackType?: ModelNewsFeedbackTypeInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNewsFeedbackHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSNewsFeedbackHistoryFilterInput | null> | null;
  not?: ModelSaaSNewsFeedbackHistoryFilterInput | null;
};

export type ModelNewsFeedbackTypeInput = {
  eq?: NewsFeedbackType | null;
  ne?: NewsFeedbackType | null;
};

export type ModelSaaSNewsFeedbackHistoryConnection = {
  __typename: 'ModelSaaSNewsFeedbackHistoryConnection';
  items: Array<SaaSNewsFeedbackHistory | null>;
  nextToken?: string | null;
};

export type ModelSaaSDeliveryHistoryFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  couponId?: ModelIDInput | null;
  couponDef?: ModelStringInput | null;
  targetId?: ModelIDInput | null;
  targetDef?: ModelStringInput | null;
  title?: ModelStringInput | null;
  budget?: ModelFloatInput | null;
  balance?: ModelFloatInput | null;
  couponState?: ModelCouponStateInput | null;
  startAt?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  deliveryArea?: ModelDeliveryAreaInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSDeliveryHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSDeliveryHistoryFilterInput | null> | null;
  not?: ModelSaaSDeliveryHistoryFilterInput | null;
};

export type ModelCouponStateInput = {
  eq?: CouponState | null;
  ne?: CouponState | null;
};

export type ModelSaaSDeliveryHistoryConnection = {
  __typename: 'ModelSaaSDeliveryHistoryConnection';
  items: Array<SaaSDeliveryHistory | null>;
  nextToken?: string | null;
};

export type SaaSDeliveredLog = {
  __typename: 'SaaSDeliveredLog';
  id: string;
  shopId?: string | null;
  deliveryHistoryId?: string | null;
  stamp?: string | null;
  deliveryAt?: string | null;
  userId?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSDeliveredLogFilterInput = {
  id?: ModelIDInput | null;
  shopId?: ModelIDInput | null;
  deliveryHistoryId?: ModelIDInput | null;
  stamp?: ModelStringInput | null;
  deliveryAt?: ModelStringInput | null;
  userId?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSDeliveredLogFilterInput | null> | null;
  or?: Array<ModelSaaSDeliveredLogFilterInput | null> | null;
  not?: ModelSaaSDeliveredLogFilterInput | null;
};

export type ModelSaaSDeliveredLogConnection = {
  __typename: 'ModelSaaSDeliveredLogConnection';
  items: Array<SaaSDeliveredLog | null>;
  nextToken?: string | null;
};

export type SaaSCouponAreaHistory = {
  __typename: 'SaaSCouponAreaHistory';
  id: string;
  deliveryId?: string | null;
  meshId?: string | null;
  startAt?: string | null;
  endAt?: string | null;
  areaOption?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSCouponAreaHistoryFilterInput = {
  id?: ModelIDInput | null;
  deliveryId?: ModelIDInput | null;
  meshId?: ModelStringInput | null;
  startAt?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  areaOption?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSCouponAreaHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSCouponAreaHistoryFilterInput | null> | null;
  not?: ModelSaaSCouponAreaHistoryFilterInput | null;
};

export type ModelSaaSCouponAreaHistoryConnection = {
  __typename: 'ModelSaaSCouponAreaHistoryConnection';
  items: Array<SaaSCouponAreaHistory | null>;
  nextToken?: string | null;
};

export type SaaSCouponStayHistory = {
  __typename: 'SaaSCouponStayHistory';
  id: string;
  deliveryId?: string | null;
  meshId?: string | null;
  startAt?: string | null;
  endAt?: string | null;
  stayOption?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSCouponStayHistoryFilterInput = {
  id?: ModelIDInput | null;
  deliveryId?: ModelIDInput | null;
  meshId?: ModelStringInput | null;
  startAt?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  stayOption?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSCouponStayHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSCouponStayHistoryFilterInput | null> | null;
  not?: ModelSaaSCouponStayHistoryFilterInput | null;
};

export type ModelSaaSCouponStayHistoryConnection = {
  __typename: 'ModelSaaSCouponStayHistoryConnection';
  items: Array<SaaSCouponStayHistory | null>;
  nextToken?: string | null;
};

export type SaaSCouponStationHistory = {
  __typename: 'SaaSCouponStationHistory';
  id: string;
  deliveryId?: string | null;
  stationId?: string | null;
  startAt?: string | null;
  endAt?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelSaaSCouponStationHistoryFilterInput = {
  id?: ModelIDInput | null;
  deliveryId?: ModelIDInput | null;
  stationId?: ModelStringInput | null;
  startAt?: ModelStringInput | null;
  endAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSCouponStationHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSCouponStationHistoryFilterInput | null> | null;
  not?: ModelSaaSCouponStationHistoryFilterInput | null;
};

export type ModelSaaSCouponStationHistoryConnection = {
  __typename: 'ModelSaaSCouponStationHistoryConnection';
  items: Array<SaaSCouponStationHistory | null>;
  nextToken?: string | null;
};

export type SaaSCouponBillingHistory = {
  __typename: 'SaaSCouponBillingHistory';
  id: string;
  userId?: string | null;
  stamp?: string | null;
  deliveryId?: string | null;
  amount?: number | null;
  feedbackState?: CouponFeedbackState | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum CouponFeedbackState {
  VIEWED = 'VIEWED',
  ACQUIRED = 'ACQUIRED',
  RELEASED = 'RELEASED',
  DISABLED = 'DISABLED',
}

export type ModelSaaSCouponBillingHistoryFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  stamp?: ModelStringInput | null;
  deliveryId?: ModelIDInput | null;
  amount?: ModelIntInput | null;
  feedbackState?: ModelCouponFeedbackStateInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSCouponBillingHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSCouponBillingHistoryFilterInput | null> | null;
  not?: ModelSaaSCouponBillingHistoryFilterInput | null;
};

export type ModelCouponFeedbackStateInput = {
  eq?: CouponFeedbackState | null;
  ne?: CouponFeedbackState | null;
};

export type ModelSaaSCouponBillingHistoryConnection = {
  __typename: 'ModelSaaSCouponBillingHistoryConnection';
  items: Array<SaaSCouponBillingHistory | null>;
  nextToken?: string | null;
};

export type SaaSCouponFeedbackHistory = {
  __typename: 'SaaSCouponFeedbackHistory';
  id: string;
  userId?: string | null;
  deliveryId?: string | null;
  stamp?: string | null;
  feedbackType?: CouponFeedbackType | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum CouponFeedbackType {
  VIEW = 'VIEW',
  ACQUIRE = 'ACQUIRE',
  RELEASE = 'RELEASE',
  USE = 'USE',
  CVLINK = 'CVLINK',
  CVPHONE = 'CVPHONE',
}

export type ModelSaaSCouponFeedbackHistoryFilterInput = {
  id?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  deliveryId?: ModelIDInput | null;
  stamp?: ModelStringInput | null;
  feedbackType?: ModelCouponFeedbackTypeInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSCouponFeedbackHistoryFilterInput | null> | null;
  or?: Array<ModelSaaSCouponFeedbackHistoryFilterInput | null> | null;
  not?: ModelSaaSCouponFeedbackHistoryFilterInput | null;
};

export type ModelCouponFeedbackTypeInput = {
  eq?: CouponFeedbackType | null;
  ne?: CouponFeedbackType | null;
};

export type ModelSaaSCouponFeedbackHistoryConnection = {
  __typename: 'ModelSaaSCouponFeedbackHistoryConnection';
  items: Array<SaaSCouponFeedbackHistory | null>;
  nextToken?: string | null;
};

export type ModelSaaSAnalyticsFilterInput = {
  id?: ModelIDInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSAnalyticsFilterInput | null> | null;
  or?: Array<ModelSaaSAnalyticsFilterInput | null> | null;
  not?: ModelSaaSAnalyticsFilterInput | null;
};

export type ModelSaaSAnalyticsConnection = {
  __typename: 'ModelSaaSAnalyticsConnection';
  items: Array<SaaSAnalytics | null>;
  nextToken?: string | null;
};

export type SaaSNotification = {
  __typename: 'SaaSNotification';
  id: string;
  ownerId?: string | null;
  targetId?: string | null;
  title?: string | null;
  content?: string | null;
  type?: NotificationType | null;
  read?: boolean | null;
  issue?: Issue | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export enum NotificationType {
  WARNING = 'WARNING',
  NOTICE = 'NOTICE',
}

export type Issue = {
  __typename: 'Issue';
  target?: IssueTarget | null;
  reasons?: IssueReason | null;
  treatments?: IssueTreatment | null;
  needActions?: IssueNeedAction | null;
};

export enum IssueTarget {
  SHOP = 'SHOP',
  COUPON = 'COUPON',
  NEWS = 'NEWS',
}

export enum IssueReason {
  NOT_EXIST = 'NOT_EXIST',
  INVALID = 'INVALID',
  FAKE = 'FAKE',
  INAPPROPRIATE = 'INAPPROPRIATE',
}

export enum IssueTreatment {
  STOP = 'STOP',
  FREEZE = 'FREEZE',
}

export enum IssueNeedAction {
  FIX = 'FIX',
  COMFIRM = 'COMFIRM',
}

export type ModelSaaSNotificationFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  targetId?: ModelIDInput | null;
  title?: ModelStringInput | null;
  content?: ModelStringInput | null;
  type?: ModelNotificationTypeInput | null;
  read?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSNotificationFilterInput | null> | null;
  or?: Array<ModelSaaSNotificationFilterInput | null> | null;
  not?: ModelSaaSNotificationFilterInput | null;
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null;
  ne?: NotificationType | null;
};

export type ModelSaaSNotificationConnection = {
  __typename: 'ModelSaaSNotificationConnection';
  items: Array<SaaSNotification | null>;
  nextToken?: string | null;
};

export type ModelSaaSUserInfoFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  familyName?: ModelStringInput | null;
  givenName?: ModelStringInput | null;
  agentCode?: ModelStringInput | null;
  reseller?: ModelBooleanInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSUserInfoFilterInput | null> | null;
  or?: Array<ModelSaaSUserInfoFilterInput | null> | null;
  not?: ModelSaaSUserInfoFilterInput | null;
};

export type ModelSaaSUserInfoConnection = {
  __typename: 'ModelSaaSUserInfoConnection';
  items: Array<SaaSUserInfo | null>;
  nextToken?: string | null;
};

export type ModelSaaSAccessManageFilterInput = {
  id?: ModelIDInput | null;
  ownerId?: ModelIDInput | null;
  userId?: ModelIDInput | null;
  email?: ModelStringInput | null;
  addUserId?: ModelIDInput | null;
  role?: ModelAccessManageRoleInput | null;
  status?: ModelAccessManageStatusInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelSaaSAccessManageFilterInput | null> | null;
  or?: Array<ModelSaaSAccessManageFilterInput | null> | null;
  not?: ModelSaaSAccessManageFilterInput | null;
};

export type ModelSaaSAccessManageConnection = {
  __typename: 'ModelSaaSAccessManageConnection';
  items: Array<SaaSAccessManage | null>;
  nextToken?: string | null;
};

export type InviteInfo = {
  __typename: 'InviteInfo';
  email?: string | null;
  hostEmail?: string | null;
  status?: AccessManageStatus | null;
  createdAt?: string | null;
};

export type CreateOwnerMutationVariables = {
  input?: OwnerInput | null;
};

export type CreateOwnerMutation = {
  createOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateOwnerMutationVariables = {
  input?: OwnerInput | null;
};

export type UpdateOwnerMutation = {
  updateOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdatePaymentInfoMutationVariables = {
  input?: PaymentInfoInput | null;
};

export type UpdatePaymentInfoMutation = {
  updatePaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateAccessManageMutationVariables = {
  input?: AccessManageInput | null;
};

export type CreateAccessManageMutation = {
  createAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateAccessManageMutationVariables = {
  input?: AccessManageInput | null;
};

export type UpdateAccessManageMutation = {
  updateAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateAccessManageEmailMutationVariables = {
  input?: UpdateAccessManageEmailInput | null;
};

export type UpdateAccessManageEmailMutation = {
  updateAccessManageEmail?: Array<{
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null> | null;
};

export type DeleteAccessManageMutationVariables = {
  input?: DeleteAccessManageInput | null;
};

export type DeleteAccessManageMutation = {
  deleteAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type MigrateOwnerAccessMutationVariables = {
  input?: MigrateOwnerAccessInput | null;
};

export type MigrateOwnerAccessMutation = {
  migrateOwnerAccess?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateDeliveryTargetMutationVariables = {
  input?: DeliveryTargetInput | null;
};

export type CreateDeliveryTargetMutation = {
  createDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateDeliveryTargetMutationVariables = {
  input?: DeliveryTargetInput | null;
};

export type UpdateDeliveryTargetMutation = {
  updateDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateShopGroupMutationVariables = {
  input?: ShopGroupInput | null;
};

export type CreateShopGroupMutation = {
  createShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateShopGroupMutationVariables = {
  input?: ShopGroupInput | null;
};

export type UpdateShopGroupMutation = {
  updateShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteShopGroupMutationVariables = {
  input?: DeleteShopGroupInput | null;
};

export type DeleteShopGroupMutation = {
  deleteShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type SetGroup2ShopMutationVariables = {
  input?: SetGroup2ShopInput | null;
};

export type SetGroup2ShopMutation = {
  setGroup2Shop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type DeleteDeliveryTargetMutationVariables = {
  input?: DeleteDeliveryTargetInput | null;
};

export type DeleteDeliveryTargetMutation = {
  deleteDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSaaSOwnerMutationVariables = {
  input: CreateSaaSOwnerInput;
  condition?: ModelSaaSOwnerConditionInput | null;
};

export type CreateSaaSOwnerMutation = {
  createSaaSOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSaaSOwnerMutationVariables = {
  input: UpdateSaaSOwnerInput;
  condition?: ModelSaaSOwnerConditionInput | null;
};

export type UpdateSaaSOwnerMutation = {
  updateSaaSOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSaaSOwnerMutationVariables = {
  input: DeleteSaaSOwnerInput;
  condition?: ModelSaaSOwnerConditionInput | null;
};

export type DeleteSaaSOwnerMutation = {
  deleteSaaSOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSaaSShopMutationVariables = {
  input: CreateSaaSShopInput;
  condition?: ModelSaaSShopConditionInput | null;
};

export type CreateSaaSShopMutation = {
  createSaaSShop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type UpdateSaaSShopMutationVariables = {
  input: UpdateSaaSShopInput;
  condition?: ModelSaaSShopConditionInput | null;
};

export type UpdateSaaSShopMutation = {
  updateSaaSShop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type DeleteSaaSShopMutationVariables = {
  input: DeleteSaaSShopInput;
  condition?: ModelSaaSShopConditionInput | null;
};

export type DeleteSaaSShopMutation = {
  deleteSaaSShop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type CreateSaaSShopGroupMutationVariables = {
  input: CreateSaaSShopGroupInput;
  condition?: ModelSaaSShopGroupConditionInput | null;
};

export type CreateSaaSShopGroupMutation = {
  createSaaSShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSaaSShopGroupMutationVariables = {
  input: UpdateSaaSShopGroupInput;
  condition?: ModelSaaSShopGroupConditionInput | null;
};

export type UpdateSaaSShopGroupMutation = {
  updateSaaSShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSaaSShopGroupMutationVariables = {
  input: DeleteSaaSShopGroupInput;
  condition?: ModelSaaSShopGroupConditionInput | null;
};

export type DeleteSaaSShopGroupMutation = {
  deleteSaaSShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSaaSShopExcludeVisitorMutationVariables = {
  input: CreateSaaSShopExcludeVisitorInput;
  condition?: ModelSaaSShopExcludeVisitorConditionInput | null;
};

export type CreateSaaSShopExcludeVisitorMutation = {
  createSaaSShopExcludeVisitor?: {
    __typename: 'SaaSShopExcludeVisitor';
    id: string;
    shopId?: string | null;
    ownerId?: string | null;
    invitedCode?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSaaSShopExcludeVisitorMutationVariables = {
  input: UpdateSaaSShopExcludeVisitorInput;
  condition?: ModelSaaSShopExcludeVisitorConditionInput | null;
};

export type UpdateSaaSShopExcludeVisitorMutation = {
  updateSaaSShopExcludeVisitor?: {
    __typename: 'SaaSShopExcludeVisitor';
    id: string;
    shopId?: string | null;
    ownerId?: string | null;
    invitedCode?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSaaSShopExcludeVisitorMutationVariables = {
  input: DeleteSaaSShopExcludeVisitorInput;
  condition?: ModelSaaSShopExcludeVisitorConditionInput | null;
};

export type DeleteSaaSShopExcludeVisitorMutation = {
  deleteSaaSShopExcludeVisitor?: {
    __typename: 'SaaSShopExcludeVisitor';
    id: string;
    shopId?: string | null;
    ownerId?: string | null;
    invitedCode?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSaaSPaymentInfoMutationVariables = {
  input: CreateSaaSPaymentInfoInput;
  condition?: ModelSaaSPaymentInfoConditionInput | null;
};

export type CreateSaaSPaymentInfoMutation = {
  createSaaSPaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSaaSPaymentInfoMutationVariables = {
  input: UpdateSaaSPaymentInfoInput;
  condition?: ModelSaaSPaymentInfoConditionInput | null;
};

export type UpdateSaaSPaymentInfoMutation = {
  updateSaaSPaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSaaSPaymentInfoMutationVariables = {
  input: DeleteSaaSPaymentInfoInput;
  condition?: ModelSaaSPaymentInfoConditionInput | null;
};

export type DeleteSaaSPaymentInfoMutation = {
  deleteSaaSPaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSaaSDeliveryTargetMutationVariables = {
  input: CreateSaaSDeliveryTargetInput;
  condition?: ModelSaaSDeliveryTargetConditionInput | null;
};

export type CreateSaaSDeliveryTargetMutation = {
  createSaaSDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSaaSDeliveryTargetMutationVariables = {
  input: UpdateSaaSDeliveryTargetInput;
  condition?: ModelSaaSDeliveryTargetConditionInput | null;
};

export type UpdateSaaSDeliveryTargetMutation = {
  updateSaaSDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSaaSDeliveryTargetMutationVariables = {
  input: DeleteSaaSDeliveryTargetInput;
  condition?: ModelSaaSDeliveryTargetConditionInput | null;
};

export type DeleteSaaSDeliveryTargetMutation = {
  deleteSaaSDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSaaSAnalyticsMutationVariables = {
  input: CreateSaaSAnalyticsInput;
  condition?: ModelSaaSAnalyticsConditionInput | null;
};

export type CreateSaaSAnalyticsMutation = {
  createSaaSAnalytics?: {
    __typename: 'SaaSAnalytics';
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSaaSAnalyticsMutationVariables = {
  input: UpdateSaaSAnalyticsInput;
  condition?: ModelSaaSAnalyticsConditionInput | null;
};

export type UpdateSaaSAnalyticsMutation = {
  updateSaaSAnalytics?: {
    __typename: 'SaaSAnalytics';
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSaaSAnalyticsMutationVariables = {
  input: DeleteSaaSAnalyticsInput;
  condition?: ModelSaaSAnalyticsConditionInput | null;
};

export type DeleteSaaSAnalyticsMutation = {
  deleteSaaSAnalytics?: {
    __typename: 'SaaSAnalytics';
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSaaSUserInfoMutationVariables = {
  input: CreateSaaSUserInfoInput;
  condition?: ModelSaaSUserInfoConditionInput | null;
};

export type CreateSaaSUserInfoMutation = {
  createSaaSUserInfo?: {
    __typename: 'SaaSUserInfo';
    id: string;
    email?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    agentCode?: string | null;
    reseller?: boolean | null;
    sendReportEmail?: {
      __typename: 'SendReportEmail';
      endOfDelivery?: boolean | null;
      shortBalance?: boolean | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSaaSUserInfoMutationVariables = {
  input: UpdateSaaSUserInfoInput;
  condition?: ModelSaaSUserInfoConditionInput | null;
};

export type UpdateSaaSUserInfoMutation = {
  updateSaaSUserInfo?: {
    __typename: 'SaaSUserInfo';
    id: string;
    email?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    agentCode?: string | null;
    reseller?: boolean | null;
    sendReportEmail?: {
      __typename: 'SendReportEmail';
      endOfDelivery?: boolean | null;
      shortBalance?: boolean | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSaaSUserInfoMutationVariables = {
  input: DeleteSaaSUserInfoInput;
  condition?: ModelSaaSUserInfoConditionInput | null;
};

export type DeleteSaaSUserInfoMutation = {
  deleteSaaSUserInfo?: {
    __typename: 'SaaSUserInfo';
    id: string;
    email?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    agentCode?: string | null;
    reseller?: boolean | null;
    sendReportEmail?: {
      __typename: 'SendReportEmail';
      endOfDelivery?: boolean | null;
      shortBalance?: boolean | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type CreateSaaSAccessManageMutationVariables = {
  input: CreateSaaSAccessManageInput;
  condition?: ModelSaaSAccessManageConditionInput | null;
};

export type CreateSaaSAccessManageMutation = {
  createSaaSAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type UpdateSaaSAccessManageMutationVariables = {
  input: UpdateSaaSAccessManageInput;
  condition?: ModelSaaSAccessManageConditionInput | null;
};

export type UpdateSaaSAccessManageMutation = {
  updateSaaSAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type DeleteSaaSAccessManageMutationVariables = {
  input: DeleteSaaSAccessManageInput;
  condition?: ModelSaaSAccessManageConditionInput | null;
};

export type DeleteSaaSAccessManageMutation = {
  deleteSaaSAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type AcceptAccessManageMutationVariables = {
  input?: AccessManageInput | null;
};

export type AcceptAccessManageMutation = {
  acceptAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type GetCouponQueryVariables = {
  id?: string | null;
};

export type GetCouponQuery = {
  getCoupon?: {
    __typename: 'SaaSCoupon';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    name?: string | null;
    text?: string | null;
    title?: string | null;
    rule?: string | null;
    discount?: {
      __typename: 'Discount';
      type?: DiscountType | null;
      param?: Array<number | null> | null;
      freeText?: string | null;
    } | null;
    imageUrls?: Array<string | null> | null;
    barcodeUrl?: string | null;
    usage?: CouponUsage | null;
    editState?: EditState | null;
    cvLink?: {
      __typename: 'CvLink';
      url?: string | null;
      text?: string | null;
    } | null;
    cvPhone?: string | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListCouponQueryVariables = {
  shopId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListCouponQuery = {
  listCoupon?: {
    __typename: 'ListCoupon';
    items?: Array<{
      __typename: 'SaaSCoupon';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      name?: string | null;
      text?: string | null;
      title?: string | null;
      rule?: string | null;
      discount?: {
        __typename: 'Discount';
        type?: DiscountType | null;
        param?: Array<number | null> | null;
        freeText?: string | null;
      } | null;
      imageUrls?: Array<string | null> | null;
      barcodeUrl?: string | null;
      usage?: CouponUsage | null;
      editState?: EditState | null;
      cvLink?: {
        __typename: 'CvLink';
        url?: string | null;
        text?: string | null;
      } | null;
      cvPhone?: string | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetNewsQueryVariables = {
  id?: string | null;
};

export type GetNewsQuery = {
  getNews?: {
    __typename: 'SaaSNews';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    name?: string | null;
    text?: string | null;
    title?: string | null;
    imageUrls?: Array<string | null> | null;
    editState?: EditState | null;
    cvLink?: {
      __typename: 'CvLink';
      url?: string | null;
      text?: string | null;
    } | null;
    cvPhone?: string | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListNewsQueryVariables = {
  shopId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListNewsQuery = {
  listNews?: {
    __typename: 'ListNews';
    items?: Array<{
      __typename: 'SaaSNews';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      name?: string | null;
      text?: string | null;
      title?: string | null;
      imageUrls?: Array<string | null> | null;
      editState?: EditState | null;
      cvLink?: {
        __typename: 'CvLink';
        url?: string | null;
        text?: string | null;
      } | null;
      cvPhone?: string | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDeliveryTargetQueryVariables = {
  id?: string | null;
};

export type GetDeliveryTargetQuery = {
  getDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListDeliveryTargetQueryVariables = {
  shopId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListDeliveryTargetQuery = {
  listDeliveryTarget?: {
    __typename: 'ListDeliveryTarget';
    items?: Array<{
      __typename: 'SaaSDeliveryTarget';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      title?: string | null;
      areaOption?: {
        __typename: 'AreaOption';
        areaType?: AreaType | null;
        meshs?: Array<string | null> | null;
      } | null;
      stayOption?: {
        __typename: 'StayOption';
        weekDays?: Array<number | null> | null;
        hours?: Array<number | null> | null;
        meshs?: Array<string | null> | null;
      } | null;
      stationOption?: Array<string | null> | null;
      cityOption?: Array<{
        __typename: 'CityOption';
        prefCode?: string | null;
        cityCode?: string | null;
        prefName?: string | null;
        cityName?: string | null;
      } | null> | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      users?: number | null;
      editState?: EditState | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetShopQueryVariables = {
  id?: string | null;
};

export type GetShopQuery = {
  getShop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type ListShopQueryVariables = {
  ownerId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListShopQuery = {
  listShop?: {
    __typename: 'ListShop';
    items?: Array<{
      __typename: 'SaaSShop';
      id: string;
      ownerId?: string | null;
      groupId?: string | null;
      name?: string | null;
      text?: string | null;
      address?: {
        __typename: 'FullAddress';
        zip?: string | null;
        pref?: string | null;
        city?: string | null;
        detail?: string | null;
        building?: string | null;
      } | null;
      phone?: string | null;
      biztimes?: string | null;
      holidays?: string | null;
      url?: string | null;
      legalUrl?: string | null;
      category?: ShopCategory | null;
      imageUrls?: Array<string | null> | null;
      icon?: string | null;
      location?: {
        __typename: 'Location';
        lat?: number | null;
        lon?: number | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      shopState?: ShopState | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetShopGroupQueryVariables = {
  id?: string | null;
};

export type GetShopGroupQuery = {
  getShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListShopGroupQueryVariables = {
  ownerId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListShopGroupQuery = {
  listShopGroup?: {
    __typename: 'ListShopGroup';
    items?: Array<{
      __typename: 'SaaSShopGroup';
      id: string;
      ownerId?: string | null;
      name?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetOwnerQueryVariables = {
  id?: string | null;
};

export type GetOwnerQuery = {
  getOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type GetPaymentInfoQueryVariables = {
  id?: string | null;
};

export type GetPaymentInfoQuery = {
  getPaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListOwnerQueryVariables = {
  id?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListOwnerQuery = {
  listOwner?: {
    __typename: 'ListOwner';
    items?: Array<{
      __typename: 'SaaSOwner';
      id: string;
      hojin?: string | null;
      hojinKana?: string | null;
      representative?: {
        __typename: 'FullName';
        first?: string | null;
        last?: string | null;
      } | null;
      representativeKana?: {
        __typename: 'FullName';
        first?: string | null;
        last?: string | null;
      } | null;
      address?: {
        __typename: 'FullAddress';
        zip?: string | null;
        pref?: string | null;
        city?: string | null;
        detail?: string | null;
        building?: string | null;
      } | null;
      url?: string | null;
      chargeDept?: string | null;
      charge?: {
        __typename: 'FullName';
        first?: string | null;
        last?: string | null;
      } | null;
      chargeKana?: {
        __typename: 'FullName';
        first?: string | null;
        last?: string | null;
      } | null;
      chargePhone?: string | null;
      status?: OwnerStatus | null;
      agentCode?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetDeliveryHistoryQueryVariables = {
  id?: string | null;
};

export type GetDeliveryHistoryQuery = {
  getDeliveryHistory?: {
    __typename: 'SaaSDeliveryHistory';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    couponId?: string | null;
    couponDef?: string | null;
    targetId?: string | null;
    targetDef?: string | null;
    title?: string | null;
    budget?: number | null;
    balance?: number | null;
    couponState?: CouponState | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    startAt?: string | null;
    endAt?: string | null;
    deliveryArea?: DeliveryArea | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListDeliveryHistoryQueryVariables = {
  shopId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListDeliveryHistoryQuery = {
  listDeliveryHistory?: {
    __typename: 'ListDeliveryHistory';
    items?: Array<{
      __typename: 'SaaSDeliveryHistory';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      couponId?: string | null;
      couponDef?: string | null;
      targetId?: string | null;
      targetDef?: string | null;
      title?: string | null;
      budget?: number | null;
      balance?: number | null;
      couponState?: CouponState | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      startAt?: string | null;
      endAt?: string | null;
      deliveryArea?: DeliveryArea | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetNewsHistoryQueryVariables = {
  id?: string | null;
};

export type GetNewsHistoryQuery = {
  getNewsHistory?: {
    __typename: 'SaaSNewsHistory';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    newsId?: string | null;
    newsDef?: string | null;
    targetId?: string | null;
    targetDef?: string | null;
    title?: string | null;
    budget?: number | null;
    balance?: number | null;
    newsState?: NewsState | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    startAt?: string | null;
    endAt?: string | null;
    deliveryArea?: DeliveryArea | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListNewsHistoryQueryVariables = {
  shopId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListNewsHistoryQuery = {
  listNewsHistory?: {
    __typename: 'ListNewsHistory';
    items?: Array<{
      __typename: 'SaaSNewsHistory';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      newsId?: string | null;
      newsDef?: string | null;
      targetId?: string | null;
      targetDef?: string | null;
      title?: string | null;
      budget?: number | null;
      balance?: number | null;
      newsState?: NewsState | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      startAt?: string | null;
      endAt?: string | null;
      deliveryArea?: DeliveryArea | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListAccessManageQueryVariables = {
  ownerId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListAccessManageQuery = {
  listAccessManage?: {
    __typename: 'ListAccessManageWithName';
    items?: Array<{
      __typename: 'SaaSAccessManageWithName';
      id: string;
      ownerId?: string | null;
      userId?: string | null;
      email?: string | null;
      addUserId?: string | null;
      role?: AccessManageRole | null;
      status?: AccessManageStatus | null;
      familyName?: string | null;
      givenName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type ListAccessManageByUserQueryVariables = {
  userId?: string | null;
  limit?: number | null;
  nextToken?: string | null;
  sortDirection?: string | null;
};

export type ListAccessManageByUserQuery = {
  listAccessManageByUser?: {
    __typename: 'ListAccessManage';
    items?: Array<{
      __typename: 'SaaSAccessManage';
      id: string;
      ownerId?: string | null;
      userId?: string | null;
      email?: string | null;
      addUserId?: string | null;
      role?: AccessManageRole | null;
      status?: AccessManageStatus | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
};

export type GetAccessManageQueryVariables = {
  id?: string | null;
};

export type GetAccessManageQuery = {
  getAccessManage?: {
    __typename: 'SaaSAccessManageWithName';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    familyName?: string | null;
    givenName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type GetSaaSOwnerQueryVariables = {
  id: string;
};

export type GetSaaSOwnerQuery = {
  getSaaSOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSOwnersQueryVariables = {
  filter?: ModelSaaSOwnerFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSOwnersQuery = {
  listSaaSOwners?: {
    __typename: 'ModelSaaSOwnerConnection';
    items: Array<{
      __typename: 'SaaSOwner';
      id: string;
      hojin?: string | null;
      hojinKana?: string | null;
      representative?: {
        __typename: 'FullName';
        first?: string | null;
        last?: string | null;
      } | null;
      representativeKana?: {
        __typename: 'FullName';
        first?: string | null;
        last?: string | null;
      } | null;
      address?: {
        __typename: 'FullAddress';
        zip?: string | null;
        pref?: string | null;
        city?: string | null;
        detail?: string | null;
        building?: string | null;
      } | null;
      url?: string | null;
      chargeDept?: string | null;
      charge?: {
        __typename: 'FullName';
        first?: string | null;
        last?: string | null;
      } | null;
      chargeKana?: {
        __typename: 'FullName';
        first?: string | null;
        last?: string | null;
      } | null;
      chargePhone?: string | null;
      status?: OwnerStatus | null;
      agentCode?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSAreaQueryVariables = {
  id: string;
};

export type GetSaaSAreaQuery = {
  getSaaSArea?: {
    __typename: 'SaaSArea';
    id: string;
    meshId?: string | null;
    userId?: string | null;
    areaType?: AreaType | null;
    stamp?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    unixStamp?: number | null;
  } | null;
};

export type ListSaaSAreasQueryVariables = {
  filter?: ModelSaaSAreaFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSAreasQuery = {
  listSaaSAreas?: {
    __typename: 'ModelSaaSAreaConnection';
    items: Array<{
      __typename: 'SaaSArea';
      id: string;
      meshId?: string | null;
      userId?: string | null;
      areaType?: AreaType | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSAreaByMeshIdQueryVariables = {
  meshId: string;
  userId?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSAreaFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSAreaByMeshIdQuery = {
  saaSAreaByMeshId?: {
    __typename: 'ModelSaaSAreaConnection';
    items: Array<{
      __typename: 'SaaSArea';
      id: string;
      meshId?: string | null;
      userId?: string | null;
      areaType?: AreaType | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSStayQueryVariables = {
  id: string;
};

export type GetSaaSStayQuery = {
  getSaaSStay?: {
    __typename: 'SaaSStay';
    id: string;
    meshId?: string | null;
    userId?: string | null;
    stayOffsets?: Array<number | null> | null;
    stamp?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    unixStamp?: number | null;
  } | null;
};

export type ListSaaSStaysQueryVariables = {
  filter?: ModelSaaSStayFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSStaysQuery = {
  listSaaSStays?: {
    __typename: 'ModelSaaSStayConnection';
    items: Array<{
      __typename: 'SaaSStay';
      id: string;
      meshId?: string | null;
      userId?: string | null;
      stayOffsets?: Array<number | null> | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSStayByMeshIdQueryVariables = {
  meshId: string;
  userId?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSStayFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSStayByMeshIdQuery = {
  saaSStayByMeshId?: {
    __typename: 'ModelSaaSStayConnection';
    items: Array<{
      __typename: 'SaaSStay';
      id: string;
      meshId?: string | null;
      userId?: string | null;
      stayOffsets?: Array<number | null> | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSStationPoiQueryVariables = {
  id: string;
};

export type GetSaaSStationPoiQuery = {
  getSaaSStationPoi?: {
    __typename: 'SaaSStationPoi';
    id: string;
    routeId?: string | null;
    poiType?: PoiType | null;
    parent?: string | null;
    seq?: number | null;
    name?: string | null;
    parentName?: string | null;
    innerId?: string | null;
    yomi?: string | null;
    mimi?: string | null;
    unixStamp?: number | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSStationPoisQueryVariables = {
  filter?: ModelSaaSStationPoiFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSStationPoisQuery = {
  listSaaSStationPois?: {
    __typename: 'ModelSaaSStationPoiConnection';
    items: Array<{
      __typename: 'SaaSStationPoi';
      id: string;
      routeId?: string | null;
      poiType?: PoiType | null;
      parent?: string | null;
      seq?: number | null;
      name?: string | null;
      parentName?: string | null;
      innerId?: string | null;
      yomi?: string | null;
      mimi?: string | null;
      unixStamp?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSStationPoiByPoiTypeQueryVariables = {
  poiType: PoiType;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSStationPoiFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSStationPoiByPoiTypeQuery = {
  saaSStationPoiByPoiType?: {
    __typename: 'ModelSaaSStationPoiConnection';
    items: Array<{
      __typename: 'SaaSStationPoi';
      id: string;
      routeId?: string | null;
      poiType?: PoiType | null;
      parent?: string | null;
      seq?: number | null;
      name?: string | null;
      parentName?: string | null;
      innerId?: string | null;
      yomi?: string | null;
      mimi?: string | null;
      unixStamp?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSStationPoiByParentQueryVariables = {
  parent: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSStationPoiFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSStationPoiByParentQuery = {
  saaSStationPoiByParent?: {
    __typename: 'ModelSaaSStationPoiConnection';
    items: Array<{
      __typename: 'SaaSStationPoi';
      id: string;
      routeId?: string | null;
      poiType?: PoiType | null;
      parent?: string | null;
      seq?: number | null;
      name?: string | null;
      parentName?: string | null;
      innerId?: string | null;
      yomi?: string | null;
      mimi?: string | null;
      unixStamp?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSStationPoiByNameQueryVariables = {
  name: string;
  parentName?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSStationPoiFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSStationPoiByNameQuery = {
  saaSStationPoiByName?: {
    __typename: 'ModelSaaSStationPoiConnection';
    items: Array<{
      __typename: 'SaaSStationPoi';
      id: string;
      routeId?: string | null;
      poiType?: PoiType | null;
      parent?: string | null;
      seq?: number | null;
      name?: string | null;
      parentName?: string | null;
      innerId?: string | null;
      yomi?: string | null;
      mimi?: string | null;
      unixStamp?: number | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSStationQueryVariables = {
  id: string;
};

export type GetSaaSStationQuery = {
  getSaaSStation?: {
    __typename: 'SaaSStation';
    id: string;
    userId?: string | null;
    routeId?: string | null;
    stationId?: string | null;
    masterId?: string | null;
    innerPoiId?: string | null;
    entryDays?: number | null;
    stamp?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    unixStamp?: number | null;
  } | null;
};

export type ListSaaSStationsQueryVariables = {
  filter?: ModelSaaSStationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSStationsQuery = {
  listSaaSStations?: {
    __typename: 'ModelSaaSStationConnection';
    items: Array<{
      __typename: 'SaaSStation';
      id: string;
      userId?: string | null;
      routeId?: string | null;
      stationId?: string | null;
      masterId?: string | null;
      innerPoiId?: string | null;
      entryDays?: number | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSStationByRouteIdQueryVariables = {
  routeId: string;
  stationId?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSStationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSStationByRouteIdQuery = {
  saaSStationByRouteId?: {
    __typename: 'ModelSaaSStationConnection';
    items: Array<{
      __typename: 'SaaSStation';
      id: string;
      userId?: string | null;
      routeId?: string | null;
      stationId?: string | null;
      masterId?: string | null;
      innerPoiId?: string | null;
      entryDays?: number | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSVisitorQueryVariables = {
  id: string;
};

export type GetSaaSVisitorQuery = {
  getSaaSVisitor?: {
    __typename: 'SaaSVisitor';
    id: string;
    userId?: string | null;
    shopId?: string | null;
    deliveryType?: DeliveryType | null;
    deliveryId?: string | null;
    stamp?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSVisitorsQueryVariables = {
  filter?: ModelSaaSVisitorFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSVisitorsQuery = {
  listSaaSVisitors?: {
    __typename: 'ModelSaaSVisitorConnection';
    items: Array<{
      __typename: 'SaaSVisitor';
      id: string;
      userId?: string | null;
      shopId?: string | null;
      deliveryType?: DeliveryType | null;
      deliveryId?: string | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSVisitorByShopIdQueryVariables = {
  shopId: string;
  stamp?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSVisitorFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSVisitorByShopIdQuery = {
  saaSVisitorByShopId?: {
    __typename: 'ModelSaaSVisitorConnection';
    items: Array<{
      __typename: 'SaaSVisitor';
      id: string;
      userId?: string | null;
      shopId?: string | null;
      deliveryType?: DeliveryType | null;
      deliveryId?: string | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSVisitorByDeliveryIdQueryVariables = {
  deliveryId: string;
  stamp?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSVisitorFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSVisitorByDeliveryIdQuery = {
  saaSVisitorByDeliveryId?: {
    __typename: 'ModelSaaSVisitorConnection';
    items: Array<{
      __typename: 'SaaSVisitor';
      id: string;
      userId?: string | null;
      shopId?: string | null;
      deliveryType?: DeliveryType | null;
      deliveryId?: string | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSCityPoiQueryVariables = {
  id: string;
};

export type GetSaaSCityPoiQuery = {
  getSaaSCityPoi?: {
    __typename: 'SaaSCityPoi';
    id: string;
    prefCode?: string | null;
    cityName?: string | null;
    prefName?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSCityPoisQueryVariables = {
  filter?: ModelSaaSCityPoiFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSCityPoisQuery = {
  listSaaSCityPois?: {
    __typename: 'ModelSaaSCityPoiConnection';
    items: Array<{
      __typename: 'SaaSCityPoi';
      id: string;
      prefCode?: string | null;
      cityName?: string | null;
      prefName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCityPoiByPrefCodeQueryVariables = {
  prefCode: string;
  id?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCityPoiFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCityPoiByPrefCodeQuery = {
  saaSCityPoiByPrefCode?: {
    __typename: 'ModelSaaSCityPoiConnection';
    items: Array<{
      __typename: 'SaaSCityPoi';
      id: string;
      prefCode?: string | null;
      cityName?: string | null;
      prefName?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSCityUserCountQueryVariables = {
  id: string;
};

export type GetSaaSCityUserCountQuery = {
  getSaaSCityUserCount?: {
    __typename: 'SaaSCityUserCount';
    id: string;
    cityCode?: string | null;
    prefCode?: string | null;
    userCount?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    stamp?: string | null;
    unixStamp?: number | null;
  } | null;
};

export type ListSaaSCityUserCountsQueryVariables = {
  filter?: ModelSaaSCityUserCountFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSCityUserCountsQuery = {
  listSaaSCityUserCounts?: {
    __typename: 'ModelSaaSCityUserCountConnection';
    items: Array<{
      __typename: 'SaaSCityUserCount';
      id: string;
      cityCode?: string | null;
      prefCode?: string | null;
      userCount?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stamp?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCityUserCountByCityCodeQueryVariables = {
  cityCode: string;
  stamp?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCityUserCountFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCityUserCountByCityCodeQuery = {
  saaSCityUserCountByCityCode?: {
    __typename: 'ModelSaaSCityUserCountConnection';
    items: Array<{
      __typename: 'SaaSCityUserCount';
      id: string;
      cityCode?: string | null;
      prefCode?: string | null;
      userCount?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stamp?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCityUserCountByPrefCodeQueryVariables = {
  prefCode: string;
  cityCode?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCityUserCountFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCityUserCountByPrefCodeQuery = {
  saaSCityUserCountByPrefCode?: {
    __typename: 'ModelSaaSCityUserCountConnection';
    items: Array<{
      __typename: 'SaaSCityUserCount';
      id: string;
      cityCode?: string | null;
      prefCode?: string | null;
      userCount?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      stamp?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSUserQueryVariables = {
  id: string;
};

export type GetSaaSUserQuery = {
  getSaaSUser?: {
    __typename: 'SaaSUser';
    id: string;
    dotmoneyId?: string | null;
    job?: number | null;
    gender?: string | null;
    age?: number | null;
    married?: boolean | null;
    residence?: string | null;
    movingMethod?: string | null;
    childrenTogether?: number | null;
    childYoungest?: number | null;
    income?: number | null;
    householdIncome?: number | null;
    familyTogether?: number | null;
    address?: number | null;
    cityCode?: string | null;
    prefCode?: string | null;
    interest?: Array<number | null> | null;
    childAge?: Array<number | null> | null;
    stamp?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    unixStamp?: number | null;
  } | null;
};

export type ListSaaSUsersQueryVariables = {
  filter?: ModelSaaSUserFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSUsersQuery = {
  listSaaSUsers?: {
    __typename: 'ModelSaaSUserConnection';
    items: Array<{
      __typename: 'SaaSUser';
      id: string;
      dotmoneyId?: string | null;
      job?: number | null;
      gender?: string | null;
      age?: number | null;
      married?: boolean | null;
      residence?: string | null;
      movingMethod?: string | null;
      childrenTogether?: number | null;
      childYoungest?: number | null;
      income?: number | null;
      householdIncome?: number | null;
      familyTogether?: number | null;
      address?: number | null;
      cityCode?: string | null;
      prefCode?: string | null;
      interest?: Array<number | null> | null;
      childAge?: Array<number | null> | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SearchSaaSUsersQueryVariables = {
  filter?: SearchableSaaSUserFilterInput | null;
  sort?: Array<SearchableSaaSUserSortInput | null> | null;
  limit?: number | null;
  nextToken?: string | null;
  from?: number | null;
  aggregates?: Array<SearchableSaaSUserAggregationInput | null> | null;
};

export type SearchSaaSUsersQuery = {
  searchSaaSUsers?: {
    __typename: 'SearchableSaaSUserConnection';
    items: Array<{
      __typename: 'SaaSUser';
      id: string;
      dotmoneyId?: string | null;
      job?: number | null;
      gender?: string | null;
      age?: number | null;
      married?: boolean | null;
      residence?: string | null;
      movingMethod?: string | null;
      childrenTogether?: number | null;
      childYoungest?: number | null;
      income?: number | null;
      householdIncome?: number | null;
      familyTogether?: number | null;
      address?: number | null;
      cityCode?: string | null;
      prefCode?: string | null;
      interest?: Array<number | null> | null;
      childAge?: Array<number | null> | null;
      stamp?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      unixStamp?: number | null;
    } | null>;
    nextToken?: string | null;
    total?: number | null;
    aggregateItems: Array<{
      __typename: 'SearchableAggregateResult';
      name: string;
      result:
        | (
            | {
                __typename: 'SearchableAggregateScalarResult';
                value: number;
              }
            | {
                __typename: 'SearchableAggregateBucketResult';
                buckets?: Array<{
                  __typename: string;
                  key: string;
                  doc_count: number;
                } | null> | null;
              }
          )
        | null;
    } | null>;
  } | null;
};

export type GetSaaSSocialQueryVariables = {
  id: string;
};

export type GetSaaSSocialQuery = {
  getSaaSSocial?: {
    __typename: 'SaaSSocial';
    id: string;
    shopId?: string | null;
    userId?: string | null;
    userHashId?: string | null;
    followed?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSSocialsQueryVariables = {
  filter?: ModelSaaSSocialFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSSocialsQuery = {
  listSaaSSocials?: {
    __typename: 'ModelSaaSSocialConnection';
    items: Array<{
      __typename: 'SaaSSocial';
      id: string;
      shopId?: string | null;
      userId?: string | null;
      userHashId?: string | null;
      followed?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSSocialByShopIdQueryVariables = {
  shopId: string;
  userHashId?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSSocialFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSSocialByShopIdQuery = {
  saaSSocialByShopId?: {
    __typename: 'ModelSaaSSocialConnection';
    items: Array<{
      __typename: 'SaaSSocial';
      id: string;
      shopId?: string | null;
      userId?: string | null;
      userHashId?: string | null;
      followed?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSSocialByUserIdQueryVariables = {
  userId: string;
  shopId?: ModelIDKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSSocialFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSSocialByUserIdQuery = {
  saaSSocialByUserId?: {
    __typename: 'ModelSaaSSocialConnection';
    items: Array<{
      __typename: 'SaaSSocial';
      id: string;
      shopId?: string | null;
      userId?: string | null;
      userHashId?: string | null;
      followed?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSSocialLogQueryVariables = {
  id: string;
};

export type GetSaaSSocialLogQuery = {
  getSaaSSocialLog?: {
    __typename: 'SaaSSocialLog';
    id: string;
    shopId?: string | null;
    userId?: string | null;
    stamp?: string | null;
    action?: SocialAction | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSSocialLogsQueryVariables = {
  filter?: ModelSaaSSocialLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSSocialLogsQuery = {
  listSaaSSocialLogs?: {
    __typename: 'ModelSaaSSocialLogConnection';
    items: Array<{
      __typename: 'SaaSSocialLog';
      id: string;
      shopId?: string | null;
      userId?: string | null;
      stamp?: string | null;
      action?: SocialAction | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSSocialLogByShopIdQueryVariables = {
  shopId: string;
  stamp?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSSocialLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSSocialLogByShopIdQuery = {
  saaSSocialLogByShopId?: {
    __typename: 'ModelSaaSSocialLogConnection';
    items: Array<{
      __typename: 'SaaSSocialLog';
      id: string;
      shopId?: string | null;
      userId?: string | null;
      stamp?: string | null;
      action?: SocialAction | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSShopQueryVariables = {
  id: string;
};

export type GetSaaSShopQuery = {
  getSaaSShop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type ListSaaSShopsQueryVariables = {
  filter?: ModelSaaSShopFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSShopsQuery = {
  listSaaSShops?: {
    __typename: 'ModelSaaSShopConnection';
    items: Array<{
      __typename: 'SaaSShop';
      id: string;
      ownerId?: string | null;
      groupId?: string | null;
      name?: string | null;
      text?: string | null;
      address?: {
        __typename: 'FullAddress';
        zip?: string | null;
        pref?: string | null;
        city?: string | null;
        detail?: string | null;
        building?: string | null;
      } | null;
      phone?: string | null;
      biztimes?: string | null;
      holidays?: string | null;
      url?: string | null;
      legalUrl?: string | null;
      category?: ShopCategory | null;
      imageUrls?: Array<string | null> | null;
      icon?: string | null;
      location?: {
        __typename: 'Location';
        lat?: number | null;
        lon?: number | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      shopState?: ShopState | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSShopByOwnerIdQueryVariables = {
  ownerId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSShopFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSShopByOwnerIdQuery = {
  saaSShopByOwnerId?: {
    __typename: 'ModelSaaSShopConnection';
    items: Array<{
      __typename: 'SaaSShop';
      id: string;
      ownerId?: string | null;
      groupId?: string | null;
      name?: string | null;
      text?: string | null;
      address?: {
        __typename: 'FullAddress';
        zip?: string | null;
        pref?: string | null;
        city?: string | null;
        detail?: string | null;
        building?: string | null;
      } | null;
      phone?: string | null;
      biztimes?: string | null;
      holidays?: string | null;
      url?: string | null;
      legalUrl?: string | null;
      category?: ShopCategory | null;
      imageUrls?: Array<string | null> | null;
      icon?: string | null;
      location?: {
        __typename: 'Location';
        lat?: number | null;
        lon?: number | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      shopState?: ShopState | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSShopByGroupIdQueryVariables = {
  groupId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSShopFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSShopByGroupIdQuery = {
  saaSShopByGroupId?: {
    __typename: 'ModelSaaSShopConnection';
    items: Array<{
      __typename: 'SaaSShop';
      id: string;
      ownerId?: string | null;
      groupId?: string | null;
      name?: string | null;
      text?: string | null;
      address?: {
        __typename: 'FullAddress';
        zip?: string | null;
        pref?: string | null;
        city?: string | null;
        detail?: string | null;
        building?: string | null;
      } | null;
      phone?: string | null;
      biztimes?: string | null;
      holidays?: string | null;
      url?: string | null;
      legalUrl?: string | null;
      category?: ShopCategory | null;
      imageUrls?: Array<string | null> | null;
      icon?: string | null;
      location?: {
        __typename: 'Location';
        lat?: number | null;
        lon?: number | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      shopState?: ShopState | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSShopGroupQueryVariables = {
  id: string;
};

export type GetSaaSShopGroupQuery = {
  getSaaSShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSShopGroupsQueryVariables = {
  filter?: ModelSaaSShopGroupFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSShopGroupsQuery = {
  listSaaSShopGroups?: {
    __typename: 'ModelSaaSShopGroupConnection';
    items: Array<{
      __typename: 'SaaSShopGroup';
      id: string;
      ownerId?: string | null;
      name?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSShopGroupByOwnerIdQueryVariables = {
  ownerId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSShopGroupFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSShopGroupByOwnerIdQuery = {
  saaSShopGroupByOwnerId?: {
    __typename: 'ModelSaaSShopGroupConnection';
    items: Array<{
      __typename: 'SaaSShopGroup';
      id: string;
      ownerId?: string | null;
      name?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSShopExcludeVisitorQueryVariables = {
  id: string;
};

export type GetSaaSShopExcludeVisitorQuery = {
  getSaaSShopExcludeVisitor?: {
    __typename: 'SaaSShopExcludeVisitor';
    id: string;
    shopId?: string | null;
    ownerId?: string | null;
    invitedCode?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSShopExcludeVisitorsQueryVariables = {
  filter?: ModelSaaSShopExcludeVisitorFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSShopExcludeVisitorsQuery = {
  listSaaSShopExcludeVisitors?: {
    __typename: 'ModelSaaSShopExcludeVisitorConnection';
    items: Array<{
      __typename: 'SaaSShopExcludeVisitor';
      id: string;
      shopId?: string | null;
      ownerId?: string | null;
      invitedCode?: string | null;
      userId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSShopExcludeVisitorByShopIdQueryVariables = {
  shopId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSShopExcludeVisitorFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSShopExcludeVisitorByShopIdQuery = {
  saaSShopExcludeVisitorByShopId?: {
    __typename: 'ModelSaaSShopExcludeVisitorConnection';
    items: Array<{
      __typename: 'SaaSShopExcludeVisitor';
      id: string;
      shopId?: string | null;
      ownerId?: string | null;
      invitedCode?: string | null;
      userId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSPaymentInfoQueryVariables = {
  id: string;
};

export type GetSaaSPaymentInfoQuery = {
  getSaaSPaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSPaymentInfosQueryVariables = {
  filter?: ModelSaaSPaymentInfoFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSPaymentInfosQuery = {
  listSaaSPaymentInfos?: {
    __typename: 'ModelSaaSPaymentInfoConnection';
    items: Array<{
      __typename: 'SaaSPaymentInfo';
      id: string;
      customerId?: string | null;
      paymentMethodId?: string | null;
      paymentType?: PaymentType | null;
      subscriptionId?: string | null;
      subscriptionItemIds?: Array<string | null> | null;
      priceIds?: Array<string | null> | null;
      billing?: boolean | null;
      invitedCode?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSPaymentInfoByCustomerIdQueryVariables = {
  customerId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSPaymentInfoFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSPaymentInfoByCustomerIdQuery = {
  saaSPaymentInfoByCustomerId?: {
    __typename: 'ModelSaaSPaymentInfoConnection';
    items: Array<{
      __typename: 'SaaSPaymentInfo';
      id: string;
      customerId?: string | null;
      paymentMethodId?: string | null;
      paymentType?: PaymentType | null;
      subscriptionId?: string | null;
      subscriptionItemIds?: Array<string | null> | null;
      priceIds?: Array<string | null> | null;
      billing?: boolean | null;
      invitedCode?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSCouponQueryVariables = {
  id: string;
};

export type GetSaaSCouponQuery = {
  getSaaSCoupon?: {
    __typename: 'SaaSCoupon';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    name?: string | null;
    text?: string | null;
    title?: string | null;
    rule?: string | null;
    discount?: {
      __typename: 'Discount';
      type?: DiscountType | null;
      param?: Array<number | null> | null;
      freeText?: string | null;
    } | null;
    imageUrls?: Array<string | null> | null;
    barcodeUrl?: string | null;
    usage?: CouponUsage | null;
    editState?: EditState | null;
    cvLink?: {
      __typename: 'CvLink';
      url?: string | null;
      text?: string | null;
    } | null;
    cvPhone?: string | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSCouponsQueryVariables = {
  filter?: ModelSaaSCouponFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSCouponsQuery = {
  listSaaSCoupons?: {
    __typename: 'ModelSaaSCouponConnection';
    items: Array<{
      __typename: 'SaaSCoupon';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      name?: string | null;
      text?: string | null;
      title?: string | null;
      rule?: string | null;
      discount?: {
        __typename: 'Discount';
        type?: DiscountType | null;
        param?: Array<number | null> | null;
        freeText?: string | null;
      } | null;
      imageUrls?: Array<string | null> | null;
      barcodeUrl?: string | null;
      usage?: CouponUsage | null;
      editState?: EditState | null;
      cvLink?: {
        __typename: 'CvLink';
        url?: string | null;
        text?: string | null;
      } | null;
      cvPhone?: string | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponByOwnerIdQueryVariables = {
  ownerId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponByOwnerIdQuery = {
  saaSCouponByOwnerId?: {
    __typename: 'ModelSaaSCouponConnection';
    items: Array<{
      __typename: 'SaaSCoupon';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      name?: string | null;
      text?: string | null;
      title?: string | null;
      rule?: string | null;
      discount?: {
        __typename: 'Discount';
        type?: DiscountType | null;
        param?: Array<number | null> | null;
        freeText?: string | null;
      } | null;
      imageUrls?: Array<string | null> | null;
      barcodeUrl?: string | null;
      usage?: CouponUsage | null;
      editState?: EditState | null;
      cvLink?: {
        __typename: 'CvLink';
        url?: string | null;
        text?: string | null;
      } | null;
      cvPhone?: string | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponByShopIdQueryVariables = {
  shopId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponByShopIdQuery = {
  saaSCouponByShopId?: {
    __typename: 'ModelSaaSCouponConnection';
    items: Array<{
      __typename: 'SaaSCoupon';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      name?: string | null;
      text?: string | null;
      title?: string | null;
      rule?: string | null;
      discount?: {
        __typename: 'Discount';
        type?: DiscountType | null;
        param?: Array<number | null> | null;
        freeText?: string | null;
      } | null;
      imageUrls?: Array<string | null> | null;
      barcodeUrl?: string | null;
      usage?: CouponUsage | null;
      editState?: EditState | null;
      cvLink?: {
        __typename: 'CvLink';
        url?: string | null;
        text?: string | null;
      } | null;
      cvPhone?: string | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNewsQueryVariables = {
  id: string;
};

export type GetSaaSNewsQuery = {
  getSaaSNews?: {
    __typename: 'SaaSNews';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    name?: string | null;
    text?: string | null;
    title?: string | null;
    imageUrls?: Array<string | null> | null;
    editState?: EditState | null;
    cvLink?: {
      __typename: 'CvLink';
      url?: string | null;
      text?: string | null;
    } | null;
    cvPhone?: string | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNewsQueryVariables = {
  filter?: ModelSaaSNewsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNewsQuery = {
  listSaaSNews?: {
    __typename: 'ModelSaaSNewsConnection';
    items: Array<{
      __typename: 'SaaSNews';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      name?: string | null;
      text?: string | null;
      title?: string | null;
      imageUrls?: Array<string | null> | null;
      editState?: EditState | null;
      cvLink?: {
        __typename: 'CvLink';
        url?: string | null;
        text?: string | null;
      } | null;
      cvPhone?: string | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsByOwnerIdQueryVariables = {
  ownerId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsByOwnerIdQuery = {
  saaSNewsByOwnerId?: {
    __typename: 'ModelSaaSNewsConnection';
    items: Array<{
      __typename: 'SaaSNews';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      name?: string | null;
      text?: string | null;
      title?: string | null;
      imageUrls?: Array<string | null> | null;
      editState?: EditState | null;
      cvLink?: {
        __typename: 'CvLink';
        url?: string | null;
        text?: string | null;
      } | null;
      cvPhone?: string | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsByShopIdQueryVariables = {
  shopId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsByShopIdQuery = {
  saaSNewsByShopId?: {
    __typename: 'ModelSaaSNewsConnection';
    items: Array<{
      __typename: 'SaaSNews';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      name?: string | null;
      text?: string | null;
      title?: string | null;
      imageUrls?: Array<string | null> | null;
      editState?: EditState | null;
      cvLink?: {
        __typename: 'CvLink';
        url?: string | null;
        text?: string | null;
      } | null;
      cvPhone?: string | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSDeliveryTargetQueryVariables = {
  id: string;
};

export type GetSaaSDeliveryTargetQuery = {
  getSaaSDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSDeliveryTargetsQueryVariables = {
  filter?: ModelSaaSDeliveryTargetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSDeliveryTargetsQuery = {
  listSaaSDeliveryTargets?: {
    __typename: 'ModelSaaSDeliveryTargetConnection';
    items: Array<{
      __typename: 'SaaSDeliveryTarget';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      title?: string | null;
      areaOption?: {
        __typename: 'AreaOption';
        areaType?: AreaType | null;
        meshs?: Array<string | null> | null;
      } | null;
      stayOption?: {
        __typename: 'StayOption';
        weekDays?: Array<number | null> | null;
        hours?: Array<number | null> | null;
        meshs?: Array<string | null> | null;
      } | null;
      stationOption?: Array<string | null> | null;
      cityOption?: Array<{
        __typename: 'CityOption';
        prefCode?: string | null;
        cityCode?: string | null;
        prefName?: string | null;
        cityName?: string | null;
      } | null> | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      users?: number | null;
      editState?: EditState | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSDeliveryTargetByShopIdQueryVariables = {
  shopId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSDeliveryTargetFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSDeliveryTargetByShopIdQuery = {
  saaSDeliveryTargetByShopId?: {
    __typename: 'ModelSaaSDeliveryTargetConnection';
    items: Array<{
      __typename: 'SaaSDeliveryTarget';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      title?: string | null;
      areaOption?: {
        __typename: 'AreaOption';
        areaType?: AreaType | null;
        meshs?: Array<string | null> | null;
      } | null;
      stayOption?: {
        __typename: 'StayOption';
        weekDays?: Array<number | null> | null;
        hours?: Array<number | null> | null;
        meshs?: Array<string | null> | null;
      } | null;
      stationOption?: Array<string | null> | null;
      cityOption?: Array<{
        __typename: 'CityOption';
        prefCode?: string | null;
        cityCode?: string | null;
        prefName?: string | null;
        cityName?: string | null;
      } | null> | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      users?: number | null;
      editState?: EditState | null;
      archive?: boolean | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNewsHistoryQueryVariables = {
  id: string;
};

export type GetSaaSNewsHistoryQuery = {
  getSaaSNewsHistory?: {
    __typename: 'SaaSNewsHistory';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    newsId?: string | null;
    newsDef?: string | null;
    targetId?: string | null;
    targetDef?: string | null;
    title?: string | null;
    budget?: number | null;
    balance?: number | null;
    newsState?: NewsState | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    startAt?: string | null;
    endAt?: string | null;
    deliveryArea?: DeliveryArea | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNewsHistoriesQueryVariables = {
  filter?: ModelSaaSNewsHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNewsHistoriesQuery = {
  listSaaSNewsHistories?: {
    __typename: 'ModelSaaSNewsHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsHistory';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      newsId?: string | null;
      newsDef?: string | null;
      targetId?: string | null;
      targetDef?: string | null;
      title?: string | null;
      budget?: number | null;
      balance?: number | null;
      newsState?: NewsState | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      startAt?: string | null;
      endAt?: string | null;
      deliveryArea?: DeliveryArea | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsHistoryByShopIdQueryVariables = {
  shopId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsHistoryByShopIdQuery = {
  saaSNewsHistoryByShopId?: {
    __typename: 'ModelSaaSNewsHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsHistory';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      newsId?: string | null;
      newsDef?: string | null;
      targetId?: string | null;
      targetDef?: string | null;
      title?: string | null;
      budget?: number | null;
      balance?: number | null;
      newsState?: NewsState | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      startAt?: string | null;
      endAt?: string | null;
      deliveryArea?: DeliveryArea | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsHistoryByNewsIdQueryVariables = {
  newsId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsHistoryByNewsIdQuery = {
  saaSNewsHistoryByNewsId?: {
    __typename: 'ModelSaaSNewsHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsHistory';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      newsId?: string | null;
      newsDef?: string | null;
      targetId?: string | null;
      targetDef?: string | null;
      title?: string | null;
      budget?: number | null;
      balance?: number | null;
      newsState?: NewsState | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      startAt?: string | null;
      endAt?: string | null;
      deliveryArea?: DeliveryArea | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNewsLogQueryVariables = {
  id: string;
};

export type GetSaaSNewsLogQuery = {
  getSaaSNewsLog?: {
    __typename: 'SaaSNewsLog';
    id: string;
    shopId?: string | null;
    newsHistoryId?: string | null;
    stamp?: string | null;
    deliveryAt?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNewsLogsQueryVariables = {
  filter?: ModelSaaSNewsLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNewsLogsQuery = {
  listSaaSNewsLogs?: {
    __typename: 'ModelSaaSNewsLogConnection';
    items: Array<{
      __typename: 'SaaSNewsLog';
      id: string;
      shopId?: string | null;
      newsHistoryId?: string | null;
      stamp?: string | null;
      deliveryAt?: string | null;
      userId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsLogByShopIdQueryVariables = {
  shopId: string;
  stamp?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsLogByShopIdQuery = {
  saaSNewsLogByShopId?: {
    __typename: 'ModelSaaSNewsLogConnection';
    items: Array<{
      __typename: 'SaaSNewsLog';
      id: string;
      shopId?: string | null;
      newsHistoryId?: string | null;
      stamp?: string | null;
      deliveryAt?: string | null;
      userId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsLogByNewsHistoryIdQueryVariables = {
  newsHistoryId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsLogByNewsHistoryIdQuery = {
  saaSNewsLogByNewsHistoryId?: {
    __typename: 'ModelSaaSNewsLogConnection';
    items: Array<{
      __typename: 'SaaSNewsLog';
      id: string;
      shopId?: string | null;
      newsHistoryId?: string | null;
      stamp?: string | null;
      deliveryAt?: string | null;
      userId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNewsAreaHistoryQueryVariables = {
  id: string;
};

export type GetSaaSNewsAreaHistoryQuery = {
  getSaaSNewsAreaHistory?: {
    __typename: 'SaaSNewsAreaHistory';
    id: string;
    deliveryId?: string | null;
    meshId?: string | null;
    startAt?: string | null;
    endAt?: string | null;
    areaOption?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNewsAreaHistoriesQueryVariables = {
  filter?: ModelSaaSNewsAreaHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNewsAreaHistoriesQuery = {
  listSaaSNewsAreaHistories?: {
    __typename: 'ModelSaaSNewsAreaHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsAreaHistory';
      id: string;
      deliveryId?: string | null;
      meshId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      areaOption?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsAreaHistoryByMeshIdQueryVariables = {
  meshId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsAreaHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsAreaHistoryByMeshIdQuery = {
  saaSNewsAreaHistoryByMeshId?: {
    __typename: 'ModelSaaSNewsAreaHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsAreaHistory';
      id: string;
      deliveryId?: string | null;
      meshId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      areaOption?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNewsStayHistoryQueryVariables = {
  id: string;
};

export type GetSaaSNewsStayHistoryQuery = {
  getSaaSNewsStayHistory?: {
    __typename: 'SaaSNewsStayHistory';
    id: string;
    deliveryId?: string | null;
    meshId?: string | null;
    startAt?: string | null;
    endAt?: string | null;
    stayOption?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNewsStayHistoriesQueryVariables = {
  filter?: ModelSaaSNewsStayHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNewsStayHistoriesQuery = {
  listSaaSNewsStayHistories?: {
    __typename: 'ModelSaaSNewsStayHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsStayHistory';
      id: string;
      deliveryId?: string | null;
      meshId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      stayOption?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsStayHistoryByMeshIdQueryVariables = {
  meshId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsStayHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsStayHistoryByMeshIdQuery = {
  saaSNewsStayHistoryByMeshId?: {
    __typename: 'ModelSaaSNewsStayHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsStayHistory';
      id: string;
      deliveryId?: string | null;
      meshId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      stayOption?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNewsStationHistoryQueryVariables = {
  id: string;
};

export type GetSaaSNewsStationHistoryQuery = {
  getSaaSNewsStationHistory?: {
    __typename: 'SaaSNewsStationHistory';
    id: string;
    deliveryId?: string | null;
    stationId?: string | null;
    startAt?: string | null;
    endAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNewsStationHistoriesQueryVariables = {
  filter?: ModelSaaSNewsStationHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNewsStationHistoriesQuery = {
  listSaaSNewsStationHistories?: {
    __typename: 'ModelSaaSNewsStationHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsStationHistory';
      id: string;
      deliveryId?: string | null;
      stationId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsStationHistoryByStationIdQueryVariables = {
  stationId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsStationHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsStationHistoryByStationIdQuery = {
  saaSNewsStationHistoryByStationId?: {
    __typename: 'ModelSaaSNewsStationHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsStationHistory';
      id: string;
      deliveryId?: string | null;
      stationId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNewsBillingHistoryQueryVariables = {
  id: string;
};

export type GetSaaSNewsBillingHistoryQuery = {
  getSaaSNewsBillingHistory?: {
    __typename: 'SaaSNewsBillingHistory';
    id: string;
    userId?: string | null;
    stamp?: string | null;
    deliveryId?: string | null;
    amount?: number | null;
    feedbackState?: NewsFeedbackState | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNewsBillingHistoriesQueryVariables = {
  filter?: ModelSaaSNewsBillingHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNewsBillingHistoriesQuery = {
  listSaaSNewsBillingHistories?: {
    __typename: 'ModelSaaSNewsBillingHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsBillingHistory';
      id: string;
      userId?: string | null;
      stamp?: string | null;
      deliveryId?: string | null;
      amount?: number | null;
      feedbackState?: NewsFeedbackState | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsBillingHistoryByUserIdQueryVariables = {
  userId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsBillingHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsBillingHistoryByUserIdQuery = {
  saaSNewsBillingHistoryByUserId?: {
    __typename: 'ModelSaaSNewsBillingHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsBillingHistory';
      id: string;
      userId?: string | null;
      stamp?: string | null;
      deliveryId?: string | null;
      amount?: number | null;
      feedbackState?: NewsFeedbackState | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsBillingHistoryByStampQueryVariables = {
  stamp: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsBillingHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsBillingHistoryByStampQuery = {
  saaSNewsBillingHistoryByStamp?: {
    __typename: 'ModelSaaSNewsBillingHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsBillingHistory';
      id: string;
      userId?: string | null;
      stamp?: string | null;
      deliveryId?: string | null;
      amount?: number | null;
      feedbackState?: NewsFeedbackState | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsBillingHistoryByDeliveryIdQueryVariables = {
  deliveryId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsBillingHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsBillingHistoryByDeliveryIdQuery = {
  saaSNewsBillingHistoryByDeliveryId?: {
    __typename: 'ModelSaaSNewsBillingHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsBillingHistory';
      id: string;
      userId?: string | null;
      stamp?: string | null;
      deliveryId?: string | null;
      amount?: number | null;
      feedbackState?: NewsFeedbackState | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNewsFeedbackHistoryQueryVariables = {
  id: string;
};

export type GetSaaSNewsFeedbackHistoryQuery = {
  getSaaSNewsFeedbackHistory?: {
    __typename: 'SaaSNewsFeedbackHistory';
    id: string;
    userId?: string | null;
    deliveryId?: string | null;
    stamp?: string | null;
    feedbackType?: NewsFeedbackType | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNewsFeedbackHistoriesQueryVariables = {
  filter?: ModelSaaSNewsFeedbackHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNewsFeedbackHistoriesQuery = {
  listSaaSNewsFeedbackHistories?: {
    __typename: 'ModelSaaSNewsFeedbackHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsFeedbackHistory';
      id: string;
      userId?: string | null;
      deliveryId?: string | null;
      stamp?: string | null;
      feedbackType?: NewsFeedbackType | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNewsFeedbackHistoryByStampQueryVariables = {
  stamp: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNewsFeedbackHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNewsFeedbackHistoryByStampQuery = {
  saaSNewsFeedbackHistoryByStamp?: {
    __typename: 'ModelSaaSNewsFeedbackHistoryConnection';
    items: Array<{
      __typename: 'SaaSNewsFeedbackHistory';
      id: string;
      userId?: string | null;
      deliveryId?: string | null;
      stamp?: string | null;
      feedbackType?: NewsFeedbackType | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSDeliveryHistoryQueryVariables = {
  id: string;
};

export type GetSaaSDeliveryHistoryQuery = {
  getSaaSDeliveryHistory?: {
    __typename: 'SaaSDeliveryHistory';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    couponId?: string | null;
    couponDef?: string | null;
    targetId?: string | null;
    targetDef?: string | null;
    title?: string | null;
    budget?: number | null;
    balance?: number | null;
    couponState?: CouponState | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    startAt?: string | null;
    endAt?: string | null;
    deliveryArea?: DeliveryArea | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSDeliveryHistoriesQueryVariables = {
  filter?: ModelSaaSDeliveryHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSDeliveryHistoriesQuery = {
  listSaaSDeliveryHistories?: {
    __typename: 'ModelSaaSDeliveryHistoryConnection';
    items: Array<{
      __typename: 'SaaSDeliveryHistory';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      couponId?: string | null;
      couponDef?: string | null;
      targetId?: string | null;
      targetDef?: string | null;
      title?: string | null;
      budget?: number | null;
      balance?: number | null;
      couponState?: CouponState | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      startAt?: string | null;
      endAt?: string | null;
      deliveryArea?: DeliveryArea | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSDeliveryHistoryByShopIdQueryVariables = {
  shopId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSDeliveryHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSDeliveryHistoryByShopIdQuery = {
  saaSDeliveryHistoryByShopId?: {
    __typename: 'ModelSaaSDeliveryHistoryConnection';
    items: Array<{
      __typename: 'SaaSDeliveryHistory';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      couponId?: string | null;
      couponDef?: string | null;
      targetId?: string | null;
      targetDef?: string | null;
      title?: string | null;
      budget?: number | null;
      balance?: number | null;
      couponState?: CouponState | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      startAt?: string | null;
      endAt?: string | null;
      deliveryArea?: DeliveryArea | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSDeliveryHistoryByCouponIdQueryVariables = {
  couponId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSDeliveryHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSDeliveryHistoryByCouponIdQuery = {
  saaSDeliveryHistoryByCouponId?: {
    __typename: 'ModelSaaSDeliveryHistoryConnection';
    items: Array<{
      __typename: 'SaaSDeliveryHistory';
      id: string;
      ownerId?: string | null;
      shopId?: string | null;
      couponId?: string | null;
      couponDef?: string | null;
      targetId?: string | null;
      targetDef?: string | null;
      title?: string | null;
      budget?: number | null;
      balance?: number | null;
      couponState?: CouponState | null;
      userOption?: {
        __typename: 'UserOption';
        gender?: Gender | null;
        ageRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        married?: boolean | null;
        residence?: Array<Residence | null> | null;
        movingMethod?: Array<MovingMethod | null> | null;
        childrenTogether?: number | null;
        childYoungest?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        incomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        householdIncomeRange?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        familyTogether?: number | null;
        address?: Array<number | null> | null;
        job?: Array<number | null> | null;
        interest?: Array<number | null> | null;
        childAge?: {
          __typename: 'RangeOption';
          lower?: number | null;
          upper?: number | null;
        } | null;
        followed?: boolean | null;
        visited?: boolean | null;
      } | null;
      startAt?: string | null;
      endAt?: string | null;
      deliveryArea?: DeliveryArea | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSDeliveredLogQueryVariables = {
  id: string;
};

export type GetSaaSDeliveredLogQuery = {
  getSaaSDeliveredLog?: {
    __typename: 'SaaSDeliveredLog';
    id: string;
    shopId?: string | null;
    deliveryHistoryId?: string | null;
    stamp?: string | null;
    deliveryAt?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSDeliveredLogsQueryVariables = {
  filter?: ModelSaaSDeliveredLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSDeliveredLogsQuery = {
  listSaaSDeliveredLogs?: {
    __typename: 'ModelSaaSDeliveredLogConnection';
    items: Array<{
      __typename: 'SaaSDeliveredLog';
      id: string;
      shopId?: string | null;
      deliveryHistoryId?: string | null;
      stamp?: string | null;
      deliveryAt?: string | null;
      userId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSDeliveredLogByShopIdQueryVariables = {
  shopId: string;
  stamp?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSDeliveredLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSDeliveredLogByShopIdQuery = {
  saaSDeliveredLogByShopId?: {
    __typename: 'ModelSaaSDeliveredLogConnection';
    items: Array<{
      __typename: 'SaaSDeliveredLog';
      id: string;
      shopId?: string | null;
      deliveryHistoryId?: string | null;
      stamp?: string | null;
      deliveryAt?: string | null;
      userId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSDeliveredLogByDeliveryHistoryIdQueryVariables = {
  deliveryHistoryId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSDeliveredLogFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSDeliveredLogByDeliveryHistoryIdQuery = {
  saaSDeliveredLogByDeliveryHistoryId?: {
    __typename: 'ModelSaaSDeliveredLogConnection';
    items: Array<{
      __typename: 'SaaSDeliveredLog';
      id: string;
      shopId?: string | null;
      deliveryHistoryId?: string | null;
      stamp?: string | null;
      deliveryAt?: string | null;
      userId?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSCouponAreaHistoryQueryVariables = {
  id: string;
};

export type GetSaaSCouponAreaHistoryQuery = {
  getSaaSCouponAreaHistory?: {
    __typename: 'SaaSCouponAreaHistory';
    id: string;
    deliveryId?: string | null;
    meshId?: string | null;
    startAt?: string | null;
    endAt?: string | null;
    areaOption?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSCouponAreaHistoriesQueryVariables = {
  filter?: ModelSaaSCouponAreaHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSCouponAreaHistoriesQuery = {
  listSaaSCouponAreaHistories?: {
    __typename: 'ModelSaaSCouponAreaHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponAreaHistory';
      id: string;
      deliveryId?: string | null;
      meshId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      areaOption?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponAreaHistoryByMeshIdQueryVariables = {
  meshId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponAreaHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponAreaHistoryByMeshIdQuery = {
  saaSCouponAreaHistoryByMeshId?: {
    __typename: 'ModelSaaSCouponAreaHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponAreaHistory';
      id: string;
      deliveryId?: string | null;
      meshId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      areaOption?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSCouponStayHistoryQueryVariables = {
  id: string;
};

export type GetSaaSCouponStayHistoryQuery = {
  getSaaSCouponStayHistory?: {
    __typename: 'SaaSCouponStayHistory';
    id: string;
    deliveryId?: string | null;
    meshId?: string | null;
    startAt?: string | null;
    endAt?: string | null;
    stayOption?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSCouponStayHistoriesQueryVariables = {
  filter?: ModelSaaSCouponStayHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSCouponStayHistoriesQuery = {
  listSaaSCouponStayHistories?: {
    __typename: 'ModelSaaSCouponStayHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponStayHistory';
      id: string;
      deliveryId?: string | null;
      meshId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      stayOption?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponStayHistoryByMeshIdQueryVariables = {
  meshId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponStayHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponStayHistoryByMeshIdQuery = {
  saaSCouponStayHistoryByMeshId?: {
    __typename: 'ModelSaaSCouponStayHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponStayHistory';
      id: string;
      deliveryId?: string | null;
      meshId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      stayOption?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSCouponStationHistoryQueryVariables = {
  id: string;
};

export type GetSaaSCouponStationHistoryQuery = {
  getSaaSCouponStationHistory?: {
    __typename: 'SaaSCouponStationHistory';
    id: string;
    deliveryId?: string | null;
    stationId?: string | null;
    startAt?: string | null;
    endAt?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSCouponStationHistoriesQueryVariables = {
  filter?: ModelSaaSCouponStationHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSCouponStationHistoriesQuery = {
  listSaaSCouponStationHistories?: {
    __typename: 'ModelSaaSCouponStationHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponStationHistory';
      id: string;
      deliveryId?: string | null;
      stationId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponStationHistoryByStationIdQueryVariables = {
  stationId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponStationHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponStationHistoryByStationIdQuery = {
  saaSCouponStationHistoryByStationId?: {
    __typename: 'ModelSaaSCouponStationHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponStationHistory';
      id: string;
      deliveryId?: string | null;
      stationId?: string | null;
      startAt?: string | null;
      endAt?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSCouponBillingHistoryQueryVariables = {
  id: string;
};

export type GetSaaSCouponBillingHistoryQuery = {
  getSaaSCouponBillingHistory?: {
    __typename: 'SaaSCouponBillingHistory';
    id: string;
    userId?: string | null;
    stamp?: string | null;
    deliveryId?: string | null;
    amount?: number | null;
    feedbackState?: CouponFeedbackState | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSCouponBillingHistoriesQueryVariables = {
  filter?: ModelSaaSCouponBillingHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSCouponBillingHistoriesQuery = {
  listSaaSCouponBillingHistories?: {
    __typename: 'ModelSaaSCouponBillingHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponBillingHistory';
      id: string;
      userId?: string | null;
      stamp?: string | null;
      deliveryId?: string | null;
      amount?: number | null;
      feedbackState?: CouponFeedbackState | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponBillingHistoryByUserIdQueryVariables = {
  userId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponBillingHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponBillingHistoryByUserIdQuery = {
  saaSCouponBillingHistoryByUserId?: {
    __typename: 'ModelSaaSCouponBillingHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponBillingHistory';
      id: string;
      userId?: string | null;
      stamp?: string | null;
      deliveryId?: string | null;
      amount?: number | null;
      feedbackState?: CouponFeedbackState | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponBillingHistoryByStampQueryVariables = {
  stamp: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponBillingHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponBillingHistoryByStampQuery = {
  saaSCouponBillingHistoryByStamp?: {
    __typename: 'ModelSaaSCouponBillingHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponBillingHistory';
      id: string;
      userId?: string | null;
      stamp?: string | null;
      deliveryId?: string | null;
      amount?: number | null;
      feedbackState?: CouponFeedbackState | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponBillingHistoryByDeliveryIdQueryVariables = {
  deliveryId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponBillingHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponBillingHistoryByDeliveryIdQuery = {
  saaSCouponBillingHistoryByDeliveryId?: {
    __typename: 'ModelSaaSCouponBillingHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponBillingHistory';
      id: string;
      userId?: string | null;
      stamp?: string | null;
      deliveryId?: string | null;
      amount?: number | null;
      feedbackState?: CouponFeedbackState | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSCouponFeedbackHistoryQueryVariables = {
  id: string;
};

export type GetSaaSCouponFeedbackHistoryQuery = {
  getSaaSCouponFeedbackHistory?: {
    __typename: 'SaaSCouponFeedbackHistory';
    id: string;
    userId?: string | null;
    deliveryId?: string | null;
    stamp?: string | null;
    feedbackType?: CouponFeedbackType | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSCouponFeedbackHistoriesQueryVariables = {
  filter?: ModelSaaSCouponFeedbackHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSCouponFeedbackHistoriesQuery = {
  listSaaSCouponFeedbackHistories?: {
    __typename: 'ModelSaaSCouponFeedbackHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponFeedbackHistory';
      id: string;
      userId?: string | null;
      deliveryId?: string | null;
      stamp?: string | null;
      feedbackType?: CouponFeedbackType | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSCouponFeedbackHistoryByStampQueryVariables = {
  stamp: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSCouponFeedbackHistoryFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSCouponFeedbackHistoryByStampQuery = {
  saaSCouponFeedbackHistoryByStamp?: {
    __typename: 'ModelSaaSCouponFeedbackHistoryConnection';
    items: Array<{
      __typename: 'SaaSCouponFeedbackHistory';
      id: string;
      userId?: string | null;
      deliveryId?: string | null;
      stamp?: string | null;
      feedbackType?: CouponFeedbackType | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSAnalyticsQueryVariables = {
  id: string;
};

export type GetSaaSAnalyticsQuery = {
  getSaaSAnalytics?: {
    __typename: 'SaaSAnalytics';
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSAnalyticsQueryVariables = {
  filter?: ModelSaaSAnalyticsFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSAnalyticsQuery = {
  listSaaSAnalytics?: {
    __typename: 'ModelSaaSAnalyticsConnection';
    items: Array<{
      __typename: 'SaaSAnalytics';
      id: string;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSNotificationQueryVariables = {
  id: string;
};

export type GetSaaSNotificationQuery = {
  getSaaSNotification?: {
    __typename: 'SaaSNotification';
    id: string;
    ownerId?: string | null;
    targetId?: string | null;
    title?: string | null;
    content?: string | null;
    type?: NotificationType | null;
    read?: boolean | null;
    issue?: {
      __typename: 'Issue';
      target?: IssueTarget | null;
      reasons?: IssueReason | null;
      treatments?: IssueTreatment | null;
      needActions?: IssueNeedAction | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSNotificationsQueryVariables = {
  filter?: ModelSaaSNotificationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSNotificationsQuery = {
  listSaaSNotifications?: {
    __typename: 'ModelSaaSNotificationConnection';
    items: Array<{
      __typename: 'SaaSNotification';
      id: string;
      ownerId?: string | null;
      targetId?: string | null;
      title?: string | null;
      content?: string | null;
      type?: NotificationType | null;
      read?: boolean | null;
      issue?: {
        __typename: 'Issue';
        target?: IssueTarget | null;
        reasons?: IssueReason | null;
        treatments?: IssueTreatment | null;
        needActions?: IssueNeedAction | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSNotificationByOwnerIdQueryVariables = {
  ownerId: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSNotificationFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSNotificationByOwnerIdQuery = {
  saaSNotificationByOwnerId?: {
    __typename: 'ModelSaaSNotificationConnection';
    items: Array<{
      __typename: 'SaaSNotification';
      id: string;
      ownerId?: string | null;
      targetId?: string | null;
      title?: string | null;
      content?: string | null;
      type?: NotificationType | null;
      read?: boolean | null;
      issue?: {
        __typename: 'Issue';
        target?: IssueTarget | null;
        reasons?: IssueReason | null;
        treatments?: IssueTreatment | null;
        needActions?: IssueNeedAction | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSUserInfoQueryVariables = {
  id: string;
};

export type GetSaaSUserInfoQuery = {
  getSaaSUserInfo?: {
    __typename: 'SaaSUserInfo';
    id: string;
    email?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    agentCode?: string | null;
    reseller?: boolean | null;
    sendReportEmail?: {
      __typename: 'SendReportEmail';
      endOfDelivery?: boolean | null;
      shortBalance?: boolean | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSUserInfosQueryVariables = {
  filter?: ModelSaaSUserInfoFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSUserInfosQuery = {
  listSaaSUserInfos?: {
    __typename: 'ModelSaaSUserInfoConnection';
    items: Array<{
      __typename: 'SaaSUserInfo';
      id: string;
      email?: string | null;
      familyName?: string | null;
      givenName?: string | null;
      agentCode?: string | null;
      reseller?: boolean | null;
      sendReportEmail?: {
        __typename: 'SendReportEmail';
        endOfDelivery?: boolean | null;
        shortBalance?: boolean | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSUserInfoByEmailQueryVariables = {
  email: string;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSUserInfoFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSUserInfoByEmailQuery = {
  saaSUserInfoByEmail?: {
    __typename: 'ModelSaaSUserInfoConnection';
    items: Array<{
      __typename: 'SaaSUserInfo';
      id: string;
      email?: string | null;
      familyName?: string | null;
      givenName?: string | null;
      agentCode?: string | null;
      reseller?: boolean | null;
      sendReportEmail?: {
        __typename: 'SendReportEmail';
        endOfDelivery?: boolean | null;
        shortBalance?: boolean | null;
      } | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetSaaSAccessManageQueryVariables = {
  id: string;
};

export type GetSaaSAccessManageQuery = {
  getSaaSAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type ListSaaSAccessManagesQueryVariables = {
  filter?: ModelSaaSAccessManageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type ListSaaSAccessManagesQuery = {
  listSaaSAccessManages?: {
    __typename: 'ModelSaaSAccessManageConnection';
    items: Array<{
      __typename: 'SaaSAccessManage';
      id: string;
      ownerId?: string | null;
      userId?: string | null;
      email?: string | null;
      addUserId?: string | null;
      role?: AccessManageRole | null;
      status?: AccessManageStatus | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSAccessManageByOwnerIdQueryVariables = {
  ownerId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSAccessManageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSAccessManageByOwnerIdQuery = {
  saaSAccessManageByOwnerId?: {
    __typename: 'ModelSaaSAccessManageConnection';
    items: Array<{
      __typename: 'SaaSAccessManage';
      id: string;
      ownerId?: string | null;
      userId?: string | null;
      email?: string | null;
      addUserId?: string | null;
      role?: AccessManageRole | null;
      status?: AccessManageStatus | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSAccessManageByUserIdQueryVariables = {
  userId: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSAccessManageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSAccessManageByUserIdQuery = {
  saaSAccessManageByUserId?: {
    __typename: 'ModelSaaSAccessManageConnection';
    items: Array<{
      __typename: 'SaaSAccessManage';
      id: string;
      ownerId?: string | null;
      userId?: string | null;
      email?: string | null;
      addUserId?: string | null;
      role?: AccessManageRole | null;
      status?: AccessManageStatus | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type SaaSAccessManageByEmailQueryVariables = {
  email: string;
  createdAt?: ModelStringKeyConditionInput | null;
  sortDirection?: ModelSortDirection | null;
  filter?: ModelSaaSAccessManageFilterInput | null;
  limit?: number | null;
  nextToken?: string | null;
};

export type SaaSAccessManageByEmailQuery = {
  saaSAccessManageByEmail?: {
    __typename: 'ModelSaaSAccessManageConnection';
    items: Array<{
      __typename: 'SaaSAccessManage';
      id: string;
      ownerId?: string | null;
      userId?: string | null;
      email?: string | null;
      addUserId?: string | null;
      role?: AccessManageRole | null;
      status?: AccessManageStatus | null;
      createdAt?: string | null;
      updatedAt?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
};

export type GetInviteInfoQueryVariables = {
  id?: string | null;
};

export type GetInviteInfoQuery = {
  getInviteInfo?: {
    __typename: 'InviteInfo';
    email?: string | null;
    hostEmail?: string | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
  } | null;
};

export type OnCreateSaaSOwnerSubscription = {
  onCreateSaaSOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSaaSOwnerSubscription = {
  onUpdateSaaSOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSaaSOwnerSubscription = {
  onDeleteSaaSOwner?: {
    __typename: 'SaaSOwner';
    id: string;
    hojin?: string | null;
    hojinKana?: string | null;
    representative?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    representativeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    url?: string | null;
    chargeDept?: string | null;
    charge?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargeKana?: {
      __typename: 'FullName';
      first?: string | null;
      last?: string | null;
    } | null;
    chargePhone?: string | null;
    status?: OwnerStatus | null;
    agentCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateSaaSShopSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnCreateSaaSShopSubscription = {
  onCreateSaaSShop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type OnUpdateSaaSShopSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnUpdateSaaSShopSubscription = {
  onUpdateSaaSShop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type OnDeleteSaaSShopSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnDeleteSaaSShopSubscription = {
  onDeleteSaaSShop?: {
    __typename: 'SaaSShop';
    id: string;
    ownerId?: string | null;
    groupId?: string | null;
    name?: string | null;
    text?: string | null;
    address?: {
      __typename: 'FullAddress';
      zip?: string | null;
      pref?: string | null;
      city?: string | null;
      detail?: string | null;
      building?: string | null;
    } | null;
    phone?: string | null;
    biztimes?: string | null;
    holidays?: string | null;
    url?: string | null;
    legalUrl?: string | null;
    category?: ShopCategory | null;
    imageUrls?: Array<string | null> | null;
    icon?: string | null;
    location?: {
      __typename: 'Location';
      lat?: number | null;
      lon?: number | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
    shopState?: ShopState | null;
  } | null;
};

export type OnCreateSaaSShopGroupSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnCreateSaaSShopGroupSubscription = {
  onCreateSaaSShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSaaSShopGroupSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnUpdateSaaSShopGroupSubscription = {
  onUpdateSaaSShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSaaSShopGroupSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnDeleteSaaSShopGroupSubscription = {
  onDeleteSaaSShopGroup?: {
    __typename: 'SaaSShopGroup';
    id: string;
    ownerId?: string | null;
    name?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateSaaSShopExcludeVisitorSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnCreateSaaSShopExcludeVisitorSubscription = {
  onCreateSaaSShopExcludeVisitor?: {
    __typename: 'SaaSShopExcludeVisitor';
    id: string;
    shopId?: string | null;
    ownerId?: string | null;
    invitedCode?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSaaSShopExcludeVisitorSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnUpdateSaaSShopExcludeVisitorSubscription = {
  onUpdateSaaSShopExcludeVisitor?: {
    __typename: 'SaaSShopExcludeVisitor';
    id: string;
    shopId?: string | null;
    ownerId?: string | null;
    invitedCode?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSaaSShopExcludeVisitorSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnDeleteSaaSShopExcludeVisitorSubscription = {
  onDeleteSaaSShopExcludeVisitor?: {
    __typename: 'SaaSShopExcludeVisitor';
    id: string;
    shopId?: string | null;
    ownerId?: string | null;
    invitedCode?: string | null;
    userId?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateSaaSPaymentInfoSubscriptionVariables = {
  id?: string | null;
};

export type OnCreateSaaSPaymentInfoSubscription = {
  onCreateSaaSPaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSaaSPaymentInfoSubscriptionVariables = {
  id?: string | null;
};

export type OnUpdateSaaSPaymentInfoSubscription = {
  onUpdateSaaSPaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSaaSPaymentInfoSubscriptionVariables = {
  id?: string | null;
};

export type OnDeleteSaaSPaymentInfoSubscription = {
  onDeleteSaaSPaymentInfo?: {
    __typename: 'SaaSPaymentInfo';
    id: string;
    customerId?: string | null;
    paymentMethodId?: string | null;
    paymentType?: PaymentType | null;
    subscriptionId?: string | null;
    subscriptionItemIds?: Array<string | null> | null;
    priceIds?: Array<string | null> | null;
    billing?: boolean | null;
    invitedCode?: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateSaaSDeliveryTargetSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnCreateSaaSDeliveryTargetSubscription = {
  onCreateSaaSDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSaaSDeliveryTargetSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnUpdateSaaSDeliveryTargetSubscription = {
  onUpdateSaaSDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSaaSDeliveryTargetSubscriptionVariables = {
  ownerId?: string | null;
};

export type OnDeleteSaaSDeliveryTargetSubscription = {
  onDeleteSaaSDeliveryTarget?: {
    __typename: 'SaaSDeliveryTarget';
    id: string;
    ownerId?: string | null;
    shopId?: string | null;
    title?: string | null;
    areaOption?: {
      __typename: 'AreaOption';
      areaType?: AreaType | null;
      meshs?: Array<string | null> | null;
    } | null;
    stayOption?: {
      __typename: 'StayOption';
      weekDays?: Array<number | null> | null;
      hours?: Array<number | null> | null;
      meshs?: Array<string | null> | null;
    } | null;
    stationOption?: Array<string | null> | null;
    cityOption?: Array<{
      __typename: 'CityOption';
      prefCode?: string | null;
      cityCode?: string | null;
      prefName?: string | null;
      cityName?: string | null;
    } | null> | null;
    userOption?: {
      __typename: 'UserOption';
      gender?: Gender | null;
      ageRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      married?: boolean | null;
      residence?: Array<Residence | null> | null;
      movingMethod?: Array<MovingMethod | null> | null;
      childrenTogether?: number | null;
      childYoungest?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      incomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      householdIncomeRange?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      familyTogether?: number | null;
      address?: Array<number | null> | null;
      job?: Array<number | null> | null;
      interest?: Array<number | null> | null;
      childAge?: {
        __typename: 'RangeOption';
        lower?: number | null;
        upper?: number | null;
      } | null;
      followed?: boolean | null;
      visited?: boolean | null;
    } | null;
    users?: number | null;
    editState?: EditState | null;
    archive?: boolean | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateSaaSAnalyticsSubscription = {
  onCreateSaaSAnalytics?: {
    __typename: 'SaaSAnalytics';
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSaaSAnalyticsSubscription = {
  onUpdateSaaSAnalytics?: {
    __typename: 'SaaSAnalytics';
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSaaSAnalyticsSubscription = {
  onDeleteSaaSAnalytics?: {
    __typename: 'SaaSAnalytics';
    id: string;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateSaaSUserInfoSubscriptionVariables = {
  id?: string | null;
};

export type OnCreateSaaSUserInfoSubscription = {
  onCreateSaaSUserInfo?: {
    __typename: 'SaaSUserInfo';
    id: string;
    email?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    agentCode?: string | null;
    reseller?: boolean | null;
    sendReportEmail?: {
      __typename: 'SendReportEmail';
      endOfDelivery?: boolean | null;
      shortBalance?: boolean | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSaaSUserInfoSubscriptionVariables = {
  id?: string | null;
};

export type OnUpdateSaaSUserInfoSubscription = {
  onUpdateSaaSUserInfo?: {
    __typename: 'SaaSUserInfo';
    id: string;
    email?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    agentCode?: string | null;
    reseller?: boolean | null;
    sendReportEmail?: {
      __typename: 'SendReportEmail';
      endOfDelivery?: boolean | null;
      shortBalance?: boolean | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSaaSUserInfoSubscriptionVariables = {
  id?: string | null;
};

export type OnDeleteSaaSUserInfoSubscription = {
  onDeleteSaaSUserInfo?: {
    __typename: 'SaaSUserInfo';
    id: string;
    email?: string | null;
    familyName?: string | null;
    givenName?: string | null;
    agentCode?: string | null;
    reseller?: boolean | null;
    sendReportEmail?: {
      __typename: 'SendReportEmail';
      endOfDelivery?: boolean | null;
      shortBalance?: boolean | null;
    } | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnCreateSaaSAccessManageSubscription = {
  onCreateSaaSAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnUpdateSaaSAccessManageSubscription = {
  onUpdateSaaSAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};

export type OnDeleteSaaSAccessManageSubscription = {
  onDeleteSaaSAccessManage?: {
    __typename: 'SaaSAccessManage';
    id: string;
    ownerId?: string | null;
    userId?: string | null;
    email?: string | null;
    addUserId?: string | null;
    role?: AccessManageRole | null;
    status?: AccessManageStatus | null;
    createdAt?: string | null;
    updatedAt?: string | null;
  } | null;
};
