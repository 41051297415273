import {Auth} from 'aws-amplify';
import axios from 'axios';
import dayjs from 'dayjs';
import _ from 'lodash';
import * as queryString from 'query-string';
import {PoiType, SaaSDeliveryTarget} from '../../API';
import {
  createDeliveryTarget,
  updateDeliveryTarget,
} from '../../graphql/mutations';
import {
  getSaaSStationPoi,
  listDeliveryTarget,
  saaSStationPoiByParent,
  saaSStationPoiByPoiType,
} from '../../graphql/queries';
import {AppConfig} from '../config/AppConfig';
import {GraphQlRepository} from './GraphQlRepository';
import {SaaSRepository} from './SaaSRepository';
import {EncodedUserProp, UserOptionDto} from './helpers/UserPropHelper';

const TAG = 'SaaSTargetRepository';

export type AreaOption = {
  home?: boolean;
  office?: boolean;
};

export type StayOption = {
  stayWeekDays: number[];
  stayHours: number[];
};

export type AreaProp = {
  stayType: string;
  stayHours: number[];
};

export type SaaSStationResponse = {
  id: string;
  user: EncodedUserProp;
};

export type SaaSAreaResponse = {
  id: string;
  stayType: string;
  user: EncodedUserProp;
};

export type SaaSStayResponse = {
  id: string;
  stay: number[];
  user: EncodedUserProp;
};

export type AreaOptionDto = {
  areaType: string;
  meshs: string[];
};

export type StayOptionDto = {
  weekDays: number[];
  hours: number[];
  meshs: string[];
};

export type CityOptionDto = {
  prefCode?: string;
  cityCode?: string;
  prefName?: string;
  cityName?: string;
};

export type DeliveryTargetDto = {
  id?: string;
  ownerId?: string;
  shopId?: string;
  title?: string;
  areaOption?: AreaOptionDto;
  stayOption?: StayOptionDto;
  stationOption?: string[];
  cityOption?: CityOptionDto[];
  userOption?: UserOptionDto;
  editState?: string;
  users?: number;
  archive?: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type MeshOption = {
  meshs: string[];
  shopId: string;
};

export type StationParam = {
  id: string;
  name: string;
};

export type StationOption = {
  stations: string[];
  shopId: string;
};

export type TargetsCount = {
  targetId: string;
  deliveries: number;
  users: number;
};

export type StationCategory = {
  id: string;
  name: string;
  lines?: [SearchLine];
};

export type SearchLine = {
  id: string;
  name: string;
  stations: [
    {
      id: string;
      name: string;
      parent: string;
    },
  ];
};

export type SearchStation = {
  id: string;
  name: string;
  parent: string;
};

export type StationCategories = StationCategory[];

export type StationSearchResult = {
  id: string;
  name: string;
  lines: [
    {
      id: string;
      name: string;
      stations: [
        {
          id: string;
          name: string;
          parent: string;
        },
      ];
    },
  ];
};

export type CitySearchResult = {
  id: string;
  name: string;
  prefs: [
    {
      id: string;
      name: string;
      cities: [
        {
          id: string;
          name: string;
          prefCode: string;
        },
      ];
    },
  ];
};

export class SaaSTargetRepository {
  static async fetchMeshUsers(mesh: MeshOption): Promise<any> {
    const config = await SaaSRepository.axioConfig();
    const params = {
      url: `${AppConfig.SaaSBackend}${AppConfig.MeshTargetEndPoint}/${mesh.shopId}`,
      query: {
        meshs: mesh.meshs.join(','),
      },
    };
    const url = queryString.stringifyUrl(params);
    console.log(TAG, 'fetchMeshUsers', url);

    const result = await axios.get(url, config);

    // console.log(TAG, 'fetch-mesh', result);
    return result.data;
  }

  static async fetchMeshStayUsers(mesh: MeshOption): Promise<any> {
    const config = await SaaSRepository.axioConfig();
    const params = {
      url: `${AppConfig.SaaSBackend}${AppConfig.MeshStayTargetEndPoint}/${mesh.shopId}`,
      query: {
        meshs: mesh.meshs.join(','),
      },
    };
    const url = queryString.stringifyUrl(params);
    console.log(TAG, 'fetchMeshStayUsers', url);

    const result = await axios.get(url, config);

    // console.log(TAG, 'fetch-mesh', result);
    return result.data;
  }

  static async fetchStationUsers(station: StationOption): Promise<any> {
    const config = await SaaSRepository.axioConfig();
    const params = {
      url: `${AppConfig.SaaSBackend}${AppConfig.StationTargetEndPoint}/${station.shopId}`,
      query: {
        stations: station.stations.join(','),
      },
    };
    const url = queryString.stringifyUrl(params);
    console.log(TAG, 'fetchStationUsers', url);

    const result = await axios.get(url, config);

    // console.log(TAG, 'fetch-station', result);
    return result.data;
  }

  static async fetchRouteCagetories(): Promise<StationCategories> {
    const result = (await GraphQlRepository.query(
      saaSStationPoiByPoiType,
      'saaSStationPoiByPoiType',
      {
        poiType: PoiType.TRAIN_CATEGORY,
      },
    )) as {id?: string; name?: string}[];
    if (result) {
      const filtered = result
        .map((value) => _.pick(value, ['id', 'name']))
        .filter((r) => r && r.id && r.name) as StationCategories;
      return filtered.sort((a, b) => {
        return a.id < b.id ? -1 : a.id === b.id ? 0 : 1;
      });
    }
    throw 'no result';
  }

  static async fetchRoutes(): Promise<any> {
    const result = await GraphQlRepository.query(
      saaSStationPoiByPoiType,
      'saaSStationPoiByPoiType',
      {
        poiType: PoiType.TRAIN_ROUTE,
      },
    );

    return (
      result &&
      result.sort((a: any, b: any) =>
        a.name < b.name ? -1 : a.name === b.name ? 0 : -1,
      )
    );
  }

  static async fetchStationChild(id: string): Promise<any> {
    const result = await GraphQlRepository.query(
      saaSStationPoiByParent,
      'saaSStationPoiByParent',
      {
        parent: id,
      },
    );

    if (result) {
      return result.sort((a: any, b: any) => {
        return a.id < b.id ? -1 : a.id === b.id ? 0 : 1;
      });
    }

    return (
      result &&
      result.sort((a: any, b: any) =>
        a.name < b.name ? -1 : a.name === b.name ? 0 : 1,
      )
    );
  }

  static async fetchRouteId(id: string): Promise<{
    id: string;
    routeId?: string;
    name?: string;
  } | null> {
    return await GraphQlRepository.get(getSaaSStationPoi, 'getSaaSStationPoi', {
      id,
    });
  }

  static async saveDeliveryTarget(def: DeliveryTargetDto): Promise<any> {
    // console.log('SaveDelivetyTarget', def);
    return def.id
      ? // save it
        GraphQlRepository.mutation(
          updateDeliveryTarget,
          'updateDeliveryTarget',
          {input: def},
        )
      : // create it
        GraphQlRepository.mutation(
          createDeliveryTarget,
          'createDeliveryTarget',
          {input: def},
        );
  }

  static async fixDeliveryTarge(): Promise<any> {
    return null;
  }

  static async queryDeliveryTarget({
    shopId,
    limit,
    nextToken,
  }: {
    shopId: string;
    limit?: number;
    nextToken?: string;
  }): Promise<SaaSDeliveryTarget[]> {
    const user = await Auth.currentUserPoolUser();
    console.log(TAG, 'user', user);

    const result = await GraphQlRepository.query(
      listDeliveryTarget,
      'listDeliveryTarget',
      {shopId, sortDirection: 'DESC', limit, nextToken},
    );

    return result;
  }

  static async getDeliveryCountOnCoupon(id: string): Promise<TargetsCount> {
    console.log(TAG, '[getDeliveryCountOnCoupon]', id);

    const config = await SaaSRepository.axioConfig();
    const random = dayjs().unix();
    const result = await axios.get(
      `${AppConfig.SaaSBackend}${AppConfig.CouponTargetCountEndPoint}/${id}/${random}`,
      config,
    );

    console.log(TAG, '[getDeliveryCountOnCoupon] result', result);
    const data = result.data;
    if (data) {
      const targetId = data.targetId;
      const deliveries = Number(data.deliveries);
      const users = Number(data.users);
      return {targetId, deliveries, users};
    }

    throw '[getDeliveryCountOnCoupon] field error';
  }

  static async getDeliveryCountOnNews(id: string): Promise<TargetsCount> {
    console.log(TAG, '[getDeliveryCountOnNews]', id);

    const config = await SaaSRepository.axioConfig();
    const random = dayjs().unix();
    const result = await axios.get(
      `${AppConfig.SaaSBackend}${AppConfig.NewsTargetCountEndPoint}/${id}/${random}`,
      config,
    );

    console.log(TAG, '[getDeliveryCountOnNews] result', result);
    const data = result.data;
    if (data) {
      const targetId = data.targetId;
      const deliveries = Number(data.deliveries);
      const users = Number(data.users);
      return {targetId, deliveries, users};
    }

    throw '[getDeliveryCountOnNews] field error';
  }

  // クーポン/お知らせの合算を取得
  static async getDeliveryCount(id: string): Promise<TargetsCount> {
    const couponPromise = this.getDeliveryCountOnCoupon(id);
    const newsPromise = this.getDeliveryCountOnNews(id);
    const [coupon, news] = await Promise.all([couponPromise, newsPromise]);
    // ないと思うけど一応チェック
    if (coupon.targetId !== news.targetId) {
      throw Error('[getDeliveryCount] targetId unmatched');
    }
    return {
      targetId: coupon.targetId,
      deliveries: coupon.deliveries + news.deliveries,
      users: coupon.users + news.users,
    };
  }

  static async searchStationPoiByName(
    searchKey: string,
  ): Promise<StationSearchResult[]> {
    const config = await SaaSRepository.axioConfig();
    const params = {
      url: `${AppConfig.SaaSBackend}${AppConfig.SearchStationPoiEndPoint}`,
      query: {
        key: searchKey,
      },
    };
    const url = queryString.stringifyUrl(params);
    const result = await axios.get(url, config);

    // console.log(TAG, result.data);
    return result.data;
  }

  static async searchCityPoiByName(
    searchKey: string,
  ): Promise<CitySearchResult[]> {
    const config = await SaaSRepository.axioConfig();
    const params = {
      url: `${AppConfig.SaaSBackend}${AppConfig.SearchCityPoiEndPoint}`,
      query: {
        key: searchKey,
      },
    };
    const url = queryString.stringifyUrl(params);
    const result = await axios.get(url, config);

    // console.log(TAG, result.data);
    return result.data;
  }
}
