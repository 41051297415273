import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import * as Picker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';

// キャンセルされた時は保存しないため、キャンセル時を除いた型を作っておく
type PickedImage = Exclude<Picker.ImagePickerResult, {cancelled: true}>;

function useImagePicker() {
  const [baseImage, setBaseImageInner] = useState<PickedImage | undefined>();
  const [image, setImage] = useState<
    ImageManipulator.ImageResult | undefined
  >();
  const setBaseImage = useCallback((picked: Picker.ImagePickerResult) => {
    if (!picked.cancelled) {
      setBaseImageInner(picked);
    }
  }, []);
  const clear = useCallback(() => {
    setBaseImageInner(undefined);
    setImage(undefined);
  }, []);
  return {
    baseImage,
    setBaseImage,
    image,
    setImage,
    clear,
  };
}

export const ImagePickerContainer = createContainer(useImagePicker);
