/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOwner = /* GraphQL */ `
  mutation CreateOwner($input: OwnerInput) {
    createOwner(input: $input) {
      id
      hojin
      hojinKana
      representative {
        first
        last
      }
      representativeKana {
        first
        last
      }
      address {
        zip
        pref
        city
        detail
        building
      }
      url
      chargeDept
      charge {
        first
        last
      }
      chargeKana {
        first
        last
      }
      chargePhone
      status
      agentCode
      createdAt
      updatedAt
    }
  }
`;
export const updateOwner = /* GraphQL */ `
  mutation UpdateOwner($input: OwnerInput) {
    updateOwner(input: $input) {
      id
      hojin
      hojinKana
      representative {
        first
        last
      }
      representativeKana {
        first
        last
      }
      address {
        zip
        pref
        city
        detail
        building
      }
      url
      chargeDept
      charge {
        first
        last
      }
      chargeKana {
        first
        last
      }
      chargePhone
      status
      agentCode
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentInfo = /* GraphQL */ `
  mutation UpdatePaymentInfo($input: PaymentInfoInput) {
    updatePaymentInfo(input: $input) {
      id
      customerId
      paymentMethodId
      paymentType
      subscriptionId
      subscriptionItemIds
      priceIds
      billing
      invitedCode
      createdAt
      updatedAt
    }
  }
`;
export const createAccessManage = /* GraphQL */ `
  mutation CreateAccessManage($input: AccessManageInput) {
    createAccessManage(input: $input) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateAccessManage = /* GraphQL */ `
  mutation UpdateAccessManage($input: AccessManageInput) {
    updateAccessManage(input: $input) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateAccessManageEmail = /* GraphQL */ `
  mutation UpdateAccessManageEmail($input: UpdateAccessManageEmailInput) {
    updateAccessManageEmail(input: $input) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccessManage = /* GraphQL */ `
  mutation DeleteAccessManage($input: DeleteAccessManageInput) {
    deleteAccessManage(input: $input) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const migrateOwnerAccess = /* GraphQL */ `
  mutation MigrateOwnerAccess($input: MigrateOwnerAccessInput) {
    migrateOwnerAccess(input: $input) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const createDeliveryTarget = /* GraphQL */ `
  mutation CreateDeliveryTarget($input: DeliveryTargetInput) {
    createDeliveryTarget(input: $input) {
      id
      ownerId
      shopId
      title
      areaOption {
        areaType
        meshs
      }
      stayOption {
        weekDays
        hours
        meshs
      }
      stationOption
      cityOption {
        prefCode
        cityCode
        prefName
        cityName
      }
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      users
      editState
      archive
      createdAt
      updatedAt
    }
  }
`;
export const updateDeliveryTarget = /* GraphQL */ `
  mutation UpdateDeliveryTarget($input: DeliveryTargetInput) {
    updateDeliveryTarget(input: $input) {
      id
      ownerId
      shopId
      title
      areaOption {
        areaType
        meshs
      }
      stayOption {
        weekDays
        hours
        meshs
      }
      stationOption
      cityOption {
        prefCode
        cityCode
        prefName
        cityName
      }
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      users
      editState
      archive
      createdAt
      updatedAt
    }
  }
`;
export const createShopGroup = /* GraphQL */ `
  mutation CreateShopGroup($input: ShopGroupInput) {
    createShopGroup(input: $input) {
      id
      ownerId
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateShopGroup = /* GraphQL */ `
  mutation UpdateShopGroup($input: ShopGroupInput) {
    updateShopGroup(input: $input) {
      id
      ownerId
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteShopGroup = /* GraphQL */ `
  mutation DeleteShopGroup($input: DeleteShopGroupInput) {
    deleteShopGroup(input: $input) {
      id
      ownerId
      name
      createdAt
      updatedAt
    }
  }
`;
export const setGroup2Shop = /* GraphQL */ `
  mutation SetGroup2Shop($input: SetGroup2ShopInput) {
    setGroup2Shop(input: $input) {
      id
      ownerId
      groupId
      name
      text
      address {
        zip
        pref
        city
        detail
        building
      }
      phone
      biztimes
      holidays
      url
      legalUrl
      category
      imageUrls
      icon
      location {
        lat
        lon
      }
      createdAt
      updatedAt
      shopState
    }
  }
`;
export const deleteDeliveryTarget = /* GraphQL */ `
  mutation DeleteDeliveryTarget($input: DeleteDeliveryTargetInput) {
    deleteDeliveryTarget(input: $input) {
      id
      ownerId
      shopId
      title
      areaOption {
        areaType
        meshs
      }
      stayOption {
        weekDays
        hours
        meshs
      }
      stationOption
      cityOption {
        prefCode
        cityCode
        prefName
        cityName
      }
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      users
      editState
      archive
      createdAt
      updatedAt
    }
  }
`;
export const createSaaSOwner = /* GraphQL */ `
  mutation CreateSaaSOwner(
    $input: CreateSaaSOwnerInput!
    $condition: ModelSaaSOwnerConditionInput
  ) {
    createSaaSOwner(input: $input, condition: $condition) {
      id
      hojin
      hojinKana
      representative {
        first
        last
      }
      representativeKana {
        first
        last
      }
      address {
        zip
        pref
        city
        detail
        building
      }
      url
      chargeDept
      charge {
        first
        last
      }
      chargeKana {
        first
        last
      }
      chargePhone
      status
      agentCode
      createdAt
      updatedAt
    }
  }
`;
export const updateSaaSOwner = /* GraphQL */ `
  mutation UpdateSaaSOwner(
    $input: UpdateSaaSOwnerInput!
    $condition: ModelSaaSOwnerConditionInput
  ) {
    updateSaaSOwner(input: $input, condition: $condition) {
      id
      hojin
      hojinKana
      representative {
        first
        last
      }
      representativeKana {
        first
        last
      }
      address {
        zip
        pref
        city
        detail
        building
      }
      url
      chargeDept
      charge {
        first
        last
      }
      chargeKana {
        first
        last
      }
      chargePhone
      status
      agentCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaaSOwner = /* GraphQL */ `
  mutation DeleteSaaSOwner(
    $input: DeleteSaaSOwnerInput!
    $condition: ModelSaaSOwnerConditionInput
  ) {
    deleteSaaSOwner(input: $input, condition: $condition) {
      id
      hojin
      hojinKana
      representative {
        first
        last
      }
      representativeKana {
        first
        last
      }
      address {
        zip
        pref
        city
        detail
        building
      }
      url
      chargeDept
      charge {
        first
        last
      }
      chargeKana {
        first
        last
      }
      chargePhone
      status
      agentCode
      createdAt
      updatedAt
    }
  }
`;
export const createSaaSShop = /* GraphQL */ `
  mutation CreateSaaSShop(
    $input: CreateSaaSShopInput!
    $condition: ModelSaaSShopConditionInput
  ) {
    createSaaSShop(input: $input, condition: $condition) {
      id
      ownerId
      groupId
      name
      text
      address {
        zip
        pref
        city
        detail
        building
      }
      phone
      biztimes
      holidays
      url
      legalUrl
      category
      imageUrls
      icon
      location {
        lat
        lon
      }
      createdAt
      updatedAt
      shopState
    }
  }
`;
export const updateSaaSShop = /* GraphQL */ `
  mutation UpdateSaaSShop(
    $input: UpdateSaaSShopInput!
    $condition: ModelSaaSShopConditionInput
  ) {
    updateSaaSShop(input: $input, condition: $condition) {
      id
      ownerId
      groupId
      name
      text
      address {
        zip
        pref
        city
        detail
        building
      }
      phone
      biztimes
      holidays
      url
      legalUrl
      category
      imageUrls
      icon
      location {
        lat
        lon
      }
      createdAt
      updatedAt
      shopState
    }
  }
`;
export const deleteSaaSShop = /* GraphQL */ `
  mutation DeleteSaaSShop(
    $input: DeleteSaaSShopInput!
    $condition: ModelSaaSShopConditionInput
  ) {
    deleteSaaSShop(input: $input, condition: $condition) {
      id
      ownerId
      groupId
      name
      text
      address {
        zip
        pref
        city
        detail
        building
      }
      phone
      biztimes
      holidays
      url
      legalUrl
      category
      imageUrls
      icon
      location {
        lat
        lon
      }
      createdAt
      updatedAt
      shopState
    }
  }
`;
export const createSaaSShopGroup = /* GraphQL */ `
  mutation CreateSaaSShopGroup(
    $input: CreateSaaSShopGroupInput!
    $condition: ModelSaaSShopGroupConditionInput
  ) {
    createSaaSShopGroup(input: $input, condition: $condition) {
      id
      ownerId
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateSaaSShopGroup = /* GraphQL */ `
  mutation UpdateSaaSShopGroup(
    $input: UpdateSaaSShopGroupInput!
    $condition: ModelSaaSShopGroupConditionInput
  ) {
    updateSaaSShopGroup(input: $input, condition: $condition) {
      id
      ownerId
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaaSShopGroup = /* GraphQL */ `
  mutation DeleteSaaSShopGroup(
    $input: DeleteSaaSShopGroupInput!
    $condition: ModelSaaSShopGroupConditionInput
  ) {
    deleteSaaSShopGroup(input: $input, condition: $condition) {
      id
      ownerId
      name
      createdAt
      updatedAt
    }
  }
`;
export const createSaaSShopExcludeVisitor = /* GraphQL */ `
  mutation CreateSaaSShopExcludeVisitor(
    $input: CreateSaaSShopExcludeVisitorInput!
    $condition: ModelSaaSShopExcludeVisitorConditionInput
  ) {
    createSaaSShopExcludeVisitor(input: $input, condition: $condition) {
      id
      shopId
      ownerId
      invitedCode
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateSaaSShopExcludeVisitor = /* GraphQL */ `
  mutation UpdateSaaSShopExcludeVisitor(
    $input: UpdateSaaSShopExcludeVisitorInput!
    $condition: ModelSaaSShopExcludeVisitorConditionInput
  ) {
    updateSaaSShopExcludeVisitor(input: $input, condition: $condition) {
      id
      shopId
      ownerId
      invitedCode
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaaSShopExcludeVisitor = /* GraphQL */ `
  mutation DeleteSaaSShopExcludeVisitor(
    $input: DeleteSaaSShopExcludeVisitorInput!
    $condition: ModelSaaSShopExcludeVisitorConditionInput
  ) {
    deleteSaaSShopExcludeVisitor(input: $input, condition: $condition) {
      id
      shopId
      ownerId
      invitedCode
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createSaaSPaymentInfo = /* GraphQL */ `
  mutation CreateSaaSPaymentInfo(
    $input: CreateSaaSPaymentInfoInput!
    $condition: ModelSaaSPaymentInfoConditionInput
  ) {
    createSaaSPaymentInfo(input: $input, condition: $condition) {
      id
      customerId
      paymentMethodId
      paymentType
      subscriptionId
      subscriptionItemIds
      priceIds
      billing
      invitedCode
      createdAt
      updatedAt
    }
  }
`;
export const updateSaaSPaymentInfo = /* GraphQL */ `
  mutation UpdateSaaSPaymentInfo(
    $input: UpdateSaaSPaymentInfoInput!
    $condition: ModelSaaSPaymentInfoConditionInput
  ) {
    updateSaaSPaymentInfo(input: $input, condition: $condition) {
      id
      customerId
      paymentMethodId
      paymentType
      subscriptionId
      subscriptionItemIds
      priceIds
      billing
      invitedCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaaSPaymentInfo = /* GraphQL */ `
  mutation DeleteSaaSPaymentInfo(
    $input: DeleteSaaSPaymentInfoInput!
    $condition: ModelSaaSPaymentInfoConditionInput
  ) {
    deleteSaaSPaymentInfo(input: $input, condition: $condition) {
      id
      customerId
      paymentMethodId
      paymentType
      subscriptionId
      subscriptionItemIds
      priceIds
      billing
      invitedCode
      createdAt
      updatedAt
    }
  }
`;
export const createSaaSDeliveryTarget = /* GraphQL */ `
  mutation CreateSaaSDeliveryTarget(
    $input: CreateSaaSDeliveryTargetInput!
    $condition: ModelSaaSDeliveryTargetConditionInput
  ) {
    createSaaSDeliveryTarget(input: $input, condition: $condition) {
      id
      ownerId
      shopId
      title
      areaOption {
        areaType
        meshs
      }
      stayOption {
        weekDays
        hours
        meshs
      }
      stationOption
      cityOption {
        prefCode
        cityCode
        prefName
        cityName
      }
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      users
      editState
      archive
      createdAt
      updatedAt
    }
  }
`;
export const updateSaaSDeliveryTarget = /* GraphQL */ `
  mutation UpdateSaaSDeliveryTarget(
    $input: UpdateSaaSDeliveryTargetInput!
    $condition: ModelSaaSDeliveryTargetConditionInput
  ) {
    updateSaaSDeliveryTarget(input: $input, condition: $condition) {
      id
      ownerId
      shopId
      title
      areaOption {
        areaType
        meshs
      }
      stayOption {
        weekDays
        hours
        meshs
      }
      stationOption
      cityOption {
        prefCode
        cityCode
        prefName
        cityName
      }
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      users
      editState
      archive
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaaSDeliveryTarget = /* GraphQL */ `
  mutation DeleteSaaSDeliveryTarget(
    $input: DeleteSaaSDeliveryTargetInput!
    $condition: ModelSaaSDeliveryTargetConditionInput
  ) {
    deleteSaaSDeliveryTarget(input: $input, condition: $condition) {
      id
      ownerId
      shopId
      title
      areaOption {
        areaType
        meshs
      }
      stayOption {
        weekDays
        hours
        meshs
      }
      stationOption
      cityOption {
        prefCode
        cityCode
        prefName
        cityName
      }
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      users
      editState
      archive
      createdAt
      updatedAt
    }
  }
`;
export const createSaaSAnalytics = /* GraphQL */ `
  mutation CreateSaaSAnalytics(
    $input: CreateSaaSAnalyticsInput!
    $condition: ModelSaaSAnalyticsConditionInput
  ) {
    createSaaSAnalytics(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateSaaSAnalytics = /* GraphQL */ `
  mutation UpdateSaaSAnalytics(
    $input: UpdateSaaSAnalyticsInput!
    $condition: ModelSaaSAnalyticsConditionInput
  ) {
    updateSaaSAnalytics(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaaSAnalytics = /* GraphQL */ `
  mutation DeleteSaaSAnalytics(
    $input: DeleteSaaSAnalyticsInput!
    $condition: ModelSaaSAnalyticsConditionInput
  ) {
    deleteSaaSAnalytics(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createSaaSUserInfo = /* GraphQL */ `
  mutation CreateSaaSUserInfo(
    $input: CreateSaaSUserInfoInput!
    $condition: ModelSaaSUserInfoConditionInput
  ) {
    createSaaSUserInfo(input: $input, condition: $condition) {
      id
      email
      familyName
      givenName
      agentCode
      reseller
      sendReportEmail {
        endOfDelivery
        shortBalance
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSaaSUserInfo = /* GraphQL */ `
  mutation UpdateSaaSUserInfo(
    $input: UpdateSaaSUserInfoInput!
    $condition: ModelSaaSUserInfoConditionInput
  ) {
    updateSaaSUserInfo(input: $input, condition: $condition) {
      id
      email
      familyName
      givenName
      agentCode
      reseller
      sendReportEmail {
        endOfDelivery
        shortBalance
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaaSUserInfo = /* GraphQL */ `
  mutation DeleteSaaSUserInfo(
    $input: DeleteSaaSUserInfoInput!
    $condition: ModelSaaSUserInfoConditionInput
  ) {
    deleteSaaSUserInfo(input: $input, condition: $condition) {
      id
      email
      familyName
      givenName
      agentCode
      reseller
      sendReportEmail {
        endOfDelivery
        shortBalance
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSaaSAccessManage = /* GraphQL */ `
  mutation CreateSaaSAccessManage(
    $input: CreateSaaSAccessManageInput!
    $condition: ModelSaaSAccessManageConditionInput
  ) {
    createSaaSAccessManage(input: $input, condition: $condition) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateSaaSAccessManage = /* GraphQL */ `
  mutation UpdateSaaSAccessManage(
    $input: UpdateSaaSAccessManageInput!
    $condition: ModelSaaSAccessManageConditionInput
  ) {
    updateSaaSAccessManage(input: $input, condition: $condition) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteSaaSAccessManage = /* GraphQL */ `
  mutation DeleteSaaSAccessManage(
    $input: DeleteSaaSAccessManageInput!
    $condition: ModelSaaSAccessManageConditionInput
  ) {
    deleteSaaSAccessManage(input: $input, condition: $condition) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const acceptAccessManage = /* GraphQL */ `
  mutation AcceptAccessManage($input: AccessManageInput) {
    acceptAccessManage(input: $input) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
