import React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {CommonStyles} from '../../../../theme';
import {Text, TrimaButton} from '../../../Elements';
import {CitySearchContainer} from '../../CitySearchContainer';
import {TargetCounter} from '../../Counters';
import {TargetingContainer} from '../../TargetingContainer';
import {PrefectureContainer} from './PrefectureContainer';
import PrefectureSelector from './PrefectureSelector';

const PrefectureSetting: React.FC = React.memo(() => {
  return (
    <View style={CommonStyles.fullHeight}>
      <View style={CommonStyles.margin.all}>
        <Text>市区町村を設定</Text>
      </View>
      <Counter />
      <ScrollView style={CommonStyles.margin.all}>
        <PrefectureSelector />
      </ScrollView>
      <ConfirmButton />
    </View>
  );
});

const ConfirmButton: React.FC = () => {
  const {confirmPrefecture} = TargetingContainer.useContainer();
  const {canConfirm, clearExpand} = PrefectureContainer.useContainer();
  const {clearSearch} = CitySearchContainer.useContainer();
  return (
    <View style={[CommonStyles.margin.horizontal, CommonStyles.margin.bottom]}>
      <TrimaButton
        variant="contained"
        disabled={!canConfirm}
        onClick={() => {
          confirmPrefecture();
          clearExpand();
          clearSearch();
        }}
      >
        確定
      </TrimaButton>
    </View>
  );
};

const Counter: React.FC = () => {
  const {targetingUserCount} = TargetingContainer.useContainer();
  return (
    <View style={styles.counter}>
      <TargetCounter count={targetingUserCount} />
    </View>
  );
};

const styles = StyleSheet.create({
  counter: {height: 60},
});

export default PrefectureSetting;
