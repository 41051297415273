import lodash from 'lodash';
import {UseFormGetValues} from 'react-hook-form';
import {AreaType, Gender, MovingMethod, Residence} from '../../API';
import {UserOptionDto} from '../../_proto/services/helpers/UserPropHelper';
import {
  RadioInfos,
  STRING_LIMIT,
  SelectProps,
  TextInputProps,
  yup,
  yupUtil,
} from '../../components/Elements';
import {CheckboxProps} from '../Elements/Forms';
import {getDtoUserOption} from './convert';

// メッシュ、駅の選択数上限
export const CHOICE_LIMIT = 3000;

// ラジオボタンで選択するユーザー属性 : 性別、フォロワー、来店記録、既婚/未婚
const radioAttribute = ['gender', 'married'] as const;
type RadioAttribute = (typeof radioAttribute)[number];
// チェックボックスで選択するユーザー属性 ： 主な移動手段、居住形態
const checkAttribute = [
  'movingMethod',
  'residence',
  'job',
  'interest',
] as const;
type CheckAttribute = (typeof checkAttribute)[number];
// プルダウンで選択するユーザー属性：年齢（範囲）、同居の子供人数、同居の末子の年齢（範囲）、年収（範囲）、世帯年収（範囲）
const selectAttribute = [
  'ageRange',
  'children',
  'income',
  'householdIncome',
  'familyTogether',
] as const;
type SelectAttribute = (typeof selectAttribute)[number];
// エリア利用有無
export type AreaMode = 'allArea' | 'prefecture' | 'areaTarget';
// エリア系属性（ prefecture はUX上はエリアだけど、データ上はユーザー属性のため注意）
export type Area = 'areaResidence' | 'areaStay' | 'station' | 'prefecture';

// ユーザー属性全部
export type UserAttribute = RadioAttribute | SelectAttribute | CheckAttribute;
// エリアも含めて
export type TargetOptionType = UserAttribute | Area;

// 各設定値が有効にされているかどうか
export type AttrActiveStatus = {[P in TargetOptionType]?: boolean};

// エリア以外のユーザー属性が全部設定されているかどうか
export function isAllUserPropEnabled(
  status: AttrActiveStatus | undefined,
): boolean {
  if (!status) {
    return false;
  }
  const picked = lodash.pick(
    status,
    checkAttribute,
    radioAttribute,
    selectAttribute,
  );
  const arr = Object.values(picked).filter((v) => v);
  return !(
    arr.length <
    checkAttribute.length + radioAttribute.length + selectAttribute.length
  );
}

// フォーム
export type RadiusForm = {
  radius?: number; // 半径選択用
};

export type AttrForm = {
  // 使用有無
  use?: AttrActiveStatus;
  // 居住地
  areaResidence?: AreaType;
  // 滞在履歴
  areaStay?: {
    sunday?: boolean;
    monday?: boolean;
    tuesday?: boolean;
    wednesday?: boolean;
    thursday?: boolean;
    friday?: boolean;
    saturday?: boolean;
    hourFrom?: number;
    hourTo?: number;
  };
  // 都道府県 本当は number[] がいいのだが、そうするとどうもネスト上限のためにtypescriptのビルドが通らなくなるのでstringにdumpする
  areaPrefecture?: string;
  // ラジオボタン系
  gender?: Gender;
  married?: boolean;
  // チェックボックス系（チェックボックスは1つ1つがフォーム）
  movingMethod?: {
    train?: boolean;
    bus?: boolean;
    walk?: boolean;
    car?: boolean;
    bicycle?: boolean;
    motorcycle?: boolean;
  };
  residence?: {
    myHouse?: boolean;
    myApartment?: boolean;
    rentHouse?: boolean;
    rentApartment?: boolean;
    companyHousing?: boolean;
  };
  // ドロップダウン系
  ageRange?: {
    lower?: number;
    upper?: number;
  };
  children?: {
    together?: number;
    lower?: number;
    upper?: number;
  };
  income?: {
    lower?: number;
    upper?: number;
  };
  householdIncome?: {
    lower?: number;
    upper?: number;
  };
  familyTogether?: number;
  job?: {
    officer?: boolean; //経営者役員: 1;
    employee?: boolean; // 会社員: 2;
    temporaryEmployee?: boolean; //契約社員派遣社員: 3;
    partTime?: boolean; // パートアルバイト: 4;
    civilServant?: boolean; //'公務員（教職員除く）': 5;
    teacher?: boolean; //教職員: 6;
    medical?: boolean; // 医療関係者: 7;
    freelance?: boolean; //自営業自由業: 8;
    house?: boolean; // 専業主婦主夫: 9;
    universityStudent?: boolean; //'学生（大学生大学院生）': 10;
    collegeStudent?: boolean; //'学生（専門学校生短大生）': 11;
    highSchoolStudent?: boolean; // '学生（高校生以下）': 12;
    occupation?: boolean; // '士業（公認会計士弁護士税理士司法書士）': 13;
    unemployed?: boolean; // 無職: 14;
    retirement?: boolean; // 定年退職: 15;
    other?: boolean; // その他: 16;
  };
  interest?: {
    sports?: boolean; // 'スポーツ・アウトドア': 1;
    technology?: boolean; // '家電・PC/スマホ・テクノロジー': 2;
    business?: boolean; // 'ビジネス・自己啓発': 3;
    health?: boolean; // 'フィットネス・健康': 4;
    game?: boolean; // 'ゲーム（PC/スマホ/家庭用）': 5;
    movie?: boolean; // '映画・ドラマ': 6;
    music?: boolean; // '音楽・アート': 7;
    divination?: boolean; // 占い: 8;
    comic?: boolean; // '漫画・アニメ': 9;
    career?: boolean; // 'キャリア・転職': 10;
    pet?: boolean; // ペット: 11;
    nursing?: boolean; // '介護・相続': 12;
    childcare?: boolean; // '出産・育児': 13;
    language?: boolean; // '語学・留学': 14;
    education?: boolean; // '教育・子育て': 15;
    qualification?: boolean; // 資格取得: 16;
    investment?: boolean; // '投資・副業・マネー': 17;
    automobile?: boolean; // '自動車・バイク': 18;
    love?: boolean; // '出会い・恋愛': 19;
    gambling?: boolean; // ギャンブル: 20;
    gourmet?: boolean; // グルメ: 21;
    travel?: boolean; // 旅行: 22;
    fashion?: boolean; // ファッション: 23;
    residence?: boolean; //'住まい・物件購入': 24;
    beauty?: boolean; // '美容・エステ': 25;
    diy?: boolean; // 'ガーデニング・DIY': 26;
  };
} & {
  // 更新用
  id?: string;
  // 管理用タイトル
  title: string;
} & RadiusForm;

export const schemaAttrForm: yup.SchemaOf<AttrForm> = yup.object().shape({
  areaResidence: yup.mixed<AreaType>().oneOf(Object.values(AreaType)),
  areaStay: yup.object({
    sunday: yup
      .boolean()
      .test(
        'weekCheck',
        '１つ以上選択してください',
        (value, context) =>
          value ||
          context.parent.monday ||
          context.parent.tuesday ||
          context.parent.wednesday ||
          context.parent.thursday ||
          context.parent.friday ||
          context.parent.saturday,
      ),
    monday: yup.boolean(),
    tuesday: yup.boolean(),
    wednesday: yup.boolean(),
    thursday: yup.boolean(),
    friday: yup.boolean(),
    saturday: yup.boolean(),
    hourFrom: yupUtil
      .number()
      .test('compareHourTo', '不正な指定です', (value, context) => {
        const hourTo: number | undefined = context.parent.hourTo;
        if (!hourTo || !value) {
          return true;
        }
        return value < hourTo;
      }),
    hourTo: yupUtil.number(),
  }),
  areaPrefecture: yupUtil.string(),
  use: yup.object({}),
  // radio
  gender: yup.mixed<Gender>().oneOf(Object.values(Gender)),
  // .required('選択してください'),
  married: yup.boolean(),
  // checkbox
  movingMethod: yup.object({
    train: yup.boolean(),
    bus: yup.boolean(),
    walk: yup.boolean(),
    car: yup.boolean(),
    bicycle: yup.boolean(),
    motorcycle: yup.boolean(),
  }),
  residence: yup.object({
    myHouse: yup.boolean(),
    myApartment: yup.boolean(),
    rentHouse: yup.boolean(),
    rentApartment: yup.boolean(),
    companyHousing: yup.boolean(),
  }),
  job: yup.object({
    officer: yup.boolean(),
    employee: yup.boolean(),
    temporaryEmployee: yup.boolean(),
    partTime: yup.boolean(),
    civilServant: yup.boolean(),
    teacher: yup.boolean(),
    medical: yup.boolean(),
    freelance: yup.boolean(),
    house: yup.boolean(),
    universityStudent: yup.boolean(),
    collegeStudent: yup.boolean(),
    highSchoolStudent: yup.boolean(),
    occupation: yup.boolean(),
    unemployed: yup.boolean(),
    retirement: yup.boolean(),
    other: yup.boolean(),
  }),
  interest: yup.object({
    sports: yup.boolean(),
    technology: yup.boolean(),
    business: yup.boolean(),
    health: yup.boolean(),
    game: yup.boolean(),
    movie: yup.boolean(),
    music: yup.boolean(),
    divination: yup.boolean(),
    comic: yup.boolean(),
    career: yup.boolean(),
    pet: yup.boolean(),
    nursing: yup.boolean(),
    childcare: yup.boolean(),
    language: yup.boolean(),
    education: yup.boolean(),
    qualification: yup.boolean(),
    investment: yup.boolean(),
    automobile: yup.boolean(),
    love: yup.boolean(),
    gambling: yup.boolean(),
    gourmet: yup.boolean(),
    travel: yup.boolean(),
    fashion: yup.boolean(),
    residence: yup.boolean(),
    beauty: yup.boolean(),
    diy: yup.boolean(),
  }),
  // dropdown
  ageRange: yup.object({
    lower: yupUtil.number(),
    upper: yupUtil.number(),
  }),
  children: yup.object({
    together: yupUtil.number(),
    lower: yupUtil.number(),
    upper: yupUtil.number(),
  }),
  income: yup.object({
    lower: yupUtil.number(),
    upper: yupUtil.number(),
  }),
  householdIncome: yup.object({
    lower: yupUtil.number(),
    upper: yupUtil.number(),
  }),
  familyTogether: yupUtil.number(),
  // text
  id: yupUtil.string(),
  title: yupUtil.stringRequired(STRING_LIMIT.title),
  // radius
  radius: yupUtil.number(),
});

export function getUserOptionWithForm(
  getter: UseFormGetValues<AttrForm>,
  areaMode: AreaMode,
): UserOptionDto | undefined {
  const casted = schemaAttrForm.cast(
    Object.assign({title: ''}, getter()),
  ) as AttrForm | null;
  if (casted) {
    return getDtoUserOption(casted, areaMode);
  }
  return undefined;
}

// フォームに割り当てる際に string 化している関係で
// validation せずに watch で受け取ると stringで渡ってくるパラメータがある。
// 具体的にはRadioかSelectに割り当てるもの（それらの実装の問題だけど保留）。
export type NonValidateRange = {
  lower?: string | number;
  upper?: string | number;
};
export type NonValidateAttrForm = Pick<
  AttrForm,
  | 'use'
  | 'areaResidence'
  | 'areaStay'
  | 'gender'
  | 'movingMethod'
  | 'residence'
  | 'job'
  | 'interest'
  | 'id'
  | 'title'
> & {
  // ラジオボタン系
  married?: boolean | string;
  // ドロップダウン系
  ageRange?: NonValidateRange;
  income?: NonValidateRange;
  householdIncome?: NonValidateRange;
  children?: {
    together?: number | string;
  } & NonValidateRange;
  familyTogether?: number | string;
};

// 各属性のラベル
export const ATTR_LABELS: {[P in UserAttribute]: string} = {
  ageRange: '年齢',
  children: '同居している子供',
  familyTogether: '同居している家族',
  gender: '性別',
  householdIncome: '世帯年収',
  income: '年収',
  married: '既婚／未婚',
  movingMethod: '主な移動手段',
  residence: '居住形態',
  job: '職業',
  interest: '興味関心',
};

// 初期表示
export const USE_DEFAULT: AttrForm = {
  use: {
    gender: true,
    ageRange: true,
    married: false,
    children: false,
    income: false,
    householdIncome: false,
    familyTogether: false,
    residence: false,
    movingMethod: false,
    areaResidence: false,
    areaStay: false,
    station: false,
    prefecture: false,
    job: false,
    interest: false,
  },
  areaResidence: AreaType.BOTH,
  areaStay: {
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    hourFrom: 0,
    hourTo: 24,
  },
  areaPrefecture: undefined,
  // genderは未選択にしたいので定義しない
  married: true,
  ageRange: {
    lower: 0,
    upper: 0,
  },
  children: {
    together: 0,
    lower: 0,
    upper: 0,
  },
  income: {
    lower: 0,
    upper: 0,
  },
  householdIncome: {
    lower: 0,
    upper: 0,
  },
  familyTogether: 1,
  // movingMethod, residence は全部未選択（フィルタなし）がデフォルトなので、定義しなくても大丈夫なはず
  title: '',
  radius: 1,
};

// エリア
export const areaResidenceInfo: SelectProps<Pick<AttrForm, 'areaResidence'>> = {
  name: 'areaResidence',
  label: '対象',
  options: [
    {value: AreaType.BOTH, label: 'このエリアに居住地・勤務地のあるユーザー'},
    {value: AreaType.HOME, label: 'このエリアに居住地のあるユーザー'},
    {value: AreaType.OFFICE, label: 'このエリアに勤務地のあるユーザー'},
  ],
  defaultValue: AreaType.BOTH,
};

// ラジオボタン系属性の選択肢
export const radio: RadioInfos<AttrForm, RadioAttribute> = {
  gender: {
    name: 'gender',
    items: {MALE: '男性', FEMALE: '女性'},
  },
  married: {
    name: 'married',
    items: {
      true: '既婚',
      false: '未婚',
    },
  },
};

// チェックボックス系
// 居住地
export type MovingKey = keyof Required<
  Exclude<AttrForm['movingMethod'], undefined>
>;
// フォームと値の対応表
export const MOVING_TABLE: {[P in MovingKey]: MovingMethod} = {
  bicycle: MovingMethod.BICYCLE,
  bus: MovingMethod.BUS,
  car: MovingMethod.CAR,
  motorcycle: MovingMethod.MOTORCYCLE,
  train: MovingMethod.TRAIN,
  walk: MovingMethod.WALK,
};

export const MOVING_LABELS: {[P in MovingMethod]: string} = {
  TRAIN: '鉄道',
  CAR: '自動車',
  BUS: 'バス',
  BICYCLE: '自転車',
  WALK: '徒歩',
  MOTORCYCLE: 'バイク・原付',
  NONE: '',
  OTHER: '',
};

export const moving: CheckboxProps<Pick<AttrForm, 'movingMethod'>>[] = [
  {name: 'movingMethod.train', label: MOVING_LABELS.TRAIN},
  {name: 'movingMethod.car', label: MOVING_LABELS.CAR},
  {name: 'movingMethod.bus', label: MOVING_LABELS.BUS},
  {name: 'movingMethod.bicycle', label: MOVING_LABELS.BICYCLE},
  {name: 'movingMethod.walk', label: MOVING_LABELS.WALK},
  {name: 'movingMethod.motorcycle', label: MOVING_LABELS.MOTORCYCLE},
];

// 居住形態
// 居住形態属性のリスト
export type ResidenceKey = keyof Required<
  Exclude<AttrForm['residence'], undefined>
>;
export const RESIDENCE_TABLE: {[P in ResidenceKey]: Residence} = {
  myHouse: Residence.MY_HOUSE,
  myApartment: Residence.MY_APARTMENT,
  rentHouse: Residence.RENT_HOUSE,
  rentApartment: Residence.RENT_APARTMENT,
  companyHousing: Residence.COMPANY_HOUSING,
};
export const RESIDENCE_LABELS: {[P in Residence]: string} = {
  MY_HOUSE: '持ち家（一戸建て）',
  MY_APARTMENT: '持ち家（マンション等集合住宅）',
  RENT_HOUSE: '賃貸（一戸建て）',
  RENT_APARTMENT: '賃貸（マンション等集合住宅）',
  COMPANY_HOUSING: '社宅・寮・公務員住宅',
  OTHER: '',
};
export const residences: CheckboxProps<Pick<AttrForm, 'residence'>>[] = [
  {name: 'residence.myHouse', label: RESIDENCE_LABELS.MY_HOUSE},
  {name: 'residence.myApartment', label: RESIDENCE_LABELS.MY_APARTMENT},
  {name: 'residence.rentHouse', label: RESIDENCE_LABELS.RENT_HOUSE},
  {name: 'residence.rentApartment', label: RESIDENCE_LABELS.RENT_APARTMENT},
  {
    name: 'residence.companyHousing',
    label: RESIDENCE_LABELS.COMPANY_HOUSING,
  },
];
// 職業
// 職業属性リスト
export type JobKey = keyof Required<Exclude<AttrForm['job'], undefined>>;
export type Job =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16;

// フォームと値の対応表
export const JOB_TABLE: {[P in JobKey]: number} = {
  officer: 1,
  employee: 2,
  temporaryEmployee: 3,
  partTime: 4,
  civilServant: 5,
  teacher: 6,
  medical: 7,
  freelance: 8,
  house: 9,
  universityStudent: 10,
  collegeStudent: 11,
  highSchoolStudent: 12,
  occupation: 13,
  unemployed: 14,
  retirement: 15,
  other: 16,
};

export const JOB_LABELS: {
  [P in Job]: string;
} = {
  1: '経営者役員',
  2: '会社員',
  3: '契約社員派遣社員',
  4: 'パートアルバイト',
  5: '公務員（教職員除く）',
  6: '教職員',
  7: '医療関係者',
  8: '自営業自由業',
  9: '専業主婦主夫',
  10: '学生（大学生大学院生）',
  11: '学生（専門学校生短大生）',
  12: '学生（高校生以下）',
  13: '士業（公認会計士弁護士税理士司法書士）',
  14: '無職',
  15: '定年退職',
  16: 'その他',
};

export const job: CheckboxProps<Pick<AttrForm, 'job'>>[] = [
  {name: 'job.officer', label: JOB_LABELS[1]},
  {name: 'job.employee', label: JOB_LABELS[2]},
  {name: 'job.temporaryEmployee', label: JOB_LABELS[3]},
  {name: 'job.partTime', label: JOB_LABELS[4]},
  {name: 'job.civilServant', label: JOB_LABELS[5]},
  {name: 'job.teacher', label: JOB_LABELS[6]},
  {name: 'job.medical', label: JOB_LABELS[7]},
  {name: 'job.freelance', label: JOB_LABELS[8]},
  {name: 'job.house', label: JOB_LABELS[9]},
  {name: 'job.universityStudent', label: JOB_LABELS[10]},
  {name: 'job.collegeStudent', label: JOB_LABELS[11]},
  {name: 'job.highSchoolStudent', label: JOB_LABELS[12]},
  {name: 'job.occupation', label: JOB_LABELS[13]},
  {name: 'job.unemployed', label: JOB_LABELS[14]},
  {name: 'job.retirement', label: JOB_LABELS[15]},
  {name: 'job.other', label: JOB_LABELS[16]},
];

// 興味関心
// 興味関心属性リスト
export type InterestKey = keyof Required<
  Exclude<AttrForm['interest'], undefined>
>;
export type Interest =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26;

// フォームと値の対応表
export const INTEREST_TABLE: {[P in InterestKey]: number} = {
  sports: 1,
  technology: 2,
  business: 3,
  health: 4,
  game: 5,
  movie: 6,
  music: 7,
  divination: 8,
  comic: 9,
  career: 10,
  pet: 11,
  nursing: 12,
  childcare: 13,
  language: 14,
  education: 15,
  qualification: 16,
  investment: 17,
  automobile: 18,
  love: 19,
  gambling: 20,
  gourmet: 21,
  travel: 22,
  fashion: 23,
  residence: 24,
  beauty: 25,
  diy: 26,
};

export const INTEREST_LABELS: {[P in Interest]: string} = {
  1: 'スポーツ・アウトドア',
  2: '家電・PC/スマホ・テクノロジー',
  3: 'ビジネス・自己啓発',
  4: 'フィットネス・健康',
  5: 'ゲーム（PC/スマホ/家庭用）',
  6: '映画・ドラマ',
  7: '音楽・アート',
  8: '占い',
  9: '漫画・アニメ',
  10: 'キャリア・転職',
  11: 'ペット',
  12: '介護・相続',
  13: '出産・育児',
  14: '語学・留学',
  15: '教育・子育て',
  16: '資格取得',
  17: '投資・副業・マネー',
  18: '自動車・バイク',
  19: '出会い・恋愛',
  20: 'ギャンブル',
  21: 'グルメ',
  22: '旅行',
  23: 'ファッション',
  24: '住まい・物件購入',
  25: '美容・エステ',
  26: 'ガーデニング・DIY',
};

export const interest: CheckboxProps<Pick<AttrForm, 'interest'>>[] = [
  {name: 'interest.sports', label: INTEREST_LABELS[1]},
  {name: 'interest.technology', label: INTEREST_LABELS[2]},
  {name: 'interest.business', label: INTEREST_LABELS[3]},
  {name: 'interest.health', label: INTEREST_LABELS[4]},
  {name: 'interest.game', label: INTEREST_LABELS[5]},
  {name: 'interest.movie', label: INTEREST_LABELS[6]},
  {name: 'interest.music', label: INTEREST_LABELS[7]},
  {name: 'interest.divination', label: INTEREST_LABELS[8]},
  {name: 'interest.comic', label: INTEREST_LABELS[9]},
  {name: 'interest.career', label: INTEREST_LABELS[10]},
  {name: 'interest.pet', label: INTEREST_LABELS[11]},
  {name: 'interest.nursing', label: INTEREST_LABELS[12]},
  {name: 'interest.childcare', label: INTEREST_LABELS[13]},
  {name: 'interest.language', label: INTEREST_LABELS[14]},
  {name: 'interest.education', label: INTEREST_LABELS[15]},
  {name: 'interest.qualification', label: INTEREST_LABELS[16]},
  {name: 'interest.investment', label: INTEREST_LABELS[17]},
  {name: 'interest.automobile', label: INTEREST_LABELS[18]},
  {name: 'interest.love', label: INTEREST_LABELS[19]},
  {name: 'interest.gambling', label: INTEREST_LABELS[20]},
  {name: 'interest.gourmet', label: INTEREST_LABELS[21]},
  {name: 'interest.travel', label: INTEREST_LABELS[22]},
  {name: 'interest.fashion', label: INTEREST_LABELS[23]},
  {name: 'interest.residence', label: INTEREST_LABELS[24]},
  {name: 'interest.beauty', label: INTEREST_LABELS[25]},
  {name: 'interest.diy', label: INTEREST_LABELS[26]},
];

export const WEEK_ARRAY = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const;

export const weekList: CheckboxProps<Pick<AttrForm, 'areaStay'>>[] = [
  {name: 'areaStay.sunday', label: '日', defaultValue: true},
  {name: 'areaStay.monday', label: '月', defaultValue: true},
  {name: 'areaStay.tuesday', label: '火', defaultValue: true},
  {name: 'areaStay.wednesday', label: '水', defaultValue: true},
  {name: 'areaStay.thursday', label: '木', defaultValue: true},
  {name: 'areaStay.friday', label: '金', defaultValue: true},
  {name: 'areaStay.saturday', label: '土', defaultValue: true},
];

// ドロップダウン系（数値の選択肢が多いので、mapを使っての生成が中心
// 自分の年齢
const myAgeOptions: Omit<SelectProps<Pick<AttrForm, 'ageRange'>>, 'name'> = {
  options: [...Array(81)]
    .map((_, index) => {
      return {value: String(index), label: index ? String(index) : '指定なし'};
    })
    .filter((_, index) => index === 0 || index >= 12),

  defaultValue: '0',
};
export const ageUpper: SelectProps<Pick<AttrForm, 'ageRange'>> = Object.assign(
  {name: 'ageRange.upper' as const},
  myAgeOptions,
);
export const ageLower: SelectProps<Pick<AttrForm, 'ageRange'>> = Object.assign(
  {name: 'ageRange.lower' as const},
  myAgeOptions,
);
// 子供
export const childrenTogether: SelectProps<Pick<AttrForm, 'children'>> = {
  name: 'children.together',
  options: [...Array(6)].map((_, index) => {
    return {value: String(index), label: index ? `${index}人以上` : 'なし'};
  }),
};
export const childrenLower: SelectProps<Pick<AttrForm, 'children'>> = {
  name: 'children.lower',
  options: [...Array(21)].map((_, index) => {
    return {value: String(index), label: String(index)};
  }),
};
export const childrenUpper: SelectProps<Pick<AttrForm, 'children'>> = {
  name: 'children.upper',
  options: [...Array(21)].map((_, index) => {
    return {value: String(index), label: index ? String(index) : '指定なし'};
  }),
};
// 年収
export const incomeLower: SelectProps<Pick<AttrForm, 'income'>> = {
  name: 'income.lower',
  options: [...Array(17)]
    // 0, 1, 100, 200, ...
    .map((_, index) => (index > 1 ? (index - 1) * 100 : index))
    .map((value) => {
      return {
        value: String(value),
        label: !value
          ? '収入なし'
          : value === 1
          ? `${value}円`
          : `${value}万円`,
      };
    })
    .filter((_, index) => index <= 11 || index === 16),
};
export const incomeUpper: SelectProps<Pick<AttrForm, 'income'>> = {
  name: 'income.upper',
  options: [...Array(16)]
    .map((_, index) => index * 100)
    .map((value) => {
      return {
        value: String(value),
        label: value ? `${value}万円` : '指定なし',
      };
    })
    .filter((_, index) => index <= 10 || index === 15),
};
export const householdLower: SelectProps<Pick<AttrForm, 'householdIncome'>> = {
  name: 'householdIncome.lower',
  options: [...Array(21)]
    .map((_, index) => index * 100)
    .map((value) => {
      return {
        value: String(value),
        label: value ? `${value}万円` : `${value}円`,
      };
    })
    .filter((_, index) => index <= 10 || index === 15 || index === 20),
};
export const householdUpper: SelectProps<Pick<AttrForm, 'householdIncome'>> = {
  name: 'householdIncome.upper',
  options: [...Array(21)]
    .map((_, index) => index * 100)
    .map((value) => {
      return {
        value: String(value),
        label: value ? `${value}万円` : '指定なし',
      };
    })
    .filter((_, index) => index <= 10 || index === 15 || index === 20),
};
// 同居家族
export const family: SelectProps<Pick<AttrForm, 'familyTogether'>> = {
  name: 'familyTogether',
  options: [...Array(7)]
    .map((_, index) => index + 1)
    .map((i) => {
      return {value: String(i), label: i === 1 ? '1人暮らし' : `${i}人以上`};
    }),
};
// 時間帯
export const hourFrom: SelectProps<Pick<AttrForm, 'areaStay'>> = {
  name: 'areaStay.hourFrom',
  options: [...Array(24)].map((_, index) => ({
    value: String(index),
    label: String(index),
  })),
  defaultValue: '0',
};
export const hourTo: SelectProps<Pick<AttrForm, 'areaStay'>> = {
  name: 'areaStay.hourTo',
  options: [...Array(24)].map((_, index) => ({
    value: String(index + 1),
    label: String(index + 1),
  })),
  defaultValue: '24',
};

// 管理用タイトル
export const title: TextInputProps<Pick<AttrForm, 'title'>> = {
  name: 'title',
  label: 'タイトル（管理用）',
  placeholder: '周辺在住または勤務の20～39歳',
  maxLength: STRING_LIMIT.title,
  required: true,
};

// 半径選択用
export const RADIUS_DEFAULT: RadiusForm = {radius: 1};

export const radiusInfo: SelectProps<RadiusForm> = {
  name: 'radius',
  options: [...Array(10)]
    .map((_, i) => i + 1) // 1~10
    .map((v) => ({
      value: String(v),
      label: `${v} km`,
    })),
  defaultValue: String(RADIUS_DEFAULT.radius),
};
