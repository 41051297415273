import {API, Auth, graphqlOperation} from 'aws-amplify';
import axios, {AxiosResponse} from 'axios';
import {saaSShopByOwnerId} from '../../graphql/queries';
import {AppConfig} from '../config/AppConfig';
import {
  SaaSAddressDto,
  SaaSImageContents,
  SaaSRepository,
} from './SaaSRepository';

const TAG = 'SaaSShopRepository';

type SaaSShopDef = {
  name: string;
  address: SaaSAddressDto;
  phone?: string;
  icon: string;
  location?: LocationDto;

  biztimes?: string;
  holidays?: string;
  text?: string;
  category?: string;
  url?: string;
  legalUrl?: string;
  ownerId?: string;
  groupId?: string | null;
};

export type LocationDto = {
  lon: number;
  lat: number;
};

export type SaaSShopDto = {
  id?: string;
  name: string;
  text: string;
  images: SaaSImageContents[];
  address: SaaSAddressDto;
  phone?: string;
  biztimes: string;
  holidays: string;
  url: string;
  legalUrl?: string;
  category: string;
  icon?: string;
  location?: LocationDto;
  ownerId: string;
  groupId?: string | null;
};

export class SaaSShopRepository {
  static async fetchShops(): Promise<any> {
    const user = await Auth.currentUserPoolUser();
    console.log(TAG, 'user', user);

    try {
      const result: any = await API.graphql(
        graphqlOperation(saaSShopByOwnerId, {ownerId: user.username}),
      );
      console.log(TAG, 'shops', result);
      return (
        result.data &&
        result.data.saaSShopByOwnerId &&
        result.data.saaSShopByOwnerId.items
      );
    } catch (err: any) {
      console.error(err);
    }
    return null;
  }

  static async postShop(shop: SaaSShopDto): Promise<AxiosResponse<any>> {
    console.log(TAG, shop);

    const data = await SaaSShopRepository.buildFormData(shop);
    const config = await SaaSRepository.formAxioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.ShopEndPoint}`,
      data,
      config,
    );
    console.log(TAG, 'upload', result);

    return result;
  }

  static async updateShop(shop: SaaSShopDto): Promise<AxiosResponse<any>> {
    console.log(TAG, shop);

    const data = await SaaSShopRepository.buildFormData(shop);
    const config = await SaaSRepository.formAxioConfig();

    const result = await axios.put(
      `${AppConfig.SaaSBackend}${AppConfig.ShopEndPoint}/${shop.id}`,
      data,
      config,
    );
    console.log(TAG, 'update', result);

    return result;
  }

  static async remove(shop: {id: string}): Promise<any> {
    console.log(TAG, shop);

    const config = await SaaSRepository.axioConfig();

    const result = await axios.delete(
      `${AppConfig.SaaSBackend}${AppConfig.ShopEndPoint}/${shop.id}`,
      config,
    );

    console.log(TAG, 'delete', result);
    return result;
  }

  // ----------- private -------------
  private static async buildFormData(shop: SaaSShopDto): Promise<FormData> {
    const data = new FormData();

    const def: SaaSShopDef = {
      name: shop.name,
      address: {...shop.address},
      phone: shop.phone,
      icon: shop.icon || '',
      text: shop.text === '' ? undefined : shop.text,
      biztimes: shop.biztimes === '' ? undefined : shop.biztimes,
      holidays: shop.holidays === '' ? undefined : shop.holidays,
      url: shop.url,
      legalUrl: shop.legalUrl,
      category: shop.category === '' ? undefined : shop.category,

      location: shop.location,
      ownerId: shop.ownerId,
    };
    if (shop.groupId) {
      // groupIdがGSIのため、nullを回避
      def.groupId = shop.groupId;
    }

    data.append('json', new Blob([JSON.stringify(def)]), 'shop');
    await Promise.all(
      shop.images.map(async (image) => {
        const blob = new Blob([image.uri]);
        data.append('images', blob, image.key);
      }),
    );
    return data;
  }
}
