import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {EditState, SaaSNews} from '../../API';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {OwnerContainer, ShopContainer} from '../../container';
import {UseImages, useImages} from '../../helper';
import {FormData} from './schema';
import {convert, save} from './service';

type UseNewsCreator = UseImages & {
  saved?: SaaSNews;
  submit(
    data: FormData,
    editState: EditState,
    shopId?: string,
  ): Promise<SaaSNews>;
};

function useNewsCreator(initialState?: {
  images: SaaSImageContents[] | undefined;
  forceState?: EditState;
}): UseNewsCreator {
  const {selected} = ShopContainer.useContainer();
  const {images, addImage, removeImage, changeImage} = useImages(
    initialState?.images,
  );
  const [saved, setSaved] = useState<SaaSNews | undefined>();
  const {owner} = OwnerContainer.useContainer();

  const submit = useCallback<
    (data: FormData, editState: EditState, shopId?: string) => Promise<SaaSNews>
  >(
    async (data, editState, shopId) => {
      const edit = initialState?.forceState || editState;
      const def = convert(
        data,
        edit,
        shopId ? shopId : selected?.id || '',
        owner?.id || '',
      );
      const response = await save({def, images}, data.id);
      // 成功したら結果は保持しておく（上書きの際など必要）
      setSaved(response.data);
      console.log('[news create] setSave', response.data);
      return response.data;
    },
    [initialState?.forceState, selected?.id, owner?.id, images],
  );

  return {
    images,
    addImage,
    removeImage,
    changeImage,
    saved,
    submit,
  };
}

export const NewsCreator = createContainer(useNewsCreator);
