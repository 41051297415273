import {DimensionValue, Platform} from 'react-native';
import {Colors} from './index';

// トリマアプリからとりあえず持ってきている
const headerShadowHeight = 2;
const headerBaseHeight = Platform.OS === 'ios' ? 44 : 56;
const header = {
  headerShown: true,
  headerTitleAllowFontScaling: false,
  headerStyle: {
    // Androidでは影がelevationで制御されてshadow...でデザインができない。
    // そのため両OS共にborderBottomを使う
    elevation: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    borderBottomWidth: headerShadowHeight,
    height: headerBaseHeight + headerShadowHeight,
  },
  // Android/iOSで違うので明示的に指定して揃えておく
  headerTitleAlign: 'center' as 'center' | 'left',
  // https://reactnavigation.org/docs/nesting-navigators/
  // https://reactnavigation.org/docs/drawer-actions/
  headerBackTitleVisible: true,
  // 親のViewの方でtopは考慮するのでtopは無効化
  safeAreaInsets: {
    top: 0,
  },
};

const headerHeight = header.headerStyle.height;

// 以下は汎用スタイル系
const flex = {
  row: {flexDirection: 'row' as const},
  reverse: {flexDirection: 'column-reverse' as const},
  full: {flex: 1},
  center: {justifyContent: 'center' as const, alignItems: 'center' as const},
  // flex方向の寄せ指定
  mainCenter: {justifyContent: 'center' as const},
  between: {justifyContent: 'space-between' as const},
  mainEnd: {justifyContent: 'flex-end' as const},
  // flexと直行する方向の寄せ指定
  crossCenter: {alignItems: 'center' as const},
  crossEnd: {alignItems: 'flex-end' as const},
  crossFull: {alignItems: 'stretch' as const},
};

const align = {
  self: {
    start: {alignSelf: 'flex-start' as const},
    end: {alignSelf: 'flex-end' as const},
    stretch: {alignSelf: 'stretch' as const},
    center: {alignSelf: 'center' as const},
  },
};

const full = {
  width: '100%' as DimensionValue,
  height: '100%' as DimensionValue,
};
const fullWidth = {width: '100%' as DimensionValue};
const fullHeight = {height: '100%' as DimensionValue};

const marginSize = 16;
const marginCompactSize = 4;

const marginCompact = {
  all: {margin: marginCompactSize},
  top: {marginTop: marginCompactSize},
  bottom: {marginBottom: marginCompactSize},
  vertical: {marginVertical: marginCompactSize},
  left: {marginLeft: marginCompactSize},
  right: {marginRight: marginCompactSize},
  horizontal: {marginHorizontal: marginCompactSize},
};

const margin = {
  all: {margin: marginSize},
  top: {marginTop: marginSize},
  bottom: {marginBottom: marginSize},
  vertical: {marginVertical: marginSize},
  left: {marginLeft: marginSize},
  right: {marginRight: marginSize},
  horizontal: {marginHorizontal: marginSize},
};
const padding = {
  all: {padding: marginSize},
  top: {paddingTop: marginSize},
  bottom: {paddingBottom: marginSize},
  vertical: {paddingTop: marginSize, paddingBottom: marginSize},
  horizontal: {paddingLeft: marginSize, paddingRight: marginSize},
};

const linkText = {
  color: 'blue',
  textDecorationLine: 'underline' as const,
};

const formBlock = {
  ...padding.all,
  backgroundColor: Colors.lightgray,
  borderRadius: 8,
};

const maxWidth = {
  list: 568, // リスト系とかの最大幅
};

const text = {
  center: {textAlign: 'center' as const},
};

// 表・グラフ関係
const dataTable = {width: 280};
const borderless = {borderWidth: 0};

export default {
  header,
  headerHeight,
  headerBaseHeight,
  full,
  fullWidth,
  fullHeight,
  flex,
  align,
  margin,
  marginCompact,
  padding,
  linkText,
  formBlock,
  maxWidth,
  text,
  dataTable,
  borderless,
};
