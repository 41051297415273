import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Close,
  CreditCard,
  Delete,
  Edit as EditIcon,
  MoreVert,
} from '@mui/icons-material';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  List as MuiList,
  Menu as MuiMenu,
  Typography,
} from '@mui/material';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {Linking, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {AccessManageRole, PaymentType} from '../../API';
import {SaaSBillingRepository} from '../../_proto/services/SaaSBillingRepository';
import {
  BackLink,
  Container,
  FormBlock,
  HelperText,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VMargin,
} from '../../components/Elements';
import {CardForm} from '../../components/Payment/CardForm';
import {
  EditCardDialog,
  EditFormInput,
} from '../../components/Payment/EditCardDialog';
import {PaymentMethodContainer} from '../../components/Payment/PaymentMethodContainer';
import {AccessRoleContainer, PaymentInfoContainer} from '../../container';
import {useCustomNav} from '../../helper';
import {Colors, CommonStyles} from '../../theme';

type PaymentMethod = {
  billing_details: {
    name: string;
  };
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };
  default: boolean;
  id: string;
};

const ConfirmDialog: React.FC<{
  item: PaymentMethod;
  visible: boolean;
  onConfirm(): Promise<void>;
  onClose(): void;
}> = ({item, visible, onConfirm, onClose}) => {
  const [processing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const {card} = item;
  const handleCancel = () => {
    onClose();
  };

  const handleOk = async () => {
    setProcessing(true);
    setError(null);
    try {
      await onConfirm();
      // setProcessing(false);
      // onClose();
    } catch (err: any) {
      console.log(err);
      setProcessing(false);
      setError(Error('削除できませんでした'));
    }
  };

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: Colors.lightgray,
        },
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={handleCancel}
      open={visible}>
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>お支払い方法の削除</Box>
          <Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <View>
          <Text>
            {card.brand.toUpperCase()} ****{card.last4} を削除しますか？
          </Text>
          <VMargin />
          <VMargin />
          <TrimaLoadingButton
            color="error"
            variant="contained"
            disabled={processing}
            loading={processing}
            loadingPosition="start"
            onClick={handleOk}>
            削除
          </TrimaLoadingButton>
          <HelperText type="error">{error?.message}</HelperText>
          <TrimaButton variant="outlined" onClick={handleCancel}>
            キャンセル
          </TrimaButton>
        </View>
      </DialogContent>
      <VMargin />
    </Dialog>
  );
};

const AddButton: React.FC<{
  onPress(): void;
}> = ({onPress}) => {
  return (
    <Box pt={2}>
      <TrimaButton
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={onPress}>
        お支払い方法を追加
      </TrimaButton>
    </Box>
  );
};

const CardDialog: React.FC<{
  visible: boolean;
  onClose(): void;
}> = ({visible, onClose}) => {
  const {paymentInfo} = PaymentInfoContainer.useContainer();
  const {getData} = PaymentMethodContainer.useContainer();
  const handleCancel = () => {
    onClose();
  };

  const handleOk = async () => {
    const data = {
      customerId: paymentInfo.customerId,
    };
    await getData(data);
    onClose();
  };

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: Colors.lightgray,
        },
      }}
      onClose={handleCancel}
      open={visible}>
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>お支払い方法の追加</Box>
          <Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <View>
          <CardForm onClick={handleOk} isAddCard={true} />
          <VMargin />
        </View>
      </DialogContent>
    </Dialog>
  );
};

const PaymentMethodListItem: React.FC<{
  paymentMethod: PaymentMethod;
}> = React.memo(({paymentMethod}) => {
  const {paymentInfo} = PaymentInfoContainer.useContainer();
  const {getData, editData, removeData} = PaymentMethodContainer.useContainer();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [removeVisible, setRemoveVisible] = React.useState<boolean>(false);
  const [editVisible, setEditVisible] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showRemoveDialog = () => {
    handleClose();
    setRemoveVisible(true);
  };
  const hideRemoveDialog = () => {
    setRemoveVisible(false);
  };
  const showEditDialog = () => {
    handleClose();
    setEditVisible(true);
  };
  const hideEditDialog = () => {
    setEditVisible(false);
  };

  const setDefault = async () => {
    handleClose();
    const data = {
      customerId: paymentInfo.customerId,
      defaultPaymentMethodId: paymentMethod.id,
    };
    await SaaSBillingRepository.updateCustomer(data);
    await getData(data);
  };

  const edit = async (formData: EditFormInput) => {
    const data = {
      customerId: paymentInfo.customerId,
      paymentMethodId: paymentMethod.id,
      billing_details: {
        name: formData.name,
      },
      card: {
        exp_month: Number(formData.exp_month),
        exp_year: formData.exp_year + 2000,
      },
    };
    await editData(data);
  };

  const remove = async () => {
    const data = {
      customerId: paymentInfo.customerId,
      paymentMethodId: paymentMethod.id,
    };
    await removeData(data);
  };

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          id={paymentMethod.id}
          primary={
            <>
              <Box display="flex" flexDirection="row">
                <Typography variant="h6" component="span">
                  {paymentMethod.card.brand.toUpperCase()} ****
                  {paymentMethod.card.last4}
                </Typography>
                {paymentMethod.default && (
                  <Box ml={1}>
                    <Chip size="small" label="デフォルト" color="primary" />
                  </Box>
                )}
              </Box>
              <Box pt={1}>
                <Typography
                  variant="subtitle2"
                  component="span"
                  display="block">
                  {paymentMethod.billing_details.name}
                </Typography>
                <Typography
                  variant="caption"
                  component="span"
                  display="block"
                  color="textSecondary">{`有効期限 ${paymentMethod.card.exp_month
                  .toString()
                  .padStart(2, '0')}/${
                  paymentMethod.card.exp_year
                }`}</Typography>
              </Box>
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreVert color="primary" />
          </IconButton>
          <MuiMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={setDefault} disabled={paymentMethod.default}>
              <ListItemIcon>
                <CreditCard fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="inherit" color="primary">
                デフォルトに設定
              </Typography>
            </MenuItem>
            <MenuItem onClick={showEditDialog}>
              <ListItemIcon>
                <EditIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="inherit" color="primary">
                編集する
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={showRemoveDialog}
              disabled={paymentMethod.default}>
              <ListItemIcon>
                <Delete fontSize="small" color="primary" />
              </ListItemIcon>
              <Typography variant="inherit" color="primary">
                削除する
              </Typography>
            </MenuItem>
          </MuiMenu>
        </ListItemSecondaryAction>
      </ListItem>
      <ConfirmDialog
        item={paymentMethod}
        visible={removeVisible}
        onConfirm={remove}
        onClose={hideRemoveDialog}
      />
      <EditCardDialog
        item={paymentMethod}
        visible={editVisible}
        onConfirm={(data) => edit(data)}
        onClose={hideEditDialog}
      />
    </>
  );
});

const ChangePaymentComponent: React.FC = React.memo(() => {
  const {paymentInfo} = PaymentInfoContainer.useContainer();

  const {paymentMethods, getData} = PaymentMethodContainer.useContainer();

  const navigator = useCustomNav();
  const goBack = () => {
    navigator.goBack('SettingsMain');
  };

  const [visible, setVisible] = React.useState<boolean>(false);
  const showDialog = () => {
    setVisible(true);
  };
  const hideDialog = () => {
    setVisible(false);
  };

  const [loading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    async function fetchData() {
      if (paymentInfo.paymentType === PaymentType.STRIPE) {
        const data = {
          customerId: paymentInfo.customerId,
        };
        await getData(data);
      }
      setLoading(false);
    }
    fetchData();
  }, [paymentInfo, getData]);

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label="変更せずに戻る" onClick={goBack} />
        </Box>
        <Menu>お支払い方法の変更</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          <MaxWidth maxWidth={586}>
            {loading ? (
              <ActivityIndicator size={60} />
            ) : (
              <View>
                {paymentMethods ? (
                  <View>
                    <View>
                      <MuiList sx={{backgroundColor: Colors.lightgray}}>
                        {paymentMethods.data.map(
                          (
                            paymentMethod: PaymentMethod,
                            index: number,
                            array: Array<PaymentMethod>,
                          ) => {
                            if (index + 1 !== array.length) {
                              return (
                                <React.Fragment key={paymentMethod.id}>
                                  <PaymentMethodListItem
                                    paymentMethod={paymentMethod}
                                  />
                                  <Divider />
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <React.Fragment key={paymentMethod.id}>
                                  <PaymentMethodListItem
                                    paymentMethod={paymentMethod}
                                  />
                                </React.Fragment>
                              );
                            }
                          },
                        )}
                      </MuiList>
                    </View>
                    <AddButton onPress={showDialog} />
                    <CardDialog visible={visible} onClose={hideDialog} />
                    <VMargin />
                    <VMargin />
                    <FormBlock>
                      <Text>
                        他のお支払い方法(銀行振込/口座振替/コンビニ払い)への変更をご希望の場合は、{' '}
                        <Text
                          style={CommonStyles.linkText}
                          onPress={() =>
                            Linking.openURL(
                              'https://support.shop.trip-mile.com/hc/ja',
                            )
                          }>
                          サポートサイト
                        </Text>
                        よりお問い合わせください。
                      </Text>
                    </FormBlock>
                  </View>
                ) : (
                  <View>
                    <Text>
                      Paid(銀行振込/口座振替/コンビニ払い)をご利用中の方
                    </Text>
                    <VMargin />
                    <Text>
                      クレジットカード払いへ変更をご希望の場合は、{' '}
                      <Text
                        style={CommonStyles.linkText}
                        onPress={() =>
                          Linking.openURL(
                            'https://support.shop.trip-mile.com/hc/ja',
                          )
                        }>
                        サポートサイト
                      </Text>
                      よりお問い合わせください。 その他の変更は、{' '}
                      <Text
                        style={CommonStyles.linkText}
                        onPress={() =>
                          Linking.openURL('https://paid.jp/v/do/login')
                        }>
                        Paidの会員ページ
                      </Text>
                      からお願いいたします。
                    </Text>
                  </View>
                )}
                <VMargin />
                <TrimaButton variant="outlined" onClick={() => goBack()}>
                  企業設定に戻る
                </TrimaButton>
              </View>
            )}
          </MaxWidth>
        </View>
      </MaxWidth>
    </Container>
  );
});

export const ChangePayment: React.FC = () => {
  const {accessRole} = AccessRoleContainer.useContainer();
  const navigation = useNavigation();

  useEffect(() => {
    console.log(accessRole);
    if (accessRole !== AccessManageRole.OWNER) {
      navigation.navigate('DashBoard');
    }
  }, [accessRole, navigation]);

  return (
    <PaymentMethodContainer.Provider>
      <ChangePaymentComponent />
    </PaymentMethodContainer.Provider>
  );
};
