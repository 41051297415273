import {AxiosError, AxiosResponse} from 'axios';

type ResponseHandler<T = never> = (response: AxiosResponse<T>) => string;
type ErrorHandler<T = never> = (error: AxiosError<T>) => string;

type CommonErrorResponse = {
  code: string;
  message: string;
};

// Todo: エラーメッセージ
function baseErrorHandler<T = never>(
  error: AxiosError<T>,
  handler: ResponseHandler<T>,
): string {
  if (error.response) {
    return handler(error.response);
  } else if (error.request) {
    return `通信に失敗しました_A。${error.request} ${error?.message}`;
  } else {
    return error.message ? error.message : '通信に失敗しました_B。';
  }
}

const commonHandler: ResponseHandler<CommonErrorResponse> = (response) => {
  if (response.data.code) {
    if (response.data.code === '30010001') {
      return '入力されたメールアドレスは既に登録されています';
    } else {
      return (
        'エラーコード：' + response.data.code + '\n' + response.data.message
      );
    }
  }
  return `通信に失敗しました_C。${response} ${response.data?.message}`;
};

const commonErrorHandler: ErrorHandler<CommonErrorResponse> = (error) => {
  return baseErrorHandler<CommonErrorResponse>(error, commonHandler);
};

export const axiosHelper = {
  commonErrorHandler,
};
