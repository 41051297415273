import {Link} from '@mui/material';
import parsePhoneNumber from 'libphonenumber-js';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Colors, CommonStyles, trimaColor} from '../../theme';
import {AppText, FontAwesomeIcon, Text} from '../Elements';

/**
 * トリマアプリ側のスワイプボタンの擬似再現コンポーネント（ボタン機能はない）
 */
export const PrimaryDummyButton = React.memo((props: any) => {
  const {children} = props;
  return (
    <View style={styles.container}>
      <View style={styles.shadow} />
      <View style={styles.body}>
        <AppText style={styles.label}>{children}</AppText>
      </View>
    </View>
  );
});

export const CvLinkDummyButton = React.memo<{text: string; url: string}>(
  ({text, url}) => {
    return (
      <View style={styles.container}>
        <Link href={url} target="_blank" rel="noopener">
          <View style={styles.cvLinkShadow} />
          <View style={styles.cvLinkBody}>
            <AppText style={styles.cvLinkLabel}>{text}</AppText>
          </View>
        </Link>
      </View>
    );
  },
);

export const PhoneDummyButton = React.memo<{phone: string}>(({phone}) => {
  if (!phone) {
    return null;
  }
  const phoneNumber = parsePhoneNumber(phone ?? '', 'JP');
  return (
    <View style={styles.container}>
      <View style={styles.callNumberShadow} />
      <View style={styles.callNumberBody}>
        <View style={CommonStyles.flex.row}>
          <FontAwesomeIcon name="phone" size={24} color={Colors.white} />
          <Text style={styles.callNumberText}>
            {phoneNumber?.formatNational()}
          </Text>
        </View>
        <Text style={styles.callSubText}>「トリマを見た」とお伝えください</Text>
      </View>
    </View>
  );
});

const appleFontRatio = 0.935;
const buttonBaseHeight = 48;
const buttonShadowHeight = 4;
const buttonHeight = buttonBaseHeight + buttonShadowHeight;
const buttonBorderWidth = 1;
const styles = StyleSheet.create({
  container: {
    height: buttonHeight,
  },
  shadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonHeight,
    borderRadius: buttonHeight / 2,
    backgroundColor: trimaColor.shadow,
  },
  body: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonBaseHeight,
    borderWidth: buttonBorderWidth,
    borderRadius: buttonHeight / 2,
    borderColor: trimaColor.shadow,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: 16 * appleFontRatio,
    fontWeight: 'normal',
    color: trimaColor.accent,
  },
  cvLinkShadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonHeight,
    borderRadius: buttonHeight / 2,
    backgroundColor: trimaColor.accentShadow,
  },
  cvLinkBody: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonBaseHeight,
    borderWidth: buttonBorderWidth,
    borderRadius: buttonHeight / 2,
    borderColor: trimaColor.accentShadow,
    backgroundColor: trimaColor.accent,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cvLinkLabel: {
    fontSize: 16 * appleFontRatio,
    fontWeight: 'normal',
    color: Colors.white,
  },
  callNumberShadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonHeight,
    borderRadius: buttonHeight / 2,
    backgroundColor: trimaColor.accentShadow,
  },
  callNumberBody: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonBaseHeight,
    borderWidth: buttonBorderWidth,
    borderRadius: buttonHeight / 2,
    borderColor: trimaColor.accentShadow,
    backgroundColor: trimaColor.accent,
    justifyContent: 'center',
    alignItems: 'center',
  },
  callNumberText: {
    color: Colors.white,
    fontSize: 16 * appleFontRatio,
    marginLeft: 4,
  },
  callSubText: {
    color: Colors.white,
    fontSize: 10,
  },
});
