import dayjs from 'dayjs';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSShop} from '../../API';
import {OwnerContainer} from '../../container';
import {UseImages, useImages} from '../../helper';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {SaaSShopRepository} from '../../_proto/services/SaaSShopRepository';
import {convert, ShopFormData, ShopImages} from './shopSchema';

// 画像はreact-hook-formで管理もできなくはなさそうだが、とりあえずコンテナで。
type UseShopRegister = UseImages & {
  icon?: SaaSImageContents;
  isLoading: boolean;
  addIcon(uri: string): void;
  removeIcon(): void;
  changeIcon(uri: string): void;
  /**
   * フォームの入力データをサーバーに登録する
   * @param data フォームの入力
   * @throws 失敗時
   */
  createShop(data: ShopFormData & {groupId?: string}): Promise<SaaSShop>;
};

// 店舗イメージ＆アイコンの管理とサーバーへの登録・更新処理
function useShopRegister(initialState?: ShopImages): UseShopRegister {
  const {images, addImage, removeImage, changeImage} = useImages(
    initialState?.images,
  );
  const [icon, setIcon] = useState<SaaSImageContents | undefined>(
    initialState?.icon,
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const {owner} = OwnerContainer.useContainer();

  const addIcon = useCallback((uri: string) => {
    const key = dayjs().unix().toString();
    setIcon({uri, key});
  }, []);

  const removeIcon = useCallback(() => {
    setIcon(undefined);
  }, []);

  const changeIcon = useCallback((uri: string) => {
    const key = dayjs().unix().toString();
    setIcon({uri, key});
  }, []);

  // 生成 or 更新
  const createShop = useCallback<
    (data: ShopFormData & {groupId?: string}) => Promise<SaaSShop>
  >(
    async (data) => {
      setLoading(true);
      if (!owner?.id) {
        return;
      }
      try {
        const dto = {
          images,
          icon: icon?.uri,
          ...convert(data, owner?.id),
        };
        const result = dto.id
          ? await SaaSShopRepository.updateShop(dto)
          : await SaaSShopRepository.postShop(dto);
        return result.data;
      } finally {
        setLoading(false);
      }
    },
    [icon, images, owner],
  );

  return {
    icon,
    images,
    isLoading,
    addIcon,
    removeIcon,
    changeIcon,
    addImage,
    removeImage,
    changeImage,
    createShop,
  };
}

export const ShopRegisterContainer = createContainer(useShopRegister);
