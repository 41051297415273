import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil,
} from 'google-libphonenumber';
import urlRegex from 'url-regex';
import * as yup from 'yup';
import {setLocale} from 'yup';
import 'yup-phone';
import {MessageParams} from 'yup/lib/types';
import {Helpers} from '../../helper';

const labelText = (prm: MessageParams) => {
  return prm.label ? `${prm.label}は` : '';
};

// エラーメッセージのカスタマイズ
setLocale({
  string: {
    url: (prm: MessageParams & {regex: RegExp}) =>
      `${labelText(prm)}URLの形式が不正です`,
  },
});

// yup のデフォルトメッセージだと英語になるので、日本語のメッセージに差し替える
const REQUIRED = '必須の項目です';
const REQUIRED_IMAGE = '画像の登録が１枚以上必要です';
const MAX_OVER = '文字数が超過しています';
const NEED_NUMBER = '数値を入力してください';
const RANGE_PERCENT = '1から99の範囲で入力してください。';
const MAX_PRICE = 99999;
const RANGE_PRICE = `1から${MAX_PRICE}の範囲で入力してください`;
const NEED_EMAIL = 'メールアドレスの形式が不正です';
const NEED_PHONE = '電話番号の形式が違います';
const PASSWORD_LENGTH = 'パスワードは８文字以上で設定してください';
const PASSWORD_CHAR = 'パスワードには大文字、小文字、数字を含めてください';
const ZIP_FORMAT = '郵便番号の形式が異なります';
const KANA_FORMAT = '全角カタカナで入力してください';
const BUDGET_LOWER = 1000;
const BUDGET_UPPER = 10000000;
const RANGE_BUDGET = `${Helpers.sepComma(BUDGET_LOWER)}円から${Helpers.sepComma(
  BUDGET_UPPER,
)}円の範囲で入力してください。`;

const REGEXP_ZIP = /^\d{3}-?\d{4}$/;
// 片仮名 (Unicodeのブロック)
// https://ja.wikipedia.org/wiki/%E7%89%87%E4%BB%AE%E5%90%8D_(Unicode%E3%81%AE%E3%83%96%E3%83%AD%E3%83%83%E3%82%AF)
const REGEXP_KANA = /^[\u30A0-\u30FF]+$/;
const REGEXP_NUM = /[0-9]/;
const REGEXP_UPPER = /[A-Z]/;
const REGEXP_LOWER = /[a-z]/;

function toPostCode(code?: string): string | undefined {
  if (code && code.length === 7) {
    // ハイフン追加
    return code.slice(0, 3) + '-' + code.slice(3);
  }
  // それ以外はそのまま
  return code;
}

export function formatPhoneNumber(
  source: string,
  phoneNumberFormat: PhoneNumberFormat,
): string {
  if (!source) {
    return source;
  }
  try {
    const util = PhoneNumberUtil.getInstance();
    const phoneNumber: PhoneNumber = util.parseAndKeepRawInput(source, 'JP');
    if (util.isValidNumber(phoneNumber)) {
      return util.format(phoneNumber, phoneNumberFormat);
    }
  } catch {
    // 変換できなければ無視
  }
  return source;
}

// 外部ライブラリであり返り値型の指定が辛いので、ここではそのチェックを無効化する
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function string(max?: number) {
  return max ? yup.string().max(max, MAX_OVER) : yup.string();
}

function stringRequired(max?: number) {
  return string(max).required(REQUIRED);
}

// ここでの max は 文字数（桁数）を意味する
function number(max?: number) {
  const num = yup.number().typeError(NEED_NUMBER);
  // 桁数に変換
  return max ? num.lessThan(Math.pow(10, max), MAX_OVER) : num;
}

// ここでの max は 桁数を意味する
function numberRequired(max?: number) {
  return number(max).required(REQUIRED);
}

function booleanRequired() {
  return yup.boolean().required(REQUIRED);
}

function percent() {
  return number().min(1, RANGE_PERCENT).max(99, RANGE_PERCENT);
}

function percentRequired() {
  return percent().required(REQUIRED);
}

function price() {
  return number().min(1, RANGE_PRICE).max(MAX_PRICE, RANGE_PRICE);
}

function priceRequired() {
  return price().required(REQUIRED);
}

function budget() {
  return numberRequired()
    .min(BUDGET_LOWER, RANGE_BUDGET)
    .max(BUDGET_UPPER, RANGE_BUDGET);
}

function email() {
  return stringRequired().email(NEED_EMAIL);
}

function phone() {
  return string()
    .phone('JP', false, NEED_PHONE)
    .required(REQUIRED)
    .transform((value) => {
      return typeof value === 'string' && value !== null
        ? formatPhoneNumber(value, PhoneNumberFormat.NATIONAL)
        : value;
    });
}

function phoneSameCheck(formattedNumber: string) {
  return phone().test(
    'sameCheck',
    '同じ番号です',
    (value) => value !== formattedNumber,
  );
}

function phoneOptional() {
  return string()
    .test('phone-optional', NEED_PHONE, (value) => {
      if (!value || value.length === 0) {
        return true;
      }
      // 入力があるならバリデーションする
      return phone().isValidSync(value);
    })
    .transform((value) => {
      return typeof value === 'string' && value !== null
        ? formatPhoneNumber(value, PhoneNumberFormat.NATIONAL)
        : value;
    });
}

function password() {
  // ８文字以上、小文字、大文字、数値
  return stringRequired()
    .min(8, PASSWORD_LENGTH)
    .matches(REGEXP_LOWER, PASSWORD_CHAR)
    .matches(REGEXP_UPPER, PASSWORD_CHAR)
    .matches(REGEXP_NUM, PASSWORD_CHAR);
}

function zipRequired() {
  return stringRequired()
    .matches(REGEXP_ZIP, ZIP_FORMAT)
    .transform((value) => {
      return typeof value === 'string' && value !== null
        ? toPostCode(value)
        : value;
    });
}

function kana(max?: number) {
  return string(max).matches(REGEXP_KANA, KANA_FORMAT);
}

function kanaRequired(max?: number) {
  return stringRequired(max).matches(REGEXP_KANA, KANA_FORMAT);
}

function imageRequired() {
  return yup.boolean().required(REQUIRED_IMAGE).equals([true], REQUIRED_IMAGE);
}

function url(max?: number) {
  return string(max)
    .test('is-url', 'URLの形式が不正です', (value) => {
      return (
        value === undefined ||
        value === null ||
        (!!value &&
          urlRegex({exact: true}).test(value) &&
          /^((http|https):\/\/)/.test(value))
      );
    })
    .nullable()
    .transform((value, originalValue) => {
      return String(originalValue).trim() === '' ? null : value;
    });
}
/* eslint-enable */

// 共通プレースホルダー
export const PLACEHOLDER = {
  mail: {placeholder: 'mail@trip-mile.com'},
  hojinName: {placeholder: 'ジオテクノロジーズ株式会社'},
  hojinYomi: {placeholder: 'ジオテクノロジーズカブシキガイシャ'},
  zip: {placeholder: '113-0021'},
  pref: {placeholder: '東京都'},
  city: {placeholder: '文京区'},
  detail: {placeholder: '本駒込2-28−1'},
  building: {placeholder: '文京グリーンコート 22F'},
  url: {placeholder: 'https://shop.trip-mile.com/'},
  phone: {placeholder: '09012345678'},

  shopName: {placeholder: '炭火焼鳥 トリマ家 駒込店'},
  shopText: {
    placeholder: `当店は、岩手県産地鶏の焼き鳥が自慢の居酒屋です。
駒込駅南口から徒歩5分、お電話でのご予約も受け付けております。
当店の人気メニュー
・焼き鳥8種盛り 880円
・若鶏の唐揚げ 640円
・生ビール 480円`,
  },
  shopBizHours: {
    placeholder: `平日 11:00-23:00
土日祝 16:00-23:00`,
  },
  shopHolidays: {placeholder: '木曜定休・年末年始は休まず営業'},
};
// 共通文字数制限
export const STRING_LIMIT = {
  mail: 100,
  hojinName: 50,
  hojinYomi: 50,
  nameCommon: 50,
  detail: 50,
  building: 50,
  bizHours: 100,
  holidays: 100,
  url: 200,
  shopName: 26,
  shopText: 2000,
  title: 30,
};
export {yup};
export const yupUtil = {
  imageRequired,
  string,
  stringRequired,
  email,
  password,
  phone,
  phoneSameCheck,
  phoneOptional,
  number,
  numberRequired,
  booleanRequired,
  percent,
  percentRequired,
  price,
  priceRequired,
  budget,
  zipRequired,
  kana,
  kanaRequired,
  toPostCode,
  url,
};
