import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ShopContainer} from '../../../../container';
import {LngLat} from '../../../../helper';
import {CommonStyles} from '../../../../theme';
import {
  MCIcon,
  MinText,
  Text,
  TrimaLoadingButton,
  VForm,
} from '../../../Elements';
import {TargetCounter} from '../../Counters';
import {TargetingContainer} from '../../TargetingContainer';
import {AttrForm, areaResidenceInfo} from '../../schema';
import {ChoiceCounter} from '../ChoiceCounter';
import MeshSelectMap from '../MeshSelectMap';
import {ResidenceContainer} from './ResidenceContainer';

// 居住地・勤務地設定

const ResidenceSetting: React.FC = React.memo(() => {
  const {getResidenceInitial} = TargetingContainer.useContainer();
  const init = getResidenceInitial();
  return (
    <ResidenceContainer.Provider initialState={init}>
      <ResidenceSettingInner />
    </ResidenceContainer.Provider>
  );
});

const ResidenceSettingInner: React.FC = React.memo(() => {
  const [shopCenter] = React.useState<LngLat | undefined>(
    ShopContainer.useContainer().selected?.location ?? undefined,
  );
  const [hideCounter, setHideCounter] = React.useState<boolean>(false);
  const {
    calcMode,
    canConfirm,
    confirm,
    selectMesh,
    selectMeshes,
    selectedMesh,
    userCount,
  } = ResidenceContainer.useContainer();

  const formDisabled = calcMode === 'map';
  const count = Object.keys(selectedMesh).length;
  return (
    <View style={[CommonStyles.fullHeight, CommonStyles.flex.reverse]}>
      <View style={CommonStyles.margin.all}>
        <TrimaLoadingButton
          variant="contained"
          onClick={confirm}
          disabled={!canConfirm}
          loading={userCount === 'calculating'}
          loadingPosition="start"
        >
          確定
        </TrimaLoadingButton>
      </View>
      <View
        style={[CommonStyles.margin.horizontal, CommonStyles.margin.bottom]}
      >
        <VForm.Select<AttrForm>
          {...areaResidenceInfo}
          disabled={formDisabled}
        />
      </View>
      <View
        style={[
          CommonStyles.flex.row,
          CommonStyles.flex.mainEnd,
          CommonStyles.flex.crossCenter,
          CommonStyles.margin.right,
        ]}
      >
        <ChoiceCounter type="mesh" count={count} />
        <View style={styles.captionMargin} />
        <MCIcon name="square-outline" size={12} />
        <MinText>{'=500m×500m'}</MinText>
      </View>
      <View style={CommonStyles.flex.full}>
        <MeshSelectMap
          meshes={selectedMesh}
          onSelect={selectMesh}
          onSelectMeshes={selectMeshes}
          center={shopCenter}
          changeDialogShow={setHideCounter}
        />
        {!hideCounter && <TargetCounter count={userCount} />}
      </View>
      <View style={CommonStyles.margin.all}>
        <Text>居住地・勤務地を設定</Text>
        <MinText>地図をクリックするとエリアが選択できます</MinText>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  captionMargin: {
    marginLeft: 8,
  },
});

export default ResidenceSetting;
