import {API, Auth, graphqlOperation} from 'aws-amplify';
import axios from 'axios';
import {createSaaSOwner} from '../../graphql/mutations';
import {getSaaSOwner} from '../../graphql/queries';
import {AppConfig} from '../config/AppConfig';
import {DayjsRepository} from './DayjsRepository';

const TAG = 'SaaSRepository';

export class SaaSRepository {
  static async fetchUserToken() {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    return token;
  }

  static async formAxioConfig(): Promise<any> {
    const token = await SaaSRepository.fetchUserToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    };

    return config;
  }

  static async axioConfig(): Promise<any> {
    const token = await SaaSRepository.fetchUserToken();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
        Accept: '*/*',
      },
    };

    return config;
  }

  static async postPreRegist(email: string): Promise<any> {
    const url = `${AppConfig.SaaSBackend}${AppConfig.PreRegistEndPoint}`;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    };

    const data = {
      email,
    };

    console.log(TAG, 'pre-r-data', config);
    const rt = await axios.post(url, data, config);
    console.log(TAG, 'pre-regist', rt.data);
    return rt.data;
  }

  static async confirmPreRegist(params: any): Promise<any> {
    const url = `${AppConfig.SaaSBackend}${AppConfig.ConfirmRegistEndPoint}`;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    };

    const data = {
      ...params,
      stamp: Number(params.stamp),
    };

    console.log(TAG, 'pre-c-data', config);
    try {
      const rt = await axios.post(url, data, config);
      console.log(TAG, 'pre-c-regist', rt.data);
      return rt.data;
    } catch (err: any) {
      console.log(TAG, 'pre-c-err', err.response.data);
      throw err.response.data;
    }
  }

  static async fetchImage(key: string): Promise<any> {
    const imgUri = `${AppConfig.SaaSBackend}${AppConfig.ImageEndPoint}/${key}`;
    const token = await SaaSRepository.fetchUserToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: '*/*',
      },
    };

    const rt = await axios.get(imgUri, config);
    console.log(TAG, 'img', rt.data);
    return rt.data;
  }

  static async fetchGooglePlace(key: string): Promise<any> {
    const random = DayjsRepository.japanDate().unix();
    const uri = `${AppConfig.SaaSBackend}${AppConfig.GooglePlaceEndPoint}/${key}/${random}`;
    const token = await SaaSRepository.fetchUserToken();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: '*/*',
      },
    };

    const rt = await axios.get(uri, config);
    console.log(TAG, 'place', rt.data);
    return rt.data;
  }

  static async addGroup(data: any): Promise<any> {
    const config = await this.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.AddGroupEndPoint}`,
      data,
      config,
    );
    return result.data;
  }

  static async removeGroup(data: any): Promise<any> {
    const config = await this.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.RemoveGroupEndPoint}`,
      data,
      config,
    );
    return result.data;
  }
}

const fetchOwner = async (): Promise<any> => {
  const user = await Auth.currentUserPoolUser();
  console.log(TAG, 'user', user);

  try {
    const result: any = await API.graphql(
      graphqlOperation(getSaaSOwner, {id: user.username}),
    );
    console.log(TAG, 'owner', result);
    return result.data && result.data.getSaaSOwner;
  } catch (err: any) {
    console.error(err);
  }
  return null;
};

type SaaSFullNameDto = {
  first: string;
  last: string;
};

export type SaaSBizDaysDto = {
  text: string;
};

export type SaaSAddressDto = {
  zip: string;
  pref: string;
  city: string;
  detail: string;
  building: string;
};

export type SaaSOwnerDto = {
  id?: string;
  hojin: string;
  hojinKana: string;
  representative: SaaSFullNameDto;
  representativeKana: SaaSFullNameDto;
  address: SaaSAddressDto;
  url: string;
  chargeDept: string;
  charge: SaaSFullNameDto;
  chargeKana: SaaSFullNameDto;
  chargePhone: string;
  promo: string;
};

const createOwner = async (owner: SaaSOwnerDto): Promise<any> => {
  const user = await Auth.currentUserPoolUser();
  console.log(TAG, 'user', user);

  try {
    const result: any = await API.graphql(
      graphqlOperation(createSaaSOwner, {input: {...owner, id: user.username}}),
    );
    console.log(TAG, 'owner', result);
    return result.data && result.data.createSaaSOwner;
  } catch (err: any) {
    console.error(err);
  }
  return null;
};

const updateOwner = async (owner: SaaSOwnerDto) => {
  const user = await Auth.currentUserPoolUser();
  console.log(TAG, 'user', user);

  const updateSaaSOwner = /* GraphQL */ `
    mutation UpdateSaaSOwner(
      $input: UpdateSaaSOwnerInput!
      $condition: ModelSaaSOwnerConditionInput
    ) {
      updateSaaSOwner(input: $input, condition: $condition) {
        id
        hojin
        hojinKana
        representative {
          first
          last
        }
        representativeKana {
          first
          last
        }
        address {
          zip
          pref
          city
          detail
          building
        }
        url
        chargeDept
        charge {
          first
          last
        }
        chargeKana {
          first
          last
        }
        chargePhone
        promo
        createdAt
        updatedAt
      }
    }
  `;

  try {
    const result: any = await API.graphql(
      graphqlOperation(updateSaaSOwner, {input: owner}),
    );
    console.log(TAG, 'owner', result);
    return result.data && result.data.updateSaaSOwner;
  } catch (err: any) {
    console.error(err);
  }
  return null;
};

export type SaaSImageContents = {
  uri: string;
  key: string;
};

export {fetchOwner, createOwner, updateOwner};
