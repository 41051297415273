import axios, {AxiosResponse} from 'axios';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import {SaaSCoupon, SaaSNews} from '../API';
import {AppConfig} from '../_proto/config/AppConfig';
import {SaaSRepository} from '../_proto/services/SaaSRepository';
import {axiosHelper} from './axiosHelper';

dayjs.extend(isoWeek);

export type DeliveryType = 'COUPON' | 'NEWS';

export type DailyRecord = {
  stamp: string; // YYYY-MM-DD
  listed?: number;
  amount?: number;
  viewed?: number;
  visited?: number;
  unit?: number;
  urlClicked?: number;
  urlClickedUnit?: number;
};

export type RangeReportResponse = {
  listed?: number;
  amount?: number;
  viewed?: number;
  visited?: number; // 未計測の時は undefined
  urlClicked?: number;
  dailyRecords: DailyRecord[];
};

export type DeliverySummary = {
  id: string;
  type: DeliveryType;
  title: string;
  startAt: string;
  endAt: string;
  viewed?: number;
  acquired?: number;
  visited?: number;
  clickedUrl?: number;
  clickedPhone?: number;
};

export type TargetReport = {
  id: string;
  ownerId: string;
  shopId: string;
  title: string;
  users: string;
};

export type DeliveryDailyReport = {
  id: string;
  shopId: string;
  stamp: string;
  listCount: number;
  billCount: number;
  billAmount: number;
  viewCount: number;
  keptCount: number;
  usedCount: number;
  visited: number;
  clickedUrlCount: number;
  clickedPhoneCount: number;
};

export type DeliveryDetail = {
  title: string;
  startAt: string;
  endAt: string;
  budget: number;
  balance: number;
  sums: DeliverySums;
  delivery: SaaSCoupon | SaaSNews;
  target: TargetReport;
  reports: DeliveryDailyReport[];
};

export type DeliverySums = {
  list?: number;
  bill?: number;
  kept?: number;
  visited?: number;
  used?: number;
  amount?: number;
  clickedUrl?: number;
  clickedPhone?: number;
};

function sortFunc(a: {stamp: string}, b: {stamp: string}): number {
  return a.stamp > b.stamp ? 1 : a.stamp < b.stamp ? -1 : 0;
}

// 来店者分析結果
export type ShopVisitorResponse = {
  visitor: {
    visitor?: number;
    repeater?: number;
    reports: DailyVisitorReportDto[];
  };
  mesh?: {
    residence: SingleMeshCountDto[];
    office: SingleMeshCountDto[];
  };
  user?: {
    male: AgeReportDto[];
    female: AgeReportDto[];
    other: AgeReportDto[];
  };
  follower?: FollowerReportDto[];
};

export type DailyVisitorReportDto = {
  stamp: string; // YYYY-MM-DD
  visitor?: number;
  repeater?: number;
};

export type SingleMeshCountDto = {
  mesh: string;
  count: number;
};

export type AgeReportDto = {
  range: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  count: number;
};

export const AGE_RANGE_LABEL: {[P in string]: string} = {
  '1': '-19',
  '2': '20-29',
  '3': '30-39',
  '4': '40-49',
  '5': '50-59',
  '6': '60-69',
  '7': '70-',
};

export type FollowerReportDto = {
  stamp: string; // YYYY-MM-DD
  follower?: number;
  unfollowed?: number;
  followed?: number;
};

/**
 * 配信結果の日別サマリーの取得
 */
async function getShopDailyReports({
  id,
  from,
  to,
}: {
  id: string;
  from: string;
  to: string;
}): Promise<RangeReportResponse> {
  let result: AxiosResponse<Partial<RangeReportResponse>>;
  try {
    const config = await SaaSRepository.axioConfig();
    result = await axios.get(
      `${AppConfig.SaaSBackend}${AppConfig.ReportShopDailyEndPoint}/${id}`,
      {
        params: {
          from,
          to,
        },
        ...config,
      },
    );
  } catch (err: any) {
    throw axiosHelper.commonErrorHandler(err);
  }

  if (result.data) {
    const reports = result.data.dailyRecords ?? [];
    const checked = reports.filter((daily) => {
      if (!daily.stamp || daily.amount === undefined) {
        return false;
      }
      const stamp = dayjs(daily.stamp);
      return stamp.isValid();
    }) as DailyRecord[];
    return {
      ...result.data,
      dailyRecords: checked.sort(sortFunc),
    };
  }

  throw new Error('invalid shop daily reports');
}

/**
 * 配信ごとの結果一覧の取得
 * @param id 店舗ID
 * @throws 通信失敗や取得データの異常時に発生
 */
async function getDeliverySummary(id: string): Promise<DeliverySummary[]> {
  let result: AxiosResponse<Partial<DeliverySummary>[]>;
  try {
    const config = await SaaSRepository.axioConfig();
    const random = dayjs().unix();
    result = await axios.get(
      `${AppConfig.SaaSHeavyBackend}${AppConfig.ReportShopDeliverySummaryEndPoint}/${id}/${random}`,
      config,
    );
    console.log('[reports]', result);
  } catch (err: any) {
    throw axiosHelper.commonErrorHandler(err);
  }

  if (result.data) {
    const checked = result.data.filter((delivery) => {
      if (
        !delivery.id ||
        !delivery.type ||
        !delivery.title ||
        !delivery.startAt ||
        !delivery.endAt
      ) {
        return false;
      }
      const start = dayjs(delivery.startAt);
      const end = dayjs(delivery.endAt);
      return start.isValid() && end.isValid();
    }) as DeliverySummary[];
    return checked;
  }

  return [];
}

async function getDeliveryDetail(
  id: string,
  type: 'COUPON' | 'NEWS',
): Promise<DeliveryDetail> {
  try {
    const config = await SaaSRepository.axioConfig();
    const random = dayjs().unix();
    const result: AxiosResponse<DeliveryDetail> = await axios.get(
      `${AppConfig.SaaSHeavyBackend}${AppConfig.ReportShopDeliveryDetailEndpoint}/${id}/${type}/${random}`,
      config,
    );
    if (result.data && result.data.reports !== undefined) {
      const sorted = result.data.reports.sort(sortFunc);
      result.data.reports = sorted;
      return result.data;
    }
  } catch (err: any) {
    throw axiosHelper.commonErrorHandler(err);
  }
  throw new Error('getDeliveryDetail invalid data');
}

export type UserAnalyzeReport = {
  label: string;
  listCount?: number;
  viewCount?: number;
  clickedUrlCount?: number;
  visited?: number;
};

async function getDeliveryUserAnalyze(
  id: string,
  category: string,
): Promise<Array<UserAnalyzeReport>> {
  try {
    const config = await SaaSRepository.axioConfig();
    const result: AxiosResponse<Array<UserAnalyzeReport>> = await axios.get(
      `${AppConfig.SaaSBackend}${AppConfig.DeliveryUserAnalyzeEndpoint}/${id}/${category}`,
      config,
    );
    return result.data;
  } catch (err: any) {
    throw axiosHelper.commonErrorHandler(err);
  }
}

async function getDeliveryDailyReport(
  id: string,
  days: 7 | 14 | 28,
): Promise<RangeReportResponse> {
  try {
    const config = await SaaSRepository.axioConfig();
    const random = dayjs().unix();
    const result: AxiosResponse<RangeReportResponse> = await axios.get(
      `${AppConfig.SaaSHeavyBackend}${AppConfig.ReportDeliveryDailyEndPoint}/${id}/${days}/${random}`,
      config,
    );
    if (result.data) {
      const sorted = result.data.dailyRecords.sort(sortFunc);
      result.data.dailyRecords = sorted;
      return result.data;
    }
  } catch (err: any) {
    throw axiosHelper.commonErrorHandler(err);
  }
  throw new Error('getDeliveryDailyReport invalid data');
}

async function getShopVisitor(
  id: string,
  type: 'visitor' | 'full',
): Promise<ShopVisitorResponse> {
  try {
    const config = await SaaSRepository.axioConfig();
    const random = dayjs().unix();
    const result: AxiosResponse<ShopVisitorResponse> = await axios.get(
      `${AppConfig.SaaSHeavyBackend}${AppConfig.ReportShopVisitor}/${id}/${type}/${random}`,
      config,
    );
    if (result.data && result.data.visitor.reports) {
      // visitorの日付順ソート
      result.data.visitor.reports.sort(sortFunc);
      if (type === 'visitor' || !result.data.follower) {
        return result.data;
      }
      // full かつフォロワーレポートがあるならそっちもソート
      result.data.follower.sort(sortFunc);
      return result.data;
    }
  } catch (err: any) {
    throw axiosHelper.commonErrorHandler(err);
  }
  throw new Error('getShopVisitor invalid data');
}

export type WeeksClassified<T> = {
  unitCount: number;
  startDay: dayjs.Dayjs;
  values: T[];
}[];

function classifyWeeks<T>(sorted: ({stamp: string} & T)[]): WeeksClassified<T> {
  const ret: WeeksClassified<T> = [];
  sorted.forEach((value) => {
    const stamp = dayjs(value.stamp);
    if (stamp.isValid()) {
      const week = stamp.isoWeek();
      if (ret[ret.length - 1] && ret[ret.length - 1].unitCount === week) {
        ret[ret.length - 1].values.push(value);
      } else {
        // new week
        ret.push({
          unitCount: week,
          startDay: stamp.startOf('isoWeek'),
          values: [value],
        });
      }
    }
  });
  return ret;
}

function classifyMonth<T>(sorted: ({stamp: string} & T)[]): WeeksClassified<T> {
  const ret: WeeksClassified<T> = [];
  sorted.forEach((value) => {
    const stamp = dayjs(value.stamp);
    if (stamp.isValid()) {
      const month = stamp.month();
      if (ret[ret.length - 1] && ret[ret.length - 1].unitCount === month) {
        ret[ret.length - 1].values.push(value);
      } else {
        // new month
        ret.push({
          unitCount: month,
          startDay: stamp.startOf('month'),
          values: [value],
        });
      }
    }
  });
  return ret;
}

export const SaasReportRepository = {
  getShopDailyReports,
  getDeliverySummary,
  getDeliveryDetail,
  getDeliveryDailyReport,
  getShopVisitor,
  classifyWeeks,
  classifyMonth,
  getDeliveryUserAnalyze,
};
