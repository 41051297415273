import React from 'react';
import {View} from 'react-native';
import {Header} from '../../components/Elements';
import {Forms} from './components';

export const PreSignUpComponent: React.FC = React.memo(() => {
  return (
    <View>
      <Header />
      <Forms />
    </View>
  );
});

export {Forms};
