import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link, Stack, Typography} from '@mui/material';
import React from 'react';
import {StyleSheet} from 'react-native';
import {Colors} from '../../theme';
import {Text} from './Text';

export const LinkText: React.FC<{
  url: string;
  postText?: string;
  preText?: string;
  children: React.ReactNode;
}> = ({url, children, postText, preText}) => {
  return (
    <Typography>
      {postText && <Text>{postText}</Text>}
      <Link href={url} target="_blank" rel="noopener" underline="hover">
        <Text style={styles.text}>{children}</Text>
      </Link>
      {preText && <Text>{preText}</Text>}
    </Typography>
  );
};

export const LinkTextMin: React.FC<{
  url: string;
  children: React.ReactNode;
}> = ({url, children}) => {
  return (
    <Link
      href={url}
      target="_blank"
      rel="noopener"
      underline="hover"
      color="primary">
      {children}
    </Link>
  );
};

export const GotoDetail: React.FC<{onPress?(): void}> = ({onPress}) => {
  return (
    <Link component="button" onClick={onPress} underline="hover">
      <Stack direction="row" alignItems="center" gap={1}>
        詳しく見る
        <FontAwesomeIcon icon={faChevronRight} />
      </Stack>
    </Link>
  );
};

export const BackLink: React.FC<{label: string; onClick?(): void}> = ({
  label,
  onClick,
}) => {
  return (
    <Link component="button" onClick={onClick} underline="hover">
      <Stack direction="row" alignItems="center" gap={1}>
        <FontAwesomeIcon icon={faChevronLeft} />
        {label}
      </Stack>
    </Link>
  );
};

const styles = StyleSheet.create({
  gotoDetail: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {color: Colors.main},
  icon: {color: Colors.main, fontSize: 20},
});
