import {CountByUsrAttrRunner} from './runner/CountByUserAttrRunner';

const TAG = 'UserPropHelper';
console.log(TAG);

export type EncodedUserProp = {
  [key: string]: any;
};

export type RangeOptionDto = {
  lower: number;
  upper: number;
};

export type UserOptionDto = {
  gender?: string;
  ageRange?: RangeOptionDto;
  married?: boolean;
  residence?: string[];
  movingMethod?: string[];
  childrenTogether?: number;
  incomeRange?: RangeOptionDto;
  householdIncomeRange?: RangeOptionDto;
  familyTogether?: number;
  address?: number[];
  job?: number[];
  interest?: number[];
  childAge?: RangeOptionDto;
  cityCode?: string[];
  prefCode?: string[];
};

export class UserPropHelper {
  static async getUserCount(
    option: UserOptionDto,
  ): Promise<number | undefined> {
    console.log(TAG, option);
    const runner = new CountByUsrAttrRunner(option);
    return runner.run();
  }
}
