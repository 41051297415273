import {
  Box,
  CircularProgress,
  Button as MuiButton,
  Typography,
} from '@mui/material';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {Text} from 'react-native-paper';
import {AppConfig} from '../../_proto/config/AppConfig';
import American_Expresse from '../../assets/stripe/American_Express.png';
import american_express from '../../assets/stripe/american_express.svg';
import diners from '../../assets/stripe/diners.svg';
import discover from '../../assets/stripe/discover.svg';
import jcb from '../../assets/stripe/jcb.svg';
import mc_symbol from '../../assets/stripe/mc_symbol.svg';
import visa_Master_JCB_DinersClub from '../../assets/stripe/visa_Master_JCB_DinersClub.png';
import visa_blue from '../../assets/stripe/visa_blue.svg';
import {
  OwnerContainer,
  PaymentInfoContainer,
  StepNavigationContainer,
} from '../../container';
import {isAxiosError} from '../../helper/errors';
import {Colors, CommonStyles, Fonts} from '../../theme';
import {MaxWidth, RequiredNotice, VMargin} from '../Elements';
import './stripe.css';

const priceId = AppConfig.StripePriceId;

// Stripe Elementsのオプション
// const CARD_OPTIONS = {
//   style: {
//     base: {
//       fontSize: '16px',
//       color: '#32325d',
//       fontFamily:
//         '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
//       fontSmoothing: 'antialPiased',
//       '::placeholder': {
//         color: '#a0aec0',
//       },
//     },
//   },
//   hidePostalCode: true,
// };

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
  showIcon: true,
};

const ErrorMessage = ({children}: any) => (
  <div className="error">{children}</div>
);

export const CardForm: React.FC<{
  onClick?: () => Promise<void>;
  isAddCard?: boolean;
}> = ({onClick, isAddCard}) => {
  const {owner} = OwnerContainer.useContainer();
  const {createPaymentMethod, addCard, createSubscription, paymentInfo} =
    PaymentInfoContainer.useContainer();
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = React.useState('');
  const [error, setError]: any = React.useState(null);
  // const [cardComplete, setCardComplete] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const {setRegisterDone} = StepNavigationContainer.useContainer();

  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setProcessing(true);
    setError(null);
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    if (cardElement && owner) {
      const payload = await createPaymentMethod(
        stripe,
        cardElement,
        name,
        owner,
      );

      if (payload.error) {
        console.log('[error]', payload.error);
        setError(payload.error);
      } else {
        try {
          if (isAddCard) {
            // クレジットカード追加の場合
            await addCard(paymentInfo.customerId, payload.paymentMethod.id);
            if (onClick) {
              await onClick();
            }
          } else {
            await createSubscription(
              owner,
              priceId,
              payload.paymentMethod.id,
              stripe,
            );
            setRegisterDone();
          }
        } catch (err: any) {
          if (isAxiosError(err)) {
            console.log(err.response);
            setError(err.response?.data);
          } else {
            console.log(err);
            setError(err);
          }
        }
      }
    }
    setProcessing(false);
  };

  return (
    <MaxWidth maxWidth={586}>
      <div className="stripe">
        <View style={styles.creditBrandContainer}>
          <Image
            style={{
              ...CommonStyles.margin.left,
              ...CommonStyles.margin.bottom,
            }}
            source={{uri: visa_blue, width: 48, height: 30}}
          />
          <Image
            style={{
              ...CommonStyles.margin.left,
              ...CommonStyles.margin.bottom,
            }}
            source={{uri: jcb, width: 48, height: 30}}
          />
          <Image
            style={{
              ...CommonStyles.margin.left,
              ...CommonStyles.margin.bottom,
            }}
            source={{uri: mc_symbol, width: 48, height: 30}}
          />
          <Image
            style={{
              ...CommonStyles.margin.left,
              ...CommonStyles.margin.bottom,
            }}
            source={{uri: american_express, width: 30, height: 30}}
          />
          <Image
            style={{
              ...CommonStyles.margin.left,
              ...CommonStyles.margin.bottom,
            }}
            source={{uri: diners, width: 41, height: 30}}
          />
          <Image
            style={{
              ...CommonStyles.margin.left,
              ...CommonStyles.margin.bottom,
            }}
            source={{uri: discover, width: 47, height: 30}}
          />
        </View>

        <form className="Form" onSubmit={handleSubmit}>
          <View>
            <View
              style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
              <label htmlFor="name">カード名義</label>
              <RequiredNotice />
            </View>
            <input
              id="name"
              required
              placeholder="HANAKO TANAKA"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </View>
          <View>
            <View
              style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
              <label htmlFor="cardNumber">カード番号</label>
              <RequiredNotice />
            </View>
            <CardNumberElement id="cardNumber" options={ELEMENT_OPTIONS} />
          </View>
          <View style={CommonStyles.flex.full}>
            <View
              style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
              <label htmlFor="expiry">有効期限</label>
              <RequiredNotice />
            </View>

            <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
          </View>
          <View style={CommonStyles.flex.full}>
            <View
              style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
              <label htmlFor="cvc">セキュリティコード</label>
              <RequiredNotice />
            </View>
            <CardCvcElement id="cvc" options={ELEMENT_OPTIONS} />
          </View>
          <VMargin />
          <View style={styles.card}>
            <Text style={Fonts.bold}>セキュリティコードとは</Text>
            <VMargin />
            <Text>
              セキュリティコードとは、インターネット取引にあたってクレジットカード番号の他に入力していただく本人認証のための番号です。
            </Text>
            <VMargin />
            <Text>
              ■カード裏面に記載の場合（Visa/Mastercard/JCB/Diners Clubなど）
            </Text>
            <Text>署名欄の上または脇にある3桁か4桁の数字</Text>
            <VMargin />
            <View style={styles.container}>
              <Image
                style={styles.image}
                source={{
                  uri: visa_Master_JCB_DinersClub,
                }}
                resizeMode="contain"
              />
            </View>
            <VMargin />
            <Text>■カード表面に記載の場合（American Expressなど）</Text>
            <Text>カード番号の右上にある4桁の数字</Text>
            <VMargin />
            <View style={styles.container}>
              <Image
                style={styles.image}
                source={{
                  uri: American_Expresse,
                }}
                resizeMode="contain"
              />
            </View>

            <VMargin />
            <Text>■ナンバーレスカードの場合</Text>
            <Text>
              ナンバーレスカードでは、カードによって確認方法が異なります。
            </Text>
            <Text>
              セキュリティコードがわからない場合はカード発行元にお問い合わせください。
            </Text>
          </View>

          <VMargin />
          <MuiButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={processing || !stripe}
            sx={{fontSize: '1rem', height: '56px'}}
            fullWidth>
            {processing && (
              <CircularProgress sx={{m: 1}} color="primary" size={28} />
            )}
            クレジットカードを登録
          </MuiButton>
          {error && (
            <Box m={1}>
              <Typography
                color="error"
                variant="caption"
                display="block"
                gutterBottom>
                <ErrorMessage>{error.message}</ErrorMessage>
              </Typography>
            </Box>
          )}
        </form>
      </div>
    </MaxWidth>
  );
};

const styles = StyleSheet.create({
  creditBrandContainer: {
    ...CommonStyles.flex.row,
    flexWrap: 'wrap',
  },
  card: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.base,
    ...CommonStyles.padding.all,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  image: {
    flex: 1,
    width: 126,
    height: 82,
  },
});
