import {AxiosError} from 'axios';

export const ERROR_MSG = {
  signUp: {
    params: 'クエリパラメータがありません',
    confirm: '認証URL確認エラー',
    other: '予期せぬエラー',
  },
  signIn: {
    other: '予期せぬエラー',
  },
  common: {
    form: '入力フォームに誤りがあります',
    dump: (error: Error) => {
      return (
        'エラーが発生しました。' + (error.message ? `（${error.message}）` : '')
      );
    },
  },
  agency: {
    params: 'クエリパラメータがありません',
    fetch: '代理店情報取得エラー',
    other: '予期せぬエラー',
  },
};

export function isAxiosError(error: any): error is AxiosError {
  return !!error.isAxiosError;
}
