import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSDeliveryTarget} from '../API';
import {SaaSTargetRepository} from '../_proto/services/SaaSTargetRepository';
import {Helpers} from '../helper';
import {graphQLService, isUsedItem} from '../service';

export type Target = Omit<SaaSDeliveryTarget, '__typename'> & {
  title: string;
  targetCount?: number | null;
  delivery: string; // 配信数
  used: boolean;
  id: string;
};

const useTargetsContainer = () => {
  const [isGetLoading, setGetLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [targets, setTargets] = useState<Array<Target>>([]);
  const [error, setError] = useState<Error | undefined>();
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  async function convert(target: SaaSDeliveryTarget): Promise<Target> {
    // 不正データ排除
    if (!target.id || !target.editState) {
      return {
        id: '',
        title: '',
        used: false,
        delivery: '',
      };
    }
    const used = isUsedItem(target);
    let delivery: string;
    if (used) {
      try {
        const count = await SaaSTargetRepository.getDeliveryCount(target.id);
        delivery = `${count.deliveries}回の配信で${Helpers.sepComma(
          count.users,
        )}人が閲覧済み`;
      } catch (err: any) {
        console.log('[convert] getCouponCount catch', err);
        delivery = '配信数取得エラー';
      }
    } else {
      delivery = 'この配信先はまだ使用していません';
    }

    return {
      ...target,
      title: target.title ?? '（タイトル不明）',
      targetCount: target.users,
      delivery,
      used,
      archive: !!target.archive,
    };
  }

  const getData = useCallback(async (shopId: string) => {
    setGetLoading(true);
    setError(undefined);
    try {
      const items = await SaaSTargetRepository.queryDeliveryTarget({shopId});
      const result = await Promise.all(
        items.map(async (item: any) => {
          return await convert(item);
        }),
      );

      setTargets(result);
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setGetLoading(false);
    }
  }, []);

  const deleteData = useCallback(
    async (id: string) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        const item = targets.filter((target) => target.id === id)[0];
        console.log('item', item);
        if (!item.used) {
          await graphQLService.deleteDeliveryTarget({id});
        } else {
          await SaaSTargetRepository.saveDeliveryTarget({id, archive: true});
        }

        const newTargets = targets.filter((target) => target.id !== id);
        setTargets(newTargets);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [targets],
  );

  return {
    isGetLoading,
    isDeleteLoading,
    targets,
    error,
    getData,
    deleteData,
    setCheckedIds,
    checkedIds,
  };
};

export const TargetsContainer = createContainer(useTargetsContainer);
