import dayjs from 'dayjs';
import {
  CouponState,
  Discount,
  DiscountType,
  EditState,
  NewsState,
  SaaSCoupon,
  SaaSDeliveryTarget,
  SaaSNews,
} from '../API';
import {Helpers} from '../helper';

const UNKNOWN = '（不明）';

/**
 * 値引きの種類を表示用文字列化
 * @param discount
 */
export function dumpDiscount(discount: Discount): string {
  switch (discount.type) {
    case DiscountType.BY_FREE:
      return '無料';
    case DiscountType.BY_HALF:
      return '半額';
    case DiscountType.BY_PERCENT:
      return `${(discount.param && discount.param[0]) ?? '-'}％OFF`;
    case DiscountType.BY_PRICE:
      return `${
        discount.param && discount.param[0]
          ? Helpers.sepComma(discount.param[0])
          : '-'
      }円引き`;
    case DiscountType.FROM_PRICE:
      return `${
        discount.param && discount.param[0]
          ? Helpers.sepComma(discount.param[0])
          : '-'
      }円→${
        discount.param && discount.param[1]
          ? Helpers.sepComma(discount.param[1])
          : '-'
      }円`;
    case DiscountType.BY_TEXT:
      return discount.freeText ?? UNKNOWN;
  }
  return UNKNOWN;
}

export type DeliveryStatus =
  | 'disabled'
  | 'limited'
  | 'active'
  | 'before'
  | 'after';
/**
 * 配信状態の判定
 * @param history
 */
export function getDeliveryStatus(history: {
  balance: number;
  budget: number;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  state?: CouponState | NewsState | null;
}): DeliveryStatus {
  const {balance, budget, start, end, state} = history;
  // 強制配信停止チェック
  if (state === 'DISABLED') {
    return 'disabled';
  }
  // 配信期間が事実上ない場合
  if (start.isAfter(end) || start.isSame(end)) {
    return 'after';
  }
  // Todo サーバー時間を使った方が正確
  const now = dayjs();
  // 期間判定
  if (now.isAfter(end)) {
    return 'after';
  }
  if (now.isBefore(start)) {
    return 'before';
  }
  // 予算残チェック
  if (budget !== 0 && balance < 3) {
    return 'limited';
  }
  // チェック通ったら配信中
  return 'active';
}

export function isUsedItem(
  item: SaaSNews | SaaSCoupon | SaaSDeliveryTarget,
): boolean {
  return item.editState === EditState.DELIVERED;
}
