import React from 'react';
import { View, ViewStyle } from 'react-native';
import { CommonStyles } from '../../../theme';
import { MaxWidth } from '../Layouts';
import { RegisterStep, StepIndicator } from '../StepIndicator';
import { Menu } from '../Text';
import { Container, ContainerFull } from './component';

const RegisterContainer: React.FC<{
  step?: RegisterStep;
  label?: string;
  style?: ViewStyle;
  children: React.ReactNode;
}> = ({step, label, style = CommonStyles.formBlock, children}) => {
  return (
    <View>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <View>
          {step && (
            <View style={CommonStyles.margin.bottom}>
              <StepIndicator currentStep={step} />
            </View>
          )}
          {label && (
            <View style={CommonStyles.margin.bottom}>
              <Menu>{label}</Menu>
            </View>
          )}
          <View style={style}>{children}</View>
        </View>
      </MaxWidth>
    </View>
  );
};

export { Container, ContainerFull, RegisterContainer };

