import _ from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {createContainer} from 'unstated-next';
import {EditState, SaaSDeliveryTarget} from '../../API';
import {
  DeliveryTargetDto,
  SaaSTargetRepository,
} from '../../_proto/services/SaaSTargetRepository';
import {UserPropHelper} from '../../_proto/services/helpers/UserPropHelper';
import {StationCacheContainer} from '../../container';
import {useOwnerId} from '../../container/Shop';
import {QueueHelper} from '../../helper';
import {PrefectureContainer} from './area/prefecture/PrefectureContainer';
import {calcUserCount, calcUserList} from './commonFunc';
import {
  getDtoAreaOption,
  getDtoCount,
  getDtoStationOption,
  getDtoStayOption,
  getDtoUserOption,
} from './convert';
import {useUseChanger} from './hooks';
import {Area, AreaMode, AttrForm, getUserOptionWithForm} from './schema';
import {
  BaseUsersByMesh,
  BaseUsersByStation,
  MeshList,
  ResidenceInitialState,
  ResidenceUsersByMesh,
  StationInitialState,
  StationsByLine,
  StayInitialState,
  StayUsersByMesh,
  TargetingUserCount,
  TargetsInitialState,
} from './types';
import {checkAttr, useAttrFilter} from './userAttribute/attrFilters';

// 画面状態
type ScreenState = 'init' | 'userAttr' | Area | 'prefecture';

type UseTargeting = {
  // 共通
  screen: ScreenState; // 画面状態
  targetingUserCount: TargetingUserCount; // 対象人数
  areaMode: AreaMode; // エリア指定の有無
  // プレビュー表示用データ
  residenceMesh: MeshList;
  stayMesh: MeshList;
  stationsByLine: StationsByLine;
  // エリアの設定・解除
  setAreaMode(mode: AreaMode): void; // エリアモードの変更
  settingArea(area: Area): () => void; // エリア選択開始
  dismissArea(): void; // エリア選択モードキャンセル
  clearArea(area: Area): () => void; // エリアの利用キャンセル
  // 各エリアコンテナに渡すデータ生成関数
  getResidenceInitial(): ResidenceInitialState;
  getStayInitial(): StayInitialState;
  getStationInitial(): StationInitialState;
  // 各エリア確定関数
  confirmResidence(
    residence: ResidenceUsersByMesh,
    count: TargetingUserCount,
  ): void;
  confirmStay(stay: StayUsersByMesh, count: TargetingUserCount): void;
  confirmStation(station: BaseUsersByStation, count: TargetingUserCount): void;
  confirmPrefecture(): void;
  // サーバーへ保存
  save(
    formData: AttrForm,
    fixed: boolean,
    shopId: string,
  ): Promise<SaaSDeliveryTarget>;
};

function makeMeshList(byMesh: BaseUsersByMesh): MeshList {
  const mesh: MeshList = {};
  _.forEach(byMesh, (_v, key) => (mesh[key] = true));
  return mesh;
}

// 全国or都道府県エリアモードで通信の順番を守らせるため（＝並列数１）のキュー
const queue = QueueHelper.getQueue(1);

function useTargeting(initialState?: TargetsInitialState): UseTargeting {
  const {
    selectedRegionList,
    selectedPrefList,
    selectedCityList,
    restoreByTarget,
  } = PrefectureContainer.useContainer();
  const mountRef = useRef(false);
  const [screen, setScreen] = useState<ScreenState>('userAttr');
  const [targetingUserCount, setCount] = useState<TargetingUserCount>('init');
  const [areaMode, setAreaMode] = useState<AreaMode>(
    initialState?.areaMode ?? 'areaTarget',
  );
  const {getStationName, makeStationsByLine} =
    StationCacheContainer.useContainer();
  const {getValues, setValue} = useFormContext<AttrForm>();

  // 各エリア設定におけるユーザー生データ（ユーザーデータの保持）
  const residenceUsersRef = useRef<ResidenceUsersByMesh>(
    initialState?.residenceUsers ?? {},
  );
  const stayUsersRef = useRef<StayUsersByMesh>(initialState?.stayUsers ?? {});
  const stationUsersRef = useRef<BaseUsersByStation>(
    initialState?.stationUsers ?? {},
  );

  // 居住・勤務地と滞在履歴、駅のプレビュー表示用state
  const [residenceMesh, setResidenceMesh] = useState<MeshList>(
    makeMeshList(residenceUsersRef.current),
  );
  const [stayMesh, setStayMesh] = useState<MeshList>(
    makeMeshList(stayUsersRef.current),
  );
  const [stationsByLine, setStationsByLine] = useState<StationsByLine>(
    makeStationsByLine(Object.keys(stationUsersRef.current)),
  );
  const feedbackResidenceMesh = useCallback(() => {
    setResidenceMesh(makeMeshList(residenceUsersRef.current));
  }, []);
  const feedbackStayMesh = useCallback(() => {
    setStayMesh(makeMeshList(stayUsersRef.current));
  }, []);
  const feedbackStations = useCallback(() => {
    setStationsByLine(makeStationsByLine(Object.keys(stationUsersRef.current)));
  }, [makeStationsByLine]);

  // ローカル計算モードでのターゲット人数の算出
  const calcCount = useCallback(() => {
    const sum = calcUserCount([
      residenceUsersRef.current,
      stayUsersRef.current,
      stationUsersRef.current,
    ]);
    setCount(sum);
  }, []);

  // ユーザー属性フィルタ
  const {attrFilter} = useAttrFilter();
  // フォームのuse属性編集関数
  const {enabled, disabled} = useUseChanger();

  // ユーザー属性変化時にはユーザー属性の再適用を実施
  // ローカルで人数算出する場合
  useEffect(() => {
    if (areaMode !== 'areaTarget') {
      return;
    }
    console.log('[TargetingContainer] update areaTarget');
    if (!mountRef.current) {
      return; // 初回マウント時は空or計算済みなのでスルー
    }
    setCount('calculating');
    [
      residenceUsersRef.current,
      stayUsersRef.current,
      stationUsersRef.current,
    ].forEach((usersBy) => {
      _.forOwn(usersBy, (users) => {
        for (let i = 0; i < users.length; i++) {
          checkAttr(users[i], attrFilter);
        }
      });
    });
    calcCount();
  }, [areaMode, attrFilter, calcCount]);

  // サーバーで人数算出する場合（全国モード or 市区町村モード）
  useEffect(() => {
    if (areaMode === 'areaTarget') {
      return;
    }
    const userOpt = getUserOptionWithForm(getValues, areaMode) ?? {};
    if (initialState?.cities > 0) {
      userOpt.cityCode = initialState?.cities.map((city: any) => city.cityCode);
    }
    const prefCode = [];
    if (selectedRegionList.length > 0) {
      prefCode.push(
        ...selectedRegionList
          .map((selected) =>
            selected.prefs.map((prefecture) => prefecture.prefCode),
          )
          .flat(),
      );
    }
    if (selectedPrefList.length > 0) {
      prefCode.push(...selectedPrefList.map((pref) => pref.prefCode));
    }
    if (selectedCityList.length > 0) {
      userOpt.cityCode = selectedCityList.map((city) => city.id);
    }
    if (prefCode.length > 0) {
      userOpt.prefCode = prefCode;
    }
    if (areaMode === 'allArea' || userOpt.prefCode || userOpt.cityCode) {
      setCount('calculating');
      queue
        .add(async () => {
          if (queue.getQueueLength() > 0) {
            // より新しい問い合わせが存在するため、処理をSKIP
            return 'calculating';
          }
          return UserPropHelper.getUserCount(userOpt).catch(() => undefined);
        })
        .then((count) => {
          setCount(count ?? 'error');
        });
    } else {
      setCount(0);
    }
    // attrFilter 自体は使わないが、ユーザー属性が変化した時に本処理が動いて欲しいので指定が必要
  }, [
    areaMode,
    attrFilter,
    getValues,
    initialState,
    selectedRegionList,
    selectedPrefList,
    selectedCityList,
  ]);

  useEffect(() => {
    if (initialState?.cities) {
      restoreByTarget(initialState?.cities);
    }
  }, [initialState?.cities, restoreByTarget]);

  // エリア選択開始
  const prefListStringOld = useRef<string>(); // キャンセル時に戻す値の記憶用
  const settingArea = useCallback<UseTargeting['settingArea']>(
    (area) => {
      if (area === 'prefecture') {
        // 都道府県モードの場合は、戻す値を覚えておく必要がある
        return () => {
          prefListStringOld.current = getValues('areaPrefecture');
          setScreen(area);
        };
      }
      return () => setScreen(area);
    },
    [getValues],
  );

  // エリア選択キャンセル
  const dismissArea = useCallback(() => {
    // 都道府県モードの時は値を戻す必要がある
    if (areaMode === 'prefecture') {
      setValue('areaPrefecture', prefListStringOld.current);
    }
    setScreen('userAttr');
  }, [areaMode, setValue]);

  // エリア利用の解除
  const clearArea = useCallback<UseTargeting['clearArea']>(
    (area) => {
      return () => {
        if (area === 'areaResidence') {
          residenceUsersRef.current = {};
          feedbackResidenceMesh();
        } else if (area === 'areaStay') {
          stayUsersRef.current = {};
          feedbackStayMesh();
        } else if (area === 'station') {
          stationUsersRef.current = {};
          feedbackStations();
        } else if (area === 'prefecture') {
          setValue('areaPrefecture', undefined);
        }
        disabled(area); // useフラグを落とす
        if (area !== 'prefecture') {
          calcCount();
        }
      };
    },
    [
      calcCount,
      disabled,
      feedbackResidenceMesh,
      feedbackStations,
      feedbackStayMesh,
      setValue,
    ],
  );

  // 各エリアユーザー算出用コンテナに渡す初期値の生成
  const getResidenceInitial = useCallback<
    UseTargeting['getResidenceInitial']
  >(() => {
    const otherUsers = calcUserList([
      stayUsersRef.current,
      stationUsersRef.current,
    ]);
    const otherChoiceCount =
      Object.keys(stayUsersRef.current).length +
      Object.keys(stationUsersRef.current).length;
    return {
      attrFilter,
      otherUsers,
      otherChoiceCount,
      count: targetingUserCount,
      residenceUsers: residenceUsersRef.current,
    };
  }, [attrFilter, targetingUserCount]);
  const getStayInitial = useCallback<UseTargeting['getStayInitial']>(() => {
    const otherUsers = calcUserList([
      residenceUsersRef.current,
      stationUsersRef.current,
    ]);
    const otherChoiceCount =
      Object.keys(residenceUsersRef.current).length +
      Object.keys(stationUsersRef.current).length;
    return {
      attrFilter,
      otherUsers,
      otherChoiceCount,
      count: targetingUserCount,
      stayUsers: stayUsersRef.current,
    };
  }, [attrFilter, targetingUserCount]);
  const getStationInitial = useCallback<
    UseTargeting['getStationInitial']
  >(() => {
    const otherUsers = calcUserList([
      residenceUsersRef.current,
      stayUsersRef.current,
    ]);
    const otherChoiceCount =
      Object.keys(residenceUsersRef.current).length +
      Object.keys(stayUsersRef.current).length;
    return {
      attrFilter,
      otherUsers,
      otherChoiceCount,
      count: targetingUserCount,
      stationUsers: stationUsersRef.current,
    };
  }, [attrFilter, targetingUserCount]);

  // 各エリアコンテナからもらった情報をフィードバックして確定。
  const confirmResidence = useCallback<UseTargeting['confirmResidence']>(
    (residence, count) => {
      residenceUsersRef.current = residence;
      feedbackResidenceMesh();
      setCount(count);
      enabled('areaResidence');
      setScreen('userAttr');
    },
    [enabled, feedbackResidenceMesh],
  );
  const confirmStay = useCallback<UseTargeting['confirmStay']>(
    (stay, count) => {
      stayUsersRef.current = stay;
      feedbackStayMesh();
      setCount(count);
      enabled('areaStay');
      setScreen('userAttr');
    },
    [enabled, feedbackStayMesh],
  );
  const confirmStation = useCallback<UseTargeting['confirmStation']>(
    (station, count) => {
      stationUsersRef.current = station;
      feedbackStations();
      setCount(count);
      enabled('station');
      setScreen('userAttr');
    },
    [enabled, feedbackStations],
  );
  const confirmPrefecture = useCallback<
    UseTargeting['confirmPrefecture']
  >(() => {
    enabled('prefecture');
    setScreen('userAttr');
  }, [enabled]);

  // サーバーへの保存
  const {ownerId} = useOwnerId();
  const save = useCallback<UseTargeting['save']>(
    async (formData, fixed, shopId) => {
      const {id, title} = formData;
      // 送信オブジェクトを生成
      const dto: DeliveryTargetDto = {
        id,
        ownerId,
        shopId,
        title,
        areaOption:
          areaMode === 'areaTarget'
            ? getDtoAreaOption(residenceUsersRef.current, formData)
            : undefined,
        stayOption:
          areaMode === 'areaTarget'
            ? getDtoStayOption(stayUsersRef.current, formData)
            : undefined,
        stationOption:
          areaMode === 'areaTarget'
            ? getDtoStationOption(stationUsersRef.current, getStationName)
            : undefined,
        userOption: getDtoUserOption(formData, areaMode),
        users: getDtoCount(targetingUserCount),
        editState: fixed ? EditState.FIXED : EditState.EDITING,
      };
      if (areaMode === 'prefecture') {
        if (
          selectedRegionList.length > 0 ||
          selectedPrefList.length > 0 ||
          selectedCityList.length > 0
        ) {
          const cityOption = [
            ...selectedRegionList
              .map((selected) =>
                selected.prefs.map((pref) => {
                  const {prefCode, prefName} = pref;
                  return {
                    prefCode,
                    prefName,
                  };
                }),
              )
              .flat(),
            ...selectedPrefList.map((selected) => {
              const {prefCode, prefName} = selected;
              return {
                prefCode,
                prefName,
              };
            }),
            ...selectedCityList.map((selected) => {
              const {prefCode, id, prefName, cityName} = selected;
              return {
                prefCode,
                cityCode: id,
                prefName,
                cityName,
              };
            }),
          ];
          dto.cityOption = cityOption;
        }
      }
      console.log('[converted dto]', dto);
      return await SaaSTargetRepository.saveDeliveryTarget(dto);
    },
    [
      areaMode,
      getStationName,
      ownerId,
      targetingUserCount,
      selectedRegionList,
      selectedPrefList,
      selectedCityList,
    ],
  );

  // マウント完了したらコールバック
  useEffect(() => {
    if (mountRef.current) {
      return;
    }
    mountRef.current = true;
    if (!initialState || initialState?.areaMode === 'areaTarget') {
      // 初回計算
      calcCount();
    }
    initialState?.onMounted && initialState.onMounted();
  }, [calcCount, initialState]);

  return {
    screen,
    targetingUserCount,
    areaMode,
    residenceMesh,
    stayMesh,
    stationsByLine,
    setAreaMode,
    settingArea,
    dismissArea,
    clearArea,
    getResidenceInitial,
    getStayInitial,
    getStationInitial,
    confirmResidence,
    confirmStay,
    confirmStation,
    confirmPrefecture,
    save,
  };
}

export const TargetingContainer = createContainer(useTargeting);
