import {Auth} from 'aws-amplify';
import React from 'react';
import {Linking, StyleSheet, View} from 'react-native';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import {AccessManageRole, OwnerStatus, PaymentType} from '../../API';
import {AppConfig} from '../../_proto/config/AppConfig';
import {SaaSBillingRepository} from '../../_proto/services/SaaSBillingRepository';
import {
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  TrimaLoadingButton,
  VMargin,
} from '../../components/Elements';
import {
  AccessRoleContainer,
  OwnerContainer,
  PaymentInfoContainer,
  StepNavigationContainer,
} from '../../container';
import {Colors, CommonStyles} from '../../theme';

const priceId = AppConfig.StripePriceId;
const SUPPORT_SITE_URL = 'https://support.shop.trip-mile.com/hc/ja';

const Main: React.FC = React.memo(() => {
  const [isLoading, setLoading] = React.useState(false);
  const {checkInitData} = StepNavigationContainer.useContainer();
  const {owner, update} = OwnerContainer.useContainer();
  const {paymentInfo, createSubscription, createPaidSubscription} =
    PaymentInfoContainer.useContainer();
  const {accessRole} = AccessRoleContainer.useContainer();

  const reSubscribe = async () => {
    setLoading(true);
    if (!owner?.id || !paymentInfo) {
      throw new Error();
    }

    if (paymentInfo.subscriptionId) {
      await SaaSBillingRepository.notCancelSubscription({
        subscriptionId: paymentInfo.subscriptionId,
        customerId: paymentInfo.customerId,
      });
    } else {
      if (paymentInfo.paymentType === PaymentType.STRIPE) {
        await createSubscription(owner, priceId, paymentInfo.paymentMethodId);
      } else {
        await createPaidSubscription(owner, priceId);
      }
    }

    const user = await Auth.currentAuthenticatedUser();

    await update({
      id: owner?.id,
      status: OwnerStatus.ENABLED,
    });
    checkInitData(owner?.id, user.username);

    setLoading(false);
  };

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>企業停止中</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          <View>
            {owner?.status === OwnerStatus.BANNED && (
              <View>
                <FormBlock style={styles.accnentBgColor}>
                  <MaxWidth maxWidth={586}>
                    <View
                      style={[CommonStyles.flex.row, CommonStyles.flex.center]}
                    >
                      <FontAwesomeIcon style={styles.icon} name="ban" />
                    </View>
                    <VMargin />
                    <Text style={styles.whiteColor}>
                      お客様のご利用状況に重大な問題があり、企業アカウント：
                      {owner?.hojin}が停止されました。
                    </Text>
                    <Text style={styles.whiteColor}>
                      企業アカウント停止中のため、トリマ広告ダイレクトをご利用いただけません。
                    </Text>
                    <Text style={styles.whiteColor}>
                      配信中の広告は全て停止しております。
                    </Text>
                    <VMargin />
                    <Text style={styles.whiteColor}>
                      利用を再開したい方や、ご利用状況の問題点に心当たりのない方は、
                      <Text
                        style={CommonStyles.linkText}
                        onPress={() => Linking.openURL(SUPPORT_SITE_URL)}
                      >
                        こちら
                      </Text>
                      から問い合わせをお願いいたします。
                    </Text>
                  </MaxWidth>
                </FormBlock>
                <VMargin />
              </View>
            )}
            <FormBlock>
              <MaxWidth maxWidth={586}>
                {owner?.status !== OwnerStatus.BANNED && (
                  <View>
                    <Text>
                      現在利用停止中のため、企業アカウント：{owner?.hojin}
                      をご利用いただけません。
                    </Text>
                    <Text>
                      以下のボタンから、企業アカウント：{owner?.hojin}
                      のご利用を再開できます。
                    </Text>
                    <Text>
                      利用再開を行うと、当月分から利用料金の請求が再開されます。
                    </Text>
                    <VMargin />
                    <TrimaLoadingButton
                      variant="contained"
                      disabled={
                        accessRole !== AccessManageRole.OWNER || isLoading
                      }
                      loading={isLoading}
                      onClick={() => reSubscribe()}
                    >
                      利用再開
                    </TrimaLoadingButton>
                    <VMargin />
                    <VMargin />
                  </View>
                )}
                <VMargin />
                <Text>
                  トリマビジネスアカウントの削除をご希望の方は
                  <Text
                    style={CommonStyles.linkText}
                    onPress={() => Linking.openURL(SUPPORT_SITE_URL)}
                  >
                    こちら
                  </Text>
                  からお問い合わせをお願いいたします。
                </Text>
                <Text>
                  ※未払いの利用料金が残っている場合は、お支払いが全て完了した後に削除されます。
                </Text>
              </MaxWidth>
            </FormBlock>
            <VMargin />
          </View>
        </View>
      </MaxWidth>
    </Container>
  );
});

const styles = StyleSheet.create({
  accnentBgColor: {backgroundColor: Colors.accent},
  whiteColor: {color: Colors.white},
  icon: {color: Colors.white, fontSize: 120},
});

export const Suspend = {
  Main,
};
