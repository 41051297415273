import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/material';
import React from 'react';
import {
  useFormContext,
  UseFormGetValues,
  UseFormSetValue,
} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {Card, Dialog, IconButton, Portal} from 'react-native-paper';
import {Colors, CommonStyles} from '../../../theme';
import {
  MaxWidth,
  MinText,
  Text,
  TrimaButton,
  VForm,
  VMargin,
  WithHint,
} from '../../Elements';
import {useUseChanger, useWatchUse} from '../hooks';
import {
  ageLower,
  ageUpper,
  ATTR_LABELS,
  AttrForm,
  childrenLower,
  childrenTogether,
  childrenUpper,
  family,
  householdLower,
  householdUpper,
  incomeLower,
  incomeUpper,
  interest,
  isAllUserPropEnabled,
  job,
  moving,
  radio,
  residences,
  UserAttribute,
} from '../schema';

export const AttrCard: React.FC<{
  id: UserAttribute;
  children: React.ReactNode;
}> = ({id, children}) => {
  // 当該属性が使用されている時に属性カードを表示する
  const {isUse} = useWatchUse(id);
  return isUse ? <AttrCardInner id={id}>{children}</AttrCardInner> : null;
};

const AttrCardInner: React.FC<{
  id: UserAttribute;
  children: React.ReactNode;
}> = React.memo(({id, children}) => {
  return (
    <View style={styles.card}>
      <View style={styles.contentArea}>
        {ATTR_LABELS[id] && <Text>{ATTR_LABELS[id]}</Text>}
        <View style={CommonStyles.margin.top} />
        {children}
      </View>
      <AttrClose id={id} />
    </View>
  );
});

const AttrClose: React.FC<{id: UserAttribute}> = ({id}) => {
  const {disabled} = useUseChanger();
  const onClose = () => disabled(id);
  return (
    <View style={styles.buttonArea}>
      <IconButton
        icon="close-circle"
        onPress={onClose}
        size={32}
        color={Colors.main}
      />
      <MinText style={styles.clearLabel}>クリア</MinText>
    </View>
  );
};

const PostfixSelect: React.FC<{
  postfix?: string;
  children: React.ReactNode;
}> = ({postfix, children}) => {
  return (
    <View
      style={[
        CommonStyles.flex.row,
        CommonStyles.flex.center,
        styles.rightMargin,
      ]}>
      <View style={CommonStyles.flex.full}>{children}</View>
      <Text>{postfix}</Text>
    </View>
  );
};

const ChildrenForm: React.FC = () => {
  const {watch} = useFormContext<AttrForm>();
  // 同居の子供の年齢は子供がいる場合のみ有効にする
  const noChild =
    !watch('children.together') ||
    (watch('children.together') as unknown) === '0';
  return (
    <AttrCard id="children">
      <View style={CommonStyles.flex.reverse}>
        <VMargin />
        <PostfixSelect postfix=" 歳 未満">
          <VForm.Select<AttrForm> {...childrenUpper} disabled={noChild} />
        </PostfixSelect>
        <VMargin />
        <PostfixSelect postfix=" 歳 以上">
          <VForm.Select<AttrForm> {...childrenLower} disabled={noChild} />
        </PostfixSelect>
        <Text>子供の年齢</Text>
        <VMargin />
        <View style={styles.rightMargin}>
          <VForm.Select<AttrForm> {...childrenTogether} />
        </View>
        <Text>人数</Text>
      </View>
    </AttrCard>
  );
};

const AddAttrButtons: React.FC<{
  getValues: UseFormGetValues<AttrForm>;
  setValue: UseFormSetValue<AttrForm>;
  onDismiss: () => void;
}> = ({getValues, setValue, onDismiss}) => {
  const use = {...getValues('use')};
  const showAttr = (id: UserAttribute) => {
    use[id] = true;
    setValue('use', use);
    onDismiss();
  };
  // useが有効になってない場合にボタンを表示
  const RenderButton = (id: UserAttribute) => {
    return (
      !use[id] && (
        <Button sx={{height: 40}} onClick={() => showAttr(id)}>
          {ATTR_LABELS[id]}
        </Button>
      )
    );
  };
  return (
    <View>
      {RenderButton('gender')}
      {RenderButton('ageRange')}
      {RenderButton('married')}
      {RenderButton('residence')}
      {RenderButton('income')}
      {RenderButton('householdIncome')}
      {RenderButton('familyTogether')}
      {RenderButton('children')}
      {RenderButton('movingMethod')}
      {RenderButton('job')}
      {RenderButton('interest')}
    </View>
  );
};

const AddButton: React.FC = () => {
  const {setValue, getValues, watch} = useFormContext<AttrForm>();
  const [show, setShow] = React.useState<boolean>(false);
  const showDialog = () => setShow(true);
  const closeDialog = () => setShow(false);
  const disabled = isAllUserPropEnabled(watch('use'));
  return (
    <View>
      <VMargin />
      <TrimaButton
        variant="outlined"
        disabled={disabled}
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={showDialog}>
        条件を追加する
      </TrimaButton>
      <Portal>
        <Dialog visible={show} onDismiss={closeDialog}>
          <Card>
            <Card.Title title="属性を追加する" />
            <Card.Content style={CommonStyles.flex.center}>
              <View style={styles.addAttrContent}>
                <AddAttrButtons
                  setValue={setValue}
                  getValues={getValues}
                  onDismiss={closeDialog}
                />
              </View>
            </Card.Content>
          </Card>
        </Dialog>
      </Portal>
    </View>
  );
};

export const AttributeForms: React.FC = () => {
  return (
    <View style={CommonStyles.formBlock}>
      <View style={CommonStyles.flex.center}>
        <WithHint id="targetUser">
          <Text>対象者詳細設定</Text>
        </WithHint>
        <MinText>条件を外すときは右上の × ボタンを押してください</MinText>
      </View>
      {/*プルダウンが描画順の関係で隠れないように、フォーム部は逆順（下から）配置している*/}
      <MaxWidth
        maxWidth={CommonStyles.maxWidth.list}
        style={CommonStyles.flex.reverse}>
        <AddButton />
        <AttrCard id="movingMethod">
          <View style={styles.moving}>
            {moving.map((value) => {
              return (
                <View style={styles.movingItem} key={value.name}>
                  <VForm.Check<AttrForm> {...value} />
                </View>
              );
            })}
          </View>
        </AttrCard>
        <ChildrenForm />
        <AttrCard id="familyTogether">
          <View style={styles.rightMargin}>
            <VForm.Select<AttrForm> {...family} />
          </View>
          <VMargin />
        </AttrCard>
        <AttrCard id="householdIncome">
          <View style={CommonStyles.flex.reverse}>
            <VMargin />
            <PostfixSelect postfix=" 未満">
              <VForm.Select<AttrForm> {...householdUpper} />
            </PostfixSelect>
            <VMargin />
            <PostfixSelect postfix=" 以上">
              <VForm.Select<AttrForm> {...householdLower} />
            </PostfixSelect>
          </View>
        </AttrCard>
        <AttrCard id="income">
          <View style={CommonStyles.flex.reverse}>
            <VMargin />
            <PostfixSelect postfix=" 未満">
              <VForm.Select<AttrForm> {...incomeUpper} />
            </PostfixSelect>
            <VMargin />
            <PostfixSelect postfix=" 以上">
              <VForm.Select<AttrForm> {...incomeLower} />
            </PostfixSelect>
          </View>
        </AttrCard>
        <AttrCard id="residence">
          <View style={styles.residences}>
            {residences.map((value) => {
              return <VForm.Check<AttrForm> {...value} key={value.name} />;
            })}
          </View>
        </AttrCard>
        <AttrCard id="married">
          <VForm.Radio<AttrForm> {...radio.married} />
        </AttrCard>
        <AttrCard id="ageRange">
          <View style={CommonStyles.flex.reverse}>
            <VMargin />
            <PostfixSelect postfix=" 歳 未満">
              <VForm.Select<AttrForm> {...ageUpper} />
            </PostfixSelect>
            <VMargin />
            <PostfixSelect postfix=" 歳 以上">
              <VForm.Select<AttrForm> {...ageLower} />
            </PostfixSelect>
          </View>
        </AttrCard>
        <AttrCard id="gender">
          <VForm.Radio<AttrForm> {...radio.gender} />
        </AttrCard>
        <AttrCard id="job">
          <View style={styles.job}>
            {job.map((value) => {
              return <VForm.Check<AttrForm> {...value} key={value.name} />;
            })}
          </View>
        </AttrCard>
        <AttrCard id="interest">
          <View style={styles.interest}>
            {interest.map((value) => {
              return <VForm.Check<AttrForm> {...value} key={value.name} />;
            })}
          </View>
        </AttrCard>
      </MaxWidth>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.base,
    ...CommonStyles.margin.top,
  },
  buttonArea: {
    position: 'absolute',
    top: -10,
    right: 0,
  },
  contentArea: {
    ...CommonStyles.padding.all,
    paddingBottom: 0,
  },
  newButton: {
    ...CommonStyles.margin.top,
    height: 40,
  },
  // Layout
  moving: {
    ...CommonStyles.flex.row,
    flexWrap: 'wrap',
    ...CommonStyles.padding.bottom,
  },
  movingItem: {
    width: '50%',
    alignItems: 'flex-start',
  },
  residences: {
    alignItems: 'flex-start',
    ...CommonStyles.padding.bottom,
  },
  rightMargin: {
    marginRight: 32,
  },
  addAttrContent: {
    width: 250,
  },
  clearLabel: {
    position: 'relative',
    alignSelf: 'center',
    top: -20,
    color: Colors.main,
  },
  job: {
    alignItems: 'flex-start',
    ...CommonStyles.padding.bottom,
  },
  interest: {
    alignItems: 'flex-start',
    ...CommonStyles.padding.bottom,
  },
});
