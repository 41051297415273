import {SaaSTargetRepository} from '../../../_proto/services/SaaSTargetRepository';
import {UseStationCache} from '../StationCacheContainer';
import {
  AttrFilter,
  BaseUsersByStation,
  FetchedMeshsResidenceUser,
  FetchedMeshsStayUser,
  FetchedStationUserList,
  MasterCode,
  MeshCode,
  ResidenceFilter,
  ResidenceUsersByMesh,
  StationCode,
  StayFilter,
  StayUsersByMesh,
} from '../types';
import {checkAttr} from '../userAttribute/attrFilters';
import {checkResidence} from './residence/residenceFilters';
import {isMasterCode} from './station/stationFunc';
import {checkStay} from './stayHistory/stayHistoryFilters';

export type TaskType = () => Promise<boolean>;

// 居住地メッシュ単位処理タスク生成
export function makeResidenceFetcher(
  meshes: MeshCode[],
  output: ResidenceUsersByMesh,
  shopId: string,
  attrFilter?: AttrFilter,
  residenceFilter?: ResidenceFilter,
): TaskType {
  return async () => {
    try {
      const residences = (await SaaSTargetRepository.fetchMeshUsers({
        meshs: meshes,
        shopId,
      })) as FetchedMeshsResidenceUser;
      residences.meshs.forEach((mesh) => {
        for (let i = 0; i < mesh.users.length; i++) {
          checkAttr(mesh.users[i], attrFilter);
          checkResidence(mesh.users[i], residenceFilter);
        }
        output[mesh.mesh] = mesh.users ?? [];
      });
      return true;
    } catch (err: any) {
      // Todo エラー時処理？
      console.log('[makeResidenceFetcher] catch', err);
      return false;
    }
  };
}

export function makeStayFetcher(
  meshes: MeshCode[],
  output: StayUsersByMesh,
  shopId: string,
  attrFilter?: AttrFilter,
  stayFilter?: StayFilter,
): TaskType {
  return async () => {
    try {
      const stays = (await SaaSTargetRepository.fetchMeshStayUsers({
        meshs: meshes,
        shopId,
      })) as FetchedMeshsStayUser;
      stays.meshs.forEach((mesh) => {
        for (let i = 0; i < mesh.users.length; i++) {
          checkAttr(mesh.users[i], attrFilter);
          checkStay(mesh.users[i], stayFilter);
        }
        output[mesh.mesh] = mesh.users ?? [];
      });
      return true;
    } catch (err: any) {
      // Todo エラー時処理？
      console.log('[makeStayFetcher] catch', err);
      return false;
    }
  };
}

// 復帰時は新旧どちらの情報で格納されているか不定なので、どちらの場合でも使えるようにする
export function makeStationFetcher(
  stations: (StationCode | MasterCode)[],
  output: BaseUsersByStation,
  shopId: string,
  attrFilter?: AttrFilter,
  // 以下はリストア処理時の新旧コード吸収率処理用
  getMasterCode?: UseStationCache['getMasterCode'],
  getStationCode?: UseStationCache['getStationCode'],
  name?: string[],
): TaskType {
  return async () => {
    try {
      // 新コードの場合は後で路線を判別できるようにキャッシュさせておく
      if (getStationCode) {
        await Promise.all(
          stations.map((station) => {
            return isMasterCode(station) ? getStationCode(station) : undefined;
          }),
        );
      }
      // 新コード一覧へ統一
      const masterCodes: MasterCode[] = await Promise.all(
        stations.map((station, index) => {
          if (isMasterCode(station)) {
            return station;
          }
          // 旧コードの場合、新コードへ差し替えて復帰させる
          if (getMasterCode) {
            return getMasterCode(station, name ? name[index] : undefined);
          }
          return '';
        }),
      );
      // 駅毎のユーザーの取得
      const fetched = (await SaaSTargetRepository.fetchStationUsers({
        stations: masterCodes.filter((c) => !!c),
        shopId,
      })) as FetchedStationUserList;
      // 属性フィルタ適用して格納
      (fetched?.stations ?? []).forEach((station) => {
        if (station.users) {
          for (let i = 0; i < station.users.length; i++) {
            checkAttr(station.users[i], attrFilter);
          }
        }
        output[station.station] = station.users ?? [];
      });
      return true;
    } catch (err: any) {
      // Todo エラー時処理？
      console.log('[makeStationFetcher] catch', err);
      return false;
    }
  };
}
