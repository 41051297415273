import React from 'react';
import {useFormContext} from 'react-hook-form';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {IconButton, RadioButton, useTheme} from 'react-native-paper';
import {Colors, CommonStyles} from '../../../theme';
import {
  HintButton,
  MaxWidth,
  MinText,
  SheetInner,
  SheetOuter,
  Text,
  VMargin,
  WithHint,
} from '../../Elements';
import {HintPattern} from '../../Elements/Hint';
import {CitySearchContainer} from '../CitySearchContainer';
import {TargetingContainer} from '../TargetingContainer';
import {getCountByMesh, getCountBySbyL} from '../commonFunc';
import {Area, AreaMode, AttrForm} from '../schema';
import {ChoiceCounter} from './ChoiceCounter';
import {LimitCautionProvider} from './LimitCautionDialog';
import {PrefectureContainer} from './prefecture/PrefectureContainer';
import PrefectureSetting from './prefecture/PrefectureSetting';
import {PrefecturePreviewWithForm} from './preview/PrefecturePreview';
import {ResidencePreviewWithEdit} from './preview/ResidencePreview';
import {StationPreviewWithEdit} from './preview/StationPreview';
import {StayPreviewWithEdit} from './preview/StayPreview';
import ResidenceSetting from './residence/ResidenceSetting';
import StationSetting from './station/StationSetting';
import StaySetting from './stayHistory/StaySetting';

const AREA_TARGETING_INFORMATION = `複数の条件を設定できます。
1つ以上に該当する人が対象者になります。`;
const PREFECTURE_INFORMATION = `複数の市区町村を設定できます。
選択した市区町村に居住するトリマユーザーが対象者になります。`;

type AreaPreviewProp = {
  type: Area;
};
const AreaPreview: React.FC<AreaPreviewProp> = (props: AreaPreviewProp) => {
  switch (props.type) {
    case 'areaResidence':
      return <ResidencePreviewWithEdit />;
    case 'areaStay':
      return <StayPreviewWithEdit />;
    case 'station':
      return <StationPreviewWithEdit />;
    case 'prefecture':
      return <PrefecturePreviewWithForm />;
  }
};

const HINT: {[P in Area]?: HintPattern} = {
  areaResidence: 'targetResidence',
  areaStay: 'targetStay',
  station: 'targetStation',
};

const AreaButton: React.FC<{id: Area; children: React.ReactNode}> = ({
  id,
  children,
}) => {
  const {colors} = useTheme();
  const {watch} = useFormContext<AttrForm>();
  const {areaMode, settingArea, clearArea} = TargetingContainer.useContainer();
  const {clearAll} = PrefectureContainer.useContainer();
  const {clearSearch} = CitySearchContainer.useContainer();

  const use = watch('use');
  const selected = use && use[id];

  const disabled =
    id === 'prefecture' ? areaMode !== 'prefecture' : areaMode !== 'areaTarget';
  const onPress = settingArea(id);
  const onClose = clearArea(id);

  // 選択中の時は選択内容の表示
  if (selected) {
    return (
      <View style={styles.areaCard}>
        <Text style={styles.centering}>{children}</Text>
        <AreaPreview type={id} />
        <View style={styles.buttonArea}>
          <IconButton
            icon="close-circle"
            onPress={() => {
              onClose();
              clearAll();
              clearSearch();
            }}
            size={32}
            color={Colors.darkgray}
          />
        </View>
      </View>
    );
  }
  const hint = HINT[id];
  // 未選択の時は追加ボタン
  const background = disabled ? styles.areaButtonDisabled : {};
  return (
    <View>
      <View style={[styles.areaButton, background]}>
        {hint !== undefined ? (
          <WithHint id={hint}>
            <Text style={CommonStyles.margin.left}>{children}</Text>
          </WithHint>
        ) : (
          <Text style={CommonStyles.margin.left}>{children}</Text>
        )}
        <IconButton
          icon="plus-circle"
          onPress={() => {
            onPress();
            clearAll();
          }}
          size={30}
          color={colors.primary}
          disabled={disabled}
        />
      </View>
    </View>
  );
};

const AreaTargetingWarning: React.FC = () => {
  const {areaMode} = TargetingContainer.useContainer();
  const {watch} = useFormContext<AttrForm>();
  const hide =
    areaMode !== 'areaTarget' ||
    watch('use.areaResidence') ||
    watch('use.areaStay') ||
    watch('use.station');
  if (hide) {
    return null;
  }
  return (
    <View style={CommonStyles.margin.top}>
      <MinText style={styles.warningText}>
        条件を1つ以上設定してください。
      </MinText>
    </View>
  );
};

const PrefectureWarning: React.FC = () => {
  const {areaMode} = TargetingContainer.useContainer();
  const {watch} = useFormContext<AttrForm>();
  const hide = areaMode !== 'prefecture' || watch('use.prefecture');
  if (hide) {
    return null;
  }
  return (
    <View style={CommonStyles.margin.top}>
      <MinText style={styles.warningText}>
        市区町村を1つ以上設定してください。
      </MinText>
    </View>
  );
};

const ChoiceSum: React.FC = () => {
  const {residenceMesh, stayMesh, stationsByLine} =
    TargetingContainer.useContainer();
  let count = 0;
  count += getCountByMesh(residenceMesh);
  count += getCountByMesh(stayMesh);
  count += getCountBySbyL(stationsByLine);
  return <ChoiceCounter type="all" count={count} />;
};

export const AreaForms: React.FC = () => {
  const {colors, fonts} = useTheme();
  const {areaMode, screen, setAreaMode, dismissArea} =
    TargetingContainer.useContainer();
  const {clearExpand} = PrefectureContainer.useContainer();
  const {watch} = useFormContext<AttrForm>();
  // 各ボタンのDisabled条件
  const disabledAreaTarget = watch('use.prefecture');
  const disabledPrefecture =
    watch('use.areaResidence') || watch('use.areaStay') || watch('use.station');
  const disabledAllArea = disabledAreaTarget || disabledPrefecture;

  const weight = {fontWeight: fonts.medium.fontWeight};
  const fontColor = (disabled: boolean) =>
    disabled ? {color: Colors.darkgray} : {};
  const onAreaModeChange = (mode: string) => {
    setAreaMode(mode as AreaMode);
  };
  const isSheet = screen !== 'init' && screen !== 'userAttr';

  return (
    <View style={CommonStyles.formBlock}>
      <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
        <Text style={styles.centering}>エリア</Text>
        <RadioButton.Group value={areaMode} onValueChange={onAreaModeChange}>
          <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
            <RadioButton
              value={'areaTarget' as AreaMode}
              color={colors.primary}
              disabled={disabledAreaTarget}
            />
            <TouchableOpacity
              disabled={disabledAreaTarget}
              onPress={() => onAreaModeChange('areaTarget')}>
              <Text style={[weight, fontColor(disabledAreaTarget as boolean)]}>
                条件でエリアを選択する
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.areaTarget}>
            <MinText>{AREA_TARGETING_INFORMATION}</MinText>
            <AreaTargetingWarning />
            <VMargin />
            <View>
              <AreaButton id="areaResidence">居住地・勤務地</AreaButton>
              <AreaButton id="areaStay">滞在履歴</AreaButton>
              <AreaButton id="station">利用駅</AreaButton>
            </View>
            <VMargin />
            <ChoiceSum />
          </View>
          <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
            <RadioButton
              value={'prefecture' as AreaMode}
              color={colors.primary}
              disabled={disabledPrefecture}
            />
            <TouchableOpacity
              disabled={disabledPrefecture}
              onPress={() => onAreaModeChange('prefecture')}>
              <Text style={[weight, fontColor(disabledPrefecture as boolean)]}>
                市区町村を選択する
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.areaTarget}>
            <MinText>{PREFECTURE_INFORMATION}</MinText>
            <PrefectureWarning />
            <VMargin />
            <AreaButton id="prefecture">市区町村</AreaButton>
            <VMargin />
          </View>
          <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
            <RadioButton
              value={'allArea' as AreaMode}
              color={colors.primary}
              disabled={disabledAllArea}
            />
            <TouchableOpacity
              disabled={disabledAllArea}
              onPress={() => onAreaModeChange('allArea')}>
              <Text style={[weight, fontColor(disabledAllArea as boolean)]}>
                全国へ配信
              </Text>
            </TouchableOpacity>
            <HintButton id="targetAllArea" />
          </View>
        </RadioButton.Group>
      </MaxWidth>
      <SheetOuter isVisible={isSheet}>
        <LimitCautionProvider>
          <SheetInner
            onDismiss={() => {
              dismissArea();
              clearExpand();
            }}>
            {screen === 'areaResidence' && <ResidenceSetting />}
            {screen === 'areaStay' && <StaySetting />}
            {screen === 'station' && <StationSetting />}
            {screen === 'prefecture' && <PrefectureSetting />}
          </SheetInner>
        </LimitCautionProvider>
      </SheetOuter>
    </View>
  );
};

export const styles = StyleSheet.create({
  centering: {textAlign: 'center'},
  buttonArea: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  areaCard: {
    ...CommonStyles.padding.all,
    paddingBottom: 0,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.darkgray,
  },
  areaTarget: {marginLeft: 36},
  areaButton: {
    height: 40,
    alignSelf: 'stretch',
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.darkgray,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  areaButtonDisabled: {
    backgroundColor: Colors.base,
  },
  warningText: {
    color: Colors.accent,
  },
});
