import {Box} from '@mui/material';
import _ from 'lodash';
import React, {useState} from 'react';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {ProgressBar} from 'react-native-paper';
import {SaaSDeliveryTarget} from '../../API';
import {AppConfig} from '../../_proto/config/AppConfig';
import {ShopContainer, TargetsContainer} from '../../container';
import {ScreenType} from '../../navigation/MainScreen';
import {Colors, CommonStyles, Message} from '../../theme';
import {
  BackLink,
  Container,
  DoubleButtons,
  HelperText,
  MaxWidth,
  Menu,
  MinText,
  Text,
  TrimaLoadingButton,
  VForm,
  VMargin,
  WithHint,
} from '../Elements';
import {BRAKE_POINT, useWidth} from '../Responsive';
import {CitySearchContainer} from './CitySearchContainer';
import {PersonBadge, PersonInfo} from './Counters';
import {
  RestoreContainer,
  RestoreInitState,
  RestoringState,
} from './RestoreContainer';
import {TargetingContainer} from './TargetingContainer';
import {AreaForms} from './area/AreaForms';
import {PrefectureContainer} from './area/prefecture/PrefectureContainer';
import {isUnderLimit} from './commonFunc';
import {convertForms} from './convert';
import {AttrForm, USE_DEFAULT, schemaAttrForm, title} from './schema';
import {TargetsInitialState} from './types';
import {AttributeForms} from './userAttribute/AttributeForms';

const INFO_TARGETING = `配信する対象者を設定してください。
複数の条件を組み合わせて詳細なターゲティングが可能です。`;

function getBackButtonLabel(
  editMode?: TargetFormProps['editMode'],
  from?: ScreenType,
): string | undefined {
  if (editMode === 'edit') {
    return Message.BackNoEdit;
  } else if (editMode === 'copy') {
    return Message.BackNoCopy;
  } else if (from === 'CreativesCreate') {
    return Message.BackToCreatives;
  } else if (from === 'TargetsMain') {
    return Message.BackToList;
  }
  return undefined;
}

function getMenuLabel(editMode?: TargetFormProps['editMode']): string {
  return editMode === 'edit'
    ? '配信先を編集'
    : editMode === 'copy'
    ? '配信先を複製'
    : '配信先を作成';
}

const Heading: React.FC<{
  editMode?: TargetFormProps['editMode'];
  from?: ScreenType;
  onBack?(): void;
}> = ({editMode, from, onBack}) => {
  const backLabel = onBack ? getBackButtonLabel(editMode, from) : undefined;
  return (
    <View>
      {backLabel && (
        <View style={CommonStyles.margin.bottom}>
          <Box sx={{width: 'inherit'}}>
            <BackLink label={backLabel} onClick={onBack} />
          </Box>
        </View>
      )}
      <View style={[CommonStyles.flex.row, CommonStyles.flex.between]}>
        <WithHint id="targetsCreate">
          <Menu>{getMenuLabel(editMode)}</Menu>
        </WithHint>
      </View>
      <View style={CommonStyles.margin.top} />
      <MinText>{INFO_TARGETING}</MinText>
    </View>
  );
};

const Submit: React.FC<{
  from?: ScreenType;
  onDone?(type: SubmitType, result: {id: string}): void;
  group?: 'on';
}> = ({from, onDone, group}) => {
  const {
    handleSubmit,
    setError,
    setValue,
    formState: {errors},
  } = useFormContext<AttrForm>();
  const {checkedIds} = TargetsContainer.useContainer();
  const {save, targetingUserCount} = TargetingContainer.useContainer();
  const {selected} = ShopContainer.useContainer();
  const {clearAll} = PrefectureContainer.useContainer();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const getHandler: (type: SubmitType) => SubmitHandler<AttrForm> = (type) => {
    return async (data) => {
      setLoading(true);
      try {
        if (group !== 'on') {
          const result = await save(
            data,
            type !== 'editing',
            selected?.id ?? '',
          );
          clearAll();
          const id = result?.id ?? '';
          setValue('id', id);
          onDone && onDone(type, {id});
        } else {
          await Promise.all(
            checkedIds.map(async (shopId: string) => {
              await save(data, type !== 'editing', shopId);
            }),
          );
          clearAll();
          onDone && onDone(type, {id: ''});
        }
      } catch (err: any) {
        setError('id', {
          type: 'manual',
          message: `保存に失敗しました（${JSON.stringify(err)}）`,
        });
      } finally {
        setLoading(false);
      }
    };
  };
  const onFix = getHandler('fixed');
  const onDelivery = getHandler('fixAndGo');

  const onError: SubmitErrorHandler<AttrForm> = (fErrors) => {
    setError('id', {
      type: 'manual',
      message: `フォームエラー（${JSON.stringify(fErrors)}）`,
    });
  };

  const canSubmit = !isUnderLimit(targetingUserCount);
  return (
    <View style={styles.formBlock}>
      <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
        <VForm.Text<AttrForm> {...title} />
        {errors.id && (
          <HelperText type="error">
            {errors.id ? errors.id.message : ''}
          </HelperText>
        )}
      </MaxWidth>
      {group !== 'on' ? (
        <DoubleButtons
          button1={
            from === 'CreativesCreate' ? undefined : (
              <TrimaLoadingButton
                variant="outlined"
                onClick={handleSubmit(onFix, onError)}
                loading={isLoading}
                loadingPosition="start"
                disabled={!canSubmit || isLoading}>
                保存して終了
              </TrimaLoadingButton>
            )
          }
          button2={
            <TrimaLoadingButton
              variant="contained"
              onClick={handleSubmit(onDelivery, onError)}
              loading={isLoading}
              loadingPosition="start"
              disabled={!canSubmit || isLoading}>
              保存して配信
            </TrimaLoadingButton>
          }
        />
      ) : (
        <MaxWidth maxWidth={586}>
          <TrimaLoadingButton
            variant="outlined"
            onClick={handleSubmit(onFix, onError)}
            loading={isLoading}
            loadingPosition="start"
            disabled={!canSubmit || isLoading}>
            保存して終了
          </TrimaLoadingButton>
        </MaxWidth>
      )}
    </View>
  );
};

export type SubmitType = 'editing' | 'fixed' | 'fixAndGo';

const FloatingCounter: React.FC = () => {
  const {targetingUserCount, screen} = TargetingContainer.useContainer();
  const {contentWidth} = useWidth();
  if (screen !== 'init' && screen !== 'userAttr') {
    return null;
  }
  const rightOffset = {
    right: Math.max(
      (contentWidth - 1024) / 2,
      CommonStyles.margin.right.marginRight,
    ),
  };
  return (
    <View style={[styles.persons, rightOffset]}>
      <PersonBadge target={targetingUserCount} />
    </View>
  );
};

const FixedCounter: React.FC = () => {
  const {targetingUserCount} = TargetingContainer.useContainer();
  return <PersonInfo target={targetingUserCount} />;
};

export type TargetFormProps = {
  editMode?: 'edit' | 'copy';
  editTarget?: SaaSDeliveryTarget;
  onDone?(type: SubmitType, result: {id: string}): void;
  // 戻る遷移用
  from?: ScreenType;
  onBack?(): void;
  group?: 'on';
};
type RestoredState = 'restoring' | 'editing';

export const TargetForms: React.FC<TargetFormProps> = (props) => {
  const {editTarget, ...rest} = props;

  // フォーム初期値を生成。編集時は取得データから生成することでフォーム状態が復帰する。
  const [defaultValues] = useState<AttrForm>(
    editTarget && props.editMode
      ? convertForms(editTarget, props.editMode)
      : _.cloneDeep(USE_DEFAULT),
  );

  // リストア処理関係。編集時は RestoredComponent をまず描画してユーザーデータを取得。
  // 取得したデータをTargetingContainer初期値として渡すことで編集可能な状態に復帰する。
  const [restoreState, setRestoreState] = React.useState<RestoredState>(
    rest.editMode ? 'restoring' : 'editing',
  );
  const targetInitRef = React.useRef<TargetsInitialState | undefined>();
  const onRestored = (restored: TargetsInitialState) => {
    restored.onMounted = () => {
      // 利用完了したら、参照を保持し続けると解放されない気がするので、参照を外す
      targetInitRef.current = undefined;
    };
    // リストアコンポーネントから渡されたデータを配信先コンテナ初期値として渡すために一時保持
    targetInitRef.current = restored;
    setRestoreState('editing');
  };

  return (
    <VForm.Provider<AttrForm>
      schema={schemaAttrForm}
      defaultValues={defaultValues}>
      {restoreState === 'restoring' && (
        <RestoredComponent
          onRestored={onRestored}
          editTarget={editTarget}
          editMode={props.editMode}
        />
      )}
      {restoreState === 'editing' && (
        <TargetingContainer.Provider initialState={targetInitRef.current}>
          <CitySearchContainer.Provider>
            <TargetFormsInner {...rest} />
          </CitySearchContainer.Provider>
        </TargetingContainer.Provider>
      )}
    </VForm.Provider>
  );
};

const Information: React.FC = React.memo(() => {
  return (
    <View style={CommonStyles.formBlock}>
      <Text>
        {'配信対象者数 ＝ トリマアプリを起動したらクーポンが表示される人の総数'}
      </Text>
      <MinText>
        {'※配信期間内にトリマを起動しない人、クーポン画面を見ない人を含む'}
      </MinText>
      <VMargin />
      <Text>
        {
          'クーポンを届けたい人数よりも配信対象者が十分多くなるように、エリアの設定を調節してください。'
        }
      </Text>
      <Text>
        {
          '配信時に予算上限を設定するため、配信対象者数が多すぎてもご利用金額が高額になることはありません。'
        }
      </Text>
    </View>
  );
});

const CountCaution: React.FC = React.memo(() => {
  const {targetingUserCount} = TargetingContainer.useContainer();
  const isLimit = isUnderLimit(targetingUserCount);
  if (!isLimit) {
    return null;
  }
  return (
    <HelperText type="error" style={styles.cautionText}>
      配信対象が{AppConfig.MinTargetCount}人未満の場合は配信できません。
    </HelperText>
  );
});

const TargetFormsInner: React.FC<Omit<TargetFormProps, 'editTarget'>> = ({
  editMode,
  onDone,
  onBack,
  from,
  group,
}) => {
  return (
    <View>
      <Container>
        <MaxWidth
          maxWidth={BRAKE_POINT.desktop}
          style={[CommonStyles.margin.all, CommonStyles.flex.reverse]}>
          <Submit onDone={onDone} from={from} group={group} />
          <CountCaution />
          <VMargin />
          <Information />
          <VMargin />
          <FixedCounter />
          <VMargin />
          <AttributeForms />
          <VMargin />
          <AreaForms />
          <VMargin />
          <Heading editMode={editMode} from={from} onBack={onBack} />
        </MaxWidth>
      </Container>
      <FloatingCounter />
    </View>
  );
};

const RestoredComponent: React.FC<{
  editTarget?: SaaSDeliveryTarget;
  editMode: TargetFormProps['editMode'];
  onRestored(restored: TargetsInitialState): void;
}> = ({editTarget, editMode, onRestored}) => {
  // リストアコンテナに渡す情報を生成
  const [init] = useState<RestoreInitState>({
    residenceMeshes: (editTarget?.areaOption?.meshs ?? undefined) as
      | string[]
      | undefined,
    stayMeshes: (editTarget?.stayOption?.meshs ?? undefined) as
      | string[]
      | undefined,
    stations: (editTarget?.stationOption ?? undefined) as string[] | undefined,
    cities: editTarget?.cityOption ?? undefined,
    onComplete: onRestored,
  });

  return (
    <RestoreContainer.Provider initialState={init}>
      <RestoredProgress editMode={editMode} />
    </RestoreContainer.Provider>
  );
};

// リストアの進捗表示
const RESTORE_MSG: {[P in RestoringState]: string} = {
  init: '',
  areaResidence: '居住地・勤務地のデータを取得中です。',
  areaStay: '滞在履歴のデータを取得中です。',
  station: '利用駅のデータを取得中です。',
  done: '完了しました',
  error: `配信先データの取得エラーが発生しました。
編集開始できません。`,
};

const RestoredProgress: React.FC<{editMode: TargetFormProps['editMode']}> = ({
  editMode,
}) => {
  const {progress, restoringState} = RestoreContainer.useContainer();
  return (
    <MaxWidth maxWidth={BRAKE_POINT.desktop} style={[CommonStyles.margin.all]}>
      <Menu>
        配信先を{editMode === 'copy' ? '複製' : '編集'}：データを取得中
      </Menu>
      <VMargin />
      <Text>{RESTORE_MSG[restoringState]}</Text>
      <VMargin />
      {progress && <Text>{`${progress.complete} / ${progress.all}`}</Text>}
      {progress && <ProgressBar progress={progress.complete / progress.all} />}
    </MaxWidth>
  );
};

const styles = StyleSheet.create({
  formBlock: {
    ...CommonStyles.formBlock,
    backgroundColor: Colors.lightgray,
  },
  persons: {
    position: 'absolute',
    top: CommonStyles.margin.top.marginTop,
  },
  cautionText: {
    color: Colors.accent,
  },
});
