import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {
  Container,
  FormBlock,
  Header,
  HelperText,
  MaxWidth,
  Menu,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
  yup,
} from '../../components/Elements';
import {CheckboxProps} from '../../components/Elements/Forms';
import {UserInfoContainer} from '../../container/UserInfoContainer';
import {useCustomNav} from '../../helper';
import {AccountMenu} from '../../navigation/AccountMenu';
import {CommonStyles} from '../../theme';

const schemaSendType: yup.SchemaOf<SendType> = yup.object({
  endOfDelivery: yup.boolean(),
  shortBalance: yup.boolean(),
});

type SendType = {
  endOfDelivery?: boolean;
  shortBalance?: boolean;
};

export const sendReportEmail: CheckboxProps<SendType>[] = [
  {name: 'endOfDelivery', label: '広告配信結果レポートメール'},
  {name: 'shortBalance', label: '予算上限到達通知メール'},
];

const Submit: React.FC = () => {
  const {userInfo, update} = UserInfoContainer.useContainer();
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    handleSubmit,
    formState: {isValid},
    reset,
  } = useFormContext<SendType>();
  const navigation = useNavigation();

  useEffect(() => {
    reset({
      endOfDelivery: !!userInfo?.sendReportEmail?.endOfDelivery,
      shortBalance: !!userInfo?.sendReportEmail?.shortBalance,
    }); // asynchronously reset your form values
  }, [reset, userInfo]);

  const register = async (formData: SendType, navigation: any) => {
    setLoading(true);
    try {
      if (!userInfo) {
        throw new Error('userInfo is null or undefined');
      }
      const newUserInfo = await update({
        id: userInfo.id,
        sendReportEmail: formData,
      });
      console.log('[UserInfoRegister] submit convert', newUserInfo);
      navigation.navigate('AccountMain');
    } catch (error: any) {
      console.log('[OwnerRegister] submit error', error);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };
  const helper = !isValid ? '必要項目の入力をお願いします' : errorMsg;

  return (
    <View>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit((data) => register(data, navigation))}
        disabled={!isValid || isLoading}
        loading={isLoading}
        loadingPosition="start">
        メール送信設定を変更
      </TrimaLoadingButton>
      <HelperText type="error">{helper}</HelperText>
    </View>
  );
};

const SendEmailComponent: React.FC = () => {
  const navigator = useCustomNav();
  const goBack = () => {
    navigator.goBack('AccountMain');
  };
  const {userInfo} = UserInfoContainer.useContainer();

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>メール送信設定の変更</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          {!userInfo ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <FormBlock>
                <MaxWidth maxWidth={586}>
                  <VForm.Provider<SendType> schema={schemaSendType}>
                    <View style={styles.residences}>
                      {sendReportEmail.map((value) => {
                        return (
                          <VForm.Check<SendType> {...value} key={value.name} />
                        );
                      })}
                    </View>
                    <VMargin />
                    <VMargin />
                    <Submit />
                  </VForm.Provider>

                  <TrimaButton variant="outlined" onClick={() => goBack()}>
                    キャンセル
                  </TrimaButton>
                </MaxWidth>
              </FormBlock>
            </View>
          )}
        </View>
      </MaxWidth>
    </Container>
  );
};

export const ChangeSendEmail: React.FC = () => {
  const route = useRoute<any>();
  const [showHeader, setShowHeader] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (route.params?.header) {
      setShowHeader(route.params?.header);
    }
  }, [route.params]);
  if (showHeader) {
    return (
      <View>
        <Header rightItem={<AccountMenu />} />
        <SendEmailComponent />
      </View>
    );
  } else {
    return <SendEmailComponent />;
  }
};

const styles = StyleSheet.create({
  residences: {
    alignItems: 'flex-start',
  },
});
