import {yupResolver} from '@hookform/resolvers/yup';
import {Close} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {View} from 'react-native';
import * as yup from 'yup';
import {Colors, CommonStyles} from '../../theme';
import {HelperText, RequiredNotice, Text, VMargin} from '../Elements';

export type EditFormInput = {
  name: string;
  exp_month: string;
  exp_year: number;
};

type PaymentMethod = {
  billing_details: {
    name: string;
  };
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  };
  default: boolean;
  id: string;
};

const schema = yup
  .object({
    name: yup.string().required('必須の項目です'),
    exp_month: yup.string().required('必須の項目です'),
    exp_year: yup.number().positive().integer().required('必須の項目です'),
  })
  .required();

export const EditCardDialog: React.FC<{
  item: PaymentMethod;
  visible: boolean;
  onConfirm(data: EditFormInput): Promise<void>;
  onClose(): void;
}> = ({item, visible, onConfirm, onClose}) => {
  const [processing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const {billing_details, card} = item;
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<EditFormInput>({
    defaultValues: {
      name: billing_details.name,
      exp_month: card.exp_month.toString().padStart(2, '0'),
      exp_year: card.exp_year - 2000,
    },
    resolver: yupResolver(schema),
  });
  const handleCancel = () => {
    onClose();
  };

  const handleOk: SubmitHandler<EditFormInput> = async (data) => {
    setProcessing(true);
    setError(null);
    try {
      await onConfirm(data);
      onClose();
    } catch (err: any) {
      console.log(err);
      setError(Error('更新できませんでした'));
    }
    setProcessing(false);
  };

  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: Colors.lightgray,
          m: 2,
        },
      }}
      fullWidth={true}
      maxWidth="sm"
      onClose={handleCancel}
      open={visible}>
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>お支払い方法の編集</Box>
          <Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <View>
          <Typography variant="h5" component="span">
            {card.brand.toUpperCase()} ****{card.last4}
          </Typography>
          <VMargin />
          <form autoComplete="off" onSubmit={handleSubmit(handleOk)}>
            <View style={CommonStyles.flex.full}>
              <View
                style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
                <label htmlFor="name">カード名義</label>
                <RequiredNotice />
              </View>
              <Controller
                name="name"
                control={control}
                render={({field}) => (
                  <TextField
                    {...field}
                    size="small"
                    margin="dense"
                    required
                    placeholder="HANAKO TANAKA"
                    variant="outlined"
                    error={'name' in errors}
                    helperText={errors.name?.message}
                  />
                )}
              />
            </View>
            <VMargin />
            <View style={CommonStyles.flex.full}>
              <View
                style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
                <label htmlFor="expiry">有効期限(MM/YY)</label>
                <RequiredNotice />
              </View>
              <View
                style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
                <Controller
                  name="exp_month"
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      size="small"
                      margin="dense"
                      required
                      placeholder="月"
                      variant="outlined"
                      error={'exp_month' in errors}
                      helperText={errors.exp_month?.message}
                    />
                  )}
                />
                <Text>{' / '}</Text>
                <Controller
                  name="exp_year"
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      size="small"
                      margin="dense"
                      required
                      placeholder="年"
                      variant="outlined"
                      error={'exp_year' in errors}
                      helperText={errors.exp_year?.message}
                    />
                  )}
                />
              </View>
            </View>
            <VMargin />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={processing}
              sx={{fontSize: '1rem', height: '56px'}}
              fullWidth>
              {processing && (
                <CircularProgress sx={{m: 1}} color="primary" size={28} />
              )}
              クレジットカードを更新
            </Button>
            <HelperText type="error">{error?.message}</HelperText>
          </form>
        </View>
      </DialogContent>
      <VMargin />
    </Dialog>
  );
};
