import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
// import InfiniteScroll from 'react-infinite-scroller';
// import InfiniteScroll from 'react-infinite-scroll-component';
import {useFormContext} from 'react-hook-form';
import {ActivityIndicator, HelperText} from 'react-native-paper';
import {BRAKE_POINT} from '../../../components';
import {
  BackLink,
  Container,
  MaxWidth,
  Menu,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../../../components/Elements';
import {
  OwnerContainer,
  ShopGroupsContainer,
  ShopsContainer,
} from '../../../container';
import {
  ShopGroupFormData,
  schemaShopGroupFormData,
} from '../../../container/ShopGroupsContainer';
import {
  MainNavigationProp,
  MainRouteProp,
} from '../../../navigation/MainScreen';
import {CommonStyles} from '../../../theme';
import {ExcludeVisitorsContainer} from '../ExcludeVisitors/ExcludeVisitorsContainer';

type NProp = MainNavigationProp<'GroupsEdit'>;
type RProp = MainRouteProp<'GroupsEdit'>;

const EditGroupSubmit: React.FC<{shopGroup: any}> = ({shopGroup}) => {
  const {owner} = OwnerContainer.useContainer();
  const {update, isCreateLoading} = ShopGroupsContainer.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<ShopGroupFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const disabled = isCreateLoading || !watch('name') || !!errors.name;

  if (!owner) {
    return null;
  }
  return (
    <View>
      <TrimaLoadingButton
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(async (data) => {
          await update({
            ...data,
            id: shopGroup.id,
            ownerId: shopGroup.ownerId,
          });
        }, onSubmitError)}
        loading={isCreateLoading}
        loadingIndicator={'店舗情報を修正中'}
      >
        店舗情報を修正
      </TrimaLoadingButton>
      <HelperText type="error">{errors?.name?.message}</HelperText>
    </View>
  );
};

export const Edit: React.FC = () => {
  // Todo コンテナ化した方がいいかは要検討
  const {shopGroups} = ShopGroupsContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const navigation = useNavigation<NProp>();

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('ShopsMain');
    }
  };

  return (
    <ExcludeVisitorsContainer.Provider>
      <Container>
        <MaxWidth
          maxWidth={BRAKE_POINT.desktop}
          style={CommonStyles.padding.all}
        >
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label="店舗情報に戻る" onClick={goBack} />
          </Box>
          <Menu>店舗グループ名の編集</Menu>
          <View style={CommonStyles.margin.top} />
          <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
            <View style={CommonStyles.flex.full}>
              {!shopList || !shopGroups ? (
                <ActivityIndicator size={60} />
              ) : (
                <>
                  <VForm.Provider<ShopGroupFormData>
                    schema={schemaShopGroupFormData}
                    defaultValues={{
                      name: shopGroups.find((shopGroup) => shopGroup.id === id)
                        .name,
                    }}
                  >
                    <VForm.Text<ShopGroupFormData>
                      name="name"
                      label="店舗グループ名"
                      maxLength={30}
                      required
                    />
                    <VMargin />
                    <EditGroupSubmit
                      shopGroup={shopGroups.find(
                        (shopGroup) => shopGroup.id === id,
                      )}
                    />
                  </VForm.Provider>
                </>
              )}
            </View>
          </MaxWidth>
        </MaxWidth>
      </Container>
    </ExcludeVisitorsContainer.Provider>
  );
};
