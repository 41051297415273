import {useRoute} from '@react-navigation/native';
import React from 'react';
import {ActivityIndicator, View} from 'react-native';
import {ERROR_MSG} from '../../helper';
import {SplashScreen} from '../../screens/Splash';
import {Header, RegisterContainer, Text} from '../Elements';
import {Forms as PreSignUp} from '../PreSignUp';
import {AgencyError} from './AgencyError';
import {AgencyInfo} from './AgencyInfo';
import {AgencyPreContainer} from './AgencyPreContainer';

const TAG = 'Agency';

const PreRegistSelector: React.FC = () => {
  const {busy, step} = AgencyPreContainer.useContainer();
  if (busy) {
    return <SplashScreen />;
  }

  console.log(TAG, 'selector', step);
  switch (step) {
    case 'error':
      return <AgencyErrorComponent />;
    case 'init':
      return <ParamParser />;
    case 'info':
      return <AgencyInfoComponent />;
    case 'regist':
      return <PreSignUpComponent />;
    default:
      return null;
  }
};

// クエリパラメータの解釈と確認
const ParamParser: React.FC = () => {
  const {params} = useRoute();
  console.log('[ParamParser]', params);
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const {step, error, fetchAgency} = AgencyPreContainer.useContainer();
  React.useEffect(() => {
    if (step === 'init') {
      if (!params) {
        setErrorMsg(ERROR_MSG.agency.params);
      } else {
        console.log(TAG, 'param-fetch');
        fetchAgency((params as any).id);
      }
    }
  }, [fetchAgency, params, step]);

  React.useEffect(() => {
    if (error) {
      console.log('[ParamParser] error', error);
      const code = 'code' in error ? `${error?.code}: ` : '';
      setErrorMsg(`${ERROR_MSG.agency.fetch}（${code}${error?.message}）`);
    }
  }, [error]);

  if (errorMsg) {
    return <Text>{errorMsg}</Text>;
  }
  return <ActivityIndicator />;
};

const PreSignUpComponent: React.FC = () => {
  const {agency} = AgencyPreContainer.useContainer();

  return agency ? (
    <View>
      <Header />
      <PreSignUp agency={agency?.id} />
    </View>
  ) : null;
};

export const PreRegistComponent: React.FC = () => {
  return (
    <View>
      <AgencyPreContainer.Provider>
        <PreRegistSelector />
      </AgencyPreContainer.Provider>
    </View>
  );
};

const AgencyInfoComponent: React.FC = () => {
  return (
    <RegisterContainer label={'代理店情報表示'}>
      <AgencyInfo />
    </RegisterContainer>
  );
};

const AgencyErrorComponent: React.FC = () => {
  return <AgencyError />;
};
