import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {BRAKE_POINT} from '../../components';
import {
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {DailyGraph, DeliveryList} from '../../components/Report';
import {ShopContainer} from '../../container';
import {MainNavigationProp} from '../../navigation/MainScreen';
import {DeliveryType} from '../../service';
import {CommonStyles} from '../../theme';
import {Customers} from './Customers';
import {Detail} from './Detail';

type NProp = MainNavigationProp<'ReportsMain'>;

const Main: React.FC = React.memo(() => {
  const [shopId] = React.useState<string | undefined>(
    ShopContainer.useContainer().selected?.id,
  );
  const navigation = useNavigation<NProp>();
  const gotoDetail = (id: string, type: DeliveryType) => {
    navigation.navigate('ReportsDetail', {id, type});
  };

  if (!shopId) {
    return (
      <Container>
        <MaxWidth maxWidth={BRAKE_POINT.desktop}>
          <View style={CommonStyles.padding.all}>
            <Text>店舗の選択状態が不正です</Text>
          </View>
        </MaxWidth>
      </Container>
    );
  }

  return (
    <Container>
      <MaxWidth maxWidth={BRAKE_POINT.desktop}>
        <View style={CommonStyles.padding.all}>
          <WithHint id="reportsMain">
            <Menu>配信結果</Menu>
          </WithHint>
          <VMargin />
          <FormBlock>
            <DailyGraph shopId={shopId} />
          </FormBlock>
          <VMargin />
          <Text>配信ごとの結果</Text>
          <VMargin />
          <View>
            <DeliveryList shopId={shopId} onPress={gotoDetail} />
          </View>
        </View>
      </MaxWidth>
    </Container>
  );
});

export const Reports = {
  Main,
  Detail,
  Customers,
};
