import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSNews} from '../API';
import {SaaSNewsRepository} from '../_proto/services/SaaSNewsRepository';
import {Helpers} from '../helper';
import {isUsedItem} from '../service';

export type News = Omit<SaaSNews, '__typename'> & {
  title: string;
  name: string;
  used: boolean;
  delivery: string;
  id: string;
  archive: boolean;
  type: 'news';
};

const useNewsContainer = () => {
  const [isGetLoading, setGetLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [news, setNews] = useState<Array<News>>([]);
  const [groupNews, setGroupNews] = useState<Array<Array<News>>>([]);
  const [error, setError] = useState<Error | undefined>();

  async function convert(news: SaaSNews): Promise<News> {
    // 不正データ除去
    if (!news.id || !news.editState) {
      return {
        delivery: '',
        id: '',
        name: '',
        title: '',
        used: false,
        archive: false,
        type: 'news',
      };
    }
    const used = isUsedItem(news);
    let delivery: string;
    if (used) {
      try {
        const count = await SaaSNewsRepository.getNewsCount(news.id);
        delivery = `${count.deliveries}回の配信で${Helpers.sepComma(
          count.users,
        )}人が閲覧済み`;
      } catch (err: any) {
        console.log('[convert] getNewsCount catch', err);
        delivery = '配信数取得エラー';
      }
    } else {
      delivery = 'この広告はまだ配信していません';
    }
    return {
      ...news,
      title: news.title ?? '（タイトル不明）',
      name: news.name ?? '-',
      used,
      delivery,
      id: news.id,
      archive: !!news.archive,
      type: 'news',
    };
  }

  const getData = useCallback(async (id: string) => {
    setGetLoading(true);
    setError(undefined);
    try {
      const items = await SaaSNewsRepository.queryNews(id);
      const result = await Promise.all(
        items.map(async (item) => {
          return await convert(item);
        }),
      );

      setNews(result);
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setGetLoading(false);
    }
  }, []);

  const getGroupData = useCallback(async (ids: string[]) => {
    setGetLoading(true);
    setError(undefined);
    try {
      const response = await Promise.all(
        ids.map(async (id) => {
          const items = await SaaSNewsRepository.queryNews(id);
          const result = await Promise.all(
            items.map(async (item) => {
              return await convert(item);
            }),
          );
          return result;
        }),
      );

      setGroupNews(response);
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setGetLoading(false);
    }
  }, []);

  const deleteData = useCallback(
    async (id: string) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        const item = news.filter((news) => news.id === id)[0];
        if (!item.used) {
          await SaaSNewsRepository.remove(item.id);
        } else {
          await SaaSNewsRepository.archive(item.id);
        }

        const newNews = news.filter((news) => news.id !== id);
        setNews(newNews);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [news],
  );

  const deleteGroupData = useCallback(
    async (id: string) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        const item = groupNews.map((news) =>
          news.filter((news) => news.id === id),
        )[0][0];
        if (!item.used) {
          await SaaSNewsRepository.remove(item.id);
        } else {
          await SaaSNewsRepository.archive(item.id);
        }

        const newNews = groupNews.map((news) =>
          news.filter((news) => news.id !== id),
        );
        setGroupNews(newNews);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [groupNews],
  );

  return {
    isGetLoading,
    isDeleteLoading,
    news,
    error,
    getData,
    deleteData,
    groupNews,
    getGroupData,
    deleteGroupData,
  };
};

export const NewsContainer = createContainer(useNewsContainer);
