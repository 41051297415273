import API, {graphqlOperation, GraphQLResult} from '@aws-amplify/api';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {CreateOwnerMutation, GetOwnerQuery, UpdateOwnerMutation} from '../API';
import {createOwner, updateOwner} from '../graphql/mutations';
import {getOwner} from '../graphql/queries';
import {SaasOwnerQuery} from '../service';

const useOwnerContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [owner, setOwner] = useState<SaasOwnerQuery>();
  const [error, setError] = useState<Error | undefined>();

  const create = useCallback(async (input: any) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = (await API.graphql(
        graphqlOperation(createOwner, {
          input: {
            ...input,
          },
        }),
      )) as GraphQLResult<CreateOwnerMutation>;
      console.log(result);
      if (!result.data) {
        throw new Error('[createOwner] result.data is undefined');
      }
      setOwner(result.data.createOwner);
      return result.data.createOwner;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const update = useCallback(async (input: any) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = (await API.graphql(
        graphqlOperation(updateOwner, {
          input: {
            ...input,
          },
        }),
      )) as GraphQLResult<UpdateOwnerMutation>;
      console.log(result);
      if (!result.data) {
        throw new Error('[updateOwner] result.data is undefined');
      }
      setOwner(result.data.updateOwner);
      return result.data.updateOwner;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const fetchOwner = useCallback(async (id: string) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = (await API.graphql(
        graphqlOperation(getOwner, {id}),
      )) as GraphQLResult<GetOwnerQuery>;
      if (!result.data) {
        throw new Error('[getOwner] result.data is undefined');
      }
      setOwner(result.data.getOwner);
      return result.data.getOwner;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  return {
    isCreateLoading,
    error,
    owner,
    create,
    update,
    fetchOwner,
  };
};

export const OwnerContainer = createContainer(useOwnerContainer);
