import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {AreaType} from '../../../../API';
import {AttrForm} from '../../schema';
import {ResidenceFilter, ResidenceUser} from '../../types';

// 常にNGとなるフィルター
const NOT_PASSED: ResidenceFilter = () => false;

// 居住地・勤務地判定用フィルター
function makeResidenceFilter(
  areaResidence: AttrForm['areaResidence'],
): ResidenceFilter | undefined {
  if (!areaResidence) {
    return NOT_PASSED;
  }
  switch (areaResidence) {
    case AreaType.HOME:
      return (user) => user.stayType === AreaType.HOME;
    case AreaType.OFFICE:
      return (user) => user.stayType === AreaType.OFFICE;
    case AreaType.BOTH:
      // 両方の場合はフィルターする必要がない
      return undefined;
  }
}

/**
 * 居住・勤務地フィルタ利用フック
 * フィルターが更新される時のみ返却オブジェエクトが更新される。
 */
export function useResidenceFilter(): {residenceFilter?: ResidenceFilter} {
  const {watch, getValues} = useFormContext<AttrForm>();
  const [residenceFilter, setFilter] = useState<{
    filter?: ResidenceFilter;
  }>({
    filter: makeResidenceFilter(getValues('areaResidence')),
  });
  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      console.log(value, name, type);
      const filter = makeResidenceFilter(value.areaResidence);
      setFilter({filter});
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  return {residenceFilter: residenceFilter.filter};
}

// 居住・勤務地フィルタの判定とマーク
export function checkResidence(
  user: ResidenceUser,
  filter?: ResidenceFilter,
): void {
  if (filter && !filter(user)) {
    user.areaNG = true;
  } else {
    delete user.areaNG;
  }
}
