// Todo react-selector を使っているため、webでしか動かない。必要に応じて web.tsx に分離すること
import React from 'react';
import {Controller, FieldValues, Path, useFormContext} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {useTheme} from 'react-native-paper';
import Select from 'react-select';
import {CommonStyles} from '../../theme';
import {HintPattern} from './Hint';
import {RequiredNotice, Text, WithHint} from './Text';

export type OptionType = {value: string; label: string}[];
export type SelectProps<T extends FieldValues> = {
  name: Path<T>;
  label?: string;
  options: OptionType;
  defaultValue?: string;
  withHint?: HintPattern;
  disabled?: boolean;
  required?: boolean;
};

export function VSelect<T extends FieldValues>(
  props: SelectProps<T>,
): React.ReactElement {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <>
      <View style={styles.label}>
        {props.withHint ? (
          <WithHint id={props.withHint}>
            <Text>{props.label}</Text>
          </WithHint>
        ) : (
          <Text>{props.label}</Text>
        )}
        {props.required && <RequiredNotice />}
      </View>
      {/* 
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore */}
      <SelectMain {...props} />
    </>
  );
}

// Select要素は非常に癖が強いので、実績のあるSelect用パッケージを paper のデザインに寄せて利用する
function SelectMain<T extends FieldValues>(
  props: SelectProps<T>,
): React.ReactElement {
  const {control} = useFormContext<T>();
  const {colors, fonts, roundness} = useTheme();
  const {name, options, defaultValue, disabled} = props;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Controller
      name={name}
      control={control}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultValue={defaultValue}
      render={({field: {onChange, value, ref}}) => {
        return (
          <Select
            theme={(theme) => {
              return {
                ...theme,
                borderRadius: roundness,
                colors: {
                  ...theme.colors,
                  ...colors,
                },
                spacing: {
                  ...theme.spacing,
                  controlHeight: 40,
                },
              };
            }}
            styles={{
              control: (base) => {
                return {
                  ...base,
                  fontFamily: fonts.regular.fontFamily,
                  borderColor: colors.placeholder,
                };
              },
              dropdownIndicator: (base) => ({
                ...base,
                color: colors.primary,
              }),
              indicatorSeparator: () => ({}),
              menu: (base) => {
                return {
                  ...base,
                  fontFamily: fonts.regular.fontFamily,
                };
              },
              menuPortal: (base) => ({...base, zIndex: 9999}),
            }}
            ref={ref}
            isSearchable={false}
            options={options}
            isDisabled={disabled}
            value={options.find((c) => c.value === String(value))}
            onChange={(val) => onChange(val?.value)}
            menuPortalTarget={document.querySelector('body')}
          />
        );
      }}
    />
  );
}

const styles = StyleSheet.create({
  label: {
    marginBottom: 2,
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.crossCenter,
  },
});
