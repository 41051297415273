const Linkings = {
  prefixes: [
    'https://dev.d3e9spv41fdbjs.amplifyapp.com',
    // 'localhost:3000',
    /* your linking prefixes */
  ],
  config: {
    screens: {
      Error: 'error',
      LandingPage: 'landing',
      PreSignUp: 'pre-sign-up',
      SignUp: 'sign-up',
      SignIn: 'sign-in',
      Invite: 'invite',
      PasswordForgot: 'password-forgot',
      OwnerRegister: 'register/owner',
      PaymentRegister: 'register/payment',
      PaymentRegisterReview: 'register/payment/paid',
      RegisterDone: 'register/done',
      ShopRegister: 'register/shop',
      SuspendMain: 'suspend',
      SelectOwner: 'select-owner',
      AccountMain: 'register/account',
      AccountChangePhoneNumber: 'register/account/change-phone',
      AccountChangePassword: 'register/account/change-password',
      AccountChangeEmail: 'register/account/change-email',
      AccountChangeName: 'register/account/change-name',
      AccountChangeSendEmail: 'register/account/change-send-email',
      Main: {
        path: '',
        screens: {
          DashBoard: 'dashboard',
          AccountMain: 'account',
          AccountChangePhoneNumber: 'account/change-phone',
          AccountChangePassword: 'account/change-password',
          AccountChangeEmail: 'account/change-email',
          AccountChangeName: 'account/change-name',
          AccountChangeSendEmail: 'account/change-send-email',
          SettingsMain: 'settings',
          SettingsChangeOwner: 'settings/change-owner',
          SettingsChangePayment: 'settings/change-payment',
          AccessManageMain: 'settings/access-manage',
          AccessManageEdit: 'settings/access-manage/:id',
          AccountDelete: 'account/delete',
          OwnersMain: 'owners',
          ShopsMain: 'shops',
          ShopsCreate: 'shops/create',
          ShopsDetail: 'shops/:id',
          ShopsEdit: 'shops/:id/edit',
          ShopsCopy: 'shops/:id/copy',
          GroupsMain: 'shops/groups/:id',
          GroupsEdit: 'shops/groups/:id/edit',
          ExcludeVisitorsMain: 'shops/:id/visitors',
          InvoicesMain: 'invoices',
          CouponsMain: 'coupons',
          CouponsCreate: 'coupons/create',
          CouponsDetail: 'coupons/:id',
          CouponsEdit: 'coupons/:id/edit',
          CouponsCopy: 'coupons/:id/copy',
          NewsMain: 'news',
          NewsCreate: 'news/create',
          NewsDetail: 'news/:id',
          NewsEdit: 'news/:id/edit',
          NewsCopy: 'news/:id/copy',
          TargetsMain: 'targets',
          TargetsCreate: 'targets/create',
          TargetsDetail: 'targets/:id',
          TargetsEdit: 'targets/:id/edit',
          TargetsCopy: 'targets/:id/copy',
          CreativesMain: 'creatives',
          CreativesCreate: 'creatives/create',
          CreativesDetail: 'creatives/:id',
          CreativesEdit: 'creatives/:id/edit',
          CreativesGroupCreate: 'creatives-group/create',
          ReportsMain: 'reports',
          ReportsCustomers: 'reports/customers',
          ReportsDetail: 'reports/:id',
          ReportGroups: 'report-groups',
        },
      },
      AgencyPreRegist: 'agency/pre-regist',
      AgencySignUp: 'agency/sign-up',
    },
    /* configuration for matching screens with paths */
  },
};

export default Linkings;
