import React from 'react';
import {View} from 'react-native';
import {Text} from '../../components/Elements';

export const Delete: React.FC = React.memo(() => {
  return (
    <View>
      <Text>アカウント削除</Text>
    </View>
  );
});
