import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Delete,
  Edit as EditIcon,
  FileCopyOutlined,
  MoreVert,
  Send,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  List as MuiList,
  Menu as MuiMenu,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator, Dialog, Portal} from 'react-native-paper';
import {ReactComponent as Copy2GroupIcon} from '../../assets/copy2group.svg';
import {BRAKE_POINT} from '../../components';
import {
  BackLink,
  Caption,
  Container,
  MaxWidth,
  Menu,
  MinText,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {useWidth} from '../../components/Responsive';
import {ShopContainer, ShopsContainer} from '../../container';
import {TargetsContainer} from '../../container/TargetsContainer';
import {ERROR_MSG, Helpers} from '../../helper';
import {
  MainNavigationProp,
  MainRouteProp,
  ScreenType,
} from '../../navigation/MainScreen';
import {axiosHelper} from '../../service';
import {Colors, CommonStyles, Message} from '../../theme';
import {Copy} from './Copy';
import {Create} from './Create';
import {Detail} from './Detail';
import {Edit} from './Edit';

type NProp = MainNavigationProp<'TargetsMain'>;
type RProp = MainRouteProp<'TargetsMain'>;

type ListItem = {
  title: string;
  targetCount?: number | null;
  delivery: string; // 配信数
  used: boolean;
  id: string;
};

const CouponDeleteDialog: React.FC<{
  visible: boolean;
  onDismiss?(): void;
  onError?(): void;
  item: ListItem;
}> = ({visible, onDismiss, onError, item}) => {
  const [busy, setBusy] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string | undefined>();
  const {deleteData} = TargetsContainer.useContainer();
  const removed = () => onDismiss && onDismiss();
  const cancel = () => onDismiss && onDismiss();
  const errored = () => onError && onError();
  const remove = async () => {
    setBusy(true);
    try {
      await deleteData(item.id);
      removed();
    } catch (err: any) {
      setMessage(axiosHelper.commonErrorHandler(err));
    } finally {
      setBusy(false);
    }
  };
  const render = () => {
    // エラー時
    if (message) {
      return (
        <View>
          <Text>配信先の削除に失敗しました。</Text>
          <Text>{message}</Text>
          <VMargin />
          <TrimaButton variant="outlined" onClick={errored}>
            {Message.BackToList}
          </TrimaButton>
        </View>
      );
    }
    return (
      <View>
        <Text>■タイトル（管理用）</Text>
        <Text>{item.title}</Text>
        <Text>■配信対象者数</Text>
        <Text>{item.targetCount}人</Text>
        <Text>■配信回数と閲覧回数</Text>
        <Text>{item.delivery}</Text>
        <VMargin />
        <TrimaLoadingButton
          variant="contained"
          color="error"
          onClick={remove}
          disabled={busy}
          loading={busy}>
          削除
        </TrimaLoadingButton>
        <VMargin />
        <TrimaButton variant="outlined" onClick={cancel} disabled={busy}>
          キャンセル
        </TrimaButton>
      </View>
    );
  };
  const {windowWidth} = useWidth();
  const widthStyle =
    windowWidth > 600 ? {width: 568, alignSelf: 'center' as const} : undefined;

  return (
    <Portal>
      <Dialog
        visible={visible}
        onDismiss={cancel}
        dismissable={false}
        style={widthStyle}>
        <Dialog.Title style={styles.deleteText}>
          配信先を削除しますか？
        </Dialog.Title>
        <Dialog.Content>{render()}</Dialog.Content>
      </Dialog>
    </Portal>
  );
};

const Heading: React.FC<{from?: ScreenType}> = ({from}) => {
  const navigation = useNavigation<NProp>();
  const cancel = () => navigation.goBack();
  if (!from) {
    // 規定
    return (
      <WithHint id="targetsMain">
        <Menu>作成済みの配信先</Menu>
      </WithHint>
    );
  } else {
    // とりあえず配信からくるケースを実装
    return (
      <View>
        {from === 'CreativesCreate' && (
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label="配信する画面に戻る" onClick={cancel} />
          </Box>
        )}
        <Menu>作成済み配信先の選択</Menu>
      </View>
    );
  }
};

const Footer: React.FC<{from?: ScreenType}> = ({from}) => {
  const navigation = useNavigation<NProp>();
  // 他の画面から来た場合は新規ボタンは表示しない
  if (from) {
    return null;
  }
  const gotoCreate = () =>
    navigation.navigate('TargetsCreate', {from: 'TargetsMain'});
  return (
    <Box pt={1}>
      <TrimaButton
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={gotoCreate}>
        配信先を新規作成
      </TrimaButton>
    </Box>
  );
};

const Main: React.FC = () => {
  const [errorMsg, setError] = React.useState<string>('');
  // 遷移元の設定
  const route = useRoute<RProp>();
  const fromScreen = route.params?.from;
  const [from] = React.useState<ScreenType | undefined>(fromScreen);

  const {selected} = ShopContainer.useContainer();
  const {targets, getData, isGetLoading} = TargetsContainer.useContainer();

  React.useEffect(() => {
    async function fetchData() {
      if (selected) {
        try {
          await getData(selected.id);
        } catch (err: any) {
          setError(ERROR_MSG.common.dump(err));
        }
      }
    }
    fetchData();
  }, [selected, getData]);

  return (
    <Container style={CommonStyles.padding.all}>
      <MaxWidth maxWidth={BRAKE_POINT.desktop}>
        <Heading from={from} />
        <View style={CommonStyles.margin.top} />
        <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
          <View style={CommonStyles.flex.full}>
            {errorMsg ? (
              <Text>{errorMsg}</Text>
            ) : isGetLoading ? (
              <ActivityIndicator size={60} />
            ) : (
              <View>
                <Text>未使用</Text>
                <VMargin />
                <Footer from={from} />
                <VMargin />
                <MuiList>
                  {targets
                    .filter((target) => !target.used)
                    .map((target) => (
                      <React.Fragment key={target.id}>
                        <TargetListItem target={target} from={from} />
                        <Box p={0.5} />
                      </React.Fragment>
                    ))}
                </MuiList>
                <VMargin />
                <Text>使用済み</Text>
                <VMargin />
                <MuiList>
                  {targets
                    .filter((target) => target.used)
                    .filter((target) => !target.archive)
                    .map((target: any) => (
                      <React.Fragment key={target.id}>
                        <TargetListItem target={target} from={from} />
                        <Box p={0.5} />
                      </React.Fragment>
                    ))}
                </MuiList>
              </View>
            )}
          </View>
        </MaxWidth>
      </MaxWidth>
    </Container>
  );
};

const TargetCopyDialog: React.FC<{
  visible: boolean;
  onDismiss?(): void;
  onError?(): void;
  item: ListItem;
}> = ({visible, onDismiss, item}) => {
  const navigation = useNavigation<NProp>();
  const {selected} = ShopContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const {checkedIds, setCheckedIds} = TargetsContainer.useContainer();
  const cancel = () => {
    onDismiss && onDismiss();
    setCheckedIds(
      shopList
        .filter((shop) => shop.id === selected?.id)
        .map((shop) => shop.id),
    );
  };
  const copy = async () => {
    navigation.navigate('TargetsCopy', {
      id: item.id,
      from: 'TargetsMain',
      group: 'on',
    });
  };

  React.useEffect(() => {
    setCheckedIds(
      shopList
        .filter((shop) => shop.id === selected?.id)
        .map((shop) => shop.id),
    );
  }, [shopList]);

  const render = () => {
    return (
      <View>
        <Text>グループ内の店舗に複製ができます。</Text>
        <Box px={2}>
          <FormControlLabel
            label="すべてを選択"
            control={
              <Checkbox
                checked={
                  checkedIds.length ===
                  shopList.filter((shop) => shop.groupId === selected?.groupId)
                    .length
                }
                indeterminate={
                  checkedIds.length > 0 &&
                  checkedIds.length <
                    shopList.filter(
                      (shop) => shop.groupId === selected?.groupId,
                    ).length
                }
                onChange={() => {
                  if (
                    checkedIds.length !==
                    shopList.filter(
                      (shop) => shop.groupId === selected?.groupId,
                    ).length
                  ) {
                    setCheckedIds(
                      shopList
                        .filter((shop) => {
                          if (selected && selected.groupId) {
                            return shop.groupId === selected.groupId;
                          } else {
                            return shop.groupId === null;
                          }
                        })
                        .map((shop) => shop.id),
                    );
                  } else {
                    setCheckedIds([]);
                  }
                }}
              />
            }
          />
        </Box>
        <Box
          mb={2}
          sx={{
            border: `1px solid ${Colors.darkgray}`,
          }}>
          <Stack
            pl={2}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              maxHeight: '40vh',
              height: '100%',
            }}>
            <Stack flex={1} overflow="auto">
              {shopList
                .filter((shop) => {
                  if (selected && selected.groupId) {
                    return shop.groupId === selected.groupId;
                  } else {
                    return shop.groupId === null;
                  }
                })
                .map((shop) => {
                  return (
                    <FormControlLabel
                      label={shop.name}
                      control={
                        <Checkbox
                          id={shop.id}
                          checked={checkedIds.includes(shop.id)}
                          onChange={(event) => {
                            const target = event.target;
                            setCheckedIds(
                              checkedIds.includes(target.id)
                                ? checkedIds.filter(
                                    (item) => item !== target.id,
                                  )
                                : [...checkedIds, target.id],
                            );
                          }}
                        />
                      }
                    />
                  );
                })}
            </Stack>
          </Stack>
        </Box>
        <TrimaLoadingButton
          disabled={checkedIds.length === 0}
          variant="contained"
          color="primary"
          onClick={copy}>
          複製
        </TrimaLoadingButton>
        <VMargin />
        <TrimaButton variant="outlined" onClick={cancel}>
          キャンセル
        </TrimaButton>
      </View>
    );
  };
  const {windowWidth} = useWidth();
  const widthStyle =
    windowWidth > 600 ? {width: 568, alignSelf: 'center' as const} : undefined;

  return (
    <Portal>
      <Dialog
        visible={visible}
        onDismiss={cancel}
        dismissable={false}
        style={widthStyle}>
        <Dialog.Title>他店舗を選択</Dialog.Title>
        <Dialog.Content>{render()}</Dialog.Content>
      </Dialog>
    </Portal>
  );
};

const TargetListItem: React.FC<{
  target: any;
  from?: ScreenType;
}> = React.memo(({target, from}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDialog, setDialog] = React.useState<boolean>(false);
  const [showCopyDialog, setCopyDialog] = React.useState<boolean>(false);
  const {selected} = ShopContainer.useContainer();

  const navigation = useNavigation<NProp>();
  const goto = () => {
    if (from === 'CreativesCreate') {
      // 配信作成画面からきたので、選択された配信先情報を渡して戻る。
      navigation.navigate(from, {targetId: target.id});
    } else {
      // 通常は詳細ページへ
      navigation.navigate('TargetsDetail', {id: target.id, used: target.used});
    }
  };
  const gotoEdit = () =>
    navigation.navigate('TargetsEdit', {id: target.id, from: 'TargetsMain'});
  const gotoCreative = () =>
    navigation.navigate('CreativesCreate', {targetId: target.id});
  const gotoCopy = () =>
    navigation.navigate('TargetsCopy', {id: target.id, from: 'TargetsMain'});
  const gotoDash = () => navigation.navigate('DashBoard');
  const onDismiss = () => {
    setDialog(false);
  };
  const onCopyDismiss = () => {
    setCopyDialog(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const visiblePopup = !from;

  return (
    <>
      <ListItem
        sx={{
          '&.MuiListItem-root': {
            padding: 0,
            bgcolor: 'background.paper',
            border: `1px solid ${Colors.main}`,
          },
        }}
        secondaryAction={
          <>
            {visiblePopup && (
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <MoreVert color="primary" />
              </IconButton>
            )}
            <MuiMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              {!target.used && (
                <MenuItem onClick={gotoEdit}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" color="primary" />
                  </ListItemIcon>
                  <Typography variant="inherit" color="primary">
                    編集する
                  </Typography>
                </MenuItem>
              )}
              <MenuItem onClick={gotoCopy}>
                <ListItemIcon>
                  <FileCopyOutlined fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  複製する
                </Typography>
              </MenuItem>
              <MenuItem
                disabled={!selected?.groupId}
                onClick={() => {
                  handleClose();
                  setCopyDialog(true);
                }}>
                <ListItemIcon>
                  <SvgIcon component={Copy2GroupIcon}></SvgIcon>
                </ListItemIcon>
                <ListItemText sx={{color: 'primary.main'}}>
                  他店舗に
                  <br />
                  複製する
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={gotoCreative}>
                <ListItemIcon>
                  <Send fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  配信する
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDialog(true);
                }}>
                <ListItemIcon>
                  <Delete fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText sx={{color: 'primary.main'}}>
                  削除する
                </ListItemText>
              </MenuItem>
            </MuiMenu>
          </>
        }>
        <ListItemButton
          onClick={goto}
          sx={{
            '&.MuiButtonBase-root': {
              py: 2,
            },
          }}>
          <ListItemText
            id={target.id}
            primary={
              <>
                <Box display="flex" flexDirection="row">
                  <Typography
                    style={{fontWeight: 500, color: Colors.main}}
                    variant="subtitle1"
                    component="span">
                    {target.title}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block">
                    {target.targetCount ? (
                      <View
                        style={[
                          CommonStyles.flex.row,
                          CommonStyles.flex.crossCenter,
                        ]}>
                        <Caption>配信対象者：</Caption>
                        <Caption style={styles.count}>
                          {Helpers.sepComma(target.targetCount)}
                        </Caption>
                        <Caption>人</Caption>
                      </View>
                    ) : (
                      <Caption numberOfLines={1}>（人数不明）</Caption>
                    )}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    variant="caption"
                    component="span"
                    display="block">
                    <MinText>{target.delivery}</MinText>
                  </Typography>
                </Box>
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      <CouponDeleteDialog
        visible={showDialog}
        item={target}
        onDismiss={onDismiss}
        onError={gotoDash}
      />
      <TargetCopyDialog
        visible={showCopyDialog}
        item={target}
        onDismiss={onCopyDismiss}
        onError={gotoDash}
      />
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  item: {
    backgroundColor: Colors.lightgray,
    ...CommonStyles.padding.all,
    ...CommonStyles.align.self.stretch,
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.crossCenter,
    ...CommonStyles.flex.between,
  },
  popup: {
    position: 'absolute',
    top: 0,
    right: 60,
    width: 120,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.darkgray,
    ...CommonStyles.padding.all,
    ...CommonStyles.flex.center,
  },
  textButton: {
    height: 30,
  },
  separator: {
    height: 2,
    backgroundColor: Colors.base,
  },
  newButton: {
    ...CommonStyles.margin.top,
    height: 40,
  },
  count: {
    minWidth: 50,
    textAlign: 'center',
  },
  deleteText: {
    color: Colors.delete,
  },
});

export const Targets = {
  Main,
  Copy,
  Create,
  Detail,
  Edit,
};
