import {
  createDrawerNavigator,
  DrawerNavigationProp,
} from '@react-navigation/drawer';
import {RouteProp, useNavigation} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {DRAWER_WIDTH, useHeight, useResponsive} from '../components';
import {Logo, Text} from '../components/Elements';
import {
  Shop,
  ShopContainer,
  ShopsContainer,
  StationCacheContainer,
  StationSearchContainer,
  StepNavigationContainer,
} from '../container';
import {
  AccessManage,
  Account,
  Coupons,
  Creatives,
  CreativesGroup,
  DashBoard,
  ExcludeVisitors,
  Groups,
  Invoices,
  News,
  Owners,
  ReportGroups,
  Reports,
  Settings,
  Shops,
  Suspend,
  Targets,
} from '../screens';
import {DeliveryType} from '../service';
import {CommonStyles} from '../theme';
import {AccountMenu} from './AccountMenu';
import {CustomDrawer, DrawerTree, showToggle} from './Drawer';

export type MainScreenParams = {
  DashBoard: undefined;
  AccountMain: undefined;
  AccountChangePhoneNumber: undefined;
  AccountChangePassword: undefined;
  AccountChangeEmail: undefined;
  AccessManageMain: undefined;
  AccessManageEdit: {id?: string};
  AccountChangeName: undefined;
  AccountChangeSendEmail: undefined;
  SettingsChangeOwner: undefined;
  SettingsChangePayment: undefined;
  AccountDelete: undefined;
  ShopsMain: undefined;
  ShopsCreate: {from?: keyof MainScreenParams; groupId?: string} | undefined;
  ShopsDetail: {id?: string};
  ShopsEdit: {id?: string};
  ShopsCopy: {id?: string};
  ExcludeVisitorsMain: {id?: string};
  GroupsMain: {id?: string};
  GroupsEdit: {id?: string};
  InvoicesMain: undefined;
  CouponsMain: {from?: keyof MainScreenParams} | undefined;
  CouponsCopy: {id?: string; from?: keyof MainScreenParams; group?: 'on'};
  CouponsCreate: {from?: keyof MainScreenParams} | undefined;
  CouponsDetail: {id?: string};
  CouponsEdit: {id?: string; from?: keyof MainScreenParams; group?: 'on'};
  NewsMain: {from?: keyof MainScreenParams} | undefined;
  NewsCopy: {id?: string; from?: keyof MainScreenParams; group?: 'on'};
  NewsCreate: {from?: keyof MainScreenParams} | undefined;
  NewsDetail: {id?: string};
  NewsEdit: {id?: string; from?: keyof MainScreenParams; group?: 'on'};
  TargetsMain: {from?: keyof MainScreenParams} | undefined;
  TargetsCopy: {id?: string; from?: keyof MainScreenParams; group?: 'on'};
  TargetsCreate: {from?: keyof MainScreenParams} | undefined;
  TargetsDetail: {id?: string; used?: boolean | string};
  TargetsEdit: {id?: string; from?: keyof MainScreenParams; group?: 'on'};
  CreativesMain: undefined;
  CreativesCreate:
    | {type?: 'coupon' | 'news'; adsId?: string; targetId?: string}
    | undefined;
  CreativesDetail: {type?: 'coupon' | 'news'; id?: string};
  CreativesEdit: {type?: 'coupon' | 'news'; id?: string};
  CreativesGroupCreate:
    | {type?: 'coupon' | 'news'; adsId?: string; targetId?: string}
    | undefined;
  ReportsMain: undefined;
  ReportsDetail: {id?: string; type?: DeliveryType};
  ReportsCustomers: undefined;
  ReportGroups: undefined;
  SettingsMain: undefined;
  SuspendMain: undefined;
  OwnersMain: undefined;
};

// 画面型
export type ScreenType = keyof MainScreenParams;
export type MainRouteProp<T extends ScreenType> = RouteProp<
  MainScreenParams,
  T
>;
export type MainNavigationProp<T extends ScreenType> = DrawerNavigationProp<
  MainScreenParams,
  T
>;

const Drawer = createDrawerNavigator<MainScreenParams>();

const mainDrawerTree: DrawerTree<keyof MainScreenParams> = {
  dashboard: {screens: ['DashBoard'], separator: true},
  ad: [
    {
      label: '広告',
      children: {
        screens: ['CouponsCreate', 'CouponsMain'],
      },
    },
  ],
  target: [
    {
      label: '配信先',
      children: {
        screens: ['TargetsCreate', 'TargetsMain'],
      },
    },
  ],
  creative: [
    {
      separator: true,
      label: '配信',
      children: {
        screens: ['CreativesCreate', 'CreativesGroupCreate', 'CreativesMain'],
      },
    },
  ],
  report: [
    {
      separator: true,
      label: 'レポート',
      children: {
        screens: ['ReportsMain', 'ReportGroups', 'ReportsCustomers'],
      },
    },
  ],
  owner: {screens: ['OwnersMain']},
  shop: {screens: ['ShopsMain']},
  invoice: {screens: ['InvoicesMain']},
};

const suspendDrawerTree: DrawerTree<keyof MainScreenParams> = {
  settings: {screens: ['SettingsMain']},
  invoice: {screens: ['InvoicesMain']},
};

// iOSでドロワーが透明なる点への暫定対策 Todo
const OPACITY = {opacity: 1};

const HeaderTitle = React.memo<{isDesktop: boolean}>(({isDesktop}) => {
  const offset = isDesktop ? {paddingRight: DRAWER_WIDTH.width} : {};
  return (
    <View style={offset}>
      <Logo height={38} isDark />
    </View>
  );
});

// ログイン後に中心となるメイン画面
// Drawerナビゲーションにより構成される
const MainScreen = React.memo<{
  isDesktop: boolean;
  windowHeight: number;
}>(({isDesktop, windowHeight}) => {
  // const navi = useNavigation();
  const {selected} = ShopContainer.useContainer();
  const [nowShop, setNowShop] = React.useState<Shop | undefined>(selected);
  const [changing, setChanging] = React.useState<boolean>(false);
  const {step, toScreen, clear2Screen} = StepNavigationContainer.useContainer();
  const navigation = useNavigation();

  React.useEffect(() => {
    // 店舗が切り替わったら、不正な状態へ陥るのを回避するため一度ドロワー自体をクリアしてしまう
    if (!changing && nowShop !== selected) {
      setChanging(true);
      setNowShop(undefined);

      setTimeout(() => {
        setNowShop(selected);
        setChanging(false);
      }, 2000);
    }
  }, [changing, nowShop, selected]);

  React.useEffect(() => {
    // 店舗が切り替わったら、不正な状態へ陥るのを回避するため一度ドロワー自体をクリアしてしまう
    if (step === 'suspended') {
      navigation.navigate('SuspendMain');
    }
  }, [step, navigation]);

  React.useEffect(() => {
    if (toScreen) {
      navigation.navigate(toScreen);
      clear2Screen();
    }
  }, [toScreen, navigation, clear2Screen]);

  if (!nowShop) {
    return (
      <View>
        <ActivityIndicator size={40} />
        <Text>店舗の選択中</Text>
      </View>
    );
  }

  // https://github.com/react-navigation/react-navigation/issues/7510
  const containerStyle = {height: windowHeight};
  return (
    <Drawer.Navigator
      initialRouteName="DashBoard"
      sceneContainerStyle={containerStyle}
      screenOptions={({navigation}) => ({
        unmountOnBlur: true, // ページ遷移した時点でスクリーンコンポーネントを解放する（状態を維持しない）
        ...CommonStyles.header,
        ...(isDesktop ? {headerLeft: () => null} : showToggle(navigation)),
        headerTitle: () => <HeaderTitle isDesktop={isDesktop} />,
        headerRight: () => <AccountMenu />,
      })}
      drawerStyle={[isDesktop ? DRAWER_WIDTH : CommonStyles.fullWidth, OPACITY]}
      drawerType={isDesktop ? 'permanent' : 'front'}
      drawerContent={(props) => {
        return (
          <CustomDrawer
            {...props}
            tree={step === 'suspended' ? suspendDrawerTree : mainDrawerTree}
          />
        );
      }}>
      {step === 'suspended' ? (
        <>
          <Drawer.Screen
            name="SuspendMain"
            component={Suspend.Main}
            options={{title: '企業アカウント停止中'}}
          />
          <Drawer.Screen
            name="SettingsMain"
            component={Settings.Main}
            options={{title: '企業設定'}}
          />
          <Drawer.Screen
            name="AccountMain"
            component={Account.Main}
            options={{title: 'アカウント設定'}}
          />
          <Drawer.Screen
            name="AccountChangePhoneNumber"
            component={Account.ChangePhoneNumber}
            options={{title: '電話番号の変更'}}
          />
          <Drawer.Screen
            name="AccountChangePassword"
            component={Account.ChangePassword}
            options={{title: 'パスワードの変更'}}
          />
          <Drawer.Screen
            name="AccountChangeEmail"
            component={Account.ChangeEmail}
            options={{title: 'メールアドレスの変更'}}
          />
          <Drawer.Screen
            name="AccountChangeName"
            component={Account.ChangeName}
            options={{title: '名前の変更'}}
          />
          <Drawer.Screen
            name="AccountChangeSendEmail"
            component={Account.ChangeSendEmail}
            options={{title: 'メール送信の変更'}}
          />
          <Drawer.Screen
            name="SettingsChangeOwner"
            component={Settings.ChangeOwner}
            options={{title: '企業情報の変更'}}
          />
          <Drawer.Screen
            name="SettingsChangePayment"
            component={Settings.ChangePayment}
            options={{title: 'お支払い方法の変更'}}
          />
          <Drawer.Screen
            name="AccessManageMain"
            component={AccessManage.Main}
            options={{title: '所属ユーザーを管理'}}
          />
          <Drawer.Screen
            name="AccessManageEdit"
            component={AccessManage.Edit}
            options={{title: '所属ユーザーの変更'}}
          />
          <Drawer.Screen
            name="AccountDelete"
            component={Account.Delete}
            options={{title: 'アカウントの削除'}}
          />
          <Drawer.Screen
            name="OwnersMain"
            component={Owners.Main}
            options={{title: '企業管理'}}
          />
          <Drawer.Screen
            name="InvoicesMain"
            component={Invoices.Main}
            options={{title: '請求情報'}}
          />
        </>
      ) : (
        <>
          <Drawer.Screen
            name="DashBoard"
            component={DashBoard}
            options={{title: 'ダッシュボード'}}
          />
          <Drawer.Screen
            name="CouponsMain"
            component={Coupons.Main}
            options={{title: '作成済みの広告'}}
          />
          <Drawer.Screen
            name="CouponsCreate"
            component={Coupons.Create}
            options={{title: '広告を作成'}}
          />
          <Drawer.Screen
            name="CouponsCopy"
            component={Coupons.Copy}
            options={{title: '広告を複製'}}
          />
          <Drawer.Screen
            name="CouponsDetail"
            component={Coupons.Detail}
            options={{title: '広告の詳細'}}
          />
          <Drawer.Screen
            name="CouponsEdit"
            component={Coupons.Edit}
            options={{title: '広告を編集'}}
          />
          <Drawer.Screen
            name="NewsMain"
            component={News.Main}
            options={{title: '作成済みの広告'}}
          />
          <Drawer.Screen
            name="NewsCreate"
            component={News.Create}
            options={{title: '広告を作成'}}
          />
          <Drawer.Screen
            name="NewsCopy"
            component={News.Copy}
            options={{title: '広告を複製'}}
          />
          <Drawer.Screen
            name="NewsDetail"
            component={News.Detail}
            options={{title: '広告の詳細'}}
          />
          <Drawer.Screen
            name="NewsEdit"
            component={News.Edit}
            options={{title: '広告の編集'}}
          />
          <Drawer.Screen
            name="TargetsMain"
            component={Targets.Main}
            options={{title: '作成済みの配信先'}}
          />
          <Drawer.Screen
            name="TargetsCopy"
            component={Targets.Copy}
            options={{title: '配信先を複製'}}
          />
          <Drawer.Screen
            name="TargetsCreate"
            component={Targets.Create}
            options={{title: '配信先を作成'}}
          />
          <Drawer.Screen
            name="TargetsDetail"
            component={Targets.Detail}
            options={{title: '配信先の詳細'}}
          />
          <Drawer.Screen
            name="TargetsEdit"
            component={Targets.Edit}
            options={{title: '配信先の編集'}}
          />
          <Drawer.Screen
            name="CreativesMain"
            component={Creatives.Main}
            options={{title: '配信管理'}}
          />
          <Drawer.Screen
            name="CreativesCreate"
            component={Creatives.Create}
            options={{title: '配信する', unmountOnBlur: false}}
          />
          <Drawer.Screen
            name="CreativesDetail"
            component={Creatives.Detail}
            options={{title: '配信の詳細'}}
          />
          <Drawer.Screen
            name="CreativesEdit"
            component={Creatives.Edit}
            options={{title: '配信の編集'}}
          />
          <Drawer.Screen
            name="CreativesGroupCreate"
            component={CreativesGroup.Create}
            options={{title: 'グループ一括配信', unmountOnBlur: false}}
          />
          <Drawer.Screen
            name="ReportsMain"
            component={Reports.Main}
            options={{title: '配信結果'}}
          />
          <Drawer.Screen
            name="ReportsDetail"
            component={Reports.Detail}
            options={{title: '配信結果の詳細'}}
          />
          <Drawer.Screen
            name="ReportGroups"
            component={ReportGroups.Main}
            options={{title: 'グループ配信結果'}}
          />
          <Drawer.Screen
            name="ReportsCustomers"
            component={Reports.Customers}
            options={{title: '来店者分析'}}
          />
          <Drawer.Screen
            name="SettingsMain"
            component={Settings.Main}
            options={{title: '企業設定'}}
          />
          <Drawer.Screen
            name="AccountMain"
            component={Account.Main}
            options={{title: 'アカウント設定'}}
          />
          <Drawer.Screen
            name="AccountChangePhoneNumber"
            component={Account.ChangePhoneNumber}
            options={{title: '電話番号の変更'}}
          />
          <Drawer.Screen
            name="AccountChangePassword"
            component={Account.ChangePassword}
            options={{title: 'パスワードの変更'}}
          />
          <Drawer.Screen
            name="AccountChangeEmail"
            component={Account.ChangeEmail}
            options={{title: 'メールアドレスの変更'}}
          />
          <Drawer.Screen
            name="AccountChangeName"
            component={Account.ChangeName}
            options={{title: '名前の変更'}}
          />
          <Drawer.Screen
            name="AccountChangeSendEmail"
            component={Account.ChangeSendEmail}
            options={{title: 'メール送信の変更'}}
          />
          <Drawer.Screen
            name="SettingsChangeOwner"
            component={Settings.ChangeOwner}
            options={{title: '企業情報の変更'}}
          />
          <Drawer.Screen
            name="SettingsChangePayment"
            component={Settings.ChangePayment}
            options={{title: 'お支払い方法の変更'}}
          />
          <Drawer.Screen
            name="AccessManageMain"
            component={AccessManage.Main}
            options={{title: '所属ユーザーを管理'}}
          />
          <Drawer.Screen
            name="AccessManageEdit"
            component={AccessManage.Edit}
            options={{title: '所属ユーザーの変更'}}
          />
          <Drawer.Screen
            name="AccountDelete"
            component={Account.Delete}
            options={{title: 'アカウントの削除'}}
          />
          <Drawer.Screen
            name="ShopsMain"
            component={Shops.Main}
            options={{title: '店舗管理'}}
          />
          <Drawer.Screen
            name="ShopsCreate"
            component={Shops.Create}
            options={{title: '店舗の作成'}}
          />
          <Drawer.Screen
            name="ShopsDetail"
            component={Shops.Detail}
            options={{title: '店舗の詳細'}}
          />
          <Drawer.Screen
            name="ShopsEdit"
            component={Shops.Edit}
            options={{title: '店舗の編集'}}
          />
          <Drawer.Screen
            name="ShopsCopy"
            component={Shops.Copy}
            options={{title: '店舗の複製'}}
          />
          <Drawer.Screen
            name="ExcludeVisitorsMain"
            component={ExcludeVisitors.Main}
            options={{title: '店舗の詳細'}}
          />
          <Drawer.Screen
            name="GroupsMain"
            component={Groups.Main}
            options={{title: 'グループ管理'}}
          />
          <Drawer.Screen
            name="GroupsEdit"
            component={Groups.Edit}
            options={{title: 'グループ管理'}}
          />
          <Drawer.Screen
            name="OwnersMain"
            component={Owners.Main}
            options={{title: '企業管理'}}
          />
          <Drawer.Screen
            name="InvoicesMain"
            component={Invoices.Main}
            options={{title: '請求情報'}}
          />
        </>
      )}
    </Drawer.Navigator>
  );
});

const WithResponsive: React.FC = React.memo(() => {
  const {isDesktop} = useResponsive();
  const {windowHeight} = useHeight();
  return <MainScreen isDesktop={isDesktop} windowHeight={windowHeight} />;
});

// ドロワーで移動すると、メモ化していてもなぜか必ずRenderが呼ばれるので、
// とりあえず一段階置いてメモ化する
const Screen: React.FC = () => {
  const {shopList} = ShopsContainer.useContainer();
  console.log('shopList', shopList);
  return (
    <ShopContainer.Provider initialState={shopList}>
      <StationCacheContainer.Provider>
        <StationSearchContainer.Provider>
          <WithResponsive />
        </StationSearchContainer.Provider>
      </StationCacheContainer.Provider>
    </ShopContainer.Provider>
  );
};

export default Screen;
