import {
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {useFormContext} from 'react-hook-form';
import {createContainer} from 'unstated-next';
import {StationCacheContainer, useShopId} from '../../container';
import {QueueHelper} from '../../helper';
import {regions, string2PrefList} from './area/prefecture/def';
import {useResidenceFilter} from './area/residence/residenceFilters';
import {splitStationDump} from './area/station/stationFunc';
import {useStayFilter} from './area/stayHistory/stayHistoryFilters';
import {
  makeResidenceFetcher,
  makeStationFetcher,
  makeStayFetcher,
} from './area/userFetchFuncs';
import {Area, AttrForm} from './schema';
import {
  BaseUsersByStation,
  MasterCode,
  MeshCode,
  ResidenceUsersByMesh,
  StationCode,
  StayUsersByMesh,
  TargetsInitialState,
} from './types';
import {useAttrFilter} from './userAttribute/attrFilters';

export type RestoreInitState = {
  residenceMeshes?: MeshCode[];
  stayMeshes?: MeshCode[];
  stations?: string[];
  cities?: Array<any>;
  // リストア完了したら呼び出される関数
  onComplete?(restored: TargetsInitialState): void;
};

const queue = QueueHelper.getQueue();

// リストア進捗状態
type TaskProgress = {all: number; complete: number; error: number} | undefined;

export type RestoringState =
  | 'init'
  | Exclude<Area, 'prefecture'> // 都道府県はユーザー属性なので、ユーザー取得処理がない
  | 'done'
  | 'error';
type Action = {type: 'next'; value?: RestoreInitState} | {type: 'error'};

const reducer: Reducer<RestoringState, Action> = (
  state: RestoringState,
  action: Action,
) => {
  if (state === 'done') {
    return 'done';
  }
  if (action.type === 'next') {
    if (state === 'station') {
      return 'done';
    }
    if (state === 'init') {
      if (action.value?.residenceMeshes) {
        return 'areaResidence';
      }
    }
    if (state !== 'areaStay') {
      if (action.value?.stayMeshes) {
        return 'areaStay';
      }
    }
    if (action.value?.stations) {
      return 'station';
    }
  } else if (action.type === 'error') {
    return 'error';
  }
  return 'done';
};

type UseRestore = {
  restoringState: RestoringState;
  progress: TaskProgress;
};

// 配信先編集復帰モードで利用するコンテナ
function useRestore(initState?: RestoreInitState): UseRestore {
  const initStateRef = useRef<RestoreInitState>(initState ?? {});
  const restoredData = useRef<TargetsInitialState>({});
  const {shopId} = useShopId();
  const {getValues, setValue} = useFormContext<AttrForm>();
  // 各フィルター
  const {attrFilter} = useAttrFilter();
  const {residenceFilter} = useResidenceFilter();
  const {stayFilter} = useStayFilter();

  // 表示用
  const [restoringState, dispatch] = useReducer(reducer, 'init');
  const [progress, setProgress] = useState<TaskProgress>();
  const progressRef = useRef<TaskProgress>();
  const restoringRef = useRef<RestoringState | undefined>();

  // 次の処理に遷移
  const goNext = useCallback(() => {
    if (progressRef.current?.error) {
      // エラーがあるのでエラー状態に遷移
      dispatch({type: 'error'});
    } else {
      setProgress(undefined);
      progressRef.current = undefined;
      dispatch({type: 'next', value: initStateRef.current});
    }
  }, []);

  // task後処理（共通）
  const afterTask = useCallback(
    (success: boolean) => {
      setProgress((prev) => {
        if (!prev) {
          return undefined;
        }
        const update = {
          all: prev.all,
          complete:
            prev.all - (queue.getQueueLength() + queue.getPendingLength()),
          error: prev.error + (success ? 0 : 1),
        };
        progressRef.current = update;
        return update;
      });
      if (!QueueHelper.isFinish(queue)) {
        return;
      }
      goNext();
    },
    [goNext],
  );

  // 居住地の復帰処理
  const restoreResidence = useCallback(() => {
    if (
      initStateRef.current.residenceMeshes &&
      initStateRef.current.residenceMeshes.length > 0
    ) {
      restoredData.current.residenceUsers = {};
      const task = (mesh: MeshCode) =>
        makeResidenceFetcher(
          [mesh],
          restoredData.current.residenceUsers as ResidenceUsersByMesh,
          shopId,
          attrFilter,
          residenceFilter,
        );
      setProgress({
        all: initStateRef.current.residenceMeshes.length,
        complete: 0,
        error: 0,
      });
      initStateRef.current.residenceMeshes.forEach((mesh) => {
        queue.add(task(mesh)).then(afterTask);
      });
    } else {
      goNext();
    }
  }, [afterTask, attrFilter, goNext, residenceFilter, shopId]);

  // 滞在履歴の復帰処理
  const restoreStay = useCallback(() => {
    if (
      initStateRef.current.stayMeshes &&
      initStateRef.current.stayMeshes.length > 0
    ) {
      restoredData.current.stayUsers = {};
      const task = (mesh: MeshCode) =>
        makeStayFetcher(
          [mesh],
          restoredData.current.stayUsers as StayUsersByMesh,
          shopId,
          attrFilter,
          stayFilter,
        );
      setProgress({
        all: initStateRef.current.stayMeshes.length,
        complete: 0,
        error: 0,
      });
      initStateRef.current.stayMeshes.forEach((mesh) => {
        queue.add(task(mesh)).then(afterTask);
      });
    } else {
      goNext();
    }
  }, [afterTask, attrFilter, goNext, shopId, stayFilter]);

  // 駅の復帰処理
  const {getMasterCode, getStationCode} = StationCacheContainer.useContainer();
  const restoreStation = useCallback(() => {
    if (
      initStateRef.current.stations &&
      initStateRef.current.stations.length > 0
    ) {
      restoredData.current.stationUsers = {};
      const task = (station: StationCode | MasterCode, name: string) =>
        makeStationFetcher(
          [station],
          restoredData.current.stationUsers as BaseUsersByStation,
          shopId,
          attrFilter,
          getMasterCode,
          getStationCode,
          [name],
        );
      setProgress({
        all: initStateRef.current.stations.length,
        complete: 0,
        error: 0,
      });
      initStateRef.current.stations.forEach((str) => {
        // 駅コードを取り出す必要がある
        const split = splitStationDump(str);
        queue.add(task(split.code, split.name)).then(afterTask);
      });
    } else {
      goNext();
    }
  }, [afterTask, attrFilter, getMasterCode, getStationCode, goNext, shopId]);

  // 順番に復帰処理を進めていく
  useEffect(() => {
    if (restoringRef.current !== restoringState) {
      if (restoringState === 'init') {
        goNext();
      } else if (restoringState === 'areaResidence') {
        restoreResidence();
      } else if (restoringState === 'areaStay') {
        restoreStay();
      } else if (restoringState === 'station') {
        restoreStation();
      } else if (restoringState === 'done') {
        if (initStateRef.current?.onComplete) {
          const areaPrefecture = getValues('areaPrefecture');
          if (areaPrefecture) {
            // 旧都道府県→新市区町村への変換
            const prefIds = string2PrefList(areaPrefecture ?? []);
            const stringPrefIds = prefIds.map((prefId) =>
              prefId.toString().padStart(2, '0'),
            );
            const cities: Array<{
              prefCode: string;
              prefName: string;
            }> = [];
            stringPrefIds.forEach((id) =>
              regions.forEach((region) =>
                region.prefs.forEach((pref) => {
                  if (pref.prefCode === id) {
                    cities.push(pref);
                  }
                }),
              ),
            );
            setValue('areaPrefecture', undefined);
            restoredData.current.areaMode = 'prefecture';
            restoredData.current.cities = cities;
          } else if (initStateRef.current?.cities) {
            restoredData.current.areaMode = 'prefecture';
            restoredData.current.cities = initStateRef.current?.cities;
          } else {
            restoredData.current.areaMode =
              restoredData.current.residenceUsers ||
              restoredData.current.stayUsers ||
              restoredData.current.stationUsers
                ? 'areaTarget'
                : 'allArea';
          }
          initStateRef.current.onComplete(restoredData.current);
          restoredData.current = {};
        }
      }
      restoringRef.current = restoringState;
    }
  }, [
    getValues,
    setValue,
    goNext,
    restoreResidence,
    restoreStation,
    restoreStay,
    restoringState,
  ]);

  return {
    restoringState,
    progress,
  };
}

export const RestoreContainer = createContainer(useRestore);
