import {
  Chip,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Dialog as MuiDialog,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import {StyleSheet, View, ViewProps, ViewStyle} from 'react-native';
import {Dialog, HelperText, Portal, useTheme} from 'react-native-paper';
import {CouponUsage, DiscountType, EditState} from '../../API';
import {SaaSCouponRepository} from '../../_proto/services/SaaSCouponRepository';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {CouponsContainer, ShopContainer} from '../../container';
import {Helpers} from '../../helper';
import {MainScreenParams} from '../../navigation/MainScreen';
import {axiosHelper} from '../../service';
import {Colors, CommonStyles} from '../../theme';
import {Preview as PreviewComponent} from '../CouponPreview';
import {
  DoubleButtons,
  MaxWidth,
  NotRequired,
  RequiredNotice,
  Submenu,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
  WithHint,
} from '../Elements';
import {ImagePicker} from '../ImagePicker';
import {CouponCreator} from './container';
import {
  FormData,
  discountTypeInfo,
  formInfos,
  schemaFormData,
  usageInfo,
} from './schema';

const PUB_TEXT = `岩手県産地鶏の焼き鳥が自慢、駒込駅南口から徒歩5分の居酒屋です。

【トリマ家について】
・焼き鳥1本80円、生ビール390円！
・岩手県産地鶏の焼き鳥を低価格で！
・その他一品料理も充実！
お仕事帰りにぜひお立ち寄りください。

【お得なクーポン】
大人気の「やきとり5種盛」が390円→200円！
当店自慢の岩手県産地鶏を是非お試しください！

宴会のご予約はこちら
https://www.trip-mile.com/`;

const EC_TEXT = `海外のかわいい雑貨や、流行りの海外コスメを揃えたセレクトショップです。
食器・インテリア・日用雑貨・コスメ等、1,000アイテム以上を取り扱っております。

【トリマ限定スペシャルクーポン】

トリマオンラインストアで初めて購入する方は、購入画面でクーポンコード【TRIMA11】を入力すると、海外コスメ全品15%OFFさせていただきます！
※一部割引対象外の商品がございます

トリマオンラインストアを見る
https://www.trip-mile.com/

【メールアドレスを登録しよう】

人気アイテムはすぐに売り切れてしまいますので、メールアドレスを登録して入荷情報メールを受け取りましょう。

メールアドレス登録はこちら
https://www.trip-mile.com/`;

const BODY_CARE_TEXT = `肩や腰の痛みで悩んでいませんか？

駒込駅から徒歩2分の「トリマ整体院」では、つらい肩・腰のお悩みを解決します！

【おすすめポイント】
・原因や症状に合わせた施術をご提案
・平日夜21時まで営業
・駅から徒歩2分で通いやすい

【トリマ限定の特別料金】
トリマ整体院を初めてご利用の方限定
「肩・腰 集中コース(60分)」
通常価格5,000円(税込)→3,500円(税込)！

そろそろカラダのお悩みから開放されませんか？

▼ご予約はこちら
https://www.trip-mile.com/`;

const BEAUTY_SALON_TEXT = `溜まった疲れをスッキリ解消！
免疫力＆代謝アップ！
Beauty salon トリマは、10年後もカラダの中から美しくいたい方をサポートします。

* 〜 * 〜 * 〜 * 〜 *

【beauty salon トリマ】

100%天然アロマを使用したリンパマッサージで、心身ともに極上のひとときをお過ごしいただけます。

首・肩の痛み、余分な脂肪など、お悩みに合わせたコースもご用意。

駒込駅東口から徒歩2分
https://www.trip-mile.com/

* 〜 * 〜 * 〜 * 〜 *

【ご新規様限定クーポン】

当サロン一番人気の「全身リンパマッサージ60分コースが、クーポンご利用で8,800円(税込)→4,980円(税込)！

一度体験するだけで、施術後のカラダの変化に違いを感じていただけるはずです。

クーポンを使用したご予約はこちら
https://www.trip-mile.com/`;

const SCHOOL_TEXT = `初心者も経験者もお子様も大歓迎！
完全個人レッスンのギター教室です。

あなたも憧れのギタリストのあのフレーズを弾いてみませんか？

【こんな方におすすめ】

・初心者/未経験者
「ギターを弾けるようになりたい」「何から始めていいか分からない」「動画を見ながら練習しても上達しない」

・お子様の習い事
「子供に音楽の楽しさを知ってほしい」「子供に音感を身につけてほしい」

・経験者
「マンツーマンでプロのテクニックを教わりたい」「自分のレベルに合わせて教えてほしい」

★トリマご利用の方限定★
入会手続き時に本クーポンをご提示で、新規入会料金 通常16,500円(税込)が無料！

★無料体験レッスン受付中★
駒込駅徒歩3分！手ぶらでOK！
まずは無料体験レッスンで、ギタリストへの第一歩を踏み出しましょう！

★お気軽にお問い合わせください。
https://www.trip-mile.com/`;

const Provider: React.FC<{
  group?: 'on';
  mode?: 'edit' | 'new';
  editTarget?: {
    forms: FormData;
    images: SaaSImageContents[];
    barcode?: any;
    editState?: EditState;
  };
  children?: React.ReactNode;
}> = ({mode = 'new', editTarget, children}) => {
  // 編集モードなら、hook-form/コンテナに初期値を復帰させる
  const {forms, images, barcode, editState} =
    mode === 'edit' && editTarget
      ? editTarget
      : {
          forms: undefined,
          images: undefined,
          barcode: undefined,
          editState: undefined,
        };
  console.log(barcode);
  return (
    <VForm.Provider<FormData> schema={schemaFormData} defaultValues={forms}>
      <CouponCreator.Provider
        initialState={{images, barcode, forceState: editState}}>
        {children}
      </CouponCreator.Provider>
    </VForm.Provider>
  );
};

const ImagePickers: React.FC = () => {
  const {addImage, images, removeImage, changeImage} =
    CouponCreator.useContainer();
  const {
    formState: {errors},
  } = useFormContext<FormData>();
  return (
    <View>
      <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
        <WithHint id="couponPhoto">
          <Text>写真</Text>
        </WithHint>
        <RequiredNotice />
      </View>
      <View style={styles.pickersContainer}>
        {[...Array(5)].map((_, i) => {
          return (
            <ImagePicker
              index={i}
              key={'picker_' + i}
              image={images[i]}
              style={styles.picker}
              disabled={i !== images.length}
              onPicked={addImage}
              onRemove={() => removeImage(i)}
              onChange={changeImage}
            />
          );
        })}
      </View>
      <HelperText type="error" style={styles.errorMargin}>
        {errors.imageEnabled?.message}
      </HelperText>
    </View>
  );
};

const BarcodeImage: React.FC = () => {
  const {addBarcode, barcode, removeBarcode} = CouponCreator.useContainer();
  return (
    <View>
      <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
        <WithHint id="couponBarcode">
          <Text>バーコード画像</Text>
        </WithHint>
        <NotRequired />
      </View>
      <View style={styles.pickersContainer}>
        {[...Array(1)].map((_, i) => {
          return (
            <ImagePicker
              index={i + 1}
              key={'picker_' + i}
              image={
                barcode
                  ? {
                      key: 'barcode',
                      uri: barcode,
                    }
                  : undefined
              }
              style={styles.picker}
              onPicked={addBarcode}
              onRemove={() => removeBarcode()}
            />
          );
        })}
      </View>
      <VMargin />
    </View>
  );
};

const Forms: React.FC = () => {
  const {selected} = ShopContainer.useContainer();
  const {setValue} = useFormContext<FormData>();
  const setTemplate = (text: string) => {
    setValue('text', text);
  };
  const {dark} = useTheme();
  const back = dark ? {} : {backgroundColor: Colors.gray};
  return (
    <View style={CommonStyles.fullWidth}>
      <VForm.Text<FormData> {...formInfos.name} />
      <View style={styles.select}>
        <VForm.Select<FormData> {...discountTypeInfo} />
      </View>
      <SelectedDiscountInput />
      <View style={CommonStyles.margin.top} />
      <ImagePickers />
      <BarcodeImage />
      <Text>説明のテンプレート</Text>
      <Grid container spacing={1}>
        <Grid item>
          <Chip
            label="居酒屋"
            onClick={() => setTemplate(PUB_TEXT)}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Chip
            label="通販"
            onClick={() => setTemplate(EC_TEXT)}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Chip
            label="整体院"
            onClick={() => setTemplate(BODY_CARE_TEXT)}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Chip
            label="美容院"
            onClick={() => setTemplate(BEAUTY_SALON_TEXT)}
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Chip
            label="塾・教室"
            onClick={() => setTemplate(SCHOOL_TEXT)}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <VMargin />
      <VForm.Text<FormData> {...formInfos.text} />
      <VForm.Text<FormData> {...formInfos.rule} />
      <VForm.Text<FormData> {...formInfos.cvLinkUrl} />
      <View style={[styles.discountInput, back]}>
        <VForm.Text<FormData> {...formInfos.cvLinkText} />
      </View>
      <VForm.Text<FormData> {...formInfos.cvPhone} />
      {selected?.legalUrl ? (
        <VForm.Radio<FormData>
          {...usageInfo}
          disabledKeys={[CouponUsage.ONETIME]}
        />
      ) : (
        <VForm.Radio<FormData> {...usageInfo} />
      )}
    </View>
  );
};

const TitleForm: React.FC = () => {
  return (
    <View style={CommonStyles.fullWidth}>
      <VForm.Text<FormData> {...formInfos.title} />
    </View>
  );
};

export type CouponSubmitType =
  | 'editing'
  | 'fixed'
  | 'fixAndGo'
  | 'fixAndCreative';

const LABEL: {[P in CouponSubmitType]: string} = {
  editing: '一時保存',
  fixed: '保存して終了',
  fixAndGo: '保存して配信',
  fixAndCreative: '保存して戻る',
};

const SubmitButton: React.FC<{
  type: CouponSubmitType;
  disabled: boolean;
  onStartSubmit(): void;
  onFinishSubmit(
    type: CouponSubmitType,
    success: boolean,
    reason?: string,
  ): void;
  style?: ViewStyle;
  editState?: EditState;
  group?: 'on';
}> = ({
  type,
  disabled,
  onStartSubmit,
  onFinishSubmit,
  style,
  editState,
  group,
}) => {
  const {checkedIds} = CouponsContainer.useContainer();
  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isFormError, setFormError] = React.useState<boolean>(false);
  const [deliveryLabel, setDeliveryLabel] = React.useState<string>('');
  const {submit} = CouponCreator.useContainer();
  const {
    setValue,
    handleSubmit,
    formState: {errors},
  } = useFormContext<FormData>();
  // エラー監視
  const nowError = errors && Object.keys(errors).length > 0;
  if (isFormError && !nowError) {
    // エラーが消えたら落とす
    setFormError(nowError);
  }
  const mode =
    type === 'fixAndGo' || type === 'fixAndCreative' ? 'contained' : 'outlined';
  const onSubmit: SubmitHandler<FormData> = async (data) => {
    onStartSubmit();
    let success = false;
    let reason: string | undefined;
    setLoading(true);
    try {
      if (group !== 'on') {
        const coupon = await submit(data, EditState.FIXED);
        // 保存したらIDが生成されるので保持
        setValue('id', coupon.id);
        success = true;
      } else {
        await Promise.all(
          checkedIds.map(async (shopId: string) => {
            await submit(data, EditState.FIXED, shopId);
          }),
        );
        success = true;
      }
    } catch (err: any) {
      reason = axiosHelper.commonErrorHandler(err);
      console.log('[Coupon] post error', err);
    } finally {
      setOpen(false);
      setLoading(false);
      onFinishSubmit(type, success, reason);
    }
  };
  const onSubmitError: SubmitErrorHandler<FormData> = () => {
    setFormError(true);
  };
  const openDialog: SubmitHandler<FormData> = async (data) => {
    if (editState === EditState.DELIVERED) {
      if (data.id) {
        const count = await SaaSCouponRepository.getCouponCount(data?.id);
        const delivery = `${count.deliveries}回の配信で${Helpers.sepComma(
          count.users,
        )}人が閲覧済み`;
        console.log(delivery);
        setDeliveryLabel(delivery);
      }
      setOpen(true);
    } else {
      await onSubmit(data);
    }
  };
  const hideDialog = () => {
    setOpen(false);
  };

  return (
    <View style={style}>
      <TrimaLoadingButton
        variant={mode}
        loading={isLoading}
        loadingPosition="start"
        disabled={disabled || isLoading}
        // Todo ボタンごとの押下後の振る舞いの実装
        onClick={handleSubmit((data) => openDialog(data), onSubmitError)}>
        {LABEL[type]}
      </TrimaLoadingButton>
      <HelperText type="error">
        {isFormError && 'フォームの入力に誤りがあります'}
      </HelperText>
      <MuiDialog
        open={open}
        onClose={hideDialog}
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          <Typography variant="inherit" color="secondary">
            配信中のクーポンを変更しますか？
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">
              このクーポンは既に配信が開始されています。
            </Typography>
            <Typography variant="body1">
              変更内容は配信済みのクーポンにも反映されます。
            </Typography>
            <VMargin />
            <Typography variant="body1">■配信回数と閲覧回数</Typography>
            <Typography variant="body1">{deliveryLabel}</Typography>
            <VMargin />
            <TrimaLoadingButton
              color="error"
              variant="contained"
              onClick={handleSubmit((data) => onSubmit(data), onSubmitError)}
              disabled={isLoading}
              loading={isLoading}
              loadingPosition="start">
              変更
            </TrimaLoadingButton>
            <VMargin />
            <TrimaButton variant="outlined" onClick={hideDialog}>
              キャンセル
            </TrimaButton>
          </DialogContentText>
        </DialogContent>
      </MuiDialog>
    </View>
  );
};

const Dummy: React.FC = () => null;

type Result = {
  success: boolean;
  reason?: string;
};

const Submit: React.FC<{
  from?: keyof MainScreenParams;
  onSubmit(type: CouponSubmitType, couponId?: string): void;
  editTarget?: {
    forms: FormData;
    images: SaaSImageContents[];
    barcode?: string;
    editState?: EditState;
  };
  group?: 'on';
}> = ({from, onSubmit, editTarget, group}) => {
  console.log('editTarget', editTarget);
  const [disabled, setDisabled] = React.useState(false);
  const [result, setResult] = React.useState<Result | undefined>();
  const {getValues} = useFormContext<FormData>();
  const param = {
    disabled,
    onStartSubmit: () => setDisabled(true),
    onFinishSubmit: (
      type: CouponSubmitType,
      success: boolean,
      reason?: string,
    ) => {
      if (type !== 'editing' && success) {
        // 保存して遷移するときはとりあえずダイアログは出さないで遷移
        const couponId = getValues('id') ?? undefined;
        onSubmit(type, couponId);
      } else {
        setResult({success, reason});
      }
      setDisabled(false);
    },
  };
  const hideDialog = () => setResult(undefined);
  return (
    <View style={CommonStyles.fullWidth}>
      {from === 'CreativesCreate' || from === 'CreativesGroupCreate' ? (
        <DoubleButtons
          button1={<Dummy />}
          button2={
            <SubmitButton
              type="fixAndCreative"
              {...param}
              editState={editTarget?.editState}
            />
          }
        />
      ) : group !== 'on' ? (
        <DoubleButtons
          button1={
            <SubmitButton
              type="fixed"
              {...param}
              editState={editTarget?.editState}
            />
          }
          button2={
            <SubmitButton
              type="fixAndGo"
              {...param}
              editState={editTarget?.editState}
            />
          }
        />
      ) : (
        <MaxWidth maxWidth={586}>
          <SubmitButton
            type="fixed"
            {...param}
            editState={editTarget?.editState}
            group={group}
          />
        </MaxWidth>
      )}
      <Portal>
        <Dialog visible={!!result} onDismiss={hideDialog}>
          <Dialog.Title>
            {result?.success
              ? '保存しました'
              : `保存に失敗しました ${result?.reason}`}
          </Dialog.Title>
          <Dialog.Actions>
            <TrimaButton variant="outlined" onClick={hideDialog}>
              OK
            </TrimaButton>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

const DiscountInput: React.FC<{type: DiscountType}> = React.memo(({type}) => {
  const {dark} = useTheme();
  const back = dark ? {} : {backgroundColor: Colors.gray};
  const {trigger} = useFormContext<FormData>();
  const priceFrom = useWatch<FormData>({name: ['priceFrom']});
  React.useEffect(() => {
    trigger(['priceTo']).then();
  }, [priceFrom, trigger]);
  switch (type) {
    case DiscountType.BY_TEXT:
      // free Text
      return (
        <View style={[styles.discountInput, back]}>
          <VForm.Text<FormData> {...formInfos.freeText} />
        </View>
      );
    case DiscountType.FROM_PRICE:
      // double number
      return (
        <View style={[styles.discountInput, back]}>
          <VForm.Text<FormData> {...formInfos.priceFrom} />
          <VForm.Text<FormData> {...formInfos.priceTo} />
        </View>
      );
    case DiscountType.BY_PERCENT:
    case DiscountType.BY_PRICE:
      // single number
      return (
        <View style={[styles.discountInput, back]}>
          <VForm.Text<FormData> {...formInfos[type]} />
        </View>
      );
    case DiscountType.BY_FREE:
    case DiscountType.BY_HALF:
    default:
      // no input
      return <View style={CommonStyles.margin.bottom} />;
  }
});

const SelectedDiscountInput: React.FC = () => {
  const {watch} = useFormContext<FormData>();
  const type = watch('discountType') ?? discountTypeInfo.defaultValue;
  return <DiscountInput type={type} />;
};

const Preview: React.FC<{
  style?: ViewProps;
}> = ({style}) => {
  const {clearErrors, watch, setValue, trigger} = useFormContext<FormData>();
  const {images, barcode} = CouponCreator.useContainer();
  React.useEffect(() => {
    const imageEnabled = images.length > 0;
    setValue('imageEnabled', imageEnabled);
    if (imageEnabled) {
      // 追加した際にエラーを消すためにトリガーをかける
      trigger('imageEnabled');
    }
  }, [clearErrors, images, setValue, trigger]);
  const data = watch();
  return (
    <View style={[styles.preview, style]}>
      <Submenu style={styles.previewTitle}>プレビュー</Submenu>
      <PreviewComponent coupon={{data, images, barcode}} showShop={true} />
    </View>
  );
};

export const Coupon = {
  Provider,
  Forms,
  TitleForm,
  Submit,
  Preview,
};

const styles = StyleSheet.create({
  preview: {
    flex: 1,
    alignItems: 'center',
  },
  previewTitle: {
    alignSelf: 'flex-start',
  },
  pickersContainer: {
    ...CommonStyles.flex.row,
    flexWrap: 'wrap',
  },
  picker: {
    margin: 1,
  },
  select: {
    zIndex: 100,
  },
  discountInput: {
    zIndex: 50,
    marginTop: -4,
    paddingTop: 15,
    paddingHorizontal: 8,
    marginBottom: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  errorMargin: {minHeight: 22},
});
