import React from 'react';
import {Image, StyleSheet} from 'react-native';
import gem from '../../assets/gem.png';

export const Mile: React.FC = () => {
  return <Image source={{uri: gem}} style={styles.min} />;
};

const styles = StyleSheet.create({
  min: {width: 13, height: 13},
});
