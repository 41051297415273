// eslint-disable-next-line @typescript-eslint/no-var-requires
const meshcode2latlng = require('meshcode2latlng');

export interface LonLatBound {
  west: number;
  north: number;
  east: number;
  south: number;
}

interface LonLat {
  lng: number;
  lat: number;
}

const InitBound = {
  west: 180,
  north: -90,
  east: -180,
  south: 90,
};

type MeshResolution = 1 | 2 | 3 | 4 | 5;
export class MeshRepository {
  static boundOfMesh(mesh: string): LonLatBound {
    if (!mesh) {
      throw Error('bad param');
    }

    switch (mesh.length) {
      case 4:
        return meshcode2latlng.first(Number(mesh));
      case 6:
        return meshcode2latlng.second(Number(mesh));
      case 8:
        return meshcode2latlng.third(Number(mesh));
      case 9:
        return meshcode2latlng.half(Number(mesh));
      case 10:
        return meshcode2latlng.quater(Number(mesh));
      default:
        throw Error('bad param len');
    }
  }

  static boundOfMeshs(meshs: string[]): LonLatBound {
    const ret = {...InitBound};
    meshs.map((mesh: string) => {
      const bound = MeshRepository.boundOfMesh(mesh);
      ret.west = Math.min(ret.west, bound.west);
      ret.north = Math.max(ret.north, bound.north);
      ret.east = Math.max(ret.east, bound.east);
      ret.south = Math.min(ret.south, bound.south);
    });
    return ret;
  }

  static lnglat2Mesh(lonlat: LonLat, resolution: MeshResolution): string {
    const {lng, lat} = lonlat;
    const p = Math.floor((lat * 60) / 40);
    const a = (lat * 60) % 40;
    const q = Math.floor(a / 5);
    const b = a % 5;
    const r = Math.floor((b * 60) / 30);
    const c = (b * 60) % 30;
    const s = Math.floor(c / 15);
    const d = c % 15;
    const t = Math.floor(d / 7.5);

    const u = Math.floor(lng - 100);
    const f = lng - 100 - u;
    const v = Math.floor((f * 60) / 7.5);
    const g = (f * 60) % 7.5;
    const w = Math.floor((g * 60) / 45);
    const h = (g * 60) % 45;
    const x = Math.floor(h / 22.5);
    const i = h % 22.5;
    const y = Math.floor(i / 11.25);

    const m = s * 2 + (x + 1);
    const n = t * 2 + (y + 1);

    //1次メッシュ
    let mesh = '' + p + u;
    //2次メッシュ
    if (resolution >= 2) {
      mesh = mesh + q + v;
      //3次メッシュ
      if (resolution >= 3) {
        mesh = mesh + r + w;
        // 1/2メッシュ
        if (resolution >= 4) {
          mesh = mesh + m;
          // 1/4メッシュ
          if (resolution >= 5) {
            mesh = mesh + n;
          }
        }
      }
    }
    return mesh;
  }
}
