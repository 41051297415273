import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {View} from 'react-native';
import defaultIcon from '../../../assets/icon_shop.png';
// import InfiniteScroll from 'react-infinite-scroller';
// import InfiniteScroll from 'react-infinite-scroll-component';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Clear,
  Delete,
  Edit as EditIcon,
  FileCopyOutlined,
  Forward,
  MoreVert,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Button as MuiButton,
  List as MuiList,
  Menu as MuiMenu,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {ActivityIndicator} from 'react-native-paper';
import * as yup from 'yup';
import {FullAddress, SaaSShop} from '../../../API';
import {BRAKE_POINT} from '../../../components';
import {
  BackLink,
  Container,
  MaxWidth,
  Menu,
  TrimaButton,
  TrimaLoadingButton,
  VMargin,
} from '../../../components/Elements';
import {ShopDeleteDialog} from '../../../components/Shop/ShopDeleteDialog';
import {
  ShopContainer,
  ShopGroupsContainer,
  ShopsContainer,
} from '../../../container';
import {
  MainNavigationProp,
  MainRouteProp,
} from '../../../navigation/MainScreen';
import {graphQLService} from '../../../service';
import {Colors, CommonStyles} from '../../../theme';
import {ExcludeVisitorsContainer} from '../ExcludeVisitors/ExcludeVisitorsContainer';
import {Edit} from './Edit';

type NProp = MainNavigationProp<'ShopsMain'>;
type RProp = MainRouteProp<'GroupsMain'>;

// type RProp = MainRouteProp<'ShopsMain'>;

const AddButton: React.FC<{groupId: string}> = ({groupId}) => {
  const navigation = useNavigation();
  const gotoCreate = () =>
    navigation.navigate('ShopsCreate', {from: 'ShopsMain', groupId});
  return (
    <Box pt={1}>
      <TrimaButton
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={gotoCreate}
      >
        店舗を新規作成
      </TrimaButton>
    </Box>
  );
};

function dumpAddress(address: FullAddress): string {
  return `${address.pref || ''}${address.city || ''}${address.detail || ''}${
    address.building || ''
  }`;
}

function convert(shop: SaaSShop) {
  return {
    ...shop,
    address: shop.address ? dumpAddress(shop.address) : '-',
    phone: shop.phone && shop.phone !== '' ? shop.phone : '-',
  };
}

const Move2GroupDialog: React.FC<{
  open: boolean;
  onClose(): void;
  shop: any;
}> = ({open, onClose, shop}) => {
  const [groupId, setGroupId] = React.useState<string | undefined>('');
  const {shopGroups} = ShopGroupsContainer.useContainer();
  const {setGroup2Shop} = ShopsContainer.useContainer();
  const {selected, setSelectedShop} = ShopContainer.useContainer();
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setGroupId(event.target.value as string);
  };
  const handleClose = () => {
    onClose();
  };

  const move = async () => {
    setLoading(true);
    const updatedShop = await setGroup2Shop({
      id: shop.id,
      groupId: groupId === 'no-group' ? null : groupId,
    });
    if (selected && updatedShop.id === selected.id) {
      setSelectedShop(updatedShop);
    }
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog
      sx={{
        '& .MuiDialogContent-root': {
          minWidth: 300,
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        移動先グループを選択
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{`${shop.name}をどこに移動しますか？`}</Typography>
          <Box p={1} />
          <FormControl fullWidth variant="outlined">
            <Select
              value={groupId}
              onChange={handleChange}
              displayEmpty
              renderValue={(value: unknown): React.ReactNode => {
                console.log(value);
                if (value === 'no-group') {
                  return 'グループなし';
                } else if (value !== '') {
                  return shopGroups.find(
                    (shopGroups) => shopGroups.id === value,
                  ).name;
                } else {
                  return <em style={{color: '#BBBBBB'}}>選択してください</em>;
                }
              }}
            >
              {shopGroups.map((shopGroup) => {
                return (
                  <MenuItem
                    value={shopGroup.id}
                    disabled={shopGroup.id === shop.groupId}
                  >
                    {shopGroup.name}
                  </MenuItem>
                );
              })}
              <MenuItem value={'no-group'}>グループなし</MenuItem>
            </Select>
          </FormControl>
        </DialogContentText>
        <Box p={1} />
        <TrimaLoadingButton
          variant="contained"
          onClick={move}
          disabled={groupId === shop.groupId || groupId === '' || loading}
          loading={loading}
        >
          OK
        </TrimaLoadingButton>
        <Box p={1} />
      </DialogContent>
    </Dialog>
  );
};

const ShopListItem: React.FC<{
  shop: any;
}> = React.memo(({shop}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigation = useNavigation<NProp>();
  const [showDialog, setDialog] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const gotoEdit = () => navigation.navigate('ShopsEdit', {id: shop.id});
  const gotoCopy = () => navigation.navigate('ShopsCopy', {id: shop.id});
  const gotoDash = () => navigation.navigate('DashBoard');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onDismiss = (removed: boolean) => {
    setDialog(false);
    if (removed) {
      // 削除後に一覧をリフレッシュしたいので、仮だがとりあえずダッシュボードに飛んでしまう
      gotoDash();
    }
  };

  return (
    <>
      <ListItem
        sx={{
          '&.MuiListItem-root': {
            padding: 0,
            bgcolor: 'background.paper',
            border: `1px solid ${Colors.main}`,
          },
        }}
        secondaryAction={
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert color="primary" />
            </IconButton>
            <MuiMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={gotoEdit}>
                <ListItemIcon>
                  <EditIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  編集する
                </Typography>
              </MenuItem>
              <MenuItem onClick={gotoCopy}>
                <ListItemIcon>
                  <FileCopyOutlined fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  複製する
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpen(true);
                }}
              >
                <ListItemIcon>
                  <Forward fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  グループに移動する
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDialog(true);
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  削除する
                </Typography>
              </MenuItem>
            </MuiMenu>
          </>
        }
      >
        <ListItemButton
          onClick={() => navigation.navigate('ShopsDetail', {id: shop.id})}
          sx={{
            '&.MuiButtonBase-root': {
              py: 2,
            },
          }}
        >
          <ListItemText
            id={shop.id}
            primary={
              <>
                <Box display="flex" flexDirection="row">
                  <Avatar src={shop.icon ? shop.icon : defaultIcon} />
                  <Box pr={0.5} />
                  <Typography
                    style={{fontWeight: 500, color: Colors.main}}
                    variant="subtitle1"
                    component="span"
                  >
                    {shop.name}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block"
                  >
                    {shop.address}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block"
                  >
                    {shop.phone}
                  </Typography>
                </Box>
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      <ShopDeleteDialog
        visible={showDialog}
        id={shop.id}
        name={shop.name}
        onDismiss={onDismiss}
        onError={gotoDash}
      />
      <Move2GroupDialog open={open} onClose={handleCloseDialog} shop={shop} />
    </>
  );
});

const Main: React.FC = () => {
  // Todo コンテナ化した方がいいかは要検討
  const {shopGroups} = ShopGroupsContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const [word, setWord] = React.useState('');

  useEffect(() => {
    async function fetchData() {
      if (id) {
        await graphQLService.getShopGroup(id);
      } else {
        console.log('error');
      }
    }
    fetchData();
  }, [id, route]);

  const {
    control,
    formState: {errors},
    handleSubmit,
    reset,
  } = useForm<{
    word: string;
  }>({
    resolver: yupResolver(
      yup
        .object({
          word: yup.string().required('必須の項目です'),
        })
        .required(),
    ),
  });
  const search = (data: {word: string}) => {
    console.log(data);
    setWord(data.word);
  };
  const onSubmit: SubmitHandler<{
    word: string;
  }> = (data) => {
    search(data);
  };
  const onSubmitError = () => true;
  const processing = !!errors.word;

  const navigation = useNavigation<NProp>();

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('ShopsMain');
    }
  };

  return (
    <ExcludeVisitorsContainer.Provider>
      <Container>
        <MaxWidth
          maxWidth={BRAKE_POINT.desktop}
          style={CommonStyles.padding.all}
        >
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label="店舗情報に戻る" onClick={goBack} />
          </Box>
          <Menu>
            {shopGroups.find((shopGroup) => shopGroup.id === id).name}
          </Menu>
          <View style={CommonStyles.margin.top} />
          <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
            <View style={CommonStyles.flex.full}>
              {!shopList || !shopGroups ? (
                <ActivityIndicator size={60} />
              ) : (
                <>
                  <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={10}>
                        <FormControl
                          required
                          error={'word' in errors}
                          component="fieldset"
                          fullWidth
                        >
                          <Controller
                            name="word"
                            control={control}
                            rules={{
                              required: '入力してください',
                            }}
                            render={({field}) => (
                              <TextField
                                sx={{
                                  '& .MuiInputBase-root': {
                                    backgroundColor: '#fcfcfb',
                                    borderRadius: 1,
                                    height: 48,
                                  },
                                }}
                                {...field}
                                margin="dense"
                                required
                                variant="outlined"
                                error={'word' in errors}
                                helperText={errors.word?.message}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        reset({
                                          word: '',
                                        });
                                        setWord('');
                                      }}
                                    >
                                      <Clear />
                                    </IconButton>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <MuiButton
                          type="submit"
                          color="primary"
                          variant="contained"
                          size="large"
                          disabled={processing}
                          onClick={handleSubmit(search, onSubmitError)}
                        >
                          検索
                        </MuiButton>
                      </Grid>
                    </Grid>
                  </form>
                  <Box p={1} />
                  <Typography variant="body1" component="h2">
                    店舗
                  </Typography>
                  <AddButton groupId={id as string} />
                  <VMargin />
                  {shopList.filter((shop) => shop.groupId === id).length ===
                  0 ? (
                    <Typography variant="body1">店舗はありません</Typography>
                  ) : (
                    <MuiList>
                      {shopList
                        .filter((shop) => shop.groupId === id)
                        .filter((shop) => shop.name.includes(word))
                        .map(convert)
                        .map((shop: any) => {
                          return (
                            <React.Fragment key={shop.id}>
                              <ShopListItem shop={shop} />
                              <Box p={0.5} />
                            </React.Fragment>
                          );
                        })}
                    </MuiList>
                  )}
                </>
              )}
            </View>
          </MaxWidth>
        </MaxWidth>
      </Container>
    </ExcludeVisitorsContainer.Provider>
  );
};

export const Groups = {
  Main,
  Edit,
};
