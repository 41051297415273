import React from 'react';
import {SaaSAgencyRepository} from '../../_proto/services/SaaSAgencyRepository';

export function useAgencyName(agentCode?: string): {agencyName: string} {
  const [agencyName, setName] = React.useState<string>('取得中...');
  React.useEffect(() => {
    if (agentCode) {
      SaaSAgencyRepository.get(agentCode)
        .then((dto) => setName(dto.name))
        .catch(() => setName('代理店名の取得に失敗しました'));
    } else {
      setName('代理店コードがありません');
    }
  }, [agentCode]);
  return {agencyName};
}
