import AsyncStorage from '@react-native-async-storage/async-storage';
import {DefaultTheme, NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import Amplify from 'aws-amplify';
import * as queryString from 'query-string';
import React, {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import awsExports from '../aws-exports';
import {ContainerFull} from '../components';
import {SignInContainer} from '../components/Auth/SignInContainer';
import {PrefectureContainer} from '../components/Targets/area/prefecture/PrefectureContainer';
import {
  AccessRoleContainer,
  AdminContainer,
  AuthContainer,
  CouponsContainer,
  NewsContainer,
  OwnerContainer,
  PaymentInfoContainer,
  ShopGroupsContainer,
  ShopsContainer,
  StepNavigationContainer,
  StripeContainer,
  TargetsContainer,
  UserInfoContainer,
  ZendeskContainer,
} from '../container';
import {
  Account,
  Error,
  Invite,
  LandingPage,
  OwnerRegister,
  PasswordForgot,
  PaymentRegister,
  PaymentRegisterReview,
  PreSignUp,
  SelectOwner,
  ShopRegister,
  SignIn,
  SignUp,
} from '../screens';
import {AgencyPreRegist} from '../screens/AgencyPreRegist';
import {AgencySignUp} from '../screens/AgencySignUp';
import {RegisterDone} from '../screens/RegisterDone';
import {AccessManageContainer} from '../screens/Settings/AccessManageContainer';
import {SplashScreen} from '../screens/Splash';
import {Colors} from '../theme';
import './GestureHandler';
import Linkings from './Linking';
import MainScreen from './MainScreen';
import OwnerSelector, {OwnerSelectContainer} from './OwnerSelector';
// メンテナンスページを出すときはコメントアウト
// import {MaintenancePage} from '../screens/LandingPage/MaintenancePage';

Amplify.configure(awsExports);

export type StackParams = {
  Splash: undefined;
  Error: undefined;
  LandingPage: undefined;
  Main: undefined;
  Auth: undefined;
  Owner: undefined;
  PreSignUp: undefined;
  SignUp: undefined;
  SignIn: undefined;
  PasswordForgot: undefined;
  SelectOwner: undefined;
  OwnerRegister: undefined;
  PaymentRegister: undefined;
  PaymentRegisterReview: undefined;
  RegisterDone: undefined;
  ShopRegister: undefined;
  SuspendMain: undefined;
  InvoicesMain: undefined;
  AccountMain: {header?: boolean};
  AccountChangePhoneNumber: {header?: boolean};
  AccountChangePassword: {header?: boolean};
  AccountChangeEmail: {header?: boolean};
  AccountChangeName: {header?: boolean};
  AccountChangeSendEmail: {header?: boolean};
  SettingsChangeOwner: undefined;
  SettingsChangePayment: undefined;
  AccountDelete: undefined;
  MaintenancePage: undefined;
  Invite: undefined;
  AgencyPreRegist: undefined;
  AgencySignUp: undefined;
};

const Stack = createStackNavigator<StackParams>();

// Todo Paper theme との統合
const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    card: Colors.header,
    border: Colors.headerShadow,
    text: Colors.white,
    background: Colors.base,
  },
};

// メンテナンスページを出すときはコメントアウト
// const Maintance: React.FC = () => {
//   return (
//     <ContainerFull>
//       <NavigationContainer linking={Linkings} theme={theme}>
//         <Stack.Navigator
//           headerMode="screen"
//           screenOptions={{headerShown: false}}>
//           <Stack.Screen
//             name="MaintenancePage"
//             component={MaintenancePage}
//             options={{title: 'トリマクーポン'}}
//           />
//         </Stack.Navigator>
//       </NavigationContainer>
//     </ContainerFull>
//   );
// };

const StackScreens: React.FC = () => {
  const {checkAuthUser} = AuthContainer.useContainer();
  const {fetchUserInfo} = UserInfoContainer.useContainer();
  const [isLoading, setLoading] = useState(true);

  const {step, setStep2SelectOwner, setStep2SignOut, checkInitData} =
    StepNavigationContainer.useContainer();
  const {set} = AdminContainer.useContainer();
  const location = useLocation();
  const parsed: any = queryString.parse(location.search);
  const isAdmin = useRef<boolean>(parsed.admin);
  const userId = useRef<string>(parsed.user_id);
  const ownerId = useRef<string>(parsed.owner_id);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await checkAuthUser(true);
        if (!isAdmin.current) {
          await fetchUserInfo(user.username);
          const ownerId = await AsyncStorage.getItem('owner');
          if (ownerId) {
            checkInitData(ownerId, user.username);
          } else {
            setStep2SelectOwner();
          }
        } else {
          set({
            isAdmin: !!isAdmin.current,
            userId: userId.current,
            ownerId: ownerId.current,
          });
          console.log('set', {
            isAdmin: !!isAdmin.current,
            userId: userId.current,
            ownerId: ownerId.current,
          });
          await fetchUserInfo(userId.current);
          // setStep2SelectOwner();
          checkInitData(ownerId.current, userId.current);
        }
      } catch (err: any) {
        console.log(err);
        setStep2SignOut();
      } finally {
        setLoading(false);
      }
    };
    if (userId) {
      fetchData();
    }
  }, [
    checkAuthUser,
    setStep2SelectOwner,
    setStep2SignOut,
    checkInitData,
    fetchUserInfo,
    set,
  ]);

  console.log(step);
  return (
    <ContainerFull>
      {isLoading || step === 'loading' ? (
        <SplashScreen />
      ) : (
        <NavigationContainer linking={Linkings} theme={theme}>
          <Stack.Navigator
            headerMode="screen"
            screenOptions={{headerShown: false}}>
            {step === 'signOut' ? (
              <>
                <Stack.Screen
                  name="LandingPage"
                  component={LandingPage}
                  options={{title: 'トリマ広告ダイレクト'}}
                />
                <Stack.Screen
                  name="PreSignUp"
                  component={PreSignUp}
                  options={{title: 'トリマビジネスアカウント仮登録'}}
                />
                <Stack.Screen
                  name="SignUp"
                  component={SignUp}
                  options={{title: 'トリマビジネスアカウント本登録'}}
                />
                <Stack.Screen
                  name="SignIn"
                  component={SignIn}
                  options={{title: 'ログイン'}}
                />
                <Stack.Screen
                  name="PasswordForgot"
                  component={PasswordForgot}
                  options={{title: 'パスワードを忘れた'}}
                />
                <Stack.Screen
                  name="AgencyPreRegist"
                  component={AgencyPreRegist}
                  options={{title: '取次代理トリマビジネスアカウント仮登録'}}
                />
                <Stack.Screen
                  name="AgencySignUp"
                  component={AgencySignUp}
                  options={{title: '取次代理トリマビジネスアカウント本登録'}}
                />
                <Stack.Screen
                  name="Invite"
                  component={Invite}
                  options={{title: 'Invite'}}
                />
                <Stack.Screen
                  name="Error"
                  component={Error}
                  options={{title: 'エラー'}}
                />
              </>
            ) : step === 'unselectedOwner' ? (
              <>
                <Stack.Screen
                  name="SelectOwner"
                  component={SelectOwner.Main}
                  options={{title: '企業の選択'}}
                />
                <Stack.Screen
                  name="Invite"
                  component={Invite}
                  options={{title: 'Invite'}}
                />
                <Stack.Screen
                  name="AccountMain"
                  component={Account.Main}
                  options={{title: 'アカウント設定'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePhoneNumber"
                  component={Account.ChangePhoneNumber}
                  options={{title: '電話番号の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeEmail"
                  component={Account.ChangeEmail}
                  options={{title: 'パスワードの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePassword"
                  component={Account.ChangePassword}
                  options={{title: 'メールアドレスの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeName"
                  component={Account.ChangeName}
                  options={{title: '名前の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeSendEmail"
                  component={Account.ChangeSendEmail}
                  options={{title: 'メール送信の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="Error"
                  component={Error}
                  options={{title: 'エラー'}}
                />
              </>
            ) : step === 'unregisteredOwner' ? (
              <>
                <Stack.Screen
                  name="OwnerRegister"
                  component={OwnerRegister}
                  options={{title: '企業情報登録'}}
                />
                <Stack.Screen
                  name="Invite"
                  component={Invite}
                  options={{title: 'Invite'}}
                />
                <Stack.Screen
                  name="AccountMain"
                  component={Account.Main}
                  options={{title: 'アカウント設定'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePhoneNumber"
                  component={Account.ChangePhoneNumber}
                  options={{title: '電話番号の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeEmail"
                  component={Account.ChangeEmail}
                  options={{title: 'パスワードの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePassword"
                  component={Account.ChangePassword}
                  options={{title: 'メールアドレスの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeName"
                  component={Account.ChangeName}
                  options={{title: '名前の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeSendEmail"
                  component={Account.ChangeSendEmail}
                  options={{title: 'メール送信の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="Error"
                  component={Error}
                  options={{title: 'エラー'}}
                />
              </>
            ) : step === 'unregisteredPayment' ? (
              <>
                <Stack.Screen
                  name="PaymentRegister"
                  component={PaymentRegister}
                  options={{title: '支払い方法の登録'}}
                />
                <Stack.Screen
                  name="Invite"
                  component={Invite}
                  options={{title: 'Invite'}}
                />
                <Stack.Screen
                  name="AccountMain"
                  component={Account.Main}
                  options={{title: 'アカウント設定'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePhoneNumber"
                  component={Account.ChangePhoneNumber}
                  options={{title: '電話番号の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeEmail"
                  component={Account.ChangeEmail}
                  options={{title: 'パスワードの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePassword"
                  component={Account.ChangePassword}
                  options={{title: 'メールアドレスの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeName"
                  component={Account.ChangeName}
                  options={{title: '名前の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeSendEmail"
                  component={Account.ChangeSendEmail}
                  options={{title: 'メール送信の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="Error"
                  component={Error}
                  options={{title: 'エラー'}}
                />
              </>
            ) : step === 'paymentReviewed' ? (
              <>
                <Stack.Screen
                  name="PaymentRegisterReview"
                  component={PaymentRegisterReview}
                  options={{title: 'Paid審査確認'}}
                />
                <Stack.Screen
                  name="Invite"
                  component={Invite}
                  options={{title: 'Invite'}}
                />
                <Stack.Screen
                  name="AccountMain"
                  component={Account.Main}
                  options={{title: 'アカウント設定'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePhoneNumber"
                  component={Account.ChangePhoneNumber}
                  options={{title: '電話番号の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeEmail"
                  component={Account.ChangeEmail}
                  options={{title: 'パスワードの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePassword"
                  component={Account.ChangePassword}
                  options={{title: 'メールアドレスの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeName"
                  component={Account.ChangeName}
                  options={{title: '名前の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeSendEmail"
                  component={Account.ChangeSendEmail}
                  options={{title: 'メール送信の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="Error"
                  component={Error}
                  options={{title: 'エラー'}}
                />
              </>
            ) : step === 'registerDone' ? (
              <>
                <Stack.Screen
                  name="RegisterDone"
                  component={RegisterDone}
                  options={{title: '企業情報登録完了'}}
                />
                <Stack.Screen
                  name="Invite"
                  component={Invite}
                  options={{title: 'Invite'}}
                />
                <Stack.Screen
                  name="AccountMain"
                  component={Account.Main}
                  options={{title: 'アカウント設定'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePhoneNumber"
                  component={Account.ChangePhoneNumber}
                  options={{title: '電話番号の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeEmail"
                  component={Account.ChangeEmail}
                  options={{title: 'パスワードの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePassword"
                  component={Account.ChangePassword}
                  options={{title: 'メールアドレスの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeName"
                  component={Account.ChangeName}
                  options={{title: '名前の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeSendEmail"
                  component={Account.ChangeSendEmail}
                  options={{title: 'メール送信の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="Error"
                  component={Error}
                  options={{title: 'エラー'}}
                />
              </>
            ) : step === 'unregisteredShop' ? (
              <>
                <Stack.Screen
                  name="ShopRegister"
                  component={ShopRegister}
                  options={{title: '店舗情報の登録'}}
                />
                <Stack.Screen
                  name="Invite"
                  component={Invite}
                  options={{title: 'Invite'}}
                />
                <Stack.Screen
                  name="AccountMain"
                  component={Account.Main}
                  options={{title: 'アカウント設定'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePhoneNumber"
                  component={Account.ChangePhoneNumber}
                  options={{title: '電話番号の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeEmail"
                  component={Account.ChangeEmail}
                  options={{title: 'パスワードの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePassword"
                  component={Account.ChangePassword}
                  options={{title: 'メールアドレスの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeName"
                  component={Account.ChangeName}
                  options={{title: '名前の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeSendEmail"
                  component={Account.ChangeSendEmail}
                  options={{title: 'メール送信の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="Error"
                  component={Error}
                  options={{title: 'エラー'}}
                />
              </>
            ) : (
              <>
                <Stack.Screen name="Main" component={MainScreen} />
                <Stack.Screen
                  name="Invite"
                  component={Invite}
                  options={{title: 'Invite'}}
                />
                <Stack.Screen
                  name="AccountMain"
                  component={Account.Main}
                  options={{title: 'アカウント設定'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePhoneNumber"
                  component={Account.ChangePhoneNumber}
                  options={{title: '電話番号の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeEmail"
                  component={Account.ChangeEmail}
                  options={{title: 'パスワードの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangePassword"
                  component={Account.ChangePassword}
                  options={{title: 'メールアドレスの変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeName"
                  component={Account.ChangeName}
                  options={{title: '名前の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="AccountChangeSendEmail"
                  component={Account.ChangeSendEmail}
                  options={{title: 'メール送信の変更'}}
                  initialParams={{header: true}}
                />
                <Stack.Screen
                  name="Error"
                  component={Error}
                  options={{title: 'エラー'}}
                />
              </>
            )}
          </Stack.Navigator>
        </NavigationContainer>
      )}
      <OwnerSelector />
    </ContainerFull>
  );
};

export const Navigation: React.FC = () => {
  // メンテナンスページを出すときはコメントアウト
  // return <Maintance />;
  return (
    <ShopGroupsContainer.Provider>
      <AdminContainer.Provider>
        <OwnerContainer.Provider>
          <PaymentInfoContainer.Provider>
            <ShopsContainer.Provider>
              <CouponsContainer.Provider>
                <NewsContainer.Provider>
                  <TargetsContainer.Provider>
                    <AccessRoleContainer.Provider>
                      <StepNavigationContainer.Provider>
                        <AuthContainer.Provider>
                          <UserInfoContainer.Provider>
                            <AccessManageContainer.Provider>
                              <OwnerSelectContainer.Provider>
                                <StripeContainer.Provider>
                                  <ZendeskContainer.Provider>
                                    <SignInContainer.Provider>
                                      <PrefectureContainer.Provider>
                                        <StackScreens />
                                      </PrefectureContainer.Provider>
                                    </SignInContainer.Provider>
                                  </ZendeskContainer.Provider>
                                </StripeContainer.Provider>
                              </OwnerSelectContainer.Provider>
                            </AccessManageContainer.Provider>
                          </UserInfoContainer.Provider>
                        </AuthContainer.Provider>
                      </StepNavigationContainer.Provider>
                    </AccessRoleContainer.Provider>
                  </TargetsContainer.Provider>
                </NewsContainer.Provider>
              </CouponsContainer.Provider>
            </ShopsContainer.Provider>
          </PaymentInfoContainer.Provider>
        </OwnerContainer.Provider>
      </AdminContainer.Provider>
    </ShopGroupsContainer.Provider>
  );
};
