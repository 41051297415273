import {faBars, faClose} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconButton} from '@mui/material';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerNavigationProp,
} from '@react-navigation/drawer';
import {
  DrawerHeaderOptions,
  DrawerNavigationHelpers,
} from '@react-navigation/drawer/lib/typescript/src/types';
import {
  CommonActions,
  DrawerActions,
  useLinkBuilder,
} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useTheme} from 'react-native-paper';
import {Media, useHeight} from '../components';
import {Caption, MCIcon} from '../components/Elements';
import {
  ShopContainer,
  ShopGroupsContainer,
  StepNavigationContainer,
} from '../container';
import {Colors, CommonStyles, Fonts} from '../theme';
import OwnerSelectButton from './OwnerSelectButton';
import {ShopGroupSelector} from './ShopGroupSelector';
import {ShopSelector} from './ShopSelector';

// for Header
export function showToggle(
  navigation: DrawerNavigationProp<never>,
): DrawerHeaderOptions {
  return {
    headerLeft: () => (
      <IconButton
        sx={{height: 45, width: 45}}
        onClick={() => {
          navigation.toggleDrawer();
        }}>
        <FontAwesomeIcon icon={faBars} color="white" fontSize={30} />
      </IconButton>
    ),
  };
}

type IsSeparator = {
  separator?: boolean;
};

type Leaf<T> = {
  screens: T[];
} & IsSeparator;
type Node<T> = {
  label: string;
  children: Node<T>[] | Leaf<T>;
} & IsSeparator;
export type DrawerTree<T> = {[P in string]: Leaf<T> | Node<T>[]};

const DisabledItem: React.FC<{label: string}> = ({label}) => {
  const {fonts} = useTheme();
  return (
    <DrawerItem
      label={label}
      onPress={() => false}
      labelStyle={[fonts.medium, Fonts.base, {color: Colors.darkgray}]}
      style={styles.item}
    />
  );
};

function Leaf<T extends string>(
  props: DrawerContentComponentProps & Leaf<T>,
): React.ReactElement {
  const buildLink = useLinkBuilder();
  const {colors, fonts} = useTheme();
  const {screens, descriptors, navigation, state} = props;
  const {selected} = ShopContainer.useContainer();

  return (
    <View>
      {screens.map((screen) => {
        let focused = false;
        const route = props.state.routes.find((value, index) => {
          const match = value.name === screen;
          focused = match && state.index === index;
          return match;
        });
        if (!route) {
          return (
            <DisabledItem
              label={screen + '（未）'}
              key={'disabled_' + screen}
            />
          );
        }
        const {title, drawerLabel} = descriptors[route.key].options;
        if (route.name === 'ReportsCustomers' && selected?.legalUrl) {
          return (
            <DisabledItem
              label={title !== undefined ? title : route.name}
              key={'disabled_' + screen}
            />
          );
        }

        return (
          <DrawerItem
            key={route.key}
            label={
              drawerLabel !== undefined
                ? drawerLabel
                : title !== undefined
                ? title
                : route.name
            }
            // icon={drawerIcon}
            focused={focused}
            activeTintColor={Colors.white}
            inactiveTintColor={Colors.lightgray}
            activeBackgroundColor={colors.primary}
            // inactiveBackgroundColor={inactiveBackgroundColor}
            labelStyle={[fonts.medium, Fonts.base, {paddingLeft: 8}]}
            style={styles.item}
            to={buildLink(route.name, route.params)}
            onPress={() => {
              navigation.dispatch({
                ...(focused
                  ? DrawerActions.closeDrawer()
                  : // ドロワーから遷移する場合はfromをクリア
                    CommonActions.navigate(route.name, {from: undefined})),
                target: state.key,
              });
            }}
          />
        );
      })}
    </View>
  );
}

const Separator: React.FC<{enable?: boolean; children: React.ReactNode}> = ({
  enable,
  children,
}) => {
  const style = enable
    ? {borderTopWidth: 1, borderColor: '#707070', marginLeft: -100}
    : undefined;
  return (
    <View>
      {children}
      <View style={style} />
    </View>
  );
};

const Label: React.FC<{label: string}> = ({label}) => {
  // アイコン付き特殊対応
  const iconName =
    label === '広告'
      ? 'newspaper-variant'
      : label === '配信先'
      ? 'target'
      : label === '配信'
      ? 'send'
      : label === 'レポート'
      ? 'chart-areaspline-variant'
      : '';
  if (iconName) {
    return (
      <View style={styles.iconLabel}>
        <MCIcon name={iconName} size={16} color={Colors.darkgray} />
        <Caption style={styles.label}>{' ' + label}</Caption>
      </View>
    );
  }
  // 通常
  return <Caption style={styles.label}>{label}</Caption>;
};

function ListInner<T extends string>(
  props: DrawerContentComponentProps & {node: Node<T>[] | Leaf<T>},
): React.ReactElement {
  const {node, ...rest} = props;
  if ('screens' in node) {
    return (
      <Separator enable={node.separator}>
        <Leaf {...node} {...rest} />
      </Separator>
    );
  }
  return (
    <View>
      {node.map((value) => {
        return (
          <Separator enable={value.separator} key={'group_' + value.label}>
            <View>
              <Label label={value.label} />
              <ListInner node={value.children} {...rest} />
            </View>
          </Separator>
        );
      })}
    </View>
  );
}

const DrawerHeader: React.FC = () => {
  return (
    <Media desktop>
      <View style={styles.drawerHead} />
    </Media>
  );
};

const CloseDrawer: React.FC<{
  navigation: DrawerNavigationHelpers;
}> = ({navigation}) => {
  return (
    <View>
      <Media tablet mobile>
        <IconButton
          sx={{height: 45, width: 45}}
          onClick={() => {
            navigation.closeDrawer();
          }}>
          <FontAwesomeIcon icon={faClose} color="white" fontSize={30} />
        </IconButton>
      </Media>
    </View>
  );
};

export function CustomDrawer<T extends string>(
  props: DrawerContentComponentProps & {tree: DrawerTree<T>},
): React.ReactElement {
  const {step} = StepNavigationContainer.useContainer();
  const {shopGroups} = ShopGroupsContainer.useContainer();
  const {tree, ...rest} = props;
  const {windowHeight} = useHeight();
  const style = {maxHeight: windowHeight};
  return (
    <View style={style}>
      <DrawerHeader />
      <CloseDrawer navigation={rest.navigation} />
      <OwnerSelectButton />
      {step !== 'suspended' && shopGroups.length > 0 && (
        <ShopGroupSelector navigation={rest.navigation} />
      )}
      {step !== 'suspended' && <ShopSelector navigation={rest.navigation} />}
      <DrawerContentScrollView {...rest}>
        {Object.values(tree).map((value, index) => {
          return <ListInner {...rest} node={value} key={'group_' + index} />;
        })}
      </DrawerContentScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  iconLabel: {
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.crossCenter,
    height: 28,
    paddingLeft: 8,
  },
  label: {
    color: Colors.darkgray,
  },
  dummy: {
    color: Colors.main,
    marginLeft: 20, // DrawItem だとこのサイズ右にずれる模様
  },
  item: {
    justifyContent: 'center',
    height: 54,
    marginHorizontal: 0,
    marginVertical: 0,
  },
  drawerHead: {
    height: CommonStyles.headerBaseHeight,
    width: 375,
    backgroundColor: Colors.header,
    justifyContent: 'center',
    paddingLeft: 16,
  },
});
