import {AppConfig} from '../config/AppConfig';
import {LngLat} from './ZipAddressRepository';

const TAG = 'GoogleApiRepository';
console.log(TAG);

export type GooglePlace = {
  formattedAddress: string;
  location: LngLat;
  placeId: string;
  types: string[];
};

export type SuggestOptionDto = {
  searchKey: string;
  token: string;
  session?: string;
};

export type DetailOptionDto = {
  id: string;
  token: string;
  session: string;
};

export type FetchSuggestConfig = {
  url: string;
  init: RequestInit;
};

export class GoogleApiRepository {
  static readonly KEY = 'AIzaSyBhjJW688p2loMFfKuU2pBkbvoEk52dP_U';

  static buildFetchSuggestConfig(option: SuggestOptionDto) {
    const url = option.session
      ? `${AppConfig.SaaSBackend}${AppConfig.GoogleAddrSuggestEndPoint}/${option.searchKey}/${option.session}`
      : `${AppConfig.SaaSBackend}${AppConfig.GoogleAddrSuggestEndPoint}/${option.searchKey}`;

    return {
      url,
      init: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${option.token}`,
        },
      },
    };
  }

  static buildFetchDetailConfig(option: DetailOptionDto) {
    const url = `${AppConfig.SaaSBackend}${AppConfig.GoogleAddrDetailEndPoint}/${option.id}/${option.session}`;

    return {
      url,
      init: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${option.token}`,
        },
      },
    };
  }
}
