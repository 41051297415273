import React from 'react';
import {AGE_RANGE_LABEL, ShopVisitorResponse} from '../../service';
import {DataArea, Overlay, Text} from '../Elements';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {ActivityIndicator} from 'react-native-paper';
import {View, ViewStyle} from 'react-native';
import {CommonStyles, trimaColor} from '../../theme';
import {Helpers} from '../../helper';
import {useWidth} from '../Responsive';

type CustomerAttributeReport = Pick<
  NonNullable<ShopVisitorResponse['user']>,
  'male' | 'female'
>;

function checkResponse(
  response?: Pick<ShopVisitorResponse, 'user'> | 'loading' | 'error',
): {
  isOverLay: boolean;
  reports: CustomerAttributeReport;
  isEmpty: boolean;
} {
  let isOverLay: boolean;
  const reports: CustomerAttributeReport = {male: [], female: []};
  let isEmpty = true;
  if (
    response === 'loading' ||
    response === 'error' ||
    !response ||
    !response.user
  ) {
    isOverLay = true;
  } else {
    isOverLay = false;
    reports.male = response.user.male ?? [];
    reports.female = response.user.female ?? [];
    isEmpty = reports.male.length === 0 && reports.female.length === 0;
  }
  return {isOverLay, reports, isEmpty};
}

const MAX_GRAPH_WIDTH = 400;
function useGraphStyle(): {graphStyle: ViewStyle} {
  const {contentWidth} = useWidth();
  const enableWidth = contentWidth - CommonStyles.margin.all.margin * 4;
  if (enableWidth > MAX_GRAPH_WIDTH) {
    return {graphStyle: {width: MAX_GRAPH_WIDTH, height: 300}};
  } else {
    return {graphStyle: {width: enableWidth, height: 300}};
  }
}

const AgeGraph: React.FC<{
  response?: Pick<ShopVisitorResponse, 'user'> | 'loading' | 'error';
}> = React.memo(({response}) => {
  const {isEmpty, isOverLay, reports} = checkResponse(response);
  const {graphStyle} = useGraphStyle();
  const data = [...Array(8)].map((_, i) => {
    return i
      ? {label: AGE_RANGE_LABEL[String(i)], count: 0}
      : {label: '', count: 0};
  });
  reports.male.forEach(({range, count}) => (data[range].count += count));
  reports.female.forEach(({range, count}) => (data[range].count += count));
  const sum = data.reduce((p, c) => p + c.count, 0);
  const forBar = data
    .map((d) => ({
      percent: Helpers.percent(d.count, sum, 0),
      ...d,
    }))
    .slice(1);

  return (
    <DataArea>
      <View style={graphStyle}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={forBar}
            margin={{
              top: 5,
              left: 10,
              right: 32,
            }}
            barSize={20}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="label" fontSize={10} />
            <YAxis unit="%" fontSize={10} />
            <Tooltip />
            <Bar
              dataKey="percent"
              fill={trimaColor.main}
              name="割合"
              unit="%"
            />
          </BarChart>
        </ResponsiveContainer>
      </View>
      {(isOverLay || isEmpty) && (
        <Overlay>
          {response === 'loading' ? (
            <ActivityIndicator size={40} />
          ) : response === 'error' ? (
            <Text>データの取得に失敗しました</Text>
          ) : (
            <Text>データがありません。</Text>
          )}
        </Overlay>
      )}
    </DataArea>
  );
});

const RADIAN = Math.PI / 180;
const renderCustomizedLabel: React.ComponentProps<typeof Pie>['label'] = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const COLORS = ['#3AB0FF', '#FF3A95'];

const GenderCircle: React.FC<{
  response?: Pick<ShopVisitorResponse, 'user'> | 'loading' | 'error';
}> = React.memo(({response}) => {
  const {isEmpty, isOverLay, reports} = checkResponse(response);
  const {graphStyle} = useGraphStyle();
  const maleSum = reports.male.reduce((p, c) => p + c.count, 0);
  const femaleSum = reports.female.reduce((p, c) => p + c.count, 0);
  const data = [
    {name: '男性', value: maleSum},
    {name: '女性', value: femaleSum},
  ];

  return (
    <DataArea>
      <View style={graphStyle}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Legend />
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              startAngle={90}
              endAngle={450}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </View>
      {(isOverLay || isEmpty) && (
        <Overlay>
          {response === 'loading' ? (
            <ActivityIndicator size={40} />
          ) : response === 'error' ? (
            <Text>データの取得に失敗しました</Text>
          ) : (
            <Text>データがありません。</Text>
          )}
        </Overlay>
      )}
    </DataArea>
  );
});

const AgeGenderGraph: React.FC<{
  response?: Pick<ShopVisitorResponse, 'user'> | 'loading' | 'error';
}> = React.memo(({response}) => {
  const {isEmpty, isOverLay, reports} = checkResponse(response);
  // 表示用データに整形
  const maleSum = reports.male.reduce((p, c) => p + c.count, 0);
  const sum = reports.female.reduce((p, c) => p + c.count, maleSum);
  let data = [...Array(8)].map((_, i) => {
    return i
      ? {label: AGE_RANGE_LABEL[String(i)], male: 0, female: 0}
      : {label: '', male: 0, female: 0};
  });
  reports.male.forEach(
    ({range, count}) => (data[range].male -= Helpers.percent(count, sum, 1)),
  );
  reports.female.forEach(
    ({range, count}) => (data[range].female += Helpers.percent(count, sum, 1)),
  );
  data = data.slice(1).reverse(); // 下が若い順になるように。
  return (
    <DataArea>
      <ResponsiveContainer width="100%" height={280}>
        <BarChart
          layout="vertical"
          data={data}
          stackOffset="sign"
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            fontSize={10}
            tickFormatter={(n: number) => Math.abs(n) + '%'}
          />
          <YAxis type="category" dataKey="label" fontSize={10} />
          <Tooltip formatter={(value: number) => Math.abs(value) + '%'} />
          <Legend />
          <Bar dataKey="male" fill={COLORS[0]} stackId="stack" name="男性" />
          <Bar dataKey="female" fill={COLORS[1]} stackId="stack" name="女性" />
        </BarChart>
      </ResponsiveContainer>
      {(isOverLay || isEmpty) && (
        <Overlay>
          {response === 'loading' ? (
            <ActivityIndicator size={40} />
          ) : response === 'error' ? (
            <Text>データの取得に失敗しました</Text>
          ) : (
            <Text>データがありません。</Text>
          )}
        </Overlay>
      )}
    </DataArea>
  );
});

export const CustomerAttribute = {
  AgeGraph,
  GenderCircle,
  AgeGenderGraph,
};
