/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: String) {
    getCoupon(id: $id) {
      id
      ownerId
      shopId
      name
      text
      title
      rule
      discount {
        type
        param
        freeText
      }
      imageUrls
      barcodeUrl
      usage
      editState
      cvLink {
        url
        text
      }
      cvPhone
      archive
      createdAt
      updatedAt
    }
  }
`;
export const listCoupon = /* GraphQL */ `
  query ListCoupon(
    $shopId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listCoupon(
      shopId: $shopId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        shopId
        name
        text
        title
        rule
        discount {
          type
          param
          freeText
        }
        imageUrls
        barcodeUrl
        usage
        editState
        cvLink {
          url
          text
        }
        cvPhone
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: String) {
    getNews(id: $id) {
      id
      ownerId
      shopId
      name
      text
      title
      imageUrls
      editState
      cvLink {
        url
        text
      }
      cvPhone
      archive
      createdAt
      updatedAt
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $shopId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listNews(
      shopId: $shopId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        shopId
        name
        text
        title
        imageUrls
        editState
        cvLink {
          url
          text
        }
        cvPhone
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeliveryTarget = /* GraphQL */ `
  query GetDeliveryTarget($id: String) {
    getDeliveryTarget(id: $id) {
      id
      ownerId
      shopId
      title
      areaOption {
        areaType
        meshs
      }
      stayOption {
        weekDays
        hours
        meshs
      }
      stationOption
      cityOption {
        prefCode
        cityCode
        prefName
        cityName
      }
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      users
      editState
      archive
      createdAt
      updatedAt
    }
  }
`;
export const listDeliveryTarget = /* GraphQL */ `
  query ListDeliveryTarget(
    $shopId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listDeliveryTarget(
      shopId: $shopId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        shopId
        title
        areaOption {
          areaType
          meshs
        }
        stayOption {
          weekDays
          hours
          meshs
        }
        stationOption
        cityOption {
          prefCode
          cityCode
          prefName
          cityName
        }
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        users
        editState
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: String) {
    getShop(id: $id) {
      id
      ownerId
      groupId
      name
      text
      address {
        zip
        pref
        city
        detail
        building
      }
      phone
      biztimes
      holidays
      url
      legalUrl
      category
      imageUrls
      icon
      location {
        lat
        lon
      }
      createdAt
      updatedAt
      shopState
    }
  }
`;
export const listShop = /* GraphQL */ `
  query ListShop(
    $ownerId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listShop(
      ownerId: $ownerId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        groupId
        name
        text
        address {
          zip
          pref
          city
          detail
          building
        }
        phone
        biztimes
        holidays
        url
        legalUrl
        category
        imageUrls
        icon
        location {
          lat
          lon
        }
        createdAt
        updatedAt
        shopState
      }
      nextToken
    }
  }
`;
export const getShopGroup = /* GraphQL */ `
  query GetShopGroup($id: String) {
    getShopGroup(id: $id) {
      id
      ownerId
      name
      createdAt
      updatedAt
    }
  }
`;
export const listShopGroup = /* GraphQL */ `
  query ListShopGroup(
    $ownerId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listShopGroup(
      ownerId: $ownerId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOwner = /* GraphQL */ `
  query GetOwner($id: String) {
    getOwner(id: $id) {
      id
      hojin
      hojinKana
      representative {
        first
        last
      }
      representativeKana {
        first
        last
      }
      address {
        zip
        pref
        city
        detail
        building
      }
      url
      chargeDept
      charge {
        first
        last
      }
      chargeKana {
        first
        last
      }
      chargePhone
      status
      agentCode
      createdAt
      updatedAt
    }
  }
`;
export const getPaymentInfo = /* GraphQL */ `
  query GetPaymentInfo($id: String) {
    getPaymentInfo(id: $id) {
      id
      customerId
      paymentMethodId
      paymentType
      subscriptionId
      subscriptionItemIds
      priceIds
      billing
      invitedCode
      createdAt
      updatedAt
    }
  }
`;
export const listOwner = /* GraphQL */ `
  query ListOwner(
    $id: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listOwner(
      id: $id
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        hojin
        hojinKana
        representative {
          first
          last
        }
        representativeKana {
          first
          last
        }
        address {
          zip
          pref
          city
          detail
          building
        }
        url
        chargeDept
        charge {
          first
          last
        }
        chargeKana {
          first
          last
        }
        chargePhone
        status
        agentCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDeliveryHistory = /* GraphQL */ `
  query GetDeliveryHistory($id: String) {
    getDeliveryHistory(id: $id) {
      id
      ownerId
      shopId
      couponId
      couponDef
      targetId
      targetDef
      title
      budget
      balance
      couponState
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      startAt
      endAt
      deliveryArea
      createdAt
      updatedAt
    }
  }
`;
export const listDeliveryHistory = /* GraphQL */ `
  query ListDeliveryHistory(
    $shopId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listDeliveryHistory(
      shopId: $shopId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        shopId
        couponId
        couponDef
        targetId
        targetDef
        title
        budget
        balance
        couponState
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        startAt
        endAt
        deliveryArea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNewsHistory = /* GraphQL */ `
  query GetNewsHistory($id: String) {
    getNewsHistory(id: $id) {
      id
      ownerId
      shopId
      newsId
      newsDef
      targetId
      targetDef
      title
      budget
      balance
      newsState
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      startAt
      endAt
      deliveryArea
      createdAt
      updatedAt
    }
  }
`;
export const listNewsHistory = /* GraphQL */ `
  query ListNewsHistory(
    $shopId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listNewsHistory(
      shopId: $shopId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        shopId
        newsId
        newsDef
        targetId
        targetDef
        title
        budget
        balance
        newsState
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        startAt
        endAt
        deliveryArea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAccessManage = /* GraphQL */ `
  query ListAccessManage(
    $ownerId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listAccessManage(
      ownerId: $ownerId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        userId
        email
        addUserId
        role
        status
        familyName
        givenName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAccessManageByUser = /* GraphQL */ `
  query ListAccessManageByUser(
    $userId: String
    $limit: Int
    $nextToken: String
    $sortDirection: String
  ) {
    listAccessManageByUser(
      userId: $userId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        userId
        email
        addUserId
        role
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccessManage = /* GraphQL */ `
  query GetAccessManage($id: String) {
    getAccessManage(id: $id) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      familyName
      givenName
      createdAt
      updatedAt
    }
  }
`;
export const getSaaSOwner = /* GraphQL */ `
  query GetSaaSOwner($id: ID!) {
    getSaaSOwner(id: $id) {
      id
      hojin
      hojinKana
      representative {
        first
        last
      }
      representativeKana {
        first
        last
      }
      address {
        zip
        pref
        city
        detail
        building
      }
      url
      chargeDept
      charge {
        first
        last
      }
      chargeKana {
        first
        last
      }
      chargePhone
      status
      agentCode
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSOwners = /* GraphQL */ `
  query ListSaaSOwners(
    $filter: ModelSaaSOwnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSOwners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hojin
        hojinKana
        representative {
          first
          last
        }
        representativeKana {
          first
          last
        }
        address {
          zip
          pref
          city
          detail
          building
        }
        url
        chargeDept
        charge {
          first
          last
        }
        chargeKana {
          first
          last
        }
        chargePhone
        status
        agentCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSArea = /* GraphQL */ `
  query GetSaaSArea($id: ID!) {
    getSaaSArea(id: $id) {
      id
      meshId
      userId
      areaType
      stamp
      createdAt
      updatedAt
      unixStamp
    }
  }
`;
export const listSaaSAreas = /* GraphQL */ `
  query ListSaaSAreas(
    $filter: ModelSaaSAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSAreas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        meshId
        userId
        areaType
        stamp
        createdAt
        updatedAt
        unixStamp
      }
      nextToken
    }
  }
`;
export const saaSAreaByMeshId = /* GraphQL */ `
  query SaaSAreaByMeshId(
    $meshId: String!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSAreaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSAreaByMeshId(
      meshId: $meshId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meshId
        userId
        areaType
        stamp
        createdAt
        updatedAt
        unixStamp
      }
      nextToken
    }
  }
`;
export const getSaaSStay = /* GraphQL */ `
  query GetSaaSStay($id: ID!) {
    getSaaSStay(id: $id) {
      id
      meshId
      userId
      stayOffsets
      stamp
      createdAt
      updatedAt
      unixStamp
    }
  }
`;
export const listSaaSStays = /* GraphQL */ `
  query ListSaaSStays(
    $filter: ModelSaaSStayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSStays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        meshId
        userId
        stayOffsets
        stamp
        createdAt
        updatedAt
        unixStamp
      }
      nextToken
    }
  }
`;
export const saaSStayByMeshId = /* GraphQL */ `
  query SaaSStayByMeshId(
    $meshId: String!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSStayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSStayByMeshId(
      meshId: $meshId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        meshId
        userId
        stayOffsets
        stamp
        createdAt
        updatedAt
        unixStamp
      }
      nextToken
    }
  }
`;
export const getSaaSStationPoi = /* GraphQL */ `
  query GetSaaSStationPoi($id: ID!) {
    getSaaSStationPoi(id: $id) {
      id
      routeId
      poiType
      parent
      seq
      name
      parentName
      innerId
      yomi
      mimi
      unixStamp
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSStationPois = /* GraphQL */ `
  query ListSaaSStationPois(
    $filter: ModelSaaSStationPoiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSStationPois(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        routeId
        poiType
        parent
        seq
        name
        parentName
        innerId
        yomi
        mimi
        unixStamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSStationPoiByPoiType = /* GraphQL */ `
  query SaaSStationPoiByPoiType(
    $poiType: PoiType!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSStationPoiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSStationPoiByPoiType(
      poiType: $poiType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        routeId
        poiType
        parent
        seq
        name
        parentName
        innerId
        yomi
        mimi
        unixStamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSStationPoiByParent = /* GraphQL */ `
  query SaaSStationPoiByParent(
    $parent: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSStationPoiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSStationPoiByParent(
      parent: $parent
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        routeId
        poiType
        parent
        seq
        name
        parentName
        innerId
        yomi
        mimi
        unixStamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSStationPoiByName = /* GraphQL */ `
  query SaaSStationPoiByName(
    $name: String!
    $parentName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSStationPoiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSStationPoiByName(
      name: $name
      parentName: $parentName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        routeId
        poiType
        parent
        seq
        name
        parentName
        innerId
        yomi
        mimi
        unixStamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSStation = /* GraphQL */ `
  query GetSaaSStation($id: ID!) {
    getSaaSStation(id: $id) {
      id
      userId
      routeId
      stationId
      masterId
      innerPoiId
      entryDays
      stamp
      createdAt
      updatedAt
      unixStamp
    }
  }
`;
export const listSaaSStations = /* GraphQL */ `
  query ListSaaSStations(
    $filter: ModelSaaSStationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSStations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        routeId
        stationId
        masterId
        innerPoiId
        entryDays
        stamp
        createdAt
        updatedAt
        unixStamp
      }
      nextToken
    }
  }
`;
export const saaSStationByRouteId = /* GraphQL */ `
  query SaaSStationByRouteId(
    $routeId: String!
    $stationId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSStationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSStationByRouteId(
      routeId: $routeId
      stationId: $stationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        routeId
        stationId
        masterId
        innerPoiId
        entryDays
        stamp
        createdAt
        updatedAt
        unixStamp
      }
      nextToken
    }
  }
`;
export const getSaaSVisitor = /* GraphQL */ `
  query GetSaaSVisitor($id: ID!) {
    getSaaSVisitor(id: $id) {
      id
      userId
      shopId
      deliveryType
      deliveryId
      stamp
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSVisitors = /* GraphQL */ `
  query ListSaaSVisitors(
    $filter: ModelSaaSVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSVisitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        shopId
        deliveryType
        deliveryId
        stamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSVisitorByShopId = /* GraphQL */ `
  query SaaSVisitorByShopId(
    $shopId: String!
    $stamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSVisitorByShopId(
      shopId: $shopId
      stamp: $stamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        shopId
        deliveryType
        deliveryId
        stamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSVisitorByDeliveryId = /* GraphQL */ `
  query SaaSVisitorByDeliveryId(
    $deliveryId: ID!
    $stamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSVisitorByDeliveryId(
      deliveryId: $deliveryId
      stamp: $stamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        shopId
        deliveryType
        deliveryId
        stamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSCityPoi = /* GraphQL */ `
  query GetSaaSCityPoi($id: ID!) {
    getSaaSCityPoi(id: $id) {
      id
      prefCode
      cityName
      prefName
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSCityPois = /* GraphQL */ `
  query ListSaaSCityPois(
    $filter: ModelSaaSCityPoiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSCityPois(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        prefCode
        cityName
        prefName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCityPoiByPrefCode = /* GraphQL */ `
  query SaaSCityPoiByPrefCode(
    $prefCode: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCityPoiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCityPoiByPrefCode(
      prefCode: $prefCode
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        prefCode
        cityName
        prefName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSCityUserCount = /* GraphQL */ `
  query GetSaaSCityUserCount($id: ID!) {
    getSaaSCityUserCount(id: $id) {
      id
      cityCode
      prefCode
      userCount
      createdAt
      updatedAt
      stamp
      unixStamp
    }
  }
`;
export const listSaaSCityUserCounts = /* GraphQL */ `
  query ListSaaSCityUserCounts(
    $filter: ModelSaaSCityUserCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSCityUserCounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cityCode
        prefCode
        userCount
        createdAt
        updatedAt
        stamp
        unixStamp
      }
      nextToken
    }
  }
`;
export const saaSCityUserCountByCityCode = /* GraphQL */ `
  query SaaSCityUserCountByCityCode(
    $cityCode: String!
    $stamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCityUserCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCityUserCountByCityCode(
      cityCode: $cityCode
      stamp: $stamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cityCode
        prefCode
        userCount
        createdAt
        updatedAt
        stamp
        unixStamp
      }
      nextToken
    }
  }
`;
export const saaSCityUserCountByPrefCode = /* GraphQL */ `
  query SaaSCityUserCountByPrefCode(
    $prefCode: String!
    $cityCode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCityUserCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCityUserCountByPrefCode(
      prefCode: $prefCode
      cityCode: $cityCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cityCode
        prefCode
        userCount
        createdAt
        updatedAt
        stamp
        unixStamp
      }
      nextToken
    }
  }
`;
export const getSaaSUser = /* GraphQL */ `
  query GetSaaSUser($id: ID!) {
    getSaaSUser(id: $id) {
      id
      dotmoneyId
      job
      gender
      age
      married
      residence
      movingMethod
      childrenTogether
      childYoungest
      income
      householdIncome
      familyTogether
      address
      cityCode
      prefCode
      interest
      childAge
      stamp
      createdAt
      updatedAt
      unixStamp
    }
  }
`;
export const listSaaSUsers = /* GraphQL */ `
  query ListSaaSUsers(
    $filter: ModelSaaSUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dotmoneyId
        job
        gender
        age
        married
        residence
        movingMethod
        childrenTogether
        childYoungest
        income
        householdIncome
        familyTogether
        address
        cityCode
        prefCode
        interest
        childAge
        stamp
        createdAt
        updatedAt
        unixStamp
      }
      nextToken
    }
  }
`;
export const searchSaaSUsers = /* GraphQL */ `
  query SearchSaaSUsers(
    $filter: SearchableSaaSUserFilterInput
    $sort: [SearchableSaaSUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSaaSUserAggregationInput]
  ) {
    searchSaaSUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        dotmoneyId
        job
        gender
        age
        married
        residence
        movingMethod
        childrenTogether
        childYoungest
        income
        householdIncome
        familyTogether
        address
        cityCode
        prefCode
        interest
        childAge
        stamp
        createdAt
        updatedAt
        unixStamp
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getSaaSSocial = /* GraphQL */ `
  query GetSaaSSocial($id: ID!) {
    getSaaSSocial(id: $id) {
      id
      shopId
      userId
      userHashId
      followed
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSSocials = /* GraphQL */ `
  query ListSaaSSocials(
    $filter: ModelSaaSSocialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSSocials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        userId
        userHashId
        followed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSSocialByShopId = /* GraphQL */ `
  query SaaSSocialByShopId(
    $shopId: ID!
    $userHashId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSSocialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSSocialByShopId(
      shopId: $shopId
      userHashId: $userHashId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        userId
        userHashId
        followed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSSocialByUserId = /* GraphQL */ `
  query SaaSSocialByUserId(
    $userId: ID!
    $shopId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSSocialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSSocialByUserId(
      userId: $userId
      shopId: $shopId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        userId
        userHashId
        followed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSSocialLog = /* GraphQL */ `
  query GetSaaSSocialLog($id: ID!) {
    getSaaSSocialLog(id: $id) {
      id
      shopId
      userId
      stamp
      action
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSSocialLogs = /* GraphQL */ `
  query ListSaaSSocialLogs(
    $filter: ModelSaaSSocialLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSSocialLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        userId
        stamp
        action
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSSocialLogByShopId = /* GraphQL */ `
  query SaaSSocialLogByShopId(
    $shopId: ID!
    $stamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSSocialLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSSocialLogByShopId(
      shopId: $shopId
      stamp: $stamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        userId
        stamp
        action
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSShop = /* GraphQL */ `
  query GetSaaSShop($id: ID!) {
    getSaaSShop(id: $id) {
      id
      ownerId
      groupId
      name
      text
      address {
        zip
        pref
        city
        detail
        building
      }
      phone
      biztimes
      holidays
      url
      legalUrl
      category
      imageUrls
      icon
      location {
        lat
        lon
      }
      createdAt
      updatedAt
      shopState
    }
  }
`;
export const listSaaSShops = /* GraphQL */ `
  query ListSaaSShops(
    $filter: ModelSaaSShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        groupId
        name
        text
        address {
          zip
          pref
          city
          detail
          building
        }
        phone
        biztimes
        holidays
        url
        legalUrl
        category
        imageUrls
        icon
        location {
          lat
          lon
        }
        createdAt
        updatedAt
        shopState
      }
      nextToken
    }
  }
`;
export const saaSShopByOwnerId = /* GraphQL */ `
  query SaaSShopByOwnerId(
    $ownerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSShopByOwnerId(
      ownerId: $ownerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        groupId
        name
        text
        address {
          zip
          pref
          city
          detail
          building
        }
        phone
        biztimes
        holidays
        url
        legalUrl
        category
        imageUrls
        icon
        location {
          lat
          lon
        }
        createdAt
        updatedAt
        shopState
      }
      nextToken
    }
  }
`;
export const saaSShopByGroupId = /* GraphQL */ `
  query SaaSShopByGroupId(
    $groupId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSShopByGroupId(
      groupId: $groupId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        groupId
        name
        text
        address {
          zip
          pref
          city
          detail
          building
        }
        phone
        biztimes
        holidays
        url
        legalUrl
        category
        imageUrls
        icon
        location {
          lat
          lon
        }
        createdAt
        updatedAt
        shopState
      }
      nextToken
    }
  }
`;
export const getSaaSShopGroup = /* GraphQL */ `
  query GetSaaSShopGroup($id: ID!) {
    getSaaSShopGroup(id: $id) {
      id
      ownerId
      name
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSShopGroups = /* GraphQL */ `
  query ListSaaSShopGroups(
    $filter: ModelSaaSShopGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSShopGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSShopGroupByOwnerId = /* GraphQL */ `
  query SaaSShopGroupByOwnerId(
    $ownerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSShopGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSShopGroupByOwnerId(
      ownerId: $ownerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSShopExcludeVisitor = /* GraphQL */ `
  query GetSaaSShopExcludeVisitor($id: ID!) {
    getSaaSShopExcludeVisitor(id: $id) {
      id
      shopId
      ownerId
      invitedCode
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSShopExcludeVisitors = /* GraphQL */ `
  query ListSaaSShopExcludeVisitors(
    $filter: ModelSaaSShopExcludeVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSShopExcludeVisitors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        ownerId
        invitedCode
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSShopExcludeVisitorByShopId = /* GraphQL */ `
  query SaaSShopExcludeVisitorByShopId(
    $shopId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSShopExcludeVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSShopExcludeVisitorByShopId(
      shopId: $shopId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        ownerId
        invitedCode
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSPaymentInfo = /* GraphQL */ `
  query GetSaaSPaymentInfo($id: ID!) {
    getSaaSPaymentInfo(id: $id) {
      id
      customerId
      paymentMethodId
      paymentType
      subscriptionId
      subscriptionItemIds
      priceIds
      billing
      invitedCode
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSPaymentInfos = /* GraphQL */ `
  query ListSaaSPaymentInfos(
    $filter: ModelSaaSPaymentInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSPaymentInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        paymentMethodId
        paymentType
        subscriptionId
        subscriptionItemIds
        priceIds
        billing
        invitedCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSPaymentInfoByCustomerId = /* GraphQL */ `
  query SaaSPaymentInfoByCustomerId(
    $customerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSPaymentInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSPaymentInfoByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerId
        paymentMethodId
        paymentType
        subscriptionId
        subscriptionItemIds
        priceIds
        billing
        invitedCode
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSCoupon = /* GraphQL */ `
  query GetSaaSCoupon($id: ID!) {
    getSaaSCoupon(id: $id) {
      id
      ownerId
      shopId
      name
      text
      title
      rule
      discount {
        type
        param
        freeText
      }
      imageUrls
      barcodeUrl
      usage
      editState
      cvLink {
        url
        text
      }
      cvPhone
      archive
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSCoupons = /* GraphQL */ `
  query ListSaaSCoupons(
    $filter: ModelSaaSCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        shopId
        name
        text
        title
        rule
        discount {
          type
          param
          freeText
        }
        imageUrls
        barcodeUrl
        usage
        editState
        cvLink {
          url
          text
        }
        cvPhone
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponByOwnerId = /* GraphQL */ `
  query SaaSCouponByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        name
        text
        title
        rule
        discount {
          type
          param
          freeText
        }
        imageUrls
        barcodeUrl
        usage
        editState
        cvLink {
          url
          text
        }
        cvPhone
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponByShopId = /* GraphQL */ `
  query SaaSCouponByShopId(
    $shopId: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponByShopId(
      shopId: $shopId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        name
        text
        title
        rule
        discount {
          type
          param
          freeText
        }
        imageUrls
        barcodeUrl
        usage
        editState
        cvLink {
          url
          text
        }
        cvPhone
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNews = /* GraphQL */ `
  query GetSaaSNews($id: ID!) {
    getSaaSNews(id: $id) {
      id
      ownerId
      shopId
      name
      text
      title
      imageUrls
      editState
      cvLink {
        url
        text
      }
      cvPhone
      archive
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNews = /* GraphQL */ `
  query ListSaaSNews(
    $filter: ModelSaaSNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        shopId
        name
        text
        title
        imageUrls
        editState
        cvLink {
          url
          text
        }
        cvPhone
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsByOwnerId = /* GraphQL */ `
  query SaaSNewsByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        name
        text
        title
        imageUrls
        editState
        cvLink {
          url
          text
        }
        cvPhone
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsByShopId = /* GraphQL */ `
  query SaaSNewsByShopId(
    $shopId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsByShopId(
      shopId: $shopId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        name
        text
        title
        imageUrls
        editState
        cvLink {
          url
          text
        }
        cvPhone
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSDeliveryTarget = /* GraphQL */ `
  query GetSaaSDeliveryTarget($id: ID!) {
    getSaaSDeliveryTarget(id: $id) {
      id
      ownerId
      shopId
      title
      areaOption {
        areaType
        meshs
      }
      stayOption {
        weekDays
        hours
        meshs
      }
      stationOption
      cityOption {
        prefCode
        cityCode
        prefName
        cityName
      }
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      users
      editState
      archive
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSDeliveryTargets = /* GraphQL */ `
  query ListSaaSDeliveryTargets(
    $filter: ModelSaaSDeliveryTargetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSDeliveryTargets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        title
        areaOption {
          areaType
          meshs
        }
        stayOption {
          weekDays
          hours
          meshs
        }
        stationOption
        cityOption {
          prefCode
          cityCode
          prefName
          cityName
        }
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        users
        editState
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSDeliveryTargetByShopId = /* GraphQL */ `
  query SaaSDeliveryTargetByShopId(
    $shopId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSDeliveryTargetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSDeliveryTargetByShopId(
      shopId: $shopId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        title
        areaOption {
          areaType
          meshs
        }
        stayOption {
          weekDays
          hours
          meshs
        }
        stationOption
        cityOption {
          prefCode
          cityCode
          prefName
          cityName
        }
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        users
        editState
        archive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNewsHistory = /* GraphQL */ `
  query GetSaaSNewsHistory($id: ID!) {
    getSaaSNewsHistory(id: $id) {
      id
      ownerId
      shopId
      newsId
      newsDef
      targetId
      targetDef
      title
      budget
      balance
      newsState
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      startAt
      endAt
      deliveryArea
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNewsHistories = /* GraphQL */ `
  query ListSaaSNewsHistories(
    $filter: ModelSaaSNewsHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNewsHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        newsId
        newsDef
        targetId
        targetDef
        title
        budget
        balance
        newsState
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        startAt
        endAt
        deliveryArea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsHistoryByShopId = /* GraphQL */ `
  query SaaSNewsHistoryByShopId(
    $shopId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsHistoryByShopId(
      shopId: $shopId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        newsId
        newsDef
        targetId
        targetDef
        title
        budget
        balance
        newsState
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        startAt
        endAt
        deliveryArea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsHistoryByNewsId = /* GraphQL */ `
  query SaaSNewsHistoryByNewsId(
    $newsId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsHistoryByNewsId(
      newsId: $newsId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        newsId
        newsDef
        targetId
        targetDef
        title
        budget
        balance
        newsState
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        startAt
        endAt
        deliveryArea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNewsLog = /* GraphQL */ `
  query GetSaaSNewsLog($id: ID!) {
    getSaaSNewsLog(id: $id) {
      id
      shopId
      newsHistoryId
      stamp
      deliveryAt
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNewsLogs = /* GraphQL */ `
  query ListSaaSNewsLogs(
    $filter: ModelSaaSNewsLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNewsLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopId
        newsHistoryId
        stamp
        deliveryAt
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsLogByShopId = /* GraphQL */ `
  query SaaSNewsLogByShopId(
    $shopId: ID!
    $stamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsLogByShopId(
      shopId: $shopId
      stamp: $stamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        newsHistoryId
        stamp
        deliveryAt
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsLogByNewsHistoryId = /* GraphQL */ `
  query SaaSNewsLogByNewsHistoryId(
    $newsHistoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsLogByNewsHistoryId(
      newsHistoryId: $newsHistoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        newsHistoryId
        stamp
        deliveryAt
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNewsAreaHistory = /* GraphQL */ `
  query GetSaaSNewsAreaHistory($id: ID!) {
    getSaaSNewsAreaHistory(id: $id) {
      id
      deliveryId
      meshId
      startAt
      endAt
      areaOption
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNewsAreaHistories = /* GraphQL */ `
  query ListSaaSNewsAreaHistories(
    $filter: ModelSaaSNewsAreaHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNewsAreaHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        meshId
        startAt
        endAt
        areaOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsAreaHistoryByMeshId = /* GraphQL */ `
  query SaaSNewsAreaHistoryByMeshId(
    $meshId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsAreaHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsAreaHistoryByMeshId(
      meshId: $meshId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        meshId
        startAt
        endAt
        areaOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNewsStayHistory = /* GraphQL */ `
  query GetSaaSNewsStayHistory($id: ID!) {
    getSaaSNewsStayHistory(id: $id) {
      id
      deliveryId
      meshId
      startAt
      endAt
      stayOption
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNewsStayHistories = /* GraphQL */ `
  query ListSaaSNewsStayHistories(
    $filter: ModelSaaSNewsStayHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNewsStayHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        meshId
        startAt
        endAt
        stayOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsStayHistoryByMeshId = /* GraphQL */ `
  query SaaSNewsStayHistoryByMeshId(
    $meshId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsStayHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsStayHistoryByMeshId(
      meshId: $meshId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        meshId
        startAt
        endAt
        stayOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNewsStationHistory = /* GraphQL */ `
  query GetSaaSNewsStationHistory($id: ID!) {
    getSaaSNewsStationHistory(id: $id) {
      id
      deliveryId
      stationId
      startAt
      endAt
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNewsStationHistories = /* GraphQL */ `
  query ListSaaSNewsStationHistories(
    $filter: ModelSaaSNewsStationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNewsStationHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        stationId
        startAt
        endAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsStationHistoryByStationId = /* GraphQL */ `
  query SaaSNewsStationHistoryByStationId(
    $stationId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsStationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsStationHistoryByStationId(
      stationId: $stationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        stationId
        startAt
        endAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNewsBillingHistory = /* GraphQL */ `
  query GetSaaSNewsBillingHistory($id: ID!) {
    getSaaSNewsBillingHistory(id: $id) {
      id
      userId
      stamp
      deliveryId
      amount
      feedbackState
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNewsBillingHistories = /* GraphQL */ `
  query ListSaaSNewsBillingHistories(
    $filter: ModelSaaSNewsBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNewsBillingHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stamp
        deliveryId
        amount
        feedbackState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsBillingHistoryByUserId = /* GraphQL */ `
  query SaaSNewsBillingHistoryByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsBillingHistoryByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stamp
        deliveryId
        amount
        feedbackState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsBillingHistoryByStamp = /* GraphQL */ `
  query SaaSNewsBillingHistoryByStamp(
    $stamp: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsBillingHistoryByStamp(
      stamp: $stamp
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stamp
        deliveryId
        amount
        feedbackState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsBillingHistoryByDeliveryId = /* GraphQL */ `
  query SaaSNewsBillingHistoryByDeliveryId(
    $deliveryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsBillingHistoryByDeliveryId(
      deliveryId: $deliveryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stamp
        deliveryId
        amount
        feedbackState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNewsFeedbackHistory = /* GraphQL */ `
  query GetSaaSNewsFeedbackHistory($id: ID!) {
    getSaaSNewsFeedbackHistory(id: $id) {
      id
      userId
      deliveryId
      stamp
      feedbackType
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNewsFeedbackHistories = /* GraphQL */ `
  query ListSaaSNewsFeedbackHistories(
    $filter: ModelSaaSNewsFeedbackHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNewsFeedbackHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        deliveryId
        stamp
        feedbackType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNewsFeedbackHistoryByStamp = /* GraphQL */ `
  query SaaSNewsFeedbackHistoryByStamp(
    $stamp: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNewsFeedbackHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNewsFeedbackHistoryByStamp(
      stamp: $stamp
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        deliveryId
        stamp
        feedbackType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSDeliveryHistory = /* GraphQL */ `
  query GetSaaSDeliveryHistory($id: ID!) {
    getSaaSDeliveryHistory(id: $id) {
      id
      ownerId
      shopId
      couponId
      couponDef
      targetId
      targetDef
      title
      budget
      balance
      couponState
      userOption {
        gender
        ageRange {
          lower
          upper
        }
        married
        residence
        movingMethod
        childrenTogether
        childYoungest {
          lower
          upper
        }
        incomeRange {
          lower
          upper
        }
        householdIncomeRange {
          lower
          upper
        }
        familyTogether
        address
        job
        interest
        childAge {
          lower
          upper
        }
        followed
        visited
      }
      startAt
      endAt
      deliveryArea
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSDeliveryHistories = /* GraphQL */ `
  query ListSaaSDeliveryHistories(
    $filter: ModelSaaSDeliveryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSDeliveryHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        couponId
        couponDef
        targetId
        targetDef
        title
        budget
        balance
        couponState
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        startAt
        endAt
        deliveryArea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSDeliveryHistoryByShopId = /* GraphQL */ `
  query SaaSDeliveryHistoryByShopId(
    $shopId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSDeliveryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSDeliveryHistoryByShopId(
      shopId: $shopId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        couponId
        couponDef
        targetId
        targetDef
        title
        budget
        balance
        couponState
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        startAt
        endAt
        deliveryArea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSDeliveryHistoryByCouponId = /* GraphQL */ `
  query SaaSDeliveryHistoryByCouponId(
    $couponId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSDeliveryHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSDeliveryHistoryByCouponId(
      couponId: $couponId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        shopId
        couponId
        couponDef
        targetId
        targetDef
        title
        budget
        balance
        couponState
        userOption {
          gender
          ageRange {
            lower
            upper
          }
          married
          residence
          movingMethod
          childrenTogether
          childYoungest {
            lower
            upper
          }
          incomeRange {
            lower
            upper
          }
          householdIncomeRange {
            lower
            upper
          }
          familyTogether
          address
          job
          interest
          childAge {
            lower
            upper
          }
          followed
          visited
        }
        startAt
        endAt
        deliveryArea
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSDeliveredLog = /* GraphQL */ `
  query GetSaaSDeliveredLog($id: ID!) {
    getSaaSDeliveredLog(id: $id) {
      id
      shopId
      deliveryHistoryId
      stamp
      deliveryAt
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSDeliveredLogs = /* GraphQL */ `
  query ListSaaSDeliveredLogs(
    $filter: ModelSaaSDeliveredLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSDeliveredLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        deliveryHistoryId
        stamp
        deliveryAt
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSDeliveredLogByShopId = /* GraphQL */ `
  query SaaSDeliveredLogByShopId(
    $shopId: ID!
    $stamp: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSDeliveredLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSDeliveredLogByShopId(
      shopId: $shopId
      stamp: $stamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        deliveryHistoryId
        stamp
        deliveryAt
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSDeliveredLogByDeliveryHistoryId = /* GraphQL */ `
  query SaaSDeliveredLogByDeliveryHistoryId(
    $deliveryHistoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSDeliveredLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSDeliveredLogByDeliveryHistoryId(
      deliveryHistoryId: $deliveryHistoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopId
        deliveryHistoryId
        stamp
        deliveryAt
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSCouponAreaHistory = /* GraphQL */ `
  query GetSaaSCouponAreaHistory($id: ID!) {
    getSaaSCouponAreaHistory(id: $id) {
      id
      deliveryId
      meshId
      startAt
      endAt
      areaOption
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSCouponAreaHistories = /* GraphQL */ `
  query ListSaaSCouponAreaHistories(
    $filter: ModelSaaSCouponAreaHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSCouponAreaHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        meshId
        startAt
        endAt
        areaOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponAreaHistoryByMeshId = /* GraphQL */ `
  query SaaSCouponAreaHistoryByMeshId(
    $meshId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponAreaHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponAreaHistoryByMeshId(
      meshId: $meshId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        meshId
        startAt
        endAt
        areaOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSCouponStayHistory = /* GraphQL */ `
  query GetSaaSCouponStayHistory($id: ID!) {
    getSaaSCouponStayHistory(id: $id) {
      id
      deliveryId
      meshId
      startAt
      endAt
      stayOption
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSCouponStayHistories = /* GraphQL */ `
  query ListSaaSCouponStayHistories(
    $filter: ModelSaaSCouponStayHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSCouponStayHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        meshId
        startAt
        endAt
        stayOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponStayHistoryByMeshId = /* GraphQL */ `
  query SaaSCouponStayHistoryByMeshId(
    $meshId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponStayHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponStayHistoryByMeshId(
      meshId: $meshId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        meshId
        startAt
        endAt
        stayOption
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSCouponStationHistory = /* GraphQL */ `
  query GetSaaSCouponStationHistory($id: ID!) {
    getSaaSCouponStationHistory(id: $id) {
      id
      deliveryId
      stationId
      startAt
      endAt
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSCouponStationHistories = /* GraphQL */ `
  query ListSaaSCouponStationHistories(
    $filter: ModelSaaSCouponStationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSCouponStationHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        stationId
        startAt
        endAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponStationHistoryByStationId = /* GraphQL */ `
  query SaaSCouponStationHistoryByStationId(
    $stationId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponStationHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponStationHistoryByStationId(
      stationId: $stationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deliveryId
        stationId
        startAt
        endAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSCouponBillingHistory = /* GraphQL */ `
  query GetSaaSCouponBillingHistory($id: ID!) {
    getSaaSCouponBillingHistory(id: $id) {
      id
      userId
      stamp
      deliveryId
      amount
      feedbackState
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSCouponBillingHistories = /* GraphQL */ `
  query ListSaaSCouponBillingHistories(
    $filter: ModelSaaSCouponBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSCouponBillingHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stamp
        deliveryId
        amount
        feedbackState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponBillingHistoryByUserId = /* GraphQL */ `
  query SaaSCouponBillingHistoryByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponBillingHistoryByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stamp
        deliveryId
        amount
        feedbackState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponBillingHistoryByStamp = /* GraphQL */ `
  query SaaSCouponBillingHistoryByStamp(
    $stamp: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponBillingHistoryByStamp(
      stamp: $stamp
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stamp
        deliveryId
        amount
        feedbackState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponBillingHistoryByDeliveryId = /* GraphQL */ `
  query SaaSCouponBillingHistoryByDeliveryId(
    $deliveryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponBillingHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponBillingHistoryByDeliveryId(
      deliveryId: $deliveryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        stamp
        deliveryId
        amount
        feedbackState
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSCouponFeedbackHistory = /* GraphQL */ `
  query GetSaaSCouponFeedbackHistory($id: ID!) {
    getSaaSCouponFeedbackHistory(id: $id) {
      id
      userId
      deliveryId
      stamp
      feedbackType
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSCouponFeedbackHistories = /* GraphQL */ `
  query ListSaaSCouponFeedbackHistories(
    $filter: ModelSaaSCouponFeedbackHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSCouponFeedbackHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        deliveryId
        stamp
        feedbackType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSCouponFeedbackHistoryByStamp = /* GraphQL */ `
  query SaaSCouponFeedbackHistoryByStamp(
    $stamp: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSCouponFeedbackHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSCouponFeedbackHistoryByStamp(
      stamp: $stamp
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        deliveryId
        stamp
        feedbackType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSAnalytics = /* GraphQL */ `
  query GetSaaSAnalytics($id: ID!) {
    getSaaSAnalytics(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSAnalytics = /* GraphQL */ `
  query ListSaaSAnalytics(
    $filter: ModelSaaSAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSAnalytics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSNotification = /* GraphQL */ `
  query GetSaaSNotification($id: ID!) {
    getSaaSNotification(id: $id) {
      id
      ownerId
      targetId
      title
      content
      type
      read
      issue {
        target
        reasons
        treatments
        needActions
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSNotifications = /* GraphQL */ `
  query ListSaaSNotifications(
    $filter: ModelSaaSNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        targetId
        title
        content
        type
        read
        issue {
          target
          reasons
          treatments
          needActions
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSNotificationByOwnerId = /* GraphQL */ `
  query SaaSNotificationByOwnerId(
    $ownerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSNotificationByOwnerId(
      ownerId: $ownerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        targetId
        title
        content
        type
        read
        issue {
          target
          reasons
          treatments
          needActions
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSUserInfo = /* GraphQL */ `
  query GetSaaSUserInfo($id: ID!) {
    getSaaSUserInfo(id: $id) {
      id
      email
      familyName
      givenName
      agentCode
      reseller
      sendReportEmail {
        endOfDelivery
        shortBalance
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSUserInfos = /* GraphQL */ `
  query ListSaaSUserInfos(
    $filter: ModelSaaSUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        familyName
        givenName
        agentCode
        reseller
        sendReportEmail {
          endOfDelivery
          shortBalance
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSUserInfoByEmail = /* GraphQL */ `
  query SaaSUserInfoByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSUserInfoByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        familyName
        givenName
        agentCode
        reseller
        sendReportEmail {
          endOfDelivery
          shortBalance
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSaaSAccessManage = /* GraphQL */ `
  query GetSaaSAccessManage($id: ID!) {
    getSaaSAccessManage(id: $id) {
      id
      ownerId
      userId
      email
      addUserId
      role
      status
      createdAt
      updatedAt
    }
  }
`;
export const listSaaSAccessManages = /* GraphQL */ `
  query ListSaaSAccessManages(
    $filter: ModelSaaSAccessManageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaaSAccessManages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        userId
        email
        addUserId
        role
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSAccessManageByOwnerId = /* GraphQL */ `
  query SaaSAccessManageByOwnerId(
    $ownerId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSAccessManageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSAccessManageByOwnerId(
      ownerId: $ownerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        userId
        email
        addUserId
        role
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSAccessManageByUserId = /* GraphQL */ `
  query SaaSAccessManageByUserId(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSAccessManageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSAccessManageByUserId(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        userId
        email
        addUserId
        role
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const saaSAccessManageByEmail = /* GraphQL */ `
  query SaaSAccessManageByEmail(
    $email: AWSEmail!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSaaSAccessManageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saaSAccessManageByEmail(
      email: $email
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        userId
        email
        addUserId
        role
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInviteInfo = /* GraphQL */ `
  query GetInviteInfo($id: String) {
    getInviteInfo(id: $id) {
      email
      hostEmail
      status
      createdAt
    }
  }
`;
