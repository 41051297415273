import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {
  AccessManageRole,
  AccessManageStatus,
  SaaSAccessManageWithName,
} from '../../API';
import {Colors} from '../../theme';

const styleBaseBack = {backgroundColor: Colors.base};

export const AccessManageTable: React.FC<{
  accounts: SaaSAccessManageWithName[];
  edit?: boolean;
}> = ({accounts, edit = false}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - accounts.length) : 0;

  const handleChangePage: React.ComponentProps<
    typeof TablePagination
  >['onPageChange'] = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ComponentProps<
    typeof TablePagination
  >['onRowsPerPageChange'] = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const roleLabels = {
    [AccessManageRole.OWNER]: 'オーナー',
    [AccessManageRole.ADMIN]: '一般',
  };
  const statusLabels = {
    [AccessManageStatus.INIT]: '招待中',
    [AccessManageStatus.CONFIRM]: '確認中',
    [AccessManageStatus.DONE]: '利用可能',
  };
  const navigation = useNavigation();
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{border: `1px solid ${Colors.base}`}}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow style={styleBaseBack}>
            <TableCell align="center">名前</TableCell>
            <TableCell align="center">権限</TableCell>
            <TableCell align="center">メールアドレス</TableCell>
            <TableCell align="center">{edit ? '' : '状況'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? accounts.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
            : accounts
          ).map((account) => (
            <TableRow key={account.id} hover>
              <TableCell
                sx={{border: `1px solid ${Colors.base}`}}
                component="th"
                scope="row"
                align="center"
              >
                {account.familyName} {account.givenName}
              </TableCell>
              <TableCell
                sx={{border: `1px solid ${Colors.base}`}}
                align="center"
              >
                {account.role ? roleLabels[account.role] : '-'}
              </TableCell>
              <TableCell
                sx={{border: `1px solid ${Colors.base}`}}
                align="center"
              >
                {account.email}
              </TableCell>
              <TableCell
                sx={{border: `1px solid ${Colors.base}`}}
                align="center"
                padding={edit ? 'none' : 'normal'}
              >
                {account.role === AccessManageRole.OWNER ? (
                  '-'
                ) : edit ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() =>
                      navigation.navigate('AccessManageEdit', {id: account.id})
                    }
                  >
                    変更
                  </Button>
                ) : account.status ? (
                  statusLabels[account.status]
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{height: 53 * emptyRows}}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow sx={styleBaseBack}>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, {label: 'All', value: -1}]}
              colSpan={6}
              count={accounts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="表示数"
              SelectProps={{
                inputProps: {
                  'aria-label': '表示数',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
