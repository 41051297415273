import React from 'react';
import {useFormContext} from 'react-hook-form';
import {AttrForm, weekList} from '../../schema';
import {TargetingContainer} from '../../TargetingContainer';
import MapPreviewCommon, {getMeshList} from './MapPreviewCommon';
import {Paragraph} from '../../../Elements';
import {StayOption} from '../../../../API';
import {View} from 'react-native';
import {MeshList} from '../../types';
import {ChoiceCounter} from '../ChoiceCounter';
import {getCountByMesh} from '../../commonFunc';

function toWeeks(week: Array<number | null> | null | undefined): string[] {
  if (!week || week.length === 0) {
    return [];
  }
  return week
    .map((value, index) => (value ? weekList[index].label : ''))
    .filter((value) => value) as string[];
}

function toHours(hours: Array<number | null> | null | undefined): {
  hourFrom: string;
  hourTo: string;
} {
  if (hours && hours.length > 0) {
    const from = hours.indexOf(1);
    if (from >= 0 && from < hours.length) {
      const to = hours.indexOf(0, from);
      return {hourFrom: `${from}`, hourTo: `${to > 0 ? to : 24}`};
    }
  }
  return {hourFrom: '', hourTo: ''};
}

// 詳細画面にてサーバーから取得したデータを元にするプレビュー
export const StayPreviewWithData: React.FC<{
  area: StayOption;
}> = React.memo(({area}) => {
  const meshList = getMeshList(area.meshs);
  const weeks = toWeeks(area.weekDays);
  const hours = toHours(area.hours);
  return (
    <MapPreviewCommon meshList={meshList}>
      <StayLabel weeks={weeks} {...hours} meshList={meshList} />
    </MapPreviewCommon>
  );
});

// 作成・編集時のフォーム利用時のプレビュー
export const StayPreviewWithEdit: React.FC = React.memo(() => {
  // コンテナからメッシュリスト
  const {stayMesh, settingArea} = TargetingContainer.useContainer();
  const onPress = settingArea('areaStay');
  // フォームから条件を取得
  const {getValues} = useFormContext<AttrForm>();
  const weeks: string[] = [];
  weekList.forEach((day) => {
    const value = getValues(day.name);
    if (value) {
      weeks.push(day.label as string);
    }
  });
  const hourFrom = `${getValues('areaStay.hourFrom') ?? ''}`;
  const hourTo = `${getValues('areaStay.hourTo') ?? ''}`;
  return (
    <MapPreviewCommon meshList={stayMesh} onPress={onPress}>
      <StayLabel
        weeks={weeks}
        hourFrom={hourFrom}
        hourTo={hourTo}
        meshList={stayMesh}
      />
    </MapPreviewCommon>
  );
});

const StayLabel: React.FC<{
  weeks: string[];
  hourFrom: string;
  hourTo: string;
  meshList: MeshList;
}> = ({weeks, hourFrom, hourTo, meshList}) => {
  const week =
    weeks.length === 7 ? '全て' : weeks.length === 0 ? '---' : weeks.join(' ');
  const hours = hourFrom && hourTo ? `${hourFrom}時〜${hourTo}時` : '---';
  const count = getCountByMesh(meshList);
  return (
    <View>
      <Paragraph label="対象" value={`曜日：${week}${'　'}時間帯：${hours}`} />
      <ChoiceCounter count={count} type="mesh" />
    </View>
  );
};
