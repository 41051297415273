import {AsyncStorage} from '@aws-amplify/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box} from '@mui/material';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {FlatList, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {FullAddress} from '../../API';
import {BRAKE_POINT} from '../../components';
import {
  Caption,
  Container,
  List,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {
  AdminContainer,
  AuthContainer,
  OwnerContainer,
  StepNavigationContainer,
} from '../../container';
import {ERROR_MSG} from '../../helper';
import {MainNavigationProp} from '../../navigation/MainScreen';
import {SaasOwnerQuery, graphQLService} from '../../service';
import {CommonStyles} from '../../theme';

type NProp = MainNavigationProp<'OwnersMain'>;

type ListItem = {
  name: string;
  address: string;
  phone: string;
  id: string;
};

const Item: React.FC<{item: ListItem}> = ({item}) => {
  const {owner} = OwnerContainer.useContainer();
  const {checkInitData} = StepNavigationContainer.useContainer();
  const {user} = AuthContainer.useContainer();
  const navigation = useNavigation<NProp>();
  const goto = async () => {
    if (owner?.id !== item.id) {
      await AsyncStorage.setItem('owner', item.id);
      await checkInitData(item.id, user.username, 'SettingsMain');
    }
    navigation.navigate('SettingsMain');
  };
  return (
    <List.Item onPress={goto}>
      <View>
        <List.Title>{item.name}</List.Title>
        <VMargin />
        <Caption numberOfLines={1}>{item.address}</Caption>
        <Caption numberOfLines={1}>{item.phone}</Caption>
        <VMargin />
      </View>
    </List.Item>
  );
};

const AddButton: React.FC = () => {
  const {setStep2OwnerRegist} = StepNavigationContainer.useContainer();
  return (
    <Box pt={1}>
      <TrimaButton
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={setStep2OwnerRegist}>
        企業を新規作成
      </TrimaButton>
    </Box>
  );
};

function dumpAddress(address: FullAddress): string {
  return `${address.pref || ''}${address.city || ''}${address.detail || ''}${
    address.building || ''
  }`;
}

function convert(owner: SaasOwnerQuery): ListItem {
  return {
    name: owner?.hojin ?? '-',
    address: owner?.address ? dumpAddress(owner.address) : '-',
    phone: owner?.chargePhone ?? '-',
    id: owner?.id ?? '',
  };
}

const Main: React.FC = () => {
  const [list, setList] = React.useState<SaasOwnerQuery[] | undefined>();
  const [errorMsg, setError] = React.useState<string>('');
  const {owner} = OwnerContainer.useContainer();
  const {user} = AuthContainer.useContainer();
  const {isAdmin, userId} = AdminContainer.useContainer();

  React.useEffect(() => {
    const func = async () => {
      try {
        if (!owner) {
          return;
        }
        if (!isAdmin) {
          const fetchedOwners = await graphQLService.getListOwner(
            user.username,
          );
          setList(fetchedOwners);
          if (fetchedOwners) {
            setList(fetchedOwners);
          } else {
            setList([]);
          }
        } else {
          const fetchedOwners = await graphQLService.getListOwner(userId);
          setList(fetchedOwners);
          if (fetchedOwners) {
            setList(fetchedOwners);
          } else {
            setList([]);
          }
        }
      } catch (err: any) {
        setError(ERROR_MSG.common.dump(err));
      }
    };
    func().then();
  }, [owner, user, isAdmin, userId]);

  return (
    <Container>
      <MaxWidth maxWidth={BRAKE_POINT.desktop} style={CommonStyles.padding.all}>
        <WithHint id="companiesMain">
          <Menu>企業管理</Menu>
        </WithHint>
        <View style={CommonStyles.margin.top} />
        <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
          <View style={CommonStyles.flex.full}>
            {errorMsg ? (
              <Text>{errorMsg}</Text>
            ) : list === undefined ? (
              <ActivityIndicator size={60} />
            ) : (
              <>
                <AddButton />
                <VMargin />
                <FlatList
                  data={list.map(convert).filter((item) => item.id)}
                  keyExtractor={(item) => item.id}
                  ItemSeparatorComponent={List.Separator}
                  renderItem={(item) => <Item item={item.item} />}
                />
              </>
            )}
          </View>
        </MaxWidth>
      </MaxWidth>
    </Container>
  );
};

export const Owners = {
  Main,
};
