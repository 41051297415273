import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {NameFormData, schemaNameFormData} from '../../components/Auth/schema';
import {
  Container,
  FormBlock,
  Header,
  HelperText,
  MaxWidth,
  Menu,
  TextInputProps,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../../components/Elements';
import {UserInfoContainer} from '../../container/UserInfoContainer';
import {useCustomNav} from '../../helper';
import {AccountMenu} from '../../navigation/AccountMenu';
import {CommonStyles} from '../../theme';

const formInfos: {
  [P in keyof Required<NameFormData>]: TextInputProps<NameFormData>;
} = {
  familyName: {
    name: 'familyName',
    label: '姓',
    required: true,
  },
  givenName: {
    name: 'givenName',
    label: '名',
    required: true,
  },
};

const Submit: React.FC = () => {
  const {userInfo, update} = UserInfoContainer.useContainer();
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    handleSubmit,
    formState: {isValid},
    reset,
  } = useFormContext<NameFormData>();
  const navigation = useNavigation();

  useEffect(() => {
    reset({
      familyName: userInfo?.familyName ?? '',
      givenName: userInfo?.givenName ?? '',
    }); // asynchronously reset your form values
  }, [reset, userInfo]);

  const register = async (formData: NameFormData, navigation: any) => {
    setLoading(true);
    try {
      if (!userInfo) {
        throw new Error('userInfo is null or undefined');
      }
      const newUserInfo = await update({id: userInfo.id, ...formData});
      console.log('[UserInfoRegister] submit convert', newUserInfo);
      navigation.navigate('AccountMain');
    } catch (error: any) {
      console.log('[OwnerRegister] submit error', error);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };
  const helper = !isValid ? '必要項目の入力をお願いします' : errorMsg;

  return (
    <View>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit((data) => register(data, navigation))}
        disabled={!isValid || isLoading}
        loading={isLoading}
        loadingPosition="start">
        名前の保存
      </TrimaLoadingButton>
      <HelperText type="error">{helper}</HelperText>
    </View>
  );
};

const ChangeNameComponent: React.FC = () => {
  const navigator = useCustomNav();
  const goBack = () => {
    navigator.goBack('AccountMain');
  };
  const {userInfo} = UserInfoContainer.useContainer();

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>名前の変更</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          {!userInfo ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <FormBlock>
                <MaxWidth maxWidth={586}>
                  <VForm.Provider<NameFormData> schema={schemaNameFormData}>
                    <View style={CommonStyles.flex.row}>
                      <View style={CommonStyles.flex.full}>
                        <VForm.Text<NameFormData> {...formInfos.familyName} />
                      </View>
                      <View style={CommonStyles.margin.left} />
                      <View style={CommonStyles.flex.full}>
                        <VForm.Text<NameFormData> {...formInfos.givenName} />
                      </View>
                    </View>
                    <VMargin />
                    <Submit />
                  </VForm.Provider>
                  <VMargin />
                  <TrimaButton variant="outlined" onClick={() => goBack()}>
                    キャンセル
                  </TrimaButton>
                </MaxWidth>
              </FormBlock>
            </View>
          )}
        </View>
      </MaxWidth>
    </Container>
  );
};

export const ChangeName: React.FC = () => {
  const route = useRoute<any>();
  const [showHeader, setShowHeader] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (route.params?.header) {
      setShowHeader(route.params?.header);
    }
  }, [route.params]);
  if (showHeader) {
    return (
      <View>
        <Header rightItem={<AccountMenu />} />
        <ChangeNameComponent />
      </View>
    );
  } else {
    return <ChangeNameComponent />;
  }
};
