import dayjs from 'dayjs';
import React from 'react';
import {FlatList, StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {ShopContainer} from '../../container';
import {Helpers} from '../../helper';
import {
  DeliverySummary,
  DeliveryType,
  SaasReportRepository,
} from '../../service';
import {CommonStyles} from '../../theme';
import {List, MaxWidth, MinText, Text, VMargin} from '../Elements';

const FORMAT = 'YYYY/MM/DD HH:mm';

const Item: React.FC<{
  item: DeliverySummary;
  onPress(id: string, type: DeliveryType): void;
}> = ({item, onPress}) => {
  const {selected} = ShopContainer.useContainer();
  const start = dayjs(item.startAt);
  const end = dayjs(item.endAt);
  const term =
    start.isValid() && end.isValid()
      ? `${start.format(FORMAT)} 〜 ${end.format(FORMAT)}`
      : '不明な期間';
  const goto = () => onPress(item.id, item.type);
  return (
    <List.Item onPress={goto}>
      <View>
        <List.Title>{item.title}</List.Title>
        <MinText>{term}</MinText>
        <VMargin />
        <View style={CommonStyles.flex.row}>
          <View>
            <MinText>閲覧：</MinText>
            <MinText>クリック：</MinText>
            <MinText>来店：</MinText>
          </View>
          <View style={styles.leftMargin}>
            <MinText>{Helpers.sepComma(item.viewed ?? 0)}人</MinText>
            <MinText>
              {item.clickedUrl === undefined
                ? 'データがありません'
                : `${Helpers.sepComma(item.clickedUrl)}人`}
            </MinText>
            <MinText>
              {selected?.legalUrl
                ? '---人'
                : item.visited === undefined
                ? 'データがありません'
                : `${Helpers.sepComma(item.visited)}人`}
            </MinText>
          </View>
        </View>
      </View>
    </List.Item>
  );
};

type DataState = 'loading' | 'nodata' | 'error' | 'exist';

export const DeliveryList: React.FC<{
  shopId: string;
  onPress(id: string, type: DeliveryType): void;
}> = ({shopId, onPress}) => {
  const [dataState, setDataState] = React.useState<DataState>('loading');
  const [summaries, setSummaries] = React.useState<
    DeliverySummary[] | undefined
  >();
  React.useEffect(() => {
    if (!shopId) {
      return;
    }
    setDataState('loading');
    SaasReportRepository.getDeliverySummary(shopId)
      .then((reports) => {
        setSummaries(reports);
        setDataState(reports.length > 0 ? 'exist' : 'nodata');
      })
      .catch(() => {
        setSummaries(undefined);
        setDataState('error');
      });
  }, [shopId]);

  if (dataState === 'loading') {
    return <ActivityIndicator size={40} />;
  }
  if (dataState === 'error') {
    return <Text>データ取得エラー</Text>;
  }

  return (
    <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
      <FlatList
        data={summaries}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={List.Separator}
        renderItem={(item) => <Item item={item.item} onPress={onPress} />}
        ListEmptyComponent={<Text>データがありません</Text>}
      />
    </MaxWidth>
  );
};

const styles = StyleSheet.create({
  leftMargin: {
    marginLeft: 30,
  },
});
