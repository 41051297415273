import {LoadingButton} from '@mui/lab';
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {useState} from 'react';
import {SubmitHandler, useFormContext} from 'react-hook-form';
import {View} from 'react-native';
import {
  AccessRoleContainer,
  AuthContainer,
  OwnerContainer,
  PaymentInfoContainer,
  StepNavigationContainer,
  UserInfoContainer,
} from '../../container';
import {AccountMenu} from '../../navigation/AccountMenu';
import {CommonStyles, Fonts} from '../../theme';
import {
  FormBlock,
  Header,
  HelperText,
  MaxWidth,
  RegisterContainer,
  RequiredNotice,
  Submenu,
  Text,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../Elements';
import {AddressPickerContainer} from '../PlacePicker';
import {
  OwnerFormData,
  convert,
  ownerFormInfos,
  schemaOwnerForm,
} from './ownerSchema';

const NameForms: React.FC<{
  label: string;
  family: keyof typeof ownerFormInfos;
  first: keyof typeof ownerFormInfos;
}> = ({label, family, first}) => {
  return (
    <View>
      <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
        <Text>{label}</Text>
        <RequiredNotice />
      </View>
      <View style={CommonStyles.flex.row}>
        <View style={CommonStyles.flex.full}>
          <VForm.Text<OwnerFormData> {...ownerFormInfos[family]} />
        </View>
        <View style={CommonStyles.margin.left} />
        <View style={CommonStyles.flex.full}>
          <VForm.Text<OwnerFormData> {...ownerFormInfos[first]} />
        </View>
      </View>
    </View>
  );
};

const Submit = <T extends OwnerFormData>() => {
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const {setStep2PaymentRegist} = StepNavigationContainer.useContainer();
  const ownerContainer = OwnerContainer.useContainer();
  const {clearData} = PaymentInfoContainer.useContainer();
  const {agentCode} = UserInfoContainer.useContainer();
  const {user} = AuthContainer.useContainer();
  const {fetchAccessRole} = AccessRoleContainer.useContainer();
  const {
    handleSubmit,
    formState: {isValid},
  } = useFormContext<T>();
  const register: SubmitHandler<T> = async (data) => {
    setLoading(true);
    try {
      const converted = convert(data);
      const owner = await ownerContainer.create({
        ...converted,
        agentCode,
        status: 'ENABLED',
      });
      console.log('[OwnerRegister] submit convert', ownerContainer.owner);
      await AsyncStorage.setItem('owner', owner?.id as string);
      await fetchAccessRole(user.username, owner?.id as string);
      clearData();
      await AsyncStorage.removeItem('shop');
      setStep2PaymentRegist();
    } catch (error: any) {
      console.log('[OwnerRegister] submit error', error);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };
  const helper = !isValid ? '必要項目の入力をお願いします' : errorMsg;
  return (
    <View>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(register)}
        disabled={!isValid || isLoading}
        loading={isLoading}
        loadingPosition="start">
        お支払い方法の選択へ
      </TrimaLoadingButton>
      <HelperText type="error">{helper}</HelperText>
    </View>
  );
};

const AddressForm: React.FC = () => {
  const {searchByZip, searching} = AddressPickerContainer.useContainer();
  return (
    <View>
      <Submenu>所在地</Submenu>
      <View style={CommonStyles.flex.row}>
        <View style={CommonStyles.flex.full}>
          <VForm.Text<OwnerFormData> {...ownerFormInfos.zip} />
        </View>
        <LoadingButton
          sx={{
            height: 40,
            width: 100,
            marginTop: '28px',
            marginLeft: 2,
            borderRadius: 2,
          }}
          variant="contained"
          onClick={searchByZip}
          disabled={searching}
          loading={searching}>
          自動入力
        </LoadingButton>
      </View>
      <VForm.Text<OwnerFormData> {...ownerFormInfos.pref} editable={false} />
      <VForm.Text<OwnerFormData> {...ownerFormInfos.city} editable={false} />
      <VForm.Text<OwnerFormData> {...ownerFormInfos.detail} />
      <VForm.Text<OwnerFormData> {...ownerFormInfos.building} />
    </View>
  );
};

const FormsInner = <T extends OwnerFormData>(props: {
  children?: React.ReactElement;
}) => {
  return (
    <View>
      <FormBlock>
        <MaxWidth maxWidth={586}>
          <VForm.Text<OwnerFormData> {...ownerFormInfos.hojinName} />
          <VForm.Text<OwnerFormData> {...ownerFormInfos.hojinYomi} />
          <NameForms
            label="代表者の氏名"
            family="representativeNameFamily"
            first="representativeNameFirst"
          />
          <NameForms
            label="代表者の氏名（カナ）"
            family="representativeYomiFamily"
            first="representativeYomiFirst"
          />
          <AddressPickerContainer.Provider>
            <AddressForm />
          </AddressPickerContainer.Provider>
          <VForm.Text<OwnerFormData> {...ownerFormInfos.url} />
          <VForm.Text<OwnerFormData> {...ownerFormInfos.chargeDept} />
          <NameForms
            label="担当者名"
            family="chargeNameFamily"
            first="chargeNameFirst"
          />
          <NameForms
            label="担当者名（カナ）"
            family="chargeYomiFamily"
            first="chargeYomiFirst"
          />
          <VForm.Text<OwnerFormData> {...ownerFormInfos.chargePhone} />
          <Text style={Fonts.caption}>
            ※担当者電話番号は月額料金の支払い方法によっては、審査のために使用することがございます。
          </Text>
          <Text style={Fonts.caption}>
            必ず担当者ご本人と連絡が取れる電話番号を入力してください。
          </Text>
          <View style={CommonStyles.margin.bottom} />
        </MaxWidth>
      </FormBlock>
      <VMargin />
      {props.children}
      <MaxWidth maxWidth={586}>
        <Submit<T> />
      </MaxWidth>
    </View>
  );
};

const Forms: React.FC = () => {
  return (
    <VForm.Provider<OwnerFormData> schema={schemaOwnerForm}>
      <FormsInner<OwnerFormData> />
    </VForm.Provider>
  );
};

export const OwnerRegisterComponent: React.FC = () => {
  return (
    <View>
      <Header rightItem={<AccountMenu />} />
      <RegisterContainer step="owner" label="企業情報入力" style={{}}>
        <Text>
          企業情報は、料金のご請求先、および弊社からのご連絡先の情報となります。
        </Text>
        <Text>
          代理店様の場合はクライアント様の企業情報ではなく、必ず代理店様の企業情報を入力してください。
        </Text>
        <VMargin />
        <Forms />
      </RegisterContainer>
    </View>
  );
};
