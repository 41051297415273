import {AxiosResponse} from 'axios';
import parsePhoneNumber from 'libphonenumber-js';
import {EditState, SaaSNews} from '../../API';
import {getImages} from '../../service';
import {
  SaaSNews as NewsParam,
  SaaSNewsDefine,
  SaaSNewsRepository,
} from '../../_proto/services/SaaSNewsRepository';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {FormData} from './schema';

export function convert(
  data: FormData,
  editState: EditState,
  shopId: string,
  ownerId: string,
): SaaSNewsDefine {
  const {name, text, title, cvLinkUrl, cvLinkText, cvPhone} = data;
  return {
    name,
    title,
    text: text ? text : undefined,
    editState,
    shopId,
    ownerId,
    cvLinkUrl: cvLinkUrl ? cvLinkUrl : undefined,
    cvLinkText,
    cvPhone,
  };
}

// 既存の編集（上書き）か、既存を元にした新規作成か
export type EditMode = 'edit' | 'copy';

export async function convertForRestore(
  news: SaaSNews,
  editMode: EditMode,
): Promise<{
  forms: FormData;
  images: SaaSImageContents[];
  editState?: EditState;
}> {
  const phoneNumber = parsePhoneNumber(news.cvPhone ?? '', 'JP');
  // copy 時は新規に作られるようにidをリストアしないで、タイトルも加工
  const forms: FormData = Object.assign({
    id: editMode === 'edit' ? news.id : '',
    name: news.name ?? '',
    text: news.text ?? '',
    title: (news.title ?? '') + (editMode === 'copy' ? ' のコピー' : ''),
    imageEnabled: false,
    cvLinkUrl: news.cvLink?.url ?? '',
    cvLinkText: news.cvLink?.text ?? '',
    cvPhone: news.cvPhone && phoneNumber ? phoneNumber.formatNational() : '',
  });

  if (!news.imageUrls || !news.imageUrls.length) {
    return {forms, images: []};
  }
  const images = await getImages(news.imageUrls);
  forms.imageEnabled = images.length > 0;
  return {
    forms,
    images,
    editState: editMode === 'copy' ? undefined : news.editState ?? undefined,
  };
}

export async function save(
  news: NewsParam,
  update?: string,
): Promise<AxiosResponse<SaaSNews>> {
  console.log('saved', update);

  if (update) {
    // 上書き
    return await SaaSNewsRepository.put(news, {id: update});
  } else {
    return await SaaSNewsRepository.post(news);
  }
}
