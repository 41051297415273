import {API, graphqlOperation} from 'aws-amplify';

const TAG = 'GraphQlRepository';

export class GraphQlRepository {
  static async query(
    queryDef: any,
    queryName: string,
    params: any,
  ): Promise<any> {
    console.log(TAG, queryDef.name, queryDef, params);
    const paramLimit = params.limit;
    const limit = paramLimit ? paramLimit : 1000;

    const ret = [];
    try {
      let nextToken = null;
      do {
        const result: any = await API.graphql(
          graphqlOperation(queryDef, {
            ...params,
            limit,
            nextToken,
          }),
        );

        if (
          result.data &&
          result.data[queryName] &&
          result.data[queryName].items
        ) {
          ret.push(...result.data[queryName].items);
          nextToken = result.data[queryName].nextToken;
        }

        if (paramLimit && ret.length >= paramLimit) {
          break;
        }
      } while (nextToken);
    } catch (err: any) {
      console.error(TAG, err);
      return null;
    }
    console.log(TAG, ret.length);
    return ret;
  }

  static async mutation(def: any, name: string, params: any): Promise<any> {
    console.log(TAG, def, name, params);
    try {
      const result: any = await API.graphql(graphqlOperation(def, params));
      console.log(TAG, result);
      return result && result.data && result.data[name];
    } catch (err: any) {
      console.error(TAG, err);
      return null;
    }
  }

  static async get(def: any, name: string, params: any): Promise<any> {
    console.log(TAG, def, name, params);
    try {
      const result: any = await API.graphql(graphqlOperation(def, params));
      return result && result.data && result.data[name];
    } catch (err: any) {
      console.error(TAG, err);
      return null;
    }
  }

  // ----------- private -------------
}
