import {useRoute} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {SubmitErrorHandler, useFormContext} from 'react-hook-form';
import {Linking, StyleSheet, View} from 'react-native';
import {ActivityIndicator, TextInput} from 'react-native-paper';
import {CommonStyles} from '../../theme';
import {
  HelperText,
  MaxWidth,
  PLACEHOLDER,
  Text,
  TextInputProps,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../Elements';
import {InviteContainer} from './InviteContainer';
import {SignUpContainer} from './SignUpContainer';
import {SignUpFormData, schemaResistForm} from './schema';

const formInfos: {
  [P in keyof Required<SignUpFormData>]: TextInputProps<SignUpFormData>;
} = {
  email: {
    name: 'email',
    label: 'メールアドレス',
    required: true,
  },
  familyName: {
    name: 'familyName',
    label: '姓',
    required: true,
  },
  givenName: {
    name: 'givenName',
    label: '名',
    required: true,
  },
  phone: {
    name: 'phone',
    label: '携帯電話番号',
    ...PLACEHOLDER.phone,
    keyboardType: 'phone-pad',
    required: true,
  },
  password: {
    name: 'password',
    label: 'パスワード',
    secureTextEntry: true,
  },
};

const LABEL = 'SMSで確認コードを送る';
const LABEL_LOADING = 'SMSで確認コードを送る';

const Submit: React.FC = () => {
  const [showError, setShowError] = React.useState('');
  const {
    handleSubmit,
    watch,
    formState: {errors},
    setValue,
  } = useFormContext<SignUpFormData>();
  const {signUp, step, error, email} = SignUpContainer.useContainer();

  React.useEffect(() => {
    if (!errors.phone && !errors.password) {
      setShowError('');
    }
  }, [errors.phone, errors.password]);

  React.useEffect(() => {
    setShowError('');
    if (error) {
      // Todo エラーメッセージ
      setShowError(error.message);
      // Todo リトライ
    }
  }, [error]);

  useEffect(() => {
    setValue('email', email ?? '');
  }, [setValue, email]);

  const onError: SubmitErrorHandler<SignUpFormData> = () =>
    setShowError('フォームの入力に誤りがあります');
  const isLoading = step === 'signup';
  const disabled =
    isLoading ||
    !watch('phone') ||
    !watch('password') ||
    !!errors.phone ||
    !!errors.password;
  const buttonLabel = isLoading ? LABEL_LOADING : LABEL;
  return (
    <View>
      <VMargin />
      <div
        className="btn-sign-up"
        data-gtm-visibility-category="ボタン"
        data-gtm-visibility-action="表示"
        data-gtm-visibility-label={'（サインアップ）' + LABEL}
      >
        <TrimaLoadingButton
          variant="contained"
          onClick={handleSubmit(signUp, onError)}
          disabled={disabled}
          loading={isLoading}
          loadingPosition="start"
        >
          {buttonLabel}
        </TrimaLoadingButton>
      </div>

      <HelperText type="error">{showError}</HelperText>
    </View>
  );
};

const PASSWORD_RULE = `・英文字 ( 大文字・小文字 )、数字を必ず含む8文字以上で作成してください。
・アルファベットの大文字と小文字は区別されます。`;

export const SignUpForm: React.FC = () => {
  const {params} = useRoute();
  const [secureTextEntry, setSecureTextEntry] = React.useState<boolean>(true);
  const {invite, fetchInvite} = InviteContainer.useContainer();

  useEffect(() => {
    async function fetchData() {
      if (params && (params as any).invite) {
        await fetchInvite((params as any).invite);
      }
    }
    fetchData();
  }, [params, fetchInvite]);

  return (
    <View>
      <MaxWidth maxWidth={586}>
        {(params as any).invite ? (
          !invite ? (
            <ActivityIndicator />
          ) : (
            <Text>
              {invite?.hostEmail}{' '}
              から企業に参加するよう招待メールが届いています。トリマビジネスアカウントを本登録して新しいダッシュボードにログインしてください。
            </Text>
          )
        ) : (
          <React.Fragment />
        )}

        <VMargin />
        <VForm.Provider<SignUpFormData> schema={schemaResistForm}>
          <VForm.Text<SignUpFormData> {...formInfos.email} editable={false} />
          <VMargin />
          <VForm.Text<SignUpFormData>
            label={formInfos.password.label}
            name={formInfos.password.name}
            secureTextEntry={secureTextEntry}
            required
            right={
              <TextInput.Icon
                size={24}
                style={styles.eye}
                name={secureTextEntry ? 'eye' : 'eye-off'}
                onPress={() => {
                  setSecureTextEntry(!secureTextEntry);
                }}
              />
            }
          />
          <Text>{PASSWORD_RULE}</Text>
          <VMargin />
          <View style={CommonStyles.flex.row}>
            <View style={CommonStyles.flex.full}>
              <VForm.Text<SignUpFormData> {...formInfos.familyName} />
            </View>
            <View style={CommonStyles.margin.left} />
            <View style={CommonStyles.flex.full}>
              <VForm.Text<SignUpFormData> {...formInfos.givenName} />
            </View>
          </View>
          <VMargin />
          <VForm.Text<SignUpFormData> {...formInfos.phone} />
          <Text>
            SMSが送信されます。海外事業者からのSMS受信を拒否している場合は解除をお願いします。
            <Text
              style={CommonStyles.linkText}
              onPress={() =>
                Linking.openURL(
                  'https://support.shop.trip-mile.com/hc/ja/articles/4407344353165',
                )
              }
            >
              詳細
            </Text>
          </Text>
          <VMargin />
          <Submit />
        </VForm.Provider>
      </MaxWidth>
    </View>
  );
};

const styles = StyleSheet.create({
  eye: {
    position: 'relative',
    top: 4,
  },
});
