import {Box, Stack} from '@mui/material';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Helpers} from '../../helper';
import {DeliverySums} from '../../service';
import {Colors} from '../../theme';
import {Caption, DataArea, Overlay, Text} from '../Elements';
import {useResponsive} from '../Responsive';

export const DeliveryReportSums: React.FC<{sums: DeliverySums}> = React.memo(
  ({sums}) => {
    sums = sums ?? {};
    const isEmpty = !sums || Object.keys(sums).length === 0;
    const {isMobile} = useResponsive();
    return (
      <View>
        <DataArea>
          <Stack direction={{xs: 'column', sm: 'row'}} spacing={{xs: 0, sm: 4}}>
            <Stack direction="row">
              <View style={{minWidth: 145.02}}>
                <RText>一覧表示：</RText>
                <RText>広告閲覧人数：</RText>
                <RText>クリック人数：</RText>
                <RText>来店延べ人数：</RText>
              </View>
              <View style={styles.valuesContainer}>
                <RText>{person(sums.list)}</RText>
                <RText>{person(sums.bill)}</RText>
                <RText>{person(sums.clickedUrl)}</RText>
                <RText>{person(sums.visited)}</RText>
              </View>
              <Arrows />
              <Ratios sums={sums} />
            </Stack>
            <Stack direction="row">
              <View>
                {!isMobile && <Box p={2} />}
                <RText>
                  広告料<span style={{fontSize: '10px'}}>（閲覧人数×3円）</span>
                  ：
                </RText>
                <RText>クリック単価：</RText>
                <RText>来店単価：</RText>
              </View>
              <View style={styles.valuesContainer}>
                {!isMobile && <Box p={2} />}
                <RText>{yen(sums.amount)}</RText>
                <RText>{unit(sums.amount, sums.clickedUrl)}</RText>
                <RText>{unit(sums.amount, sums.visited)}</RText>
              </View>
              <View style={{minWidth: 66.82}}>
                {!isMobile && <Box p={2} />}
                <Text style={{lineHeight: 32}}>(税別)</Text>
              </View>
            </Stack>
          </Stack>

          {isEmpty && (
            <Overlay>
              <Text>データがありません。</Text>
            </Overlay>
          )}
        </DataArea>
      </View>
    );
  },
);

const RText: React.FC<{children: React.ReactNode}> = ({children}) => {
  return <Text style={styles.right}>{children}</Text>;
};

const Arrow: React.FC = () => {
  // XDからコピペしてきたのでidとかは変だけどとりあえず
  return (
    <>
      <View style={styles.arrow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.999"
          height="31"
          viewBox="0 0 16.999 31">
          <path
            id="合体_55"
            data-name="合体 55"
            d="M5,26.186l6.722-4.814v4.044h5.749a3,3,0,0,0,3-3V4.926a3,3,0,0,0-3-3H7.834V0H18a4,4,0,0,1,4,4V23.342a4,4,0,0,1-4,4H11.722V31Z"
            transform="translate(-5)"
            fill="#b094e2"
          />
        </svg>
      </View>
      <View
        style={{
          height: 35,
          marginLeft: 4,
          marginRight: 4,
        }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.527"
          height="35"
          viewBox="0 0 17.527 35">
          <g
            id="グループ_3932"
            data-name="グループ 3932"
            transform="translate(-4259.472 -22799)">
            <path
              id="合体_55"
              data-name="合体 55"
              d="M4.472,30.186l6.722-4.813v4.691h6.278a3,3,0,0,0,3-3V5.278a3,3,0,0,0-3-3H7.834V0H18a4,4,0,0,1,4,4V28.342a4,4,0,0,1-4,4H11.194V35Z"
              transform="translate(4255 22799)"
              fill="#0076ff"
            />
          </g>
        </svg>
      </View>
      <View
        style={{
          height: 40,
          marginLeft: 4,
          marginRight: 4,
          position: 'relative',
          top: -6,
        }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.527"
          height="39.736"
          viewBox="0 0 17.527 39.736">
          <g
            id="グループ_3932"
            data-name="グループ 3932"
            transform="translate(-4259.472 -22789.264)">
            <path
              id="合体_55"
              data-name="合体 55"
              d="M4.472,36.867l6.722-5.134v3.522h6.278a3,3,0,0,0,3-3V5.671a10.064,10.064,0,0,1,.9-2.848c.076-.187.153-.374.228-.559A3.956,3.956,0,0,1,22,4V33.925a4,4,0,0,1-4,4H11.194V42Z"
              transform="translate(4255 22787)"
              fill="#ff6f00"
            />
          </g>
        </svg>
      </View>
    </>
  );
};

const Ratio: React.FC<{color: string; children: React.ReactNode}> = ({
  color,
  children,
}) => {
  return (
    <View style={styles.ratio}>
      <Caption style={[styles.ratioText, {color: color}]}>{children}</Caption>
    </View>
  );
};

function person(num: number | undefined): string {
  if (num === undefined) {
    return '---人';
  }
  return `${Helpers.sepComma(num)}人`;
}

function percent(top: number | undefined, bottom: number | undefined) {
  if (!bottom || !top) {
    // 各値が0や数値じゃない場合は計算しない
    return '---%';
  }
  const ratio = Math.round((10000 * top) / bottom) / 100;
  return `${ratio}%`;
}

function yen(num: number | undefined): string {
  if (num === undefined) {
    return '---円';
  }
  return `${Helpers.sepComma(num)}円`;
}

function unit(top: number | undefined, bottom: number | undefined) {
  if (!bottom || !top) {
    // 各値が0や数値じゃない場合は計算しない
    return '---円';
  }
  return `${Helpers.sepComma(Math.round((top / bottom) * 10) / 10)}円`;
}

const Arrows: React.FC = () => {
  return (
    <View style={styles.ratioOffset}>
      <Arrow />
    </View>
  );
};

const Ratios: React.FC<{sums: DeliverySums}> = ({sums}) => {
  return (
    <View style={styles.ratioOffset}>
      <Ratio color="#B094E2">{percent(sums.bill, sums.list)}</Ratio>
      <Ratio color="#0076FF">{percent(sums.clickedUrl, sums.bill)}</Ratio>
      <Ratio color="#FF6F00">{percent(sums.visited, sums.bill)}</Ratio>
    </View>
  );
};

const styles = StyleSheet.create({
  right: {
    lineHeight: 32,
    textAlign: 'right',
  },
  caption: {
    lineHeight: 12,
    textAlign: 'right',
    marginBottom: 12,
  },
  arrow: {
    height: 32,
    marginLeft: 4,
    marginRight: 4,
  },
  ratio: {
    height: 32,
    paddingTop: 2,
  },
  ratioText: {
    textAlign: 'right',
    color: Colors.accent,
  },
  valuesContainer: {
    minWidth: 100,
  },
  ratioOffset: {
    marginTop: 16, // 半行分くらいずれているように見せる
  },
  ratioContainer: {
    marginLeft: 8,
  },
  dataTable: {
    width: 320,
  },
});
