import {SaaSNews} from '../../API';
import {getImages} from '../../service';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {FormData} from '../News/schema';

// 各画面で使えるようにクーポン作成画面のデータorDBからのデータどちらもOKにする
export type NewsInfoInput =
  | SaaSNews
  | {data: FormData; images: SaaSImageContents[]};

export type CvLink = {
  url: string;
  text: string;
};

export type NewsInfo = {
  name: string; // 商品名
  text?: string; // 詳しい説明
  images: SaaSImageContents[];
  cvLink?: CvLink;
  cvPhone?: string;
};

// 以下、型変換処理
const newsInfoDefault: NewsInfo = {
  name: '（広告名）',
  images: [],
};

export async function convert(info?: NewsInfoInput): Promise<NewsInfo> {
  if (!info) {
    return newsInfoDefault;
  }
  if ('data' in info) {
    return {
      name: info.data.name ?? newsInfoDefault.name,
      text: info.data.text,
      images: info.images,
      cvLink: {
        url: info.data.cvLinkUrl ?? '',
        text: info.data.cvLinkText ?? 'Webサイトを見る',
      },
      cvPhone: info.data.cvPhone,
    };
  } else {
    return {
      name: info.name ?? newsInfoDefault.name,
      text: info.text ?? undefined,
      images: await getImages(info.imageUrls),
      cvLink: {
        url: info.cvLink?.url ?? '',
        text: info.cvLink?.text ?? 'Webサイトを見る',
      },
      cvPhone: info.cvPhone ?? undefined,
    };
  }
}
