import _ from 'lodash';
import {useCallback, useEffect, useState} from 'react';

import {BehaviorSubject, of} from 'rxjs';
import {fromFetch} from 'rxjs/fetch';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';
import {
  DetailOptionDto,
  GoogleApiRepository,
  SuggestOptionDto,
} from '../../../../../_proto/services';
import {MockSuggest} from './mock';
import {GoogleAddressDetail, GooglePlaceSuggestSession} from './types.def';

const TAG = 'AddrFetcher';

type SuggestOption = SuggestOptionDto & {dryRun?: boolean};
type SuggestSubject = BehaviorSubject<SuggestOption | null>;
type DetailSubject = BehaviorSubject<DetailOptionDto | null>;

export type AddrFetcherState = {
  session: string | undefined;
  suggest: GooglePlaceSuggestSession | null;
  clear: () => void;
  suggestTopic: SuggestSubject;
  detail: GoogleAddressDetail | null;
  detailTopic: DetailSubject;
};

export function useAddrFetcher(): AddrFetcherState {
  const [suggest, setSuggest] = useState<GooglePlaceSuggestSession | null>(
    null,
  );
  const [session, setSession] = useState<string | undefined>();

  const [suggestTopic] = useState(
    new BehaviorSubject<SuggestOption | null>(null),
  );

  const [detail, setDetail] = useState<GoogleAddressDetail | null>(null);
  const [detailTopic] = useState<DetailSubject>(
    new BehaviorSubject<DetailOptionDto | null>(null),
  );

  // effetct for suggest
  useEffect(() => {
    const obz = suggestTopic
      .pipe(
        distinctUntilChanged((lhs, rhs) => _.isEqual(lhs, rhs)),
        debounceTime(100),
        switchMap((option) => {
          // console.log(TAG, option);
          setSuggest(null);
          if (!option) {
            return of(null);
          }

          if (option.dryRun) {
            return of({json: () => Promise.resolve(MockSuggest)}).pipe(
              delay(1000),
            );
          }

          const config = GoogleApiRepository.buildFetchSuggestConfig(option);
          return fromFetch(config.url, config.init);
        }),
        catchError((err, _) => {
          console.log(TAG, err);
          return of(null);
        }),
      )
      .subscribe(async (res) => {
        if (res) {
          const json = await res.json();
          // console.log(TAG, 'json', json);
          setSession(json.session);
          setSuggest(json);
        }
      });
    return () => {
      obz.unsubscribe();
      suggestTopic.unsubscribe();
    };
  }, [suggestTopic]);

  // effetct for detail
  useEffect(() => {
    const obz = detailTopic
      .pipe(
        debounceTime(100),
        switchMap((option) => {
          // console.log(TAG, option);
          if (!option) {
            return of(null);
          }
          const config = GoogleApiRepository.buildFetchDetailConfig(option);
          return fromFetch(config.url, config.init);
        }),
      )
      .subscribe(async (res) => {
        if (res) {
          const json = await res.json();
          // console.log(TAG, 'json', json);
          setDetail(json);
        }
      });
    return () => {
      obz.unsubscribe();
      detailTopic.unsubscribe();
    };
  }, [detailTopic]);

  const clear = useCallback(() => {
    setSuggest(null);
  }, []);

  return {
    session,
    suggest,
    suggestTopic,
    detail,
    detailTopic,
    clear,
  };
}
