import React from 'react';
import {ScrollView, View} from 'react-native';
import {CommonStyles} from '../../../../theme';
import {Text, TrimaButton, VMargin} from '../../../Elements';
import {TargetCounter} from '../../Counters';
import {TargetingContainer} from '../../TargetingContainer';
import {ChoiceCounter} from '../ChoiceCounter';
import {StationSelector} from './StationSelector';
import {StationUsersContainer} from './StationUsersContainer';

const StationSetting: React.FC = () => {
  const {getStationInitial} = TargetingContainer.useContainer();
  const init = getStationInitial();
  return (
    <StationUsersContainer.Provider initialState={init}>
      <View style={[CommonStyles.fullHeight]}>
        <View style={CommonStyles.margin.all}>
          <Text>利用駅を設定</Text>
        </View>
        <Count />
        <VMargin />
        <VMargin />
        <Choice />
        <ScrollView style={CommonStyles.margin.all}>
          <StationSelector />
        </ScrollView>
        <ConfirmButton />
      </View>
    </StationUsersContainer.Provider>
  );
};

const ConfirmButton: React.FC = () => {
  const {canConfirm, confirm} = StationUsersContainer.useContainer();
  return (
    <View style={[CommonStyles.margin.horizontal, CommonStyles.margin.bottom]}>
      <TrimaButton variant="contained" disabled={!canConfirm} onClick={confirm}>
        確定
      </TrimaButton>
    </View>
  );
};

const Count: React.FC = () => {
  const {userCount} = StationUsersContainer.useContainer();
  return (
    <View>
      <TargetCounter count={userCount} />
    </View>
  );
};

const Choice: React.FC = () => {
  const {checkStatus} = StationUsersContainer.useContainer();
  const count =
    checkStatus.loading.station.size + checkStatus.checked.station.size;
  return (
    <View style={[CommonStyles.margin.right, CommonStyles.margin.top]}>
      <ChoiceCounter type="station" count={count} />
    </View>
  );
};

export default StationSetting;
