import {Box, Button, Link, Typography} from '@mui/material';
import React from 'react';
import {View} from 'react-native';
import {Header, MaxWidth, RegisterContainer} from '../../components/Elements';
import {StepNavigationContainer} from '../../container';
import {AccountMenu} from '../../navigation/AccountMenu';

const SUPPORT_SITE_URL = 'https://support.shop.trip-mile.com/hc/ja';

export const RegisterDone: React.FC = () => {
  const {setStep2ShopRegist} = StepNavigationContainer.useContainer();

  return (
    <View>
      <Header rightItem={<AccountMenu />} />
      <RegisterContainer step="done" label="企業情報の登録が完了しました！">
        <Typography variant="body1">
          トリマ広告ダイレクトにご登録いただき、ありがとうございます！
        </Typography>
        <Box p={1} />
        <Typography variant="body1">
          引き続き、店舗登録へとお進みください。
        </Typography>
        <Box p={1} />
        <MaxWidth maxWidth={586}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={setStep2ShopRegist}
          >
            店舗を登録する
          </Button>
        </MaxWidth>
        <Box p={1} />
        <Link
          href={SUPPORT_SITE_URL}
          target="_blank"
          rel="noopener"
          underline="hover"
        >
          サポートサイトはこちら
        </Link>
      </RegisterContainer>
    </View>
  );
};
