import axios from 'axios';
import {AppConfig} from '../config/AppConfig';
const TAG = 'SaaSAgencyRepository';

export type SaaSAgencyDto = {
  id: string;
  name: string;
};

export class SaaSAgencyRepository {
  static async get(id: string): Promise<SaaSAgencyDto> {
    console.log(TAG, '[get]', id);

    const config = {
      headers: {
        Accept: '*/*',
      },
    };

    const result = await axios.get(
      `${AppConfig.SaaSBackend}${AppConfig.AgencyFetchEndPoint}/${id}`,
      config,
    );

    console.log(TAG, '[get] result', result);

    return result.data;
  }

  static async postPreRegist(email: string, agency: string): Promise<any> {
    const url = `${AppConfig.SaaSBackend}${AppConfig.PreRegistEndPoint}`;

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    };

    const data = {
      email,
      agency,
    };

    console.log(TAG, url, config, data);
    console.log(TAG, 'pre-r-data', config);
    const rt = await axios.post(url, data, config);
    console.log(TAG, 'pre-regist', rt.data);
    return rt.data;
  }
}
