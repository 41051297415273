import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {AccessManageRole} from '../../API';
import {AuthContainer} from '../../container';
import {graphQLService} from '../../service';

export type AccessManageFormData = {
  email?: string;
  ownerId: string;
};

const useAccessManageContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<Array<any>>([]);
  const [error, setError] = useState<Error | undefined>();
  const {user} = AuthContainer.useContainer();

  /**
   * ユーザーを登録
   */
  const create = useCallback(
    async (data: AccessManageFormData) => {
      setCreateLoading(true);
      setError(undefined);
      const input = {
        ...data,
        role: AccessManageRole.ADMIN,
        addUserId: user.username,
      };
      try {
        const result = await graphQLService.createAccessManage(input);
        console.log('result', result);
        if (!accounts.some((account) => account.id === result?.id)) {
          console.log(accounts);
          setAccounts([...accounts, result]);
        }
      } catch (err: any) {
        console.log(err.errors[0].message);
        setError(new Error(err.errors[0].message));
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [accounts, user],
  );

  /**
   * サーバーからユーザーのリストを取得
   */
  const getData = useCallback(async (ownerId: string) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = await graphQLService.getAccessManageList(ownerId);
      setAccounts(result);
      return result;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const deleteData = useCallback(
    async (id: string) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        await graphQLService.deleteAccessManage({id});
        const newAccounts = accounts.filter((account) => {
          return account.id !== id;
        });
        setAccounts(newAccounts);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [accounts],
  );

  const migrationOwner = useCallback(async (id: string) => {
    setDeleteLoading(true);
    setError(undefined);
    try {
      await graphQLService.migrateOwnerAccess({id});
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setDeleteLoading(false);
    }
  }, []);

  const updateEmail = useCallback(
    async ({email, oldEmail}: {email: string; oldEmail: string}) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        await graphQLService.updateAccessManageEmail({email, oldEmail});
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [],
  );

  return {
    isCreateLoading,
    isDeleteLoading,
    error,
    accounts,
    create,
    getData,
    deleteData,
    migrationOwner,
    updateEmail,
  };
};

export const AccessManageContainer = createContainer(useAccessManageContainer);
