import React from 'react';
import {useTheme} from 'react-native-paper';
import StepIndicatorBase from 'react-native-step-indicator';
import {Colors} from '../../theme';

const labels = ['企業情報', '支払方法', '完了'];
const stepCount = labels.length;

export type RegisterStep = 'owner' | 'payment' | 'done';

function stepToNumber(step: RegisterStep): number {
  switch (step) {
    case 'owner':
      return 0;
    case 'payment':
      return 1;
    case 'done':
      return 2;
  }
  return -1;
}

type Props = Omit<React.ComponentProps<typeof StepIndicatorBase>, 'labels'> & {
  currentStep?: RegisterStep;
};

// Todo デザイン調整
export const StepIndicator: React.FC<Props> = (props) => {
  const {customStyles, currentPosition, currentStep, ...rest} = props;
  const {fonts, colors} = useTheme();
  const custom = Object.assign(
    {
      stepIndicatorSize: 16,
      currentStepIndicatorSize: 32,
      currentStepStrokeWidth: 8,
      stepStrokeCurrentColor: Colors.main,
      // stepStrokeFinishedColor: Colors.main,
      // stepStrokeUnFinishedColor: Colors.main,
      stepIndicatorFinishedColor: Colors.main,
      stepIndicatorUnFinishedColor: Colors.darkgray,
      // 横線
      separatorStrokeWidth: 8,
      separatorFinishedColor: Colors.main,
      separatorUnFinishedColor: Colors.darkgray,
      // ラベル系
      labelFontFamily: fonts.regular.fontFamily,
      labelColor: Colors.darkgray,
      currentStepLabelColor: colors.text,
      stepIndicatorLabelCurrentColor: Colors.white,
      stepIndicatorLabelFinishedColor: Colors.main,
      stepIndicatorLabelUnFinishedColor: Colors.darkgray,
    },
    customStyles,
  );
  const pos = currentStep ? stepToNumber(currentStep) : currentPosition;
  return (
    <StepIndicatorBase
      labels={labels}
      stepCount={stepCount}
      currentPosition={pos}
      customStyles={custom}
      {...rest}
    />
  );
};
