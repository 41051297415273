import {useCallback, useEffect, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSRepository} from '../../../../../_proto/services';
import {useAddrFetcher} from './AddrFetcher';
import {
  GoogleAddressDetail,
  GooglePlaceSuggest,
  GooglePlaceSuggestSession,
} from './types.def';

const TAG = 'AddrSuggestState';

type AddrSuggestState = {
  searchKey: string;
  updateSearchKey: (val: string) => void;

  selected: GooglePlaceSuggest | null;
  suggest: GooglePlaceSuggestSession | null;
  detail: GoogleAddressDetail | null;

  fetchSuggest: (val: string, session?: string) => void;
  selectSuggest: (val: GooglePlaceSuggest) => void;
};

export function useAddrSuggest(): AddrSuggestState {
  const [searchKey, setSearchKey] = useState('');
  const [selected, setSelected] = useState<GooglePlaceSuggest | null>(null);

  const {suggest, suggestTopic, detail, detailTopic, session, clear} =
    useAddrFetcher();

  const updateSearchKey = useCallback(
    (val: string) => {
      if (selected && selected.description !== val) {
        setSelected(null);
      }
      setSearchKey(val);
    },
    [selected],
  );

  const fetchSuggest = useCallback(
    async (key: string) => {
      const token = await SaaSRepository.fetchUserToken();
      suggestTopic.next({
        searchKey: key,
        token,
        session,
        // dryRun: true, // T.B.D
      });
    },
    [session, suggestTopic],
  );

  useEffect(() => {
    if (searchKey.length > 0) {
      fetchSuggest(searchKey);
    } else {
      clear();
    }
  }, [clear, fetchSuggest, searchKey]);

  const selectSuggest = useCallback(
    async (val: GooglePlaceSuggest) => {
      console.log(TAG, val);

      setSelected(val);
      const token = await SaaSRepository.fetchUserToken();
      detailTopic.next({
        id: val.placeId,
        token,
        session: session || 'session',
      });
    },
    [detailTopic, session],
  );

  return {
    searchKey,
    suggest,
    detail,
    selected,
    updateSearchKey,
    fetchSuggest,
    selectSuggest,
  };
}

export const AddrSuggestContext = createContainer(useAddrSuggest);
