const MockSuggest = {
  session: '592074a1-deae-4509-a8fb-108c136d63fb',
  suggest: [
    {
      description: '日本、東京都文京区白山５丁目３６−２ ３６−２',
      placeId: 'ChIJr6RR_LWNGGARAPubz2i1PO0',
    },
    {
      description: '日本、東京都文京区本郷７丁目３ ３号館',
      placeId: 'ChIJeV8sXzCMGGARTlc0We3OpfY',
    },
    {
      description: '日本、東京都文京区霞が関１丁目１ 東京都道301号',
      placeId:
        'EkPml6XmnKzjgIHmnbHkuqzpg73mlofkuqzljLrpnJ7jgYzplqLvvJHkuIHnm67vvJEg5p2x5Lqs6YO96YGTMzAx5Y-3Ii4qLAoUChIJEa0ryAyMGGAR9Z6xJk83VMYSFAoSCRG_A2jzixhgEcLESj7bHx2J',
    },
    {
      description: '日本、東京都文京区弥生１丁目１−１ ３号館',
      placeId: 'ChIJl54pMDKMGGARpYg7Jxf3NNY',
    },
    {
      description: '日本、東京都文京区本郷７丁目３ 情報学環・福武ホール',
      placeId: 'ChIJjTBGhzCMGGARq8qjX_R1jFc',
    },
  ],
};

export {MockSuggest};
