import {faRightLong} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Box,
  CircularProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import {ShopContainer} from '../../container';
import {Helpers} from '../../helper';
import {UserAnalyzeReport} from '../../service/report';

function showCell(num: number | undefined): string {
  return num === undefined ? '-' : Helpers.sepComma(num);
}

function percent(top: number | undefined, bottom: number | undefined) {
  if (!bottom || !top) {
    // 各値が0や数値じゃない場合は計算しない
    return 0;
  }
  const ratio = Math.round((10000 * top) / bottom) / 100;
  return ratio;
}

function getViewColor(rate: number, max: number, min: number) {
  const value = (rate - min) / (max - min);
  return `rgba(176,148,226,${value})`;
}

function getClickedUrlColor(rate: number, max: number, min: number) {
  const value = (rate - min) / (max - min);
  return `rgba(94,195,255,${value})`;
}

function getClickedVisitedColor(rate: number, max: number, min: number) {
  const value = (rate - min) / (max - min);
  return `rgba(255,164,94,${value})`;
}

export const DeliveryReportDemensionTable: React.FC<{
  reports: UserAnalyzeReport[];
  dataState: 'loading' | 'error' | 'exist' | 'nodata';
}> = ({reports, dataState}) => {
  const [selectedValue, setSelectedValue] = React.useState<string>('link');
  const {selected} = ShopContainer.useContainer();
  const allValue = reports[0];
  const rows = reports?.map(
    ({listCount, viewCount, clickedUrlCount, visited, ...rest}) => {
      const clickRate = percent(clickedUrlCount, viewCount);
      const viewRate = percent(viewCount, listCount);
      const visitedRate = percent(visited, viewCount);
      const list4AllRate = percent(listCount, allValue.listCount);
      const click4AllRate = percent(clickedUrlCount, allValue.clickedUrlCount);
      const view4AllRate = percent(viewCount, allValue.viewCount);
      const visited4AllRate = percent(visited, allValue.visited);
      return {
        listCount,
        viewCount,
        clickedUrlCount,
        visited,
        clickRate,
        viewRate,
        visitedRate,
        list4AllRate,
        click4AllRate,
        view4AllRate,
        visited4AllRate,
        ...rest,
      };
    },
  );

  const maxViewRate = rows.reduce((previousValue, currentValue) => {
    return Math.max(previousValue, currentValue.viewRate);
  }, 0);
  const minViewRate = rows.reduce((previousValue, currentValue) => {
    return Math.min(previousValue, currentValue.viewRate);
  }, 100);
  const maxClickRate = rows.reduce((previousValue, currentValue) => {
    return Math.max(previousValue, currentValue.clickRate);
  }, 0);
  const minClickRate = rows.reduce((previousValue, currentValue) => {
    return Math.min(previousValue, currentValue.clickRate);
  }, 100);
  const maxVisitedRate = rows.reduce((previousValue, currentValue) => {
    return Math.max(previousValue, currentValue.visitedRate);
  }, 0);
  const minVisitedRate = rows.reduce((previousValue, currentValue) => {
    return Math.min(previousValue, currentValue.visitedRate);
  }, 100);

  const isClickedMask =
    reports.length !== 0 &&
    allValue.clickedUrlCount !== undefined &&
    allValue.clickedUrlCount < 5;

  const isVisitedMask =
    reports.length !== 0 &&
    allValue.visited !== undefined &&
    allValue.visited < 5;

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: {value},
    } = event;
    setSelectedValue(value);
  };

  return (
    <>
      <Box p={1} sx={{bgcolor: 'white'}}>
        <Box p={1} />
        <TableContainer style={{width: '100%'}}>
          <Table sx={{}} size="small">
            <TableHead>
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    border: 0,
                  },
                }}
              >
                <TableCell />
                <TableCell align="center" colSpan={2}>
                  <Paper
                    sx={{
                      boxShadow: 'inherit',
                      border: '2px solid #707070',
                      borderRadius: 0,
                    }}
                  >
                    一覧表示
                  </Paper>
                </TableCell>
                <TableCell align="center">
                  <FontAwesomeIcon icon={faRightLong} />
                </TableCell>
                <TableCell align="center" colSpan={4}>
                  <Paper
                    sx={{
                      boxShadow: 'inherit',
                      border: '2px solid #7E57C2',
                      borderRadius: 0,
                    }}
                  >
                    広告閲覧
                  </Paper>
                </TableCell>
                <TableCell align="center">
                  <FontAwesomeIcon icon={faRightLong} />
                </TableCell>
                <TableCell align="center" colSpan={4}>
                  <Select
                    sx={{
                      width: '100%',
                      bgcolor: 'white',
                      padding: 0,
                      '& .MuiInputBase-input': {
                        padding: '0.5px 0',
                        fontSize: 14,
                        border:
                          selectedValue === 'link'
                            ? '2px solid #0076FF'
                            : '2px solid #FFA45E',
                        borderRadius: 0,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border:
                          selectedValue === 'link'
                            ? '2px solid #0076FF !important'
                            : '2px solid #FFA45E !important',
                      },
                    }}
                    defaultValue="link"
                    value={selectedValue}
                    onChange={handleChange}
                    inputProps={{
                      name: 'value',
                    }}
                  >
                    <MenuItem value={'link'}>リンククリック</MenuItem>
                    {selected && !selected.legalUrl && (
                      <MenuItem value={'visited'}>来店</MenuItem>
                    )}
                  </Select>
                </TableCell>
              </TableRow>
              <Box p={1} />
              <TableRow
                sx={{
                  '& .MuiTableCell-root': {
                    border: 0,
                    // borderTop: ' 1px solid rgba(224, 224, 224, 1)',
                  },
                }}
              >
                <TableCell />
                <TableCell align="center" colSpan={2}>
                  一覧表示人数(UU)
                </TableCell>
                <TableCell />
                <TableCell align="center">閲覧率</TableCell>
                <TableCell />
                <TableCell align="center" colSpan={2}>
                  広告閲覧人数(UU)
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  {selectedValue === 'link' ? 'クリック率' : '来店率'}
                </TableCell>
                <TableCell />
                <TableCell align="center" colSpan={2}>
                  {selectedValue === 'link'
                    ? 'クリック人数(UU)'
                    : '来店人数(UU)'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataState === 'loading' ? (
                <CircularProgress />
              ) : rows.length === 0 ? (
                <Typography>データがありません</Typography>
              ) : (
                rows.map((row, index) => (
                  <TableRow
                    sx={{
                      '& .MuiTableCell-root': {
                        border: 0,
                      },
                      '&:first-child td, &:first-child th': {
                        fontWeight: 'bold',
                        borderBottom: ' 1px solid rgba(0, 0, 0, 1)',
                      },
                    }}
                    key={row.label}
                    hover
                  >
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell align="right">
                      {showCell(row.listCount)}
                    </TableCell>
                    <TableCell align="right">
                      {index !== 0 && `${row.list4AllRate}%`}
                    </TableCell>
                    <TableCell align="right">→</TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        bgcolor: getViewColor(
                          row.viewRate,
                          maxViewRate,
                          minViewRate,
                        ),
                      }}
                    >
                      {`${row.viewRate}%`}
                    </TableCell>
                    <TableCell align="right">→</TableCell>
                    <TableCell align="right">
                      {showCell(row.viewCount)}
                    </TableCell>
                    <TableCell align="right">
                      {index !== 0 && `${row.view4AllRate}%`}
                    </TableCell>
                    <TableCell align="right">→</TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        bgcolor:
                          selectedValue === 'link'
                            ? getClickedUrlColor(
                                row.clickRate,
                                maxClickRate,
                                minClickRate,
                              )
                            : getClickedVisitedColor(
                                row.visitedRate,
                                maxVisitedRate,
                                minVisitedRate,
                              ),
                      }}
                    >
                      {`${
                        selectedValue === 'link'
                          ? row.clickRate
                          : row.visitedRate
                      }%`}
                    </TableCell>
                    <TableCell align="right">→</TableCell>
                    {selectedValue === 'link' && (
                      <TableCell align="right">
                        {isClickedMask ? '-' : showCell(row.clickedUrlCount)}
                      </TableCell>
                    )}
                    {selectedValue === 'visited' && (
                      <TableCell align="right">
                        {isVisitedMask ? '-' : showCell(row.visited)}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      {index !== 0
                        ? selectedValue === 'link'
                          ? isClickedMask
                            ? '-'
                            : `${row.click4AllRate}%`
                          : isVisitedMask
                          ? '-'
                          : `${row.visited4AllRate}%`
                        : ''}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isClickedMask && (
        <Typography color="error" variant="subtitle2">
          リンククリック人数が5人未満のため、分析結果を表示できません。
        </Typography>
      )}
      {isVisitedMask && (
        <Typography color="error" variant="subtitle2">
          来店人数が5人未満のため、分析結果を表示できません。
        </Typography>
      )}
    </>
  );
};
