import dayjs from 'dayjs';
import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {ShopContainer} from '../../container';
import {DeliveryDailyReport} from '../../service';
import {Colors} from '../../theme';
import {DataArea, Overlay, Text} from '../Elements';

export const DeliveryReportGraph: React.FC<{
  reports?: DeliveryDailyReport[];
}> = ({reports}) => {
  const {selected} = ShopContainer.useContainer();
  const isEmpty = !reports || reports.length === 0;
  reports = reports?.map(({stamp, ...rest}) => {
    const day = dayjs(stamp);
    return {
      stamp: day.isValid() ? day.format('MM/DD(ddd)') : stamp,
      ...rest,
    };
  });
  // Todo 横スクロール実装
  // 案 要素数に応じて横幅を決めてしまえば多分できる。
  return (
    <DataArea>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          data={reports}
          margin={{
            top: 16,
            bottom: 8,
            left: -16,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="stamp" fontSize={10} />
          <YAxis yAxisId="left" fontSize={10} />
          <YAxis yAxisId="right" fontSize={10} orientation="right" />
          <Tooltip />
          <Legend />
          <Bar
            yAxisId="right"
            dataKey="clickedUrlCount"
            fill={'#0076FF'}
            name="リンククリック"
          />
          <Bar
            yAxisId="right"
            dataKey="clickedPhoneCount"
            fill={'#09E809'}
            name="電話クリック"
          />
          {!selected?.legalUrl && (
            <Bar
              yAxisId="right"
              dataKey="visited"
              fill={Colors.accent}
              name="来店"
            />
          )}
          <Line
            yAxisId="left"
            type="monotone"
            name="一覧表示"
            dataKey="listCount"
            stroke={'#3C3648'}
            activeDot={{r: 8}}
          />
          <Line
            yAxisId="left"
            type="monotone"
            name="閲覧回数"
            dataKey="viewCount"
            stroke={'red'}
            activeDot={{r: 8}}
          />
          <Line
            yAxisId="left"
            type="monotone"
            name="閲覧人数"
            dataKey="billCount"
            stroke={Colors.main}
            activeDot={{r: 8}}
          />
          <Line
            yAxisId="left"
            type="monotone"
            name="保存"
            dataKey="keptCount"
            stroke={'#C3ABEB'}
            activeDot={{r: 8}}
          />
          <Line
            yAxisId="left"
            type="monotone"
            name="利用"
            dataKey="usedCount"
            stroke={'#FFC107'}
            activeDot={{r: 8}}
          />
        </ComposedChart>
      </ResponsiveContainer>
      {isEmpty && (
        <Overlay>
          <Text>データがありません。</Text>
        </Overlay>
      )}
    </DataArea>
  );
};
