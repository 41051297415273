import {View} from 'react-native';
import React from 'react';
import {DeliveryDetail} from '../../service';
import {Text, VMargin} from '../Elements';
import {TargetsPreview} from '../TargetsPreview';

export const DeliveryReportCreativePreview: React.FC<{
  target: DeliveryDetail['target'];
}> = ({target}) => {
  const summary = {
    title: target.title ?? undefined,
    users: target.users ? Number(target.users) : undefined,
  };
  return (
    <View>
      <Text>配信先</Text>
      <VMargin />
      <TargetsPreview.Summary target={summary} />
    </View>
  );
};
