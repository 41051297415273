import {CognitoUser} from 'amazon-cognito-identity-js';
import {Auth} from 'aws-amplify';
import {useCallback, useRef, useState} from 'react';
import {createContainer} from 'unstated-next';
import {AuthContainer, UserInfoContainer} from '../../container';
import {ERROR_MSG} from '../../helper';
import {MFAFormData, PhoneFormData, SignInFormData} from './schema';

const TAG = '[SignInContainer]';

export type SignInStep =
  | 'init'
  | 'try'
  | 'mfa'
  | 'challenging'
  | 'changePhone'
  | 'reSignUp'
  | 'reSignUpChallenging'
  | 'done'
  | 'reSignUpDone';

type UseSignIn = {
  step: SignInStep;
  error?: Error;
  phoneNumber: string;
  signIn(data: SignInFormData): void;
  challengeMFA(data: MFAFormData, mode: string): void;
  changePhone(): void;
  changePhoneNumber(data: PhoneFormData): void;
  reSendVerificationCode(): void;
  prevRoute: {name: string; params: any} | undefined;
  setRoute(route: {name: string; params: any} | undefined): void;
};

function useSignIn(): UseSignIn {
  const [step, setStep] = useState<SignInStep>('init');
  const [error, setError] = useState<Error | undefined>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const userRef = useRef<CognitoUser | any>();
  const {checkAuthUser} = AuthContainer.useContainer();
  const {fetchUserInfo} = UserInfoContainer.useContainer();
  const [prevRoute, setPrevRoute] = useState<
    {name: string; params: any} | undefined
  >();
  const signIn = useCallback(async (data: SignInFormData) => {
    setError(undefined);
    setStep('try');
    try {
      const user = await Auth.signIn(data.email, data.password);
      console.log(user);
      // 確認コード再送のため保存
      setEmail(data.email);
      setPassword(data.password);
      userRef.current = user;
      const {challengeName} = user;
      if (!challengeName) {
        // 強制的にMFAを設定（TYPE:SMS）
        await Auth.setPreferredMFA(user, 'SMS');
        setStep('done');
        console.log(TAG, 'SignIn succeed.');
      } else {
        setPhoneNumber(user.challengeParam.CODE_DELIVERY_DESTINATION);
        setStep('mfa');
        console.log(TAG, 'Need MFA.');
      }
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        // ステータスがunconfirmedの場合は再度サインアップさせる
        setStep('reSignUp');
        const res = await Auth.resendSignUp(data.email);
        // サインアップ、確認コード再送のため保存
        setEmail(data.email);
        setPassword(data.password);
        setPhoneNumber(res.CodeDeliveryDetails.Destination);
      } else {
        setStep('init');
        setError(err);
      }
    }
    return;
  }, []);

  const challengeMFA = useCallback(
    async (data: MFAFormData, mode: string) => {
      setError(undefined);
      if (mode === 'signIn') {
        // サインインフロー
        setStep('challenging');
        const user = userRef.current;
        if (!user) {
          setError(Error(ERROR_MSG.signIn.other));
          return;
        }
        try {
          await Auth.confirmSignIn(
            user,
            data.verificationCode,
            user?.challengeName,
          );
          await checkAuthUser();
          await fetchUserInfo(user.username);
          setStep('done');
        } catch (err: any) {
          setError(err);
          // 失敗時は状態を戻す
          setStep('mfa');
        }
      } else {
        try {
          // サインアップフロー
          setStep('reSignUpChallenging');
          await Auth.confirmSignUp(email, data.verificationCode);
          // 自動サインイン
          const result = await Auth.signIn(email, password);
          console.log(TAG, 'SignIn result.', result);
          // 強制的にMFAを設定（TYPE:SMS）
          await Auth.setPreferredMFA(result, 'SMS');
          setStep('reSignUpDone');
          console.log(TAG, 'SignIn succeed.');
        } catch (err: any) {
          setError(err);
          // 失敗時は状態を戻す
          setStep('reSignUp');
        }
      }
    },
    [email, password, checkAuthUser, fetchUserInfo],
  );

  const reSendVerificationCode = useCallback(async () => {
    if (email && password) {
      const result = await Auth.signIn(email, password);
      console.log(TAG, 'SignIn result.', result);
    }
  }, [email, password]);

  const changePhone = useCallback(() => {
    setStep('changePhone');
  }, []);

  const changePhoneNumber = useCallback(async (data: PhoneFormData) => {
    console.log(data);
    const user = userRef.current;
    console.log('user', user);
    const result = await Auth.updateUserAttributes(user, {
      phone_number: '+17608752114',
    });
    console.log(result);
  }, []);

  const setRoute = useCallback((route: {name: string; params: any}) => {
    setPrevRoute(route);
  }, []);

  return {
    step,
    error,
    phoneNumber,
    signIn,
    challengeMFA,
    changePhone,
    changePhoneNumber,
    reSendVerificationCode,
    prevRoute,
    setRoute,
  };
}

export const SignInContainer = createContainer(useSignIn);
