// import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {Container} from '../../components/Elements';
import {TargetForms} from '../../components/Targets';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {useNavigation, useRoute} from '@react-navigation/native';

type NProp = MainNavigationProp<'TargetsCreate'>;
type RProp = MainRouteProp<'TargetsCreate'>;

export const Create: React.FC = React.memo(() => {
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const from = route.params?.from;
  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      if (from === 'CreativesCreate') {
        navigation.navigate('CreativesCreate');
      } else {
        navigation.navigate('TargetsMain');
      }
    }
  };

  return (
    <Container notScroll>
      <TargetForms
        from={from}
        onBack={goBack}
        onDone={(type, result) => {
          switch (type) {
            case 'fixed':
              navigation.navigate('TargetsMain');
              return;
            case 'fixAndGo':
              navigation.navigate('CreativesCreate', {targetId: result.id});
              return;
          }
        }}
      />
    </Container>
  );
});
