import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {EditState, SaaSCoupon} from '../../API';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {OwnerContainer, ShopContainer} from '../../container';
import {UseBarcode, UseImages, useBarcode, useImages} from '../../helper';
import {FormData} from './schema';
import {convert, save} from './service';

type UseCouponCreator = UseImages &
  UseBarcode & {
    saved?: SaaSCoupon;
    submit(
      data: FormData,
      editState: EditState,
      shopId?: string,
    ): Promise<SaaSCoupon>;
  };

function useCouponCreator(initialState?: {
  images: SaaSImageContents[] | undefined;
  barcode?: string;
  forceState?: EditState;
}): UseCouponCreator {
  const {selected} = ShopContainer.useContainer();
  const {images, addImage, removeImage, changeImage} = useImages(
    initialState?.images,
  );
  const {barcode, addBarcode, removeBarcode} = useBarcode(
    initialState?.barcode,
  );
  const [saved, setSaved] = useState<SaaSCoupon | undefined>();
  const {owner} = OwnerContainer.useContainer();

  const submit = useCallback<
    (
      data: FormData,
      editState: EditState,
      shopId?: string,
    ) => Promise<SaaSCoupon>
  >(
    async (data, editState, shopId) => {
      if (!selected) {
        throw new Error('selected is undefined');
      }
      const edit = initialState?.forceState || editState;
      const def = convert(
        data,
        edit,
        shopId ? shopId : selected?.id || '',
        owner?.id || '',
      );
      const response = await save({def, images, barcode}, data.id);
      // 成功したら結果は保持しておく（上書きの際など必要）
      setSaved(response.data);
      console.log('[coupon create] setSave', response.data);
      return response.data;
    },
    [selected, initialState?.forceState, owner?.id, images, barcode],
  );

  return {
    images,
    addImage,
    removeImage,
    changeImage,
    barcode,
    addBarcode,
    removeBarcode,
    saved,
    submit,
  };
}

export const CouponCreator = createContainer(useCouponCreator);
