import API, {graphqlOperation, GraphQLResult} from '@aws-amplify/api';
import {GRAPHQL_AUTH_MODE} from '@aws-amplify/auth';
import {Auth} from 'aws-amplify';
import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil,
} from 'google-libphonenumber';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSRepository} from '../../_proto/services/SaaSRepository';
import {AcceptAccessManageMutation, AccessManageStatus} from '../../API';
import {AuthContainer, UserInfoContainer} from '../../container';
import {acceptAccessManage} from '../../graphql/mutations';
import {ERROR_MSG} from '../../helper';
import {InviteContainer} from './InviteContainer';
import {MFAFormData, PhoneFormData, SignUpFormData} from './schema';

const TAG = '[SignUpContainer]';

// Todo 型をちゃんと設定する
type Params = any;
type User = any;

export type SignUpStep =
  | 'init' // 初期状態
  | 'confirm' // パラメータチェック中
  | 'input' // アカウント情報入力中
  | 'signup' // AuthへのSignUp/SignIn処理中
  | 'signupConfirm' // MFA
  | 'signupChallenging' // 確認コードチェック
  | 'mfa' // MFA
  | 'challenging' // 確認コードチェック
  | 'changePhone' // 電話番号の変更 Todo
  | 'done'; // 処理完了

type RegistrationInfo = {
  email?: string;
  params?: Params;
  user?: User;
};

type State = {step: SignUpStep; info: RegistrationInfo};

type UseSignUp = {
  // 状態
  step: SignUpStep;
  error?: Error | (Error & {code: string});
  phoneNumber: string;
  // 処理
  parseURL(prams: Params): void; // 登録用URLの解釈
  signUp(data: SignUpFormData): void; // Cognitoへの登録
  signupConfirm(code: MFAFormData): void; // 確認コード検証（サインアップ検証）
  changePhone(): void; // 電話番号の変更画面へ
  changePhoneNumber(data: PhoneFormData): void;
  reSendVerificationCode(): void;
  email: string | undefined;
  // clearError(): void; // エラーのクリア
};

// 本コンテナではstepが進むごとにデータを追加していく
function updateState(
  step: SignUpStep,
  addInfo: RegistrationInfo = {},
): (prevState: State) => State {
  return (prevState: State) => ({step, info: {...prevState.info, ...addInfo}});
}

// 各種登録処理コンテナー
function useSignUp(): UseSignUp {
  // const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [state, setState] = useState<State>({step: 'init', info: {}});
  const [password, setPassword] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const {invite} = InviteContainer.useContainer();
  const [agentCode, setAgentCode] = useState<string | undefined>();

  const {checkAuthUser} = AuthContainer.useContainer();
  const {fetchUserInfo} = UserInfoContainer.useContainer();

  const parseURL = useCallback(async (params: Params) => {
    setState({step: 'confirm', info: {params: params}});
    try {
      if (params.agency) {
        console.log(TAG, 'agency', params.agency);
        setAgentCode(params.agency);
      }
      const result = await confirmURL(params);
      setState(updateState('input', {email: result.email}));
    } catch (err: any) {
      setError(err);
    }
  }, []);

  const signUp = useCallback(
    async (data: SignUpFormData) => {
      setError(undefined);
      if (!state.info.email || !state.info.params) {
        console.log(state.info);
        // Todo エラー内容
        return setError(Error(ERROR_MSG.signUp.other));
      }

      try {
        setState(updateState('signup'));

        const signup = await Auth.signUp({
          username: state.info.email,
          password: data.password,
          attributes: {
            email: state.info.email,
            phone_number: toE164(data.phone),
            'custom:family_name': data.familyName,
            'custom:given_name': data.givenName,
          },
          validationData: state.info.params,
          clientMetadata: {
            agentCode: agentCode || '',
          },
        });
        console.log(signup);
        console.log(TAG, 'SignUp succeed.');
        if (invite) {
          const input = {
            id: invite.id,
            userId: signup.userSub,
            status: AccessManageStatus.CONFIRM,
          };
          const result = (await API.graphql({
            ...graphqlOperation(acceptAccessManage, {input}),
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
          })) as GraphQLResult<AcceptAccessManageMutation>;
          console.log(result);
          if (!result.data) {
            throw new Error('[acceptAccessManage] result.data is undefined');
          }
        }
        // 自動サインインのため保存
        setPassword(data.password);
        setPhoneNumber(data.phone);
        setState(updateState('signupConfirm'));
      } catch (err: any) {
        setState(updateState('input'));
        console.log(err);
        // Todo リトライの仕組みとか
        setError(err);
      }
    },
    [state.info, agentCode, invite],
  );

  const signupConfirm = useCallback(
    async (data: MFAFormData) => {
      setError(undefined);
      setState(updateState('signupChallenging'));
      try {
        // サインアップ検証
        await Auth.confirmSignUp(
          state.info.email as string,
          data.verificationCode,
          {
            clientMetadata: {
              agentCode: agentCode || '',
            },
          },
        );
      } catch (err: any) {
        setError(err);
        // 失敗時は状態を戻す
        setState(updateState('signupConfirm'));
      }
      if (invite) {
        const input = {
          id: invite.id,
          status: AccessManageStatus.DONE,
        };
        const result = (await API.graphql({
          ...graphqlOperation(acceptAccessManage, {input}),
          authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        })) as GraphQLResult<AcceptAccessManageMutation>;
        console.log(result);
        if (!result.data) {
          throw new Error('[acceptAccessManage] result.data is undefined');
        }
      }
      // 自動サインイン
      const result = await Auth.signIn(state.info.email as string, password);
      console.log(TAG, 'SignIn result.', result);
      // 強制的にMFAを設定（TYPE:SMS）
      await Auth.setPreferredMFA(result, 'SMS');
      await checkAuthUser();
      await fetchUserInfo(result.username);
      setState(updateState('done'));
      console.log(TAG, 'SignIn succeed.');
    },
    [
      state.info.email,
      password,
      checkAuthUser,
      fetchUserInfo,
      agentCode,
      invite,
    ],
  );

  const reSendVerificationCode = useCallback(async () => {
    if (state.info.email) {
      const result = await Auth.resendSignUp(state.info.email);
      console.log(result);
    }
  }, [state]);

  const changePhone = useCallback(() => {
    setState(updateState('changePhone'));
  }, []);

  const changePhoneNumber = useCallback(async (data: PhoneFormData) => {
    console.log(data);
    const user = await Auth.currentAuthenticatedUser();
    console.log('user', user);
  }, []);

  return {
    step: state.step,
    error,
    phoneNumber,
    parseURL,
    signUp,
    signupConfirm,
    changePhone,
    changePhoneNumber,
    reSendVerificationCode,
    email: state.info.email,
    // clearError,
  };
}

async function confirmURL(params: Params): Promise<{email: string}> {
  return await SaaSRepository.confirmPreRegist(params);
}

// 電話番号の形式をAuthに合わせる
function toE164(phone: string): string {
  const util = PhoneNumberUtil.getInstance();
  const phoneNumber: PhoneNumber = util.parseAndKeepRawInput(phone, 'JP');
  return util.format(phoneNumber, PhoneNumberFormat.E164);
}

export const SignUpContainer = createContainer(useSignUp);
