import {yupResolver} from '@hookform/resolvers/yup';
import {Clear, Search} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import React from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {Colors} from '../../../../theme';
import {
  ListSelector,
  Text,
  TrimaLoadingButton,
  VMargin,
} from '../../../Elements';
import {CitySearchContainer} from '../../CitySearchContainer';
import {TargetingContainer} from '../../TargetingContainer';
import {PrefectureContainer} from './PrefectureContainer';
import {regions} from './def';

type Region = {
  id: string;
  name: string;
  prefs: Array<Pref>;
};

type Pref = {
  prefCode: string;
  prefName: string;
  cities?: Array<City>;
};

type City = {
  id: string;
  cityName: string;
  prefCode: string;
  prefName: string;
};

type SearchListItem = {
  id: string;
  name: string;
  prefs: Array<{
    prefCode: string;
    prefName: string;
    cities: Array<{
      id: string;
      cityName: string;
      prefCode: string;
      prefName: string;
    }>;
  }>;
};

const schema = yup
  .object({
    word: yup.string().required('必須の項目です'),
  })
  .required();

const PrefectureSelector: React.FC = React.memo(() => {
  const {search, list, word, isLoading, isClear, clearSearch} =
    CitySearchContainer.useContainer();
  const {
    control,
    formState: {errors, isDirty},
    handleSubmit,
    reset,
  } = useForm<{
    word: string;
  }>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<{
    word: string;
  }> = async (data) => {
    await search(data);
  };
  const onSubmitError = () => true;
  const processing = isLoading || !isDirty;
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={9} sm={10}>
            <FormControl
              required
              error={'word' in errors}
              component="fieldset"
              fullWidth
            >
              <Controller
                name="word"
                control={control}
                rules={{
                  required: '入力してください',
                }}
                render={({field}) => (
                  <TextField
                    {...field}
                    margin="dense"
                    required
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            clearSearch();
                            reset({
                              word: '',
                            });
                          }}
                        >
                          <Clear />
                        </IconButton>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={1}>
            <Box pl={2}>
              <TrimaLoadingButton
                onClick={handleSubmit(search, onSubmitError)}
                type="submit"
                variant="contained"
                disabled={processing}
                loading={isLoading}
              >
                <Search />
              </TrimaLoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
      <Box m={2} />
      {list.length === 0 && word && !isLoading && !isClear && (
        <div>
          <Text>
            「{word}
            」の検索結果はありません
          </Text>
          <VMargin />
        </div>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {isLoading && <CircularProgress color="primary" />}
      </Grid>
      {list.length > 0 && (
        <ListSelector.List
          component="nav"
          aria-labelledby="nested-list-subheader"
          disablePadding
        >
          {list.map((region) => {
            return region.id === '0' || region.id === '8' ? (
              <PrefItem
                sx={{
                  height: 40,
                }}
                region={region}
                prefecture={region.prefs[0]}
                search={true}
              />
            ) : (
              <Region region={region} search={true} />
            );
          })}
        </ListSelector.List>
      )}
      {!isLoading && list.length === 0 && (
        <ListSelector.List
          component="nav"
          aria-labelledby="nested-list-subheader"
          disablePadding
        >
          {regions.map((region) => {
            return region.id === '0' || region.id === '8' ? (
              <PrefItem
                sx={{
                  height: 40,
                }}
                region={region}
                prefecture={region.prefs[0]}
                search={false}
              />
            ) : (
              <Region region={region} search={false} />
            );
          })}
        </ListSelector.List>
      )}
    </>
  );
});

// 地域
const Region: React.FC<{region: Region; search: boolean}> = ({
  region,
  search,
}) => {
  const {
    expand,
    expandItem,
    selectedRegionList,
    addSelectedRegion,
    removeSelectedRegion,
    selectedPrefList,
    selectedCityList,
    addSelectedCities,
  } = PrefectureContainer.useContainer();
  const {targetingUserCount} = TargetingContainer.useContainer();
  const status =
    targetingUserCount === 'calculating' &&
    selectedRegionList.some((selectedRegion) => selectedRegion.id === region.id)
      ? 'loading'
      : selectedPrefList.some((selected) => region.id === selected.id) ||
        selectedCityList.some((selected) =>
          region.prefs.some((pref) => pref.prefCode === selected.prefCode),
        )
      ? 'indeterminate'
      : selectedRegionList.some((selected) => selected.id === region.id)
      ? 'checked'
      : 'unchecked';
  const onClick = () => expandItem({region, prefecture: undefined});
  const onIconClick = async () => {
    if (search) {
      if (status === 'unchecked') {
        addSelectedCities(region.prefs.map((pref) => pref.cities)[0]);
      }
    } else {
      if (status === 'unchecked') {
        addSelectedRegion({...region});
      } else {
        removeSelectedRegion({...region});
      }
    }
  };
  return (
    <ListSelector.Item
      sxListItemButton={{
        height: 40,
      }}
      label={region.name}
      status={status}
      open={expand.region === region || search}
      onClick={onClick}
      onIconClick={onIconClick}
    >
      {region.prefs.map((prefecture) => {
        return (
          <PrefItem
            region={region}
            prefecture={prefecture}
            sx={{
              height: 40,
              pl: 4,
              backgroundColor: Colors.base,
            }}
            divider
            search={search}
          />
        );
      })}
    </ListSelector.Item>
  );
};

// 都道府県リストアイテム
const PrefItem: React.FC<{
  region: Region | SearchListItem;
  prefecture: Pref;
  sx?: any;
  divider?: boolean;
  search: boolean;
}> = ({region, prefecture, sx, divider, search}) => {
  const [cities, setCities] = React.useState<Array<City>>([]);
  const {
    expand,
    expandItem,
    fetchCities,
    selectedPrefList,
    addSelectedPref,
    removeSelectedPref,
    selectedCityList,
    removeSelectedCities,
    selectedRegionList,
    addSelectedPrefs,
    removeSelectedRegion,
    addSelectedRegion,
    removeSelectedPrefs,
    addSelectedCities,
  } = PrefectureContainer.useContainer();
  const {targetingUserCount} = TargetingContainer.useContainer();
  React.useEffect(() => {
    if (search && prefecture.cities) {
      setCities(
        prefecture.cities.map((city) => {
          return {...city, prefName: city.prefName};
        }),
      );
    }
  }, [search, prefecture]);
  const status =
    targetingUserCount === 'calculating' &&
    selectedPrefList.some(
      (selectedPref) => selectedPref.prefCode === prefecture.prefCode,
    )
      ? 'loading'
      : selectedRegionList.some((selected) => selected.id === region.id) ||
        selectedPrefList.some(
          (selected) => selected.prefCode === prefecture.prefCode,
        )
      ? 'checked'
      : selectedCityList.some(
          (selected) => selected.prefCode === prefecture.prefCode,
        )
      ? 'indeterminate'
      : 'unchecked';
  const onClick = async () => {
    expandItem({region, prefecture});
    if (!search) {
      const res = await fetchCities(prefecture.prefCode);
      setCities(res?.items as City[]);
    }
  };
  const onIconClick = async () => {
    if (search) {
      if (status === 'unchecked') {
        addSelectedCities(cities);
      }
    } else {
      if (selectedRegionList.some((selected) => selected.id === region.id)) {
        // リージョン内都道府県全選択→ひとつチェックを外す
        const list = regions
          .filter((item) => item.id === region.id)[0]
          .prefs.filter((item) => item.prefCode !== prefecture.prefCode)
          .map((item) => {
            return {...region, ...item};
          });
        addSelectedPrefs(list);
        removeSelectedRegion({...region});
      } else if (status === 'unchecked' || status === 'indeterminate') {
        const list = [...selectedPrefList, {...region, ...prefecture}];
        const sortedPrefList = _.sortBy(
          list.filter((item) => item.id === region.id),
          'prefCode',
        ).map((item) => item.prefCode);
        const sortedDefPrefList = _.sortBy(
          regions.filter((item) => item.id === region.id)[0].prefs,
          'prefCode',
        ).map((item) => item.prefCode);
        const isEqual =
          JSON.stringify(sortedPrefList) === JSON.stringify(sortedDefPrefList);
        if (isEqual && region.id !== '0' && region.id !== '8') {
          // リージョン内都道府県全選択になる場合
          addSelectedRegion({...region});
          removeSelectedPrefs(
            regions.filter((item) => item.id === region.id)[0].prefs,
          );
        } else {
          addSelectedPref({...region, ...prefecture});
          if (cities.length > 0) {
            removeSelectedCities(cities);
          }
        }
      } else {
        removeSelectedPref({...region, ...prefecture});
      }
    }
  };

  return (
    <ListSelector.Item
      label={prefecture.prefName}
      status={status}
      open={expand.prefecture === prefecture || search}
      onClick={onClick}
      onIconClick={onIconClick}
      sxListItemButton={sx}
      divider={divider}
    >
      {cities.length > 0 ? (
        cities.map((city) => {
          return (
            <CityItem
              region={region}
              prefecture={prefecture}
              city={city}
              cities={cities}
              sx={{
                height: 40,
                pl: 6,
                backgroundColor: Colors.white,
              }}
              divider
              search={search}
            />
          );
        })
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={16} />
        </Grid>
      )}
    </ListSelector.Item>
  );
};

const CityItem: React.FC<{
  region: Region;
  prefecture: Pref;
  city: City;
  cities: Array<City>;
  sx?: any;
  divider?: boolean;
  search: boolean;
}> = ({region, prefecture, city, cities, sx, divider, search}) => {
  const {
    selectedCityList,
    addSelectedCity,
    addSelectedCities,
    removeSelectedCity,
    selectedPrefList,
    removeSelectedPref,
    addSelectedPref,
    removeSelectedCities,
    selectedRegionList,
    removeSelectedRegion,
    addSelectedPrefs,
  } = PrefectureContainer.useContainer();
  const {targetingUserCount} = TargetingContainer.useContainer();
  const status =
    targetingUserCount === 'calculating' &&
    selectedCityList.some((selectedCity) => selectedCity.id === city.id)
      ? 'loading'
      : selectedRegionList.some((selected) => selected.id === region.id) ||
        selectedPrefList.some(
          (selected) => selected.prefCode === prefecture.prefCode,
        ) ||
        selectedCityList.some((selected) => selected.id === city.id)
      ? 'checked'
      : 'unchecked';

  const onClick = async () => {
    if (search) {
      if (status === 'unchecked') {
        addSelectedCity({...prefecture, ...city});
      } else {
        if (
          selectedPrefList.some(
            (selected) => selected.prefCode === prefecture.prefCode,
          )
        ) {
          // 都道府県内市区町村全選択→ひとつチェックを外す
          const list = cities
            .filter((item) => item.id !== city.id)
            .map((item) => {
              return {...prefecture, ...item};
            });
          addSelectedCities(list);
          removeSelectedPref({...region, ...prefecture});
        } else {
          removeSelectedCity({...prefecture, ...city});
        }
      }
    } else {
      if (selectedRegionList.some((selected) => selected.id === region.id)) {
        // リージョン内市区町村全選択→ひとつチェックを外す
        const list = cities
          .filter((item) => item.id !== city.id)
          .map((item) => {
            return {...prefecture, ...item};
          });
        const prefList = regions
          .filter((item) => item.id === region.id)[0]
          .prefs.filter((item) => item.prefCode !== prefecture.prefCode)
          .map((item) => {
            return {...region, ...item};
          });
        addSelectedCities(list);
        addSelectedPrefs(prefList);
        removeSelectedRegion({...region});
      } else if (
        selectedPrefList.some(
          (selected) => selected.prefCode === prefecture.prefCode,
        )
      ) {
        // 都道府県内市区町村全選択→ひとつチェックを外す
        const list = cities
          .filter((item) => item.id !== city.id)
          .map((item) => {
            return {...prefecture, ...item};
          });
        addSelectedCities(list);
        removeSelectedPref({...region, ...prefecture});
      } else if (status === 'unchecked') {
        const list = [...selectedCityList, {...prefecture, ...city}];
        const sortedIdList = _.sortBy(
          list.filter((item) => item.prefCode === city.prefCode),
          'id',
        ).map((item) => item.id);
        const isEqual =
          JSON.stringify(sortedIdList) ===
          JSON.stringify(cities.map((item) => item.id));
        if (isEqual) {
          // 都道府県内市区町村全選択になる場合
          removeSelectedCities(cities);
          addSelectedPref({...region, ...prefecture});
        } else {
          addSelectedCity({...prefecture, ...city});
        }
      } else {
        removeSelectedCity({...prefecture, ...city});
      }
    }
  };
  return (
    <ListSelector.Item
      label={city.cityName}
      status={status}
      onClick={onClick}
      sxListItemButton={sx}
      divider={divider}
    />
  );
};

export default PrefectureSelector;
