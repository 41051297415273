import { yupResolver } from '@hookform/resolvers/yup';
import { Clear, Search } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ActivityIndicator } from 'react-native-paper';
import * as yup from 'yup';
import {
  SearchLine,
  SearchStation,
  StationCategory,
} from '../../../../_proto/services/SaaSTargetRepository';
import { Colors } from '../../../../theme';
import {
  ListSelector,
  Text,
  TrimaLoadingButton,
  VMargin,
} from '../../../Elements';
import { StationCacheContainer } from '../../StationCacheContainer';
import { StationSearchContainer } from '../../StationSearchContainer';
import { MasterCode, Station, StationCode, TrainLine } from '../../types';
import { LevelSet, StationUsersContainer } from './StationUsersContainer';
import { StationSearchFormData } from './schema';

export const StationSelector: React.FC = () => {
  return (
    <ListSelector.List component="nav" aria-labelledby="nested-list-subheader">
      <CategoryList />
    </ListSelector.List>
  );
};

// 各アイテムの表示アイコン判定
function useItemStatus(
  level: keyof LevelSet,
  id: StationCode | MasterCode,
): React.ComponentProps<typeof ListSelector.Item>['status'] {
  const {checkStatus} = StationUsersContainer.useContainer();
  return checkStatus.loading[level]?.has(id)
    ? 'loading'
    : checkStatus.indeterminate[level]?.has(id)
    ? 'indeterminate'
    : !checkStatus.checked[level]?.has(id)
    ? level === 'category'
      ? 'none' // 鉄道区分階層はチェック機能を持たせないので、空にする
      : 'unchecked'
    : 'checked';
}

// リスト読み込み中表示用
const ListLoading: React.FC = () => <ActivityIndicator size={16} />;

// 鉄道区分or路線リストアイテム（下層リストの開閉機能つき）
const ExpandListItem: React.FC<{
  label: string;
  level: keyof LevelSet;
  id: StationCode;
  search: boolean;
  children: React.ReactNode;
}> = ({label, level, id, children, search}) => {
  const {expand, expandItem, selectTrainLine, selectStation} =
    StationUsersContainer.useContainer();
  const {list} = StationSearchContainer.useContainer();
  const status = useItemStatus(level, id);
  const itemProp =
    level === 'category'
      ? {
          open: expand.category === id || search,
          sxListItemButton: {
            height: 40,
          },
          checkDisabled: true,
          onIconClick: undefined,
        }
      : {
          open: expand.trainLine === id || search,
          sxListItemButton: {
            height: 40,
            pl: 4,
            backgroundColor: Colors.base,
          },
          checkDisabled: false,
          onIconClick: () => {
            search
              ? list.forEach((category) => {
                  category.lines
                    .filter((line: SearchLine) => line.id === id)
                    .forEach((line: SearchLine) => {
                      line.stations.forEach((station: SearchStation) =>
                        selectStation(
                          station.id,
                          status === 'checked' ? 'uncheck' : 'check',
                        ),
                      );
                    });
                })
              : selectTrainLine(id, status === 'checked' ? 'uncheck' : 'check');
          },
        };
  return (
    <ListSelector.Item
      {...itemProp}
      label={label}
      status={status}
      onClick={() => expandItem(id)}>
      {children}
    </ListSelector.Item>
  );
};

const schema = yup
  .object({
    word: yup.string().required('必須の項目です'),
  })
  .required();

// 鉄道区分リスト
const CategoryList: React.FC = () => {
  const {categories, fetchCategories} = StationCacheContainer.useContainer();
  const {search, list, word, isLoading, isClear, clear} =
    StationSearchContainer.useContainer();
  const {
    control,
    formState: {errors},
    handleSubmit,
    reset,
  } = useForm<StationSearchFormData>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<StationSearchFormData> = async (data) => {
    await search(data);
  };
  const onSubmitError = () => true;
  const processing = isLoading || !!errors.word;

  React.useEffect(() => {
    fetchCategories();
    clear();
  }, [fetchCategories, clear]);

  if (!categories || categories === 'loading') {
    return <ListLoading />;
  }
  if (categories === 'error') {
    return <Text>リスト取得エラー</Text>;
  }
  return (
    <>
      <div>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={9} sm={10}>
              <FormControl
                required
                error={'word' in errors}
                component="fieldset"
                fullWidth>
                <Controller
                  name="word"
                  control={control}
                  rules={{
                    required: '入力してください',
                  }}
                  render={({field}) => (
                    <TextField
                      {...field}
                      margin="dense"
                      required
                      variant="outlined"
                      error={'word' in errors}
                      helperText={errors.word?.message}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              clear();
                              reset({
                                word: '',
                              });
                            }}>
                            <Clear />
                          </IconButton>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={1}>
              <Box pl={2}>
                <TrimaLoadingButton
                  onClick={handleSubmit(search, onSubmitError)}
                  type="submit"
                  variant="contained"
                  disabled={processing}
                  loading={processing}>
                  <Search />
                </TrimaLoadingButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box m={2} />
      {list.length === 0 && word && !isLoading && !isClear && (
        <div>
          <Text>
            「{word}
            」の検索結果はありません
          </Text>
          <VMargin />
        </div>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        {isLoading && <CircularProgress color="primary" />}
      </Grid>

      {list.length > 0 &&
        list.map((category) => (
          <CategoryListItem
            category={category}
            key={category.id}
            search={true}
          />
        ))}
      {!isLoading &&
        list.length === 0 &&
        categories.map((category) => (
          <CategoryListItem
            category={category}
            key={category.id}
            search={false}
          />
        ))}
    </>
  );
};

// 鉄道区分アイテム
const CategoryListItem: React.FC<{
  category: StationCategory;
  search: boolean;
}> = ({category, search}) => {
  return (
    <ExpandListItem
      label={category.name}
      level="category"
      id={category.id}
      search={search}>
      <TrainLineList parent={category} search={search} />
    </ExpandListItem>
  );
};

// 路線階層リスト
const TrainLineList: React.FC<{parent: StationCategory; search: boolean}> = ({
  parent,
  search,
}) => {
  const [lines, setLines] = React.useState<
    Array<TrainLine | SearchLine> | undefined
  >();
  const {getTrainLine} = StationCacheContainer.useContainer();
  React.useEffect(() => {
    if (search) {
      setLines(parent.lines);
    }
    if (lines === undefined && !search) {
      getTrainLine(parent.id).then(setLines);
    }
  }, [getTrainLine, lines, parent, search]);

  if (lines === undefined) {
    return <ListLoading />;
  }
  return (
    <>
      {lines.map((line) => (
        <TrainLineListItem line={line} key={line.id} search={search} />
      ))}
    </>
  );
};

// 路線階層アイテム
const TrainLineListItem: React.FC<{
  line: TrainLine | SearchLine;
  search: boolean;
}> = ({line, search}) => {
  return (
    <ExpandListItem
      label={line.name}
      level="trainLine"
      id={line.id}
      search={search}>
      <StationList parent={line} search={search} />
    </ExpandListItem>
  );
};

// 駅階層リスト
const StationList: React.FC<{
  parent: TrainLine | SearchLine;
  search: boolean;
}> = ({parent, search}) => {
  const [station, setStation] = React.useState<Station[] | undefined>();
  const {getStation, setStationCache} = StationCacheContainer.useContainer();

  React.useEffect(() => {
    if (search) {
      setStation((parent as SearchLine).stations);
      (parent as SearchLine).stations.map((searchStation) => {
        setStationCache(searchStation);
      });
    }
    if (station === undefined && !search) {
      getStation(parent.id).then(setStation);
    }
  }, [getStation, parent, station, search, setStationCache]);

  // 駅リスト読み込み中
  if (station === undefined) {
    return <ListLoading />;
  }
  // 駅リスト読み込み済みのためリスト表示
  return (
    <>
      {station.map((line) => (
        <StationItem {...line} key={line.id} />
      ))}
    </>
  );
};

// 駅アイテム
const StationItem: React.FC<Station> = ({name, id}) => {
  const {selectStation} = StationUsersContainer.useContainer();
  const status = useItemStatus('station', id);

  return (
    <ListSelector.Item
      label={name}
      status={status}
      onClick={() => selectStation(id)}
      sxListItemButton={{
        height: 40,
        pl: 8,
        backgroundColor: Colors.white,
      }}
      divider
    />
  );
};
