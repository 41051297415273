import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {TextInput} from 'react-native-paper';
import {
  PasswordChangeFormData,
  schemaPasswordChangeFormData,
} from '../../components/Auth/schema';
import {
  Container,
  FormBlock,
  Header,
  HelperText,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../../components/Elements';
import {ERROR_MSG, useCustomNav} from '../../helper';
import {AccountMenu} from '../../navigation/AccountMenu';
import {CommonStyles} from '../../theme';
import {AccountContainer} from './AccountContainer';

const Submit: React.FC = () => {
  const navigation = useNavigation();
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<PasswordChangeFormData>();
  const {changePassword, error, loading} = AccountContainer.useContainer();

  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const helperText = errors.newPassword?.message
    ? ERROR_MSG.common.form
    : error
    ? ERROR_MSG.common.dump(error)
    : '';
  const disabled = loading || !watch('newPassword') || !!errors.newPassword;
  return (
    <View>
      <TrimaLoadingButton
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(
          (data) => changePassword(data, navigation),
          onSubmitError,
        )}
        loading={loading}
        loadingIndicator={'更新中'}
      >
        パスワードを変更
      </TrimaLoadingButton>
      <HelperText type="error">{helperText}</HelperText>
    </View>
  );
};

const PASSWORD_RULE = `・英文字 ( 大文字・小文字 )、数字を必ず含む8文字以上で作成してください。
・アルファベットの大文字と小文字は区別されます。`;

const ChangePasswordComponent: React.FC = React.memo(() => {
  const navigator = useCustomNav();
  const goBack = () => {
    navigator.goBack('AccountMain');
  };
  const [oldSecureTextEntry, setOldSecureTextEntry] =
    React.useState<boolean>(true);
  const [newSecureTextEntry, setNewSecureTextEntry] =
    React.useState<boolean>(true);

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>パスワードの変更</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          <FormBlock>
            <MaxWidth maxWidth={586}>
              <VForm.Provider<PasswordChangeFormData>
                schema={schemaPasswordChangeFormData}
              >
                <AccountContainer.Provider>
                  <VForm.Text<PasswordChangeFormData>
                    name="oldPassword"
                    label="現在のパスワード"
                    secureTextEntry={oldSecureTextEntry}
                    required
                    right={
                      <TextInput.Icon
                        size={24}
                        style={styles.eye}
                        name={oldSecureTextEntry ? 'eye' : 'eye-off'}
                        onPress={() => {
                          setOldSecureTextEntry(!oldSecureTextEntry);
                        }}
                      />
                    }
                  />
                  <VMargin />
                  <VForm.Text<PasswordChangeFormData>
                    name="newPassword"
                    label="新しいパスワード"
                    secureTextEntry={newSecureTextEntry}
                    required
                    right={
                      <TextInput.Icon
                        size={24}
                        style={styles.eye}
                        name={newSecureTextEntry ? 'eye' : 'eye-off'}
                        onPress={() => {
                          setNewSecureTextEntry(!newSecureTextEntry);
                        }}
                      />
                    }
                  />
                  <VMargin />
                  <Text>{PASSWORD_RULE}</Text>
                  <VMargin />
                  <Submit />
                </AccountContainer.Provider>
                <VMargin />
                <TrimaButton variant="outlined" onClick={() => goBack()}>
                  キャンセル
                </TrimaButton>
              </VForm.Provider>
            </MaxWidth>
          </FormBlock>
        </View>
      </MaxWidth>
    </Container>
  );
});

export const ChangePassword: React.FC = () => {
  const route = useRoute<any>();
  const [showHeader, setShowHeader] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (route.params?.header) {
      setShowHeader(route.params?.header);
    }
  }, [route.params]);
  if (showHeader) {
    return (
      <View>
        <Header rightItem={<AccountMenu />} />
        <ChangePasswordComponent />
      </View>
    );
  } else {
    return <ChangePasswordComponent />;
  }
};

const styles = StyleSheet.create({
  eye: {
    position: 'relative',
    top: 4,
  },
});
