import AsyncStorage from '@react-native-async-storage/async-storage';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useTheme} from 'react-native-paper';
import Select from 'react-select';
import {Text} from '../components/Elements';
import {
  AdminContainer,
  AuthContainer,
  OwnerContainer,
  StepNavigationContainer,
} from '../container';
import {SaasOwnerQuery, graphQLService} from '../service';
import {Colors, CommonStyles} from '../theme';

const HEIGHT = 53;
const AVATAR_SIZE = 44;

type OwnerOption = {
  value: string;
  label: React.ReactElement;
};

const AvatarLabel: React.FC<{
  label: string;
}> = ({label}) => {
  return (
    <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
      <Text style={styles.valueLabel} numberOfLines={1}>
        {label}
      </Text>
    </View>
  );
};

function toOption(owner: any): OwnerOption {
  return {
    value: owner.id ?? owner.hojin,
    label: <AvatarLabel label={owner.hojin} />,
  };
}

const OwnerSelectButton = React.memo(() => {
  const {owner, fetchOwner} = OwnerContainer.useContainer();
  const [ownerList, setOwnerList] = React.useState<SaasOwnerQuery[]>([owner]);
  const {colors, fonts} = useTheme();
  const options = ownerList.map(toOption);
  const now = options[0];
  const [value] = React.useState<OwnerOption | null>(now);
  const {checkInitData} = StepNavigationContainer.useContainer();
  const {user} = AuthContainer.useContainer();
  const {isAdmin, userId, ownerId} = AdminContainer.useContainer();

  React.useEffect(() => {
    async function fetchData() {
      try {
        if (!isAdmin) {
          const ownerId = await AsyncStorage.getItem('owner');
          if (ownerId && !owner) {
            await fetchOwner(ownerId);
          }
          const fetchedOwners = await graphQLService.getListOwner(
            user.username,
          );
          console.log('[OwnerSelector] ownerList fetched', fetchedOwners);
          setOwnerList(fetchedOwners);
        } else {
          if (ownerId && !owner) {
            await fetchOwner(ownerId);
          }
          const fetchedOwners = await graphQLService.getListOwner(userId);
          console.log('[OwnerSelector] ownerList fetched', fetchedOwners);
          setOwnerList(fetchedOwners);
        }
      } catch {
        setOwnerList([]);
      }
    }
    fetchData();
  }, [owner, fetchOwner, user, isAdmin, userId, ownerId]);

  return (
    <View style={styles.shopSelect}>
      <Select
        isSearchable={false}
        onChange={async (v) => {
          if (v && user.username) {
            try {
              await AsyncStorage.setItem('owner', v.value);
            } catch (e) {
              console.log('[OwnerButton] onClick catch', e);
            }
            await checkInitData(v.value, user.username);
          }
        }}
        theme={(theme) => ({
          borderRadius: 0,
          colors: {
            ...theme.colors,
            ...colors,
          },
          spacing: {
            ...theme.spacing,
            menuGutter: 0,
            controlHeight: HEIGHT,
          },
        })}
        options={options}
        value={value}
        defaultValue={now}
        styles={{
          control: (base) => {
            return {
              ...base,
              fontFamily: fonts.regular.fontFamily,
              border: 0,
              boxShadow: 'none',
              backgroundColor: Colors.headerShadow,
            };
          },
          input: (base) => ({
            ...base,
            height: AVATAR_SIZE,
          }),
          indicatorSeparator: () => ({}),
          indicatorsContainer: (base) => ({
            ...base,
            paddingRight: 8,
          }),
          menu: (base) => ({
            ...base,
            fontFamily: fonts.regular.fontFamily,
            backgroundColor: Colors.headerShadow,
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: 'calc(100vh - 56px - 53px)',
          }),
          option: (base, {isFocused, isSelected}) => ({
            ...base,
            backgroundColor:
              !isSelected && isFocused ? '#4C3575' : base.backgroundColor,
          }),
        }}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  shopSelect: {
    height: HEIGHT,
    backgroundColor: Colors.headerShadow,
    zIndex: 1002,
  },
  valueLabel: {
    color: Colors.white,
    fontSize: 14,
    lineHeight: 16,
    marginVertical: 12,
    marginLeft: 8,
  },
});

export default OwnerSelectButton;
