import React from 'react';
import {StyleSheet, View} from 'react-native';
import HyperLink from 'react-native-hyperlink';
import {Helpers} from '../../helper';
import {Colors, trimaColor} from '../../theme';
// クーポンと共通のもの
import {
  ImageMini,
  ImageSwiper,
  InverseRadius,
  SummaryBase,
} from '../CouponPreview/common';
import {
  CvLinkDummyButton,
  PhoneDummyButton,
} from '../CouponPreview/PrimaryDummyButton';
import {Text} from '../Elements';
import {useResponsive} from '../Responsive';
import {NewsInfo} from './types';
export {Header} from '../CouponPreview/common';

// 配信期限表示
const DUMMY_LIMIT = '表示期限：****/**/** **:**';
const Limit = React.memo(() => {
  return (
    <View style={styles.limitContainer}>
      <Text style={styles.limitText}>{DUMMY_LIMIT}</Text>
    </View>
  );
});

// 説明テキスト
const NewsText = React.memo<{text?: string}>(({text}) => {
  if (!text) {
    return null;
  }
  return (
    <View style={styles.marginTop}>
      <HyperLink
        linkStyle={{color: trimaColor.accent}}
        onPress={(url) => Helpers.openURL(url)}
      >
        <Text style={styles.newsText}>{text}</Text>
      </HyperLink>
    </View>
  );
});

// 一覧画面でのクーポン内容表示
export const Summary: React.FC<{
  news: NewsInfo;
}> = ({news}) => {
  return (
    <SummaryBase>
      <View style={styles.summaryMain}>
        <ImageMini image={news.images[0]} />
        <View style={styles.summaryInfo}>
          <View style={styles.summaryInfo1}>
            <Text style={styles.couponNameMini} numberOfLines={4}>
              {news.name}
            </Text>
          </View>
        </View>
      </View>
    </SummaryBase>
  );
};

export const Detail: React.FC<{
  news: NewsInfo;
}> = ({news}) => {
  const {isMobile} = useResponsive();
  return (
    <View
      style={
        isMobile
          ? [styles.bodyCommon, styles.detail, styles.xsWidth]
          : [styles.bodyCommon, styles.detail, styles.desktopWidth]
      }
    >
      <ImageSwiper images={news.images} />
      <View style={styles.marginTop}>
        <Text style={styles.couponNameBig} numberOfLines={4}>
          {news.name}
        </Text>
      </View>
      <NewsText text={news.text} />
      <View style={styles.marginTop}>
        <Limit />
      </View>
      {news.cvLink?.url && (
        <View style={styles.marginTop}>
          <CvLinkDummyButton text={news.cvLink?.text} url={news.cvLink?.url} />
        </View>
      )}
      {news.cvPhone && (
        <View style={styles.marginTop}>
          <PhoneDummyButton phone={news.cvPhone} />
        </View>
      )}
      <InverseRadius isTop={true} />
    </View>
  );
};

const appleFontRatio = 0.935;
const couponWidth = 288; // W320端末（サイドマージン16*2分減算）の想定サイズ
const couponRadius = 16;
const margin = 16;
const marginMiddle = 8;
const styles = StyleSheet.create({
  desktopWidth: {
    width: 390 - 16 * 2,
  },
  xsWidth: {
    maxWidth: 288,
  },
  limitContainer: {
    alignItems: 'flex-end',
  },
  limitText: {
    fontSize: 12 * appleFontRatio,
    fontWeight: 'normal',
    lineHeight: 16,
  },
  marginTop: {
    marginTop: margin,
  },
  // クーポンボディ関連
  bodyCommon: {
    width: couponWidth,
    backgroundColor: Colors.lightgray,
    borderBottomLeftRadius: couponRadius,
    borderBottomRightRadius: couponRadius,
  },
  summary: {
    height: 140,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    paddingTop: margin,
    paddingHorizontal: margin,
    paddingBottom: 6, // 下部のマイル表示が規定マージンより下部にある
  },
  detail: {
    alignItems: 'stretch',
    padding: margin,
  },
  // サマリの主要部分
  summaryMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // サマリの文字情報エリア
  summaryInfo: {
    marginLeft: marginMiddle,
    flex: 1,
    alignItems: 'stretch',
  },
  summaryInfo1: {
    flex: 1,
    marginRight: -4,
  },
  couponNameMini: {
    fontSize: 14 * appleFontRatio,
    lineHeight: 19,
  },
  couponNameBig: {
    fontSize: 20 * appleFontRatio,
    lineHeight: 25,
  },
  newsText: {
    fontSize: 16 * appleFontRatio,
    lineHeight: 20,
  },
});
