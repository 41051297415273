import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Helpers} from '../../helper';
import {Colors} from '../../theme';
import {DialogBase, FormBlock, Text, TrimaButton, VMargin} from '../Elements';

const FOR_INACTIVE = `変更を保存しますか？
・配信終了日時を未来にした場合、配信が再開されます。
・配信期間中に予算上限を上げると、配信が再開されます。
・配信開始日時を未来にした場合、指定日時から自動的に配信が開始されます。`;

const FOR_ACTIVE = `変更を保存しますか？
・配信終了日時を過去にした場合、配信は停止されます。
・予算上限を下げた時、すでに使用金額が上回っている場合、配信が停止されます。
・配信開始日時を未来にした場合、指定日時から自動的に配信が開始されます。`;

const styleBaseBack = {backgroundColor: Colors.base};

export const UpdateDialog: React.FC<{
  forActive: boolean;
  visible: boolean;
  onConfirm?(): void;
  onDismiss?(): void;
}> = ({forActive, visible, onConfirm, onDismiss}) => {
  return (
    <DialogBase
      visible={visible}
      onDismiss={onDismiss}
      title="配信内容の変更"
      dismissable={false}
    >
      <Text>{forActive ? FOR_ACTIVE : FOR_INACTIVE}</Text>
      <VMargin />
      <TrimaButton variant="contained" onClick={onConfirm}>
        変更を保存する
      </TrimaButton>
      <VMargin />
      <TrimaButton variant="outlined" onClick={onDismiss}>
        キャンセル
      </TrimaButton>
    </DialogBase>
  );
};

export const StopDialog: React.FC<{
  visible: boolean;
  error?: string;
  onConfirm?(): void;
  onDismiss?(): void;
}> = ({visible, error, onConfirm, onDismiss}) => {
  const render = () => {
    if (error) {
      return (
        <View>
          <Text>配信停止エラー</Text>
          <VMargin />
          <Text>{error}</Text>
          <VMargin />
          <TrimaButton variant="outlined" onClick={onDismiss}>
            キャンセル
          </TrimaButton>
        </View>
      );
    }
    return (
      <View>
        <Text>
          配信を終了すると、
          <Text style={styles.emphasis}>
            広告獲得済みのユーザーも広告が見られなくなります。
          </Text>
          新しいトリマユーザーへの配信を止めたい場合は、
          <Text style={styles.emphasis}>
            予算上限を現在の使用金額より減らしてください。
          </Text>
        </Text>
        <Text>配信を終了してよろしいですか？</Text>
        <VMargin />
        <TrimaButton variant="contained" onClick={onConfirm}>
          配信終了
        </TrimaButton>
        <VMargin />
        <TrimaButton variant="outlined" onClick={onDismiss}>
          キャンセル
        </TrimaButton>
      </View>
    );
  };
  return (
    <DialogBase
      visible={visible}
      onDismiss={onDismiss}
      title="配信終了"
      dismissable={false}
    >
      {render()}
    </DialogBase>
  );
};

export const ConfirmDialog: React.FC<{
  creative:
    | {
        title: string;
        startAt: string;
        endAt: string;
        budget: number;
      }
    | undefined;
  onPress?(): void;
  onDismiss?(): void;
}> = ({creative, onPress, onDismiss}) => {
  if (!creative) {
    return null;
  }
  const {title, startAt, endAt, budget} = creative;
  const start = startAt && dayjs(startAt);
  const isBefore = dayjs().isBefore(start);
  return (
    <DialogBase
      visible
      onDismiss={onDismiss}
      title={isBefore ? '配信を設定しますか？' : '配信を開始しますか？'}
      dismissable={false}
    >
      <Text>
        {isBefore
          ? '以下の内容で配信の設定をします。'
          : '以下の内容で配信を開始します。'}
      </Text>
      <VMargin />
      <Text style={styles.lh}>■タイトル（管理用）</Text>
      <Text style={styles.lh}>{title}</Text>
      <Text style={styles.lh}>■配信期間</Text>
      <Text style={styles.lh}>{`${Helpers.toJapanDayTime(
        startAt,
      )}〜${Helpers.toJapanDayTime(endAt)}`}</Text>
      <Text style={styles.lh}>■予算上限</Text>
      <Text style={styles.lh}>
        {budget === 0 ? 'なし' : `${Helpers.sepComma(budget)}円`}
      </Text>
      <VMargin />
      <TrimaButton variant="outlined" onClick={onDismiss}>
        キャンセル
      </TrimaButton>
      <VMargin />
      <TrimaButton variant="contained" onClick={onPress}>
        {isBefore ? '配信を設定する' : '配信を開始する'}
      </TrimaButton>
    </DialogBase>
  );
};

export const ConfirmGroupDialog: React.FC<{
  creative:
    | {
        title: string;
        startAt: string;
        endAt: string;
        groups: Array<any>;
        targetArray: Array<any>;
        shopList: Array<any>;
      }
    | undefined;
  onPress?(): void;
  onDismiss?(): void;
}> = ({creative, onPress, onDismiss}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  if (!creative) {
    return null;
  }

  const {title, startAt, endAt, groups, targetArray, shopList} = creative;
  const filterdGroups: Array<any> = [];
  groups.forEach((group, index) => {
    if (group.enable) {
      filterdGroups.push({
        group,
        target: targetArray[index],
        shop: shopList[index],
      });
    }
  });
  const start = startAt && dayjs(startAt);
  const isBefore = dayjs().isBefore(start);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            groups.filter((group) => group.enable).length,
        )
      : 0;

  const handleChangePage: React.ComponentProps<
    typeof TablePagination
  >['onPageChange'] = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ComponentProps<
    typeof TablePagination
  >['onRowsPerPageChange'] = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <DialogBase
      visible
      onDismiss={onDismiss}
      title={isBefore ? '配信を設定しますか？' : '配信を開始しますか？'}
      dismissable={false}
    >
      <Text>
        {isBefore
          ? '以下の内容で配信の設定をします。'
          : '以下の内容で配信を開始します。'}
      </Text>
      <VMargin />
      <Text style={styles.lh}>■タイトル（管理用）</Text>
      <Text style={styles.lh}>{title}</Text>
      <Text style={styles.lh}>■配信期間</Text>
      <Text style={styles.lh}>{`${Helpers.toJapanDayTime(
        startAt,
      )}〜${Helpers.toJapanDayTime(endAt)}`}</Text>
      <Text style={styles.lh}>■配信対象</Text>
      <FormBlock>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={styleBaseBack}>
                <TableCell
                  align="center"
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                >
                  店舗名
                </TableCell>
                <TableCell
                  align="center"
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                >
                  配信先
                </TableCell>
                <TableCell
                  align="center"
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                >
                  予算上限
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterdGroups.length > 0 &&
                (rowsPerPage > 0
                  ? filterdGroups.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : filterdGroups
                ).map((group) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          {group.shop.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          {group.target[0].title}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          {group.group.noUpperLimit
                            ? '上限なし'
                            : `${group.group.budget.toLocaleString()}円`}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 37 * emptyRows,
                  }}
                >
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow style={styleBaseBack}>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={3}
                  count={filterdGroups.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="表示数"
                  SelectProps={{
                    inputProps: {
                      'aria-label': '表示数',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </FormBlock>
      <VMargin />
      <TrimaButton variant="outlined" onClick={onDismiss}>
        キャンセル
      </TrimaButton>
      <VMargin />
      <TrimaButton variant="contained" onClick={onPress}>
        {isBefore ? '配信を設定する' : '配信を開始する'}
      </TrimaButton>
    </DialogBase>
  );
};

export const ConfirmedDialog: React.FC<{
  creative:
    | {
        title: string;
        startAt: string;
        endAt: string;
        budget: number;
      }
    | undefined;
  onConfirm?(): void;
}> = ({creative, onConfirm}) => {
  if (!creative) {
    return null;
  }
  const {title, startAt, endAt, budget} = creative;
  const start = startAt && dayjs(startAt);
  const isBefore = dayjs().isBefore(start);
  const text = isBefore ? '配信設定が完了しました。' : '配信が開始されました。';
  return (
    <DialogBase
      visible
      onDismiss={onConfirm}
      title={isBefore ? '配信設定完了' : '配信開始完了'}
      dismissable={false}
    >
      <Text>{text}</Text>
      <VMargin />
      <Text style={styles.lh}>■タイトル（管理用）</Text>
      <Text style={styles.lh}>{title}</Text>
      <Text style={styles.lh}>■配信期間</Text>
      <Text style={styles.lh}>{`${Helpers.toJapanDayTime(
        startAt,
      )}〜${Helpers.toJapanDayTime(endAt)}`}</Text>
      <Text style={styles.lh}>■予算上限</Text>
      <Text style={styles.lh}>
        {budget === 0 ? 'なし' : `${Helpers.sepComma(budget)}円`}
      </Text>
      <VMargin />
      <TrimaButton variant="contained" onClick={onConfirm}>
        配信管理画面へ
      </TrimaButton>
    </DialogBase>
  );
};

export const ConfirmedGroupDialog: React.FC<{
  creative:
    | {
        title: string;
        startAt: string;
        endAt: string;
        groups: Array<any>;
        targetArray: Array<any>;
        shopList: Array<any>;
      }
    | undefined;
  onConfirm?(): void;
}> = ({creative, onConfirm}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  if (!creative) {
    return null;
  }

  const {title, startAt, endAt, groups, targetArray, shopList} = creative;
  const filterdGroups: Array<any> = [];
  groups.forEach((group, index) => {
    if (group.enable) {
      filterdGroups.push({
        group,
        target: targetArray[index],
        shop: shopList[index],
      });
    }
  });
  const start = startAt && dayjs(startAt);
  const isBefore = dayjs().isBefore(start);
  const text = isBefore ? '配信設定が完了しました。' : '配信が開始されました。';

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(
          0,
          (1 + page) * rowsPerPage -
            groups.filter((group) => group.enable).length,
        )
      : 0;

  const handleChangePage: React.ComponentProps<
    typeof TablePagination
  >['onPageChange'] = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ComponentProps<
    typeof TablePagination
  >['onRowsPerPageChange'] = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <DialogBase
      visible
      onDismiss={onConfirm}
      title={isBefore ? '配信設定完了' : '配信開始完了'}
      dismissable={false}
    >
      <Text>{text}</Text>
      <VMargin />
      <Text style={styles.lh}>■タイトル（管理用）</Text>
      <Text style={styles.lh}>{title}</Text>
      <Text style={styles.lh}>■配信期間</Text>
      <Text style={styles.lh}>{`${Helpers.toJapanDayTime(
        startAt,
      )}〜${Helpers.toJapanDayTime(endAt)}`}</Text>
      <Text style={styles.lh}>■配信対象</Text>
      <FormBlock>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={styleBaseBack}>
                <TableCell
                  align="center"
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                >
                  店舗名
                </TableCell>
                <TableCell
                  align="center"
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                >
                  配信先
                </TableCell>
                <TableCell
                  align="center"
                  sx={{p: 1, border: `1px solid ${Colors.base}`}}
                >
                  予算上限
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterdGroups.length > 0 &&
                (rowsPerPage > 0
                  ? filterdGroups.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                  : filterdGroups
                ).map((group) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          {group.shop.name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          {group.target[0].title}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          {group.group.noUpperLimit
                            ? '上限なし'
                            : `${group.group.budget.toLocaleString()}円`}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 37 * emptyRows,
                  }}
                >
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow style={styleBaseBack}>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  colSpan={3}
                  count={filterdGroups.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="表示数"
                  SelectProps={{
                    inputProps: {
                      'aria-label': '表示数',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </FormBlock>
      <VMargin />
      <TrimaButton variant="contained" onClick={onConfirm}>
        配信管理画面へ
      </TrimaButton>
    </DialogBase>
  );
};

const styles = StyleSheet.create({
  lh: {lineHeight: 30},
  emphasis: {color: Colors.accent},
});
