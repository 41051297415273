import {AxiosResponse} from 'axios';
import parsePhoneNumber from 'libphonenumber-js';
import {
  CouponUsage,
  Discount,
  DiscountType,
  EditState,
  SaaSCoupon,
} from '../../API';
import {getImages} from '../../service';
import {
  SaaSCoupon as CouponParam,
  SaaSCouponDefine,
  SaaSCouponRepository,
} from '../../_proto/services/SaaSCouponRepository';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {FormData} from './schema';

function pickDiscountValue(
  data: FormData,
): Pick<
  SaaSCouponDefine,
  'byPrice' | 'byPercent' | 'priceFrom' | 'priceTo' | 'freeText'
> {
  const {discountType} = data;
  switch (discountType) {
    case DiscountType.BY_PRICE:
      return {byPrice: data[discountType]};
    case DiscountType.BY_PERCENT:
      return {byPercent: data[discountType]};
    case DiscountType.FROM_PRICE:
      return {priceFrom: data.priceFrom, priceTo: data.priceTo};
    case DiscountType.BY_TEXT:
      return {freeText: data.freeText};
    case DiscountType.BY_HALF:
    case DiscountType.BY_FREE:
    default:
      return {};
  }
}

export function convert(
  data: FormData,
  editState: EditState,
  shopId: string,
  ownerId: string,
): SaaSCouponDefine {
  const {
    name,
    text,
    title,
    rule,
    usage,
    discountType,
    cvLinkUrl,
    cvLinkText,
    cvPhone,
  } = data;
  return {
    name,
    title,
    rule: rule ? rule : undefined,
    text: text ? text : undefined,
    usage: usage ?? CouponUsage.ONETIME,
    discountType,
    editState,
    shopId,
    ownerId,
    cvLinkUrl: cvLinkUrl ? cvLinkUrl : undefined,
    cvLinkText,
    cvPhone,
    ...pickDiscountValue(data),
  };
}

type PickDiscount = Pick<
  FormData,
  | 'discountType'
  | 'priceFrom'
  | 'priceTo'
  | DiscountType.BY_PRICE
  | DiscountType.BY_PERCENT
  | 'freeText'
>;

function getDiscount(discount: Discount): PickDiscount {
  if (!discount.type) {
    throw Error('invalid DiscountType');
  }
  const converted: PickDiscount = {discountType: discount.type};
  switch (discount.type) {
    case DiscountType.BY_PRICE:
    case DiscountType.BY_PERCENT:
      if (discount.param) {
        converted[discount.type] = discount.param[0] ?? 0;
      }
      break;
    case DiscountType.FROM_PRICE:
      if (discount.param) {
        converted.priceFrom = discount.param[0] ?? 0;
        converted.priceTo = discount.param[1] ?? 0;
      }
      break;
    case DiscountType.BY_TEXT:
      converted.freeText = discount.freeText ?? '';
      break;
    case DiscountType.BY_FREE:
    case DiscountType.BY_HALF:
      break; // 復帰させるデータはない
  }
  return converted;
}

// 既存の編集（上書き）か、既存を元にした新規作成か
export type EditMode = 'edit' | 'copy';

export async function convertForRestore(
  coupon: SaaSCoupon,
  editMode: EditMode,
  isReal?: boolean,
): Promise<{
  forms: FormData;
  images: SaaSImageContents[];
  barcode?: string;
  editState?: EditState;
}> {
  if (!coupon.usage || !coupon.discount?.type) {
    throw 'invalid coupon data';
  }

  const phoneNumber = parsePhoneNumber(coupon.cvPhone ?? '', 'JP');
  // copy 時は新規に作られるようにidをリストアしないで、タイトルも加工
  const forms = Object.assign(
    {
      id: editMode === 'edit' ? coupon.id : '',
      name: coupon.name ?? '',
      rule: coupon.rule ?? '',
      text: coupon.text ?? '',
      title: (coupon.title ?? '') + (editMode === 'copy' ? ' のコピー' : ''),
      // 無店舗なら何度でも利用可に変換
      usage: isReal ? coupon.usage : CouponUsage.INFINITE,
      imageEnabled: false,
      cvLinkUrl: coupon.cvLink?.url ?? '',
      cvLinkText: coupon.cvLink?.text ?? '',
      cvPhone:
        coupon.cvPhone && phoneNumber ? phoneNumber.formatNational() : '',
    },
    getDiscount(coupon.discount),
  );
  if (!coupon.imageUrls || !coupon.imageUrls.length) {
    return {forms, images: []};
  }
  const images = await getImages(coupon.imageUrls);
  const barcode = await getImages([coupon.barcodeUrl ?? null]);

  forms.imageEnabled = images.length > 0;
  return {
    forms,
    images,
    barcode: barcode[0]?.uri ?? undefined,
    editState: editMode === 'copy' ? undefined : coupon.editState ?? undefined,
  };
}

/**
 * クーポンの保存
 * @param coupon 保存するクーポン情報
 * @param update 更新する場合にクーポンIDを指定する
 */
export async function save(
  coupon: CouponParam,
  update?: string,
): Promise<AxiosResponse<SaaSCoupon>> {
  if (update) {
    // 上書き
    return await SaaSCouponRepository.put(coupon, {id: update});
  } else {
    return await SaaSCouponRepository.post(coupon);
  }
}
