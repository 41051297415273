import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {ShopContainer} from '../../container';
import {Helpers} from '../../helper';
import {DeliveryDailyReport} from '../../service';
import {Colors} from '../../theme';

const styleBaseBack = {backgroundColor: Colors.base};

function showCell(num: number | undefined): string {
  return num === undefined ? '-' : Helpers.sepComma(num);
}

export const DeliveryReportTable: React.FC<{
  reports?: DeliveryDailyReport[];
}> = ({reports}) => {
  const {selected} = ShopContainer.useContainer();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  reports = reports ?? [];
  reports = reports
    .map(({stamp, ...rest}) => {
      return {stamp: dayjs(stamp).format('MM/DD(ddd)'), ...rest};
    })
    .reverse();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reports.length) : 0;

  const handleChangePage: React.ComponentProps<
    typeof TablePagination
  >['onPageChange'] = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: React.ComponentProps<
    typeof TablePagination
  >['onRowsPerPageChange'] = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer style={{width: '100%'}}>
      <Table
        sx={{
          border: `1px solid ${Colors.base}`,
          minWidth: '700px',
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow style={styleBaseBack}>
            <TableCell>日付</TableCell>
            <TableCell align="center">一覧表示</TableCell>
            <TableCell align="center">閲覧回数</TableCell>
            <TableCell align="center">閲覧人数</TableCell>
            <TableCell align="center">保存</TableCell>
            <TableCell align="center">リンククリック</TableCell>
            <TableCell align="center">電話クリック</TableCell>
            <TableCell align="center">来店</TableCell>
            <TableCell align="center">利用</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? reports.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
            : reports
          ).map((report) => (
            <TableRow
              sx={{
                '& .MuiTableCell-root': {border: `1px solid ${Colors.base}`},
              }}
              key={report.stamp + report.id}
              hover
            >
              <TableCell component="th" scope="row">
                {report.stamp}
              </TableCell>
              <TableCell align="right">{showCell(report.listCount)}</TableCell>
              <TableCell align="right">{showCell(report.viewCount)}</TableCell>
              <TableCell align="right">{showCell(report.billCount)}</TableCell>
              <TableCell align="right">{showCell(report.keptCount)}</TableCell>
              <TableCell align="right">
                {showCell(report.clickedUrlCount)}
              </TableCell>
              <TableCell align="right">
                {showCell(report.clickedPhoneCount)}
              </TableCell>
              <TableCell align="right">
                {selected?.legalUrl ? '-' : showCell(report.visited)}
              </TableCell>
              <TableCell align="right">{showCell(report.usedCount)}</TableCell>
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{height: 53 * emptyRows}}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow style={styleBaseBack}>
            <TablePagination
              rowsPerPageOptions={[7, 14, 28, {label: 'All', value: -1}]}
              colSpan={9}
              count={reports.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="表示数"
              SelectProps={{
                inputProps: {
                  'aria-label': '表示数',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
