import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {SaaSDeliveryTarget} from '../../API';
import {Container, Text} from '../../components/Elements';
import {TargetFormProps, TargetForms} from '../../components/Targets';
import {TargetsContainer} from '../../container';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {graphQLService} from '../../service';

type NProp =
  | MainNavigationProp<'TargetsEdit'>
  | MainNavigationProp<'TargetsCopy'>;
type RProp = MainRouteProp<'TargetsEdit'> | MainRouteProp<'TargetsCopy'>;

// Edit/Copy 共通ベース
export const RestoreScreen: React.FC<{
  editMode: NonNullable<TargetFormProps['editMode']>;
}> = ({editMode}) => {
  const {checkedIds} = TargetsContainer.useContainer();
  const [target, setTarget] = React.useState<SaaSDeliveryTarget | undefined>();
  const [error, setError] = React.useState<Error | undefined>();
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const from = route.params?.from;
  const group = route.params?.group;
  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('TargetsMain');
    }
  };

  // 対象の配信先の取得
  React.useEffect(() => {
    if (id) {
      graphQLService
        .getTarget(id)
        .then((value) => {
          if (
            editMode === 'copy' ||
            value.editState === 'EDITING' ||
            value.editState === 'FIXED'
          ) {
            setTarget(value);
          } else {
            setError(Error('編集可能ではない配信先が選択されています'));
          }
        })
        .catch(setError);
    } else {
      setError(Error('対象IDが指定されていません'));
    }
    if (group === 'on') {
      if (checkedIds.length === 0) {
        setError(Error('不正な状態です'));
      }
    }
  }, [editMode, id]);

  // エラー時
  if (error) {
    return (
      <View>
        <Text>エラーが発生しました</Text>
        <Text>{error.message}</Text>
      </View>
    );
  }

  // 取得前
  if (!target) {
    return <ActivityIndicator size={40} />;
  }

  return (
    <Container notScroll>
      <TargetForms
        from={from}
        editMode={editMode}
        editTarget={target}
        onBack={goBack}
        onDone={(type, result) => {
          switch (type) {
            case 'fixed':
              navigation.navigate('TargetsMain');
              return;
            case 'fixAndGo':
              navigation.navigate('CreativesCreate', {targetId: result.id});
              return;
          }
        }}
        group={group}
      />
    </Container>
  );
};

export const Edit: React.FC = React.memo(() => {
  return <RestoreScreen editMode="edit" />;
});
