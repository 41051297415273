import API, {graphqlOperation, GraphQLResult} from '@aws-amplify/api';
import {GRAPHQL_AUTH_MODE} from '@aws-amplify/auth';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {GetInviteInfoQuery} from '../../API';
import {AuthContainer} from '../../container';
import {getInviteInfo as getInviteInfoQuery} from '../../graphql/queries';

const useInviteContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [invite, setInvite] = useState<any>();
  const [error, setError] = useState<Error | undefined>();
  const {user} = AuthContainer.useContainer();

  const fetchInvite = useCallback(
    async (id: string) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        const options = {
          ...graphqlOperation(getInviteInfoQuery, {id}),
          authMode: user
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        };
        const result = (await API.graphql(
          options,
        )) as GraphQLResult<GetInviteInfoQuery>;
        console.log(result);
        if (!result.data || !result.data.getInviteInfo) {
          throw new Error('[getInviteInfo] result.data is undefined');
        }
        setInvite({...result.data.getInviteInfo, id});
        return {...result.data.getInviteInfo, id};
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [user],
  );

  return {
    isCreateLoading,
    error,
    invite,
    fetchInvite,
  };
};

export const InviteContainer = createContainer(useInviteContainer);
