import axios from 'axios';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {AppConfig} from '../../../_proto/config/AppConfig';
import {GraphQlRepository} from '../../../_proto/services/GraphQlRepository';
import {SaaSRepository} from '../../../_proto/services/SaaSRepository';
import {
  createSaaSShopExcludeVisitor,
  deleteSaaSShopExcludeVisitor,
} from '../../../graphql/mutations';
import {graphQLService} from '../../../service';

export type ExcludeVisitorsFormData = {
  invitedCode?: string;
  shopId: string;
};

const useExcludeVisitorsContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [visitors, setVisitors] = useState<Array<any>>([]);
  const [error, setError] = useState<Error | undefined>();

  /**
   * 招待コードを登録
   */
  const create = useCallback(
    async (data: ExcludeVisitorsFormData) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        // 招待コードの有効性チェック
        const input: Record<string, unknown> = {
          id: data.shopId + '-' + data.invitedCode,
          ...data,
        };
        if (data.invitedCode) {
          const config = await SaaSRepository.axioConfig();
          const result = await axios.get(
            `${AppConfig.SaaSBackend}${AppConfig.SaaSUserInfoByInviteCodeEndpoint}/${data.invitedCode}/0`,
            config,
          );
          if (!(result.data && result.data.userId)) {
            console.log('無効な招待コードです');
            throw new Error('無効な招待コードです');
          }
          input.userId = result.data.userId;
        }
        const result = await GraphQlRepository.mutation(
          createSaaSShopExcludeVisitor,
          'createSaaSShopExcludeVisitor',
          {
            input,
          },
        );
        console.log('result', result);
        setVisitors([...visitors, result]);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [visitors],
  );

  /**
   * サーバーから招待コードのリストを取得
   */
  const getData = useCallback(async (id: string) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = await graphQLService.getShopExcludeVisitorList(id);
      setVisitors(result);
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  /**
   * 指定した招待コードを削除
   */
  const deleteData = useCallback(
    async (ids: Array<string>) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        // 最大10件なので、とりあえず1件ずつ消していく
        await Promise.all(
          ids.map((id) => {
            return GraphQlRepository.mutation(
              deleteSaaSShopExcludeVisitor,
              'deleteSaaSShopExcludeVisitor',
              {input: {id}},
            );
          }),
        );

        const newVisitores = visitors.filter((visitor) => {
          return !ids.includes(visitor.id);
        });
        setVisitors(newVisitores);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [visitors],
  );

  /**
   * すべての招待コードを削除
   */
  const deleteAllData = useCallback(async (id: string) => {
    setDeleteLoading(true);
    setError(undefined);
    try {
      const result = await graphQLService.getShopExcludeVisitorList(id);
      // 最大10件なので、とりあえず1件ずつ消していく
      await Promise.all(
        result.map((visitor) => {
          return GraphQlRepository.mutation(
            deleteSaaSShopExcludeVisitor,
            'deleteSaaSShopExcludeVisitor',
            {
              input: {
                id: visitor.id,
              },
            },
          );
        }),
      );
      setVisitors([]);
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setDeleteLoading(false);
    }
  }, []);

  return {
    isCreateLoading,
    isDeleteLoading,
    visitors,
    error,
    create,
    getData,
    deleteData,
    deleteAllData,
  };
};

export const ExcludeVisitorsContainer = createContainer(
  useExcludeVisitorsContainer,
);
