// Targets全体で利用する型定義
import {AreaType} from '../../API';
import {AreaMode} from './schema';

export type MeshCode = string; // 地図メッシュ
export type StationCode = string; // 駅コード（13桁）駅のコードは変わることがあるので、駅の特定はMasterCodeへ移行する
export type MasterCode = string; // 駅マスターコード（8桁）
export type UserId = string;

export type MeshList = {[P in MeshCode]: unknown};

export type OtherUsers = UserId[];
export const EMPTY_USERS: OtherUsers = [];

// 配信対象人数
export type TargetingUserCount = number | 'calculating' | 'error' | 'init';

// エリアコンテナ初期値受け渡し共通部
export type InitialStateBase = {
  count: TargetingUserCount;
  otherUsers: OtherUsers;
  otherChoiceCount: number; // 対象以外の選択数
  attrFilter?: AttrFilter;
};

// === ユーザー属性関係 ===
type Fields =
  | 'gender'
  | 'age'
  | 'married'
  | 'residence'
  | 'movingMethod'
  | 'childrenTogether'
  | 'childYoungest'
  | 'income'
  | 'householdIncome'
  | 'familyTogether'
  | 'job'
  | 'interest'
  | 'childAge';

// 各ユーザー属性が格納されているプロパティ名
export const FieldTable = {
  gender: 0,
  age: 1,
  married: 2,
  residence: 3,
  movingMethod: 4,
  childrenTogether: 5,
  childYoungest: 6,
  income: 7,
  householdIncome: 8,
  familyTogether: 9,
  job: 'A',
  interest: 'B',
  childAge: 'C',
} as const;

// エンコードされたユーザー属性
export type EncodedUserProp = {
  [P in (typeof FieldTable)[Fields]]: number | number[];
};

export type TestCached = {
  attrNG?: boolean;
  areaNG?: boolean;
};

export type BaseUser = {
  id: string;
  user?: EncodedUserProp; // ユーザー属性がないユーザーもいる
} & TestCached;

export type BaseUsersByStation = {[P in MasterCode]: BaseUser[]};
export type BaseUsersByMesh = {[P in MeshCode]: BaseUser[]};
// メッシュ単位or駅単位の違いはあるがキー名称ルールが違うだけで同じに扱えるのでまとめる。
export type BaseUsersBy = BaseUsersByMesh | BaseUsersByStation;

export type AttrFilter = (user: BaseUser) => boolean;

// === 居住地・滞在履歴共通
// 計算モード
export type CalcMode = 'none' | 'map' | 'form';
// メッシュ処理状態
export type SelectedMeshes = {[P in MeshCode]: 'working' | 'done'};

// === 居住地ユーザー関係 ===
export type FetchedMeshsResidenceUser = {
  meshs: FetchedMeshResidenceUser[];
};

export type FetchedMeshResidenceUser = {
  mesh: MeshCode;
  users: ResidenceUser[];
};

export type ResidenceUsersByMesh = {[P in MeshCode]: ResidenceUser[]};

export type ResidenceUser = BaseUser & {
  stayType?: AreaType.HOME | AreaType.OFFICE;
};

export type ResidenceFilter = (user: ResidenceUser) => boolean;

// コンテナに渡す初期値
export type ResidenceInitialState = InitialStateBase & {
  residenceUsers: ResidenceUsersByMesh;
};

// === 滞在履歴ユーザー関連 ===
export type FetchedMeshsStayUser = {
  meshs: FetchedMeshStayUser[];
};

export type FetchedMeshStayUser = {
  mesh: MeshCode;
  users: StayUser[];
};

export type StayUsersByMesh = {[P in MeshCode]: StayUser[]};

export type StayUser = BaseUser & {
  stay: number[];
};

export type StayFilter = (user: StayUser) => boolean;

// コンテナに渡す初期値
export type StayInitialState = InitialStateBase & {
  stayUsers: StayUsersByMesh;
};

// === 利用駅ユーザー関連 ===
export type FetchedStationUserList = {
  stations?: FetchedStationUsers[];
};

export type FetchedStationUsers = {
  station: string;
  users?: BaseUser[];
};

// 鉄道区分
export type Category = {
  id: string;
  name: string;
};
// 鉄道路線
export type TrainLine = {
  id: StationCode;
  name: string;
  category: StationCode; // 親ID
  mimi?: string; // 頭文字？現状使ってない
};
// 駅
export type Station = {
  id: MasterCode;
  name: string;
  parent?: string;
  routeId?: StationCode;
};

export type StationInitialState = InitialStateBase & {
  stationUsers: BaseUsersByStation;
};

// 共通コンテナ用
export type TargetsInitialState = {
  areaMode?: AreaMode;
  residenceUsers?: ResidenceUsersByMesh;
  stayUsers?: StayUsersByMesh;
  stationUsers?: BaseUsersByStation;
  cities?: any;
  onMounted?(): void;
};

// 駅プレビュー用 key：路線、value:駅リスト （路線毎にまとめて駅名を表示）
export type StationsByLine = {[P in StationCode]: (StationCode | MasterCode)[]};
