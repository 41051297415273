import {Box} from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {
  BRAKE_POINT,
  Media,
  ShopCreateComponent,
  useResponsive,
} from '../../components';
import {
  BackLink,
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  VForm,
  VMargin,
} from '../../components/Elements';
import {
  RealShopForm,
  SubmitForAdd,
} from '../../components/Register/ShopRegister';
import {ShopRegisterContainer} from '../../components/Register/container';
import {
  EditMode,
  ShopFormData,
  ShopRestored,
  convertForRestore,
  schemaShopForm,
} from '../../components/Register/shopSchema';
import {ShopPreview} from '../../components/Shop/ShopPreview';
import {OwnerContainer, ShopContainer, ShopsContainer} from '../../container';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {graphQLService} from '../../service';
import {CommonStyles, Message} from '../../theme';

type NProp = MainNavigationProp<'ShopsEdit'>;
type RProp = MainRouteProp<'ShopsEdit'>;

const Wrap: React.FC<{style?: ViewStyle; children: React.ReactNode}> = ({
  children,
  style,
}) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

export const RestoreScreen: React.FC<{editMode: EditMode}> = ({editMode}) => {
  const [target, setTarget] = React.useState<ShopRestored | undefined>();
  const [error, setError] = React.useState<Error | undefined>();
  const {updateShops} = ShopContainer.useContainer();
  const {fetchShops} = ShopsContainer.useContainer();
  const {owner} = OwnerContainer.useContainer();
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const {isOverDesktop} = useResponsive();
  React.useEffect(() => {
    if (id) {
      graphQLService
        .getShop(id)
        .then((data) => convertForRestore(data, editMode))
        .then(setTarget)
        .catch(setError);
    } else {
      setError(Error('チケットIDがありません'));
    }
  }, [id, route, editMode]);
  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('ShopsMain');
    }
  };
  const onUpdate = async (shop: any) => {
    await updateShops(shop.id);
    await fetchShops(owner?.id as string);
    await AsyncStorage.setItem('shop', shop.id);
  };
  // エラー時
  if (error) {
    return (
      <View>
        <Text>エラーが発生しました</Text>
        <Text>{error.message}</Text>
      </View>
    );
  }
  // ターゲット取得前
  if (!target) {
    return <ActivityIndicator size={40} />;
  }
  const label = editMode === 'edit' ? Message.BackNoEdit : Message.BackNoCopy;
  const menu = editMode === 'edit' ? '店舗情報の編集' : '店舗情報の複製';
  // 取得できたら遷移
  return (
    <Container style={CommonStyles.padding.all}>
      <MaxWidth maxWidth={BRAKE_POINT.desktop}>
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label={label} onClick={goBack} />
        </Box>
        <VForm.Provider<ShopFormData>
          schema={schemaShopForm}
          defaultValues={target.forms}>
          <ShopRegisterContainer.Provider
            initialState={{images: target.images, icon: target.icon}}>
            <Media mobile>
              <Menu>{menu}</Menu>
              <RealShopForm />
              <Wrap>
                <View style={CommonStyles.margin.top} />
                <FormBlock>
                  <ShopCreateComponent />
                </FormBlock>
              </Wrap>
              <Wrap>
                <ShopPreview />
              </Wrap>
              <VMargin />
            </Media>
            <Media tablet desktop>
              <View style={styles.desktop}>
                <View style={isOverDesktop ? styles.max : styles.fullWidth}>
                  <Menu>店舗情報</Menu>
                  <View style={[CommonStyles.flex.row]}>
                    <Wrap style={styles.leftPanel}>
                      <ShopPreview />
                    </Wrap>
                    <View style={styles.rightPanel}>
                      <RealShopForm />
                      <Wrap>
                        <FormBlock>
                          <ShopCreateComponent />
                        </FormBlock>
                      </Wrap>
                    </View>
                  </View>
                  <VMargin />
                </View>
              </View>
            </Media>
            <MaxWidth maxWidth={586}>
              <SubmitForAdd
                onSubmit={onUpdate}
                mode="edit"
                groupId={target.groupId}
              />
            </MaxWidth>
          </ShopRegisterContainer.Provider>
        </VForm.Provider>
      </MaxWidth>
    </Container>
  );
};

export const Edit: React.FC = React.memo(() => {
  return <RestoreScreen editMode="edit" />;
});

const styles = StyleSheet.create({
  container: {
    ...CommonStyles.formBlock,
    ...CommonStyles.margin.all,
    marginBottom: 0,
  },
  desktop: {
    alignItems: 'center',
  },
  fullWidth: {
    alignSelf: 'stretch',
  },
  max: {
    width: BRAKE_POINT.desktop,
  },
  leftPanel: {
    marginRight: 0,
  },
  rightPanel: {
    flex: 1,
  },
});
