// 滞在履歴の選択モード用コンテナ
import {useCallback, useEffect, useRef, useState} from 'react';
import {createContainer} from 'unstated-next';
import {
  CalcMode,
  MeshCode,
  SelectedMeshes,
  StayInitialState,
  StayUsersByMesh,
  TargetingUserCount,
} from '../../types';
import {checkStay, useStayFilter} from './stayHistoryFilters';
import _ from 'lodash';
import {TargetingContainer} from '../../TargetingContainer';
import {calcUserCount, isEmptyMeshList, isUnderLimit} from '../../commonFunc';
import {useShopId} from '../../../../container';
import {makeStayFetcher} from '../userFetchFuncs';
import {useAreaContainerBase} from '../../hooks';
import {useFormContext} from 'react-hook-form';
import {AttrForm, CHOICE_LIMIT} from '../../schema';
import {useLimitCaution} from '../LimitCautionDialog';
import {QueueHelper} from '../../../../helper';

const queue = QueueHelper.getQueue();

// StayUsersByMesh から 表示用データに変換
function toSelected(stay: StayUsersByMesh): SelectedMeshes {
  const selected: SelectedMeshes = {};
  _.forEach(stay, (__, key) => {
    selected[key] = 'done';
  });
  return selected;
}

type UseStayHistory = {
  userCount: TargetingUserCount; // 配信対象人数
  selectedMesh: SelectedMeshes; // 選択メッシュ
  canConfirm: boolean; // confirm可能状態かどうか
  calcMode: CalcMode;
  selectMesh(meshCode: MeshCode): void;
  selectMeshes(meshes: MeshCode[]): void; // 複数選択（半径一括用）
  confirm(): void;
};

function useStayHistory(initialState?: StayInitialState): UseStayHistory {
  const mountRef = useRef(false);
  const {shopId} = useShopId();
  // 初期値の保持とユーザー数のstate
  const {attrFilter, otherUsers, otherChoiceCount, userCount, setCount} =
    useAreaContainerBase(initialState);
  // 滞在ユーザーデータ（表示に必要なのはフィルタ結果の人数だけなので、ユーザーデータはRefで持つ）
  // 確定しない場合はキャンセルになるので、元データを変更しないようにコピー
  const stayUsersRef = useRef<StayUsersByMesh>(
    initialState?.stayUsers ? _.cloneDeep(initialState.stayUsers) : {},
  );
  // 操作の排他用モード
  const calcModeRef = useRef<CalcMode>('none');
  const [calcMode, setCalcMode] = useState<CalcMode>('none');
  const setMode = useCallback((mode: CalcMode) => {
    calcModeRef.current = mode;
    setCalcMode(mode);
  }, []);
  // 選択中エリア情報
  const selectedMeshRef = useRef<SelectedMeshes>(
    toSelected(stayUsersRef.current),
  );
  const [selectedMesh, setSelected] = useState<SelectedMeshes>(
    selectedMeshRef.current,
  );
  const feedbackSelected = useCallback(
    () => setSelected({...selectedMeshRef.current}),
    [],
  );
  // 選択数関係
  const getChoiceCountAll = useCallback<() => number>(() => {
    return otherChoiceCount + Object.keys(selectedMeshRef.current).length;
  }, [otherChoiceCount]);
  const {showLimitCaution} = useLimitCaution();

  // stayUsersRef -> userCount : 対象人数を算出
  const calcCount = useCallback(() => {
    if (!QueueHelper.isFinish(queue)) {
      return; // 処理中メッシュがあるのでまだ計算しない
    }
    const sum = calcUserCount([stayUsersRef.current], otherUsers);
    if (QueueHelper.isFinish(queue)) {
      setCount(sum); // タスクに割り込まれてなければ反映
      setMode('none');
    }
  }, [otherUsers, setCount, setMode]);

  // 滞在履歴条件フィルターの取得と更新時の再適用
  const {stayFilter} = useStayFilter();
  const stayFilterRef = useRef(stayFilter);
  const {trigger} = useFormContext<AttrForm>();
  useEffect(() => {
    stayFilterRef.current = stayFilter;
    if (!mountRef.current) {
      return; // 初回マウント時はスルー
    }
    // 無効条件に変化してないかフォームチェックのトリガーをかけておく
    trigger('areaStay').then(() => undefined);
    // 滞在履歴フィルタが更新されたら反映処理を実施（属性フィルタは再適用不要）
    setMode('form');
    setCount('calculating');
    _.forOwn(stayUsersRef.current, (users) => {
      for (let i = 0; i < users.length; i++) {
        checkStay(users[i], stayFilter);
      }
    });
    calcCount();
  }, [calcCount, setCount, setMode, stayFilter, trigger]);

  // メッシュ単位処理（タスクキューに積まれる処理）
  type Worker = () => Promise<boolean>;
  const makeWorker = useCallback<(meshes: MeshCode[]) => Worker>(
    (meshes: MeshCode[]) => {
      return makeStayFetcher(
        meshes,
        stayUsersRef.current,
        shopId,
        attrFilter,
        // 常に最新の上限でフィルターして欲しいので、Ref経由で最新を参照する
        stayFilterRef.current,
      );
    },
    [attrFilter, shopId],
  );

  // メッシュ選択時の処理
  const selectMesh = useCallback(
    (meshCode: MeshCode) => {
      if (calcModeRef.current === 'form') {
        // フォーム操作中はメッシュ選択させない
        return;
      }
      if (selectedMeshRef.current[meshCode] === 'done') {
        // 解除処理。ステータスが 'working' の場合はシンプル化のためとりあえず解除不可。
        delete selectedMeshRef.current[meshCode];
        delete stayUsersRef.current[meshCode];
        feedbackSelected();
        calcCount();
      } else if (!selectedMeshRef.current[meshCode]) {
        if (getChoiceCountAll() < CHOICE_LIMIT) {
          // メッシュを選択状態にして、タスクを積む
          setMode('map');
          selectedMeshRef.current[meshCode] = 'working';
          feedbackSelected();
          setCount('calculating');
          queue.add(makeWorker([meshCode])).then((success) => {
            if (success) {
              selectedMeshRef.current[meshCode] = 'done';
              // working / done で見た目に変化を出さないなら反映不要
              // feedbackSelected();
            } else {
              // 取得失敗したみたいなので、消す
              delete selectedMeshRef.current[meshCode];
              feedbackSelected();
            }
            calcCount();
          });
        } else {
          // 選択数上限
          showLimitCaution();
        }
      }
    },
    [
      calcCount,
      feedbackSelected,
      getChoiceCountAll,
      makeWorker,
      setCount,
      setMode,
      showLimitCaution,
    ],
  );

  const selectMeshes = useCallback<UseStayHistory['selectMeshes']>(
    (meshes) => {
      // 全部で何メッシュ選択状態になるのかチェック
      const count =
        _.uniq(_.concat(meshes, Object.keys(selectedMeshRef.current))).length +
        otherChoiceCount;
      if (count > CHOICE_LIMIT) {
        // 選択すると上限を超過するので、ダイアログだけ表示する
        showLimitCaution();
      } else {
        // 一括選択の場合、「選択」だけして、「選択解除」はしない
        meshes.forEach((meshCode) => {
          if (!selectedMeshRef.current[meshCode]) {
            selectMesh(meshCode);
          }
        });
      }
    },
    [otherChoiceCount, selectMesh, showLimitCaution],
  );

  // 完了処理関係
  const canConfirm =
    !isUnderLimit(userCount) && !isEmptyMeshList(stayUsersRef.current);
  const {confirmStay} = TargetingContainer.useContainer();
  const confirm = useCallback(() => {
    confirmStay(stayUsersRef.current, userCount);
  }, [confirmStay, userCount]);

  // 初回マウント完了を記録
  useEffect(() => {
    mountRef.current = true;
  }, []);

  return {
    selectedMesh,
    selectMesh,
    selectMeshes,
    canConfirm,
    calcMode,
    userCount,
    confirm,
  };
}

export const StayHistoryContainer = createContainer(useStayHistory);
