'use strict'; //IMPORTANT: to switch on customParseFormat strict-mode

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/ja';

dayjs.locale('ja');
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);

const STEP_OFFSET = 6;
const REMIND_START_TIME = {
  hour: 20,
  min: 30,
  sec: 0,
  ms: 0,
};

// startOf('day') は端末のタイムゾーンによって日付をずらしてしまう模様。
// 歩数時間のように意図的にオフセットをずらして利用する場合などで意図しない動きになるので、代替関数を用意。
const startOfDay = (day: dayjs.Dayjs) => {
  return day.hour(0).minute(0).second(0).millisecond(0);
};

const endOfDay = (day: dayjs.Dayjs) => {
  return day.hour(23).minute(59).second(59).millisecond(999);
};

export {startOfDay, endOfDay};

export class DayjsRepository {
  static japanDate(): dayjs.Dayjs {
    return dayjs().utcOffset(9);
  }

  static stepDate(): dayjs.Dayjs {
    return dayjs().utcOffset(STEP_OFFSET);
  }

  static toStepDate(date: dayjs.Dayjs): dayjs.Dayjs {
    return date.utcOffset(STEP_OFFSET);
  }

  // 歩数回収忘れ防止用リマインド開始日時
  static toStepRemindStartDate(stepDate: dayjs.Dayjs): dayjs.Dayjs {
    return stepDate
      .hour(REMIND_START_TIME.hour - (9 - STEP_OFFSET))
      .minute(REMIND_START_TIME.min)
      .second(REMIND_START_TIME.sec)
      .millisecond(REMIND_START_TIME.ms);
  }

  static startOfWeek(): dayjs.Dayjs {
    return dayjs().utcOffset(9).startOf('isoWeek');
  }

  static parseTokyoDay(day: string, format: string): dayjs.Dayjs {
    const result = dayjs(day, format);
    if (!result.isValid()) {
      throw new Error('error day format');
    }
    return result.utcOffset(9).startOf('day');
  }

  static parseStepDay(day: string, format: string): dayjs.Dayjs {
    const result = dayjs(day, format);
    if (!result.isValid()) {
      throw new Error('error day format');
    }
    return DayjsRepository.parseTokyoDay(day, format).utcOffset(STEP_OFFSET);
  }
}
