import React from 'react';
import {useFormContext} from 'react-hook-form';
import {AttrForm, areaResidenceInfo} from '../../schema';
import {TargetingContainer} from '../../TargetingContainer';
import MapPreviewCommon, {getMeshList} from './MapPreviewCommon';
import {Paragraph} from '../../../Elements';
import {AreaOption, AreaType} from '../../../../API';
import {MeshList} from '../../types';
import {View} from 'react-native';
import {ChoiceCounter} from '../ChoiceCounter';
import {getCountByMesh} from '../../commonFunc';

// 詳細画面にてサーバーから取得したデータを元にするプレビュー
export const ResidencePreviewWithData: React.FC<{
  area: AreaOption;
}> = React.memo(({area}) => {
  const list = getMeshList(area.meshs);
  return (
    <MapPreviewCommon meshList={list}>
      <ResidenceLabel areaType={area.areaType} meshList={list} />
    </MapPreviewCommon>
  );
});

// 作成・編集時のフォーム利用時のプレビュー
export const ResidencePreviewWithEdit: React.FC = React.memo(() => {
  const {residenceMesh, settingArea} = TargetingContainer.useContainer();
  const {getValues} = useFormContext<AttrForm>();
  const areaResidence = getValues('areaResidence');
  const onPress = settingArea('areaResidence');
  return (
    <MapPreviewCommon meshList={residenceMesh} onPress={onPress}>
      <ResidenceLabel areaType={areaResidence} meshList={residenceMesh} />
    </MapPreviewCommon>
  );
});

const ResidenceLabel: React.FC<{
  areaType?: AreaType | null;
  meshList: MeshList;
}> = ({areaType, meshList}) => {
  let label = '';
  if (areaType) {
    label =
      areaResidenceInfo.options.find((item) => item.value === areaType)
        ?.label ?? '';
  }
  const count = getCountByMesh(meshList);
  return (
    <View>
      <Paragraph label="対象" value={label} />
      <ChoiceCounter type="mesh" count={count} />
    </View>
  );
};
