import React from 'react';
import {Helpers} from '../../helper';
import {ButtonGroup, DataArea, Overlay, Text} from '../Elements';
import {ActivityIndicator, DataTable} from 'react-native-paper';
import {Colors, CommonStyles} from '../../theme';
import {
  DailyVisitorReportDto,
  SaasReportRepository,
  ShopVisitorResponse,
} from '../../service';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import _ from 'lodash';
import {StyleSheet, View} from 'react-native';

const Table: React.FC<{
  response?: Pick<ShopVisitorResponse, 'visitor'> | 'loading' | 'error';
}> = React.memo(({response}) => {
  const report =
    response === 'loading' || response === 'error'
      ? response
      : response?.visitor;
  let visitor = '-,---人';
  let repeater = '---人';
  let percent = '-.--%';
  if (report && report !== 'loading' && report !== 'error') {
    if (report?.visitor !== undefined) {
      visitor = `${Helpers.sepComma(report.visitor)}人`;
    }
    if (report?.repeater !== undefined) {
      repeater = `${Helpers.sepComma(report.repeater)}人`;
    }
    if (report?.repeater !== undefined && report?.visitor) {
      percent = `${Helpers.sepComma(
        Helpers.percent(report.repeater, report.visitor, 2),
      )}%`;
    }
  }

  const isOverLay = report === 'loading' || report === 'error' || !report;
  return (
    <DataArea>
      <DataTable style={styles.dataTable}>
        <DataTable.Row style={CommonStyles.borderless}>
          <DataTable.Cell numeric>来店者数：</DataTable.Cell>
          <DataTable.Cell numeric>{visitor}</DataTable.Cell>
        </DataTable.Row>
        <DataTable.Row style={CommonStyles.borderless}>
          <DataTable.Cell numeric>リピート来店者数：</DataTable.Cell>
          <DataTable.Cell numeric>{repeater}</DataTable.Cell>
        </DataTable.Row>
        <DataTable.Row style={CommonStyles.borderless}>
          <DataTable.Cell numeric>リピート率：</DataTable.Cell>
          <DataTable.Cell numeric>{percent}</DataTable.Cell>
        </DataTable.Row>
      </DataTable>
      {isOverLay && (
        <Overlay>
          {report === 'loading' ? (
            <ActivityIndicator size={40} />
          ) : report === 'error' ? (
            <Text>データの取得に失敗しました</Text>
          ) : (
            <Text>データがありません。</Text>
          )}
        </Overlay>
      )}
    </DataArea>
  );
});

const BUTTONS_REPEATER_TERM = ['週', '月'];
const Graph: React.FC<{
  response?: Pick<ShopVisitorResponse, 'visitor'> | 'loading' | 'error';
  isSummary?: boolean;
}> = React.memo(({response, isSummary}) => {
  const [repeaterIndex, setRepeaterIndex] = React.useState<number>(0);
  let isOverLay: boolean;
  let reports: DailyVisitorReportDto[] = [];
  if (
    response === 'loading' ||
    response === 'error' ||
    !response ||
    !response.visitor.reports
  ) {
    isOverLay = true;
  } else {
    isOverLay = false;
    reports = response.visitor.reports ?? [];
  }

  // 週 or 月単位の最終日データを取り出す
  const format = repeaterIndex ? 'M月' : 'M/D週';
  const classified = repeaterIndex
    ? SaasReportRepository.classifyMonth<DailyVisitorReportDto>(reports)
    : SaasReportRepository.classifyWeeks<DailyVisitorReportDto>(reports);
  const data = classified
    .map((cl) => {
      const find = cl.values
        .reverse()
        .find((value) => value.visitor && value.repeater !== 0);
      const percent = Helpers.percent(
        find?.repeater ?? 0,
        find?.visitor ?? 1,
        2,
      );
      return {stamp: cl.startDay.format(format), percent};
    })
    // 8 weeks or 3 months
    .slice(repeaterIndex ? -3 : -8);

  const isEmpty = reports.length === 0;
  return (
    <DataArea>
      {!isSummary && (
        <View style={styles.buttonGroupTerm}>
          <ButtonGroup
            buttons={BUTTONS_REPEATER_TERM}
            selectedIndex={repeaterIndex}
            onPress={setRepeaterIndex}
          />
        </View>
      )}
      <ResponsiveContainer width="100%" height={315}>
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: -16,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="stamp" fontSize={10} />
          <YAxis yAxisId="left" unit="%" fontSize={10} />
          <Tooltip />
          <Legend />
          <Line
            yAxisId="left"
            type="monotone"
            name="リピート率"
            dataKey="percent"
            stroke={Colors.accent}
            activeDot={{r: 8}}
          />
        </LineChart>
      </ResponsiveContainer>
      {(isOverLay || isEmpty) && (
        <Overlay>
          {response === 'loading' ? (
            <ActivityIndicator size={40} />
          ) : response === 'error' ? (
            <Text>データの取得に失敗しました</Text>
          ) : (
            <Text>データがありません。</Text>
          )}
        </Overlay>
      )}
    </DataArea>
  );
});

const styles = StyleSheet.create({
  buttonGroupTerm: {
    width: 100,
    ...CommonStyles.align.self.end,
  },
  dataTable: {
    width: 300, // 文字が長くて省略されるっぽいので少し広くする
  },
});

export const CustomerVisitor = {
  Table,
  Graph,
};
