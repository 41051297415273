import Queue from 'promise-queue';

function getQueue(maxConcurrent = 5, maxQueue = Infinity): Queue {
  return new Queue(maxConcurrent, maxQueue);
}

function isFinish(queue: Queue): boolean {
  return queue.getQueueLength() === 0 && queue.getPendingLength() === 0;
}

export const QueueHelper = {
  getQueue,
  isFinish,
};
