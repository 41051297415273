import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {AppConfig} from '../../_proto/config/AppConfig';
import {BRAKE_POINT, useResponsive} from '../../components';
import {
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {
  CustomerAttribute,
  CustomerVisitor,
  CustomerVisitorMap,
  DUMMY_VISITOR_RESPONSE,
} from '../../components/Report';
import {ShopContainer, useShopId} from '../../container';
import {SaasReportRepository, ShopVisitorResponse} from '../../service';
import {CommonStyles} from '../../theme';

export const Customers: React.FC = React.memo(() => {
  const navigation = useNavigation();
  const {shopId} = useShopId();
  const [response, setResponse] = React.useState<
    ShopVisitorResponse | 'loading' | 'error'
  >('loading');

  React.useEffect(() => {
    if (!shopId) {
      return;
    }
    SaasReportRepository.getShopVisitor(shopId, 'full')
      .then(setResponse)
      .catch(() => setResponse('error'));
  }, [shopId]);

  const canDummy = AppConfig.Stage !== 'prod';
  const onPressDummy = () => setResponse(DUMMY_VISITOR_RESPONSE);

  const {selected} = ShopContainer.useContainer();
  if (selected?.legalUrl) {
    // 通販向け店舗の場合、エラーページへ遷移
    navigation.navigate('Error');
  }

  return (
    <Container>
      <MaxWidth maxWidth={BRAKE_POINT.desktop} style={CommonStyles.padding.all}>
        <WithHint id="reportsCustomer">
          <Menu>来店者分析</Menu>
        </WithHint>
        <VMargin />
        <FormBlock>
          <WithHint id="reportRepeater">
            <Text>来店者リピート率</Text>
          </WithHint>
          <VMargin />
          <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
            <CustomerVisitor.Table response={response} />
            <VMargin />
            <CustomerVisitor.Graph response={response} />
          </MaxWidth>
        </FormBlock>
        <VMargin />
        <FormBlock>
          <WithHint id="reportResidence">
            <Text>来店者居住地/勤務地</Text>
          </WithHint>
          <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
            <CustomerVisitorMap response={response} />
          </MaxWidth>
        </FormBlock>
        <VMargin />
        <VisitedAttr response={response} />
        {canDummy && (
          <TrimaButton variant="outlined" onClick={onPressDummy}>
            ダミーデータを使う
          </TrimaButton>
        )}
      </MaxWidth>
    </Container>
  );
});

export const VisitedAttr: React.FC<{
  response: ShopVisitorResponse | 'loading' | 'error';
}> = ({response}) => {
  const {isDesktop} = useResponsive();
  const flexStyle = isDesktop ? CommonStyles.flex.row : {};
  return (
    <FormBlock>
      <MaxWidth maxWidth={1008}>
        <WithHint id={'reportAttribute'}>
          <Text>来店者属性</Text>
        </WithHint>
        <VMargin />
        <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
          <View>
            <Text>年齢x性別</Text>
            <CustomerAttribute.AgeGenderGraph response={response} />
          </View>
          <VMargin />
        </MaxWidth>
        <View style={flexStyle}>
          <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
            <View style={CommonStyles.flex.full}>
              <Text>年齢</Text>
              <CustomerAttribute.AgeGraph response={response} />
            </View>
          </MaxWidth>
          <View style={[CommonStyles.margin.top, CommonStyles.margin.left]} />
          <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
            <View style={CommonStyles.flex.full}>
              <Text>性別</Text>
              <CustomerAttribute.GenderCircle response={response} />
            </View>
          </MaxWidth>
        </View>
      </MaxWidth>
    </FormBlock>
  );
};
