import {Box} from '@mui/material';
import {useNavigation} from '@react-navigation/native';
import {Auth} from 'aws-amplify';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {AccessManageRole} from '../../API';
import {SaaSBillingRepository} from '../../_proto/services/SaaSBillingRepository';
import {AccessManageTable} from '../../components/AccessManage/AccessManageTable';
import {AgencyInfoPreview} from '../../components/AgencyInfo';
import {
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {
  AccessRoleContainer,
  OwnerContainer,
  PaymentInfoContainer,
  StepNavigationContainer,
} from '../../container';
import {MainNavigationProp} from '../../navigation/MainScreen';
import {CommonStyles} from '../../theme';
import {AccessManageContainer} from './AccessManageContainer';
import {ChangeOwner} from './ChangeOwner';
import {ChangePayment} from './ChangePayment';

const AccessManageComponent = () => {
  const {owner} = OwnerContainer.useContainer();
  const {accounts, getData} = AccessManageContainer.useContainer();
  const [error, setError] = React.useState<Error | undefined>();
  const {accessRole} = AccessRoleContainer.useContainer();

  const navigation = useNavigation<MainNavigationProp<'DashBoard'>>();
  const gotoAccount = () => {
    navigation.navigate('AccessManageMain');
  };

  React.useEffect(() => {
    async function fetchData() {
      if (owner?.id) {
        try {
          await getData(owner.id);
        } catch (err: any) {
          setError(err);
        }
      } else {
        setError(Error('対象IDが指定されていません'));
      }
    }
    fetchData();
  }, [owner, getData]);
  return (
    <FormBlock>
      <MaxWidth maxWidth={586}>
        <View>
          <Box fontWeight="fontWeightBold">所属ユーザー管理</Box>
          {error ? (
            <Text>{error.message}</Text>
          ) : accounts.length === 0 ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <VMargin />
              <AccessManageTable
                accounts={accounts.filter((account: any) => account.id)}
              />
              <VMargin />
              <MaxWidth maxWidth={586}>
                <TrimaButton
                  variant="outlined"
                  disabled={accessRole !== AccessManageRole.OWNER}
                  onClick={gotoAccount}>
                  所属ユーザーの管理
                </TrimaButton>
              </MaxWidth>
            </View>
          )}
        </View>
      </MaxWidth>
    </FormBlock>
  );
};

const SettingsComponent: React.FC = () => {
  const navigation = useNavigation();

  const gotoChangeOnwer = () => {
    navigation.navigate('SettingsChangeOwner');
  };
  const gotoChangePayment = () => {
    navigation.navigate('SettingsChangePayment');
  };
  const gotoSuspend = () => {
    navigation.navigate('SuspendMain');
  };

  const {paymentInfo} = PaymentInfoContainer.useContainer();
  const {owner} = OwnerContainer.useContainer();
  const [user, setUser] = React.useState<any>();
  const [paymentMethod, setPaymentMethod] = React.useState<any>();
  const {accessRole} = AccessRoleContainer.useContainer();
  const {step} = StepNavigationContainer.useContainer();

  const {paymentMethodId} = paymentInfo;

  useEffect(() => {
    async function fetchData() {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      setUser(user);

      if (paymentMethodId) {
        const data = {
          paymentMethodId,
        };
        //支払情報取得
        const paymentMethodResult =
          await SaaSBillingRepository.retrieveCustomerPaymentMethod(data);
        console.log(paymentMethodResult);
        setPaymentMethod(paymentMethodResult);
      } else {
        setPaymentMethod({});
      }
    }
    fetchData();
  }, [paymentMethodId]);

  return (
    <AccessManageContainer.Provider>
      <Container>
        <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
          <WithHint id="companySetting">
            <Menu>企業設定</Menu>
          </WithHint>
          <VMargin />
          <View style={CommonStyles.flex.full}>
            {!user || !owner || !paymentMethod ? (
              <ActivityIndicator size={60} />
            ) : (
              <View>
                <FormBlock>
                  <MaxWidth maxWidth={586}>
                    <Box fontWeight="fontWeightBold">企業情報</Box>
                    <VMargin />
                    <Text>法人名</Text>
                    <Text style={CommonStyles.margin.left}>{owner.hojin}</Text>
                    <VMargin />
                    <Text>代表者氏名</Text>
                    <Text style={CommonStyles.margin.left}>
                      {owner?.representative?.last}
                      {owner?.representative?.first}
                    </Text>
                    <VMargin />
                    <Text>所在地</Text>
                    <Text style={CommonStyles.margin.left}>
                      〒{owner?.address?.zip}
                    </Text>
                    <Text style={CommonStyles.margin.left}>
                      {owner?.address?.pref}
                      {owner?.address?.city}
                      {owner?.address?.detail}
                    </Text>
                    <Text style={CommonStyles.margin.left}>
                      {owner?.address?.building}
                    </Text>
                    <VMargin />
                    <Text>URL</Text>
                    <Text style={CommonStyles.margin.left}>
                      {owner.url ? owner.url : '-'}
                    </Text>
                    <VMargin />
                    <Text>担当部署/支店名</Text>
                    <Text style={CommonStyles.margin.left}>
                      {owner.chargeDept ? owner.chargeDept : '-'}
                    </Text>
                    <VMargin />
                    <Text>担当者氏名</Text>
                    <Text style={CommonStyles.margin.left}>
                      {owner?.charge?.last}
                      {owner?.charge?.first}
                    </Text>
                    <VMargin />
                    <Text>担当者電話番号</Text>
                    <Text style={CommonStyles.margin.left}>
                      {owner.chargePhone}
                    </Text>
                    <VMargin />
                    <TrimaButton
                      variant="outlined"
                      disabled={accessRole !== AccessManageRole.OWNER}
                      onClick={() => gotoChangeOnwer()}>
                      企業情報の変更
                    </TrimaButton>
                  </MaxWidth>
                </FormBlock>
                <AgencyInfoPreview />
                <VMargin />
                <FormBlock>
                  <MaxWidth maxWidth={586}>
                    <Box fontWeight="fontWeightBold">お支払い方法</Box>
                    <VMargin />
                    {paymentMethod.card ? (
                      <View>
                        <Text>クレジットカード</Text>
                        <VMargin />
                        <Text>ブランド名</Text>
                        <Text style={CommonStyles.margin.left}>
                          {paymentMethod.card.brand.toUpperCase()}
                        </Text>
                        <VMargin />
                        <Text>カード番号</Text>
                        <Text style={CommonStyles.margin.left}>
                          ************{paymentMethod.card.last4}
                        </Text>
                        <VMargin />
                        <Text>有効期限</Text>
                        <Text style={CommonStyles.margin.left}>
                          {paymentMethod.card.exp_month
                            .toString()
                            .padStart(2, '0')}
                          /{paymentMethod.card.exp_year}
                        </Text>
                      </View>
                    ) : (
                      <View>
                        <Text>Paid(銀行振込/口座振替/コンビニ払い)</Text>
                      </View>
                    )}
                    <VMargin />
                    <TrimaButton
                      variant="outlined"
                      disabled={accessRole !== AccessManageRole.OWNER}
                      onClick={() => gotoChangePayment()}>
                      お支払い方法の変更
                    </TrimaButton>
                  </MaxWidth>
                </FormBlock>
                <VMargin />
                <AccessManageComponent />
                <VMargin />

                {step === 'suspended' && (
                  <MaxWidth maxWidth={586}>
                    <TrimaButton
                      variant="outlined"
                      disabled={accessRole !== AccessManageRole.OWNER}
                      onClick={() => gotoSuspend()}>
                      企業の利用を再開
                    </TrimaButton>
                  </MaxWidth>
                )}
              </View>
            )}
          </View>
        </MaxWidth>
      </Container>
    </AccessManageContainer.Provider>
  );
};

export const Main: React.FC = () => {
  return <SettingsComponent />;
};

export const Settings = {
  Main,
  ChangeOwner,
  ChangePayment,
};
