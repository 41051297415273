import '@expo/match-media';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { CommonStyles } from '../../theme';

type UseResponsive = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  upperTablet: boolean;
  lowerTablet: boolean;
  isOverDesktop: boolean;
};

// とりあえず PC（Desktopと呼称）、タブレット、モバイルで区分
const MOBILE_BORDER_WIDTH = 768;
const TABLET_BORDER_WIDTH = 1024;

export function useResponsive(): UseResponsive {
  const isMobile = useMediaQuery({maxWidth: MOBILE_BORDER_WIDTH});
  const upperTablet = !isMobile;
  const lowerTablet = useMediaQuery({maxWidth: TABLET_BORDER_WIDTH});
  const isTablet = lowerTablet && !isMobile;
  const isDesktop = !isMobile && !isTablet;
  const isOverDesktop = useMediaQuery({
    minWidth: TABLET_BORDER_WIDTH * 2 - MOBILE_BORDER_WIDTH,
  });

  return {
    isMobile,
    isTablet,
    isDesktop,
    upperTablet,
    lowerTablet,
    isOverDesktop,
  };
}

type UseHeight = {
  // 画面高さ
  windowHeight: number;
  contentHeight: number;
};

export function useHeight(): UseHeight {
  const {height} = useWindowDimensions();
  const windowHeight = height;
  const contentHeight = windowHeight - CommonStyles.headerHeight;
  return {
    windowHeight,
    contentHeight,
  };
}

export const BRAKE_POINT = {
  tablet: MOBILE_BORDER_WIDTH,
  desktop: TABLET_BORDER_WIDTH,
};

export const DRAWER_WIDTH = {width: 375};

type UseWidth = {
  windowWidth: number;
  contentWidth: number; // ドロワー分除外
};

export function useWidth(): UseWidth {
  const {width} = useWindowDimensions();
  const {isDesktop} = useResponsive();
  const contentWidth = width - (isDesktop ? DRAWER_WIDTH.width : 0);
  return {
    windowWidth: width,
    contentWidth,
  };
}

export const Media: React.FC<{
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  children: React.ReactNode;
}> = ({mobile, tablet, desktop, children}) => {
  let query = {};
  if (mobile) {
    if (!desktop) {
      query = {
        maxWidth: tablet ? TABLET_BORDER_WIDTH - 1 : MOBILE_BORDER_WIDTH - 1,
      };
    }
  } else {
    if (desktop) {
      query = {
        minWidth: tablet ? MOBILE_BORDER_WIDTH : TABLET_BORDER_WIDTH,
      };
    } else {
      if (tablet) {
        query = {
          maxWidth: TABLET_BORDER_WIDTH - 1,
          minWidth: MOBILE_BORDER_WIDTH,
        };
      }
    }
  }
  return <MediaQuery {...query}>{children}</MediaQuery>;
};

// MediaQueryはとりあえずそのままエクスポートもしておく
export { MediaQuery };

