import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {useCSVDownloader} from 'react-papaparse';
import {SaaSCoupon, SaaSNews} from '../../API';
import {ShopContainer} from '../../container';
import {Helpers} from '../../helper';
import {
  DeliveryDetail,
  DeliveryType,
  SaasReportRepository,
} from '../../service';
import {CommonStyles} from '../../theme';
import {Preview as CPreview} from '../CouponPreview';
import {CreativePreview} from '../Creatives';
import {
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  VMargin,
  WithHint,
} from '../Elements';
import {Preview as NPreview} from '../NewsPreview';
import {useResponsive} from '../Responsive';
import {DeliveryReportCreativePreview} from './DeliveryReportCreativePreview';
import {DeliveryReportDemension} from './DeliveryReportDemension';
import {DeliveryReportGraph} from './DeliveryReportGraph';
import {DeliveryReportSums} from './DeliveryReportSums';
import {DeliveryReportTable} from './DeliveryReportTable';

type DataState = 'loading' | 'error' | 'exist' | 'nodata';

export const DeliveryReportComponent: React.FC<{
  id: string;
  type: DeliveryType;
}> = ({id, type}) => {
  const {selected} = ShopContainer.useContainer();
  const [dataState, setDataState] = React.useState<DataState>('loading');
  const [detail, setDetail] = React.useState<DeliveryDetail | undefined>();
  const {isMobile} = useResponsive();
  const {CSVDownloader} = useCSVDownloader();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const result = await SaasReportRepository.getDeliveryDetail(id, type);
        setDetail(result);
        setDataState(
          result.reports && result.reports.length > 0 ? 'exist' : 'nodata',
        );
      } catch (err: any) {
        setDataState('error');
      }
    }
    fetchData();
  }, [id, type]);

  if (dataState === 'loading') {
    return <ActivityIndicator size={40} />;
  }
  if (dataState === 'error' || !detail) {
    return <Text>データ取得エラー</Text>;
  }

  const directionStyle = isMobile ? {} : CommonStyles.flex.row;
  const blockStyle = isMobile ? {} : {width: 406};
  const term =
    dataState === 'exist'
      ? `${Helpers.toJapanDay(detail.reports[0].stamp)}〜${Helpers.toJapanDay(
          detail.reports[detail.reports.length - 1].stamp,
        )}`
      : 'データがありません';

  const exportCsv = () => {
    return detail.reports
      ? detail.reports.map((report) => {
          console.log(report);
          return {
            日付: report.stamp,
            一覧表示: report.listCount,
            閲覧回数: report.viewCount,
            閲覧人数: report.billCount,
            '広告料（閲覧人数×3円）': report.billAmount,
            保存: report.keptCount,
            リンククリック: report.clickedUrlCount,
            電話クリック: report.clickedPhoneCount,
            来店: selected?.legalUrl ? undefined : report.visited,
            利用: report.usedCount,
          };
        })
      : [];
  };

  return (
    <View>
      <Menu>{detail.title}</Menu>
      <VMargin />
      <FormBlock>
        <WithHint id="reportTerm">
          <Text>結果集計期間</Text>
        </WithHint>
        <VMargin />
        <Text>{term}</Text>
      </FormBlock>
      <VMargin />
      <FormBlock>
        <WithHint id="reportDeliveryReport">
          <Text>配信レポート</Text>
        </WithHint>
        <VMargin />
        <MaxWidth maxWidth={1008}>
          <DeliveryReportSums sums={detail.sums} />
          <VMargin />
          <DeliveryReportGraph reports={detail.reports} />
          <VMargin />
          <DeliveryReportTable reports={detail.reports} />
          <VMargin />
          <CSVDownloader
            filename={`report_delivery_${detail.title}`}
            bom={true}
            config={{
              header: true,
            }}
            data={exportCsv}>
            <TrimaButton variant="outlined">CSVを出力する</TrimaButton>
          </CSVDownloader>
        </MaxWidth>
      </FormBlock>
      <VMargin />
      <FormBlock>
        <WithHint id="reportUserAnalytics">
          <Text>ユーザー分析</Text>
        </WithHint>
        <MaxWidth maxWidth={1008}>
          <DeliveryReportDemension id={id} />
        </MaxWidth>
      </FormBlock>
      <VMargin />
      <View style={directionStyle}>
        <FormBlock style={blockStyle}>
          <Text>配信内容</Text>
          <VMargin />
          <Text>{detail.delivery.title}</Text>
          <View style={CommonStyles.flex.crossCenter}>
            {type === 'COUPON' && (
              <CPreview coupon={detail.delivery as SaaSCoupon} />
            )}
            {type === 'NEWS' && <NPreview news={detail.delivery as SaaSNews} />}
          </View>
        </FormBlock>
        <View style={[CommonStyles.margin.top, CommonStyles.margin.left]} />
        <View style={CommonStyles.flex.full}>
          <FormBlock>
            <DeliveryReportCreativePreview target={detail.target} />
          </FormBlock>
          <VMargin />
          <FormBlock>
            <CreativePreview creative={detail} />
          </FormBlock>
        </View>
      </View>
    </View>
  );
};
