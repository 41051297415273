import {Button} from '@mui/material';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {Pressable, StyleSheet, TouchableOpacity, View} from 'react-native';
import {IconButton, Portal} from 'react-native-paper';
import {Colors, CommonStyles} from '../../../../theme';
import {DialogBase, Text, VForm, VMargin} from '../../../Elements';
import {RadiusForm, radiusInfo} from '../../schema';

export type RadiusButtonProp = {
  onPress(): void;
};

export type RadiusSelectorProp = {
  onConfirm?(radius: number): void; // 半径選択後の処理
  onHide(): void;
  visible: boolean;
};

// 半径選択利用用hook. button,dialogに渡すパラメータを提供
// onConfirm は具体的な利用側での処理になるので、提供しない
export function useRadius(init: {changeDialogShow?(isShow: boolean): void}): {
  buttonProp: RadiusButtonProp;
  selectorPropBase: Omit<RadiusSelectorProp, 'onConfirm'>;
} {
  const [showDialog, setDialog] = React.useState<boolean>(false);
  const show = React.useCallback(() => {
    init.changeDialogShow && init.changeDialogShow(true);
    setDialog(true);
  }, [init]);
  const hide = React.useCallback(() => {
    init.changeDialogShow && init.changeDialogShow(false);
    setDialog(false);
  }, [init]);

  return {
    buttonProp: {
      onPress: show,
    },
    selectorPropBase: {
      onHide: hide,
      visible: showDialog,
    },
  };
}

export const RadiusButton: React.FC<RadiusButtonProp> = ({onPress}) => {
  return (
    <Pressable>
      {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ({hovered}: {hovered?: boolean}) => {
          const opacity = {opacity: hovered ? 1 : 0.7};
          return (
            <TouchableOpacity onPress={onPress} style={styles.radiusButton}>
              <View style={opacity}>
                <Icon />
              </View>
            </TouchableOpacity>
          );
        }
      }
    </Pressable>
  );
};

const Icon = React.memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32">
      <g transform="translate(0 4)">
        <text
          transform="translate(0 -4)"
          fill="#333"
          fontSize="16"
          fontFamily="YuGo-Bold, YuGothic, sans-serif"
          fontWeight="700">
          <tspan x="0" y="14">
            範囲
          </tspan>
          <tspan x="0" y="30">
            指定
          </tspan>
        </text>
      </g>
    </svg>
  );
});

export const RadiusSelector: React.FC<RadiusSelectorProp> = React.memo(
  (prop) => {
    if (!prop.visible) {
      return null;
    }
    return <SelectorInner {...prop} />;
  },
);

const SelectorInner: React.FC<RadiusSelectorProp> = ({
  visible,
  onConfirm,
  onHide,
}) => {
  const {getValues} = useFormContext<RadiusForm>();
  const onSubmit = () => {
    const radius = Number(getValues('radius') ?? 1);
    onConfirm && onConfirm(radius);
    onHide();
  };
  return (
    <Portal.Host>
      <DialogBase visible={visible} onDismiss={onHide} dismissable={false}>
        <View style={CommonStyles.flex.reverse}>
          <Button sx={{height: 40}} variant="contained" onClick={onSubmit}>
            指定
          </Button>
          <VMargin />
          <View style={[CommonStyles.flex.row, CommonStyles.flex.crossEnd]}>
            <Text>中心から半径 </Text>
            <View style={styles.select}>
              <VForm.Select<RadiusForm> {...radiusInfo} />
            </View>
          </View>
          <Text>一括範囲指定</Text>
          <VMargin />
        </View>
        <View style={styles.closeButtonArea}>
          <IconButton
            icon="close-circle"
            onPress={onHide}
            size={32}
            color={Colors.darkgray}
          />
        </View>
      </DialogBase>
    </Portal.Host>
  );
};

const styles = StyleSheet.create({
  radiusButton: {
    backgroundColor: 'white',
    width: 40,
    height: 40,
    borderRadius: 2,
    margin: 10,
    shadowColor: 'black',
    shadowOffset: {width: 1, height: 1},
    shadowOpacity: 0.2,
    shadowRadius: 2,
    ...CommonStyles.flex.center,
  },
  confirmButton: {
    height: 40,
  },
  select: {
    width: 100,
  },
  closeButtonArea: {
    position: 'absolute',
    top: 0,
    right: 8,
  },
});
