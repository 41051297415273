// GraphQL関係
import API, {graphqlOperation, GraphQLResult} from '@aws-amplify/api';
import {GraphQlRepository} from '../_proto/services/GraphQlRepository';
import {
  CreateAccessManageMutation,
  CreateOwnerMutation,
  CreateSaaSAccessManageInput,
  CreateSaaSOwnerInput,
  CreateSaaSShopGroupInput,
  CreateShopGroupMutation,
  DeleteAccessManageMutation,
  DeleteDeliveryTargetMutation,
  DeleteSaaSAccessManageInput,
  DeleteSaaSDeliveryTargetInput,
  DeleteSaaSShopGroupInput,
  DeleteShopGroupMutation,
  GetAccessManageQuery,
  GetCouponQuery,
  GetDeliveryHistoryQuery,
  GetDeliveryTargetQuery,
  GetNewsHistoryQuery,
  GetNewsQuery,
  GetOwnerQuery,
  GetPaymentInfoQuery,
  GetSaaSAccessManageQuery,
  GetSaaSCouponQuery,
  GetSaaSDeliveryTargetQuery,
  GetSaaSOwnerQuery,
  GetSaaSPaymentInfoQuery,
  GetSaaSShopGroupQuery,
  GetShopGroupQuery,
  GetShopQuery,
  ListAccessManageQuery,
  ListDeliveryHistoryQuery,
  ListNewsHistoryQuery,
  ListOwnerQuery,
  MigrateOwnerAccessInput,
  MigrateOwnerAccessMutation,
  SaaSAccessManageWithName,
  SaaSCoupon,
  SaaSDeliveryHistory,
  SaaSDeliveryTarget,
  SaaSNews,
  SaaSNewsHistory,
  SaaSShop,
  SaaSShopExcludeVisitor,
  SaaSShopExcludeVisitorByShopIdQuery,
  SaaSShopGroup,
  SetGroup2ShopInput,
  SetGroup2ShopMutation,
  UpdateAccessManageEmailInput,
  UpdateAccessManageEmailMutation,
  UpdateOwnerMutation,
  UpdatePaymentInfoMutation,
  UpdateSaaSOwnerInput,
  UpdateSaaSPaymentInfoInput,
  UpdateSaaSShopGroupInput,
  UpdateShopGroupMutation,
} from '../API';
import {
  createAccessManage as createAccessManageMutation,
  createOwner as createOwnerMutation,
  createShopGroup as createShopGroupMutation,
  deleteAccessManage as deleteAccessManageMutation,
  deleteDeliveryTarget as deleteDeliveryTargetMutation,
  deleteShopGroup as deleteShopGroupMutation,
  migrateOwnerAccess as migrateOwnerAccessMutation,
  setGroup2Shop as setGroup2ShopMutation,
  updateAccessManageEmail as updateAccessManageEmailMutation,
  updateOwner as updateOwnerMutation,
  updatePaymentInfo as updatePaymentInfoMutation,
  updateShopGroup as updateShopGroupMutation,
} from '../graphql/mutations';
import {
  getAccessManage as getAccessManageQuery,
  getCoupon as getCouponQuery,
  getDeliveryHistory as getDeliveryHistoryQuery,
  getDeliveryTarget,
  getNewsHistory as getNewsHistoryQuery,
  getNews as getNewsQuery,
  getOwner as getOwnerQuery,
  getPaymentInfo as getPaymentInfoQuery,
  getShopGroup as getShopGroupQuery,
  getShop as getShopQuery,
  listAccessManage,
  listDeliveryHistory,
  listNewsHistory,
  listOwner,
  listShop,
  listShopGroup,
  saaSShopExcludeVisitorByShopId,
} from '../graphql/queries';

// graphQLの返り値の型はSaasOwnerとは正確には違うみたいなので、一応ここではその型で指定
// 各プロパティがnullだったりする可能性がある
export type SaasOwnerQuery = GetSaaSOwnerQuery['getSaaSOwner'];
export type SaasPaymentInfoQuery =
  GetSaaSPaymentInfoQuery['getSaaSPaymentInfo'];
export type SaaSCouponQuery = GetSaaSCouponQuery['getSaaSCoupon'];
export type SaaSAccessManageQuery =
  GetSaaSAccessManageQuery['getSaaSAccessManage'];
export type SaasShopGroupQuery = GetSaaSShopGroupQuery['getSaaSShopGroup'];
export type SaasDeliveryTargetQuery =
  GetSaaSDeliveryTargetQuery['getSaaSDeliveryTarget'];

// Todo 例外仕様を共通化する（Axiosとも？）
// GraphQL処理の例外ハンドリングユーティリティ
function fetchError<T>(error: GraphQLResult<T> | Error): Error {
  // GraphQLのエラー時は errors プロパティに複数のエラーが入ってくる形みたいなので、とりあえず1つだけ再送出しておく
  if ('data' in error && error.errors?.length) {
    return error.errors[0];
  }
  return error as Error;
}

/**
 * オーナー情報の登録
 * @param input オーナー情報
 * @throws 登録失敗時に発生
 */
async function createOwner(
  input: CreateSaaSOwnerInput,
): Promise<SaasOwnerQuery> {
  try {
    const result = (await API.graphql(
      graphqlOperation(createOwnerMutation, {
        input: {...input},
      }),
    )) as GraphQLResult<CreateOwnerMutation>;
    console.log(result);
    if (!result.data) {
      throw new Error('[createOwner] result.data is undefined');
    }
    return result.data.createOwner;
  } catch (error: any) {
    throw fetchError(error);
  }
}

/**
 * オーナー情報の登録
 * @param input オーナー情報
 * @throws 登録失敗時に発生
 */
async function updateOwner(
  input: UpdateSaaSOwnerInput,
): Promise<SaasOwnerQuery> {
  try {
    const result = (await API.graphql(
      graphqlOperation(updateOwnerMutation, {
        input: {...input},
      }),
    )) as GraphQLResult<UpdateOwnerMutation>;
    console.log(result);
    if (!result.data) {
      throw new Error('[updateOwner] result.data is undefined');
    }
    return result.data.updateOwner;
  } catch (error: any) {
    throw fetchError(error);
  }
}

/**
 * オーナー情報の取得。まだ未登録なら例外なしでnullが返ってくるはず。
 * @throws 取得失敗時に発生
 */
async function getOwner(
  id: string | undefined = undefined,
): Promise<SaasOwnerQuery> {
  try {
    const result = (await API.graphql(
      graphqlOperation(getOwnerQuery, {id}),
    )) as GraphQLResult<GetOwnerQuery>;
    if (!result.data) {
      throw new Error('[getOwner] result.data is undefined');
    }
    return result.data.getOwner;
  } catch (error: any) {
    throw fetchError(error);
  }
}

async function getListOwner(id: string): Promise<SaasOwnerQuery[]> {
  try {
    const result = (await API.graphql(
      graphqlOperation(listOwner, {id}),
    )) as GraphQLResult<ListOwnerQuery>;
    if (!result.data?.listOwner) {
      throw new Error(
        '[getDeliveryHistoryList] result.data / listOwner is undefined',
      );
    }
    return result.data.listOwner.items as SaasOwnerQuery[];
  } catch (error: any) {
    throw fetchError(error);
  }
}

async function getPaymentInfo(id: string): Promise<SaasPaymentInfoQuery> {
  try {
    const result = (await API.graphql(
      graphqlOperation(getPaymentInfoQuery, {id}),
    )) as GraphQLResult<GetPaymentInfoQuery>;
    if (!result.data) {
      throw new Error('[getPaymentInfo] result.data is undefined');
    }
    return result.data.getPaymentInfo;
  } catch (error: any) {
    throw fetchError(error);
  }
}

async function updatePaymentInfo(
  input: UpdateSaaSPaymentInfoInput,
): Promise<SaasPaymentInfoQuery> {
  try {
    const result = (await API.graphql(
      graphqlOperation(updatePaymentInfoMutation, {
        input,
      }),
    )) as GraphQLResult<UpdatePaymentInfoMutation>;
    console.log(result);
    if (!result.data) {
      throw new Error('[updatePaymentInfo] result.data is undefined');
    }
    return result.data.updatePaymentInfo;
  } catch (error: any) {
    throw fetchError(error);
  }
}

async function getShopExcludeVisitorList(
  shopId: string,
): Promise<SaaSShopExcludeVisitor[]> {
  const result = (await API.graphql(
    graphqlOperation(saaSShopExcludeVisitorByShopId, {shopId}),
  )) as GraphQLResult<SaaSShopExcludeVisitorByShopIdQuery>;
  if (!result.data?.saaSShopExcludeVisitorByShopId) {
    throw new Error(
      '[getShopList] result.data / saaSShopExcludeVisitorByShopId is undefined',
    );
  }
  console.log(result.data?.saaSShopExcludeVisitorByShopId?.items);
  return result.data?.saaSShopExcludeVisitorByShopId
    ?.items as SaaSShopExcludeVisitor[];
}

/**
 * 店舗リストの取得。まだ未登録なら例外なしで空配列が返ってくるはず。
 * @throws 取得失敗時に発生
 */
async function getShopList(ownerId: string): Promise<SaaSShop[]> {
  const result = await GraphQlRepository.query(listShop, 'listShop', {
    ownerId,
    sortDirection: 'DESC',
  });

  if (!result) {
    throw new Error(
      '[getShopList] result.data / saaSShopByOwnerId is undefined',
    );
  }

  console.log('[getShopList]', result);
  return result;
}

/**
 * 店舗の取得
 * @param id 店舗ID
 * @throws 取得失敗時に発生
 */
async function getShop(
  id: string,
): Promise<NonNullable<GetShopQuery['getShop']>> {
  try {
    const result = (await API.graphql(
      graphqlOperation(getShopQuery, {id}),
    )) as GraphQLResult<GetShopQuery>;
    if (result.data && result.data.getShop) {
      return result.data.getShop;
    }
  } catch (err: any) {
    throw fetchError(err);
  }
  throw new Error('[getShop] get error');
}

/**
 * 店舗の取得
 * @param id 店舗ID
 * @throws 取得失敗時に発生
 */
async function setGroup2Shop(
  input: SetGroup2ShopInput,
): Promise<NonNullable<SetGroup2ShopMutation['setGroup2Shop']>> {
  try {
    const result = (await API.graphql(
      graphqlOperation(setGroup2ShopMutation, {input: {...input}}),
    )) as GraphQLResult<SetGroup2ShopMutation>;
    if (result.data && result.data.setGroup2Shop) {
      return result.data.setGroup2Shop;
    }
  } catch (err: any) {
    throw fetchError(err);
  }
  throw new Error('[setGroup2Shop] get error');
}

/**
 * 店舗グループの取得
 * @param id 店舗ID
 * @throws 取得失敗時に発生
 */
async function getShopGroup(
  id: string,
): Promise<NonNullable<GetShopGroupQuery['getShopGroup']>> {
  try {
    const result = (await API.graphql(
      graphqlOperation(getShopGroupQuery, {id}),
    )) as GraphQLResult<GetShopGroupQuery>;
    if (result.data && result.data.getShopGroup) {
      return result.data.getShopGroup;
    }
  } catch (err: any) {
    throw fetchError(err);
  }
  throw new Error('[getShopGroup] get error');
}

/**
 * クーポン情報の取得
 * @param id 取得対象のクーポンID
 * @throws 取得失敗時に発生
 */
async function getCoupon(id: string): Promise<SaaSCoupon> {
  try {
    const result = (await API.graphql(
      graphqlOperation(getCouponQuery, {id}),
    )) as GraphQLResult<GetCouponQuery>;
    if (result.data?.getCoupon) {
      if (result.data.getCoupon.discount) {
        return result.data.getCoupon as SaaSCoupon;
      } else {
        return Object.assign(result.data.getCoupon, {
          discount: undefined,
          cvLink: undefined,
        });
      }
    }
  } catch (err: any) {
    throw fetchError(err);
  }
  throw '[getCoupon] result undefined';
}

/**
 * ニュース情報の取得
 * @param id 取得対象のニュースID
 * @throws 取得失敗時に発生
 */
async function getNews(id: string): Promise<SaaSNews> {
  try {
    const result = (await API.graphql(
      graphqlOperation(getNewsQuery, {id}),
    )) as GraphQLResult<GetNewsQuery>;
    if (result.data?.getNews) {
      return result.data.getNews as SaaSNews;
    }
  } catch (err: any) {
    throw fetchError(err);
  }
  throw '[getNews] result undefined';
}

/**
 * 配信先情報の取得
 * @param id 取得対象の配信先ID
 * @throws 取得失敗時に発生
 */
async function getTarget(id: string): Promise<SaaSDeliveryTarget> {
  try {
    const result = (await API.graphql(
      graphqlOperation(getDeliveryTarget, {id}),
    )) as GraphQLResult<GetDeliveryTargetQuery>;
    if (result.data?.getDeliveryTarget) {
      const target = result.data?.getDeliveryTarget;
      if (target.areaOption === null) {
        target.areaOption = undefined;
      }
      if (target.stayOption === null) {
        target.stayOption = undefined;
      }
      if (target.userOption === null) {
        target.userOption = undefined;
      }

      return target as SaaSDeliveryTarget;
    }
  } catch (err: any) {
    throw fetchError(err);
  }
  throw '[getTarget] result undefined';
}

async function getDeliveryHistory(
  id: string,
): Promise<NonNullable<GetDeliveryHistoryQuery['getDeliveryHistory']>> {
  const result = (await API.graphql(
    graphqlOperation(getDeliveryHistoryQuery, {id}),
  )) as GraphQLResult<GetDeliveryHistoryQuery>;

  if (!result.data || !result.data.getDeliveryHistory) {
    throw Error('[getDeliveryHistory] result undefined');
  }

  return result.data.getDeliveryHistory;
}

/**
 * クーポン配信一覧取得
 * @param shopId 店舗ID
 * @throws 取得失敗
 */
async function getDeliveryHistoryList(
  shopId: string,
): Promise<SaaSDeliveryHistory[]> {
  try {
    const result = await GraphQlRepository.query(
      listDeliveryHistory,
      'listDeliveryHistory',
      {shopId, sortDirection: 'DESC'},
    );

    if (!result) {
      throw new Error(
        '[getDeliveryHistoryList] result.data / listDeliveryHistory is undefined',
      );
    }

    return result;
  } catch (err: any) {
    throw fetchError(err);
  }
}

/**
 * 最新のクーポン配信取得
 * @param shopId 店舗ID
 * @throws 取得失敗
 */
async function getDeliveryHistoryLatest(shopId: string): Promise<any> {
  try {
    const result = (await API.graphql(
      graphqlOperation(listDeliveryHistory, {
        shopId,
        sortDirection: 'DESC',
        limit: 1,
      }),
    )) as GraphQLResult<ListDeliveryHistoryQuery>;
    if (!result.data?.listDeliveryHistory) {
      throw new Error(
        '[getDeliveryHistoryList] result.data / listDeliveryHistory is undefined',
      );
    }
    return result.data.listDeliveryHistory.items as SaaSDeliveryHistory[];
  } catch (err: any) {
    throw fetchError(err);
  }
}

/**
 * ニュース配信履歴取得
 * @param id 取得対象ID
 * @throws 取得失敗
 */
async function getNewsHistory(
  id: string,
): Promise<NonNullable<GetNewsHistoryQuery['getNewsHistory']>> {
  const result = (await API.graphql(
    graphqlOperation(getNewsHistoryQuery, {id}),
  )) as GraphQLResult<GetNewsHistoryQuery>;

  if (!result.data || !result.data.getNewsHistory) {
    throw Error('[getNewsHistory] result undefined');
  }

  return result.data.getNewsHistory;
}

/**
 * お知らせ配信一覧取得
 * @param shopId 店舗ID
 * @throws 取得失敗
 */
async function getNewsHistoryList(shopId: string): Promise<SaaSNewsHistory[]> {
  try {
    const result = await GraphQlRepository.query(
      listNewsHistory,
      'listNewsHistory',
      {shopId, sortDirection: 'DESC'},
    );

    if (!result) {
      throw new Error(
        '[getNewsHistoryList] result.data / listNewsHistory is undefined',
      );
    }

    return result;
  } catch (err: any) {
    throw fetchError(err);
  }
}

/**
 * 最新のお知らせ配信取得
 * @param shopId 店舗ID
 * @throws 取得失敗
 */
async function getNewsHistoryLatest(
  shopId: string,
): Promise<SaaSNewsHistory[]> {
  try {
    const result = (await API.graphql(
      graphqlOperation(listNewsHistory, {
        shopId,
        sortDirection: 'DESC',
        limit: 1,
      }),
    )) as GraphQLResult<ListNewsHistoryQuery>;
    if (!result.data?.listNewsHistory) {
      throw new Error(
        '[getDeliveryHistoryList] result.data / listNewsHistory is undefined',
      );
    }
    return result.data.listNewsHistory.items as SaaSNewsHistory[];
  } catch (err: any) {
    throw fetchError(err);
  }
}

async function getAccessManageList(
  ownerId: string,
): Promise<SaaSAccessManageWithName[]> {
  const result = (await API.graphql(
    graphqlOperation(listAccessManage, {ownerId}),
  )) as GraphQLResult<ListAccessManageQuery>;
  if (!result.data?.listAccessManage) {
    throw new Error(
      '[getShopList] result.data / saaSShopExcludeVisitorByShopId is undefined',
    );
  }
  console.log(result.data?.listAccessManage?.items);
  return result.data?.listAccessManage?.items as SaaSAccessManageWithName[];
}

async function createAccessManage(
  input: CreateSaaSAccessManageInput,
): Promise<SaaSAccessManageQuery> {
  const result = (await API.graphql(
    graphqlOperation(createAccessManageMutation, {input: {...input}}),
  )) as GraphQLResult<CreateAccessManageMutation>;
  console.log(result);
  if (!result.data) {
    throw new Error('[createAccessManage] result.data is undefined');
  }
  return result.data.createAccessManage;
}

async function deleteAccessManage(
  input: DeleteSaaSAccessManageInput,
): Promise<SaaSAccessManageQuery> {
  const result = (await API.graphql(
    graphqlOperation(deleteAccessManageMutation, {input: {...input}}),
  )) as GraphQLResult<DeleteAccessManageMutation>;
  console.log(result);
  if (!result.data) {
    throw new Error('[deleteAccessManage] result.data is undefined');
  }
  return result.data.deleteAccessManage;
}

async function migrateOwnerAccess(
  input: MigrateOwnerAccessInput,
): Promise<SaaSAccessManageQuery> {
  const result = (await API.graphql(
    graphqlOperation(migrateOwnerAccessMutation, {input: {...input}}),
  )) as GraphQLResult<MigrateOwnerAccessMutation>;
  console.log(result);
  if (!result.data) {
    throw new Error('[deleteAccessManage] result.data is undefined');
  }
  return result.data.migrateOwnerAccess;
}

async function updateAccessManageEmail(
  input: UpdateAccessManageEmailInput,
): Promise<any> {
  const result = (await API.graphql(
    graphqlOperation(updateAccessManageEmailMutation, {input: {...input}}),
  )) as GraphQLResult<UpdateAccessManageEmailMutation>;
  console.log(result);
  if (!result.data) {
    throw new Error('[deleteAccessManage] result.data is undefined');
  }
  return result.data.updateAccessManageEmail;
}

async function getAccessManage(
  id: string,
): Promise<NonNullable<GetAccessManageQuery['getAccessManage']>> {
  const result = (await API.graphql(
    graphqlOperation(getAccessManageQuery, {id}),
  )) as GraphQLResult<GetAccessManageQuery>;

  if (!result.data || !result.data.getAccessManage) {
    throw Error('[getAccessManage] result undefined');
  }

  return result.data.getAccessManage;
}

async function getShopGroupList(ownerId: string): Promise<SaaSShopGroup[]> {
  const result = await GraphQlRepository.query(listShopGroup, 'listShopGroup', {
    ownerId,
    sortDirection: 'DESC',
  });

  if (!result) {
    throw new Error(
      '[getShopList] result.data / saaSShopByOwnerId is undefined',
    );
  }

  console.log('[getShopList]', result);
  return result;
}

async function createShopGroup(
  input: CreateSaaSShopGroupInput,
): Promise<SaasShopGroupQuery> {
  try {
    const result = (await API.graphql(
      graphqlOperation(createShopGroupMutation, {
        input: {...input},
      }),
    )) as GraphQLResult<CreateShopGroupMutation>;
    console.log(result);
    if (!result.data) {
      throw new Error('[createShopGroup] result.data is undefined');
    }
    return result.data.createShopGroup;
  } catch (error: any) {
    throw fetchError(error);
  }
}

async function updateShopGroup(
  input: UpdateSaaSShopGroupInput,
): Promise<NonNullable<SaasShopGroupQuery>> {
  try {
    const result = (await API.graphql(
      graphqlOperation(updateShopGroupMutation, {
        input: {...input},
      }),
    )) as GraphQLResult<UpdateShopGroupMutation>;
    console.log(result);
    if (!result.data || !result.data.updateShopGroup) {
      throw new Error('[updateShopGroup] result.data is undefined');
    }
    return result.data.updateShopGroup;
  } catch (error: any) {
    throw fetchError(error);
  }
}

async function deleteShopGroup(
  input: DeleteSaaSShopGroupInput,
): Promise<NonNullable<SaasShopGroupQuery>> {
  try {
    const result = (await API.graphql(
      graphqlOperation(deleteShopGroupMutation, {
        input: {...input},
      }),
    )) as GraphQLResult<DeleteShopGroupMutation>;
    console.log(result);
    if (!result.data || !result.data.deleteShopGroup) {
      throw new Error('[updateShopGroup] result.data is undefined');
    }
    return result.data.deleteShopGroup;
  } catch (error: any) {
    throw fetchError(error);
  }
}

async function deleteDeliveryTarget(
  input: DeleteSaaSDeliveryTargetInput,
): Promise<NonNullable<SaasDeliveryTargetQuery>> {
  try {
    const result = (await API.graphql(
      graphqlOperation(deleteDeliveryTargetMutation, {
        input: {...input},
      }),
    )) as GraphQLResult<DeleteDeliveryTargetMutation>;
    console.log(result);
    if (!result.data || !result.data.deleteDeliveryTarget) {
      throw new Error('[updateShopGroup] result.data is undefined');
    }
    return result.data.deleteDeliveryTarget;
  } catch (error: any) {
    throw fetchError(error);
  }
}

export const graphQLService = {
  createOwner,
  updateOwner,
  getOwner,
  getShop,
  setGroup2Shop,
  getShopGroup,
  getShopList,
  getPaymentInfo,
  updatePaymentInfo,
  getCoupon,
  getNews,
  getTarget,
  getDeliveryHistory,
  getDeliveryHistoryList,
  getDeliveryHistoryLatest,
  getNewsHistory,
  getNewsHistoryList,
  getNewsHistoryLatest,
  getShopExcludeVisitorList,
  getListOwner,
  getAccessManageList,
  createAccessManage,
  deleteAccessManage,
  migrateOwnerAccess,
  updateAccessManageEmail,
  getAccessManage,
  getShopGroupList,
  createShopGroup,
  updateShopGroup,
  deleteShopGroup,
  deleteDeliveryTarget,
};
