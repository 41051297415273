import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import {CommonActions, useNavigation, useRoute} from '@react-navigation/native';
import {Auth} from 'aws-amplify';
import React from 'react';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator, TextInput} from 'react-native-paper';
import {AccessManageRole, GetAccessManageQuery} from '../../../API';
import {BRAKE_POINT} from '../../../components';
import {
  BackLink,
  Container,
  FormBlock,
  HelperText,
  MaxWidth,
  Menu,
  Text,
  TextInputProps,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
  yup,
  yupUtil,
} from '../../../components/Elements';
import {
  AccessRoleContainer,
  AuthContainer,
  OwnerContainer,
  StepNavigationContainer,
} from '../../../container';
import {useCustomNav} from '../../../helper';
import {
  MainNavigationProp,
  MainRouteProp,
} from '../../../navigation/MainScreen';
import {graphQLService} from '../../../service';
import {Colors, CommonStyles} from '../../../theme';
import {AccountContainer} from '../../Account/AccountContainer';
import {AccessManageContainer} from '../AccessManageContainer';

type RProp = MainRouteProp<'AccessManageEdit'>;

type ConfirmFormData = {
  email: string;
  password: string;
};
const schemaConfirmFormData: yup.SchemaOf<ConfirmFormData> = yup
  .object()
  .shape({
    email: yupUtil.email(),
    password: yupUtil.stringRequired(),
  });

const formInfos: {
  [P in keyof ConfirmFormData]: TextInputProps<ConfirmFormData>;
} = {
  email: {
    name: 'email',
    label: 'メールアドレス',
  },
  password: {name: 'password', label: 'パスワード', secureTextEntry: true},
};

const roleLabels = {
  [AccessManageRole.OWNER]: 'オーナー',
  [AccessManageRole.ADMIN]: '一般',
};

const RemoveConfirmDialog: React.FC<{
  open: boolean;
  onClose(): void;
  accessManage: NonNullable<GetAccessManageQuery['getAccessManage']>;
}> = ({open, onClose, accessManage}) => {
  const navigation = useNavigation<MainNavigationProp<'AccessManageEdit'>>();
  const {deleteData} = AccessManageContainer.useContainer();
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClose = () => {
    onClose();
  };
  const remove = async () => {
    setIsLoading(true);
    try {
      await deleteData(accessManage.id);
      navigation.dispatch((state) => {
        return CommonActions.reset({
          ...state,
          history: state.history.slice(0, -1),
        });
      });
      navigation.navigate('AccessManageMain');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: Colors.lightgray,
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        本当に
        {accessManage.userId && accessManage.familyName
          ? `${accessManage.familyName} ${accessManage.givenName} (${accessManage.email}) `
          : `${accessManage.email} `}
        さんの
        <Typography variant="inherit" color="secondary">
          所属を解除
        </Typography>
        しますか？
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            ・解除されたユーザーはトリマ広告ダイレクトの利用ができなくなります。
          </Typography>
          <Typography>
            ・解除後もアカウントは残ります。アカウントを削除したい場合は、本人のアカウント設定から、トリマ広告ダイレクトの利用を停止して、削除してください。
          </Typography>
          <VMargin />
          <TrimaLoadingButton
            color="error"
            variant="contained"
            onClick={remove}
            disabled={isLoading}
            loading={isLoading}
            loadingPosition="start">
            所属を解除する
          </TrimaLoadingButton>
          <VMargin />
          <TrimaButton variant="outlined" onClick={handleClose}>
            キャンセル
          </TrimaButton>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const MigrateConfirmDialog: React.FC<{
  open: boolean;
  onClose(): void;
  accessManage: NonNullable<GetAccessManageQuery['getAccessManage']>;
}> = ({open, onClose, accessManage}) => {
  const navigation = useNavigation();
  const {migrationOwner, getData} = AccessManageContainer.useContainer();
  const {owner} = OwnerContainer.useContainer();
  const {checkInitData} = StepNavigationContainer.useContainer();
  const {user} = AuthContainer.useContainer();
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClose = () => {
    onClose();
  };
  const migrateOwner = async () => {
    setIsLoading(true);
    if (owner) {
      await migrationOwner(accessManage.id);
      await getData(owner.id);
      navigation.navigate('SettingsMain');
      await checkInitData(owner.id, user.username);
    }
    setIsLoading(false);
  };
  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: Colors.lightgray,
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        本当に
        {`${accessManage.familyName} ${accessManage.givenName} (${accessManage.email}) `}
        さんを
        <Typography variant="inherit" color="secondary">
          オーナー
        </Typography>
        にしますか？
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            ・オーナーの権限を旧オーナーに戻すためには、新オーナー側の対応が必要になります。
          </Typography>
          <Typography>
            ・オーナーは一般ユーザーができることに加えて、企業情報の変更や支払い方法の変更、一般ユーザーの管理が可能です。
          </Typography>
          <VMargin />
          <TrimaLoadingButton
            color="error"
            variant="contained"
            onClick={migrateOwner}
            disabled={isLoading}
            loading={isLoading}
            loadingPosition="start">
            オーナーにする
          </TrimaLoadingButton>
          <VMargin />
          <TrimaButton variant="outlined" onClick={handleClose}>
            キャンセル
          </TrimaButton>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const Submit: React.FC<{
  accessManage: NonNullable<GetAccessManageQuery['getAccessManage']>;
}> = ({accessManage}) => {
  const {
    handleSubmit,
    watch,
    formState: {errors},
    clearErrors,
    setValue,
  } = useFormContext<ConfirmFormData>();
  const {user} = AuthContainer.useContainer();
  const [showError, setShowError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    clearErrors();
    setShowError('');
    setOpen(true);
  };
  const handleClose = () => {
    clearErrors();
    setShowError('');
    setOpen(false);
  };

  React.useEffect(() => {
    setValue('email', user.attributes.email ?? '');
  }, [setValue, user]);

  const onSubmit: SubmitHandler<ConfirmFormData> = async (data) => {
    try {
      // パスワードチェック
      await Auth.signIn(user.attributes.email, data.password);
      handleClickOpen();
    } catch (err: any) {
      setShowError(err.message);
    }
  };

  const onSubmitError: SubmitErrorHandler<ConfirmFormData> = () =>
    setShowError('フォームの入力に誤りがあります');

  const disabled = !watch('password') || !!errors.password;

  React.useEffect(() => {
    if (!errors.password) {
      setShowError('');
    }
  }, [errors.password]);

  return (
    <View>
      <TrimaButton
        color="error"
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(onSubmit, onSubmitError)}>
        オーナー権限を移行する
      </TrimaButton>
      <MigrateConfirmDialog
        open={open}
        onClose={handleClose}
        accessManage={accessManage}
      />
      <HelperText type="error">{showError}</HelperText>
    </View>
  );
};

export const Edit: React.FC = React.memo(() => {
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const navigation = useCustomNav();
  const cancel = () => {
    navigation.goBack('AccessManageMain');
  };
  const [error, setError] = React.useState<Error | undefined>();
  const [accessManage, setAccessManage] = React.useState<
    undefined | NonNullable<GetAccessManageQuery['getAccessManage']>
  >();
  const {accessRole} = AccessRoleContainer.useContainer();

  const [secureTextEntry, setSecureTextEntry] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    async function fetchData() {
      if (id) {
        try {
          const res = await graphQLService.getAccessManage(id);
          setAccessManage(res);
        } catch (err: any) {
          setError(err);
        }
      } else {
        setError(Error('対象IDが指定されていません'));
      }
    }
    fetchData();
  }, [id]);

  return (
    <AccessManageContainer.Provider>
      <Container>
        <MaxWidth
          maxWidth={BRAKE_POINT.desktop}
          style={CommonStyles.padding.all}>
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label="変更せずに戻る" onClick={cancel} />
          </Box>
          {error ? (
            <Text>{error.message}</Text>
          ) : !accessManage ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <Menu>
                {accessManage.userId && accessManage.familyName
                  ? `${accessManage.familyName} ${accessManage.givenName} (${accessManage.email}) `
                  : `${accessManage.email} `}
                さんを変更
              </Menu>
              <VMargin />
              {accessRole !== AccessManageRole.OWNER ? (
                <Typography>アクセス権限がありません</Typography>
              ) : (
                <View>
                  <FormBlock>
                    <MaxWidth maxWidth={586}>
                      <Box fontWeight="fontWeightBold">所属の解除</Box>
                      <VMargin />
                      <Typography>
                        企業に所属するアカウントから解除します。
                      </Typography>
                      <Typography>
                        複数の企業に所属している方は、解除されていない企業でトリマ広告ダイレクトを使い続けることができます。
                      </Typography>
                      <Typography>
                        所属する企業がなくなってしまった方は、新しい企業を作成することで、トリマ広告ダイレクトを使用することができます。
                      </Typography>
                      <VMargin />
                      <Typography>
                        アカウントを削除したい場合は、
                        <Link
                          href="https://support.shop.trip-mile.com/hc/ja"
                          target="_blank"
                          rel="noopener"
                          underline="hover">
                          こちら
                        </Link>
                        からお問合せをお願いいたします。
                      </Typography>
                      <VMargin />
                      <TrimaButton
                        color="error"
                        variant="contained"
                        onClick={handleClickOpen}
                        disabled={!accessManage}>
                        所属を解除する
                      </TrimaButton>
                      <RemoveConfirmDialog
                        open={open}
                        onClose={handleClose}
                        accessManage={accessManage}
                      />
                    </MaxWidth>
                  </FormBlock>
                  <VMargin />
                  {accessManage.userId && accessManage.familyName && (
                    <FormBlock>
                      <MaxWidth maxWidth={586}>
                        <Box fontWeight="fontWeightBold">
                          オーナー権限の移行
                        </Box>
                        <VMargin />
                        <Typography>一般ユーザーにできること</Typography>
                        <Typography>・広告/配信先の作成</Typography>
                        <Typography>・配信の開始、終了</Typography>
                        <Typography>・配信結果や分析内容の閲覧</Typography>
                        <VMargin />
                        <Typography>オーナーユーザーにできること</Typography>
                        <Typography>・一般ユーザーができること全て</Typography>
                        <Typography>・企業情報の変更</Typography>
                        <Typography>・支払方法の変更</Typography>
                        <Typography>・一般ユーザーの管理</Typography>
                        <VMargin />
                        <Typography>
                          {accessManage.familyName} {accessManage.givenName}さん
                          の現在の権限
                        </Typography>
                        <Typography color="secondary">
                          {accessManage.role
                            ? roleLabels[accessManage.role]
                            : '-'}
                        </Typography>
                        <VMargin />
                        <Typography>
                          <Typography
                            component="span"
                            variant="inherit"
                            color="secondary">
                            オーナー権限を移行
                          </Typography>
                          すると、元々オーナー権限を持っていた方は
                          <Typography
                            component="span"
                            variant="inherit"
                            color="secondary">
                            一般ユーザーに変更
                          </Typography>
                          されます。
                        </Typography>
                        <VMargin />
                        <Typography>
                          ※所有権を移行するためには追加の認証が必要です。
                        </Typography>
                        <VMargin />
                        <VForm.Provider<ConfirmFormData>
                          schema={schemaConfirmFormData}>
                          <AccountContainer.Provider>
                            <VForm.Text<ConfirmFormData>
                              {...formInfos.email}
                              disabled={true}
                            />
                            <VForm.Text<ConfirmFormData>
                              label={formInfos.password.label}
                              name={formInfos.password.name}
                              secureTextEntry={secureTextEntry}
                              right={
                                <TextInput.Icon
                                  size={24}
                                  style={styles.eye}
                                  name={secureTextEntry ? 'eye' : 'eye-off'}
                                  onPress={() => {
                                    setSecureTextEntry(!secureTextEntry);
                                  }}
                                />
                              }
                            />
                            <VMargin />
                            <Submit accessManage={accessManage} />
                          </AccountContainer.Provider>
                        </VForm.Provider>
                      </MaxWidth>
                    </FormBlock>
                  )}
                </View>
              )}
            </View>
          )}

          <VMargin />
        </MaxWidth>
      </Container>
    </AccessManageContainer.Provider>
  );
});

const styles = StyleSheet.create({
  eye: {
    position: 'relative',
    top: 4,
  },
});
