import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';

const useAdminContainer = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [ownerId, setOwnerId] = useState<string>('');

  const set = useCallback(
    async ({
      isAdmin,
      userId,
      ownerId,
    }: {
      isAdmin: boolean;
      userId: string;
      ownerId: string;
    }) => {
      setIsAdmin(isAdmin);
      setUserId(userId);
      setOwnerId(ownerId);
    },
    [],
  );

  return {
    set,
    isAdmin,
    userId,
    ownerId,
  };
};

export const AdminContainer = createContainer(useAdminContainer);
