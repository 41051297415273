import {AttrForm, TargetOptionType} from './schema';
import {useFormContext, useWatch} from 'react-hook-form';
import React, {useCallback, useEffect, useState} from 'react';
import {
  AttrFilter,
  EMPTY_USERS,
  InitialStateBase,
  OtherUsers,
  TargetingUserCount,
} from './types';

/**
 * 指定属性が有効かされているかどうか
 * @param target 監視対象属性
 */
export function useWatchUse(target: TargetOptionType): {isUse?: boolean} {
  const [isUse, setUse] = useState<boolean | undefined>();
  const watchUse = useWatch<AttrForm>({name: 'use'});
  const use = ((watchUse as AttrForm['use']) ?? {})[target];
  useEffect(() => {
    setUse(use);
  }, [use]);
  return {
    isUse,
  };
}

/**
 * AttrFormでのuseオプションの有効/無効の制御関数を提供
 */
export function useUseChanger(): {
  enabled(id: TargetOptionType): void;
  disabled(id: TargetOptionType): void;
} {
  const {setValue, getValues} = useFormContext<AttrForm>();
  const enabled = useCallback(
    (id: TargetOptionType) => {
      const use = getValues('use') ?? {};
      use[id] = true;
      setValue('use', use);
    },
    [getValues, setValue],
  );
  const disabled = useCallback(
    (id: TargetOptionType) => {
      const use = getValues('use') ?? {};
      use[id] = false;
      setValue('use', use);
    },
    [getValues, setValue],
  );
  return {enabled, disabled};
}

/**
 * 各エリアコンテナでの共通部分処理hook
 */
export function useAreaContainerBase(initialState?: InitialStateBase): {
  otherUsers: OtherUsers;
  otherChoiceCount: number;
  attrFilter?: AttrFilter;
  userCount: TargetingUserCount;
  setCount: React.Dispatch<React.SetStateAction<TargetingUserCount>>;
} {
  const [otherUsers] = useState<OtherUsers>(
    initialState?.otherUsers ?? EMPTY_USERS,
  );
  const [otherChoiceCount] = useState<number>(
    initialState?.otherChoiceCount ?? 0,
  );
  const [attrFilter] = useState<AttrFilter | undefined>(
    () => initialState?.attrFilter,
  );
  const [userCount, setCount] = useState<TargetingUserCount>(
    initialState?.count ?? 'init',
  );
  return {
    otherUsers,
    otherChoiceCount,
    attrFilter,
    userCount,
    setCount,
  };
}
