import React, {useEffect} from 'react';
import Countdown, {CountdownApi} from 'react-countdown';
import {useFormContext} from 'react-hook-form';
import {Linking, StyleSheet, View} from 'react-native';
import {ERROR_MSG} from '../../helper';
import {Colors, CommonStyles} from '../../theme';
import {
  HelperText,
  MaxWidth,
  PLACEHOLDER,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../Elements';
import {SignInContainer} from './SignInContainer';
import {SignUpContainer} from './SignUpContainer';
import {
  MFAFormData,
  PhoneFormData,
  schemaMFAFormData,
  schemaPhoneFormData,
} from './schema';

type Mode = 'signIn' | 'signUpConfirm' | 'reSignUpConfirm';

const LABEL: {[P in Mode]: string} = {
  signIn: 'ログイン',
  signUpConfirm: '確認コード送信',
  reSignUpConfirm: '確認コード送信',
};
const LABEL_LOADING = '確認コードのチェック中';

const Submit: React.FC<{mode: Mode}> = ({mode}) => {
  const container = SignInContainer;
  const {challengeMFA, step, error} = container.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<MFAFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const helperText = errors.verificationCode?.message
    ? ERROR_MSG.common.form
    : error
    ? ERROR_MSG.common.dump(error)
    : '';
  const isLoading = step === 'challenging' || step === 'reSignUpChallenging';
  const disabled =
    isLoading || !watch('verificationCode') || !!errors.verificationCode;
  const buttonLabel = LABEL[mode];

  useEffect(() => {
    (window as any).dataLayer.push({
      event: 'customEvent',
      category: 'ボタン',
      action: '表示',
      label: buttonLabel,
    });
  }, [buttonLabel]);

  return (
    <View>
      <TrimaLoadingButton
        loadingIndicator={LABEL_LOADING}
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(
          (data) => challengeMFA(data, mode),
          onSubmitError,
        )}
        loading={isLoading}
      >
        {buttonLabel}
      </TrimaLoadingButton>
      <HelperText type="error">{helperText}</HelperText>
    </View>
  );
};

const SignupConfirmSubmit: React.FC<{mode: Mode}> = ({mode}) => {
  const {signupConfirm, step, error} = SignUpContainer.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<MFAFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const helperText = errors.verificationCode?.message
    ? ERROR_MSG.common.form
    : error
    ? ERROR_MSG.common.dump(error)
    : '';
  const isLoading = step === 'signupChallenging';
  const disabled =
    isLoading || !watch('verificationCode') || !!errors.verificationCode;
  const buttonLabel = LABEL[mode];

  useEffect(() => {
    (window as any).dataLayer.push({
      event: 'customEvent',
      category: 'ボタン',
      action: '表示',
      label: '（サインアップ）' + buttonLabel,
    });
  }, [buttonLabel]);

  return (
    <View>
      <TrimaLoadingButton
        loadingIndicator={LABEL_LOADING}
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit((data) => {
          signupConfirm(data);
        }, onSubmitError)}
        loading={isLoading}
      >
        {buttonLabel}
      </TrimaLoadingButton>
      <HelperText type="error">{helperText}</HelperText>
    </View>
  );
};

const ReSendCode: React.FC<{mode: Mode}> = ({mode}) => {
  const container =
    mode === 'signIn' || mode === 'reSignUpConfirm'
      ? SignInContainer
      : SignUpContainer;
  const {reSendVerificationCode} = container.useContainer();
  // 60秒セット
  const timerSeconds = 60000;
  const [date, setDate] = React.useState<number>(Date.now() + timerSeconds);

  const renderer = ({
    api,
    total,
    completed,
  }: {
    api: CountdownApi;
    total: number;
    completed: boolean;
  }) => {
    return (
      <View>
        <TrimaButton
          variant="outlined"
          disabled={api.isStarted()}
          onClick={() => {
            // タイマースタート
            api.start();
            // タイマー再セット
            setDate(Date.now() + timerSeconds);
            reSendVerificationCode();
          }}
        >
          確認コードの再送
        </TrimaButton>
        {!api.isStarted() ||
          (!completed && (
            <span style={{color: Colors.accent}}>
              {total / 1000}秒後に再送できます
            </span>
          ))}
      </View>
    );
  };

  return (
    <View>
      <Text>SMSが届かない場合</Text>
      <VMargin />
      <Text>
        SMSが届かない場合は
        <Text
          style={CommonStyles.linkText}
          onPress={() =>
            Linking.openURL(
              'https://support.shop.trip-mile.com/hc/ja/articles/4407344353165',
            )
          }
        >
          こちら
        </Text>
        の設定をご確認ください。
      </Text>
      <Text>下記のボタンから確認コードの再送が可能です。</Text>
      <VMargin />
      <Countdown date={date} renderer={renderer} autoStart={false} />
      <VMargin />
      <Text>電話番号の変更は個別対応となります。</Text>
      <Text>お手数ですがサポートまでご連絡をお願いいたします。</Text>
      <VMargin />
      <Text
        style={CommonStyles.linkText}
        onPress={() =>
          Linking.openURL('https://support.shop.trip-mile.com/hc/ja')
        }
      >
        サポートページ
      </Text>
    </View>
  );
};

export const MFAForm: React.FC<{mode: Mode}> = ({mode}) => {
  const container =
    mode === 'signIn' || mode === 'reSignUpConfirm'
      ? SignInContainer
      : SignUpContainer;
  const expiredLabel = mode === 'signIn' ? '3分' : '24時間';
  const {phoneNumber} = container.useContainer();

  return (
    <View>
      <MaxWidth maxWidth={586}>
        <Text>
          SMS(***-****-{phoneNumber.slice(-4)})
          に届いた確認コードを入力してください。
        </Text>
        <VMargin />
        <Text style={styles.accentText}>
          確認コードの有効期限は発行から{expiredLabel}です。
        </Text>
        <VMargin />
        <VForm.Provider<MFAFormData> schema={schemaMFAFormData}>
          <VForm.Text<MFAFormData>
            name="verificationCode"
            label="確認コード"
            required
          />
          {mode === 'signUpConfirm' ? (
            <SignupConfirmSubmit mode={mode} />
          ) : (
            <Submit mode={mode} />
          )}
        </VForm.Provider>
        <VMargin />
        <ReSendCode mode={mode} />
      </MaxWidth>
    </View>
  );
};

const PhoneSubmit: React.FC<{mode: Mode}> = ({mode}) => {
  const container =
    mode === 'signIn' || mode === 'reSignUpConfirm'
      ? SignInContainer
      : SignUpContainer;
  const {changePhoneNumber, step, error} = container.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<PhoneFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const helperText = errors.phone?.message
    ? ERROR_MSG.common.form
    : error
    ? ERROR_MSG.common.dump(error)
    : '';
  const isLoading = step === 'challenging';
  const disabled = isLoading || !watch('phone') || !!errors.phone;
  const buttonLabel = '変更する';
  return (
    <View>
      <TrimaLoadingButton
        loadingIndicator={LABEL_LOADING}
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(changePhoneNumber, onSubmitError)}
        loading={isLoading}
      >
        {buttonLabel}
      </TrimaLoadingButton>
      <HelperText type="error">{helperText}</HelperText>
    </View>
  );
};

export const PhoneChangeForm: React.FC<{mode: Mode}> = ({mode}) => {
  return (
    <View>
      <VForm.Provider<PhoneFormData> schema={schemaPhoneFormData}>
        <VForm.Text<PhoneFormData>
          name="phone"
          label="電話番号"
          {...PLACEHOLDER.phone}
        />
        <View style={CommonStyles.margin.top} />
        <PhoneSubmit mode={mode} />
      </VForm.Provider>
    </View>
  );
};

export const PhoneForm: React.FC<{mode: Mode}> = () => {
  return null;
};

const styles = StyleSheet.create({
  accentText: {
    color: Colors.accent,
  },
});
