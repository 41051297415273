import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSAgencyRepository} from '../../_proto/services/SaaSAgencyRepository';

const TAG = '[AgencyPreContainer]';
export type AgencyPreStep = 'init' | 'info' | 'regist' | 'error';

type AgencyDto = {
  id: string;
  name: string;
};

type PreRegistRequest = {
  email: string;
};

type UseAgencyPre = {
  busy: boolean;

  step: AgencyPreStep;
  error?: Error | (Error & {code: string});

  agency?: AgencyDto;

  startRegist(): void;
  preRegist(request: PreRegistRequest): void;
  fetchAgency(id: string): void;
};

function useAgencyPre(): UseAgencyPre {
  const [busy, setBusy] = useState(false);
  const [step, setStep] = useState<AgencyPreStep>('init');
  const [agency, setAgency] = useState<AgencyDto | undefined>();
  const [error, setError] = useState<Error | undefined>();
  const [email, setEmail] = useState<string>('');

  // T.B.D
  console.log(TAG, 'email', email);

  const preRegist = useCallback((request: PreRegistRequest) => {
    setEmail(request.email);
  }, []);

  const fetchAgency = useCallback(async (id: string) => {
    setBusy(true);
    try {
      const result = await SaaSAgencyRepository.get(id);
      if (result) {
        setAgency(result);
        setStep('info');
      }
    } catch (err: any) {
      setError(err as Error);
      setStep('error');
    } finally {
      setBusy(false);
    }
    console.log(TAG, 'fetchAgency');
  }, []);

  const startRegist = () => setStep('regist');

  return {
    busy,
    step,
    error,
    agency,
    startRegist,
    preRegist,
    fetchAgency,
  };
}

export const AgencyPreContainer = createContainer(useAgencyPre);
