import React, {ComponentProps} from 'react';
import {StyleSheet, View} from 'react-native';
import {HelperText as PHelperText, Text as PaperText} from 'react-native-paper';
import {Colors, CommonStyles, Fonts} from '../../theme';
import {HintButton, HintButtonProps} from './Hint';

type Props = ComponentProps<typeof PaperText>;

// Captionはとりあえず Paper のをそのまま
export {Caption} from 'react-native-paper';

export const MinText: React.FC<Props> = (props) => {
  const {children, style, theme, ...rest} = props;
  const textStyle = {
    ...Fonts.caption,
    fontWeight: theme?.fonts?.medium?.fontWeight ?? ('500' as const),
  };
  return (
    <PaperText style={[textStyle, style]} {...rest}>
      {children}
    </PaperText>
  );
};

export const Text: React.FC<Props> = (props) => {
  const {children, style, theme, ...rest} = props;
  const textStyle = {
    ...Fonts.base,
    fontWeight: theme?.fonts?.medium?.fontWeight ?? ('500' as const),
  };
  return (
    <PaperText style={[textStyle, style]} {...rest}>
      {children}
    </PaperText>
  );
};

export const AppText: React.FC<Props> = (props) => {
  const {children, style, ...rest} = props;
  const textStyle = {
    ...Fonts.base,
    fontWeight: '600' as const,
  };
  return (
    <PaperText style={[textStyle, style]} {...rest}>
      {children}
    </PaperText>
  );
};

export const Submenu: React.FC<Props> = (props) => {
  const {children, style, theme, ...rest} = props;
  const textStyle = {
    ...Fonts.submenu,
    fontWeight: theme?.fonts?.medium?.fontWeight ?? ('500' as const),
  };
  return (
    <PaperText style={[style, textStyle]} {...rest}>
      {children}
    </PaperText>
  );
};

export const Menu: React.FC<Props> = (props) => {
  const {children, style, theme, ...rest} = props;
  const textStyle = {
    ...Fonts.menu,
    fontWeight: theme?.fonts?.medium?.fontWeight ?? ('500' as const),
  };
  return (
    <PaperText style={[style, textStyle]} {...rest}>
      {children}
    </PaperText>
  );
};

export const HelperText: React.FC<React.ComponentProps<typeof PHelperText>> = (
  props,
) => {
  const {style, children, ...rest} = props;
  return (
    <PHelperText style={[styles.helper, style]} {...rest}>
      {children}
    </PHelperText>
  );
};

// ヒントボタン付きラベルで使う想定
export const WithHint: React.FC<
  {children: React.ReactNode} & HintButtonProps
> = (props) => {
  const {children, ...rest} = props;
  return (
    <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
      {children}
      <View style={{paddingBottom: 0}}>
        <HintButton {...rest} />
      </View>
    </View>
  );
};

export const NotRequired: React.FC = () => {
  return <Text style={styles.notRequired}>任意</Text>;
};

export const RequiredNotice: React.FC = () => {
  return <Text style={styles.requiredNotice}>必須</Text>;
};

const styles = StyleSheet.create({
  helper: {
    height: 30,
  },
  notRequired: {
    ...Fonts.caption,
    color: Colors.white,
    height: 19,
    backgroundColor: Colors.darkgray,
    paddingHorizontal: 4,
    borderRadius: 4,
    marginLeft: 8,
  },
  requiredNotice: {
    ...Fonts.caption,
    color: Colors.white,
    height: 19,
    backgroundColor: Colors.accent,
    paddingHorizontal: 4,
    borderRadius: 4,
    marginLeft: 8,
  },
});
