import React, {useEffect} from 'react';
import {Linking, StyleSheet, View} from 'react-native';
import {AppConfig} from '../../_proto/config/AppConfig';
import {SaaSBillingRepository} from '../../_proto/services/SaaSBillingRepository';
import {
  OwnerContainer,
  PaymentInfoContainer,
  StepNavigationContainer,
} from '../../container';
import {isAxiosError} from '../../helper/errors';
import {AccountMenu} from '../../navigation/AccountMenu';
import {Colors, CommonStyles} from '../../theme';
import {
  Header,
  HelperText,
  MaxWidth,
  RegisterContainer,
  Text,
  TrimaLoadingButton,
  VMargin,
} from '../Elements';

const priceId = AppConfig.PaidPriceId;

export const PaymentRegisterReviewComponent: React.FC = () => {
  const {owner} = OwnerContainer.useContainer();
  const {setStep2ShopRegist} = StepNavigationContainer.useContainer();
  const {paymentInfo, createPaidSubscription} =
    PaymentInfoContainer.useContainer();
  const [value, setValue] = React.useState('1');
  const {customerId} = paymentInfo;
  const [processing, setProcessing] = React.useState(false);
  const [error, setError]: any = React.useState(null);
  const paidPlan = async () => {
    setProcessing(true);
    setError(null);
    try {
      await createPaidSubscription(owner, priceId);
      setStep2ShopRegist();
    } catch (err: any) {
      if (isAxiosError(err)) {
        console.log(err.response);
        setError(err.response?.data);
      } else {
        console.log(err);
        setError(err);
      }
    }
    setProcessing(false);
  };

  useEffect(() => {
    async function fetchData() {
      const data = {
        b2bMemberIds: customerId,
      };
      // Paid審査ステータスチェック
      const paidStatus: any = await SaaSBillingRepository.getPaidStatus(data);
      console.log(paidStatus);
      setValue(paidStatus.body.results.successes[0].memberStatusCode);
    }
    fetchData();
  }, [customerId]);

  let text, label;
  if (value === '1') {
    text = '審査中';
    label = 'Paidの利用審査中です';
  } else if (value === '2') {
    text = '審査完了';
    label = 'Paidの利用審査が完了しました';
  } else {
    text = '審査完了(否決)';
    label = 'Paidの利用審査が完了しました';
  }

  const helper = error ? `エラーが発生しました（${error.message}）` : '';

  return (
    <View>
      <Header rightItem={<AccountMenu />} />
      <RegisterContainer step="done" label={label}>
        {value === '1' ? (
          <View>
            <Text>Paid（ペイド）のご利用には審査がございます。</Text>
            <VMargin />
            <Text>
              株式会社ラクーンフィナンシャルが運営するPaid（ペイド）担当より
              <Text style={styles.accentText}>
                アカウント作成時に入力されたメール
              </Text>
              、又は
              <Text style={styles.accentText}>
                企業情報入力で登録していただいた担当者電話番号
              </Text>
              までご連絡、ご案内がございますので予めご理解くださいますよう、どうぞよろしくお願いいたします。
            </Text>
            <VMargin />
            <Text>
              審査期間は1～3営業日程度で、審査結果はアカウント作成時に入力されたメールアドレスへ送信されます。
            </Text>
            <VMargin />
            <Text>審査完了までしばらくお待ちください。</Text>
            <VMargin />
          </View>
        ) : (
          <View>
            <Text>
              審査結果はアカウント作成時に入力されたメールアドレスへ送信されています。
            </Text>
            <VMargin />
          </View>
        )}
        <Text
          style={CommonStyles.linkText}
          onPress={() =>
            Linking.openURL('https://paid.jp/v/contents/pre/buyer/')
          }>
          Paidについて詳しく確認する
        </Text>
        <VMargin />
        <View>
          <Text>ステータス：{text}</Text>
        </View>
        <VMargin />
        {!(value === '1' || value === '2') && (
          <View>
            <Text>
              他の支払い方法へ変更される場合は
              <Text
                style={CommonStyles.linkText}
                onPress={() =>
                  Linking.openURL('https://support.shop.trip-mile.com/hc/ja')
                }>
                こちら
              </Text>
              にお問い合わせください。
            </Text>
            <VMargin />
          </View>
        )}
        <MaxWidth maxWidth={586}>
          <TrimaLoadingButton
            variant="contained"
            onClick={() => {
              paidPlan();
            }}
            disabled={value !== '2' || processing}
            loading={processing}
            loadingPosition="start">
            店舗を登録する
          </TrimaLoadingButton>
          <HelperText type="error">{helper}</HelperText>
        </MaxWidth>
      </RegisterContainer>
    </View>
  );
};

const styles = StyleSheet.create({
  accentText: {
    color: Colors.accent,
  },
});
