import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link, Stack} from '@mui/material';
import {useNavigation} from '@react-navigation/native';
import dayjs from 'dayjs';
// プラグインが必要
import {Box} from '@mui/material';
import isBetween from 'dayjs/plugin/isBetween';
import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import StartGuideImage from '../../assets/start_guide_image.png';
import {Container, useResponsive} from '../../components';
import {
  CustomizedButton,
  FormBlock,
  GotoDetail,
  MaxWidth,
  Menu,
  Text,
  VMargin,
} from '../../components/Elements';
import {
  CustomerRepeatSummary,
  DailyGraph,
  DeliveryReportSummary,
} from '../../components/Report';
import {WhatsNew} from '../../components/WhatsNew';
import {PaymentInfoContainer, ShopContainer} from '../../container';
import {StripeContainer} from '../../container/StripeContainer';
import {Helpers} from '../../helper';
import {MainNavigationProp} from '../../navigation/MainScreen';
import {graphQLService} from '../../service';
import {Colors, CommonStyles, trimaColor} from '../../theme';

dayjs.extend(isBetween);

type NProp = MainNavigationProp<'DashBoard'>;

const START_GUIDE_URL =
  'https://support.shop.trip-mile.com/hc/ja/sections/360014091171';

const formatter = new Intl.NumberFormat('ja-JP');

const GoToScreen: React.FC<{onPress(): void; children: React.ReactNode}> = ({
  onPress,
  children,
}) => {
  return (
    <Link component="button" onClick={onPress} underline="hover">
      <Stack direction="row" alignItems="center" gap={1}>
        {children}
        <FontAwesomeIcon icon={faChevronRight} />
      </Stack>
    </Link>
  );
};

const DeliveryMonth: React.FC = React.memo(() => {
  const [shopId] = React.useState<string | undefined>(
    ShopContainer.useContainer().selected?.id,
  );
  const [isDeliverying, setIsDeliverying] = React.useState<boolean>(false);
  const {paymentInfo} = PaymentInfoContainer.useContainer();
  const {customerId, subscriptionId} = paymentInfo;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [couponCount, setCouponCount] = React.useState<number>(0);
  const [newsCount, setNewsCount] = React.useState<number>(0);
  const {upcomingInvoice, getUpcomingInvoice} = StripeContainer.useContainer();

  const navigation = useNavigation<NProp>();
  const gotoReportsMain = () => navigation.navigate('ReportsMain');

  React.useEffect(() => {
    async function fetchData() {
      if (!shopId) {
        return;
      }

      setLoading(true);
      const upcomingRequest = {
        customerId,
        subscriptionId,
      };
      if (!upcomingInvoice) {
        await getUpcomingInvoice(upcomingRequest);
      }

      const [couponList, newsList] = await Promise.all([
        graphQLService.getDeliveryHistoryList(shopId),
        graphQLService.getNewsHistoryList(shopId),
      ]);

      // 現在配信中のクーポンを取得
      const deliveryingCouponList = couponList.filter((coupon) =>
        dayjs().isBetween(dayjs(coupon.startAt), dayjs(coupon.endAt)),
      );
      // 現在配信中のお知らせを取得
      const deliveryingNewsList = newsList.filter((news) =>
        dayjs().isBetween(dayjs(news.startAt), dayjs(news.endAt)),
      );

      if (deliveryingCouponList.length > 0 || deliveryingNewsList.length > 0) {
        setIsDeliverying(true);
      }
      setCouponCount(deliveryingCouponList.length);
      setNewsCount(deliveryingNewsList.length);
      setLoading(false);
    }
    fetchData();
  }, [shopId, customerId, subscriptionId, getUpcomingInvoice, upcomingInvoice]);

  if (loading) {
    return (
      <FormBlock>
        <ActivityIndicator size={40} />
      </FormBlock>
    );
  }
  if (isDeliverying) {
    return (
      <FormBlock>
        <Text>配信状況</Text>
        <VMargin />
        <Text>現在の配信状況は以下になります。</Text>
        <Text>広告：{formatter.format(couponCount + newsCount)}件</Text>
        <View style={CommonStyles.flex.crossEnd}>
          <GoToScreen onPress={gotoReportsMain}>配信結果はこちら</GoToScreen>
        </View>
      </FormBlock>
    );
  } else {
    return (
      <FormBlock style={styles.deliveryMonthBlock}>
        <Text style={styles.whiteColor}>配信状況</Text>
        <VMargin />
        <Text style={styles.whiteColor}>
          今月、広告が1件も配信されていません。
        </Text>
        <Text style={styles.whiteColor}>
          広告を配信することでお店の情報をトリマに掲載することができます。
        </Text>
        <VMargin />
        <View style={CommonStyles.flex.crossEnd}>
          <Link
            sx={{color: Colors.white}}
            href={START_GUIDE_URL}
            target="_blank"
            rel="noopener"
            underline="hover">
            <Stack direction="row" alignItems="center" gap={1}>
              広告の作成方法がわからない方はこちら
              <FontAwesomeIcon icon={faChevronRight} />
            </Stack>
          </Link>
        </View>
      </FormBlock>
    );
  }
});

export const DashBoard: React.FC = React.memo(() => {
  const [shopId] = React.useState<string | undefined>(
    ShopContainer.useContainer().selected?.id,
  );
  const navigation = useNavigation<NProp>();

  const gotoReportsMain = () => navigation.navigate('ReportsMain');
  const gotoReportsCustomers = () => navigation.navigate('ReportsCustomers');
  const gotoReportsDetail = () =>
    navigation.navigate('ReportsDetail', {id: creative.id, type});
  const gotoCouponsCreate = () => navigation.navigate('CouponsCreate');

  const {isDesktop} = useResponsive();
  const flexStyle = isDesktop ? CommonStyles.flex.row : {};

  const [creative, setCreative] = React.useState<any>({});
  const [type, setType] = React.useState<any>('');

  React.useEffect(() => {
    async function fetchData() {
      if (!shopId) {
        return;
      }

      const [delivery, news] = await Promise.all([
        graphQLService.getDeliveryHistoryLatest(shopId),
        graphQLService.getNewsHistoryLatest(shopId),
      ]);

      if (delivery.length === 0 && news.length === 0) {
        return;
      }
      if (delivery.length === 0) {
        setCreative(news[0]);
        setType('NEWS');
      } else if (news.length === 0) {
        setCreative(delivery[0]);
        setType('COUPON');
      } else {
        if (dayjs(delivery[0].createdAt).isAfter(dayjs(news[0].createdAt))) {
          setCreative(delivery[0]);
          setType('COUPON');
        } else {
          setCreative(news[0]);
          setType('NEWS');
        }
      }
    }
    fetchData();
  }, [shopId]);

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <DeliveryMonth />
        <VMargin />
        <MaxWidth maxWidth={586}>
          <CustomizedButton
            variant="contained"
            onClick={() => gotoCouponsCreate()}>
            広告の作成をする
          </CustomizedButton>
          <VMargin />
        </MaxWidth>
        <FormBlock style={styles.startGuideBlock}>
          <MaxWidth
            maxWidth={CommonStyles.maxWidth.list}
            style={[CommonStyles.flex.between, CommonStyles.flex.row]}>
            <View style={styles.startGuideLeft}>
              <Menu>スタートガイド</Menu>
              <VMargin />
              <Text>トリマ広告ダイレクトを始めたら最初にチェック！</Text>
              <VMargin />
              <Box sx={{backgroundColor: 'white', borderRadius: 2, width: 160}}>
                <CustomizedButton
                  fullWidth
                  variant="outlined"
                  onClick={() => Helpers.openURL(START_GUIDE_URL)}>
                  スタートガイド
                </CustomizedButton>
              </Box>
            </View>
            <View style={styles.startGuideRight}>
              <Image
                source={{uri: StartGuideImage}}
                style={CommonStyles.full}
                resizeMode="contain"
              />
            </View>
          </MaxWidth>
        </FormBlock>
        <VMargin />
        <Menu>ダッシュボード</Menu>
        <VMargin />
        <FormBlock>
          <Text>新着情報</Text>
          <VMargin />
          <WhatsNew />
        </FormBlock>
        <VMargin />
        {shopId && (
          <FormBlock>
            <DailyGraph shopId={shopId} isSummary />
            <VMargin />
            <View style={CommonStyles.flex.crossEnd}>
              <GotoDetail onPress={gotoReportsMain} />
            </View>
          </FormBlock>
        )}
        <VMargin />
        <View style={flexStyle}>
          <View
            style={
              isDesktop
                ? {...CommonStyles.flex.full, paddingRight: 16}
                : {paddingBottom: 16}
            }>
            {shopId && (
              <FormBlock style={CommonStyles.fullWidth}>
                <CustomerRepeatSummary shopId={shopId} />
                <VMargin />
                <View style={CommonStyles.flex.crossEnd}>
                  <GotoDetail onPress={gotoReportsCustomers} />
                </View>
              </FormBlock>
            )}
          </View>
          <View style={isDesktop ? CommonStyles.flex.full : {}}>
            {shopId && (
              <FormBlock style={CommonStyles.fullWidth}>
                <DeliveryReportSummary creative={creative} />
                <VMargin />
                {creative.id && (
                  <View style={CommonStyles.flex.crossEnd}>
                    <GotoDetail onPress={gotoReportsDetail} />
                  </View>
                )}
              </FormBlock>
            )}
          </View>
        </View>
      </MaxWidth>
    </Container>
  );
});

const styles = StyleSheet.create({
  startGuideBlock: {backgroundColor: trimaColor.main},
  startGuideLeft: {flexGrow: 1, flexShrink: 1},
  startGuideRight: {flexGrow: 1, flexShrink: 0, flexBasis: 80},
  startGuideButton: {width: 160},
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    ...CommonStyles.flex.center,
  },
  cationArea: {
    backgroundColor: Colors.white,
    ...CommonStyles.padding.all,
  },
  deliveryMonthBlock: {backgroundColor: Colors.accent},
  whiteColor: {color: Colors.white},
  icon: {color: Colors.main, fontSize: 20},
  text: {color: Colors.main},
  button: {
    alignSelf: 'stretch',
  },
});
