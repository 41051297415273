import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {ja} from 'date-fns/locale';
import dayjs, {extend} from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React from 'react';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {useCSVDownloader} from 'react-papaparse';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {Shop, ShopContainer} from '../../container';
import {RangeReportResponse, SaasReportRepository} from '../../service';
import {Colors} from '../../theme';
import {
  Caption,
  DataArea,
  MaxWidth,
  Overlay,
  Text,
  TrimaButton,
  VMargin,
} from '../Elements';
import './DailyGraph.css';
import {DeliveryReportSums} from './DeliveryReportSums';

extend(duration);

const now = dayjs();

const TermLabel: React.FC<{shopReports?: RangeReportResponse}> = ({
  shopReports,
}) => {
  const reports = shopReports?.dailyRecords;
  let term = '';
  if (reports && reports.length !== 0) {
    // Todo: 日付フォーマット
    term = `${reports[0].stamp}〜${reports[reports.length - 1].stamp}`;
  }
  return (
    <View>
      <Caption>{term}</Caption>
    </View>
  );
};

const Graph: React.FC<{shopReports?: RangeReportResponse}> = ({
  shopReports,
}) => {
  const {selected} = ShopContainer.useContainer();
  let reports = shopReports?.dailyRecords ?? [];
  reports = reports?.map(({stamp, ...rest}) => {
    const day = dayjs(stamp);
    return {stamp: day.isValid() ? day.format('MM/DD(ddd)') : stamp, ...rest};
  });
  return (
    <ResponsiveContainer height={400}>
      <ComposedChart
        data={reports}
        margin={{
          top: 16,
          bottom: 8,
          left: -16,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="stamp" fontSize={10} />
        <YAxis yAxisId="left" fontSize={10} />
        <YAxis yAxisId="right" fontSize={10} orientation="right" />
        <Tooltip />
        <Legend />
        <Bar
          yAxisId="right"
          dataKey="urlClicked"
          fill={'#0499FA'}
          name="クリック人数"
        />
        {!selected?.legalUrl && (
          <Bar
            yAxisId="right"
            name="来店延べ人数"
            dataKey="visited"
            fill={Colors.accent}
          />
        )}
        <Line
          yAxisId="left"
          type="monotone"
          name="一覧表示"
          dataKey="listed"
          stroke={'#333333'}
          activeDot={{r: 8}}
        />
        <Line
          yAxisId="left"
          type="monotone"
          name="広告閲覧人数"
          dataKey="viewed"
          stroke={Colors.main}
          activeDot={{r: 8}}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

type DataState = 'loading' | 'nodata' | 'error' | 'exist';

function percent(top: number | undefined, bottom: number | undefined) {
  if (!bottom || !top) {
    // 各値が0や数値じゃない場合は計算しない
    return 0;
  }
  const ratio = Math.round((10000 * top) / bottom) / 10000;
  return ratio;
}

export const DailyGraph: React.FC<{
  shopId: string;
  isSummary?: boolean; // ダッシュボード利用時に立てる想定
}> = ({shopId, isSummary}) => {
  const {selected} = ShopContainer.useContainer();
  const [dataState, setDataState] = React.useState<DataState>('loading');
  const [shopDailyReport, setReports] = React.useState<
    RangeReportResponse | undefined
  >();
  const [state, setState] = React.useState([
    {
      startDate: now.subtract(7, 'day').toDate(),
      endDate: now.toDate(),
      key: 'selection',
    },
  ]);
  const [value, setValue] = React.useState(10);
  const {CSVDownloader} = useCSVDownloader();

  React.useEffect(() => {
    if (!shopId) {
      return;
    }
    setDataState('loading');
    SaasReportRepository.getShopDailyReports({
      id: shopId,
      from: dayjs(state[0].startDate).format('YYYY-MM-DD'),
      to: dayjs(state[0].endDate).format('YYYY-MM-DD'),
    })
      .then((reports) => {
        setReports(reports);
        setDataState(reports.dailyRecords.length > 0 ? 'exist' : 'nodata');
      })
      .catch(() => {
        setReports(undefined);
        setDataState('error');
      });
  }, [shopId, state]);

  const handleSelect = (item: any) => {
    setState([item.selection]);
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    setValue(event.target.value as number);
    switch (event.target.value) {
      case 10:
        setState([
          {
            startDate: now.subtract(7, 'day').toDate(),
            endDate: now.toDate(),
            key: 'selection',
          },
        ]);
        break;
      case 20:
        setState([
          {
            startDate: now.subtract(30, 'day').toDate(),
            endDate: now.toDate(),
            key: 'selection',
          },
        ]);
        break;
      case 30:
        setState([
          {
            startDate: now.date(1).toDate(),
            endDate: now.date(1).add(1, 'month').toDate(),
            key: 'selection',
          },
        ]);
        break;
      case 40:
        setState([
          {
            startDate: now.subtract(1, 'month').date(1).toDate(),
            endDate: now.date(1).subtract(1, 'day').toDate(),
            key: 'selection',
          },
        ]);
        break;
      case 50:
        setState([
          {
            startDate: state[0].startDate,
            endDate: state[0].endDate,
            key: 'selection',
          },
        ]);
        break;
      default:
        setState([
          {
            startDate: now.subtract(7, 'day').toDate(),
            endDate: now.toDate(),
            key: 'selection',
          },
        ]);
    }
  };

  const exportCsv = (selected: Shop | undefined) => {
    return shopDailyReport
      ? shopDailyReport.dailyRecords.map((record) => {
          const {stamp, listed, viewed, amount, urlClicked, visited} = record;
          return {
            日付: stamp,
            一覧表示人数: listed ?? 0,
            広告閲覧人数: viewed ?? 0,
            閲覧率: percent(viewed, listed),
            '広告料(税別)': amount ?? 0,
            クリック人数: urlClicked ?? 0,
            クリック率: percent(urlClicked, viewed),
            クリック単価:
              amount && urlClicked
                ? Math.round((amount / urlClicked) * 10) / 10
                : 0,
            来店延べ人数: selected?.legalUrl ? undefined : visited ?? 0,
            来店率: selected?.legalUrl
              ? undefined
              : percent(record.visited, record.viewed),
            来店単価: selected?.legalUrl
              ? undefined
              : amount && visited
              ? Math.round((amount / visited) * 10) / 10
              : 0,
          };
        })
      : [];
  };

  const convertSums = (shopDailyReport: RangeReportResponse) => {
    return {
      list: shopDailyReport.listed ?? undefined,
      bill: shopDailyReport.viewed ?? undefined,
      visited: shopDailyReport.visited ?? undefined,
      amount: shopDailyReport.amount ?? undefined,
      clickedUrl: shopDailyReport.urlClicked ?? undefined,
    };
  };

  return (
    <View>
      <Text>店舗全体の効果</Text>
      {!isSummary && (
        <View>
          <MaxWidth maxWidth={586}>
            <FormControl variant="filled">
              <InputLabel htmlFor="value-native-simple">
                {dayjs(state[0].startDate).format('YYYY/MM/DD')} ~{' '}
                {dayjs(state[0].endDate).format('YYYY/MM/DD')}
              </InputLabel>
              <Select
                sx={{bgcolor: 'white'}}
                value={value}
                onChange={handleChange}
                inputProps={{
                  name: 'value',
                  id: 'demo-controlled-open-select',
                }}
              >
                <MenuItem value={10}>過去7日間</MenuItem>
                <MenuItem value={20}>過去30日間</MenuItem>
                <MenuItem value={30}>今月</MenuItem>
                <MenuItem value={40}>先月</MenuItem>
                <MenuItem value={50}>カスタム（範囲指定）</MenuItem>
              </Select>
            </FormControl>
          </MaxWidth>
          <VMargin />
          <Box sx={{display: value === 50 ? 'block' : 'none'}}>
            <DataArea>
              <MaxWidth maxWidth={352}>
                <Box
                  sx={{
                    margin: 'auto',
                    border: '1px solid rgb(230, 230, 230)',
                  }}
                >
                  <DateRange
                    onChange={handleSelect}
                    moveRangeOnFirstSelection={false}
                    rangeColors={['#7E57C2', '#3ecf8e', '#fed14c']}
                    ranges={state}
                    direction="vertical"
                    locale={ja}
                  />
                </Box>
                <VMargin />
                <Text>
                  {dayjs
                    .duration(
                      state[0].endDate.getTime() - state[0].startDate.getTime(),
                    )
                    .asDays()}
                  日間選択中
                </Text>
              </MaxWidth>
            </DataArea>
          </Box>
          <VMargin />
        </View>
      )}
      {isSummary && <TermLabel shopReports={shopDailyReport} />}
      <VMargin />
      {shopDailyReport && (
        <DeliveryReportSums sums={convertSums(shopDailyReport)} />
      )}
      <VMargin />
      <DataArea>
        <Graph shopReports={shopDailyReport} />
        {dataState !== 'exist' && (
          <Overlay>
            {dataState === 'loading' && <ActivityIndicator size={40} />}
            {dataState === 'nodata' && <Text>データがありません</Text>}
            {dataState === 'error' && <Text>データ取得エラー</Text>}
          </Overlay>
        )}
      </DataArea>
      <VMargin />
      <MaxWidth maxWidth={586}>
        <CSVDownloader
          filename={`report_all_${dayjs(state[0].startDate).format(
            'YYYYMMDD',
          )}-${dayjs(state[0].endDate).format('YYYYMMDD')}`}
          bom={true}
          config={{
            header: true,
          }}
          data={exportCsv(selected)}
        >
          <TrimaButton variant="outlined">CSVを出力する</TrimaButton>
        </CSVDownloader>
      </MaxWidth>
    </View>
  );
};
