import {LoadingButton} from '@mui/lab';
import {Box, ButtonProps, Button as MuiButton, styled} from '@mui/material';
import React, {ComponentProps} from 'react';
import {StyleSheet} from 'react-native';
import {ButtonGroup as BaseButtonGroup} from 'react-native-elements';
import {Colors, CommonStyles, Fonts, trimaColor} from '../../theme';

export const CustomizedButton = styled(MuiButton)<ButtonProps>(({variant}) => {
  const outlineStyles =
    variant === 'outlined'
      ? {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        }
      : {};
  return {
    height: 56,
    fontSize: 16,
    borderRadius: 8,
    ...outlineStyles,
  };
});

export const CustomizedLoadingButton = styled(LoadingButton)<ButtonProps>(({
  variant,
}) => {
  const outlineStyles =
    variant === 'outlined'
      ? {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        }
      : {};
  return {
    height: 56,
    fontSize: 16,
    borderRadius: 8,
    ...outlineStyles,
  };
});

export const TrimaButton: React.FC<ComponentProps<typeof MuiButton>> = (
  props,
) => {
  const {children, variant, ...rest} = props;
  if (variant === 'outlined') {
    return (
      <Box sx={{backgroundColor: 'white', borderRadius: 2}}>
        <CustomizedButton fullWidth {...rest} variant="outlined">
          {children}
        </CustomizedButton>
      </Box>
    );
  } else {
    return (
      <CustomizedButton fullWidth {...rest} variant={variant}>
        {children}
      </CustomizedButton>
    );
  }
};

export const TrimaLoadingButton: React.FC<
  ComponentProps<typeof LoadingButton>
> = (props) => {
  const {children, variant, ...rest} = props;

  if (variant === 'outlined') {
    console.log('out');
    return (
      <Box sx={{backgroundColor: 'white', borderRadius: 2}}>
        <CustomizedLoadingButton fullWidth {...rest} variant="outlined">
          {children}
        </CustomizedLoadingButton>
      </Box>
    );
  } else {
    return (
      <CustomizedLoadingButton fullWidth {...rest} variant={variant}>
        {children}
      </CustomizedLoadingButton>
    );
  }
};

export const ButtonGroup: React.FC<
  React.ComponentProps<typeof BaseButtonGroup>
> = (props) => {
  const {containerStyle, buttonStyle, selectedButtonStyle, textStyle} = props;
  const innerBorder = {color: trimaColor.accent};
  return (
    <BaseButtonGroup
      containerStyle={[styles.screenButtonContainer, containerStyle]}
      innerBorderStyle={innerBorder}
      buttonStyle={[styles.groupButton, buttonStyle]}
      selectedButtonStyle={[styles.groupSelectedButton, selectedButtonStyle]}
      textStyle={[styles.groupText, textStyle]}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  button: {
    height: 56,
    ...CommonStyles.flex.mainCenter,
  },
  buttonContainer: {
    height: 56,
  },
  buttonLabel: {
    ...Fonts.base,
  },
  outlined: {
    backgroundColor: Colors.white,
    borderWidth: 2,
    borderColor: Colors.main,
  },
  screenButtonContainer: {
    borderRadius: 8,
    height: 44,
    borderWidth: 1,
    borderColor: trimaColor.accent,
    ...CommonStyles.margin.bottom,
  },
  groupButton: {backgroundColor: Colors.lightgray},
  groupSelectedButton: {backgroundColor: trimaColor.accent},
  groupText: {color: Colors.darkgray},
  newButton: {
    height: 40,
  },
});
