import React from 'react';
import {useFormContext} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {ShopContainer} from '../../../../container';
import {LngLat} from '../../../../helper';
import {CommonStyles} from '../../../../theme';
import {
  HelperText,
  MCIcon,
  MinText,
  Text,
  TrimaLoadingButton,
  VForm,
} from '../../../Elements';
import {VMarginCompact} from '../../../Elements/Layouts';
import {TargetCounter} from '../../Counters';
import {TargetingContainer} from '../../TargetingContainer';
import {AttrForm, hourFrom, hourTo, weekList} from '../../schema';
import {ChoiceCounter} from '../ChoiceCounter';
import MeshSelectMap from '../MeshSelectMap';
import {StayHistoryContainer} from './StayHistoryContainer';

// 滞在履歴設定
const StaySetting: React.FC = React.memo(() => {
  const {getStayInitial} = TargetingContainer.useContainer();
  const init = getStayInitial();
  return (
    <StayHistoryContainer.Provider initialState={init}>
      <StaySettingInner />
    </StayHistoryContainer.Provider>
  );
});

const StaySettingInner: React.FC = React.memo(() => {
  const [shopCenter] = React.useState<LngLat | undefined>(
    ShopContainer.useContainer().selected?.location ?? undefined,
  );
  const [hideCounter, setHideCounter] = React.useState<boolean>(false);

  const {
    calcMode,
    canConfirm,
    confirm,
    selectMesh,
    selectedMesh,
    selectMeshes,
    userCount,
  } = StayHistoryContainer.useContainer();
  const {
    formState: {errors},
  } = useFormContext<AttrForm>();

  const weekError = errors.areaStay?.sunday?.message;
  const hourError = errors.areaStay?.hourFrom?.message;
  const formDisabled = calcMode === 'map';
  const buttonDisabled = !!weekError || !!hourError || !canConfirm;
  const count = Object.keys(selectedMesh).length;
  return (
    <View style={[CommonStyles.fullHeight, CommonStyles.flex.reverse]}>
      <View style={CommonStyles.margin.all}>
        <TrimaLoadingButton
          variant="contained"
          onClick={confirm}
          disabled={buttonDisabled}
          loading={userCount === 'calculating'}
          loadingPosition="start"
        >
          確定
        </TrimaLoadingButton>
      </View>
      <View style={[CommonStyles.margin.all, CommonStyles.flex.reverse]}>
        <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
          <View style={styles.hour}>
            <VForm.Select<AttrForm> {...hourFrom} disabled={formDisabled} />
          </View>
          <Text>{' 時 〜 '}</Text>
          <View style={styles.hour}>
            <VForm.Select<AttrForm> {...hourTo} disabled={formDisabled} />
          </View>
          <Text>{' 時'}</Text>
        </View>
        <VMarginCompact />
        <View style={CommonStyles.flex.row}>
          <Text>滞在時間帯指定</Text>
          <HelperText type="error" style={styles.errorText}>
            {hourError}
          </HelperText>
        </View>
        <VMarginCompact />
        <View style={styles.week}>
          {weekList.map((value) => {
            return (
              <View key={value.name}>
                <VForm.Check<AttrForm>
                  {...value}
                  compact={true}
                  disabled={formDisabled}
                />
              </View>
            );
          })}
        </View>
        <View style={[CommonStyles.flex.row, styles.offMargin]}>
          <Text>曜日指定</Text>
          <HelperText type="error" style={styles.errorText}>
            {weekError}
          </HelperText>
        </View>
      </View>
      <View
        style={[
          CommonStyles.flex.row,
          CommonStyles.flex.mainEnd,
          CommonStyles.flex.crossCenter,
          CommonStyles.margin.right,
        ]}
      >
        <ChoiceCounter type="mesh" count={count} />
        <View style={styles.captionMargin} />
        <MCIcon name="square-outline" size={12} />
        <MinText>{'=500m×500m'}</MinText>
      </View>
      <View style={CommonStyles.flex.full}>
        <MeshSelectMap
          meshes={selectedMesh}
          onSelect={selectMesh}
          onSelectMeshes={selectMeshes}
          center={shopCenter}
          changeDialogShow={setHideCounter}
        />
        {!hideCounter && <TargetCounter count={userCount} />}
      </View>
      <View style={CommonStyles.margin.all}>
        <Text>滞在履歴を設定</Text>
        <MinText>地図をクリックするとエリアが選択できます</MinText>
      </View>
    </View>
  );
});

export const styles = StyleSheet.create({
  week: {
    ...CommonStyles.flex.row,
    flexWrap: 'wrap',
  },
  offMargin: {
    marginTop: -16,
  },
  hour: {
    width: 100,
  },
  errorText: {
    height: undefined,
  },
  captionMargin: {
    marginLeft: 8,
  },
});

export default StaySetting;
