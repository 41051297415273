import {useNavigationState, useRoute} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {StepNavigationContainer} from '../../container';
import {ERROR_MSG} from '../../helper';
import {
  Header,
  MaxWidth,
  RegisterContainer,
  Text,
  TrimaButton,
  VMargin,
} from '../Elements';
import {InviteContainer} from './InviteContainer';
import {MFAForm, PhoneChangeForm} from './MFAForm';
import {SignInContainer} from './SignInContainer';
import {SignInForm} from './SignInForm';
import {SignUpContainer} from './SignUpContainer';
import {SignUpForm} from './SignUpForm';

// クエリパラメータの解釈と確認
const ParamParser: React.FC = () => {
  const {params} = useRoute();
  console.log('[ParamParser]', params);
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const {step, error, parseURL} = SignUpContainer.useContainer();
  React.useEffect(() => {
    if (step === 'init') {
      if (!params) {
        setErrorMsg(ERROR_MSG.signUp.params);
      } else {
        parseURL(params);
      }
    }
  }, [params, step, parseURL]);

  React.useEffect(() => {
    if (error) {
      console.log('[ParamParser] error', error);
      const code = 'code' in error ? `${error?.code}` : '';
      // 有効期限エラー
      if (code === '30010003') {
        setErrorMsg(
          '招待メールが送信されてから24時間以上経過したため、無効となっています。\n招待メールに記載の招待者様に、再度招待していただくようご連絡をお願いします。',
        );
      } else {
        setErrorMsg(
          `${ERROR_MSG.signUp.confirm}（${
            code && code + ': '
          }${error?.message}）`,
        );
      }
    }
  }, [error]);

  if (errorMsg) {
    return <Text>{errorMsg}</Text>;
  }
  return <ActivityIndicator />;
};

const Done: React.FC = () => {
  const {setStep2OwnerRegist, setStep2SelectOwner} =
    StepNavigationContainer.useContainer();
  const {invite} = InviteContainer.useContainer();
  return (
    <View>
      <MaxWidth maxWidth={586}>
        {!invite ? (
          <View>
            <Text>
              利用開始手続きを完了すると、すぐに広告配信を実施できます。
            </Text>
            <VMargin />
            <div
              className="btn-start-register"
              data-gtm-visibility-category="ボタン"
              data-gtm-visibility-action="表示"
              data-gtm-visibility-label="利用開始手続きへ">
              <TrimaButton variant="contained" onClick={setStep2OwnerRegist}>
                利用開始手続きへ
              </TrimaButton>
            </div>
          </View>
        ) : (
          <View>
            <Text>
              企業選択画面が表示されますので、利用したい企業を選択の上、トリマ広告ダイレクトをご利用ください。
            </Text>
            <VMargin />
            <div
              className="btn-start-register"
              data-gtm-visibility-category="ボタン"
              data-gtm-visibility-action="表示"
              data-gtm-visibility-label="トリマ広告ダイレクトへ">
              <TrimaButton variant="contained" onClick={setStep2SelectOwner}>
                トリマ広告ダイレクトへ
              </TrimaButton>
            </div>
          </View>
        )}
      </MaxWidth>
    </View>
  );
};

const RenderForm: React.FC<{label: string; children: React.ReactNode}> = ({
  label,
  children,
}) => {
  return <RegisterContainer label={label}>{children}</RegisterContainer>;
};

const SignUpSelector: React.FC = () => {
  const {step} = SignUpContainer.useContainer();
  console.log('[SignUpSelector]', step);
  switch (step) {
    case 'init':
    case 'confirm':
      return <RenderForm label="URL確認中" children={<ParamParser />} />;
    case 'input':
    case 'signup':
      return (
        <RenderForm
          label="トリマビジネスアカウント本登録"
          children={<SignUpForm />}
        />
      );
    case 'signupConfirm':
    case 'signupChallenging':
      return (
        <RenderForm
          label="確認コード入力"
          children={<MFAForm mode="signUpConfirm" />}
        />
      );
    case 'done':
      return <RenderForm label="本登録完了" children={<Done />} />;
    default:
      return null; // ここには来ないはず
  }
};

const SignInSelector: React.FC = () => {
  const {step, setRoute} = SignInContainer.useContainer();
  const {setStep2SelectOwner} = StepNavigationContainer.useContainer();
  const state = useNavigationState((state) => state);

  useEffect(() => {
    if (state.routes.length > 1) {
      setRoute({
        name: state.routes[state.routes.length - 2].name,
        params: state.routes[state.routes.length - 2].params,
      });
    }

    if (step === 'done') {
      setStep2SelectOwner();
    }
  }, [setStep2SelectOwner, step, setRoute, state]);
  console.log('[SignInSelector]', step);
  switch (step) {
    case 'init':
    case 'try':
      return <RenderForm label="ログイン" children={<SignInForm />} />;
    case 'mfa':
    case 'challenging':
      return (
        <RenderForm label="ログイン" children={<MFAForm mode="signIn" />} />
      );
    case 'changePhone':
      return (
        <RenderForm
          label="電話番号の変更"
          children={<PhoneChangeForm mode="signIn" />}
        />
      );
    case 'reSignUp':
    case 'reSignUpChallenging':
      return (
        <RenderForm
          label="確認コード入力"
          children={<MFAForm mode="reSignUpConfirm" />}
        />
      );
    case 'done':
      return <ActivityIndicator />;
    case 'reSignUpDone':
      return <RenderForm label="本登録完了" children={<Done />} />;
    default:
      return null;
  }
};

export const SignUpComponent: React.FC = () => {
  return (
    <View>
      <Header />
      <InviteContainer.Provider>
        <SignUpContainer.Provider>
          <SignUpSelector />
        </SignUpContainer.Provider>
      </InviteContainer.Provider>
    </View>
  );
};

export const SignInComponent: React.FC = () => {
  return (
    <View>
      <Header />
      <SignInContainer.Provider>
        <SignInSelector />
      </SignInContainer.Provider>
    </View>
  );
};
