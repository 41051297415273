import {Folder} from '@mui/icons-material';
import {DrawerNavigationHelpers} from '@react-navigation/drawer/lib/typescript/src/types';
import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {useTheme} from 'react-native-paper';
import Select from 'react-select';
import {Text} from '../components/Elements';
import {ShopContainer, ShopGroupsContainer, ShopsContainer} from '../container';
import {Colors, CommonStyles} from '../theme';

const HEIGHT = 53;
const AVATAR_SIZE = 44;

type ShopOption = {
  value: string;
  label: React.ReactElement;
  isDisabled?: boolean;
};

const AvatarLabel: React.FC<{
  label: string;
}> = ({label}) => {
  return (
    <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
      <Folder style={{color: Colors.white, marginLeft: 8}} />
      <Text style={styles.valueLabel} numberOfLines={1}>
        {label}
      </Text>
    </View>
  );
};

function toOption(shopGroup: any): ShopOption {
  return {
    value: shopGroup && shopGroup.id ? shopGroup.name : '',
    label: (
      <AvatarLabel label={shopGroup && shopGroup.id ? shopGroup.name : ''} />
    ),
  };
}

export const ShopGroupSelector: React.FC<{
  navigation: DrawerNavigationHelpers;
}> = () => {
  const {shopGroups, setShopGroup} = ShopGroupsContainer.useContainer();
  const {selected, setSelectedShop} = ShopContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const {colors, fonts} = useTheme();
  const [values, setValues] = useState<Array<ShopOption>>([]);
  const [selectedValues, setSelectedValues] = useState<ShopOption | null>();

  useEffect(() => {
    const options = shopGroups.map((shopGroup) => {
      return {
        value: shopGroup.id ?? shopGroup.name,
        label: <AvatarLabel label={shopGroup.name} />,
        isDisabled: !shopList.find((shop) => shop.groupId === shopGroup.id),
      };
    });
    if (shopList.find((shop) => shop.groupId === null)) {
      options.push({
        value: 'no-group',
        label: <AvatarLabel label={'グループなし'} />,
        isDisabled: !shopList.find((shop) => shop.groupId === null),
      });
    }
    const now = selected?.groupId
      ? toOption(
          shopGroups.find((shopGroup) => shopGroup.id === selected.groupId),
        )
      : options[options.length - 1];
    setValues(options);
    setSelectedValues(now);
  }, [shopGroups, shopList, selected]);

  const onOptionChange = (options: any) => {
    setSelectedValues(options);
    if (options && options.value !== 'no-group') {
      setShopGroup(options.value);
      setSelectedShop(shopList.find((shop) => shop.groupId === options.value));
    } else {
      setShopGroup(null);
      setSelectedShop(shopList.find((shop) => shop.groupId === null));
    }
  };

  return (
    <View style={styles.shopSelect}>
      <Select
        isSearchable={false}
        onChange={onOptionChange}
        theme={(theme) => ({
          borderRadius: 0,
          colors: {
            ...theme.colors,
            ...colors,
          },
          spacing: {
            ...theme.spacing,
            menuGutter: 0,
            controlHeight: HEIGHT,
          },
        })}
        options={values}
        value={selectedValues}
        styles={{
          control: (base) => {
            return {
              ...base,
              fontFamily: fonts.regular.fontFamily,
              border: 0,
              boxShadow: 'none',
              backgroundColor: Colors.headerShadow,
            };
          },
          input: (base) => ({
            ...base,
            height: AVATAR_SIZE,
          }),
          indicatorSeparator: () => ({}),
          indicatorsContainer: (base) => ({
            ...base,
            paddingRight: 8,
          }),
          menu: (base) => ({
            ...base,
            fontFamily: fonts.regular.fontFamily,
            backgroundColor: Colors.headerShadow,
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: 'calc(100vh - 56px - 53px - 53px)',
          }),
          option: (base, {isFocused, isSelected}) => ({
            ...base,
            backgroundColor:
              !isSelected && isFocused ? '#4C3575' : base.backgroundColor,
          }),
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  shopSelect: {
    height: HEIGHT,
    backgroundColor: Colors.headerShadow,
    zIndex: 1001,
  },
  valueLabel: {
    color: Colors.white,
    fontSize: 14,
    lineHeight: 16,
    marginVertical: 12,
    marginLeft: 8,
  },
});
