import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {ja} from 'date-fns/locale';
import dayjs, {extend} from 'dayjs';
import duration from 'dayjs/plugin/duration';
import split from 'graphemesplit';
import React from 'react';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {useCSVDownloader} from 'react-papaparse';
import {BRAKE_POINT} from '../../components';
import {
  Container,
  DataArea,
  FormBlock,
  MaxWidth,
  Menu,
  Overlay,
  Text,
  TrimaButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {
  ShopContainer,
  ShopGroupsContainer,
  ShopsContainer,
} from '../../container';
import {SaasReportRepository} from '../../service';
import {Colors, CommonStyles} from '../../theme';

extend(duration);
const now = dayjs();
const formatter = new Intl.NumberFormat('ja-JP');

type DataState = 'loading' | 'nodata' | 'error' | 'exist';
type GroupReport = {
  name: string;
  listed: number;
  viewed: number;
  viewedRate: number;
  amount: number;
  urlClicked: number;
  urlClickedRate: number;
  urlClickedUnit: number;
  visited: number;
  visitedRate: number;
  visitedUnit: number;
};

const Table: React.FC<{reports: any}> = React.memo(({reports}) => {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '店舗名',
      width: 174,
      headerAlign: 'center',
      valueFormatter: ({value}) => {
        if (get2byteLength(value) > 10) {
          const newValue = `${split(value).slice(0, 6).join('')}...${split(
            value,
          )
            .slice(split(value).length - 4)
            .join('')}`;
          return newValue;
        }
        return value;
      },
    },
    {
      field: 'listed',
      headerName: '一覧表示人数',
      type: 'number',
      width: 112,
      headerAlign: 'center',
    },
    {
      field: 'viewed',
      headerName: '広告閲覧人数',
      type: 'number',
      width: 112,
      headerAlign: 'center',
    },
    {
      field: 'viewedRate',
      headerName: '閲覧率',
      type: 'number',
      width: 112,
      headerAlign: 'center',
      valueFormatter: ({value}) =>
        `${Math.round((value as number) * 100 * 100) / 100}%`,
    },
    {
      field: 'amount',
      headerName: '広告料(税別)',
      type: 'number',
      width: 112,
      headerAlign: 'center',
      valueFormatter: ({value}) => `¥${formatter.format(value)}`,
    },
    {
      field: 'urlClicked',
      headerName: 'クリック人数',
      type: 'number',
      width: 112,
      headerAlign: 'center',
    },
    {
      field: 'urlClickedRate',
      headerName: 'クリック率',
      type: 'number',
      width: 112,
      headerAlign: 'center',
      valueFormatter: ({value}) =>
        `${Math.round((value as number) * 100 * 100) / 100}%`,
    },
    {
      field: 'urlClickedUnit',
      headerName: 'クリック単価',
      type: 'number',
      width: 112,
      headerAlign: 'center',
      valueFormatter: ({value}) => `¥${formatter.format(value)}`,
    },
    {
      field: 'visited',
      headerName: '来店延べ人数',
      type: 'number',
      width: 112,
      headerAlign: 'center',
    },
    {
      field: 'visitedRate',
      headerName: '来店率',
      type: 'number',
      width: 112,
      headerAlign: 'center',
      valueFormatter: ({value}) =>
        `${Math.round((value as number) * 100 * 100) / 100}%`,
    },
    {
      field: 'visitedUnit',
      headerName: '来店単価',
      type: 'number',
      width: 112,
      headerAlign: 'center',
      valueFormatter: ({value}) => `¥${formatter.format(value)}`,
    },
  ];

  if (reports.length === 0) {
    return null;
  }
  return (
    <Box sx={{width: '100%'}}>
      <DataGrid
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: Colors.base,
          },
          // '& .MuiDataGrid-cell': {
          //   borderRight: `1px solid ${Colors.base}`,
          // },
          // '& .MuiDataGrid-cell:last-child': {
          //   borderRight: 'none',
          // },
          // '& .MuiDataGrid-columnSeparator': {
          //   display: 'none',
          // },
          // '& .MuiDataGrid-columnHeader': {
          //   borderRight: `1px solid ${Colors.base}`,
          // },
        }}
        rows={reports}
        columns={columns}
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
        disableVirtualization
        density="compact"
        getRowId={(row) => row.name}
        hideFooter
        showCellRightBorder
        showColumnRightBorder
      />
    </Box>
  );
});

function percent(top: number | undefined, bottom: number | undefined) {
  if (!bottom || !top) {
    // 各値が0や数値じゃない場合は計算しない
    return 0;
  }
  const ratio = Math.round((10000 * top) / bottom) / 10000;
  return ratio;
}

const get2byteLength = (str: string): number => {
  let count = 0;
  split(str).forEach((letter) => {
    if (!letter.match(/\r?\n/g)) {
      if (letter.charCodeAt(0) >= 0x0 && letter.charCodeAt(0) <= 0x7f) {
        count += 0.5;
      } else {
        count += 1;
      }
    }
  });

  return count;
};

const Report: React.FC<{
  shopId: string;
}> = ({shopId}) => {
  const {selected} = ShopContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const {shopGroups} = ShopGroupsContainer.useContainer();
  const [dataState, setDataState] = React.useState<DataState>('loading');
  const [groupReports, setReports] = React.useState<
    Array<GroupReport> | undefined
  >([]);
  const [state, setState] = React.useState([
    {
      startDate: now.subtract(7, 'day').toDate(),
      endDate: now.toDate(),
      key: 'selection',
    },
  ]);
  const [value, setValue] = React.useState(10);
  const {CSVDownloader} = useCSVDownloader();

  React.useEffect(() => {
    if (!shopId) {
      return;
    }
    setDataState('loading');
    async function fetchData() {
      try {
        const reports = await Promise.all(
          shopList
            .filter((shop) => shop.groupId === selected?.groupId)
            .map(async (shop) => {
              return await SaasReportRepository.getShopDailyReports({
                id: shop.id,
                from: dayjs(state[0].startDate).format('YYYY-MM-DD'),
                to: dayjs(state[0].endDate).format('YYYY-MM-DD'),
              });
            }),
        );
        setReports(
          reports.map((report, index) => {
            const {listed, viewed, amount, urlClicked, visited} = report;
            return {
              name: shopList.filter(
                (shop) => shop.groupId === selected?.groupId,
              )[index].name,
              listed: listed ?? 0,
              viewed: viewed ?? 0,
              viewedRate: percent(viewed, listed),
              amount: amount ?? 0,
              urlClicked: urlClicked ?? 0,
              urlClickedRate: percent(urlClicked, viewed),
              urlClickedUnit:
                amount && urlClicked
                  ? Math.round((amount / urlClicked) * 10) / 10
                  : 0,
              visited: visited ?? 0,
              visitedRate: percent(visited, viewed),
              visitedUnit:
                amount && visited
                  ? Math.round((amount / visited) * 10) / 10
                  : 0,
            };
          }),
        );
        setDataState(reports ? 'exist' : 'nodata');
      } catch (err: any) {
        setReports(undefined);
        setDataState('error');
      }
    }
    fetchData();
  }, [shopId, state, shopList, selected]);

  const handleSelect = (item: any) => {
    setState([item.selection]);
  };

  const handleChange = (event: SelectChangeEvent<number>) => {
    setValue(event.target.value as number);
    switch (event.target.value) {
      case 10:
        setState([
          {
            startDate: now.subtract(7, 'day').toDate(),
            endDate: now.toDate(),
            key: 'selection',
          },
        ]);
        break;
      case 20:
        setState([
          {
            startDate: now.subtract(30, 'day').toDate(),
            endDate: now.toDate(),
            key: 'selection',
          },
        ]);
        break;
      case 30:
        setState([
          {
            startDate: now.date(1).toDate(),
            endDate: now.date(1).add(1, 'month').toDate(),
            key: 'selection',
          },
        ]);
        break;
      case 40:
        setState([
          {
            startDate: now.subtract(1, 'month').date(1).toDate(),
            endDate: now.date(1).subtract(1, 'day').toDate(),
            key: 'selection',
          },
        ]);
        break;
      case 50:
        setState([
          {
            startDate: state[0].startDate,
            endDate: state[0].endDate,
            key: 'selection',
          },
        ]);
        break;
      default:
        setState([
          {
            startDate: now.subtract(7, 'day').toDate(),
            endDate: now.toDate(),
            key: 'selection',
          },
        ]);
    }
  };

  const exportCsv = (reports: Array<GroupReport> | undefined) => {
    return reports
      ? reports.map((report) => {
          return {
            店舗名: report.name,
            一覧表示人数: report.listed ?? 0,
            広告閲覧人数: report.viewed ?? 0,
            閲覧率: report.viewedRate ?? 0,
            '広告料(税別)': report.amount ?? 0,
            クリック人数: report.urlClicked ?? 0,
            クリック率: report.urlClickedRate ?? 0,
            クリック単価: report.urlClickedUnit ?? 0,
            来店延べ人数: report.visited ?? 0,
            来店率: report.visitedRate ?? 0,
            来店単価: report.visitedUnit ?? 0,
          };
        })
      : [];
  };

  return (
    <View>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <FormControl variant="filled" sx={{width: '100%', maxWidth: 586}}>
          <InputLabel htmlFor="value-native-simple">
            {dayjs(state[0].startDate).format('YYYY/MM/DD')} ~{' '}
            {dayjs(state[0].endDate).format('YYYY/MM/DD')}
          </InputLabel>
          <Select
            sx={{bgcolor: 'white'}}
            value={value}
            onChange={handleChange}
            inputProps={{
              name: 'value',
              id: 'demo-controlled-open-select',
            }}>
            <MenuItem value={10}>過去7日間</MenuItem>
            <MenuItem value={20}>過去30日間</MenuItem>
            <MenuItem value={30}>今月</MenuItem>
            <MenuItem value={40}>先月</MenuItem>
            <MenuItem value={50}>カスタム（範囲指定）</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <VMargin />
      <Box sx={{display: value === 50 ? 'block' : 'none'}}>
        <DataArea>
          <MaxWidth maxWidth={352}>
            <Box
              sx={{
                margin: 'auto',
                border: '1px solid rgb(230, 230, 230)',
              }}>
              <DateRange
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                rangeColors={['#7E57C2', '#3ecf8e', '#fed14c']}
                ranges={state}
                direction="vertical"
                locale={ja}
              />
            </Box>
            <VMargin />
            <Text>
              {dayjs
                .duration(
                  state[0].endDate.getTime() - state[0].startDate.getTime(),
                )
                .asDays()}
              日間選択中
            </Text>
          </MaxWidth>
        </DataArea>
      </Box>
      <VMargin />
      <Typography variant="h6" component="h2">
        {`${
          shopGroups.find((shopGroup) => shopGroup.id === selected?.groupId)
            ? shopGroups.find((shopGroup) => shopGroup.id === selected?.groupId)
                .name
            : ''
        } (${
          shopList.filter((shop) => {
            if (selected) {
              return shop.groupId === selected.groupId;
            } else {
              return shop.groupId === null;
            }
          }).length
        }店舗)`}
      </Typography>
      <VMargin />
      <DataArea>
        <Table reports={groupReports} />
        {dataState !== 'exist' && (
          <Overlay>
            {dataState === 'loading' && <ActivityIndicator size={40} />}
            {dataState === 'nodata' && <Text>データがありません</Text>}
            {dataState === 'error' && <Text>データ取得エラー</Text>}
          </Overlay>
        )}
      </DataArea>
      <VMargin />
      <VMargin />
      <MaxWidth maxWidth={586}>
        <CSVDownloader
          filename={`report_group_${dayjs(state[0].startDate).format(
            'YYYYMMDD',
          )}-${dayjs(state[0].endDate).format('YYYYMMDD')}`}
          bom={true}
          config={{
            header: true,
          }}
          data={exportCsv(groupReports)}>
          <TrimaButton variant="outlined">CSVを出力する</TrimaButton>
        </CSVDownloader>
      </MaxWidth>
    </View>
  );
};

const Main: React.FC = React.memo(() => {
  const [shopId] = React.useState<string | undefined>(
    ShopContainer.useContainer().selected?.id,
  );

  if (!shopId) {
    return (
      <Container>
        <MaxWidth maxWidth={BRAKE_POINT.desktop}>
          <View style={CommonStyles.padding.all}>
            <Text>店舗の選択状態が不正です</Text>
          </View>
        </MaxWidth>
      </Container>
    );
  }

  return (
    <Container>
      <MaxWidth maxWidth={1400}>
        <View style={CommonStyles.padding.all}>
          <WithHint id="reportsGroup">
            <Menu>グループ配信結果</Menu>
          </WithHint>
          <VMargin />
          <FormBlock>
            <Report shopId={shopId} />
          </FormBlock>
        </View>
      </MaxWidth>
    </Container>
  );
});

export const ReportGroups = {
  Main,
};
