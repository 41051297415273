import dayjs from 'dayjs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {DataTable} from 'react-native-paper';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {ShopContainer} from '../../container';
import {Helpers} from '../../helper';
import {
  DailyRecord,
  RangeReportResponse,
  SaasReportRepository,
} from '../../service';
import {Colors, CommonStyles} from '../../theme';
import {Caption, DataArea, Overlay, Text, VMargin} from '../Elements';

const TermLabel: React.FC<{reports?: RangeReportResponse}> = ({reports}) => {
  const records = reports?.dailyRecords;
  let term = '';
  if (records && records.length !== 0) {
    term = `${records[0].stamp}〜${records[records.length - 1].stamp}`;
  }
  return (
    <View>
      <Caption>{term}</Caption>
    </View>
  );
};

const Table: React.FC<{
  reports?: RangeReportResponse;
  creative?: any;
}> = React.memo(({reports, creative}) => {
  console.log(creative);
  const {selected} = ShopContainer.useContainer();
  let budget: string | undefined = '上限なし';
  let used = '---円';
  let usedPercent = '---円';

  let visited = '---人';
  let unit = '---円';
  let clickedUrl = '---人';
  let clickedUrlUnit = '---円';
  if (reports) {
    used =
      reports.amount === undefined
        ? '---円'
        : `${Helpers.sepComma(reports.amount)}円`;
    visited = selected?.legalUrl
      ? '---人'
      : reports.visited === undefined
      ? '未計測'
      : `${Helpers.sepComma(reports.visited)}人`;
    unit =
      reports.visited && reports.amount !== undefined
        ? `${Helpers.sepComma(
            Math.round((reports.amount / reports.visited) * 10) / 10,
          )}円`
        : '---円';
    clickedUrl =
      reports.urlClicked === undefined
        ? '---人'
        : `${Helpers.sepComma(reports.urlClicked)}人`;
    clickedUrlUnit =
      reports.amount && reports.urlClicked !== undefined
        ? `${Helpers.sepComma(
            Math.round((reports.amount / reports.urlClicked) * 10) / 10,
          )}円`
        : '---円';
  }
  if (creative) {
    budget =
      creative.budget === undefined || creative.budget === null
        ? '---円'
        : creative.budget === 0
        ? '上限なし'
        : `${Helpers.sepComma(creative.budget)}円 まで`;
  }
  if (reports && creative) {
    usedPercent =
      reports.amount === undefined ||
      creative.budget === undefined ||
      creative.budget === null ||
      creative.budget === 0
        ? '---%'
        : `${Math.round((reports.amount / creative.budget) * 100)}%`;
  }

  return (
    <DataTable style={styles.dataTable}>
      <DataTable.Row style={CommonStyles.borderless}>
        <DataTable.Cell numeric>予算上限：</DataTable.Cell>
        <DataTable.Cell numeric>{budget}</DataTable.Cell>
      </DataTable.Row>
      <DataTable.Row style={CommonStyles.borderless}>
        <DataTable.Cell numeric>使用金額：</DataTable.Cell>
        <DataTable.Cell numeric>
          {used}({usedPercent})
        </DataTable.Cell>
      </DataTable.Row>
      <DataTable.Row style={CommonStyles.borderless}>
        <DataTable.Cell numeric>クリック人数：</DataTable.Cell>
        <DataTable.Cell numeric>{clickedUrl}</DataTable.Cell>
      </DataTable.Row>
      <DataTable.Row style={CommonStyles.borderless}>
        <DataTable.Cell numeric>クリック単価：</DataTable.Cell>
        <DataTable.Cell numeric>{clickedUrlUnit}</DataTable.Cell>
      </DataTable.Row>
      {!selected?.legalUrl && (
        <>
          <DataTable.Row style={CommonStyles.borderless}>
            <DataTable.Cell numeric>来店延べ人数：</DataTable.Cell>
            <DataTable.Cell numeric>{visited}</DataTable.Cell>
          </DataTable.Row>
          <DataTable.Row style={CommonStyles.borderless}>
            <DataTable.Cell numeric>来店単価：</DataTable.Cell>
            <DataTable.Cell numeric>{unit}</DataTable.Cell>
          </DataTable.Row>
        </>
      )}
    </DataTable>
  );
});

const Graph: React.FC<{
  records?: DailyRecord[];
}> = ({records}) => {
  const {selected} = ShopContainer.useContainer();
  const reports =
    records?.map(({stamp, amount, visited, urlClicked, ...rest}) => {
      const day = dayjs(stamp);
      return {
        stamp: day.isValid() ? day.format('MM/DD(ddd)') : stamp,
        amount,
        visited: visited ? visited : 0,
        urlClicked: urlClicked ? urlClicked : 0,
        ...rest,
      };
    }) ?? [];
  const isEmpty = reports.length === 0;
  return (
    <DataArea>
      <ResponsiveContainer width="95%" height={300}>
        <ComposedChart
          data={reports}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="stamp" fontSize={10} />
          <YAxis
            yAxisId="left"
            label={{
              value: '広告閲覧人数・使用金額',
              angle: -90,
              dx: -20,
            }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            label={{
              value: `クリック人数${
                !selected?.legalUrl ? '・来店延べ人数' : ''
              }`,
              angle: 90,
              dx: 20,
            }}
          />
          <Tooltip />
          <Legend />
          <Bar
            yAxisId="right"
            dataKey="urlClicked"
            name="クリック人数"
            barSize={20}
            fill={'#0499FA'}
          />
          {!selected?.legalUrl && (
            <Bar
              yAxisId="right"
              dataKey="visited"
              name="来店延べ人数"
              barSize={20}
              fill={Colors.accent}
            />
          )}
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="viewed"
            name="広告閲覧人数"
            stroke={Colors.main}
          />
          <Line
            yAxisId="left"
            type="monotone"
            dataKey="amount"
            name="使用金額"
            stroke={Colors.black}
            activeDot={{r: 8}}
          />
        </ComposedChart>
      </ResponsiveContainer>
      {isEmpty && (
        <Overlay>
          <Text>データがありません。</Text>
        </Overlay>
      )}
    </DataArea>
  );
};

export const DeliveryReportSummary: React.FC<{
  creative: any;
}> = React.memo(({creative}) => {
  const [reports, setReports] = React.useState<RangeReportResponse | undefined>(
    undefined,
  );
  React.useEffect(() => {
    async function fetchData() {
      if (creative.id) {
        const report = await SaasReportRepository.getDeliveryDailyReport(
          creative.id,
          7,
        );
        setReports(report);
      }
    }
    fetchData();
  }, [creative]);

  return (
    <View>
      <Text>最新の配信</Text>
      <VMargin />
      <Text>{creative.title}</Text>
      <TermLabel reports={reports} />
      <VMargin />
      <DataArea>
        <Table reports={reports} creative={creative} />
        {!reports && (
          <Overlay>
            <Text>データがありません。</Text>
          </Overlay>
        )}
      </DataArea>
      <VMargin />
      <Graph records={reports?.dailyRecords} />
    </View>
  );
});

const styles = StyleSheet.create({
  dataTable: {
    width: 280,
  },
});
