// 都道府県コード/名称リスト
import _ from 'lodash';
import {UserOption} from '../../../../API';

export const PREFECTURES_LIST: {[P in number]: string} = {
  1: '北海道',
  2: '青森県',
  3: '岩手県',
  4: '宮城県',
  5: '秋田県',
  6: '山形県',
  7: '福島県',
  8: '茨城県',
  9: '栃木県',
  10: '群馬県',
  11: '埼玉県',
  12: '千葉県',
  13: '東京都',
  14: '神奈川県',
  15: '新潟県',
  16: '富山県',
  17: '石川県',
  18: '福井県',
  19: '山梨県',
  20: '長野県',
  21: '岐阜県',
  22: '静岡県',
  23: '愛知県',
  24: '三重県',
  25: '滋賀県',
  26: '京都府',
  27: '大阪府',
  28: '兵庫県',
  29: '奈良県',
  30: '和歌山県',
  31: '鳥取県',
  32: '島根県',
  33: '岡山県',
  34: '広島県',
  35: '山口県',
  36: '徳島県',
  37: '香川県',
  38: '愛媛県',
  39: '高知県',
  40: '福岡県',
  41: '佐賀県',
  42: '長崎県',
  43: '熊本県',
  44: '大分県',
  45: '宮崎県',
  46: '鹿児島県',
  47: '沖縄県',
};

// 都道府県コード型
export type PREF_CODE = keyof typeof PREFECTURES_LIST;
// 都道府県名称型
// export type PREF_NAME = typeof PREFECTURES_LIST[PREF_CODE];
// 地方
export type PREF_REGION = {name: string; list: PREF_CODE[]};

// 都道府県選択用のグルーピング
export const PREFECTURES_GROUP_LIST: (PREF_CODE | PREF_REGION)[] = [
  1,
  {name: '東北', list: [2, 5, 3, 6, 4, 7]},
  {name: '関東', list: [10, 9, 8, 11, 13, 12, 14]},
  {name: '中部', list: [15, 16, 17, 18, 19, 20, 21, 22, 23]},
  {name: '近畿', list: [24, 25, 26, 27, 28, 29, 30]},
  {name: '中国', list: [31, 32, 33, 34, 35]},
  {name: '四国', list: [36, 37, 38, 39]},
  {name: '九州', list: [40, 41, 42, 43, 44, 45, 46]},
  47,
];

const SEPARATOR = ',';

export function prefList2String(list: PREF_CODE[]): string {
  return _.join(list, SEPARATOR);
}

export function string2PrefList(listString: string): PREF_CODE[] {
  return _.split(listString, SEPARATOR)
    .filter((s) => !!s)
    .map((str) => parseInt(str, 10));
}

export function getPrefecture(
  userOption?: UserOption | null,
): number[] | undefined {
  const address = userOption?.address;
  if (!address) {
    return undefined;
  }
  const filtered = address.filter((v) => typeof v === 'number');
  return filtered.length > 0 ? (filtered as number[]) : undefined;
}

export const regions = [
  {
    id: '0',
    name: '北海道',
    prefs: [{prefCode: '01', prefName: '北海道'}],
  },
  {
    id: '1',
    name: '東北',
    prefs: [
      {prefCode: '02', prefName: '青森県'},
      {prefCode: '05', prefName: '秋田県'},
      {prefCode: '03', prefName: '岩手県'},
      {prefCode: '06', prefName: '山形県'},
      {prefCode: '04', prefName: '宮城県'},
      {prefCode: '07', prefName: '福島県'},
    ],
  },
  {
    id: '2',
    name: '関東',
    prefs: [
      {prefCode: '10', prefName: '群馬県'},
      {prefCode: '09', prefName: '栃木県'},
      {prefCode: '08', prefName: '茨城県'},
      {prefCode: '11', prefName: '埼玉県'},
      {prefCode: '13', prefName: '東京都'},
      {prefCode: '12', prefName: '千葉県'},
      {prefCode: '14', prefName: '神奈川県'},
    ],
  },
  {
    id: '3',
    name: '中部',
    prefs: [
      {prefCode: '15', prefName: '新潟県'},
      {prefCode: '16', prefName: '富山県'},
      {prefCode: '17', prefName: '石川県'},
      {prefCode: '18', prefName: '福井県'},
      {prefCode: '19', prefName: '山梨県'},
      {prefCode: '20', prefName: '長野県'},
      {prefCode: '21', prefName: '岐阜県'},
      {prefCode: '22', prefName: '静岡県'},
      {prefCode: '23', prefName: '愛知県'},
    ],
  },
  {
    id: '4',
    name: '近畿',
    prefs: [
      {prefCode: '24', prefName: '三重県'},
      {prefCode: '25', prefName: '滋賀県'},
      {prefCode: '26', prefName: '京都府'},
      {prefCode: '27', prefName: '大阪府'},
      {prefCode: '28', prefName: '兵庫県'},
      {prefCode: '29', prefName: '奈良県'},
      {prefCode: '30', prefName: '和歌山県'},
    ],
  },
  {
    id: '5',
    name: '中国',
    prefs: [
      {prefCode: '31', prefName: '鳥取県'},
      {prefCode: '32', prefName: '島根県'},
      {prefCode: '33', prefName: '岡山県'},
      {prefCode: '34', prefName: '広島県'},
      {prefCode: '35', prefName: '山口県'},
    ],
  },
  {
    id: '6',
    name: '四国',
    prefs: [
      {prefCode: '36', prefName: '徳島県'},
      {prefCode: '37', prefName: '香川県'},
      {prefCode: '38', prefName: '愛媛県'},
      {prefCode: '39', prefName: '高知県'},
    ],
  },
  {
    id: '7',
    name: '九州',
    prefs: [
      {prefCode: '40', prefName: '福岡県'},
      {prefCode: '41', prefName: '佐賀県'},
      {prefCode: '42', prefName: '長崎県'},
      {prefCode: '43', prefName: '熊本県'},
      {prefCode: '44', prefName: '大分県'},
      {prefCode: '45', prefName: '宮崎県'},
      {prefCode: '46', prefName: '鹿児島県'},
    ],
  },
  {
    id: '8',
    name: '沖縄',
    prefs: [{prefCode: '47', prefName: '沖縄県'}],
  },
];
