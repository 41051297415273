import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {BRAKE_POINT, Container, Media, useResponsive} from '../../components';
import {Coupon} from '../../components/Coupon';
import {CommonStyles} from '../../theme';

const Wrap: React.FC<{style?: ViewStyle; children: React.ReactNode}> = ({
  children,
  style,
}) => {
  return <View style={[styles.container, style]}>{children}</View>;
};

/**
 * 作成/編集で配置が共通である前提で共通コンポーネント化
 * タイトル、Submit後の振る舞いなどは異なる前提でプロパティ化。
 */
export const CouponPageLayout: React.FC<
  {
    title: React.ReactElement;
  } & React.ComponentProps<typeof Coupon.Submit> &
    React.ComponentProps<typeof Coupon.Provider>
> = React.memo(({title, children, mode, group, editTarget, ...submitProps}) => {
  const {isOverDesktop} = useResponsive();
  return (
    <Container>
      <Coupon.Provider mode={mode} editTarget={editTarget}>
        <Media mobile>
          {title}
          <Wrap>
            <View style={CommonStyles.margin.top} />
            <Coupon.Forms />
          </Wrap>
          <Wrap>
            <Coupon.Preview />
          </Wrap>
          <Wrap>
            <Coupon.TitleForm />
            <View style={CommonStyles.margin.top} />
            <Coupon.Submit
              {...submitProps}
              editTarget={editTarget}
              group={group}
            />
          </Wrap>
          <View style={CommonStyles.margin.bottom} />
        </Media>
        <Media tablet desktop>
          <View style={styles.desktop}>
            <View style={isOverDesktop ? styles.max : styles.fullWidth}>
              {title}
              <View style={[CommonStyles.flex.row]}>
                <Wrap style={styles.leftPanel}>
                  <Coupon.Preview />
                </Wrap>
                <View style={styles.rightPanel}>
                  <Wrap>
                    <Coupon.Forms />
                  </Wrap>
                  <Wrap>
                    <Coupon.TitleForm />
                  </Wrap>
                </View>
              </View>
              <View style={CommonStyles.margin.all}>
                <View style={CommonStyles.margin.top} />
                <Coupon.Submit
                  {...submitProps}
                  editTarget={editTarget}
                  group={group}
                />
              </View>
            </View>
          </View>
        </Media>
      </Coupon.Provider>
    </Container>
  );
});

const styles = StyleSheet.create({
  container: {
    ...CommonStyles.formBlock,
    ...CommonStyles.margin.all,
    marginBottom: 0,
  },
  desktop: {
    alignItems: 'center',
  },
  fullWidth: {
    alignSelf: 'stretch',
  },
  max: {
    width: BRAKE_POINT.desktop + 16,
  },
  leftPanel: {
    marginRight: 0,
  },
  rightPanel: {
    flex: 1,
  },
});
