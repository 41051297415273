import {Autocomplete, Popper, TextField, useMediaQuery} from '@mui/material';
import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {Colors} from '../../../../theme';
import {AddrSuggestContext} from './state/AddrSuggestContext';
import {GooglePlaceSuggest, GooglePlaceSuggestSession} from './state/types.def';

type InputChangeReason = 'input' | 'reset' | 'clear';
type SelectReason =
  | 'createOption'
  | 'selectOption'
  | 'removeOption'
  | 'blur'
  | 'clear';

export interface GoogleAddrSuggestProps {
  state?: GooglePlaceSuggestSession;
  style?: ViewStyle;
}

const GoogleAddrSuggest: React.FC<GoogleAddrSuggestProps> = ({style}) => {
  return (
    <View style={[styles.googleAddrBar, style]}>
      <SearchInput />
    </View>
  );
};

const PopperMy = (props: any) => {
  return (
    <Popper
      {...props}
      style={{
        borderRadius: '8px',
        maxHight: 300,
        width: 280,
        backgroundColor: '#f9f9f9',
      }}
      placement="bottom-start"
    />
  );
};

export const SearchInput: React.FC = () => {
  const containerClass = useMediaQuery('(min-width:380px)')
    ? styles.container
    : styles.containerSe1;

  const {suggest, searchKey, selected, updateSearchKey, selectSuggest} =
    AddrSuggestContext.useContainer();

  const options = suggest?.suggest || [];

  const handleChange = (_e: unknown, val: string, reason: string) => {
    if ((reason as InputChangeReason) !== 'reset') {
      const key = val || '';
      updateSearchKey(key);
    }
  };

  const handleOption = (
    _e: unknown,
    val: string | GooglePlaceSuggest | null,
    reason: string,
  ) => {
    if (
      (reason as SelectReason) === 'selectOption' &&
      val &&
      typeof val !== 'string'
    ) {
      selectSuggest(val);
    }
  };

  const key = selected ? selected.description : searchKey;

  return (
    <View style={containerClass}>
      <Autocomplete
        PopperComponent={PopperMy}
        sx={{
          option: {
            // Selected
            '&[aria-selected="true"]': {
              backgroundColor: Colors.main,
              color: '#f9f9f9',
            },
          },
          // clear icon
          '& .MuiAutocomplete-clearIndicator': {
            color: Colors.main,
          },
        }}
        disablePortal
        freeSolo
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        getOptionLabel={(dto) => {
          if (typeof dto === 'string') {
            return dto;
          }
          return dto.description;
        }}
        inputValue={key}
        onInputChange={handleChange}
        onChange={handleOption}
        value={key}
        renderInput={(params) => (
          <TextField
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: '8px',
                },
              },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                borderColor: '#757575',
              },
              '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: '#B2B2B2',
                },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                {
                  borderColor: Colors.main,
                },
              backgroundColor: '#f9f9f9',
              borderRadius: 1,
            }}
            {...params}
            placeholder={'住所を検索'}
            variant={'outlined'}
            size={'small'}
          />
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 200,
  },
  containerSe1: {
    width: 150,
  },
  googleAddrBar: {
    position: 'absolute',
    top: 12,
    left: 8,
  },
});

export {GoogleAddrSuggest};
