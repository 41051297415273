import {useCallback, useState} from 'react';
import {SaaSImageContents} from '../_proto/services/SaaSRepository';

type Image = SaaSImageContents;

export type UseImages = {
  images: Image[];
  addImage(uri: string): void;
  removeImage(index?: number): void;
  changeImage(uri: string, index?: number): void;
};

export type UseBarcode = {
  barcode: string;
  addBarcode(uri: string): any;
  removeBarcode(): any;
};

// 店舗登録、クーポンなど複数枚画像をアップロードする箇所で利用
export function useImages(initImages: Image[] = []): UseImages {
  const [images, setImages] = useState<SaaSImageContents[]>(initImages);

  const addImage = useCallback((uri: string) => {
    setImages((prev) => {
      return [
        ...prev,
        {
          uri,
          key: `image_${prev.length}`,
        },
      ];
    });
  }, []);

  const removeImage = useCallback(
    (index?: number) => {
      const target = index ?? 0;
      setImages(images.filter((_, index) => index !== target));
    },
    [images],
  );

  const changeImage = useCallback((uri: string, index?: number) => {
    setImages((prev) => {
      return prev.map((image) =>
        image.key === `image_${index}` ? {key: `image_${index}`, uri} : image,
      );
    });
  }, []);

  return {
    images,
    addImage,
    removeImage,
    changeImage,
  };
}

export function useBarcode(initBarcode?: string): UseBarcode {
  const [barcode, setBarcode] = useState<any>(initBarcode);

  const addBarcode = useCallback((uri: string) => {
    setBarcode(uri);
  }, []);

  const removeBarcode = useCallback(() => {
    setBarcode(undefined);
  }, []);

  return {
    barcode,
    addBarcode,
    removeBarcode,
  };
}
