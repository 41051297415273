import {Close} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  IconButton as MuiIconButton,
} from '@mui/material';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {IconButton, useTheme} from 'react-native-paper';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import {Colors} from '../../../theme';
import {Text, VMargin} from '../index';
import {Hint, HintPattern} from './def';

export type HintButtonProps = {
  id: HintPattern;
};

export const HintButton: React.FC<HintButtonProps> = ({id}) => {
  const param = Hint[id];
  const [show, setShow] = React.useState<boolean>(false);
  const {colors} = useTheme();
  const hint = {
    backgroundColor: colors.primary,
    marginVertical: 0,
  };
  return (
    <View>
      <IconButton
        onPress={() => setShow(true)}
        style={hint}
        size={12}
        icon={({size}) => {
          return (
            <FontAwesomeIcon
              name="question"
              size={size + 4}
              color={colors.background}
              style={styles.icon}
            />
          );
        }}
      />
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: Colors.lightgray,
          },
        }}
        fullWidth={true}
        maxWidth="sm"
        onClose={() => setShow(false)}
        open={show}>
        <DialogTitle id="id">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{param.title}</Box>
            <Box>
              <MuiIconButton onClick={() => setShow(false)}>
                <Close />
              </MuiIconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <View>
            <Text>{param.comment}</Text>
            {param.link && (
              <View>
                <VMargin />
                <Link href={param.link} target="_blank" rel="noopener">
                  詳細はこちら
                </Link>
              </View>
            )}
          </View>
        </DialogContent>
        <VMargin />
      </Dialog>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonArea: {
    position: 'absolute',
    top: 8,
    right: -8,
  },
  icon: {
    marginRight: 1,
    marginBottom: 1,
  },
});
