import React from 'react';
import {View} from 'react-native';
import {OwnerContainer} from '../../container';
import {CommonStyles} from '../../theme';
import {FormBlock, MaxWidth, Menu, Text, VMargin} from '../Elements';
import {useAgencyName} from './helpers';

export const AgencyInfoPreview: React.FC = () => {
  const {owner} = OwnerContainer.useContainer();
  const {agencyName} = useAgencyName(owner?.agentCode ?? undefined);
  const isShowAgencyInfo = !!owner?.agentCode;

  if (!isShowAgencyInfo) {
    return null;
  }
  return (
    <View style={CommonStyles.margin.top}>
      <FormBlock>
        <MaxWidth maxWidth={586}>
          <Menu>代理店情報</Menu>
          <VMargin />
          <Text>代理店名</Text>
          <Text style={CommonStyles.margin.left}>{agencyName}</Text>
          <VMargin />
          <Text>代理店コード</Text>
          <Text style={CommonStyles.margin.left}>{owner?.agentCode}</Text>
          <VMargin />
        </MaxWidth>
      </FormBlock>
    </View>
  );
};
