import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Colors, CommonStyles} from '../../../theme';
import {MinText} from '../../Elements';
import {useTheme} from 'react-native-paper';

type CountType = 'mesh' | 'station' | 'all';

const TEXT: {[P in CountType]: string} = {
  mesh: 'の選択数',
  station: '駅の選択数',
  all: 'と駅の選択数の合計',
};

// メッシュ、駅の選択数表示用カウンター
export const ChoiceCounter: React.FC<{
  type: CountType;
  count: number;
}> = ({count, type}) => {
  const {colors} = useTheme();
  const mesh = {backgroundColor: colors.accent, borderColor: Colors.darkgray};
  return (
    <View
      style={[
        CommonStyles.flex.row,
        CommonStyles.flex.mainEnd,
        CommonStyles.flex.crossCenter,
        CommonStyles.align.self.stretch,
      ]}
    >
      {type !== 'station' && <View style={[styles.meshBlock, mesh]} />}
      <MinText>
        {TEXT[type]}：{count}
      </MinText>
    </View>
  );
};

const styles = StyleSheet.create({
  meshBlock: {
    width: 16,
    height: 16,
    borderWidth: 1,
    marginRight: 3,
  },
});
