import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button} from '@mui/material';
import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';
import React from 'react';
import {ActivityIndicator} from 'react-native-paper';
import {createContainer} from 'unstated-next';
import {DialogBase, Text, TrimaButton} from '../components/Elements';
import {
  AdminContainer,
  AuthContainer,
  OwnerContainer,
  StepNavigationContainer,
} from '../container';
import {SaasOwnerQuery, graphQLService} from '../service';
import {Fonts} from '../theme';

type UseOwnerSelector = {
  show(option: {fixed?: boolean; addButton?: boolean}): void;
  hide(): void;
};
type OwnerSelectorProp = {
  visible: boolean;
  dismissable: boolean;
  isAddButton: boolean;
};

function useOwnerSelectorFunc(): UseOwnerSelector & OwnerSelectorProp {
  const [status, setStatus] = React.useState<'hide' | 'visible' | 'fixed'>(
    'hide',
  );
  const [isAddButton, setIsAddButton] = React.useState<boolean>(true);
  const show = React.useCallback<
    (option: {fixed?: boolean; addButton?: boolean}) => void
  >(({fixed, addButton}) => {
    setStatus(fixed ? 'fixed' : 'visible');
    setIsAddButton(!!addButton);
  }, []);
  const hide = React.useCallback<() => void>(() => {
    setStatus('hide');
  }, []);

  const visible = status !== 'hide';
  const dismissable = status !== 'fixed';

  return {
    show,
    hide,
    visible,
    dismissable,
    isAddButton,
  };
}

const OwnerSelectContainer = createContainer(useOwnerSelectorFunc);
const useOwnerSelector: () => UseOwnerSelector =
  OwnerSelectContainer.useContainer;

const OwnerSelector: React.FC = React.memo(() => {
  const {visible} = OwnerSelectContainer.useContainer();
  return visible ? <OwnerSelectorInner /> : null;
});

const title = '使用する企業を選択';
const OwnerSelectorInner: React.FC = () => {
  const {visible, dismissable, hide, isAddButton} =
    OwnerSelectContainer.useContainer();
  const {setStep2OwnerRegist} = StepNavigationContainer.useContainer();
  const {owner, fetchOwner} = OwnerContainer.useContainer();
  const [ownerList, setOwnerList] = React.useState<
    undefined | SaasOwnerQuery[] | 'error'
  >();
  const {isAdmin, userId, ownerId} = AdminContainer.useContainer();
  const {user} = AuthContainer.useContainer();

  React.useEffect(() => {
    async function fetchData() {
      try {
        if (!isAdmin) {
          const ownerId = await AsyncStorage.getItem('owner');
          if (ownerId && !owner) {
            await fetchOwner(ownerId);
          }
          const fetchedOwners = await graphQLService.getListOwner(
            user.username,
          );
          console.log('[OwnerSelector] ownerList fetched', fetchedOwners);
          setOwnerList(fetchedOwners);
        } else {
          if (ownerId && !owner) {
            await fetchOwner(ownerId);
          }
          const fetchedOwners = await graphQLService.getListOwner(userId);
          console.log('[OwnerSelector] ownerList fetched', fetchedOwners);
          setOwnerList(fetchedOwners);
        }
      } catch {
        setOwnerList('error');
      }
    }
    fetchData().then();
  }, [owner, fetchOwner, user, isAdmin, userId, ownerId]);

  return (
    <DialogBase {...{visible, title, onDismiss: hide, dismissable}}>
      {ownerList === undefined ? (
        <ActivityIndicator size={60} />
      ) : ownerList === 'error' ? (
        // Todo エラー時の動作確認
        <Text>企業一覧の取得に失敗しました</Text>
      ) : (
        <>
          {ownerList.map((o) => {
            return <OwnerButton owner={o} key={o?.id} />;
          })}
          {isAddButton && (
            <Box pt={1}>
              <TrimaButton
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => {
                  hide();
                  setStep2OwnerRegist();
                }}
              >
                企業を新規作成
              </TrimaButton>
            </Box>
          )}
        </>
      )}
    </DialogBase>
  );
};

const OwnerButton: React.FC<{owner: SaasOwnerQuery}> = ({owner}) => {
  const {checkInitData} = StepNavigationContainer.useContainer();
  const {user} = AuthContainer.useContainer();
  const {hide} = OwnerSelectContainer.useContainer();
  const onClick = _.throttle(
    async () => {
      if (owner?.id && user.username) {
        hide();
        try {
          await AsyncStorage.setItem('owner', owner.id);
        } catch (e) {
          console.log('[OwnerButton] onClick catch', e);
        }
        await checkInitData(owner.id, user.username);
      }
    },
    1000,
    {trailing: false},
  );
  return (
    <Button
      sx={{
        minHeight: 50,
        maxHeight: 50,
        fontSize: Fonts.base.fontSize,
        marginBottom: 1 / 2,
        justifyContent: 'flex-start',
      }}
      variant="outlined"
      color="primary"
      disabled={!owner?.id}
      onClick={onClick}
    >
      {owner?.hojin}
    </Button>
  );
};

export default OwnerSelector;
export {OwnerSelectContainer, useOwnerSelector};
