import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSBillingRepository} from '../../_proto/services/SaaSBillingRepository';

export type ExcludeVisitorsFormData = {
  invitedCode?: string;
  shopId: string;
};

const usePaymentMethodContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [paymentMethods, setPaymentMethods] = useState<any>();

  /**
   * サーバーから支払い方法のリストを取得
   */
  const getData = useCallback(async (data: {customerId: string}) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const [customer, getListPaymentMethods] = await Promise.all([
        SaaSBillingRepository.getCustomer(data),
        SaaSBillingRepository.getListPaymentMethods(data),
      ]);
      const chechData = getListPaymentMethods.data.map((paymentMethod: any) => {
        paymentMethod.default =
          paymentMethod.id === customer.invoice_settings.default_payment_method;
        return paymentMethod;
      });
      setPaymentMethods({
        ...getListPaymentMethods,
        data: chechData,
      });
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  /**
   * 指定した支払い方法を編集
   */
  const editData = useCallback(
    async (data: {
      paymentMethodId: string;
      billing_details: {
        name: string;
      };
      card: {
        exp_month: number;
        exp_year: number;
      };
    }) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        await SaaSBillingRepository.updatePaymentMethod(data);
        const newData = paymentMethods.data.map((paymentMethod: any) => {
          if (paymentMethod.id === data.paymentMethodId) {
            paymentMethod.billing_details.name = data.billing_details.name;
            paymentMethod.card.exp_month = data.card.exp_month;
            paymentMethod.card.exp_year = data.card.exp_year;
          }
          return paymentMethod;
        });
        setPaymentMethods({
          ...paymentMethods,
          data: newData,
        });
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [paymentMethods],
  );

  /**
   * 指定した支払い方法を削除
   */
  const removeData = useCallback(
    async (data: {paymentMethodId: string}) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        await SaaSBillingRepository.removePaymentMethod(data);
        const newData = paymentMethods.data.filter(
          (paymentMethod: any) => paymentMethod.id !== data.paymentMethodId,
        );
        setPaymentMethods({
          ...paymentMethods,
          data: newData,
        });
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [paymentMethods],
  );

  return {
    isCreateLoading,
    isDeleteLoading,
    error,
    paymentMethods,
    getData,
    editData,
    removeData,
  };
};

export const PaymentMethodContainer = createContainer(
  usePaymentMethodContainer,
);
