import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';

export const SplashScreen: React.FC = () => {
  return (
    <View>
      <ActivityIndicator size={40} />
    </View>
  );
};
