import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {createRoot} from 'react-dom/client';
import {StyleSheet, View} from 'react-native';
import {useTheme} from 'react-native-paper';
import ShopMark from '../../../../_proto/parts/map/CenterMark';
import {GoogleApiRepository} from '../../../../_proto/services/GoogleApiRepository';
import {MeshRepository} from '../../../../_proto/services/MeshRepositoy';
import {ShopContainer} from '../../../../container';
import {LngLat} from '../../../../helper';
import {Colors, CommonStyles} from '../../../../theme';
import {MCIcon} from '../../../Elements';
import {MeshCode, MeshList} from '../../types';
import {GoogleAddrSuggest} from './GoogleAddrSuggest';
import {RadiusButton, RadiusSelector, useRadius} from './RadiusSelector';
import {calcMeshesForRadius, getMeshCode} from './function';
import {AddrSuggestContext} from './state/AddrSuggestContext';

type MapRefs = {
  map: google.maps.Map | null;
  maps: unknown | null;
};

type MeshRectList = {[P in MeshCode]: google.maps.Rectangle};

type MeshSelectMapProp = {
  bound?: google.maps.LatLngBoundsLiteral;
  center?: LngLat; // if have bound, center will be ignored
  freeze?: boolean;
  meshes: MeshList;
  onSelect?(mesh: MeshCode): void;
  onSelectMeshes?(meshes: MeshCode[]): void;
  changeDialogShow?(isShow: boolean): void; // ダイアログ表示制御
};

const MAP_KEY = {key: GoogleApiRepository.KEY};
const MAP_OPTION_NORMAL = {
  fullscreenControl: false,
};
const MAP_OPTION_FREEZE = {
  disableDefaultUI: true,
  gestureHandling: 'none',
  keyboardShortcuts: false,
};
const DEFAULT_CENTER = {
  lat: 35.658517,
  lng: 139.70133399999997,
};
const MAP_CLICK_WAIT = 300;
const PRIORITY_MESH = 10;
const PRIORITY_LINE = 5;

// メッシュ区切り線のパス情報生成
const MESH_LINE_LIMIT_ZOOM = 12;
function getMeshLines(
  bounds: google.maps.LatLngBounds,
): {lat: number; lng: number}[][] {
  // 端点の取得（地図描画範囲を覆う、メッシュ境界値）
  const swMesh = getMeshCode(bounds.getSouthWest());
  const neMesh = getMeshCode(bounds.getNorthEast());
  const swBound = MeshRepository.boundOfMesh(swMesh);
  const neBound = MeshRepository.boundOfMesh(neMesh);
  const sLimit = swBound.south;
  const wLimit = swBound.west;
  const nLimit = neBound.north;
  const eLimit = neBound.east;

  // 緯度方向のパスの生成
  const lngList = [eLimit];
  const lngDiff = 22.5 / 3600;
  for (let lng = wLimit; lng < eLimit; lng += lngDiff) {
    lngList.push(lng);
  }
  const latLines = lngList.map((lng) => [
    {lat: sLimit, lng},
    {lat: nLimit, lng},
  ]);

  // 経度方向のパスの生成
  const latList = [nLimit];
  const latDiff = 15 / 3600;
  for (let lat = sLimit; lat < nLimit; lat += latDiff) {
    latList.push(lat);
  }
  const lngLines = latList.map((lat) => [
    {lat, lng: eLimit},
    {lat, lng: wLimit},
  ]);
  return latLines.concat(lngLines);
}

const MeshSelectMap: React.FC<MeshSelectMapProp> = (props) => {
  return (
    <AddrSuggestContext.Provider>
      <InnerMap {...props} />
    </AddrSuggestContext.Provider>
  );
};

const InnerMap: React.FC<MeshSelectMapProp> = ({
  bound,
  center,
  freeze,
  meshes,
  onSelect,
  onSelectMeshes,
  changeDialogShow,
}) => {
  const mapRefs = useRef<MapRefs>({map: null, maps: null});
  const meshRects = useRef<MeshRectList>({});
  const lineRefs = useRef<google.maps.Polyline[]>([]);
  const {colors} = useTheme();
  const meshColor = colors.accent;
  const radiusInit = useMemo(() => ({changeDialogShow}), [changeDialogShow]);
  const {buttonProp, selectorPropBase} = useRadius(radiusInit);
  const {selected} = ShopContainer.useContainer();
  const {detail: searched} = AddrSuggestContext.useContainer();

  // 選択された地点へ復帰
  useEffect(() => {
    const {map} = mapRefs.current;
    if (map && searched) {
      if (!_.isEqual(map.getCenter(), searched.location)) {
        map.setCenter(searched.location);
      }
    }
  }, [searched]);

  // 地図のタップ時イベントからメッシュコード判定
  const handleSingle = _.debounce((event: {latLng: google.maps.LatLng}) => {
    const {latLng} = event;
    const mesh = getMeshCode(latLng);
    onSelect && onSelect(mesh);
  }, MAP_CLICK_WAIT);

  const drawMeshLine = useCallback(() => {
    // 前回描画のクリア
    lineRefs.current.forEach((line) => line.setMap(null));
    lineRefs.current = [];

    const {map, maps} = mapRefs.current;
    if (!map || !maps) {
      return;
    }
    const bounds = map.getBounds();
    const zoom = map.getZoom();
    if (!bounds || !zoom || zoom < MESH_LINE_LIMIT_ZOOM) {
      return;
    }

    // 範囲から算出した区切り線を描画
    const paths = getMeshLines(bounds);
    paths.forEach((path) => {
      const line = new google.maps.Polyline({
        path,
        strokeColor: Colors.darkgray,
        strokeOpacity: 1.0,
        strokeWeight: 2,
        clickable: false,
        zIndex: PRIORITY_LINE,
      });
      line.setMap(map);
      lineRefs.current.push(line);
    });
    return;
  }, []);

  // 選択メッシュのレクタングル描画
  const drawMesh = useCallback(
    (mesh: any) => {
      const {map, maps} = mapRefs.current;
      if (map && maps && !meshRects.current[mesh]) {
        const bounds = MeshRepository.boundOfMesh(mesh);
        const rect = new google.maps.Rectangle({
          bounds,
          map,
          strokeColor: meshColor,
          fillColor: meshColor,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillOpacity: 0.35,
          clickable: false, // レクタングルにクリックイベントを吸われないように指定
          zIndex: PRIORITY_MESH,
        });
        meshRects.current[mesh] = rect;
      }
    },
    [meshColor],
  );

  // レクタングルのメッシュ単位削除
  const clearMesh = useCallback((mesh: any) => {
    if (meshRects.current[mesh]) {
      meshRects.current[mesh].setMap(null);
      delete meshRects.current[mesh];
    }
  }, []);

  // 描画済みのものと比較して差分対応
  const drawRect = useCallback(() => {
    const old = Object.keys(meshRects.current);
    const now = Object.keys(meshes);
    const deleteMeshes = _.difference(old, now);
    const addMeshes = _.difference(now, old);
    deleteMeshes.forEach(clearMesh);
    addMeshes.forEach(drawMesh);
  }, [clearMesh, drawMesh, meshes]);

  useEffect(() => {
    drawRect();
  }, [drawRect]);

  // 地図の範囲指定を反映
  const fitBounds = useCallback(() => {
    const {map} = mapRefs.current;
    if (map && bound) {
      map.fitBounds(bound);
    }
  }, [bound]);

  useEffect(() => {
    fitBounds();
  }, [fitBounds]);

  const handleApiLoaded: React.ComponentProps<
    typeof GoogleMapReact
  >['onGoogleApiLoaded'] = ({map, maps}) => {
    mapRefs.current = {
      map,
      maps,
    };
    drawRect();
    fitBounds();
    if (map) {
      map.addListener('click', handleSingle);
      map.addListener('dblclick', handleSingle.cancel);
      if (!freeze) {
        map.addListener('bounds_changed', drawMeshLine);
        // 半径選択ボタンの追加
        const buttonDiv = document.createElement('div');
        const root = createRoot(buttonDiv);
        root.render(<RadiusButton {...buttonProp} />);
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(buttonDiv);
      }
    }
  };

  // 半径指定
  const onRadius = (radius: number) => {
    if (onSelectMeshes) {
      onSelectMeshes(
        calcMeshesForRadius(radius, mapRefs.current?.map?.getCenter()),
      );
    }
  };

  const options = freeze ? MAP_OPTION_FREEZE : MAP_OPTION_NORMAL;
  return (
    <View style={CommonStyles.flex.full}>
      <GoogleMapReact
        bootstrapURLKeys={MAP_KEY}
        center={center || DEFAULT_CENTER}
        defaultZoom={14}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
        options={options}>
        <MapCenter />
        <ShopMark {...selected?.location} style={styles.pinOffset} />
      </GoogleMapReact>
      <RadiusSelector {...selectorPropBase} onConfirm={onRadius} />
      <GoogleAddrSuggest style={freeze ? {display: 'none'} : {}} />
    </View>
  );
};

const CENTER_MAKER_SIZE = 32;
const MapCenter = React.memo(() => {
  return (
    <View style={styles.centerMark}>
      <MCIcon name="plus" size={CENTER_MAKER_SIZE} style={styles.maker} />
    </View>
  );
});

const styles = StyleSheet.create({
  centerMark: {
    width: CENTER_MAKER_SIZE,
    height: CENTER_MAKER_SIZE,
    ...CommonStyles.flex.center,
  },
  maker: {
    position: 'absolute',
    top: -CENTER_MAKER_SIZE / 2,
    left: -CENTER_MAKER_SIZE / 2,
    lineHeight: CENTER_MAKER_SIZE,
    color: Colors.accent,
  },
  // 左上起点で決まるみたいなので、そこが中央下部になるようにオフセット
  pinOffset: {
    position: 'absolute',
    top: -44,
    left: -16,
  },
});

export default MeshSelectMap;
