import dayjs from 'dayjs';
import React, {useEffect} from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {ZendeskContainer} from '../../container/ZendeskContainer';
import {CommonStyles} from '../../theme';
import {LinkTextMin, Text} from '../Elements';

type Article = {
  created_at: string;
  html_url: string;
  title: string;
};

export const WhatsNew: React.FC = () => {
  const {articles, getArticles} = ZendeskContainer.useContainer();
  const [error, setError] = React.useState<Error | undefined>();

  useEffect(() => {
    async function fetchData() {
      try {
        if (!articles) {
          await getArticles();
        }
      } catch (err: any) {
        console.log(err);
        setError(err);
      }
    }
    fetchData();
  }, [getArticles, articles, setError]);
  return (
    <View>
      {!articles ? (
        <ActivityIndicator size={60} />
      ) : error ? (
        <Text>新着情報はありません</Text>
      ) : (
        articles.map((article: Article) => (
          <View style={CommonStyles.flex.row}>
            <Text>{dayjs(article.created_at).format('YYYY/MM/DD')}</Text>
            <View style={CommonStyles.margin.left} />
            <View style={styles.hidden}>
              <LinkTextMin url={article.html_url}>{article.title}</LinkTextMin>
            </View>
          </View>
        ))
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  // flex: rowだとうまくellipsisできないので、とりあえずhidden
  hidden: {
    ...CommonStyles.flex.full,
    overflow: 'hidden',
  },
});
