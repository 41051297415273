import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {BRAKE_POINT, Container, CreativeSettings} from '../../components';
import {
  CreativeRestored,
  convertForRestore,
  getUsedMoney,
} from '../../components/Creatives/schema';
import {
  BackLink,
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  VMargin,
} from '../../components/Elements';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {graphQLService} from '../../service';
import {CommonStyles, Message} from '../../theme';

type NProp = MainNavigationProp<'CreativesEdit'>;
type RProp = MainRouteProp<'CreativesEdit'>;

type Converted = {
  restored: CreativeRestored;
  usedMoney?: number;
  targetUsers?: number;
};

export const Edit: React.FC = React.memo(() => {
  const [converted, setConverted] = React.useState<Converted>();
  const [error, setError] = React.useState<Error | undefined>();
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const type = route.params?.type;
  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('CreativesMain');
    }
  };

  // 配信情報の取得
  React.useEffect(() => {
    async function fetchData() {
      if (id && type) {
        try {
          if (type === 'coupon') {
            const coupon = await graphQLService.getDeliveryHistory(id);
            const target = await graphQLService.getTarget(
              coupon?.targetId as string,
            );
            const restored = convertForRestore(coupon);
            const usedMoney = getUsedMoney(coupon);
            setConverted({
              restored,
              usedMoney,
              targetUsers: target.users ?? undefined,
            });
          } else if (type === 'news') {
            const news = await graphQLService.getNewsHistory(id);
            const target = await graphQLService.getTarget(
              news?.targetId as string,
            );
            const restored = convertForRestore(news);
            const usedMoney = getUsedMoney(news);
            setConverted({
              restored,
              usedMoney,
              targetUsers: target.users ?? undefined,
            });
          } else {
            setError(Error('不正なタイプが指定されています'));
          }
        } catch (err: any) {
          setError(err);
        }
      } else {
        setError(Error('対象ID/タイプが指定されていません'));
      }
    }
    fetchData();
  }, [id, type]);

  // エラー時
  if (error) {
    return (
      <View>
        <Text>エラーが発生しました</Text>
        <Text>{error.message}</Text>
      </View>
    );
  }

  // 取得前
  if (!converted) {
    return <ActivityIndicator size={40} />;
  }

  const status = converted.restored.status;
  return (
    <Container>
      <CreativeSettings.ProviderForUpdate defaultValues={converted.restored}>
        <MaxWidth maxWidth={BRAKE_POINT.tablet} style={CommonStyles.margin.all}>
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label={Message.BackNoEdit} onClick={goBack} />
          </Box>
          <Menu>配信期間・予算上限の変更</Menu>
          <VMargin />
          <FormBlock>
            <CreativeSettings.TermForm
              usedMoney={converted.usedMoney}
              editMode
              targetUsers={converted.targetUsers}
            />
          </FormBlock>
          <CreativeSettings.Update
            type={type}
            status={status}
            onDone={goBack}
          />
        </MaxWidth>
      </CreativeSettings.ProviderForUpdate>
    </Container>
  );
});
