import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Image, Linking, StyleSheet, View} from 'react-native';
import uri from '../../assets/langing.svg';
import {useResponsive} from '../../components';
import {
  FormBlock,
  Logo,
  MaxWidth,
  Text,
  TrimaButton,
  VMargin,
} from '../../components/Elements';
import {CommonStyles} from '../../theme';

export const LandingPage: React.FC = () => {
  const navigation = useNavigation();
  const {isDesktop} = useResponsive();
  const gotoSignIn = () => navigation.navigate('SignIn');
  const gotoPreSignUp = () => navigation.navigate('PreSignUp');

  const height = isDesktop ? 120 : 70;
  const scale = 1.8;
  const trimaWidth = 93.876 * scale;
  const trimaHeight = 85.071 * scale;

  return (
    <View>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <View style={styles.title}>
          <Logo height={height} />
        </View>
        <VMargin />

        <MaxWidth maxWidth={586}>
          <FormBlock>
            <Text>トリマ広告ダイレクトとは？</Text>
            <VMargin />
            <View style={styles.title}>
              <Image source={{uri, width: trimaWidth, height: trimaHeight}} />
            </View>
            <VMargin />
            <Text>
              国内最大規模のユーザー数を誇るポイントアプリ「トリマ」のユーザー様と地域の店舗様をつなぐ集客サービスです。
            </Text>
            <Text>
              店舗の商圏内で生活しているユーザー様をターゲティングし、クーポンなどの広告を配信することができます。
            </Text>
            <VMargin />
            <TrimaButton
              variant="outlined"
              onClick={() =>
                Linking.openURL('https://www.ads.trip-mile.com/service/direct/')
              }
            >
              トリマ広告ダイレクト紹介サイトを開く
            </TrimaButton>
          </FormBlock>
          <VMargin />
          <VMargin />
          <TrimaButton variant="outlined" onClick={gotoSignIn}>
            登録済みの方はログイン
          </TrimaButton>
          <VMargin />
          <VMargin />
          <TrimaButton variant="contained" onClick={gotoPreSignUp}>
            トリマビジネスアカウント登録
          </TrimaButton>
        </MaxWidth>
      </MaxWidth>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    alignItems: 'center',
    ...CommonStyles.padding.all,
  },
  buttonMargin: {
    marginTop: 120,
  },
});
