import _ from 'lodash';
import {AreaType, MovingMethod, Residence, SaaSDeliveryTarget} from '../../API';
import {
  AreaOptionDto,
  StayOptionDto,
} from '../../_proto/services/SaaSTargetRepository';
import {UserOptionDto} from '../../_proto/services/helpers/UserPropHelper';
import {UseStationCache} from './StationCacheContainer';
import {getPrefecture, prefList2String} from './area/prefecture/def';
import {dumpStation} from './area/station/stationFunc';
import {
  AreaMode,
  AttrForm,
  INTEREST_TABLE,
  Interest,
  InterestKey,
  JOB_TABLE,
  Job,
  JobKey,
  MOVING_TABLE,
  MovingKey,
  RESIDENCE_TABLE,
  ResidenceKey,
  TargetOptionType,
  USE_DEFAULT,
  WEEK_ARRAY,
} from './schema';
import {
  BaseUsersByStation,
  ResidenceUsersByMesh,
  StayUsersByMesh,
  TargetingUserCount,
} from './types';

function isEnabled(num: unknown | null | undefined): boolean {
  return num !== null && num !== undefined;
}

type Range = {lower: number; upper: number};

function getResidenceParam(residence: Residence | null) {
  switch (residence) {
    case Residence.COMPANY_HOUSING:
      return 'companyHousing';
    case Residence.MY_APARTMENT:
      return 'myApartment';
    case Residence.MY_HOUSE:
      return 'myHouse';
    case Residence.RENT_APARTMENT:
      return 'rentApartment';
    case Residence.RENT_HOUSE:
      return 'rentHouse';
    default:
      return;
  }
}

function getMovingParam(moving: MovingMethod | null) {
  switch (moving) {
    case MovingMethod.BICYCLE:
      return 'bicycle';
    case MovingMethod.BUS:
      return 'bus';
    case MovingMethod.CAR:
      return 'car';
    case MovingMethod.MOTORCYCLE:
      return 'motorcycle';
    case MovingMethod.TRAIN:
      return 'train';
    case MovingMethod.WALK:
      return 'walk';
    default:
      return;
  }
}

function getJobParam(job: number | null) {
  const TABLE: {[P in Job]: JobKey} = {
    1: 'officer',
    2: 'employee',
    3: 'temporaryEmployee',
    4: 'partTime',
    5: 'civilServant',
    6: 'teacher',
    7: 'medical',
    8: 'freelance',
    9: 'house',
    10: 'universityStudent',
    11: 'collegeStudent',
    12: 'highSchoolStudent',
    13: 'occupation',
    14: 'unemployed',
    15: 'retirement',
    16: 'other',
  };
  return job ? TABLE[job as Job] : undefined;
}

function getInterestParam(interest: number | null) {
  const TABLE: {[P in Interest]: InterestKey} = {
    1: 'sports',
    2: 'technology',
    3: 'business',
    4: 'health',
    5: 'game',
    6: 'movie',
    7: 'music',
    8: 'divination',
    9: 'comic',
    10: 'career',
    11: 'pet',
    12: 'nursing',
    13: 'childcare',
    14: 'language',
    15: 'education',
    16: 'qualification',
    17: 'investment',
    18: 'automobile',
    19: 'love',
    20: 'gambling',
    21: 'gourmet',
    22: 'travel',
    23: 'fashion',
    24: 'residence',
    25: 'beauty',
    26: 'diy',
  };

  return interest ? TABLE[interest as Interest] : undefined;
}

export const UPPER_UNLIMITED = 9999999;

// サーバー型からFormの型への変換（編集機能用）
export function convertForms(
  target: SaaSDeliveryTarget,
  mode: 'edit' | 'copy',
): AttrForm {
  const {userOption, areaOption, stayOption, stationOption, title, id} = target;
  if (!title || !id) {
    throw Error('データに異常があります');
  }

  // copy 時は 加工したtitleのみ設定
  const forms: AttrForm = Object.assign(
    _.cloneDeep(USE_DEFAULT),
    mode === 'edit' ? {title, id} : {title: title + ' のコピー'},
  );
  if (!forms.use) {
    throw Error('初期値異常'); // 来ないはず。
  }
  // 新規作成時にデフォルトで有効にされているものがあるのでuseフラグを一律落としておく
  Object.keys(forms.use).forEach((p) => {
    if (forms && forms.use) {
      forms.use[p as TargetOptionType] = false;
    }
  });
  if (userOption) {
    // ユーザー属性の復帰
    if (userOption.gender) {
      forms.use.gender = true;
      forms.gender = userOption.gender;
    }
    if (isEnabled(userOption.married)) {
      forms.use.married = true;
      forms.married = userOption.married as boolean;
    }
    if (userOption.residence) {
      forms.use.residence = true;
      forms.residence = {};
      userOption.residence.forEach((value) => {
        const param = getResidenceParam(value);
        if (forms.residence && param) {
          forms.residence[param] = true;
        }
      });
    }
    if (userOption.movingMethod) {
      forms.use.movingMethod = true;
      forms.movingMethod = {};
      userOption.movingMethod.forEach((value) => {
        const param = getMovingParam(value);
        if (forms.movingMethod && param) {
          forms.movingMethod[param] = true;
        }
      });
    }
    if (
      userOption.ageRange &&
      isEnabled(userOption.ageRange.lower) &&
      isEnabled(userOption.ageRange.upper)
    ) {
      forms.use.ageRange = true;
      forms.ageRange = userOption.ageRange as Range;
      if (forms.ageRange.upper === UPPER_UNLIMITED) {
        forms.ageRange.upper = 0;
      }
    }
    if (isEnabled(userOption.familyTogether)) {
      forms.use.familyTogether = true;
      forms.familyTogether = userOption.familyTogether as number;
    }
    if (isEnabled(userOption.childrenTogether)) {
      forms.use.children = true;
      forms.children = {};
      forms.children.together = userOption.childrenTogether as number;

      let sub = userOption.childAge;
      if (!sub) {
        // 旧仕様のケア
        sub = userOption.childYoungest;
      }
      if (sub) {
        if (isEnabled(sub.lower)) {
          forms.children.lower = sub.lower as number;
        }
        if (isEnabled(sub.upper)) {
          if (forms.children.upper === UPPER_UNLIMITED) {
            forms.children.upper = 0;
          } else {
            forms.children.upper = sub.upper as number;
          }
        }
      }
    }
    if (
      userOption.incomeRange &&
      isEnabled(userOption.incomeRange.lower) &&
      isEnabled(userOption.incomeRange.upper)
    ) {
      forms.use.income = true;
      forms.income = userOption.incomeRange as Range;
      if (forms.income.upper === UPPER_UNLIMITED) {
        forms.income.upper = 0;
      }
    }
    if (
      userOption.householdIncomeRange &&
      isEnabled(userOption.householdIncomeRange.lower) &&
      isEnabled(userOption.householdIncomeRange.upper)
    ) {
      forms.use.householdIncome = true;
      forms.householdIncome = userOption.householdIncomeRange as Range;
      if (forms.householdIncome.upper === UPPER_UNLIMITED) {
        forms.householdIncome.upper = 0;
      }
    }
    if (userOption.address) {
      const prefecture = getPrefecture(userOption);
      if (prefecture) {
        forms.use.prefecture = true;
        forms.areaPrefecture = prefList2String(prefecture);
      }
    }
    if (userOption.job) {
      forms.use.job = true;
      forms.job = {};
      userOption.job.forEach((value) => {
        const param = getJobParam(value);
        if (forms.job && param) {
          forms.job[param] = true;
        }
      });
    }
    if (userOption.interest) {
      forms.use.interest = true;
      forms.interest = {};
      userOption.interest.forEach((value) => {
        const param = getInterestParam(value);
        if (forms.interest && param) {
          forms.interest[param] = true;
        }
      });
    }
  }
  if (target.cityOption) {
    forms.use.prefecture = true;
  }

  // 居住地のフォーム復帰
  if (areaOption && areaOption.meshs && areaOption.meshs.length > 0) {
    forms.use.areaResidence = true;
    forms.areaResidence = areaOption.areaType ?? AreaType.HOME;
  }
  // 滞在地のフォーム復帰
  if (stayOption && stayOption.meshs && stayOption.meshs.length > 0) {
    forms.use.areaStay = true;
    forms.areaStay = {};
    if (stayOption.weekDays) {
      stayOption.weekDays.forEach((value, index) => {
        if (forms.areaStay) {
          forms.areaStay[WEEK_ARRAY[index]] = !!value;
        }
      });
    }
    forms.areaStay.hourFrom = 0;
    forms.areaStay.hourTo = 24;
    if (stayOption.hours && stayOption.hours.length > 0) {
      const from = stayOption.hours.indexOf(1);
      if (from >= 0 && from < stayOption.hours.length) {
        const to = stayOption.hours.indexOf(0, from);
        forms.areaStay.hourFrom = from;
        forms.areaStay.hourTo = to > 0 ? to : 24;
      }
    }
  }
  // 利用駅
  if (stationOption && stationOption.length > 0) {
    forms.use.station = true;
  }

  return forms;
}

// フォームデータからサーバー送信用への変換
function getRangeOpt(range?: Partial<Range>): Range | undefined {
  if (!range || (!range.lower && !range.upper)) {
    // 下限も上限も設定がないなら設定不要
    return undefined;
  }
  return {
    lower: range.lower ?? 0,
    upper: range.upper ? range.upper : UPPER_UNLIMITED,
  };
}

export function getDtoUserOption(
  formData: AttrForm,
  areaMode: AreaMode,
): UserOptionDto | undefined {
  const use = formData.use;
  if (!use) {
    return undefined;
  }
  const option: UserOptionDto = {};
  // useフラグが立っているものが使われる
  if (use.gender && formData.gender) {
    option.gender = formData.gender;
  }
  if (use.ageRange) {
    const range = getRangeOpt(formData.ageRange);
    if (range) {
      option.ageRange = range;
    }
  }
  if (use.married) {
    option.married = formData.married;
  }
  if (use.residence && formData.residence) {
    const residence = formData.residence;
    const arr: Residence[] = [];
    Object.keys(residence).forEach((key) => {
      const rKey = key as ResidenceKey;
      residence[rKey] && arr.push(RESIDENCE_TABLE[rKey]);
    });
    if (arr.length > 0) {
      option.residence = arr;
    }
  }
  if (use.movingMethod && formData.movingMethod) {
    const movingMethod = formData.movingMethod;
    const methods: MovingMethod[] = [];
    Object.keys(movingMethod).forEach((key) => {
      const mKey = key as MovingKey;
      movingMethod[mKey] && methods.push(MOVING_TABLE[mKey]);
    });
    if (methods.length > 0) {
      option.movingMethod = methods;
    }
  }
  if (use.children && formData.children) {
    option.childrenTogether = formData.children.together ?? 0;
    const range = getRangeOpt(formData.children);
    option.childAge = range ? range : {lower: 0, upper: UPPER_UNLIMITED};
  }
  if (use.income) {
    const range = getRangeOpt(formData.income);
    if (range) {
      option.incomeRange = range;
    }
  }
  if (use.householdIncome) {
    const range = getRangeOpt(formData.householdIncome);
    if (range) {
      option.householdIncomeRange = range;
    }
  }
  // formData.familyTogether の正常値に 0 は入らないので、この判定方法でOK
  if (use.familyTogether && formData.familyTogether) {
    option.familyTogether = formData.familyTogether;
  }

  if (use.job && formData.job) {
    const job = formData.job;
    const arr: number[] = [];
    Object.keys(job).forEach((key) => {
      const jKey = key as JobKey;
      job[jKey] && arr.push(JOB_TABLE[jKey]);
    });
    if (arr.length > 0) {
      option.job = arr;
    }
  }

  if (use.interest && formData.interest) {
    const interest = formData.interest;
    const arr: number[] = [];
    Object.keys(interest).forEach((key) => {
      const iKey = key as InterestKey;
      interest[iKey] && arr.push(INTEREST_TABLE[iKey]);
    });
    if (arr.length > 0) {
      option.interest = arr;
    }
  }

  // 都道府県モード時は都道府県エリア情報もユーザー属性に設定する必要がある
  if (areaMode === 'prefecture') {
    // option.address = string2PrefList(formData.areaPrefecture ?? '');
  }
  return Object.keys(option).length === 0 ? undefined : option;
}

export function getDtoAreaOption(
  byMesh: ResidenceUsersByMesh,
  formData: AttrForm,
): AreaOptionDto | undefined {
  if (!formData.use?.areaResidence) {
    return undefined;
  }
  const meshs = Object.keys(byMesh);
  if (meshs.length === 0) {
    return undefined;
  }
  return {meshs, areaType: formData.areaResidence ?? AreaType.BOTH};
}

export function getDtoStayOption(
  byMesh: StayUsersByMesh,
  formData: AttrForm,
): StayOptionDto | undefined {
  if (!formData.use?.areaStay || !formData.areaStay) {
    return undefined;
  }
  const meshs = Object.keys(byMesh);
  if (meshs.length === 0) {
    return undefined;
  }
  const option = formData.areaStay;
  const hourFrom = Number(option.hourFrom);
  const hourTo = Number(option.hourTo);
  const hours = [...Array(24)].map((_v, index) =>
    index >= hourFrom && index < hourTo ? 1 : 0,
  );
  const weekDays = [...Array(7)].map((_v, index) =>
    option[WEEK_ARRAY[index]] ? 1 : 0,
  );
  return {meshs, hours, weekDays};
}

export function getDtoStationOption(
  byStation: BaseUsersByStation,
  getStationName: UseStationCache['getStationName'], // 駅名取得関数
): string[] | undefined {
  const stations = Object.keys(byStation).map((code) => {
    return dumpStation(code, getStationName(code));
  });
  return stations && stations.length > 0 ? stations : undefined;
}

export function getDtoCount(count: TargetingUserCount): number {
  return typeof count === 'string' ? 0 : Math.floor(count / 10) * 10;
}
