import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {SaaSCoupon} from '../../API';
import {BRAKE_POINT} from '../../components';
import {Preview} from '../../components/CouponPreview';
import {
  BackLink,
  Container,
  DoubleButtons,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
} from '../../components/Elements';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {graphQLService} from '../../service';
import {CommonStyles, Message} from '../../theme';

type NProp = MainNavigationProp<'CouponsDetail'>;
type RProp = MainRouteProp<'CouponsDetail'>;

export const Detail: React.FC = React.memo(() => {
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const [coupon, setCoupon] = React.useState<SaaSCoupon | undefined>();
  const [error, setError] = React.useState<Error | undefined>();
  const id = route.params?.id;
  React.useEffect(() => {
    async function fetchData() {
      if (id) {
        try {
          const coupon = await graphQLService.getCoupon(id);
          if (coupon.archive) {
            setError(Error('広告を表示できません'));
          } else {
            setCoupon(coupon);
          }
        } catch (err: any) {
          setError(err);
        }
      } else {
        setError(Error('広告IDがありません'));
      }
    }
    fetchData();
  }, [id, route]);

  const gotoEdit = () =>
    navigation.navigate('CouponsEdit', {id, from: 'CouponsDetail'});
  const gotoCreatives = () =>
    navigation.navigate('CreativesCreate', {type: 'coupon', adsId: id});

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('CouponsMain');
    }
  };
  const Back = () => {
    return (
      <View>
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label={Message.BackToList} onClick={goBack} />
        </Box>
      </View>
    );
  };
  return (
    <Container>
      <MaxWidth maxWidth={BRAKE_POINT.desktop} style={CommonStyles.padding.all}>
        {error ? (
          <View>
            <Back />
            <Text>広告表示エラー（{error.message}）</Text>
          </View>
        ) : !coupon ? (
          <ActivityIndicator size={40} />
        ) : (
          <View>
            <Back />
            <Menu>{coupon.title ?? '（タイトル不明）'}</Menu>
            <View style={styles.block}>
              <View style={CommonStyles.align.self.center}>
                <Preview coupon={coupon} />
              </View>
              <View style={CommonStyles.margin.top} />
              <DoubleButtons
                button1={
                  <TrimaButton variant="contained" onClick={gotoEdit}>
                    広告の編集
                  </TrimaButton>
                }
                button2={
                  <TrimaButton variant="contained" onClick={gotoCreatives}>
                    配信する
                  </TrimaButton>
                }
              />
            </View>
          </View>
        )}
      </MaxWidth>
    </Container>
  );
});

const styles = StyleSheet.create({
  block: {
    ...CommonStyles.formBlock,
    ...CommonStyles.margin.top,
    ...CommonStyles.flex.crossFull,
  },
});
