import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {SubmitErrorHandler, useFormContext} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {TextInput} from 'react-native-paper';
import {
  HelperText,
  MaxWidth,
  Text,
  TextInputProps,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../Elements';
import {SignInContainer} from './SignInContainer';
import {SignInFormData, schemaSignInFormData} from './schema';

const formInfos: {
  [P in keyof SignInFormData]: TextInputProps<SignInFormData>;
} = {
  email: {name: 'email', label: 'メールアドレス', required: true},
  password: {name: 'password', label: 'パスワード', secureTextEntry: true},
};

const LABEL = 'SMSで確認コードを送る';

// Todo: 疎通＆エラーメッセージ
const Submit: React.FC = () => {
  const {step, error, signIn} = SignInContainer.useContainer();
  const [showError, setShowError] = React.useState('');
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<SignInFormData>();
  React.useEffect(() => {
    if (!errors.email && !errors.password) {
      setShowError('');
    }
  }, [errors.email, errors.password]);

  React.useEffect(() => {
    setShowError('');
    if (error) {
      // Todo エラーメッセージ
      setShowError(error.message);
      // Todo リトライ
    }
  }, [error]);

  const isLoading = step === 'try';
  const disabled =
    isLoading ||
    !watch('email') ||
    !watch('password') ||
    !!errors.email ||
    !!errors.password;
  const onError: SubmitErrorHandler<SignInFormData> = () =>
    setShowError('フォームの入力に誤りがあります');
  return (
    <View>
      <div
        className="btn-sign-in"
        data-gtm-visibility-category="ボタン"
        data-gtm-visibility-action="表示"
        data-gtm-visibility-label={'（サインイン）' + LABEL}
      >
        <TrimaLoadingButton
          variant="contained"
          onClick={handleSubmit(signIn, onError)}
          disabled={disabled}
          loading={isLoading}
          loadingPosition="start"
        >
          {LABEL}
        </TrimaLoadingButton>
      </div>
      <HelperText type="error">{showError}</HelperText>
    </View>
  );
};

export const SignInForm: React.FC = () => {
  const navigation = useNavigation();
  const [secureTextEntry, setSecureTextEntry] = React.useState<boolean>(true);

  return (
    <View>
      <MaxWidth maxWidth={586}>
        <VForm.Provider<SignInFormData> schema={schemaSignInFormData}>
          <VForm.Text<SignInFormData> {...formInfos.email} />
          <VForm.Text<SignInFormData>
            label={formInfos.password.label}
            name={formInfos.password.name}
            secureTextEntry={secureTextEntry}
            required
            right={
              <TextInput.Icon
                size={24}
                style={styles.eye}
                name={secureTextEntry ? 'eye' : 'eye-off'}
                onPress={() => {
                  setSecureTextEntry(!secureTextEntry);
                }}
              />
            }
          />
          <Submit />
        </VForm.Provider>
        <VMargin />
        <Text>
          パスワードをお忘れの方は以下のボタンから再設定をお願いいたします。
        </Text>
        <VMargin />
        <TrimaButton
          onClick={() => {
            navigation.navigate('PasswordForgot');
          }}
        >
          パスワードの再設定
        </TrimaButton>
      </MaxWidth>
    </View>
  );
};

const styles = StyleSheet.create({
  eye: {
    position: 'relative',
    top: 4,
  },
});
