import React from 'react';
import {View} from 'react-native';
import {CommonStyles} from '../../theme';
import {MaxWidth, Text, TrimaButton, VMargin} from '../Elements';
import {AgencyPreContainer} from './AgencyPreContainer';

const Submit: React.FC = () => {
  const {startRegist} = AgencyPreContainer.useContainer();

  return (
    <View>
      <TrimaButton variant="contained" onClick={startRegist}>
        {'トリマビジネスアカウント仮登録'}
      </TrimaButton>
    </View>
  );
};

export const AgencyInfo: React.FC = () => {
  const {agency} = AgencyPreContainer.useContainer();
  return (
    <View>
      <MaxWidth maxWidth={586}>
        <Text>代理店コード</Text>
        <Text style={CommonStyles.margin.left}>{agency?.id}</Text>
        <VMargin />
        <Text>代理店名称</Text>
        <Text style={CommonStyles.margin.left}>{agency?.name}</Text>
        <VMargin />
        <Submit />
        <VMargin />
      </MaxWidth>
    </View>
  );
};
