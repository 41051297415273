import {
  ExpandLess,
  ExpandMore,
  IndeterminateCheckBox,
} from '@mui/icons-material';
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import {Colors} from '../../theme';

// リストアイテム
const CheckListItem: React.FC<{
  label: string;
  sxListItemButton?: any;
  status: React.ComponentProps<typeof CheckIcon>['status'];
  open?: boolean;
  divider?: boolean;
  checkDisabled?: boolean;
  onClick?: any;
  onIconClick?: React.ComponentProps<typeof ListItemIcon>['onClick'];
  children?: React.ReactNode;
}> = ({
  label,
  status,
  open,
  sxListItemButton,
  divider,
  checkDisabled,
  onClick,
  onIconClick,
  children,
}) => {
  const onIconClickInner: React.ComponentProps<
    typeof CheckListItem
  >['onIconClick'] = onIconClick
    ? (event) => {
        onIconClick(event);
        event.stopPropagation();
      }
    : undefined;
  return (
    <>
      <ListItem
        sx={{
          height: 40,
          backgroundColor: Colors.gray,
          '&.MuiListItem-root': {
            padding: 0,
          },
        }}>
        <ListItemButton sx={sxListItemButton} onClick={onClick}>
          <ListItemIcon sx={{minWidth: 30}} onClick={onIconClickInner}>
            <CheckIcon status={status} disabled={checkDisabled} />
          </ListItemIcon>
          <ListItemText primary={label} />
          {children ? open ? <ExpandLess /> : <ExpandMore /> : undefined}
        </ListItemButton>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
      {divider && <Divider />}
    </>
  );
};

/**
 * チェック状態
 * @param status アイコンなし/チェックなし/ロード中/チェックあり/未決定（半チェック）
 * @param disabled
 */
const CheckIcon: React.FC<{
  status: 'none' | 'unchecked' | 'loading' | 'checked' | 'indeterminate';
  disabled?: boolean;
}> = ({status, disabled}) => {
  const icon =
    status === 'loading' ? (
      <CircularProgress size={16} />
    ) : status === 'none' ? (
      <></>
    ) : undefined;
  return (
    <Checkbox
      edge="start"
      color="primary"
      checked={status === 'checked'}
      tabIndex={-1}
      icon={icon}
      disabled={disabled}
      indeterminate={status === 'indeterminate'}
      indeterminateIcon={<IndeterminateCheckBox />}
      disableRipple
    />
  );
};

export const ListSelector = {
  List,
  Item: CheckListItem,
};
