import AsyncStorage from '@react-native-async-storage/async-storage';
import {useCallback, useEffect, useState} from 'react';
import {createContainer} from 'unstated-next';
import {OwnerContainer} from '.';
import {SaaSShop, ShopCategory} from '../API';
import defaultIcon from '../assets/icon_shop.png';
import {ShopFormData} from '../components';
import {graphQLService} from '../service';
import {LngLatIPC} from '../_proto/services/ZipAddressRepository';
import {ShopsContainer} from './ShopsContainer';

// SaaSShop型のままだと各プロパティが null/undefined のチェックが必要になるのでここで変換
export type Shop = Omit<ShopFormData, 'imageEnabled'> & {
  id: string;
  ownerId: string;
  imageUrls: string[];
  icon?: string;
  groupId?: string;
};

export const shopCategoryLabels: {[P in ShopCategory]: string} = {
  GOURMET: 'グルメ',
  GOURMET_DELIVERY: 'グルメ(デリバリー専門)',
  CAFE: 'カフェ・喫茶店',
  SUPERMARKET: 'コンビニ・スーパー',
  DRUGSTORE: 'ドラッグストア・薬局',
  FASHION: 'ファッション',
  SHOPPING: 'ショッピング',
  LEISURE: 'レジャー・スポーツ',
  SCHOOL: 'スクール・習い事',
  ENTERTAINMENT: 'エンタメ',
  SALON: '美容室・サロン',
  MASSAGE: 'マッサージ・整体',
  CLEANING: 'クリーニング',
  OTHER: 'その他',
};

function convert(saasShops: SaaSShop[]): Shop[] {
  return saasShops
    .filter((saas) => {
      return saas.id && saas.ownerId;
    })
    .map((saas) => {
      // null/undefinedチェックと規定値の設定（通信などでデータが壊れないない限りは規定値は使われないはず）
      return {
        id: saas.id as string,
        ownerId: saas.ownerId as string,
        name: saas.name ?? '名称不明店舗',
        text: saas.text ?? '',
        zip: saas.address?.zip ?? '',
        pref: saas.address?.pref ?? '',
        city: saas.address?.city ?? '',
        detail: saas.address?.detail ?? '',
        building: saas.address?.building ?? undefined,
        phone: saas.phone ?? '',
        bizHours: saas.biztimes ?? '',
        holidays: saas.holidays ?? '',
        url: saas.url ?? undefined,
        legalUrl: saas.legalUrl ?? undefined,
        category: saas.category ?? ShopCategory.OTHER,
        icon: saas.icon ? saas.icon : defaultIcon,
        imageUrls: !saas.imageUrls
          ? []
          : (saas.imageUrls.filter((image) => image) as string[]),
        location: {
          lat: saas.location?.lat ?? LngLatIPC.lat,
          lng: saas.location?.lon ?? LngLatIPC.lng,
        },
        groupId: saas.groupId ?? undefined,
        isReal: !!saas.address?.zip,
      };
    });
}

type UseShop = {
  shopList: Shop[];
  selected?: Shop;
  updateShops(id?: string): Promise<void>;
  setSelectedShop(shop: SaaSShop): void;
};

// const STORAGE_KEY = 'shop';

function useShop(initialState: SaaSShop[] = []): UseShop {
  const converted = convert(initialState);
  const [shopList, setList] = useState<Shop[]>(converted);
  const [selected, setSelected] = useState<Shop | undefined>(
    shopList.length < 2 ? shopList[0] : undefined,
  );
  const {owner} = OwnerContainer.useContainer();
  const {shopId} = ShopsContainer.useContainer();

  // 選択していた店舗の復帰
  useEffect(() => {
    // 複数店舗あるなら復帰処理を入れる
    const old = shopList.find((shop) => shop.id === shopId);
    if (old) {
      setSelected(old);
    } else {
      setSelected(shopList[0]);
    }
  }, [shopList, shopId]);

  // MerchantInfo 側は更新しなくて大丈夫かな？
  const updateShops = useCallback(
    async (id?: string) => {
      if (!owner) {
        return;
      }
      const shops: SaaSShop[] = await graphQLService.getShopList(owner?.id);
      if (shops) {
        const converted = convert(shops);
        setList(converted);
        // 基本的にはないはずだが、リストの中にすでに選択している店舗がなかったら選択を変更する
        if (!selected?.id || !shops.some((shop) => shop.id === selected?.id)) {
          setSelected(converted[0]);
        }
        if (id) {
          const found = converted.find((shop) => shop.id === id);
          if (found) {
            setSelected(found);
            await AsyncStorage.setItem('shop', found.id);
          }
        }
      }
    },
    [selected?.id, owner],
  );

  const setSelectedShop = useCallback(async (shop: SaaSShop) => {
    const convertedShops = convert([shop]);
    if (convertedShops) {
      setSelected(convertedShops[0]);
      await AsyncStorage.setItem('shop', convertedShops[0].id);
    }
  }, []);

  return {
    shopList,
    selected,
    updateShops,
    setSelectedShop,
  };
}

export const ShopContainer = createContainer(useShop);

// ShopId だけ使うケースが多いので、専用のhookを提供
// ショップID取得（ショップIDの動的変更には対応しない
export function useShopId(): {shopId: string} {
  const [shopId] = useState<string>(
    ShopContainer.useContainer().selected?.id ?? '',
  );
  return {shopId};
}
// オーナーID取得（オーナーIDの動的変更には対応しない）
export function useOwnerId(): {ownerId: string} {
  const [ownerId] = useState<string>(
    ShopContainer.useContainer().selected?.ownerId ?? '',
  );
  return {ownerId};
}

export function convertShop(shop: Shop): SaaSShop {
  console.log(shop);
  return {
    __typename: 'SaaSShop',
    id: shop.id,
    ownerId: shop.ownerId,
    name: shop.name,
    text: shop.text,
    address: {
      __typename: 'FullAddress',
      zip: shop.zip,
      pref: shop.pref,
      city: shop.city,
      detail: shop.detail,
      building: shop.building,
    },
    phone: shop.phone,
    biztimes: shop.bizHours,
    holidays: shop.holidays,
    url: shop.url,
    legalUrl: shop.legalUrl,
    category: shop.category,
    imageUrls: shop.imageUrls,
    icon: shop.icon,
    location: {
      __typename: 'Location',
      lat: shop.location?.lat ?? 0,
      lon: shop.location?.lng ?? 0,
    },
    groupId: shop.groupId,
  };
}
