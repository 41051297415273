import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Image, TouchableOpacity} from 'react-native';
import light from '../../assets/trima_ad_direct_logo.svg';
import dark from '../../assets/trima_ad_direct_logo_dark.svg';
import {StepNavigationContainer} from '../../container';

/**
 * トリマサービスロゴ
 */
export const Logo = React.memo<{
  height: number;
  isDark?: boolean;
}>(({height, isDark}) => {
  const navigation = useNavigation();
  const {step} = StepNavigationContainer.useContainer();
  const uri = isDark ? dark : light;
  const width = Math.ceil((height * 540) / 125);
  if (step === 'signOut') {
    return (
      <TouchableOpacity onPress={() => navigation.navigate('LandingPage')}>
        <Image source={{uri, width, height}} />
      </TouchableOpacity>
    );
  } else if (step === 'main') {
    return (
      <TouchableOpacity onPress={() => navigation.navigate('DashBoard')}>
        <Image source={{uri, width, height}} />
      </TouchableOpacity>
    );
  } else if (step === 'suspended') {
    return (
      <TouchableOpacity onPress={() => navigation.navigate('SuspendMain')}>
        <Image source={{uri, width, height}} />
      </TouchableOpacity>
    );
  } else {
    return <Image source={{uri, width, height}} />;
  }
});
