import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {View} from 'react-native';
import * as yup from 'yup';
import defaultIcon from '../../assets/icon_shop.png';
// import InfiniteScroll from 'react-infinite-scroller';
// import InfiniteScroll from 'react-infinite-scroll-component';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Clear,
  Delete,
  Edit as EditIcon,
  FileCopyOutlined,
  Folder,
  Forward,
  MoreVert,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Button as MuiButton,
  List as MuiList,
  Menu as MuiMenu,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import {ActivityIndicator, HelperText} from 'react-native-paper';
import {FullAddress, SaaSShop} from '../../API';
import {BRAKE_POINT} from '../../components';
import {
  Container,
  MaxWidth,
  Menu,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
  WithHint,
  yupUtil,
} from '../../components/Elements';
import {ShopDeleteDialog} from '../../components/Shop/ShopDeleteDialog';
import {
  OwnerContainer,
  ShopContainer,
  ShopGroupsContainer,
  ShopsContainer,
} from '../../container';
import {ShopGroupFormData} from '../../container/ShopGroupsContainer';
import {Helpers} from '../../helper';
import {MainNavigationProp} from '../../navigation/MainScreen';
import {ExcludeVisitorsContainer} from '../../screens/Shops/ExcludeVisitors/ExcludeVisitorsContainer';
import {Colors, CommonStyles} from '../../theme';
import {Copy} from './Copy';
import {Create} from './Create';
import {Detail} from './Detail';
import {Edit} from './Edit';

type NProp = MainNavigationProp<'ShopsMain'> | MainNavigationProp<'GroupsMain'>;

const schemaShopGroupFormData: yup.SchemaOf<ShopGroupFormData> = yup
  .object()
  .shape({
    name: yupUtil.stringRequired(128),
  });

// type RProp = MainRouteProp<'ShopsMain'>;

const AddButton: React.FC = () => {
  const navigation = useNavigation();
  const gotoCreate = () =>
    navigation.navigate('ShopsCreate', {from: 'ShopsMain'});
  return (
    <Box pt={1}>
      <TrimaButton
        variant="outlined"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={gotoCreate}
      >
        店舗を新規作成
      </TrimaButton>
    </Box>
  );
};

function dumpAddress(address: FullAddress): string {
  return `${address.pref || ''}${address.city || ''}${address.detail || ''}${
    address.building || ''
  }`;
}

function convert(shop: SaaSShop) {
  return {
    ...shop,
    address: shop.address ? dumpAddress(shop.address) : '-',
    phone: shop.phone && shop.phone !== '' ? shop.phone : '-',
  };
}

const CreateGroupSubmit: React.FC<{handleClose(): void}> = ({handleClose}) => {
  const {owner} = OwnerContainer.useContainer();
  const {create, isCreateLoading} = ShopGroupsContainer.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<ShopGroupFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const disabled = isCreateLoading || !watch('name') || !!errors.name;

  const buttonLabel = isCreateLoading ? '作成中' : '作成';
  if (!owner) {
    return null;
  }
  return (
    <View>
      <TrimaLoadingButton
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(async (data) => {
          await create({...data, ownerId: owner.id});
          handleClose();
        }, onSubmitError)}
        loading={isCreateLoading}
        loadingPosition="start"
      >
        {buttonLabel}
      </TrimaLoadingButton>
      <HelperText type="error">{errors?.name?.message}</HelperText>
    </View>
  );
};

const CreateGroupDialog: React.FC<{
  open: boolean;
  onClose(): void;
}> = ({open, onClose}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialogContent-root': {
          minWidth: 300,
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">店舗グループ作成</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <VForm.Provider<ShopGroupFormData> schema={schemaShopGroupFormData}>
            <VForm.Text<ShopGroupFormData>
              name="name"
              label="店舗グループ名"
              maxLength={30}
              required
            />
            <VMargin />
            <CreateGroupSubmit handleClose={handleClose} />
          </VForm.Provider>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const RemoveGroupDialog: React.FC<{
  open: boolean;
  onClose(): void;
  shopGroup: any;
}> = ({open, onClose, shopGroup}) => {
  const {unsetGroup2Shop} = ShopsContainer.useContainer();
  const {remove, isCreateLoading} = ShopGroupsContainer.useContainer();
  const handleClose = () => {
    onClose();
  };
  const removeGroup = async () => {
    await remove({
      id: shopGroup.id,
    });
    unsetGroup2Shop({
      id: shopGroup.id,
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">店舗グループの削除</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{`${shopGroup.name}を削除しますか？`}</Typography>
          <Box py={1}>
            <TrimaLoadingButton
              color="error"
              disabled={isCreateLoading}
              variant="contained"
              onClick={removeGroup}
              loading={isCreateLoading}
              loadingPosition="start"
            >
              削除
            </TrimaLoadingButton>
          </Box>

          <Box py={1}>
            <TrimaButton variant="outlined" onClick={handleClose}>
              キャンセル
            </TrimaButton>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const ShopGroupListItem: React.FC<{
  shopGroup: any;
}> = React.memo(({shopGroup}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filteredShop, setFilteredShop] = React.useState<Array<any>>([]);
  const {shopList} = ShopsContainer.useContainer();
  const [openRemove, setOpenRemove] = React.useState(false);
  const handleCloseRemoveDialog = () => {
    setOpenRemove(false);
  };
  const navigation = useNavigation<NProp>();

  React.useEffect(() => {
    console.log('shopList', shopList);
    const array = shopList.filter((shop) => shop.groupId === shopGroup.id);
    setFilteredShop(array);
  }, [shopGroup, shopList]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ListItem
        sx={{
          '&.MuiListItem-root': {
            padding: 0,
            bgcolor: 'background.paper',
            border: `1px solid ${Colors.main}`,
          },
        }}
        secondaryAction={
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert color="primary" />
            </IconButton>
            <MuiMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() =>
                  navigation.navigate('GroupsEdit', {id: shopGroup.id})
                }
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  編集する
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenRemove(true);
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  削除する
                </Typography>
              </MenuItem>
            </MuiMenu>
          </>
        }
      >
        <ListItemButton
          onClick={() => navigation.navigate('GroupsMain', {id: shopGroup.id})}
          sx={{
            '&.MuiButtonBase-root': {
              py: 2,
            },
          }}
        >
          <ListItemText
            id={shopGroup.id}
            primary={
              <>
                <Box display="flex" flexDirection="row">
                  <Folder style={{color: Colors.main}} />
                  <Box pr={0.5} />
                  <Typography
                    style={{fontWeight: 500, color: Colors.main}}
                    variant="body1"
                    component="span"
                  >
                    {shopGroup.name}
                  </Typography>
                </Box>
                <Box pt={1}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block"
                  >
                    {filteredShop.length === 0
                      ? '店舗はありません'
                      : `全${Helpers.sepComma(filteredShop.length)}店舗`}
                  </Typography>
                </Box>
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      <RemoveGroupDialog
        open={openRemove}
        onClose={handleCloseRemoveDialog}
        shopGroup={shopGroup}
      />
    </>
  );
});

const Move2GroupDialog: React.FC<{
  open: boolean;
  onClose(): void;
  shop: any;
}> = ({open, onClose, shop}) => {
  const [groupId, setGroupId] = React.useState('');
  const {shopGroups} = ShopGroupsContainer.useContainer();
  const {setGroup2Shop} = ShopsContainer.useContainer();
  const {selected, setSelectedShop} = ShopContainer.useContainer();
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event: SelectChangeEvent) => {
    setGroupId(event.target.value as string);
  };
  const handleClose = () => {
    onClose();
  };

  const move = async () => {
    setLoading(true);
    const updatedShop = await setGroup2Shop({
      id: shop.id,
      groupId,
    });
    if (selected && updatedShop.id === selected.id) {
      setSelectedShop(updatedShop);
    }
    setLoading(false);
    handleClose();
  };
  return (
    <Dialog
      sx={{
        '& .MuiDialogContent-root': {
          minWidth: 300,
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">店舗グループの移動</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">{`${shop.name}をどこに移動しますか？`}</Typography>
          <Box p={1} />
          <FormControl fullWidth variant="outlined">
            <Select
              value={groupId}
              onChange={handleChange}
              displayEmpty
              renderValue={(value: unknown): React.ReactNode => {
                console.log(value);
                return (
                  value !== '' ? (
                    shopGroups.find((shopGroups) => shopGroups.id === value)
                      .name
                  ) : (
                    <em style={{color: '#BBBBBB'}}>選択してください</em>
                  )
                ) as string;
              }}
            >
              {shopGroups.map((shopGroup) => {
                return (
                  <MenuItem value={shopGroup.id}>{shopGroup.name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </DialogContentText>
        <Box p={1} />
        <TrimaLoadingButton
          disabled={groupId === '' || loading}
          variant="contained"
          onClick={move}
          loading={loading}
          loadingPosition="start"
        >
          OK
        </TrimaLoadingButton>
        <Box p={1} />
      </DialogContent>
    </Dialog>
  );
};

const ShopListItem: React.FC<{
  shop: any;
}> = React.memo(({shop}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigation = useNavigation<NProp>();
  const [showDialog, setDialog] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const gotoEdit = () => navigation.navigate('ShopsEdit', {id: shop.id});
  const gotoCopy = () => navigation.navigate('ShopsCopy', {id: shop.id});
  const gotoDash = () => navigation.navigate('DashBoard');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onDismiss = (removed: boolean) => {
    setDialog(false);
    if (removed) {
      // 削除後に一覧をリフレッシュしたいので、仮だがとりあえずダッシュボードに飛んでしまう
      gotoDash();
    }
  };

  return (
    <>
      <ListItem
        sx={{
          '&.MuiListItem-root': {
            padding: 0,
            bgcolor: 'background.paper',
            border: `1px solid ${Colors.main}`,
          },
        }}
        secondaryAction={
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert color="primary" />
            </IconButton>
            <MuiMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={gotoEdit}>
                <ListItemIcon>
                  <EditIcon fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  編集する
                </Typography>
              </MenuItem>
              <MenuItem onClick={gotoCopy}>
                <ListItemIcon>
                  <FileCopyOutlined fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  複製する
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpen(true);
                }}
              >
                <ListItemIcon>
                  <Forward fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  グループに移動する
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  setDialog(true);
                }}
              >
                <ListItemIcon>
                  <Delete fontSize="small" color="primary" />
                </ListItemIcon>
                <Typography variant="inherit" color="primary">
                  削除する
                </Typography>
              </MenuItem>
            </MuiMenu>
          </>
        }
      >
        <ListItemButton
          onClick={() => navigation.navigate('ShopsDetail', {id: shop.id})}
          sx={{
            '&.MuiButtonBase-root': {
              py: 2,
            },
          }}
        >
          <ListItemText
            id={shop.id}
            primary={
              <>
                <Box display="flex" flexDirection="row">
                  <Avatar src={shop.icon ? shop.icon : defaultIcon} />
                  <Box pr={0.5} />
                  <Typography
                    style={{fontWeight: 500, color: Colors.main}}
                    variant="subtitle1"
                    component="span"
                  >
                    {shop.name}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block"
                  >
                    {shop.address}
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography
                    style={{color: '#909090'}}
                    variant="caption"
                    component="span"
                    display="block"
                  >
                    {shop.phone}
                  </Typography>
                </Box>
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      <ShopDeleteDialog
        visible={showDialog}
        id={shop.id}
        name={shop.name}
        onDismiss={onDismiss}
        onError={gotoDash}
      />
      <Move2GroupDialog open={open} onClose={handleCloseDialog} shop={shop} />
    </>
  );
});

const Main: React.FC = () => {
  // Todo コンテナ化した方がいいかは要検討
  const [open, setOpen] = React.useState(false);
  const {shopGroups} = ShopGroupsContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const [word, setWord] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const {
    control,
    formState: {errors},
    handleSubmit,
    reset,
  } = useForm<{
    word: string;
  }>({
    resolver: yupResolver(
      yup
        .object({
          word: yup.string().required('必須の項目です'),
        })
        .required(),
    ),
  });
  const search = (data: {word: string}) => {
    console.log(data);
    setWord(data.word);
  };
  const onSubmit: SubmitHandler<{
    word: string;
  }> = (data) => {
    search(data);
  };
  const onSubmitError = () => true;
  const processing = !!errors.word;

  return (
    <ExcludeVisitorsContainer.Provider>
      <Container>
        <MaxWidth
          maxWidth={BRAKE_POINT.desktop}
          style={CommonStyles.padding.all}
        >
          <WithHint id="shopsMain">
            <Menu>店舗管理</Menu>
          </WithHint>
          <View style={CommonStyles.margin.top} />
          <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
            <View style={CommonStyles.flex.full}>
              {!shopList || !shopGroups ? (
                <ActivityIndicator size={60} />
              ) : (
                <>
                  <form
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={10}>
                        <FormControl
                          required
                          error={'word' in errors}
                          component="fieldset"
                          fullWidth
                        >
                          <Controller
                            name="word"
                            control={control}
                            rules={{
                              required: '入力してください',
                            }}
                            render={({field}) => (
                              <TextField
                                sx={{
                                  '& .MuiInputBase-root': {
                                    backgroundColor: '#fcfcfb',
                                    borderRadius: 1,
                                    height: 48,
                                  },
                                }}
                                {...field}
                                margin="dense"
                                required
                                variant="outlined"
                                error={'word' in errors}
                                helperText={errors.word?.message}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        reset({
                                          word: '',
                                        });
                                        setWord('');
                                      }}
                                    >
                                      <Clear />
                                    </IconButton>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <MuiButton
                          type="submit"
                          color="primary"
                          variant="contained"
                          size="large"
                          disabled={processing}
                          onClick={handleSubmit(search, onSubmitError)}
                        >
                          検索
                        </MuiButton>
                      </Grid>
                    </Grid>
                  </form>
                  <Box p={1} />
                  <WithHint id="shopsGroups">
                    <Typography variant="body1" component="h2">
                      店舗グループ
                    </Typography>
                  </WithHint>
                  <VMargin />
                  <TrimaButton
                    variant="outlined"
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={() => setOpen(true)}
                  >
                    店舗グループを新規作成
                  </TrimaButton>
                  <VMargin />
                  {shopGroups.length === 0 ? (
                    <Typography variant="body1">
                      店舗グループはありません
                    </Typography>
                  ) : (
                    <MuiList>
                      {shopGroups
                        .filter((shopGroup: any) =>
                          shopGroup.name.includes(word),
                        )
                        .map((shopGroup: any) => {
                          return (
                            <React.Fragment key={shopGroup.id}>
                              <ShopGroupListItem shopGroup={shopGroup} />
                              <Box p={0.5} />
                            </React.Fragment>
                          );
                        })}
                    </MuiList>
                  )}
                  <VMargin />
                  <WithHint id="shops">
                    <Typography variant="body1" component="h2">
                      店舗
                    </Typography>
                  </WithHint>
                  <AddButton />
                  <VMargin />
                  <MuiList>
                    {shopList
                      .filter((shop) => !shop.groupId)
                      .filter((shop) => shop.name.includes(word))
                      .map(convert)
                      .map((shop: any) => (
                        <React.Fragment key={shop.id}>
                          <ShopListItem shop={shop} />
                          <Box p={0.5} />
                        </React.Fragment>
                      ))}
                  </MuiList>
                  <CreateGroupDialog open={open} onClose={handleClose} />
                </>
              )}
            </View>
          </MaxWidth>
        </MaxWidth>
      </Container>
    </ExcludeVisitorsContainer.Provider>
  );
};

export const Shops = {
  Main,
  Create,
  Detail,
  Edit,
  Copy,
};
