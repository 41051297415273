import {ReportProblem} from '@mui/icons-material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {SubmitHandler, useFormContext} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {SaaSCoupon, SaaSDeliveryTarget, SaaSNews} from '../../API';
import {
  DayjsRepository,
  startOfDay,
} from '../../_proto/services/DayjsRepository';
import {
  DeliveryCouponDto,
  DeliveryNewsDto,
  SaaSDeliveryRepository,
} from '../../_proto/services/SaaSDeliveryRepository';
import {OwnerContainer, ShopContainer} from '../../container';
import {Helpers} from '../../helper';
import {
  DeliveryStatus,
  axiosHelper,
  graphQLService,
  isUsedItem,
} from '../../service';
import {Colors, CommonStyles} from '../../theme';
import {Preview as CouponPreview} from '../CouponPreview';
import {
  FormBlock,
  HelperText,
  MaxWidth,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
  WithHint,
} from '../Elements';
import {Preview as NewsPreView} from '../NewsPreview';
import {TargetsPreview} from '../TargetsPreview';
import {
  ConfirmDialog,
  ConfirmGroupDialog,
  ConfirmedDialog,
  ConfirmedGroupDialog,
  StopDialog,
  UpdateDialog,
} from './Dialogs';
import {
  AdsInfo,
  CreativeForm,
  CreativeGroupForm,
  CreativeRestored,
  CreativeUpdateForm,
  TargetCreative,
  budgetRadio,
  schemaCreativeForm,
  schemaCreativeGroupForm,
  schemaCreativeUpdateForm,
  titleInput,
} from './schema';

const styleBaseBack = {backgroundColor: Colors.base};

// 値が渡されたら、フォームに設定する空コンポーネント
const Initializer: React.FC<{ads?: AdsInfo; targetId?: string}> = ({
  ads,
  targetId,
}) => {
  const {setValue} = useFormContext<CreativeForm>();
  if (ads) {
    setValue('ads', ads);
    setValue('adsUpdated', true);
  }
  if (targetId) {
    setValue('targetId', targetId);
    setValue('targetUpdated', true);
  }
  return null;
};

const Provider: React.FC<{
  ads?: AdsInfo;
  targetId?: string;
  defaultValues?: CreativeRestored;
  children: React.ReactNode;
}> = ({ads, targetId, defaultValues, children}) => {
  // 規定値は描画のたびに生成しても意味ないのでRefで用意
  const defaultValueRef = React.useRef<CreativeForm>(
    defaultValues
      ? defaultValues
      : {
          title: '',
          startAt: DayjsRepository.japanDate().toISOString(),
          endAt: startOfDay(DayjsRepository.japanDate())
            .add(4, 'week')
            .toISOString(),
          useBudget: !!budgetRadio.defaultValue,
        },
  );
  return (
    <VForm.Provider<CreativeForm>
      schema={schemaCreativeForm}
      defaultValues={defaultValueRef.current}>
      <Initializer ads={ads} targetId={targetId} />
      {children}
    </VForm.Provider>
  );
};

const ProviderGroup: React.FC<{
  ads?: AdsInfo;
  targetId?: string;
  defaultValues?: CreativeRestored;
  groups?: any;
  children: React.ReactNode;
}> = ({ads, targetId, defaultValues, children, groups}) => {
  console.log(groups);
  // 規定値は描画のたびに生成しても意味ないのでRefで用意
  const defaultValueRef = React.useRef<CreativeGroupForm>(
    defaultValues
      ? defaultValues
      : {
          title: '',
          startAt: DayjsRepository.japanDate().toISOString(),
          endAt: startOfDay(DayjsRepository.japanDate())
            .add(4, 'week')
            .toISOString(),
          groups: [],
        },
  );
  return (
    <VForm.Provider<CreativeGroupForm>
      schema={schemaCreativeGroupForm}
      defaultValues={{...defaultValueRef.current, groups}}>
      <Initializer ads={ads} targetId={targetId} />
      {children}
    </VForm.Provider>
  );
};

const ProviderForUpdate: React.FC<{
  defaultValues?: CreativeRestored;
  children: React.ReactNode;
}> = ({defaultValues, children}) => {
  // 規定値は描画のたびに生成しても意味ないのでRefで用意
  const defaultValueRef = React.useRef<CreativeUpdateForm>(
    defaultValues
      ? defaultValues
      : {
          title: '',
          startAt: DayjsRepository.japanDate().toISOString(),
          endAt: startOfDay(DayjsRepository.japanDate())
            .add(4, 'week')
            .toISOString(),
          useBudget: !!budgetRadio.defaultValue,
        },
  );
  return (
    <VForm.Provider<CreativeUpdateForm>
      schema={schemaCreativeUpdateForm}
      defaultValues={defaultValueRef.current}>
      {children}
    </VForm.Provider>
  );
};

type SelectType =
  | 'couponNew'
  | 'couponList'
  | 'couponEdit'
  | 'newsNew'
  | 'newsList'
  | 'newsEdit';
const AdsSelector: React.FC<{
  onSelect(type: SelectType): void;
}> = ({onSelect}) => {
  return (
    <View>
      <Text>配信内容が設定されていません。</Text>
      <VMargin />
      <TrimaButton variant="outlined" onClick={() => onSelect('couponNew')}>
        広告の作成
      </TrimaButton>
      <VMargin />
      <TrimaButton variant="outlined" onClick={() => onSelect('couponList')}>
        作成済みの広告から選ぶ
      </TrimaButton>
    </View>
  );
};

const Coupon: React.FC<{
  coupon: SaaSCoupon;
  onSelect(type: SelectType, id?: string): void;
}> = ({coupon, onSelect}) => {
  const {setValue} = useFormContext<CreativeForm>();
  const onCancel = () => setValue('ads', undefined);
  const onEdit = () => onSelect('couponEdit', coupon.id);
  const used = isUsedItem(coupon);
  return (
    <View style={CommonStyles.align.self.center}>
      <CouponPreview coupon={coupon} showShop={true} />
      <VMargin />
      <TrimaButton variant="outlined" disabled={used} onClick={onEdit}>
        広告の編集
      </TrimaButton>
      <VMargin />
      <TrimaButton variant="outlined" onClick={onCancel}>
        別の広告を選択
      </TrimaButton>
    </View>
  );
};

const News: React.FC<{
  news: SaaSNews;
  onSelect(type: SelectType, id?: string): void;
}> = ({news, onSelect}) => {
  const {setValue} = useFormContext<CreativeForm>();
  const onCancel = () => setValue('ads', undefined);
  const onEdit = () => onSelect('newsEdit', news.id);
  const used = isUsedItem(news);
  return (
    <View style={CommonStyles.align.self.center}>
      <NewsPreView news={news} showShop={true} />
      <VMargin />
      <TrimaButton variant="outlined" disabled={used} onClick={onEdit}>
        広告の編集
      </TrimaButton>
      <VMargin />
      <TrimaButton variant="outlined" onClick={onCancel}>
        別の広告を選択
      </TrimaButton>
    </View>
  );
};

type SelectedAds =
  | {type: 'coupon'; ads: SaaSCoupon}
  | {type: 'news'; ads: SaaSNews}
  | undefined;
const AdsContent: React.FC<{
  onSelect(type: SelectType, id?: string): void;
}> = ({onSelect}) => {
  const [selected, setAds] = React.useState<SelectedAds>();
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: {errors},
  } = useFormContext<CreativeForm>();

  // 選択されているクーポン/ニュースの情報を取得し、プレビュー表示する
  const watchAds = watch('ads');
  const isUpdate = watch('adsUpdated');
  React.useEffect(() => {
    if (
      isUpdate ||
      watchAds?.id !== selected?.ads.id ||
      watchAds?.type !== selected?.type
    ) {
      setValue('adsUpdated', false);
      if (!watchAds || !watchAds.id || !watchAds.type) {
        // ads がクリアされた
        setAds(undefined);
      } else if (watchAds.type === 'coupon') {
        setAds(undefined);
        // クーポン
        graphQLService
          .getCoupon(watchAds.id)
          .then((ads) => {
            clearErrors('ads.id');
            setAds({type: 'coupon', ads});
          })
          .catch(() => {
            setValue('ads', undefined);
            setAds(undefined);
            setError('ads.id', {
              type: 'manual',
              message: '広告情報の取得に失敗しました',
            });
          });
      } else if (watchAds.type === 'news') {
        // ニュース
        setAds(undefined);
        graphQLService
          .getNews(watchAds.id)
          .then((ads) => {
            clearErrors('ads.id');
            setAds({type: 'news', ads});
          })
          .catch(() => {
            setValue('ads', undefined);
            setAds(undefined);
            setError('ads.id', {
              type: 'manual',
              message: '広告情報の取得に失敗しました',
            });
          });
      }
    }
  }, [selected, clearErrors, setError, watchAds, setValue, isUpdate]);

  return (
    <View>
      <Text>配信内容</Text>
      {/*エラー表示*/}
      {errors.ads?.id && (
        <View>
          <VMargin />
          <Text style={styles.error}>{errors.ads.id.message}</Text>
        </View>
      )}
      <VMargin />
      {/*プレビューまたは対象の選択*/}
      {selected?.type === 'coupon' && (
        <Coupon coupon={selected.ads} onSelect={onSelect} />
      )}
      {selected?.type === 'news' && (
        <News news={selected.ads} onSelect={onSelect} />
      )}
      {!selected && <AdsSelector onSelect={onSelect} />}
    </View>
  );
};

const TargetView: React.FC<{
  target: SaaSDeliveryTarget;
  onSelect(type: TargetSelectType, id?: string): void;
}> = ({target, onSelect}) => {
  const {setValue} = useFormContext<CreativeForm>();
  const onCancel = () => setValue('targetId', undefined);
  const onEdit = () => onSelect('edit', target.id);
  const used = isUsedItem(target);
  return (
    <View>
      <TargetsPreview.Summary target={target} />
      <VMargin />
      <TrimaButton variant="outlined" disabled={used} onClick={onEdit}>
        配信先の編集
      </TrimaButton>
      <VMargin />
      <TrimaButton variant="outlined" onClick={onCancel}>
        別の作成済み配信先を探す
      </TrimaButton>
    </View>
  );
};

type TargetSelectType = 'new' | 'list' | 'edit';
const TargetSelector: React.FC<{
  onSelect(type: TargetSelectType): void;
}> = ({onSelect}) => {
  return (
    <View>
      <Text>配信先が設定されていません。</Text>
      <VMargin />
      <TrimaButton variant="outlined" onClick={() => onSelect('new')}>
        配信先作成
      </TrimaButton>
      <VMargin />
      <TrimaButton variant="outlined" onClick={() => onSelect('list')}>
        作成済みの配信先から選ぶ
      </TrimaButton>
    </View>
  );
};

const TargetContent: React.FC<{
  onSelect(type: TargetSelectType, id?: string): void;
}> = ({onSelect}) => {
  const [selected, setTarget] = React.useState<
    SaaSDeliveryTarget | undefined
  >();
  const {
    watch,
    setValue,
    setError,
    clearErrors,
    formState: {errors},
  } = useFormContext<CreativeForm>();
  const watchId = watch('targetId');
  const isUpdate = watch('targetUpdated');
  React.useEffect(() => {
    if (!watchId) {
      // フォームがクリアされている
      if (selected) {
        setTarget(undefined);
        setValue('targetUsers', undefined);
      }
    } else {
      if (isUpdate || watchId !== selected?.id) {
        setValue('targetUpdated', false);
        setValue('targetUsers', undefined);
        setTarget(undefined);
        // watchIdに基づき新しく取得
        graphQLService
          .getTarget(watchId)
          .then((target) => {
            clearErrors('targetId');
            setTarget(target);
            setValue('targetUsers', target.users ?? undefined);
          })
          .catch(() => {
            setValue('targetId', undefined);
            setTarget(undefined);
            setError('targetId', {
              type: 'manual',
              message: '配信先情報の取得に失敗しました',
            });
          });
      }
    }

    if (selected) {
      if (!watchId) {
        setTarget(undefined);
      } else if (selected.id !== watchId) {
        //
      }
    }
  }, [clearErrors, isUpdate, selected, setError, setValue, watchId]);
  return (
    <View>
      <Text>配信先</Text>
      {/*エラー表示*/}
      {errors.targetId && (
        <View>
          <VMargin />
          <Text style={styles.error}>{errors.targetId.message}</Text>
        </View>
      )}
      <VMargin />
      {selected ? (
        <TargetView target={selected} onSelect={onSelect} />
      ) : (
        <TargetSelector onSelect={onSelect} />
      )}
    </View>
  );
};

// 編集の時は既に利用した金額の表示
const UsedMoney: React.FC<{usedMoney?: number}> = ({usedMoney}) => {
  return (
    <View style={styles.information}>
      <View style={CommonStyles.flex.row}>
        <View>
          <Text style={styles.centering}>この配信の使用金額</Text>
        </View>
        <View>
          <Text style={styles.centering}>：</Text>
        </View>
        <View style={CommonStyles.flex.full}>
          <Text style={styles.centering}>
            {usedMoney === undefined ? '---' : Helpers.sepComma(usedMoney)} 円
          </Text>
        </View>
      </View>
    </View>
  );
};

const BudgetDigestion: React.FC<{estimatedArray: any; targetUsers: any}> = ({
  estimatedArray,
  targetUsers,
}) => {
  return (
    <>
      <Text>予算消化予想</Text>
      <VMargin />
      <TableContainer style={{width: '100%'}}>
        <Table
          sx={{minWidth: 600, border: `1px solid ${Colors.base}`}}
          aria-label="simple table">
          <TableHead>
            <TableRow style={styleBaseBack}>
              <TableCell
                align="center"
                style={{width: 100}}
                sx={{p: 1, border: `1px solid ${Colors.base}`}}>
                配信開始日
                <br />
                からの日数
              </TableCell>
              {estimatedArray.map((estimated: any) => {
                return (
                  <TableCell
                    align="center"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}>
                    {estimated.days}日後
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell
                align="center"
                sx={{p: 1, border: `1px solid ${Colors.base}`}}>
                推定
                <br />
                閲覧者数（人）
              </TableCell>
              {estimatedArray.map((estimated: any) => {
                return (
                  <TableCell
                    align="right"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}>
                    {targetUsers
                      ? Helpers.sepComma(estimated.estimatedViewers)
                      : '---'}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow hover>
              <TableCell
                align="center"
                sx={{p: 1, border: `1px solid ${Colors.base}`}}>
                推定広告料
                <br />
                （円）
              </TableCell>
              {estimatedArray.map((estimated: any) => {
                return (
                  <TableCell
                    align="right"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}>
                    {targetUsers
                      ? Helpers.sepComma(estimated.estimatedAmount)
                      : '---'}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <VMargin />
    </>
  );
};

const InformationElements: React.FC<{
  useBudget: boolean;
  budget: number;
  targetUsers: number;
  estimatedUsers: number;
}> = ({useBudget, budget, targetUsers, estimatedUsers}) => {
  const estimatedArray = [
    {
      days: 1,
      estimatedViewers: Math.round(targetUsers * 0.1),
      estimatedAmount: Math.round(targetUsers * 0.1) * 3,
    },
    {
      days: 7,
      estimatedViewers: Math.round(targetUsers * 0.2),
      estimatedAmount: Math.round(targetUsers * 0.2) * 3,
    },
    {
      days: 14,
      estimatedViewers: Math.round(targetUsers * 0.24),
      estimatedAmount: Math.round(targetUsers * 0.24) * 3,
    },
    {
      days: 21,
      estimatedViewers: Math.round(targetUsers * 0.27),
      estimatedAmount: Math.round(targetUsers * 0.27) * 3,
    },
    {
      days: 28,
      estimatedViewers: Math.round(targetUsers * 0.3),
      estimatedAmount: Math.round(targetUsers * 0.3) * 3,
    },
  ];

  let notice;
  for (let i = 0; i < estimatedArray.length; i++) {
    if (useBudget && budget && estimatedArray[i].estimatedAmount > budget) {
      if (estimatedArray[i].days === 1) {
        notice = (
          <Text style={{color: Colors.delete}}>
            <ReportProblem style={{fontSize: '16px'}} />
            {`配信開始から${estimatedArray[i].days}日以内で予算上限に到達します。予算上限金額を調整するか、配信先の設定を変更して、配信対象者数を絞り込むことをおすすめします。`}
          </Text>
        );
      } else if (estimatedArray[i].days === 7) {
        notice = (
          <Text>
            <ReportProblem style={{fontSize: '16px'}} />
            {`配信開始から${estimatedArray[i].days}日以内で予算上限に到達します。`}
          </Text>
        );
      } else if (estimatedArray[i].days === 14) {
        notice = (
          <Text>
            <ReportProblem style={{fontSize: '16px'}} />
            {`配信開始から${estimatedArray[i - 1].days}〜${
              estimatedArray[i].days
            }日以内で予算上限に到達します。`}
          </Text>
        );
      }
      break;
    } else {
      notice = null;
    }
  }
  return (
    <>
      <View style={styles.information}>
        <View style={CommonStyles.flex.row}>
          <View>
            <Text style={styles.right}>{'配信対象者数　'}</Text>
            <VMargin />
            <Text style={styles.right}>{'最大閲覧者数　'}</Text>
            <VMargin />
            <Text style={styles.centering}>{'最大広告料　'}</Text>
          </View>
          <View>
            <Text style={styles.centering}>：</Text>
            <VMargin />
            <Text style={styles.centering}>：</Text>
            <VMargin />
            <Text style={styles.centering}>：</Text>
          </View>
          <View style={CommonStyles.flex.full}>
            <Text style={styles.centering}>
              {targetUsers ? Helpers.sepComma(targetUsers) : '---'} 人
            </Text>
            <VMargin />
            <Text style={styles.centering}>
              {estimatedUsers ? Helpers.sepComma(estimatedUsers) : '---'} 人
            </Text>
            <VMargin />
            <Text style={styles.centering}>
              {estimatedUsers ? Helpers.sepComma(estimatedUsers * 3) : '---'} 円
            </Text>
          </View>
        </View>
      </View>
      <VMargin />
      <BudgetDigestion
        estimatedArray={estimatedArray}
        targetUsers={targetUsers}
      />
      {notice}
      <VMargin />
    </>
  );
};

// 新規の時は使用金額予想
const Information: React.FC<{targetUsers?: number}> = ({targetUsers}) => {
  const {watch} = useFormContext<CreativeUpdateForm>();
  // ターゲット人数と予算から算出
  const useBudget = String(watch('useBudget')) === 'true';
  const budget = watch('budget') ?? 0;
  if (!targetUsers) {
    targetUsers = watch('targetUsers') ?? 0;
  }
  const estimatedUsers = useBudget
    ? Math.min(Math.floor(budget / 3), targetUsers)
    : targetUsers;

  return (
    <InformationElements
      useBudget={useBudget}
      budget={budget}
      targetUsers={targetUsers}
      estimatedUsers={estimatedUsers}
    />
  );
};

const InformationGroup: React.FC<{targetArray: Array<any>}> = ({
  targetArray,
}) => {
  const {watch} = useFormContext<CreativeGroupForm>();
  const group = watch('groups');
  // ターゲット人数と予算から算出
  const useBudget = !group.some((group) => group.noUpperLimit);
  let targetUsers = 0;
  let budget = 0;
  let estimatedUsers = 0;
  group.forEach((group, index) => {
    if (group.enable) {
      targetUsers = targetUsers + targetArray[index][0].users;
      if (group.noUpperLimit) {
        estimatedUsers = estimatedUsers + targetArray[index][0].users;
      }
      if (!group.noUpperLimit && group.budget) {
        budget = budget + Number(group.budget);
        estimatedUsers =
          estimatedUsers +
          Math.min(
            Math.floor(Number(group.budget) / 3),
            targetArray[index][0].users,
          );
      }
    }
  });

  return (
    <InformationElements
      useBudget={useBudget}
      budget={budget}
      targetUsers={targetUsers}
      estimatedUsers={estimatedUsers}
    />
  );
};

const TermForm: React.FC<{
  usedMoney?: number;
  editMode?: boolean;
  targetUsers?: number;
  isGroup?: boolean;
  targetArray?: Array<any>;
}> = ({usedMoney, editMode, targetUsers, isGroup, targetArray}) => {
  const {
    trigger,
    setValue,
    watch,
    formState: {errors},
  } = useFormContext<CreativeUpdateForm>();

  const isBudget = String(watch('useBudget')) === 'true';
  const onChangeHandler = (evt: any) => {
    setValue(
      evt.currentTarget.name,
      dayjs(evt.currentTarget.value).toISOString(),
    );
    // endAtを先の日付にすることでstartAtのエラーが解消するかもなので、両方チェック
    trigger(['startAt', 'endAt']);
  };
  return (
    <FormBlock>
      <WithHint id="creativeTerm">
        <Text>配信期間（広告有効期間）</Text>
      </WithHint>
      <VMargin />
      <Text>開始日時</Text>
      <VMargin />
      <input
        style={{
          height: '40px',
          width: '100%',
          fontSize: '16px',
        }}
        type="datetime-local"
        name="startAt"
        value={dayjs(watch('startAt')).format('YYYY-MM-DDTHH:mm')}
        onChange={onChangeHandler}
      />
      <HelperText type="error">{errors?.startAt?.message}</HelperText>
      <VMargin />
      <Text>終了日時</Text>
      <VMargin />
      <input
        style={{
          height: '40px',
          width: '100%',
          fontSize: '16px',
        }}
        type="datetime-local"
        name="endAt"
        value={dayjs(watch('endAt')).format('YYYY-MM-DDTHH:mm')}
        onChange={onChangeHandler}
      />
      <HelperText type="error">{errors?.endAt?.message}</HelperText>
      <VMargin />
      {!isGroup && (
        <>
          <VForm.Radio<CreativeForm> {...budgetRadio} />
          {isBudget && (
            <VForm.Text<CreativeForm> name="budget" postfix="　円まで" />
          )}
          <VMargin />
        </>
      )}

      {editMode && (
        <>
          <UsedMoney usedMoney={usedMoney} />
          <VMargin />
        </>
      )}

      {isGroup && targetArray ? (
        <InformationGroup targetArray={targetArray} />
      ) : (
        <Information targetUsers={targetUsers} />
      )}
      <VMargin />
      <VForm.Text<CreativeForm> {...titleInput} />
    </FormBlock>
  );
};

const Submit: React.FC<{onDone(result?: any): void}> = ({onDone}) => {
  const [busy, setBusy] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<
    | {
        title: string;
        startAt: string;
        endAt: string;
        budget: number;
      }
    | undefined
  >();
  const [showConfirmed, setShowConfirmed] = React.useState<
    | {
        title: string;
        startAt: string;
        endAt: string;
        budget: number;
      }
    | undefined
  >();
  const {
    handleSubmit,
    setError,
    setValue,
    formState: {errors},
  } = useFormContext<CreativeForm>();
  const {selected} = ShopContainer.useContainer();
  const {owner} = OwnerContainer.useContainer();
  const onSubmit: SubmitHandler<CreativeForm> = async (data) => {
    console.log('[Creative] Submit', data);
    try {
      if (selected && owner) {
        setShow(undefined);
        if (data.ads?.type === 'coupon') {
          setBusy(true);
          const dto: DeliveryCouponDto = {
            budget: data.useBudget ? (data.budget as number) : 0,
            couponId: data.ads.id as string,
            targetId: data.targetId as string,
            start: data.startAt,
            end: data.endAt,
            title: data.title,
            ownerId: owner.id,
          };
          const result = await SaaSDeliveryRepository.deliveryCoupon(
            dto,
            selected?.id,
          );
          console.log('[Creatives] Coupon registered', result);
          // フォームをクリア（配信期間は残しておいた方が便利そうなのでとりあえずそのまま）
          setValue('ads', undefined);
          setValue('targetId', undefined);
          setValue('title', '');
          setShowConfirmed(result.data);
          return;
        } else if (data.ads?.type === 'news') {
          setBusy(true);
          const dto: DeliveryNewsDto = {
            budget: data.useBudget ? (data.budget as number) : 0,
            newsId: data.ads.id as string,
            targetId: data.targetId as string,
            start: data.startAt,
            end: data.endAt,
            title: data.title,
            ownerId: owner.id,
          };
          const result = await SaaSDeliveryRepository.deliveryNews(
            dto,
            selected?.id,
          );
          console.log('[Creatives] news registered', result);
          // フォームをクリア（配信期間は残しておいた方が便利そうなのでとりあえずそのまま）
          setValue('ads', undefined);
          setValue('targetId', undefined);
          setValue('title', '');
          setShowConfirmed(result.data);
          return;
        }
      }
      // ここに来たらなんかエラー...
      setError('title', {
        type: 'manual',
        message: '配信の保存に失敗しました。',
      });
    } catch (err: any) {
      setError('title', {
        type: 'manual',
        message: `配信の保存に失敗しました。${err}`,
      });
    } finally {
      setBusy(false);
    }
  };

  const errMsg =
    Object.values(errors).length === 0
      ? ''
      : errors.title?.type === 'manual'
      ? errors.title.message
      : 'フォームの内容に誤りがあります';

  const onClose = () => {
    setShowConfirmed(undefined);
    onDone(show);
  };

  const showConfirmDialog: SubmitHandler<CreativeForm> = (data) => {
    console.log(data);
    if (selected && owner) {
      setShow({
        title: data.title,
        startAt: data.startAt,
        endAt: data.endAt,
        budget: data.useBudget ? (data.budget as number) : 0,
      });
    }
  };

  const onDismiss = () => {
    setShow(undefined);
  };
  return (
    <MaxWidth maxWidth={586} style={CommonStyles.margin.all}>
      <HelperText type="error" style={styles.centering}>
        {errMsg}
      </HelperText>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(showConfirmDialog)}
        disabled={busy}
        loading={busy}
        loadingPosition="start">
        配信する
      </TrimaLoadingButton>
      <ConfirmDialog
        creative={show}
        onPress={handleSubmit(onSubmit)}
        onDismiss={onDismiss}
      />
      <ConfirmedDialog creative={showConfirmed} onConfirm={onClose} />
    </MaxWidth>
  );
};

const GroupSubmit: React.FC<{
  onDone(result?: any): void;
  targetArray: Array<any>;
  shopList: Array<any>;
}> = ({onDone, targetArray, shopList}) => {
  const [busy, setBusy] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<
    | {
        title: string;
        startAt: string;
        endAt: string;
        groups: Array<any>;
        targetArray: Array<any>;
        shopList: Array<any>;
      }
    | undefined
  >();
  const [showConfirmed, setShowConfirmed] = React.useState<
    | {
        title: string;
        startAt: string;
        endAt: string;
        groups: Array<any>;
        targetArray: Array<any>;
        shopList: Array<any>;
      }
    | undefined
  >();
  const {
    handleSubmit,
    setError,
    setValue,
    formState: {errors},
  } = useFormContext<CreativeGroupForm>();
  const {selected} = ShopContainer.useContainer();
  const {owner} = OwnerContainer.useContainer();
  const onSubmit: SubmitHandler<CreativeGroupForm> = async (data) => {
    console.log('[Creative] Submit', data);
    try {
      if (selected && owner) {
        setShow(undefined);
        let index = 0;
        let result;
        if (data.groups) {
          if (data.ads?.type === 'coupon') {
            setBusy(true);
            for (const group of data.groups) {
              if (group.enable) {
                const dto: DeliveryCouponDto = {
                  budget: group.noUpperLimit ? 0 : (group.budget as number),
                  couponId: data.ads.id as string,
                  targetId: targetArray[index][0].id,
                  start: data.startAt,
                  end: data.endAt,
                  title: data.title,
                  ownerId: owner.id,
                };
                const response = await SaaSDeliveryRepository.deliveryCoupon(
                  dto,
                  shopList[index].id,
                );
                console.log('[Creatives] Coupon registered', response);
                result = response;
              }
              index++;
            }

            // フォームをクリア（配信期間は残しておいた方が便利そうなのでとりあえずそのまま）
            setValue('ads', undefined);
            setValue('title', '');
            setShowConfirmed(show);
            return;
          } else if (data.ads?.type === 'news') {
            setBusy(true);
            for (const group of data.groups) {
              if (group.enable) {
                const dto: DeliveryNewsDto = {
                  budget: group.noUpperLimit ? 0 : (group.budget as number),
                  newsId: data.ads.id as string,
                  targetId: targetArray[index][0].id,
                  start: data.startAt,
                  end: data.endAt,
                  title: data.title,
                  ownerId: owner.id,
                };
                const response = await SaaSDeliveryRepository.deliveryNews(
                  dto,
                  shopList[index].id,
                );
                console.log('[Creatives] news registered', result);
                result = response;
              }
              index++;
            }

            // フォームをクリア（配信期間は残しておいた方が便利そうなのでとりあえずそのまま）
            setValue('ads', undefined);
            setValue('title', '');
            setShowConfirmed(show);
            return;
          }
        }
      }
      // ここに来たらなんかエラー...
      setError('title', {
        type: 'manual',
        message: '配信の保存に失敗しました。',
      });
    } catch (err: any) {
      setError('title', {
        type: 'manual',
        message: `配信の保存に失敗しました。${err}`,
      });
    } finally {
      setBusy(false);
    }
  };

  const errMsg =
    Object.values(errors).length === 0
      ? ''
      : errors.title?.type === 'manual'
      ? errors.title.message
      : 'フォームの内容に誤りがあります';

  const onClose = () => {
    setShowConfirmed(undefined);
    onDone(show);
  };

  const showConfirmDialog: SubmitHandler<CreativeGroupForm> = (data) => {
    console.log(data);
    if (selected && owner) {
      setShow({
        title: data.title,
        startAt: data.startAt,
        endAt: data.endAt,
        groups: data.groups,
        targetArray,
        shopList,
      });
    }
  };

  const onDismiss = () => {
    setShow(undefined);
  };
  return (
    <MaxWidth maxWidth={586} style={CommonStyles.margin.all}>
      <HelperText type="error" style={styles.centering}>
        {errMsg}
      </HelperText>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(showConfirmDialog)}
        disabled={busy}
        loading={busy}
        loadingPosition="start">
        配信する
      </TrimaLoadingButton>
      <ConfirmGroupDialog
        creative={show}
        onPress={handleSubmit(onSubmit)}
        onDismiss={onDismiss}
      />
      <ConfirmedGroupDialog creative={showConfirmed} onConfirm={onClose} />
    </MaxWidth>
  );
};

const Update: React.FC<{
  type?: 'coupon' | 'news';
  status: DeliveryStatus;
  onDone(result?: any): void;
}> = ({type, onDone, status}) => {
  const [submitted, setSubmitted] = React.useState<
    CreativeUpdateForm | undefined
  >();
  const [busy, setBusy] = React.useState<boolean>(false);
  const {
    handleSubmit,
    setError,
    formState: {errors},
  } = useFormContext<CreativeUpdateForm>();

  const onSubmit: SubmitHandler<CreativeUpdateForm> = (data) => {
    // 実際の処理はダイアログ確認後なので、ここでは値の保存だけ
    setSubmitted(data);
  };

  const onConfirm = () => {
    const data = submitted;
    setSubmitted(undefined);
    if (!data || !data.id) {
      // ここには来ないはず
      return;
    }
    // budget の 型はバリデーションでチェック済み想定
    const budget = data.useBudget ? (data.budget as number) : 0;

    if (type === 'coupon') {
      setBusy(true);
      SaaSDeliveryRepository.updateCoupon({
        id: data.id,
        start: data.startAt,
        end: data.endAt,
        budget,
        title: data.title,
      })
        .then((result) => onDone(result.data))
        .catch((err) => {
          setError('title', {
            type: 'manual',
            message: axiosHelper.commonErrorHandler(err),
          });
        })
        .finally(() => setBusy(false));
    } else {
      setBusy(true);
      SaaSDeliveryRepository.updateNews({
        id: data.id,
        start: data.startAt,
        end: data.endAt,
        budget,
        title: data.title,
      })
        .then((result) => onDone(result.data))
        .catch((err) => {
          setError('title', {
            type: 'manual',
            message: axiosHelper.commonErrorHandler(err),
          });
        })
        .finally(() => setBusy(false));
    }
  };
  const onCancel = () => setSubmitted(undefined);

  // 配信状態によって出すメッセージを変える
  const forActive = status === 'active' || status === 'before';

  const errMsg =
    Object.values(errors).length === 0
      ? ''
      : errors.title?.type === 'manual'
      ? errors.title.message
      : 'フォームの内容に誤りがあります';

  return (
    <MaxWidth maxWidth={586} style={CommonStyles.margin.all}>
      <HelperText type="error" style={styles.centering}>
        {errMsg}
      </HelperText>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(onSubmit)}
        disabled={busy}
        loading={busy}
        loadingPosition="start">
        変更を保存
      </TrimaLoadingButton>
      <UpdateDialog
        forActive={forActive}
        onConfirm={onConfirm}
        onDismiss={onCancel}
        visible={!!submitted}
      />
    </MaxWidth>
  );
};

const Stop: React.FC<{
  target: NonNullable<TargetCreative>;
  onDone(result?: any): void;
}> = ({target: {type, creative}, onDone}) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [busy, setBusy] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | undefined>();

  const showDialog = () => setVisible(true);
  const hideDialog = () => {
    setError(undefined);
    setVisible(false);
  };

  const onConfirm = () => {
    const id = creative.id;
    const startAt = creative.startAt;
    const start = dayjs(startAt);
    const budget = creative.budget;
    const title = creative.title;

    if (
      !startAt ||
      budget === undefined ||
      budget === null ||
      !title ||
      !start.isValid()
    ) {
      setError('配信パラメーターエラー取得エラーが発生しました。');
      return;
    }

    // 開始が未来の場合は、停止と開始を同時刻にして停止。それ以外は今の時間に設定
    const now = dayjs();
    const end = start.isAfter(now) ? start : now;

    if (type === 'coupon') {
      setBusy(true);
      SaaSDeliveryRepository.updateCoupon({
        id,
        start: start.toISOString(),
        end: end.toISOString(),
        budget,
        title,
      })
        .then((result) => {
          hideDialog();
          onDone(result.data);
        })
        .catch((err) => {
          setError(axiosHelper.commonErrorHandler(err));
        })
        .finally(() => setBusy(false));
    } else {
      setBusy(true);
      SaaSDeliveryRepository.updateNews({
        id,
        start: start.toISOString(),
        end: end.toISOString(),
        budget,
        title,
      })
        .then((result) => {
          hideDialog();
          onDone(result.data);
        })
        .catch((err) => {
          setError(axiosHelper.commonErrorHandler(err));
        })
        .finally(() => setBusy(false));
    }
  };

  return (
    <View>
      <TrimaLoadingButton
        variant="contained"
        color="error"
        onClick={showDialog}
        disabled={busy}
        loading={busy}
        loadingPosition="start">
        配信終了
      </TrimaLoadingButton>
      <StopDialog
        visible={visible}
        error={error}
        onConfirm={onConfirm}
        onDismiss={hideDialog}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  information: {
    backgroundColor: Colors.base,
    ...CommonStyles.padding.all,
  },
  centering: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  error: {
    color: Colors.accent,
  },
});

export const CreativeSettings = {
  Provider,
  ProviderGroup,
  ProviderForUpdate,
  AdsContent,
  TargetContent,
  TermForm,
  Submit,
  GroupSubmit,
  Update,
  Stop,
};
