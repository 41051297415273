import merge from 'deepmerge';
import {STRING_LIMIT, TextInputProps, yup, yupUtil} from '../Elements';

// フォームの型
export type FormData = {
  id?: string; // データのID（ユーザーへのフォームなしで裏で保持）
  name: string;
  text?: string;
  title: string;
  // 画像添付判定用
  imageEnabled: boolean;
  cvLinkUrl?: string | null;
  cvLinkText?: string;
  cvPhone?: string;
};

// 文字数上限
const limit: Pick<
  Required<{[P in keyof FormData]: number}>,
  'name' | 'text' | 'title' | 'cvLinkText'
> = {
  name: 30,
  text: 2000,
  title: STRING_LIMIT.title,
  cvLinkText: 18,
};

// フォームのルール
export const schemaFormData: yup.SchemaOf<FormData> = yup.object().shape({
  id: yupUtil.string(),
  name: yupUtil.stringRequired(limit.name),
  text: yupUtil.string(limit.text),
  title: yupUtil.stringRequired(limit.title),
  imageEnabled: yupUtil.imageRequired(),
  cvLinkUrl: yupUtil.url(STRING_LIMIT.url),
  cvLinkText: yup.mixed().when('cvLinkUrl', {
    is: (value: string) => !!value,
    then: yupUtil.stringRequired(limit.cvLinkText),
  }),
  cvPhone: yupUtil.phoneOptional(),
});

// TextInput表示関連データ
const maxLengthList = Object.fromEntries(
  // limit から map で maxLength の指定を生成
  Object.entries(limit).map(([_, value]) => [_, {maxLength: value}]),
);
const formTexts: {
  [P in keyof Omit<FormData, 'imageEnabled'>]: TextInputProps<FormData>;
} = {
  name: {
    name: 'name',
    label: '広告のタイトル',
    placeholder: '★初売りセールのお知らせ',
    required: true,
  },
  text: {
    name: 'text',
    label: '詳しい説明',
    notRequired: true,
    placeholder:
      '1月15日まで初売りセール開催中！数量限定福袋も販売しています！店舗公式サイトはこちら https://support.shop.trip-mile.com/hc/ja ※外部サイトのリンクを貼ることができます。',
    multiline: true,
    style: {height: 320},
  },
  title: {
    name: 'title',
    label: 'タイトル（管理用）',
    placeholder: '1/15まで初売りセール告知',
    required: true,
  },
  cvLinkUrl: {
    name: 'cvLinkUrl',
    label: 'ランディングページURL',
    placeholder: 'https://geot.jp/',
    notRequired: true,
  },
  cvLinkText: {
    name: 'cvLinkText',
    label: 'ボタンラベル',
    placeholder: '詳しくはこちら',
    notRequired: true,
  },
  cvPhone: {
    name: 'cvPhone',
    label: '問い合わせ電話番号',
    placeholder: '09012341234',
    notRequired: true,
  },
};

// deepmerge で maxLength をくっつけて利用
export const formInfos = merge(formTexts, maxLengthList);
