import React from 'react';
import {SubmitHandler, useFormContext} from 'react-hook-form';
import {Linking, StyleSheet, View} from 'react-native';
import {ActivityIndicator, Checkbox, HelperText} from 'react-native-paper';
import {
  FormBlock,
  MaxWidth,
  Menu,
  PLACEHOLDER,
  Text,
  TrimaButton,
  VForm,
  VMargin,
  yup,
  yupUtil,
} from '../../components/Elements';
import {Container} from '../../components/Elements/Container/component';
// import {useResponsive} from '../../components/Responsive';
import {SaaSAgencyRepository} from '../../_proto/services/SaaSAgencyRepository';
import {SaaSRepository} from '../../_proto/services/SaaSRepository';
import {axiosHelper} from '../../service';
import {Colors, CommonStyles, Fonts} from '../../theme';

type Form = {
  email: string;
};

const schema: yup.SchemaOf<Form> = yup.object().shape({
  email: yupUtil.email(),
});

const Submit: React.FC<{
  onSubmit: SubmitHandler<Form>;
  error?: string;
  checked: boolean;
}> = ({onSubmit, error, checked}) => {
  const HELPER: {[P in string]: {type: 'info' | 'error'; children: string}} = {
    policy: {
      type: 'info',
      children: 'チェックボックスにチェックしてください',
    },
    error: {
      type: 'error',
      children: '入力内容に誤りがあります',
    },
    non: {
      type: 'info',
      children: '',
    },
  };

  const [disabled, setDisabled] = React.useState(false);
  const {
    handleSubmit,
    formState: {errors},
  } = useFormContext<Form>();

  React.useEffect(() => {
    if (!errors.email) {
      setDisabled(false);
    }
  }, [errors.email]);
  const onError = () => {
    setDisabled(true);
  };
  const onPress = handleSubmit(onSubmit, onError);
  let helper;
  if (!checked) {
    helper = HELPER.policy;
  } else if (error) {
    console.log(error);
    helper = HELPER.error;
    helper.children = error;
  } else {
    helper = HELPER.non;
  }
  return (
    <View>
      <VMargin />
      <View>
        <TrimaButton
          variant="contained"
          disabled={!checked || disabled}
          onClick={onPress}>
          仮登録
        </TrimaButton>

        <View style={styles.helperText}>
          <HelperText {...helper} />
        </View>
      </View>
    </View>
  );
};

const Busy: React.FC = () => {
  return (
    <View>
      <ActivityIndicator size="large" />
    </View>
  );
};

const Notice: React.FC = () => {
  const NOTICE_LABEL = 'トリマ広告ダイレクトをご利用いただけない店舗';
  const NOTICE_TEXT = `・風俗店、キャバクラ、ホストクラブ等、風俗営業法における接待飲食営業店、性風俗関連特殊営業を行っている店舗
・法令もしくは公序良俗に反する商品・サービスを取り扱う店舗 
・反社会的勢力、およびこれに関連する店舗 
・パチンコ等ギャンブル性の高い業態の店舗`;

  return (
    <View style={styles.noticeContainer}>
      <Text style={Fonts.caption}>{NOTICE_LABEL}</Text>
      <View style={CommonStyles.margin.top} />
      <Text style={Fonts.caption}>{NOTICE_TEXT}</Text>
    </View>
  );
};

const PrivacyPolicy: React.FC = () => {
  const PP_LINK = 'https://www.ads.trip-mile.com/terms/';
  return (
    <View style={CommonStyles.margin.bottom}>
      <Text>
        本サービスをご利用いただくために、
        <Text
          style={CommonStyles.linkText}
          onPress={() => Linking.openURL(PP_LINK)}>
          利用規約・プライバシーポリシー
        </Text>
        への同意が必要です。
      </Text>
    </View>
  );
};

export const Forms: React.FC<{agency?: string}> = ({agency}) => {
  const [busy, setBusy] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [error, setError] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [checked, setChecked] = React.useState(false);
  const onSubmit: SubmitHandler<Form> = async (data) => {
    setBusy(true);
    setEmail(data.email);
    try {
      agency
        ? await SaaSAgencyRepository.postPreRegist(data.email, agency)
        : await SaaSRepository.postPreRegist(data.email);
      setDone(true);
    } catch (err: any) {
      setError(axiosHelper.commonErrorHandler(err));
    } finally {
      setBusy(false);
    }
  };
  const render = () => {
    if (busy) {
      return <Busy />;
    } else if (done) {
      return (
        <View>
          <Text>仮登録メールを送信いたしました。</Text>
          <VMargin />
          <Text>{email}</Text>
          <VMargin />
          <Text>
            メールに記載されているURLから本登録の手続きを進めてください。
          </Text>
          <VMargin />
          <Text style={styles.accentText}>
            URLの有効期限は発行から24時間です。
          </Text>
          <VMargin />
          <VMargin />
          <Text>メールが届かない場合</Text>
          <VMargin />
          <Text>
            メールが届かない場合は
            <Text
              style={CommonStyles.linkText}
              onPress={() =>
                Linking.openURL(
                  'https://support.shop.trip-mile.com/hc/ja/articles/4407344339085',
                )
              }>
              こちら
            </Text>
            の設定をご確認ください。
          </Text>
          <Text>
            ・メールアドレスが誤っている可能性がございます。以下のボタンから再入力をお願いいたします
          </Text>
          <VMargin />
          <TrimaButton
            variant="outlined"
            onClick={() => {
              setDone(false);
            }}>
            メールアドレスの入力に戻る
          </TrimaButton>
        </View>
      );
    } else {
      return (
        <View>
          <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
            <Checkbox
              status={checked ? 'checked' : 'unchecked'}
              onPress={() => setChecked(!checked)}
            />
            <Text>利用規約・プライバシーポリシーに同意</Text>
          </View>
          <Submit onSubmit={onSubmit} error={error} checked={checked} />
        </View>
      );
    }
  };
  // const {upperTablet} = useResponsive();
  // const style = {
  //   width: upperTablet ? BRAKE_POINT.tablet : '100%',
  //   alignSelf: 'center' as const,
  // };

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>トリマビジネスアカウント仮登録</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          <FormBlock>
            <MaxWidth maxWidth={586}>
              <VForm.Provider<Form> schema={schema}>
                {!done && (
                  <View>
                    <VForm.Text<Form>
                      name="email"
                      label="メールアドレス"
                      {...PLACEHOLDER.mail}
                      editable={!done && !busy}
                      keyboardType="email-address"
                      required={true}
                    />

                    <VMargin />
                    <Text>
                      迷惑メールフィルターが設定されている場合、@shop.trip-mile.comからの受信設定をお願いいたします。
                    </Text>
                    <VMargin />
                    <Notice />
                    <VMargin />
                    <PrivacyPolicy />
                  </View>
                )}
                <View>{render()}</View>
              </VForm.Provider>
            </MaxWidth>
          </FormBlock>
        </View>
      </MaxWidth>
    </Container>
  );
};

const styles = StyleSheet.create({
  helperText: {
    height: 30,
  },
  noticeContainer: {
    borderWidth: 1,
    borderColor: Colors.gray,
    backgroundColor: Colors.white,
    ...CommonStyles.padding.all,
  },
  accentText: {
    color: Colors.accent,
  },
});
