import React, {ReactElement} from 'react';
import {StyleSheet, View} from 'react-native';
import {Logo} from './Logo';
import {Colors, CommonStyles} from '../../theme';

export const Header: React.FC<{rightItem?: ReactElement}> = ({rightItem}) => {
  return (
    <View style={styles.header}>
      <Logo height={48} isDark />
      <View style={styles.rightItem}>{rightItem}</View>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    height: 60,
    backgroundColor: Colors.header,
    ...CommonStyles.flex.center,
  },
  rightItem: {
    position: 'absolute',
    top: 0,
    right: 16,
    bottom: 0,
    justifyContent: 'center',
  },
});
