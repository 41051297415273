import _ from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {Gender, MovingMethod, Residence} from '../../../API';
import {
  AttrForm,
  INTEREST_TABLE,
  InterestKey,
  JOB_TABLE,
  JobKey,
  MOVING_TABLE,
  MovingKey,
  NonValidateAttrForm,
  NonValidateRange,
  RESIDENCE_TABLE,
  ResidenceKey,
} from '../schema';
import {AttrFilter, BaseUser, EncodedUserProp, FieldTable} from '../types';

// 属性は数値にエンコードされてる
const EncodedGender = {MALE: 0, FEMALE: 1};
const encodedBool = (b: boolean | string) => (toBool(b) ? 1 : 0);
const EncodedResidence: {[P in Residence]: number} = {
  MY_HOUSE: 0,
  MY_APARTMENT: 1,
  RENT_HOUSE: 2,
  RENT_APARTMENT: 3,
  COMPANY_HOUSING: 4,
  OTHER: 5,
};
const EncodedMovingMethod: {[P in MovingMethod]: number} = {
  TRAIN: 0,
  BUS: 1,
  WALK: 2,
  CAR: 3,
  BICYCLE: 4,
  MOTORCYCLE: 5,
  NONE: 6,
  OTHER: 7,
};

// watchでの取得だとフォームの都合上 string で返ってくることがあるケースは変換をかます
function toBool(str: boolean | string) {
  return str.toString().toLowerCase() === 'true';
}
function toNum(num?: number | string): number {
  return Number(num ?? 0);
}
function toRange(range?: NonValidateRange): {lower: number; upper: number} {
  return {
    lower: toNum(range?.lower),
    upper: toNum(range?.upper),
  };
}

// 1つの判定フィルター
type InnerFilter = (prop: EncodedUserProp) => boolean;

/**
 * ユーザー属性フィルターを返す。
 * 各ユーザー単位での処理が少なくて済むような判定関数化まで実施する。
 * @param form フォームの値
 * @return 判定不要（全期間）の場合は undefined が返る
 */
export function makeFilters(
  form: Partial<NonValidateAttrForm> | undefined,
): AttrFilter | undefined {
  const filters: InnerFilter[] = [];
  const use = form?.use;
  if (!form || !use) {
    return undefined; // ユーザー属性指定なし
  }

  // 早くfalseになった方が計算量で有利なので、falseになりそうな条件を先にpush
  if (use.children && form.children) {
    const num = toNum(form.children.together);
    if (num === 0) {
      filters.push((prop) => prop[FieldTable.childrenTogether] === num);
      // 0人なら年齢の判定は不要
    }
    if (num > 0) {
      filters.push((prop) => prop[FieldTable.childrenTogether] >= num);
      const range = toRange(form.children);
      const lower = range.lower || 0;
      const upper = range.upper || 99999;
      filters.push((prop) => {
        let ages = prop[FieldTable.childAge];
        if (!ages) {
          ages = [prop[FieldTable.childYoungest] as number];
        }

        return (ages as number[]).some((age) => age >= lower && age < upper);
      });
    }
  }

  if (use.familyTogether) {
    const num = toNum(form.familyTogether);
    if (num === 1) {
      filters.push((prop) => prop[FieldTable.familyTogether] === num);
    }
    if (num > 1) {
      filters.push((prop) => prop[FieldTable.familyTogether] >= num);
    }
  }

  if (use.gender) {
    if (form.gender === Gender.MALE || form.gender === Gender.FEMALE) {
      const match = EncodedGender[form.gender];
      filters.push((prop) => prop[FieldTable.gender] === match);
    }
  }

  if (use.married && form.married !== undefined) {
    const match = encodedBool(form.married);
    filters.push((prop) => prop[FieldTable.married] === match);
  }

  if (use.residence && form.residence) {
    const residence = form.residence;
    const enableSet = new Set<number>();
    Object.keys(residence).forEach((key) => {
      const rKey = key as ResidenceKey;
      if (residence[rKey]) {
        enableSet.add(EncodedResidence[RESIDENCE_TABLE[rKey]]);
      }
    });
    if (enableSet.size > 0) {
      filters.push((prop) =>
        enableSet.has(prop[FieldTable.residence] as number),
      );
    }
  }

  if (use.movingMethod && form.movingMethod) {
    const movingMethod = form.movingMethod;
    const enableSet = new Set<number>();
    Object.keys(movingMethod).forEach((key) => {
      const mKey = key as MovingKey;
      if (movingMethod[mKey]) {
        enableSet.add(EncodedMovingMethod[MOVING_TABLE[mKey]]);
      }
    });
    if (enableSet.size > 0) {
      filters.push((prop) =>
        enableSet.has(prop[FieldTable.movingMethod] as number),
      );
    }
  }

  if (use.ageRange) {
    const range = toRange(form.ageRange);
    if (range.lower) {
      filters.push((prop) => prop[FieldTable.age] >= range.lower);
    }
    if (range.upper) {
      filters.push((prop) => prop[FieldTable.age] < range.upper);
    }
  }
  if (use.income) {
    const range = toRange(form.income);
    if (range.lower) {
      filters.push((prop) => prop[FieldTable.income] >= range.lower);
    }
    if (range.upper) {
      filters.push((prop) => prop[FieldTable.income] < range.upper);
    }
  }
  if (use.householdIncome) {
    const range = toRange(form.householdIncome);
    if (range.lower) {
      filters.push((prop) => prop[FieldTable.householdIncome] >= range.lower);
    }
    if (range.upper) {
      filters.push((prop) => prop[FieldTable.householdIncome] < range.upper);
    }
  }

  if (use.job && form.job) {
    const job = form.job;
    const enableSet = new Set<number>();
    Object.keys(job).forEach((key) => {
      const jKey = key as JobKey;
      if (job[jKey]) {
        enableSet.add(JOB_TABLE[jKey]);
      }
    });
    if (enableSet.size > 0) {
      filters.push((prop) => enableSet.has(prop[FieldTable.job] as number));
    }
  }

  if (use.interest && form.interest) {
    const interest = form.interest;
    const enableSet = new Set<number>();
    Object.keys(interest).forEach((key) => {
      const iKey = key as InterestKey;
      if (interest[iKey]) {
        enableSet.add(INTEREST_TABLE[iKey]);
      }
    });
    if (enableSet.size > 0) {
      filters.push((prop) => {
        if (!prop[FieldTable.interest]) {
          return false;
        }
        return (prop[FieldTable.interest] as number[]).some((value) =>
          enableSet.has(value),
        );
      });
    }
  }

  if (filters.length === 0) {
    return undefined; // 属性なし
  }

  return (user) => {
    const attr = user.user;
    if (attr === undefined) {
      return false;
    }
    return filters.every((filter) => filter(attr));
  };
}

/**
 * ユーザー属性フィルタ利用フック。
 * フィルターが更新される時のみ返却オブジェエクトが更新される。
 */
export function useAttrFilter(): {attrFilter?: AttrFilter} {
  const {watch, getValues} = useFormContext<AttrForm>();
  const [attrFilter, setFilter] = useState<{
    filter?: AttrFilter;
  }>({
    filter: makeFilters(getValues()),
  });
  const ref = useRef<Partial<AttrForm>>({});

  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      console.log(value, name, type);
      const obj = _.cloneDeepWith(value, (val, key) => {
        if (key === 'title') {
          val = '';
          return val;
        }
      });
      // タイトルの変更は無視
      if (!_.isEqual(ref.current, obj)) {
        const filter = makeFilters(value);
        setFilter({filter});
        ref.current = obj;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return {attrFilter: attrFilter.filter};
}

export function checkAttr(user: BaseUser, filter?: AttrFilter): void {
  if (filter && !filter(user)) {
    user.attrNG = true;
  } else {
    delete user.attrNG;
  }
}
