import {ArrowDropDown, Person} from '@mui/icons-material';
import {
  Box,
  Fade,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {AuthContainer} from '../container';
import {Helpers} from '../helper';
import {Colors} from '../theme';
import {MainNavigationProp} from './MainScreen';

const SIZE = 42;
const StyledButton = styled(IconButton)({
  minHeight: SIZE,
  maxHeight: SIZE,
  minWidth: SIZE * 2,
  maxWidth: SIZE * 2,
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))(() => ({
  '& .MuiPaper-root': {
    border: `1px solid ${Colors.mainShadow}`,
  },
}));

const SUPPORT_SITE_URL = 'https://support.shop.trip-mile.com/hc/ja';

// ログアウト、サポートサイトへのリンクはどのケースでも問題ないのでデフォルトで入れておき、アイテムの追加はchildrenで実施
export const AccountMenu: React.FC = () => {
  const navigation = useNavigation<MainNavigationProp<'DashBoard'>>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {signOut} = AuthContainer.useContainer();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openSupport = () => {
    Helpers.openURL(SUPPORT_SITE_URL);
  };
  const gotoAccount = () => {
    navigation.navigate('AccountMain');
  };
  return (
    <>
      <StyledButton color="primary" onClick={handleClick}>
        <Person sx={{fontSize: SIZE, color: Colors.white}} />
        <Box ml={-1}>
          <ArrowDropDown sx={{fontSize: SIZE / 2, color: Colors.white}} />
        </Box>
      </StyledButton>
      <StyledMenu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        TransitionComponent={Fade}
        open={Boolean(anchorEl)}
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem onClick={gotoAccount}>
          <ListItemText primary="アカウント設定" />
        </MenuItem>
        <MenuItem onClick={signOut}>
          <ListItemText primary="ログアウト" />
        </MenuItem>
        <MenuItem onClick={openSupport}>
          <ListItemText primary="サポートサイト" />
        </MenuItem>
      </StyledMenu>
    </>
  );
};
