import React from 'react';
import {StyleSheet, View} from 'react-native';
import {TouchableRipple} from 'react-native-paper';
import {MeshRepository} from '../../../../_proto/services/MeshRepositoy';
import {CommonStyles} from '../../../../theme';
import {VMargin} from '../../../Elements';
import {MeshList} from '../../types';
import MeshSelectMap from '../MeshSelectMap';

// サーバーから取得したメッシュ配列から地図に渡すリストを生成する
export function getMeshList(
  meshs?: (string | null)[] | null | undefined,
): MeshList {
  const meshes: MeshList = {};
  meshs &&
    meshs
      .filter((mesh) => mesh)
      .map((mesh) => (meshes[mesh as string] = undefined));
  return meshes;
}

export type MapPreviewProp = {
  meshList: MeshList;
  onPress?(): void;
  children: React.ReactNode;
};

// 居住地と滞在履歴のプレビュー共通部分
const MapPreviewCommon: React.FC<MapPreviewProp> = React.memo(
  ({meshList, onPress, children}) => {
    const bound =
      meshList && Object.keys(meshList).length > 0
        ? MeshRepository.boundOfMeshs(Object.keys(meshList))
        : undefined;

    return (
      <View style={styles.container}>
        <TouchableRipple onPress={onPress} style={CommonStyles.flex.full}>
          <MeshSelectMap meshes={meshList} freeze bound={bound} />
        </TouchableRipple>
        <VMargin />
        {children}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    ...CommonStyles.margin.all,
    height: 370,
  },
});

export default MapPreviewCommon;
