import {useNavigation} from '@react-navigation/native';

export const useCustomNav = () => {
  const navigation = useNavigation();

  const goBack = (to: string) => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate(to);
    }
  };

  return {goBack};
};
