import {yupResolver} from '@hookform/resolvers/yup';
import React from 'react';
import {
  Controller,
  DefaultValues,
  FieldValues,
  FormProvider,
  Path,
  PathValue,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {StyleSheet, TextInput, TouchableOpacity, View} from 'react-native';
import {
  Caption,
  Checkbox,
  HelperText,
  TextInput as PaperTextInput,
  RadioButton,
  useTheme,
} from 'react-native-paper';
import {RenderProps} from 'react-native-paper/lib/typescript/components/TextInput/types';
import * as yup from 'yup';
import {Colors, CommonStyles, Fonts} from '../../theme';
import {HintButton, HintPattern} from './Hint';
import {VSelect} from './Select';
import {NotRequired, RequiredNotice, Text, WithHint} from './Text';

// ==== Provider ====
function VFormProvider<T extends FieldValues>({
  children,
  schema, // validation は yup で指定する前提で schema を受け取る
  defaultValues,
}: React.PropsWithChildren<{
  schema: yup.SchemaOf<T>;
  defaultValues?: DefaultValues<T>;
}>): React.ReactElement {
  const method = useForm<T>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <FormProvider {...method}>{children}</FormProvider>;
}

// ==== TextInput ====
export type TextInputProps<T extends FieldValues> = Omit<
  React.ComponentProps<typeof PaperTextInput>,
  'theme'
> & {
  name: Path<T>;
  required?: boolean; // 「必須」フォーム表示をするかどうか
  notRequired?: boolean; // 入力任意かどうか。（yupのschemaから持って来れるとベストだけど方法不明）
  maxLength?: number; // yupから条件持ってくるのは難しそうなので、個別指定
  postfix?: string; // テキストボックスの右に単位などをつける場合に指定
  withHint?: HintPattern; // ラベルにヘルプボタンをつける場合
};

function VTextInput<T extends FieldValues>(
  props: TextInputProps<T>,
): React.ReactElement {
  const {
    name,
    required,
    notRequired,
    maxLength,
    postfix,
    label,
    style,
    withHint,
    ...rest
  } = props;
  const {control, trigger} = useFormContext<T>();
  const {fonts} = useTheme();
  const weight = {fontWeight: fonts.regular.fontWeight};
  const render = props.multiline ? {render: textInputMulti} : {};
  return (
    <View style={CommonStyles.fullWidth}>
      <View style={styles.label}>
        <Text>{label}</Text>
        {notRequired && <NotRequired />}
        {required && <RequiredNotice />}
        {withHint && <HintButton id={withHint} />}
      </View>
      <Controller
        name={name}
        control={control}
        render={({
          field: {onChange, onBlur, value, ref},
          fieldState: {error},
        }) => {
          const length =
            typeof value === 'string' ? (value as string).length : 0;
          return (
            <View style={[CommonStyles.flex.row, CommonStyles.flex.crossEnd]}>
              <View style={CommonStyles.flex.full}>
                <PaperTextInput
                  mode="outlined"
                  error={!!error}
                  style={[styles.input, style]}
                  ref={ref}
                  value={value as string}
                  onChangeText={onChange}
                  onBlur={() => {
                    onBlur();
                    trigger(name);
                  }}
                  placeholderTextColor="#a7a7a7"
                  {...render}
                  {...rest}
                />
                <View style={styles.errorCaption}>
                  <HelperText type="error" visible={!!error}>
                    {error?.message}
                  </HelperText>
                  {maxLength && (
                    <Caption style={styles.charCounter}>
                      {length}/{maxLength}
                    </Caption>
                  )}
                </View>
              </View>
              <View>
                <Text style={weight}>{postfix}</Text>
                {/* テキストボックスと postfix の高さを揃えるためのダミー要素 */}
                <Caption style={styles.dummy}>{postfix}</Caption>
              </View>
            </View>
          );
        }}
      />
    </View>
  );
}

// multiline の場合に中央寄せになってしまうバグがあるので、補正する
function textInputMulti(props: RenderProps): React.ReactElement {
  const {style, ...rest} = props;
  const addStyle = {
    paddingTop: 6,
    paddingBottom: 12,
    height: style.height ?? 95,
  };
  return <TextInput style={[style, addStyle]} {...rest} />;
}

// ==== RadioButton ====
export type RadioProps<T extends FieldValues> = {
  name: Path<T>;
  label?: string;
  items: {[P in T[keyof T]]?: string};
  defaultValue?: T[keyof T];
  withHint?: HintPattern;
  disabledKeys?: [string];
};

export type RadioInfos<T, K extends keyof T> = Required<
  Pick<{[P in keyof T]: RadioProps<Record<P, Required<T>[P]>>}, K>
>;

export function VRadioButton<T extends FieldValues>(
  props: RadioProps<T>,
): React.ReactElement {
  const {control, setValue} = useFormContext<T>();
  const {colors, fonts} = useTheme();
  const {name, label, items, defaultValue, withHint, disabledKeys} = props;
  const weight = {fontWeight: fonts.medium.fontWeight};
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({field: {onChange, value}, fieldState: {error}}) => {
        return (
          <View>
            {label && !withHint && (
              <Text style={styles.radioLabel}>{label}</Text>
            )}
            {label && withHint && (
              <View style={styles.radioLabel}>
                <WithHint id={withHint}>
                  <Text>{label}</Text>
                </WithHint>
              </View>
            )}
            <RadioButton.Group value={String(value)} onValueChange={onChange}>
              {Object.entries<string | undefined>(items).map(
                ([itemKey, itemLabel]) => {
                  const changeValue = () =>
                    setValue(name, itemKey as PathValue<T, Path<T>>);
                  return (
                    <View style={styles.radio} key={itemKey}>
                      <RadioButton
                        disabled={disabledKeys?.includes(itemKey)}
                        value={itemKey}
                        color={colors.primary}
                      />
                      {disabledKeys?.includes(itemKey) ? (
                        <Text style={{...weight, color: Colors.darkgray}}>
                          {itemLabel}
                        </Text>
                      ) : (
                        <TouchableOpacity onPress={changeValue}>
                          <Text style={weight}>{itemLabel}</Text>
                        </TouchableOpacity>
                      )}
                    </View>
                  );
                },
              )}
            </RadioButton.Group>
            <HelperText type="error" visible={!!error}>
              {error?.message}
            </HelperText>
          </View>
        );
      }}
    />
  );
}

// ==== Checkbox ====
export type CheckboxProps<T extends FieldValues> = {
  name: Path<T>;
  label?: string;
  defaultValue?: boolean;
  compact?: boolean;
  disabled?: boolean;
};

export function VCheckbox<T extends FieldValues>(
  props: CheckboxProps<T>,
): React.ReactElement {
  const {control} = useFormContext<T>();
  const {colors, fonts} = useTheme();
  const weight = {fontWeight: fonts.medium.fontWeight};
  const {disabled, name, label, defaultValue, compact} = props;

  return (
    <Controller
      control={control}
      name={name}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultValue={!!defaultValue}
      render={({field: {onChange, value}}) => {
        return (
          <View style={compact ? styles.checkBoxCompact : styles.checkBox}>
            <Checkbox
              status={value ? 'checked' : 'unchecked'}
              color={colors.primary}
              onPress={() => onChange(!value)}
              disabled={disabled}
            />
            {label && (
              <View style={compact && styles.checkBoxCompactLabel}>
                <Text style={weight}>{label}</Text>
              </View>
            )}
          </View>
        );
      }}
    />
  );
}

const styles = StyleSheet.create({
  // PaperのTextInputの構成上少し隙間が出来るので詰める
  label: {
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.crossCenter,
    marginBottom: -2,
  },
  labelCaption: {
    ...Fonts.caption,
    color: Colors.white,
    backgroundColor: Colors.darkgray,
    paddingHorizontal: 4,
    borderRadius: 4,
    marginLeft: 8,
  },
  input: {
    ...Fonts.input,
    height: 40,
  },
  errorCaption: {
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.between,
    ...CommonStyles.flex.crossEnd,
    minHeight: 26,
  },
  charCounter: {
    alignSelf: 'flex-end',
    color: Colors.black,
  },
  radioLabel: {
    marginBottom: 6,
  },
  radio: {flexDirection: 'row', alignItems: 'center'},
  dummy: {
    opacity: 0,
  },
  checkBox: {
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.center,
  },
  checkBoxCompact: {
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.center,
    width: 46,
  },
  checkBoxCompactLabel: {left: -4},
});

export const VForm = {
  Provider: VFormProvider,
  Text: VTextInput,
  Radio: VRadioButton,
  Select: VSelect,
  Check: VCheckbox,
};
