import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSCoupon} from '../API';
import {SaaSCouponRepository} from '../_proto/services/SaaSCouponRepository';
import {Helpers} from '../helper';
import {dumpDiscount, isUsedItem} from '../service';

export type Coupon = Omit<SaaSCoupon, '__typename'> & {
  title: string;
  name: string;
  price: string;
  used: boolean;
  delivery: string;
  id: string;
  archive: boolean;
  type: 'coupon';
};

const useCouponsContainer = () => {
  const [isGetLoading, setGetLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [coupons, setCoupons] = useState<Array<Coupon>>([]);
  const [groupCoupons, setGroupCoupons] = useState<Array<Array<Coupon>>>([]);
  const [error, setError] = useState<Error | undefined>();
  const [checkedIds, setCheckedIds] = useState<string[]>([]);

  async function convert(coupon: SaaSCoupon): Promise<Coupon> {
    // 不正クーポン除去
    if (!coupon.id || !coupon.editState) {
      return {
        delivery: '',
        id: '',
        name: '',
        price: '',
        title: '',
        used: false,
        archive: false,
        type: 'coupon',
      };
    }
    const used = isUsedItem(coupon);
    let delivery: string;
    if (used) {
      try {
        const count = await SaaSCouponRepository.getCouponCount(coupon.id);
        delivery = `${count.deliveries}回の配信で${Helpers.sepComma(
          count.users,
        )}人が閲覧済み`;
      } catch (err: any) {
        console.log('[convert] getCouponCount catch', err);
        delivery = '配信数取得エラー';
      }
    } else {
      delivery = 'この広告はまだ配信していません';
    }

    return {
      ...coupon,
      title: coupon.title ?? '（タイトル不明）',
      name: coupon.name ?? '-',
      price: coupon.discount ? dumpDiscount(coupon.discount) : '-',
      used,
      delivery,
      archive: !!coupon.archive,
      type: 'coupon',
    };
  }

  const getData = useCallback(async (id: string) => {
    setGetLoading(true);
    setError(undefined);
    try {
      const items = await SaaSCouponRepository.queryCoupons(id);
      const result = await Promise.all(
        items.map(async (item) => {
          return await convert(item);
        }),
      );

      setCoupons(result);
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setGetLoading(false);
    }
  }, []);

  const getGroupData = useCallback(async (ids: string[]) => {
    setGetLoading(true);
    setError(undefined);
    try {
      const response = await Promise.all(
        ids.map(async (id) => {
          const items = await SaaSCouponRepository.queryCoupons(id);
          const result = await Promise.all(
            items.map(async (item) => {
              return await convert(item);
            }),
          );
          return result;
        }),
      );

      setGroupCoupons(response);
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setGetLoading(false);
    }
  }, []);

  const deleteData = useCallback(
    async (id: string) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        const item = coupons.filter((coupon) => coupon.id === id)[0];
        console.log('item', item);
        if (!item.used) {
          await SaaSCouponRepository.remove(item.id);
        } else {
          await SaaSCouponRepository.archive(item.id);
        }

        const newCoupons = coupons.filter((coupon) => coupon.id !== id);
        setCoupons(newCoupons);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [coupons],
  );

  const deleteGroupData = useCallback(
    async (id: string) => {
      setDeleteLoading(true);
      setError(undefined);
      try {
        const item = groupCoupons.map((coupons) =>
          coupons.filter((coupon) => coupon.id === id),
        )[0][0];

        console.log('item', item);
        if (!item.used) {
          await SaaSCouponRepository.remove(item.id);
        } else {
          await SaaSCouponRepository.archive(item.id);
        }

        const newCoupons = groupCoupons.map((coupons) =>
          coupons.filter((coupon) => coupon.id !== id),
        );
        setGroupCoupons(newCoupons);
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setDeleteLoading(false);
      }
    },
    [groupCoupons],
  );

  return {
    isGetLoading,
    isDeleteLoading,
    coupons,
    error,
    getData,
    deleteData,
    groupCoupons,
    getGroupData,
    deleteGroupData,
    setCheckedIds,
    checkedIds,
  };
};

export const CouponsContainer = createContainer(useCouponsContainer);
