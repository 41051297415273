import axios, {AxiosResponse} from 'axios';
import {SaaSDeliveryHistory, SaaSNewsHistory} from '../../API';
import {AppConfig} from '../config/AppConfig';
import {SaaSRepository} from './SaaSRepository';

const TAG = 'SaaSDeleveryRepository';

export type DeliveryCouponDto = {
  couponId: string;
  targetId: string;
  start: string;
  end: string;
  budget: number;
  title: string;
  ownerId: string;
};

export type DeliveryNewsDto = {
  newsId: string;
  targetId: string;
  start: string;
  end: string;
  budget: number;
  title: string;
  ownerId: string;
};

type ViewCount = {
  billingCount?: number | null;
};

export class SaaSDeliveryRepository {
  static async deliveryCoupon(
    dto: DeliveryCouponDto,
    shopId: string,
  ): Promise<AxiosResponse<any>> {
    console.log(TAG, dto);

    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.DeliveryCouponEndPoint}/${shopId}`,
      dto,
      config,
    );
    console.log(TAG, 'deliveryCoupon', result);

    return result;
  }

  static async deliveryNews(
    dto: DeliveryNewsDto,
    shopId: string,
  ): Promise<AxiosResponse<any>> {
    console.log(TAG, dto);

    const config = await SaaSRepository.axioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.DeliveryNewsEndPoint}/${shopId}`,
      dto,
      config,
    );
    console.log(TAG, 'deliveryNews', result);

    return result;
  }

  static async updateCoupon(param: {
    id: string;
    start: string; // ISODate
    end: string; // ISODate
    budget: number;
    title: string;
  }): Promise<AxiosResponse<SaaSDeliveryHistory>> {
    const {id, ...data} = param;
    const config = await SaaSRepository.axioConfig();
    const result = await axios.put(
      `${AppConfig.SaaSBackend}${AppConfig.DeliveryCouponUpdateEndPoint}/${id}`,
      data,
      config,
    );

    console.log(TAG, 'update after', result);
    return result;
  }

  static async updateNews(param: {
    id: string;
    start: string; // ISODate
    end: string; // ISODate
    budget: number;
    title: string;
  }): Promise<AxiosResponse<SaaSNewsHistory>> {
    const {id, ...data} = param;
    const config = await SaaSRepository.axioConfig();
    const result = await axios.put(
      `${AppConfig.SaaSBackend}${AppConfig.DeliveryNewsUpdateEndPoint}/${id}`,
      data,
      config,
    );

    console.log(TAG, 'update after', result);
    return result;
  }

  static async getActiveCreatives(
    ownerId: string,
  ): Promise<AxiosResponse<any>> {
    const config = await SaaSRepository.axioConfig();
    const result = await axios.get(
      `${AppConfig.SaaSBackend}${AppConfig.GetActiveCreativesEndPoint}/${ownerId}`,
      config,
    );

    console.log(TAG, 'update after', result);
    return result;
  }

  /**
   * 配信数の取得。例外はそのまま送出するため、利用側でcatchすること。
   * @param type クーポン or お知らせ
   * @param id 対象
   */
  static async getViewCount(
    type: 'coupon' | 'news',
    id?: string,
  ): Promise<number> {
    if (!id) {
      throw 'id error';
    }
    const config = await SaaSRepository.axioConfig();
    const endpoint =
      type === 'news'
        ? AppConfig.DeliveryNewsViweCountEndPoint
        : AppConfig.DeliveryCouponViewCountEndPoint;
    const result = await axios.get<ViewCount>(
      `${AppConfig.SaaSBackend}${endpoint}/${id}`,
      config,
    );
    const count = result.data?.billingCount;
    if (count === undefined || count === null) {
      throw 'invalid view count';
    }
    return count;
  }
  // ----------- private -------------
}
