import {useNavigation, useRoute} from '@react-navigation/native';
import {Auth} from 'aws-amplify';
import {parsePhoneNumber} from 'libphonenumber-js';
import React, {useEffect} from 'react';
import Countdown, {CountdownApi} from 'react-countdown';
import {useFormContext} from 'react-hook-form';
import {Linking, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {
  MFAFormData,
  PhoneFormData,
  schemaMFAFormData,
  schemaPhoneSameCheck,
} from '../../components/Auth/schema';
import {
  Container,
  FormBlock,
  Header,
  HelperText,
  MaxWidth,
  Menu,
  PLACEHOLDER,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../../components/Elements';
import {ERROR_MSG, useCustomNav} from '../../helper';
import {AccountMenu} from '../../navigation/AccountMenu';
import {Colors, CommonStyles} from '../../theme';
import {AccountContainer} from './AccountContainer';

const LABEL_LOADING = '確認コードのチェック中';

const PhoneSubmit: React.FC = () => {
  const container = AccountContainer;
  const {changePhone, step, error} = container.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<PhoneFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const helperText = errors.phone?.message
    ? ERROR_MSG.common.form
    : error
    ? ERROR_MSG.common.dump(error)
    : '';
  const isLoading = step === 'changePhone';
  const disabled = isLoading || !watch('phone') || !!errors.phone;

  return (
    <View>
      <TrimaLoadingButton
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(changePhone, onSubmitError)}
        loading={isLoading}
        loadingIndicator={'更新中'}
      >
        携帯電話番号を変更
      </TrimaLoadingButton>
      <HelperText type="error">{helperText}</HelperText>
    </View>
  );
};

const VerifySubmit: React.FC = () => {
  const navigation = useNavigation();
  const container = AccountContainer;
  const {challengeMFA, step, error} = container.useContainer();
  // Todo 適切なエラーメッセージ（認証コードエラー他）
  const {
    handleSubmit,
    watch,
    formState: {errors},
  } = useFormContext<MFAFormData>();
  // Todo エラー時処理（リトライとか）
  const onSubmitError = () => true;
  const helperText = errors.verificationCode?.message
    ? ERROR_MSG.common.form
    : error
    ? ERROR_MSG.common.dump(error)
    : '';
  const isLoading = step === 'challenging';
  const disabled =
    isLoading || !watch('verificationCode') || !!errors.verificationCode;
  return (
    <View>
      <TrimaLoadingButton
        disabled={disabled}
        variant="contained"
        onClick={handleSubmit(
          (data) => challengeMFA(data, navigation),
          onSubmitError,
        )}
        loading={isLoading}
        loadingIndicator={LABEL_LOADING}
      >
        送信
      </TrimaLoadingButton>
      <HelperText type="error">{helperText}</HelperText>
    </View>
  );
};

const PhoneChangeForm: React.FC<{nowNumber: string}> = ({nowNumber}) => {
  const navigator = useCustomNav();
  const goBack = () => {
    navigator.goBack('AccountMain');
  };

  return (
    <View>
      <VForm.Provider<PhoneFormData> schema={schemaPhoneSameCheck(nowNumber)}>
        <VForm.Text<PhoneFormData>
          name="phone"
          label="新しい携帯電話番号"
          required
          {...PLACEHOLDER.phone}
        />
        <VMargin />
        <PhoneSubmit />
      </VForm.Provider>
      <VMargin />
      <TrimaButton variant="outlined" onClick={() => goBack()}>
        キャンセル
      </TrimaButton>
    </View>
  );
};

const MFAForm: React.FC = () => {
  return (
    <View>
      <Text>SMSに記載されている確認コードを入力してください。</Text>
      <VMargin />
      <VForm.Provider<MFAFormData> schema={schemaMFAFormData}>
        <VForm.Text<MFAFormData>
          name="verificationCode"
          label="確認コード"
          required
        />
        <VerifySubmit />
      </VForm.Provider>
    </View>
  );
};

const AccountSelector: React.FC<{nowNumber: string}> = ({nowNumber}) => {
  const navigation = useNavigation();
  const {step} = AccountContainer.useContainer();
  switch (step) {
    case 'init':
    case 'changePhone':
      return <PhoneChangeForm nowNumber={nowNumber} />;
    case 'mfa':
    case 'challenging':
      return (
        <View>
          <MFAForm />
          <VMargin />
          <ReSendCode />
        </View>
      );
    case 'done':
      return (
        <TrimaButton
          variant="outlined"
          onClick={() => navigation.navigate('Account')}
        >
          戻る
        </TrimaButton>
      );
    default:
      return null; // ここには来ないはず
  }
};

const ReSendCode: React.FC = () => {
  const {reSendVerificationCode, resetState} = AccountContainer.useContainer();
  // 60秒セット
  const timerSeconds = 60000;
  const [date, setDate] = React.useState<number>(Date.now() + timerSeconds);

  const renderer = ({
    api,
    total,
    completed,
  }: {
    api: CountdownApi;
    total: number;
    completed: boolean;
  }) => {
    return (
      <View>
        <TrimaButton
          variant="outlined"
          disabled={api.isStarted()}
          onClick={() => {
            // タイマースタート
            api.start();
            // タイマー再セット
            setDate(Date.now() + timerSeconds);
            reSendVerificationCode('phone');
          }}
        >
          確認コードの再送
        </TrimaButton>
        {!api.isStarted() ||
          (!completed && (
            <span style={{color: Colors.accent}}>
              {total / 1000}
              秒後に再送できます
            </span>
          ))}
      </View>
    );
  };

  return (
    <View>
      <Text>SMSが届かない場合</Text>
      <VMargin />
      <Text>
        SMSが届かない場合は
        <Text
          style={CommonStyles.linkText}
          onPress={() =>
            Linking.openURL(
              'https://support.shop.trip-mile.com/hc/ja/articles/4407344353165',
            )
          }
        >
          こちら
        </Text>
        の設定をご確認ください。
      </Text>
      <Text>以下のボタンから、確認コードの再送が可能です。</Text>
      <VMargin />
      <Countdown date={date} renderer={renderer} autoStart={false} />
      <VMargin />
      <Text>
        新しい携帯電話番号が間違っている可能性がございます。以下のボタンから前のページに戻って再指定をお願いします。
      </Text>
      <VMargin />
      <TrimaButton variant="outlined" onClick={() => resetState()}>
        携帯電話番号の指定に戻る
      </TrimaButton>
    </View>
  );
};

const ChangePhoneNumberComponent: React.FC = React.memo(() => {
  const [user, setUser] = React.useState<any>();

  useEffect(() => {
    async function fetchData() {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setUser(cognitoUser);
    }
    fetchData();
  }, []);

  const nowNumber = React.useMemo(() => {
    return user
      ? parsePhoneNumber(user.attributes.phone_number).formatNational()
      : '-';
  }, [user]);

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>携帯電話番号の変更</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          {!user ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <FormBlock>
                <MaxWidth maxWidth={586}>
                  <Text>現在登録されている携帯電話番号</Text>
                  <Text style={CommonStyles.margin.left}>{nowNumber}</Text>
                  <VMargin />
                  <AccountContainer.Provider>
                    <AccountSelector nowNumber={nowNumber} />
                  </AccountContainer.Provider>
                </MaxWidth>
              </FormBlock>
            </View>
          )}
        </View>
      </MaxWidth>
    </Container>
  );
});

export const ChangePhoneNumber: React.FC = () => {
  const route = useRoute<any>();
  const [showHeader, setShowHeader] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (route.params?.header) {
      setShowHeader(route.params?.header);
    }
  }, [route.params]);
  if (showHeader) {
    return (
      <View>
        <Header rightItem={<AccountMenu />} />
        <ChangePhoneNumberComponent />
      </View>
    );
  } else {
    return <ChangePhoneNumberComponent />;
  }
};
