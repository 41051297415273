import React from 'react';
import {ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {SaaSDeliveryTarget} from '../../../../API';
import {CommonStyles} from '../../../../theme';
import {MinText, Text} from '../../../Elements';
import {StationCacheContainer} from '../../StationCacheContainer';
import {TargetingContainer} from '../../TargetingContainer';
import {
  getCountBySbyL,
  makeStationsByLineOnlyStationCode,
} from '../../commonFunc';
import {StationCode, StationsByLine} from '../../types';
import {ChoiceCounter} from '../ChoiceCounter';
import {isMasterCode, splitStationDump} from '../station/stationFunc';

export const StationPreviewWithData: React.FC<{
  option: SaaSDeliveryTarget['stationOption'];
}> = React.memo(({option}) => {
  const [byLine, setByLine] = React.useState<
    StationsByLine | undefined | 'error'
  >();
  const {setStationName, getStationCode} = StationCacheContainer.useContainer();

  React.useEffect(() => {
    if (option) {
      const stations: (Promise<StationCode> | StationCode)[] = []; // プレビュー対象の駅コードリスト
      option.forEach((elem) => {
        if (elem) {
          // 駅コードを把握しないと路線毎に分けられないので新旧コードで処理を分ける
          const split = splitStationDump(elem);
          if (isMasterCode(split.code)) {
            stations.push(getStationCode(split.code, split.name));
          } else {
            setStationName(split.code, split.name);
            split.code && stations.push(split.code);
          }
        }
      });
      // promise を解決した後、state更新
      Promise.all(stations).then((results) => {
        const every = results.every((code) => {
          return code;
        });
        setByLine(every ? makeStationsByLineOnlyStationCode(results) : 'error');
      });
    }
  }, [getStationCode, option, setStationName]);

  if (!byLine) {
    return (
      <View style={styles.container}>
        <ActivityIndicator />
      </View>
    );
  }
  if (byLine === 'error') {
    return (
      <View style={styles.container}>
        <Text>利用駅取得でエラーが発生しました。</Text>
      </View>
    );
  }
  return <StationPreviewCommon stationsByLine={byLine} />;
});

export const StationPreviewWithEdit: React.FC = React.memo(() => {
  const {settingArea, stationsByLine} = TargetingContainer.useContainer();
  return (
    <StationPreviewCommon
      stationsByLine={stationsByLine}
      onPress={settingArea('station')}
    />
  );
});

const StationPreviewCommon: React.FC<{
  stationsByLine: StationsByLine;
  onPress?(): void;
}> = React.memo(({stationsByLine, onPress}) => {
  const {getStationName, getTrainLineName, updateTrainLines} =
    StationCacheContainer.useContainer();
  const [lineLoading, setLineLoading] = React.useState(true);

  // 路線名の遅延取得をトリガ
  React.useEffect(() => {
    updateTrainLines(Object.keys(stationsByLine)).then(() =>
      // ローディング状態を変更して再レンダーすることで、取得した路線名を表示させる
      setLineLoading(false),
    );
  }, [stationsByLine, updateTrainLines]);

  const count = React.useMemo<number>(() => {
    return getCountBySbyL(stationsByLine);
  }, [stationsByLine]);

  return (
    <View>
      <ScrollView style={styles.container}>
        {onPress ? (
          <TouchableOpacity activeOpacity={0.8} onPress={onPress}>
            {Object.keys(stationsByLine).map((line) => {
              const lineName =
                getTrainLineName(line) ??
                (lineLoading ? '路線名取得中' : '路線名不明');
              return (
                <View
                  style={[CommonStyles.flex.center, CommonStyles.margin.top]}
                  key={line}
                >
                  <MinText ellipsizeMode="tail" numberOfLines={1}>
                    {lineName}
                  </MinText>
                  <Text>
                    {`${stationsByLine[line]
                      .map((code) => getStationName(code) ?? String(code))
                      .join(' / ')}`}
                  </Text>
                </View>
              );
            })}
          </TouchableOpacity>
        ) : (
          <>
            {Object.keys(stationsByLine).map((line) => {
              const lineName =
                getTrainLineName(line) ??
                (lineLoading ? '路線名取得中' : '路線名不明');
              return (
                <View
                  style={[CommonStyles.flex.center, CommonStyles.margin.top]}
                  key={line}
                >
                  <MinText ellipsizeMode="tail" numberOfLines={1}>
                    {lineName}
                  </MinText>
                  <Text>
                    {`${stationsByLine[line]
                      .map((code) => getStationName(code) ?? String(code))
                      .join(' / ')}`}
                  </Text>
                </View>
              );
            })}
          </>
        )}
      </ScrollView>
      <View style={styles.choice}>
        <ChoiceCounter type="station" count={count} />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    ...CommonStyles.margin.all,
    maxHeight: 300,
  },
  choice: {
    ...CommonStyles.margin.horizontal,
    ...CommonStyles.margin.bottom,
  },
});
