import {Box, Typography} from '@mui/material';
import React from 'react';
import {SaasReportRepository} from '../../service';
import {UserAnalyzeReport} from '../../service/report';
import {DeliveryReportDemensionGraph} from './DeliveryReportDemensionGraph';
import {DeliveryReportDemensionTable} from './DeliveryReportDemensionTable';

type DataState = 'loading' | 'error' | 'exist' | 'nodata';

const calcAllValue = (reports: UserAnalyzeReport[]) => {
  let allListCount = 0;
  let allViewCount = 0;
  let allClickedUrlCount = 0;
  let allVisitedCount = 0;
  reports.forEach(({listCount, viewCount, clickedUrlCount, visited}) => {
    if (listCount) {
      allListCount += listCount;
    }
    if (viewCount) {
      allViewCount += viewCount;
    }
    if (clickedUrlCount) {
      allClickedUrlCount += clickedUrlCount;
    }
    if (visited) {
      allVisitedCount += visited;
    }
  });
  reports.unshift({
    label: '全体',
    listCount: allListCount,
    viewCount: allViewCount,
    clickedUrlCount: allClickedUrlCount,
    visited: allVisitedCount,
  });
  return reports;
};

export const DeliveryReportDemension: React.FC<{id: string}> = ({id}) => {
  const [analytics, setAnalytics] = React.useState<UserAnalyzeReport[]>([]);
  const [dataState, setDataState] = React.useState<DataState>('loading');
  const changeCategory = async (value: any) => {
    setDataState('loading');
    const reports = await SaasReportRepository.getDeliveryUserAnalyze(
      id,
      value,
    );
    setAnalytics(calcAllValue(reports));
    setDataState('exist');
  };
  React.useEffect(() => {
    async function fetchData() {
      try {
        const reports = await SaasReportRepository.getDeliveryUserAnalyze(
          id,
          'age_and_gender',
        );
        setAnalytics(calcAllValue(reports));
        setDataState('exist');
      } catch (err: any) {
        setDataState('error');
      }
    }
    fetchData();
  }, [id]);

  if (dataState === 'error') {
    return <Typography>データ取得エラー</Typography>;
  }
  return (
    <>
      <DeliveryReportDemensionGraph
        reports={analytics}
        onChange={changeCategory}
        dataState={dataState}
      />
      <Box p={1} />
      <DeliveryReportDemensionTable reports={analytics} dataState={dataState} />
    </>
  );
};
