import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {SaaSDeliveryTarget} from '../../API';
import {SaaSTargetRepository} from '../../_proto/services/SaaSTargetRepository';
import {BRAKE_POINT, CreativeSettings, useResponsive} from '../../components';
import {CreativeGroupForm} from '../../components/Creatives/schema';
import {
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  VForm,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {
  ShopContainer,
  ShopGroupsContainer,
  ShopsContainer,
} from '../../container';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {isUsedItem} from '../../service';
import {Colors, CommonStyles} from '../../theme';
import {CreativesGroupContainer} from './CreativesGroupContainer';

type NProp = MainNavigationProp<'CreativesGroupCreate'>;
type RProp = MainRouteProp<'CreativesGroupCreate'>;

const styleBaseBack = {backgroundColor: Colors.base};

const TargetListItem: React.FC<{
  target: any;
  clickTarget(target: any): void;
}> = ({target, clickTarget}) => {
  return (
    <>
      <ListItem
        sx={{
          '&.MuiListItem-root': {
            padding: 0,
            bgcolor: 'background.paper',
            border: `1px solid ${Colors.main}`,
          },
        }}
        alignItems="flex-start"
      >
        <ListItemButton
          onClick={() => clickTarget(target)}
          sx={{
            '&.MuiButtonBase-root': {
              py: 2,
            },
          }}
        >
          <ListItemText
            id={target.id}
            primary={
              <>
                <Box display="flex" flexDirection="row">
                  <Typography variant="h6" component="span">
                    {target.title}
                  </Typography>
                </Box>
                <Box pt={1}>
                  <Typography
                    variant="subtitle2"
                    component="span"
                    display="block"
                  >
                    配信対象者：{target.users.toLocaleString()}人
                  </Typography>
                </Box>
              </>
            }
          />
        </ListItemButton>
      </ListItem>
      <Box p={0.5} />
    </>
  );
};

const SelectTargetDialog: React.FC<{
  open: boolean;
  onClose(): void;
  clickTargetRow: {shop: any; index: number};
}> = ({open, onClose, clickTargetRow}) => {
  const handleClose = () => {
    onClose();
  };
  const [loading, setLoading] = React.useState(true);
  const [targets, setTargets] = React.useState<SaaSDeliveryTarget[]>([]);

  const {targetArray, setTargetArray} = CreativesGroupContainer.useContainer();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await SaaSTargetRepository.queryDeliveryTarget({
        shopId: clickTargetRow.shop.id,
      });
      setTargets(response);
      setLoading(false);
    }
    fetchData();
  }, [clickTargetRow]);

  const clickTarget = (target: any) => {
    const newArray = targetArray;
    newArray[clickTargetRow.index] = [target];
    setTargetArray(newArray);
    handleClose();
  };

  return (
    <Dialog
      sx={{'& .MuiPaper-root': {minWidth: 500}}}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        作成済み配信先の選択
      </DialogTitle>
      <DialogContent>
        <List
          sx={{
            width: '100%',
            '&.MuiList-root': {padding: 0},
          }}
        >
          {loading ? (
            <ActivityIndicator size={60} />
          ) : (
            <>
              <Typography variant="body1">未使用</Typography>
              <VMargin />
              {targets.filter((target) => !isUsedItem(target)).length > 0 ? (
                targets
                  .filter((target) => !isUsedItem(target))
                  .map((target: any) => (
                    <TargetListItem target={target} clickTarget={clickTarget} />
                  ))
              ) : (
                <Typography variant="body2">該当なし</Typography>
              )}
              <VMargin />
              <Typography variant="body1">使用済み</Typography>
              <VMargin />
              {targets.filter((target) => isUsedItem(target)).length > 0 ? (
                targets
                  .filter((target) => isUsedItem(target))
                  .map((target: any) => (
                    <TargetListItem target={target} clickTarget={clickTarget} />
                  ))
              ) : (
                <Typography variant="body2">該当なし</Typography>
              )}
            </>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

const CreateForm: React.FC = () => {
  const navigation = useNavigation<NProp>();
  const {isMobile} = useResponsive();
  const directionStyle = isMobile ? {} : CommonStyles.flex.row;
  const blockStyle = isMobile ? {} : {width: 399};
  const [open, setOpen] = React.useState(false);
  const [clickTargetRow, setClickTargetRow] = React.useState<{
    shop: any;
    index: number;
  }>({shop: undefined, index: 0});
  const handleClose = () => {
    setOpen(false);
  };

  const {targetArray} = CreativesGroupContainer.useContainer();
  const {shopGroups} = ShopGroupsContainer.useContainer();
  const {shopList} = ShopsContainer.useContainer();
  const {selected} = ShopContainer.useContainer();

  const {watch} = useFormContext<CreativeGroupForm>();

  const getTarget = (index: number) => {
    if (
      targetArray.length > 0 &&
      targetArray[index].length > 0 &&
      targetArray[index][0]
    ) {
      return targetArray[index][0];
    } else {
      return {title: undefined, users: undefined};
    }
  };

  let totalUsers = 0;
  let totalShop = 0;
  let totalBudget = 0;
  let noTotalBudget = false;
  const groups = watch('groups');
  groups.forEach((group, index) => {
    if (group.enable) {
      totalShop++;
      totalUsers = totalUsers + targetArray[index][0].users;
      if (group.budget) {
        totalBudget = totalBudget + Number(group.budget);
      }
      if (group.noUpperLimit) {
        noTotalBudget = true;
      }
    }
  });
  return (
    <>
      <FormControl fullWidth>
        <FormBlock>
          <Typography variant="h6" component="h2">
            {`${
              shopGroups.find((shopGroup) => shopGroup.id === selected?.groupId)
                ? shopGroups.find(
                    (shopGroup) => shopGroup.id === selected?.groupId,
                  ).name
                : ''
            } (${
              shopList.filter((shop) => {
                if (selected) {
                  return shop.groupId === selected.groupId;
                } else {
                  return shop.groupId === null;
                }
              }).length
            }店舗)`}
          </Typography>
          <TableContainer style={{width: '100%'}}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={styleBaseBack}>
                  <TableCell
                    align="center"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  />
                  <TableCell
                    align="center"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  >
                    店舗名
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  >
                    配信先
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  >
                    配信対象者数
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  >
                    予算上限
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shopList
                  .filter((shop) => {
                    if (selected) {
                      return shop.groupId === selected.groupId;
                    } else {
                      return shop.groupId === null;
                    }
                  })
                  .map((shop, index) => {
                    return (
                      <TableRow
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        selected={watch(`groups.${index}.enable`)}
                        hover
                        sx={
                          targetArray[index] && targetArray[index].length === 0
                            ? {
                                bgcolor: 'rgba(0, 0, 0, 0.04)',
                              }
                            : {
                                '&.Mui-selected': {
                                  bgcolor: 'rgba(255, 111, 0, 0.08)',
                                },
                              }
                        }
                        key={shop.id}
                      >
                        <TableCell
                          align="center"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          <VForm.Check
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            name={`groups.${index}.enable`}
                            key={`groups.${index}.enable`}
                            disabled={
                              targetArray[index] &&
                              targetArray[index].length === 0
                            }
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          {
                            shopList.filter((shop) => {
                              if (selected) {
                                return shop.groupId === selected.groupId;
                              } else {
                                return shop.groupId === null;
                              }
                            })[index].name
                          }
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                          >
                            {getTarget(index).title}
                            {getTarget(index).title && (
                              <Button
                                style={{paddingLeft: 8}}
                                onClick={() => {
                                  setClickTargetRow({shop, index});
                                  setOpen(true);
                                }}
                                variant="contained"
                                color="primary"
                                size="small"
                              >
                                変更
                              </Button>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          {getTarget(index).users
                            ? `${getTarget(index).users.toLocaleString()}人`
                            : ''}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{p: 1, border: `1px solid ${Colors.base}`}}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <Grid item xs={6}>
                              {groups[index] && (
                                <VForm.Text<CreativeGroupForm>
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  name={`groups.${index}.budget`}
                                  disabled={
                                    (targetArray[index] &&
                                      targetArray[index].length === 0) ||
                                    groups[index].noUpperLimit
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item xs={2}>
                              円
                            </Grid>
                            <Grid item xs={4}>
                              <VForm.Check<CreativeGroupForm>
                                label="上限なし"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                name={`groups.${index}.noUpperLimit`}
                                key={`groups.${index}.noUpperLimit`}
                                disabled={
                                  targetArray[index] &&
                                  targetArray[index].length === 0
                                }
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {clickTargetRow.shop && (
                  <SelectTargetDialog
                    open={open}
                    onClose={handleClose}
                    clickTargetRow={clickTargetRow}
                  />
                )}

                <TableRow hover>
                  <TableCell
                    align="center"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  />
                  <TableCell
                    align="left"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  >
                    {totalShop.toLocaleString()}店舗
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  />
                  <TableCell
                    align="right"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  >
                    {totalUsers.toLocaleString()}人
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{p: 1, border: `1px solid ${Colors.base}`}}
                  >
                    {noTotalBudget ? '---' : totalBudget.toLocaleString()}円まで
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </FormBlock>
        <VMargin />
        <View style={directionStyle}>
          <FormBlock style={blockStyle}>
            <CreativeSettings.AdsContent
              onSelect={(type, id) => {
                switch (type) {
                  case 'couponNew':
                    return navigation.navigate('CouponsCreate', {
                      from: 'CreativesGroupCreate',
                    });
                  case 'newsNew':
                    return navigation.navigate('NewsCreate', {
                      from: 'CreativesGroupCreate',
                    });
                  case 'couponList':
                    return navigation.navigate('CouponsMain', {
                      from: 'CreativesGroupCreate',
                    });
                  case 'newsList':
                    return navigation.navigate('NewsMain', {
                      from: 'CreativesGroupCreate',
                    });
                  case 'couponEdit':
                    return navigation.navigate('CouponsEdit', {
                      from: 'CreativesGroupCreate',
                      id,
                    });
                  case 'newsEdit':
                    return navigation.navigate('NewsEdit', {
                      from: 'CreativesGroupCreate',
                      id,
                    });
                }
              }}
            />
          </FormBlock>
          <View style={[CommonStyles.margin.top, CommonStyles.margin.left]} />
          <View style={CommonStyles.flex.full}>
            <CreativeSettings.TermForm isGroup targetArray={targetArray} />
          </View>
        </View>
        <CreativeSettings.GroupSubmit
          targetArray={targetArray}
          shopList={shopList.filter((shop) => {
            if (selected) {
              return shop.groupId === selected.groupId;
            } else {
              return shop.groupId === null;
            }
          })}
          onDone={() => navigation.navigate('CreativesMain')}
        />
      </FormControl>
    </>
  );
};

const Main: React.FC = () => {
  const {shopList} = ShopsContainer.useContainer();
  const {selected} = ShopContainer.useContainer();
  const {setTargetArray} = CreativesGroupContainer.useContainer();
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();

  const ads =
    route.params && route.params.type && route.params.adsId
      ? {
          type: route.params.type,
          id: route.params.adsId,
        }
      : undefined;
  const targetId = route.params?.targetId;
  if (ads) {
    // 一度取り出したらあとは不要（逆に他のを選択した時に邪魔しても困るので）クリア。
    navigation.setParams({adsId: undefined});
  }
  if (targetId) {
    console.log('[targetId]', targetId);
    navigation.setParams({targetId: undefined});
  }

  const [groups, setGroups] = React.useState<Array<any>>();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let obj = {};
      const response = await Promise.all(
        shopList
          .filter((shop) => {
            if (selected) {
              return shop.groupId === selected.groupId;
            } else {
              return shop.groupId === null;
            }
          })

          .map(async (shop) => {
            obj = {...obj, [shop.id]: false};
            return await SaaSTargetRepository.queryDeliveryTarget({
              shopId: shop.id,
              limit: 1,
            });
          }),
      );
      setTargetArray(response);
      const array: any[] = [];
      response.forEach((res) => {
        array.push({
          enable: res.length > 0 ? true : false,
        });
      });
      setGroups(array);
      setLoading(false);
    }
    fetchData();
  }, [shopList, selected, setGroups, setTargetArray]);
  return (
    <Container>
      <MaxWidth
        maxWidth={BRAKE_POINT.desktop + 16}
        style={CommonStyles.margin.all}
      >
        <WithHint id="creativesGroupCreate">
          <Menu>グループ一括配信</Menu>
        </WithHint>
        <VMargin />
        {loading ? (
          <ActivityIndicator size={60} />
        ) : (
          <CreativeSettings.ProviderGroup
            ads={ads}
            targetId={targetId}
            groups={groups}
          >
            <CreateForm />
          </CreativeSettings.ProviderGroup>
        )}
      </MaxWidth>
    </Container>
  );
};

export const Create: React.FC = () => {
  return (
    <CreativesGroupContainer.Provider>
      <Main />
    </CreativesGroupContainer.Provider>
  );
};
