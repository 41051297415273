import React from 'react';
import {StyleSheet, View} from 'react-native';
import {
  AreaOption,
  Gender,
  MovingMethod,
  RangeOption,
  Residence,
  SaaSDeliveryTarget,
  StayOption,
} from '../../API';
import {Helpers} from '../../helper';
import {Colors, CommonStyles} from '../../theme';
import {Paragraph, Text} from '../Elements';
import {getPrefecture} from '../Targets/area/prefecture/def';
import {PrefecturePreview as PrefectureP} from '../Targets/area/preview/PrefecturePreview';
import {ResidencePreviewWithData} from '../Targets/area/preview/ResidencePreview';
import {StationPreviewWithData} from '../Targets/area/preview/StationPreview';
import {StayPreviewWithData} from '../Targets/area/preview/StayPreview';
import {UPPER_UNLIMITED} from '../Targets/convert';
import {
  ATTR_LABELS,
  INTEREST_LABELS,
  Interest,
  JOB_LABELS,
  Job,
  MOVING_LABELS,
  RESIDENCE_LABELS,
  radio,
} from '../Targets/schema';

const Summary: React.FC<{
  target?: {
    title?: string | null;
    users?: number | null;
  };
  noTitle?: boolean;
}> = ({target, noTitle}) => {
  return (
    <View>
      {!noTitle && <Text>{target?.title ?? '不明なタイトル'}</Text>}
      <View style={styles.targetCount}>
        <Text>配信対象者：</Text>
        <Text style={styles.count}>
          {target?.users ? Helpers.sepComma(target.users) : '---'}
        </Text>
        <Text>人</Text>
      </View>
    </View>
  );
};

const AllAreaPreview: React.FC = () => {
  return (
    <View style={styles.allArea}>
      <Text>全国へ配信</Text>
    </View>
  );
};

const ResidencePreview: React.FC<{
  area: AreaOption;
}> = ({area}) => {
  if (!area) {
    return null;
  }
  return (
    <View style={styles.areaContainer}>
      <Text style={styles.centering}>居住地・勤務地</Text>
      <ResidencePreviewWithData area={area} />
    </View>
  );
};

const StayPreview: React.FC<{
  area: StayOption;
}> = ({area}) => {
  if (!area) {
    return null;
  }
  return (
    <View style={styles.areaContainer}>
      <Text style={styles.centering}>滞在履歴</Text>
      <StayPreviewWithData area={area} />
    </View>
  );
};

const StationPreview: React.FC<{
  option: SaaSDeliveryTarget['stationOption'];
}> = ({option}) => {
  if (!option || option.length === 0) {
    return null;
  }
  return (
    <View style={styles.areaContainer}>
      <Text style={styles.centering}>利用駅</Text>
      <StationPreviewWithData option={option} />
    </View>
  );
};

const PrefecturePreview: React.FC<React.ComponentProps<typeof PrefectureP>> = (
  prop,
) => {
  return (
    <View style={styles.areaContainer}>
      <Text style={styles.centering}>市区町村</Text>
      <PrefectureP {...prop} />
    </View>
  );
};

const CityPreview: React.FC<React.ComponentProps<any>> = ({cityOption}) => {
  return (
    <View style={styles.areaContainer}>
      <Text style={styles.centering}>市区町村</Text>
      <PrefectureP cityOption={cityOption} />
    </View>
  );
};

const Area: React.FC<{target: SaaSDeliveryTarget}> = ({target}) => {
  const {areaOption, stayOption, stationOption, userOption, cityOption} =
    target;
  const prefectures = getPrefecture(userOption);
  return (
    <View>
      <Text style={styles.centering}>エリア</Text>
      {!areaOption &&
        !stayOption &&
        !stationOption &&
        !prefectures &&
        !cityOption && <AllAreaPreview />}
      {areaOption && <ResidencePreview area={areaOption} />}
      {stayOption && <StayPreview area={stayOption} />}
      {stationOption && <StationPreview option={stationOption} />}
      {prefectures && <PrefecturePreview prefList={prefectures} />}
      {cityOption && <CityPreview cityOption={cityOption} />}
    </View>
  );
};

function dumpAgeRange(range: RangeOption): string {
  return `${range.lower ? range.lower : '指定なし'} 歳以上
${
  range.upper && range.upper !== UPPER_UNLIMITED ? range.upper : '指定なし'
} 歳未満`;
}

function toIncome(num: number | null | undefined): string {
  if (num === 0 || num === UPPER_UNLIMITED) {
    return '指定なし';
  } else if (num === 1) {
    return String(num);
  } else if (num) {
    return num + '万';
  } else {
    // 来ないはず
    return '---';
  }
}

function dumpIncome(range: RangeOption): string {
  return `${toIncome(range.lower)} 円以上
${toIncome(range.upper)} 円未満`;
}

function dumpFamily(num: number | null | undefined): string {
  if (num === 1) {
    return num + '人暮らし';
  } else if (num) {
    return num + '人以上';
  } else {
    return '---';
  }
}

function dumpChildren(
  num: number | null | undefined,
  range: RangeOption | null | undefined,
): string {
  if (num === 0) {
    return '人数\nなし';
  } else if (num) {
    if (!range) {
      return `人数\n${num}人以上`;
    } else {
      return `人数\n${num}人以上

子供の年齢
${range.lower} 歳以上
${
  range.upper && range.upper !== UPPER_UNLIMITED ? range.upper : '指定なし'
} 歳未満`;
    }
  } else {
    // 来ないはず
    return '---';
  }
}

const Attribute: React.FC<{target: SaaSDeliveryTarget}> = ({target}) => {
  const {userOption} = target;
  if (!userOption) {
    return null;
  }

  return (
    <View>
      <Text style={styles.centering}>対象者詳細設定</Text>
      {userOption.gender && userOption.gender !== Gender.OTHER && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.gender}
          value={radio.gender.items[userOption.gender]}
        />
      )}
      {userOption.ageRange && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.ageRange}
          value={dumpAgeRange(userOption.ageRange)}
        />
      )}
      {userOption.married !== null && userOption.married !== undefined && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.married}
          value={userOption.married ? '既婚' : '未婚'}
        />
      )}
      {userOption.residence && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.residence}
          value={userOption.residence
            .filter((value) => value !== null)
            .map((value) => RESIDENCE_LABELS[value as Residence])
            .join('\n')}
        />
      )}
      {userOption.incomeRange && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.income}
          value={dumpIncome(userOption.incomeRange)}
        />
      )}
      {userOption.householdIncomeRange && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.householdIncome}
          value={dumpIncome(userOption.householdIncomeRange)}
        />
      )}
      {userOption.familyTogether && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.familyTogether}
          value={dumpFamily(userOption.familyTogether)}
        />
      )}
      {userOption.childrenTogether && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.children}
          value={dumpChildren(userOption.childrenTogether, userOption.childAge)}
        />
      )}

      {userOption.movingMethod && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.movingMethod}
          value={userOption.movingMethod
            .filter((value) => value !== null)
            .map((value) => MOVING_LABELS[value as MovingMethod])
            .join('\n')}
        />
      )}

      {userOption.job && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.job}
          value={userOption.job
            .filter((value) => value !== null)
            .map((value) => JOB_LABELS[value as Job])
            .join('\n')}
        />
      )}

      {userOption.interest && (
        <Paragraph
          style={CommonStyles.margin.top}
          label={ATTR_LABELS.interest}
          value={userOption.interest
            .filter((value) => value !== null)
            .map((value) => INTEREST_LABELS[value as Interest])
            .join('\n')}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  targetCount: {
    backgroundColor: Colors.base,
    ...CommonStyles.padding.all,
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.center,
  },
  count: {
    width: 100,
    textAlign: 'center',
  },
  centering: {
    textAlign: 'center',
  },
  areaContainer: {
    ...CommonStyles.margin.top,
    ...CommonStyles.padding.all,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.base,
  },
  map: {
    height: 280,
  },
  allArea: {
    height: 40,
    ...CommonStyles.margin.top,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.base,
    ...CommonStyles.flex.center,
  },
});

export const TargetsPreview = {
  Area,
  Attribute,
  Summary,
};
