import axios from 'axios';
import dayjs from 'dayjs';
import {SaaSNews as SaaSNewsEntity} from '../../API';
import {listNews} from '../../graphql/queries';
import {AppConfig} from '../config/AppConfig';
import {GraphQlRepository} from './GraphQlRepository';
import {SaaSImageContents, SaaSRepository} from './SaaSRepository';
const TAG = 'SaaSNewsRepository';

export type SaaSNewsDefine = {
  name: string;
  title: string;
  text?: string;
  shopId: string;
  ownerId: string;
  editState: string;
  cvLinkUrl?: string;
  cvLinkText?: string;
  cvPhone?: string;
};

export type SaaSNews = {
  def: SaaSNewsDefine;
  images: SaaSImageContents[];
};

export type SaaSNewsKey = {
  id: string;
  name: string;
};

export class SaaSNewsRepository {
  static async post(news: SaaSNews): Promise<any> {
    console.log(TAG, news);

    const data = new FormData();

    data.append('json', new Blob([JSON.stringify(news.def)]), 'news');
    await Promise.all(
      news.images.map(async (image) => {
        const blob = new Blob([image.uri]);
        data.append('images', blob, image.key);
      }),
    );

    const config = await SaaSRepository.formAxioConfig();

    const result = await axios.post(
      `${AppConfig.SaaSBackend}${AppConfig.NewsEndPoint}`,
      data,
      config,
    );

    console.log(TAG, 'upload', result);

    return result;
  }

  static async put(news: SaaSNews, origin: any): Promise<any> {
    console.log(TAG, news);

    const data = new FormData();

    data.append('json', new Blob([JSON.stringify(news.def)]), 'news');
    await Promise.all(
      news.images.map(async (image) => {
        const blob = new Blob([image.uri]);
        data.append('images', blob, image.key);
      }),
    );

    const config = await SaaSRepository.formAxioConfig();

    const result = await axios.put(
      `${AppConfig.SaaSBackend}${AppConfig.NewsEndPoint}/${origin.id}`,
      data,
      config,
    );

    console.log(TAG, 'upload', result);

    return result;
  }

  static async remove(id: string): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.delete(
      `${AppConfig.SaaSBackend}${AppConfig.NewsEndPoint}/${id}`,
      config,
    );

    console.log(TAG, 'delete', result);
    return result;
  }

  static async archive(id: string): Promise<any> {
    const config = await SaaSRepository.axioConfig();

    const result = await axios.put(
      `${AppConfig.SaaSBackend}${AppConfig.NewsEndPoint}/${id}/archive`,
      {},
      config,
    );

    console.log(TAG, 'archive', result);
    return result;
  }

  static async queryNews(shopId: string): Promise<SaaSNewsEntity[]> {
    const result = await GraphQlRepository.query(listNews, 'listNews', {
      shopId,
      sortDirection: 'DESC',
    });

    return result;
  }

  static async getNewsCount(id: string): Promise<{
    newsId: string;
    deliveries: number;
    users: number;
  }> {
    console.log(TAG, '[getNewsCount]', id);

    const config = await SaaSRepository.axioConfig();
    const random = dayjs().unix();
    const result = await axios.get(
      `${AppConfig.SaaSBackend}${AppConfig.NewsCountEndPoint}/${id}/${random}`,
      config,
    );

    console.log(TAG, '[getNewsCount] result', result);
    const data = result.data;
    if (data) {
      const newsId = data.newsId;
      const deliveries = Number(data.deliveries);
      const users = Number(data.users);
      return {newsId, deliveries, users};
    }

    throw '[getNewsCount] field error';
  }
}
