import React, {ReactElement} from 'react';
import {ScrollView, View, ViewStyle} from 'react-native';
import {Portal} from 'react-native-paper';
import {useHeight} from '../../Responsive';
import {CommonStyles} from '../../../theme';

type Props = {
  children: React.ReactElement | ReactElement[];
  style: ViewStyle;
  notScroll?: boolean; // 一覧画面など、画面内でスクロール要素を構成するのであればこちらを指定
  testID?: string;
};

export function Container({
  children,
  style,
  testID,
  notScroll,
}: Props): React.ReactElement {
  const {contentHeight} = useHeight();
  const styles = {
    alignItems: 'stretch' as const,
    justifyContent: 'flex-start' as const,
    height: contentHeight,
    ...style,
  };

  return (
    <Portal.Host>
      <View style={styles} testID={testID}>
        {notScroll ? (
          <View style={CommonStyles.flex.full}>{children}</View>
        ) : (
          <ScrollView>{children}</ScrollView>
        )}
      </View>
    </Portal.Host>
  );
}

export function ContainerFull({
  children,
  style,
  testID,
}: Props): React.ReactElement {
  const {windowHeight} = useHeight();
  const styles = {
    alignItems: 'stretch' as const,
    justifyContent: 'flex-start' as const,
    height: windowHeight,
    ...style,
  };

  return (
    <Portal.Host>
      <View style={styles} testID={testID}>
        {children}
      </View>
    </Portal.Host>
  );
}
