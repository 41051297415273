import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {BackLink, Menu, WithHint} from '../../components/Elements';
import {NewsSubmitType} from '../../components/News';
import {
  MainNavigationProp,
  MainRouteProp,
  ScreenType,
} from '../../navigation/MainScreen';
import {CommonStyles} from '../../theme';
import {NewsPageLayout} from './layout';

type NProp = MainNavigationProp<'NewsCreate'>;
type RProp = MainRouteProp<'NewsCreate'>;

const Title: React.FC<{from?: ScreenType}> = ({from}) => {
  const navigation = useNavigation<NProp>();
  const cancel = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('CreativesCreate');
    }
  };
  return (
    <View style={[CommonStyles.margin.top, CommonStyles.margin.left]}>
      {from === 'CreativesCreate' && (
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label="配信する画面に戻る" onClick={cancel} />
        </Box>
      )}
      <WithHint id="newsCreate">
        <Menu>広告を作成</Menu>
      </WithHint>
    </View>
  );
};

export const Create: React.FC = React.memo(() => {
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const from = route.params?.from;
  // 画面遷移
  const goto = (type: NewsSubmitType, newsId: string | undefined) => {
    switch (type) {
      case 'fixAndGo':
      case 'fixAndCreative':
        navigation.navigate(from ?? 'CreativesCreate', {
          type: 'news',
          adsId: newsId,
        });
        return;
      case 'fixed':
        navigation.navigate('NewsMain');
        return;
      case 'editing':
        // 一時保存では画面遷移は不要な想定
        return;
    }
  };

  return (
    <NewsPageLayout
      mode="new"
      from={from}
      onSubmit={goto}
      title={<Title from={from} />}
    />
  );
});
