import _ from 'lodash';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {TouchableOpacity, View} from 'react-native';
import {CommonStyles} from '../../../../theme';
import {MinText, Text} from '../../../Elements';
import {TargetingContainer} from '../../TargetingContainer';
import {AttrForm} from '../../schema';
import {PrefectureContainer} from '../prefecture/PrefectureContainer';
import {
  PREFECTURES_GROUP_LIST,
  PREFECTURES_LIST,
  PREF_CODE,
  PREF_REGION,
  regions,
  string2PrefList,
} from '../prefecture/def';

export const PrefecturePreviewWithForm: React.FC = React.memo(() => {
  const {getValues} = useFormContext<AttrForm>();
  const {settingArea} = TargetingContainer.useContainer();
  const list = string2PrefList(getValues('areaPrefecture') ?? '');
  return (
    <PrefecturePreview prefList={list} onPress={settingArea('prefecture')} />
  );
});

type CityOption = {
  prefCode: string;
  cityCode?: string;
  prefName: string;
  cityName?: string;
};
export const PrefecturePreview: React.FC<{
  prefList?: PREF_CODE[];
  onPress?(): void;
  cityOption?: Array<CityOption>;
}> = ({prefList, onPress, cityOption}) => {
  return (
    <>
      {onPress ? (
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={onPress}
          style={[CommonStyles.flex.center, CommonStyles.margin.top]}
        >
          {prefList &&
            PREFECTURES_GROUP_LIST.map((group) => {
              if (typeof group === 'number') {
                return (
                  <NoRegion code={group} prefList={prefList} key={group} />
                );
              } else {
                return (
                  <Region region={group} prefList={prefList} key={group.name} />
                );
              }
            })}
          <Pref cityOption={cityOption} />
          <City cityOption={cityOption} />
        </TouchableOpacity>
      ) : (
        <>
          {prefList &&
            PREFECTURES_GROUP_LIST.map((group) => {
              if (typeof group === 'number') {
                return (
                  <NoRegion code={group} prefList={prefList} key={group} />
                );
              } else {
                return (
                  <Region region={group} prefList={prefList} key={group.name} />
                );
              }
            })}
          <Pref cityOption={cityOption} />
          <City cityOption={cityOption} />
        </>
      )}
    </>
  );
};

const NoRegion: React.FC<{code: PREF_CODE; prefList: PREF_CODE[]}> = ({
  code,
  prefList,
}) => {
  if (!prefList.includes(code)) {
    return null;
  }
  return (
    <View style={CommonStyles.margin.bottom}>
      <Text>{PREFECTURES_LIST[code]}</Text>
    </View>
  );
};

const Region: React.FC<{region: PREF_REGION; prefList: PREF_CODE[]}> = ({
  region,
  prefList,
}) => {
  const list = _.intersection(region.list, prefList);
  if (list.length === 0) {
    return null;
  }
  return (
    <View style={[CommonStyles.flex.center, CommonStyles.margin.bottom]}>
      <MinText>{region.name}</MinText>
      <Text>{list.map((code) => PREFECTURES_LIST[code]).join(' / ')}</Text>
    </View>
  );
};

const getRegion = (prefCode: string) => {
  let res: {
    id: string;
    name: string;
    prefs: {prefCode: string; prefName: string}[];
  } = {id: '', name: '', prefs: []};
  regions.forEach((region) => {
    if (region.prefs.some((pref) => pref.prefCode === prefCode)) {
      res = region;
    }
  });
  return res;
};

const Pref: React.FC<{cityOption?: Array<CityOption>}> = ({cityOption}) => {
  const viewPrefList: any = [];
  const {selectedRegionList, selectedPrefList} =
    PrefectureContainer.useContainer();

  if (cityOption && cityOption.length > 0) {
    const prefListSortByPrefCode = _.sortBy(cityOption, 'prefCode');

    for (const selectedPref of prefListSortByPrefCode) {
      const {prefCode} = selectedPref;
      const region = getRegion(prefCode);
      const {id, name} = region;
      if (!viewPrefList.some((item: any) => item.id === id)) {
        viewPrefList.push({
          id,
          name,
          prefectures: [],
        });
      }
    }
    for (const selectedPref of prefListSortByPrefCode) {
      const {prefCode, cityCode} = selectedPref;
      const region = getRegion(prefCode);
      viewPrefList.forEach((item: any, index: number) => {
        if (item.id === region.id && !cityCode) {
          viewPrefList[index].prefectures.push(selectedPref);
        }
      });
    }
  } else {
    if (selectedRegionList.length > 0) {
      const regionListSortById = _.sortBy(selectedRegionList, 'id');

      for (const selectedRegion of regionListSortById) {
        const {id, name} = selectedRegion;
        if (!viewPrefList.some((item: any) => item.id === id)) {
          const prefectures = regions
            .filter((region) => region.id === selectedRegion.id)
            .map((region) => region.prefs)[0];
          viewPrefList.push({
            id,
            name,
            prefectures,
          });
        }
      }
    }
    if (selectedPrefList.length > 0) {
      const prefListSortByPrefCode = _.sortBy(selectedPrefList, 'prefCode');

      for (const selectedPref of prefListSortByPrefCode) {
        const {id, name} = selectedPref;
        if (!viewPrefList.some((item: any) => item.id === id)) {
          viewPrefList.push({
            id,
            name,
            prefectures: [],
          });
        }
      }
      for (const selectedPref of prefListSortByPrefCode) {
        viewPrefList.forEach((item: any, index: number) => {
          if (item.id === selectedPref.id) {
            viewPrefList[index].prefectures.push(selectedPref);
          }
        });
      }
    }
  }

  if (viewPrefList.length === 0) {
    return null;
  }
  return (
    <>
      {_.sortBy(viewPrefList, 'id').map((viewPref: any) => {
        return (
          <View style={[CommonStyles.flex.center, CommonStyles.margin.bottom]}>
            {(viewPref.id !== '0' || viewPref.id !== '8') && (
              <MinText>{viewPref.name}</MinText>
            )}
            <Text>
              {viewPref.prefectures
                .map((prefecture: any) => prefecture.prefName)
                .join(' / ')}
            </Text>
          </View>
        );
      })}
    </>
  );
};

const City: React.FC<{cityOption?: Array<CityOption>}> = ({cityOption}) => {
  const viewCityList: any = [];
  const {selectedCityList} = PrefectureContainer.useContainer();
  if (selectedCityList.length > 0) {
    const cityListSortByCityCode = _.sortBy(selectedCityList, 'id');

    for (const selectedCity of cityListSortByCityCode) {
      const {prefCode, prefName} = selectedCity;
      if (!viewCityList.some((item: any) => item.prefCode === prefCode)) {
        viewCityList.push({
          prefCode,
          prefName,
          cities: [],
        });
      }
    }
    for (const selectedCity of cityListSortByCityCode) {
      viewCityList.forEach((item: any, index: number) => {
        if (item.prefCode === selectedCity.prefCode) {
          viewCityList[index].cities.push(selectedCity);
        }
      });
    }
  }
  if (cityOption && cityOption.length > 0) {
    const cityListSortByCityCode = _.sortBy(cityOption, 'cityCode');

    for (const selectedCity of cityListSortByCityCode) {
      const {prefCode, prefName, cityCode} = selectedCity;
      if (
        cityCode &&
        !viewCityList.some((item: any) => item.prefCode === prefCode)
      ) {
        viewCityList.push({
          prefCode,
          prefName,
          cities: [],
        });
      }
    }
    for (const selectedCity of cityListSortByCityCode) {
      viewCityList.forEach((item: any, index: number) => {
        if (item.prefCode === selectedCity.prefCode) {
          viewCityList[index].cities.push(selectedCity);
        }
      });
    }
  }

  if (viewCityList.length === 0) {
    return null;
  }
  return (
    <>
      {viewCityList.map((viewCity: any) => {
        return (
          <View style={[CommonStyles.flex.center, CommonStyles.margin.bottom]}>
            <MinText>{viewCity.prefName}</MinText>
            <Text>
              {viewCity.cities.map((city: any) => city.cityName).join(' / ')}
            </Text>
          </View>
        );
      })}
    </>
  );
};
