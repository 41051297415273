import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {CouponSubmitType} from '../../components/Coupon';
import {FormData} from '../../components/Coupon/schema';
import {EditMode, convertForRestore} from '../../components/Coupon/service';
import {BackLink, Menu, Text} from '../../components/Elements';
import {CouponsContainer, ShopContainer} from '../../container';
import {
  MainNavigationProp,
  MainRouteProp,
  ScreenType,
} from '../../navigation/MainScreen';
import {graphQLService} from '../../service';
import {CommonStyles, Message} from '../../theme';
import {CouponPageLayout} from './layout';

type NProp =
  | MainNavigationProp<'CouponsEdit'>
  | MainNavigationProp<'CouponsCopy'>;
type RProp = MainRouteProp<'CouponsEdit'> | MainRouteProp<'CouponsCopy'>;

const Title: React.FC<{from?: ScreenType; editMode: EditMode}> = ({
  from,
  editMode,
}) => {
  const navigation = useNavigation<NProp>();
  const cancel = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      // とりあえず一覧に戻ってしまう。
      navigation.navigate('CouponsMain');
    }
  };
  const label = editMode === 'edit' ? Message.BackNoEdit : Message.BackNoCopy;
  const menu = editMode === 'edit' ? '広告を編集' : '広告を複製';
  return (
    <View style={[CommonStyles.margin.top, CommonStyles.margin.left]}>
      {from && (
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label={label} onClick={cancel} />
        </Box>
      )}
      <Menu>{menu}</Menu>
    </View>
  );
};

// 編集 or 複製時に、既存をリストアしてフォームを表示する。
export const RestoreScreen: React.FC<{editMode: EditMode}> = ({editMode}) => {
  const {selected} = ShopContainer.useContainer();
  const {checkedIds} = CouponsContainer.useContainer();
  const [target, setTarget] = React.useState<
    {forms: FormData; images: SaaSImageContents[]; barcode?: string} | undefined
  >();
  const [error, setError] = React.useState<Error | undefined>();
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const from = route.params?.from;
  const id = route.params?.id;
  const group = route.params?.group;
  React.useEffect(() => {
    if (id) {
      graphQLService
        .getCoupon(id)
        .then((data) => convertForRestore(data, editMode, !selected?.legalUrl))
        .then(setTarget)
        .catch(setError);
    } else {
      setError(Error('広告IDがありません'));
    }
    if (group === 'on') {
      if (checkedIds.length === 0) {
        setError(Error('不正な状態です'));
      }
    }
  }, [editMode, id, route, selected]);

  if (error) {
    return (
      <View>
        <Text>エラーが発生しました</Text>
        <Text>{error.message}</Text>
      </View>
    );
  }

  const goto = (type: CouponSubmitType, couponId: string | undefined) => {
    switch (type) {
      case 'fixAndGo':
      case 'fixAndCreative':
        navigation.navigate('CreativesCreate', {
          type: 'coupon',
          adsId: couponId,
        });
        return;
      case 'fixed':
        navigation.goBack();
        return;
      case 'editing':
        // 一時保存では画面遷移は不要な想定
        return;
    }
  };
  // ターゲット取得前
  if (!target) {
    return <ActivityIndicator size={40} />;
  }
  // 取得できたら遷移
  return (
    <CouponPageLayout
      group={group}
      mode="edit"
      editTarget={target}
      from={from}
      onSubmit={goto}
      title={<Title from={from} editMode={editMode} />}
    />
  );
};
export const Edit: React.FC = React.memo(() => {
  return <RestoreScreen editMode="edit" />;
});
