import AsyncStorage from '@react-native-async-storage/async-storage';
import {Auth} from 'aws-amplify';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {StepNavigationContainer} from '../container';

const useAuthContainer = () => {
  const {setStep2SignOut} = StepNavigationContainer.useContainer();
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [error, setError] = useState<Error | undefined>();

  const checkAuthUser = useCallback(async (bypassCache = false) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const currentUser = await Auth.currentAuthenticatedUser({
        // 初回は最新の情報を取得する
        bypassCache,
      });
      setUser(currentUser);
      if (!currentUser) {
        throw new Error('no current user');
      }
      return currentUser;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const signOut = useCallback(async () => {
    await Auth.signOut();
    await AsyncStorage.removeItem('owner');
    await AsyncStorage.removeItem('shop');
    setStep2SignOut();
  }, [setStep2SignOut]);

  return {
    isCreateLoading,
    error,
    user,
    checkAuthUser,
    signOut,
  };
};

export const AuthContainer = createContainer(useAuthContainer);
