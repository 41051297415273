import {createContainer} from 'unstated-next';
import {
  BaseUsersByStation,
  MasterCode,
  StationCode,
  StationInitialState,
  TargetingUserCount,
} from '../../types';
import {StationCacheContainer, useShopId} from '../../../../container';
import {useCallback, useRef, useState} from 'react';
import {calcUserCount, isUnderLimit} from '../../commonFunc';
import _ from 'lodash';
import {TargetingContainer} from '../../TargetingContainer';
import {getCategoryCode} from './stationFunc';
import {makeStationFetcher} from '../userFetchFuncs';
import {useAreaContainerBase} from '../../hooks';
import {UseStationCache} from '../../StationCacheContainer';
import {useLimitCaution} from '../LimitCautionDialog';
import {CHOICE_LIMIT} from '../../schema';
import {QueueHelper} from '../../../../helper';

const queue = QueueHelper.getQueue();

// リスト開閉状態（同時に開くのは１カテゴリまで）
type Expand = {
  category?: StationCode;
  trainLine?: StationCode;
};
// メッシュ作業状態管理
type CheckStatusByStation = {[P in MasterCode]?: 'loading' | 'done'};

function makeInitCheckStatusRef(
  byStation: BaseUsersByStation,
): CheckStatusByStation {
  const checked: CheckStatusByStation = {};
  _.forEach(byStation, (__, key) => {
    checked[key] = 'done';
  });
  return checked;
}

// 選択状態の表示用
export type LevelSet = {
  category: Set<StationCode>;
  trainLine: Set<StationCode>;
  station: Set<MasterCode>;
};

type CheckStatus = {
  loading: LevelSet;
  indeterminate: LevelSet;
  checked: LevelSet;
};

function getEmptyLevelSet(): Required<LevelSet> {
  return {
    category: new Set<StationCode>(),
    trainLine: new Set<StationCode>(),
    station: new Set<MasterCode>(),
  };
}

// 毎回１から生成しておりコストが重い。差分だけ更新できるようにしたほうが本来は良い。
function makeCheckStatus(
  byStation: CheckStatusByStation,
  getTrainLineCode: UseStationCache['getTrainLineCode'],
  getTrainLineNoAsync: UseStationCache['getTrainLineNoAsync'],
  getStationNoAsync: UseStationCache['getStationNoAsync'],
): CheckStatus {
  const loading = getEmptyLevelSet();
  const indeterminate = getEmptyLevelSet();
  const checked = getEmptyLevelSet();
  // チェックor半チェック判定用
  const byCategory: {[P in string]: Set<string>} = {};
  const byLine: {[P in string]: Set<string>} = {};

  // 駅コードから鉄道区分、路線の状態に振り分け
  Object.keys(byStation).forEach((code) => {
    const lineCode = getTrainLineCode(code) ?? '';
    if (byStation[code] === 'loading') {
      loading.category.add(getCategoryCode(lineCode));
      loading.trainLine.add(lineCode);
      loading.station.add(code);
    } else if (byStation[code] === 'done') {
      const categoryCode = getCategoryCode(lineCode);
      indeterminate.category.add(categoryCode);
      indeterminate.trainLine.add(lineCode);
      checked.station.add(code);
      byCategory[categoryCode]
        ? byCategory[categoryCode].add(lineCode)
        : (byCategory[categoryCode] = new Set([lineCode]));
      byLine[lineCode]
        ? byLine[lineCode].add(code)
        : (byLine[lineCode] = new Set([code]));
    }
  });
  // 子要素が全てチェックされているかどうか判定する
  Object.entries(byLine).forEach(([lineCode, ids]) => {
    if (loading.trainLine.has(lineCode)) {
      return; // loading状態が含まれれば全チェックはありえない
    }
    const stations = (getStationNoAsync(lineCode) ?? []).map((s) => s.id);
    const intersection = _.intersection(Array.from(ids), stations);
    if (intersection.length === stations.length) {
      checked.trainLine.add(lineCode);
      indeterminate.trainLine.delete(lineCode);
    }
  });
  Object.entries(byCategory).forEach(([categoryCode, ids]) => {
    if (loading.trainLine.has(categoryCode)) {
      return;
    }
    const lines = (getTrainLineNoAsync(categoryCode) ?? []).map((s) => s.id);
    const intersection = _.intersection(Array.from(ids), lines);
    if (intersection.length === lines.length) {
      checked.category.add(categoryCode);
      indeterminate.category.delete(categoryCode);
    }
  });
  return {loading, indeterminate, checked};
}

type UseStationUsers = {
  userCount: TargetingUserCount; // 配信対象人数
  checkStatus: CheckStatus; // チェック状態
  expand: Expand; // 開閉状態
  canConfirm: boolean;
  /**
   * 駅を選択する
   * @param station 選択対象駅コード
   * @param mode 指定しなければチェック状態をトグル。指定があった場合は指定側の遷移しか行わない。
   */
  selectStation(station: string, mode?: 'check' | 'uncheck'): void;
  // 路線一括での選択。全チェックor全チェック解除のみ許容のためmodeの指定が必須。
  selectTrainLine(lineCode: string, mode: 'check' | 'uncheck'): void;
  expandItem(code: StationCode): void;
  confirm(): void;
};

// 駅単位のユーザーデータの処理を行うコンテナ
function useStationUsers(initialState?: StationInitialState): UseStationUsers {
  const {shopId} = useShopId();
  const {attrFilter, otherUsers, otherChoiceCount, userCount, setCount} =
    useAreaContainerBase(initialState);
  const {getTrainLineCode, getStation, getStationNoAsync, getTrainLineNoAsync} =
    StationCacheContainer.useContainer();
  const stationUsersRef = useRef<BaseUsersByStation>(
    initialState?.stationUsers ? _.cloneDeep(initialState.stationUsers) : {},
  );
  // チェック状態の管理
  const checkStatusRef = useRef<CheckStatusByStation>(
    makeInitCheckStatusRef(stationUsersRef.current),
  );
  const [checkStatus, setCheck] = useState<CheckStatus>(
    makeCheckStatus(
      checkStatusRef.current,
      getTrainLineCode,
      getTrainLineNoAsync,
      getStationNoAsync,
    ),
  );
  // チェック状態への反映関数
  const feedbackChecked = useCallback(() => {
    setCheck(
      makeCheckStatus(
        checkStatusRef.current,
        getTrainLineCode,
        getTrainLineNoAsync,
        getStationNoAsync,
      ),
    );
  }, [getStationNoAsync, getTrainLineCode, getTrainLineNoAsync]);

  // 選択数関係
  const getChoiceCountAll = useCallback<() => number>(() => {
    return otherChoiceCount + Object.keys(checkStatusRef.current).length;
  }, [otherChoiceCount]);
  const {showLimitCaution} = useLimitCaution();

  // 開いているリストの管理
  const [expand, setExpand] = useState<Expand>({});
  const expandItem = useCallback<UseStationUsers['expandItem']>(
    (code) => {
      if (code.length <= 2) {
        // 第1階層の開閉
        setExpand(expand.category === code ? {} : {category: code});
      } else {
        // 第2階層の開閉
        if (expand.trainLine === code) {
          setExpand({category: expand.category});
        } else {
          const trainLine = code;
          const category = getCategoryCode(trainLine);
          setExpand({category, trainLine});
        }
      }
    },
    [expand.category, expand.trainLine],
  );

  // 人数計算
  const calcCount = useCallback(() => {
    if (!QueueHelper.isFinish(queue)) {
      return;
    }
    const sum = calcUserCount([stationUsersRef.current], otherUsers);
    if (QueueHelper.isFinish(queue)) {
      setCount(sum);
    }
  }, [otherUsers, setCount]);

  // 駅単位処理（タスクキューに積まれる処理）
  const makeWorker = useCallback<(station: string) => () => Promise<boolean>>(
    (station: string) => {
      return makeStationFetcher(
        [station],
        stationUsersRef.current,
        shopId,
        attrFilter,
      );
    },
    [attrFilter, shopId],
  );

  // 駅の選択
  const selectStation = useCallback<UseStationUsers['selectStation']>(
    (station, mode) => {
      if (checkStatusRef.current[station] === 'loading') {
        // ロード中はSKIP
        return;
      }
      if (checkStatusRef.current[station] === 'done') {
        if (mode === 'check') {
          return;
        }
        // 選択解除処理
        delete checkStatusRef.current[station];
        delete stationUsersRef.current[station];
        feedbackChecked();
        calcCount();
        return;
      }
      if (mode === 'uncheck') {
        return;
      }
      if (getChoiceCountAll() >= CHOICE_LIMIT) {
        // 上限を超えるので、ダイアログ出して終了
        showLimitCaution();
        return;
      }
      // 選択処理
      checkStatusRef.current[station] = 'loading';
      // feedbackCheckedが連続して呼ばれるとUXがブロックするくらい重くなるので、
      // 路線一括指定で使う想定であるmodeが指定されている場合は呼ばないようにしておく。
      !mode && feedbackChecked();
      setCount('calculating');
      queue.add(makeWorker(station)).then((success) => {
        if (success) {
          checkStatusRef.current[station] = 'done';
          feedbackChecked();
        } else {
          delete checkStatusRef.current[station];
          feedbackChecked();
        }
        calcCount();
      });
    },
    [
      calcCount,
      feedbackChecked,
      getChoiceCountAll,
      makeWorker,
      setCount,
      showLimitCaution,
    ],
  );

  const selectTrainLine = useCallback<UseStationUsers['selectTrainLine']>(
    (lineCode, mode) => {
      getStation(lineCode).then((stations) => {
        if (mode === 'uncheck') {
          // selectStation を連続して呼び出す形だと途中で人数計算を毎回挟んですごく重くなるので、先にまとめて解除
          stations.forEach((s) => {
            delete checkStatusRef.current[s.id];
            delete stationUsersRef.current[s.id];
          });
          feedbackChecked();
          calcCount();
        } else if (mode === 'check') {
          stations.forEach((s) => selectStation(s.id, mode));
          feedbackChecked();
        }
      });
    },
    [calcCount, feedbackChecked, getStation, selectStation],
  );

  // 完了処理関係
  const canConfirm =
    !isUnderLimit(userCount) &&
    Object.keys(stationUsersRef.current).length !== 0;
  const {confirmStation} = TargetingContainer.useContainer();
  const confirm = useCallback(() => {
    confirmStation(stationUsersRef.current, userCount);
  }, [confirmStation, userCount]);

  return {
    userCount,
    checkStatus,
    expand,
    canConfirm,
    confirm,
    selectStation,
    selectTrainLine,
    expandItem,
  };
}

export const StationUsersContainer = createContainer(useStationUsers);
