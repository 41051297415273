import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSBillingRepository} from '../_proto/services/SaaSBillingRepository';

const useStripeContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [upcomingInvoice, setUpcomingInvoice] = useState<any>();
  const [invoices, setInvoices] = useState<any>();

  const getUpcomingInvoice = useCallback(
    async (data: {customerId: string; subscriptionId: string}) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        const res = await SaaSBillingRepository.retrieveUpcomingInvoice(data);
        setUpcomingInvoice(res);
        return res;
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [],
  );

  const getListInvoices = useCallback(
    async (data: {customerId: string; limit: number}) => {
      setCreateLoading(true);
      setError(undefined);
      try {
        const res = await SaaSBillingRepository.getListInvoices(data);
        setInvoices(res);
        return res;
      } catch (err: any) {
        setError(err);
        throw err;
      } finally {
        setCreateLoading(false);
      }
    },
    [],
  );

  return {
    isCreateLoading,
    error,
    upcomingInvoice,
    getUpcomingInvoice,
    invoices,
    getListInvoices,
  };
};

export const StripeContainer = createContainer(useStripeContainer);
