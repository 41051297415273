import {API, graphqlOperation} from 'aws-amplify';
import _ from 'lodash';
import {RangeOptionDto, UserOptionDto} from '../UserPropHelper';

const TAG = 'CountByUsrAttrRunner';

const filterOfGender = (val: string) => {
  return {gender: {eq: val}};
};

const filterOfMarried = (val: boolean) => {
  return {married: {eq: val}};
};

const filterOfAge = (val: RangeOptionDto) => {
  return {and: [{age: {gte: val.lower}}, {age: {lt: val.upper}}]};
};

const filterOfResidence = (vals: string[]) => {
  const ret: {or: Record<string, unknown>[]} = {or: []};

  vals.map((val) => ret.or.push({residence: {eq: val}}));
  return ret;
};

const filterOfMovingMethod = (vals: string[]) => {
  const ret: {or: Record<string, unknown>[]} = {or: []};

  vals.map((val) => ret.or.push({movingMethod: {eq: val}}));
  return ret;
};

const filterOfChildrenTogether = (val: number) => {
  return val === 0
    ? {childrenTogether: {eq: val}}
    : {childrenTogether: {gte: val}};
};

const filterOfIncome = (val: RangeOptionDto) => {
  return {
    and: [{income: {gte: val.lower}}, {income: {lt: val.upper}}],
  };
};

const filterOfHouseholdIncome = (val: RangeOptionDto) => {
  return {
    and: [
      {householdIncome: {gte: val.lower}},
      {householdIncome: {lt: val.upper}},
    ],
  };
};

const filterOfFamilyTogether = (val: number) => {
  return val === 0 ? {familyTogether: {eq: val}} : {familyTogether: {gte: val}};
};

const filterOfAddress = (vals: number[]) => {
  const ret: {or: Record<string, unknown>[]} = {or: []};

  vals.map((val) => ret.or.push({address: {eq: val}}));
  return ret;
};

const filterOfPrefCity = (prefs?: string[], cities?: string[]) => {
  const ret: {or: Record<string, unknown>[]} = {or: []};

  prefs?.map((pref) => ret.or.push({prefCode: {eq: pref}}));
  cities?.map((city) => ret.or.push({cityCode: {eq: city}}));
  return ret;
};

const filterOfJob = (vals: number[]) => {
  const ret: {or: Record<string, unknown>[]} = {or: []};

  vals.map((val) => ret.or.push({job: {eq: val}}));
  return ret;
};

const filterOfInterest = (vals: number[]) => {
  const ret: {or: Record<string, unknown>[]} = {or: []};

  vals.map((val) => ret.or.push({interest: {eq: val}}));
  return ret;
};

const filterOfChildAge = (val: RangeOptionDto) => {
  if (val.lower >= val.upper) {
    // array の [>=lower, <upper] もヒットするらしい、不自然な結果を回避する
    return {childAge: {eq: -1}};
  }

  return {
    and: [{childAge: {gte: val.lower}}, {childAge: {lt: val.upper}}],
  };
};

export class CountByUsrAttrRunner {
  constructor(private readonly option: UserOptionDto) {
    console.log(TAG, this.option);
  }

  async run(): Promise<number | undefined> {
    // 1. translate to graphql
    const filter = this.buildFilter();
    // 2. run it

    const searchSaaSUsers = /* GraphQL */ `
      query SearchSaaSUsers(
        $filter: SearchableSaaSUserFilterInput
        $aggregates: [SearchableSaaSUserAggregationInput]
      ) {
        searchSaaSUsers(filter: $filter, aggregates: $aggregates) {
          aggregateItems {
            name
            result {
              ... on SearchableAggregateBucketResult {
                buckets {
                  key
                  doc_count
                }
              }
            }
          }
        }
      }
    `;
    console.log(TAG, 'cbuar-run');
    try {
      const result: any = await API.graphql(
        graphqlOperation(searchSaaSUsers, {
          filter,
          aggregates: [{name: 'total', type: 'terms', field: 'gender'}],
        }),
      );

      // 3. return result
      const inner =
        result &&
        result.data &&
        result.data.searchSaaSUsers &&
        result.data.searchSaaSUsers.aggregateItems &&
        result.data.searchSaaSUsers.aggregateItems.length &&
        result.data.searchSaaSUsers.aggregateItems[0].result &&
        result.data.searchSaaSUsers.aggregateItems[0].result.buckets;

      // console.log(TAG, result, inner);
      return inner && _.sumBy(inner, 'doc_count');
    } catch (err: any) {
      console.log(TAG, 'err', err);
    }
    return;
  }

  // --------------- private -------------
  private buildFilter(): unknown {
    if (_.isEmpty(this.option)) {
      return undefined;
    }

    const ret: Record<string, unknown> = {};

    this.option.gender &&
      Object.assign(ret, filterOfGender(this.option.gender));
    typeof this.option.married === 'boolean' &&
      Object.assign(ret, filterOfMarried(this.option.married));
    typeof this.option.childrenTogether === 'number' &&
      Object.assign(
        ret,
        filterOfChildrenTogether(this.option.childrenTogether),
      );
    typeof this.option.familyTogether === 'number' &&
      Object.assign(ret, filterOfFamilyTogether(this.option.familyTogether));

    const and: unknown[] = [];

    this.option.ageRange && and.push(filterOfAge(this.option.ageRange));

    typeof this.option.childrenTogether === 'number' &&
      this.option.childrenTogether > 0 &&
      this.option.childAge &&
      and.push(filterOfChildAge(this.option.childAge));

    this.option.incomeRange &&
      and.push(filterOfIncome(this.option.incomeRange));
    this.option.householdIncomeRange &&
      and.push(filterOfHouseholdIncome(this.option.householdIncomeRange));
    this.option.residence && and.push(filterOfResidence(this.option.residence));
    this.option.movingMethod &&
      and.push(filterOfMovingMethod(this.option.movingMethod));
    this.option.address && and.push(filterOfAddress(this.option.address));
    this.option.job && and.push(filterOfJob(this.option.job));
    this.option.interest && and.push(filterOfInterest(this.option.interest));
    (this.option.prefCode || this.option.cityCode) &&
      and.push(filterOfPrefCity(this.option.prefCode, this.option.cityCode));

    and.length && (ret.and = and);

    console.log(TAG, 'bf', JSON.stringify(ret, null, 2));
    return ret;
  }
}
