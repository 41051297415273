import {LoadingButton} from '@mui/lab';
import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {AccessManageRole} from '../../API';
import {SaaSBillingRepository} from '../../_proto/services/SaaSBillingRepository';
import {
  Container,
  FormBlock,
  HelperText,
  MaxWidth,
  Menu,
  RequiredNotice,
  Submenu,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../../components/Elements';
import {AddressPickerContainer} from '../../components/PlacePicker';
import {
  OwnerFormData,
  ownerFormInfos,
  schemaOwnerForm,
} from '../../components/Register/ownerSchema';
import {
  AccessRoleContainer,
  OwnerContainer,
  PaymentInfoContainer,
} from '../../container';
import {useCustomNav} from '../../helper';
import {CommonStyles} from '../../theme';

const NameForms: React.FC<{
  label: string;
  family: keyof typeof ownerFormInfos;
  first: keyof typeof ownerFormInfos;
}> = ({label, family, first}) => {
  return (
    <View>
      <View style={[CommonStyles.flex.row, CommonStyles.flex.crossCenter]}>
        <Text>{label}</Text>
        <RequiredNotice />
      </View>
      <View style={CommonStyles.flex.row}>
        <View style={CommonStyles.flex.full}>
          <VForm.Text<OwnerFormData> {...ownerFormInfos[family]} />
        </View>
        <View style={CommonStyles.margin.left} />
        <View style={CommonStyles.flex.full}>
          <VForm.Text<OwnerFormData> {...ownerFormInfos[first]} />
        </View>
      </View>
    </View>
  );
};

const Submit: React.FC = () => {
  const {owner, update} = OwnerContainer.useContainer();
  const {paymentInfo} = PaymentInfoContainer.useContainer();
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    handleSubmit,
    formState: {isValid},
    reset,
  } = useFormContext<OwnerFormData>();
  const navigation = useNavigation();

  useEffect(() => {
    const {
      address,
      charge,
      chargeDept,
      chargeKana,
      chargePhone,
      hojin,
      hojinKana,
      url,
      representative,
      representativeKana,
    } = owner as any;
    reset({
      hojinName: hojin,
      hojinYomi: hojinKana,
      representativeNameFamily: representative.last,
      representativeNameFirst: representative.first,
      representativeYomiFamily: representativeKana.last,
      representativeYomiFirst: representativeKana.first,
      zip: address.zip,
      pref: address.pref,
      city: address.city,
      detail: address.detail,
      building: address.building ? address.building : '',
      url: url ? url : null,
      chargeDept: chargeDept ? chargeDept : '',
      chargeNameFamily: charge.last,
      chargeNameFirst: charge.first,
      chargeYomiFamily: chargeKana.last,
      chargeYomiFirst: chargeKana.first,
      chargePhone,
    }); // asynchronously reset your form values
  }, [reset, owner]);

  const register = async (formData: OwnerFormData, navigation: any) => {
    setLoading(true);
    try {
      if (!owner) {
        throw new Error('owner is null or undefined');
      }
      const ownerInfo = await update({
        id: owner.id,
        hojin: formData.hojinName,
        hojinKana: formData.hojinYomi,
        representative: {
          first: formData.representativeNameFirst,
          last: formData.representativeNameFamily,
        },
        representativeKana: {
          first: formData.representativeYomiFirst,
          last: formData.representativeYomiFamily,
        },
        address: {
          zip: formData.zip,
          pref: formData.pref,
          city: formData.city,
          detail: formData.detail,
          building: formData.building,
        },
        url: formData.url ?? null,
        chargeDept: formData.chargeDept ?? null,
        charge: {
          first: formData.chargeNameFirst,
          last: formData.chargeNameFamily,
        },
        chargeKana: {
          first: formData.chargeYomiFirst,
          last: formData.chargeYomiFamily,
        },
        // Todo 正規化？
        chargePhone: formData.chargePhone,
      });
      const data = {
        customerId: paymentInfo.customerId,
        hojin: formData.hojinName,
        address: {
          zip: formData.zip,
          pref: formData.pref,
          city: formData.city,
          detail: formData.detail,
          building: formData.building,
        },
      };
      await SaaSBillingRepository.updateCustomer(data);
      console.log('[OwnerRegister] submit convert', ownerInfo);
      navigation.navigate('SettingsMain');
    } catch (error: any) {
      console.log('[OwnerRegister] submit error', error);
      setErrorMsg(error.message);
    } finally {
      setLoading(false);
    }
  };
  const helper = !isValid ? '必要項目の入力をお願いします' : errorMsg;

  return (
    <View>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit((data) => register(data, navigation))}
        disabled={!isValid || isLoading}
        loading={isLoading}
        loadingPosition="start">
        企業情報の保存
      </TrimaLoadingButton>
      <HelperText type="error">{helper}</HelperText>
    </View>
  );
};

const AddressForm: React.FC = () => {
  const {searchByZip, searching} = AddressPickerContainer.useContainer();
  return (
    <View>
      <Submenu>所在地</Submenu>
      <View style={CommonStyles.flex.row}>
        <View style={CommonStyles.flex.full}>
          <VForm.Text<OwnerFormData> {...ownerFormInfos.zip} />
        </View>
        <LoadingButton
          sx={{
            height: 40,
            width: 100,
            marginTop: '28px',
            marginLeft: 2,
            borderRadius: 2,
          }}
          variant="contained"
          onClick={searchByZip}
          disabled={searching}
          loading={searching}>
          {searching ? '' : '自動入力'}
        </LoadingButton>
      </View>
      <VForm.Text<OwnerFormData> {...ownerFormInfos.pref} editable={false} />
      <VForm.Text<OwnerFormData> {...ownerFormInfos.city} editable={false} />
      <VForm.Text<OwnerFormData> {...ownerFormInfos.detail} />
      <VForm.Text<OwnerFormData> {...ownerFormInfos.building} />
    </View>
  );
};

const ChangeOwnerComponent: React.FC = () => {
  const navigator = useCustomNav();
  const goBack = () => {
    navigator.goBack('SettingsMain');
  };
  const {owner} = OwnerContainer.useContainer();

  return (
    <Container>
      <MaxWidth maxWidth={1008} style={CommonStyles.margin.all}>
        <Menu>企業情報の変更</Menu>
        <VMargin />
        <View style={CommonStyles.flex.full}>
          {!owner ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <FormBlock>
                <MaxWidth maxWidth={586}>
                  <VForm.Provider<OwnerFormData> schema={schemaOwnerForm}>
                    <VForm.Text<OwnerFormData> {...ownerFormInfos.hojinName} />
                    <VForm.Text<OwnerFormData> {...ownerFormInfos.hojinYomi} />
                    <NameForms
                      label="代表者の氏名"
                      family="representativeNameFamily"
                      first="representativeNameFirst"
                    />
                    <NameForms
                      label="代表者の氏名（カナ）"
                      family="representativeYomiFamily"
                      first="representativeYomiFirst"
                    />
                    <AddressPickerContainer.Provider>
                      <AddressForm />
                    </AddressPickerContainer.Provider>
                    <VForm.Text<OwnerFormData> {...ownerFormInfos.url} />
                    <VForm.Text<OwnerFormData> {...ownerFormInfos.chargeDept} />
                    <NameForms
                      label="担当者名"
                      family="chargeNameFamily"
                      first="chargeNameFirst"
                    />
                    <NameForms
                      label="担当者名（カナ）"
                      family="chargeYomiFamily"
                      first="chargeYomiFirst"
                    />
                    <VForm.Text<OwnerFormData>
                      {...ownerFormInfos.chargePhone}
                    />
                    <View style={CommonStyles.margin.bottom} />
                    <Submit />
                  </VForm.Provider>
                  <VMargin />
                  <TrimaButton variant="outlined" onClick={() => goBack()}>
                    キャンセル
                  </TrimaButton>
                </MaxWidth>
              </FormBlock>
            </View>
          )}
        </View>
      </MaxWidth>
    </Container>
  );
};

export const ChangeOwner: React.FC = () => {
  const {accessRole} = AccessRoleContainer.useContainer();
  const navigation = useNavigation();

  useEffect(() => {
    console.log(accessRole);
    if (accessRole !== AccessManageRole.OWNER) {
      navigation.navigate('DashBoard');
    }
  }, [accessRole, navigation]);

  return <ChangeOwnerComponent />;
};
