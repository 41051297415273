import {MeshCode} from '../../types';
import {MeshRepository} from '../../../../_proto/services/MeshRepositoy';
import pointToLineDistance from '@turf/point-to-line-distance';
import {lineString} from '@turf/helpers';

export function getMeshCode(bounds: google.maps.LatLng): string {
  const param = {lng: bounds.lng(), lat: bounds.lat()};
  return MeshRepository.lnglat2Mesh(param, 4);
}
// 4次メッシュのメッシュ幅
const LNG_DIFF = 22.5 / 3600;
const LAT_DIFF = 15 / 3600;

// メッシュと特定点との距離。4辺と点の距離で算出
function getDistance(code: MeshCode, point: number[]): number {
  const b = MeshRepository.boundOfMesh(code);
  const se = [b.east, b.south];
  const sw = [b.west, b.south];
  const ne = [b.east, b.north];
  const nw = [b.west, b.north];
  return Math.min(
    ...[
      [se, sw],
      [ne, nw],
      [se, ne],
      [ne, nw],
    ].map((line) => pointToLineDistance(point, lineString(line))),
  );
}

export function calcMeshesForRadius(
  radius: number,
  center?: google.maps.LatLng,
): MeshCode[] {
  if (!center) {
    return [];
  }
  const diameterMeshRange = radius * 2 + 5; // 少し広めに取っておく
  // centerと半径から、選択対象のメッシュリスト（格子上）をまずは算出
  const rangeBase = [...Array(diameterMeshRange * 2 + 1)].map(
    (_, index) => index - diameterMeshRange,
  );
  const lngRange = rangeBase.map((v) => center.lng() + LNG_DIFF * v);
  const latRange = rangeBase.map((v) => center.lat() + LAT_DIFF * v);
  const meshList: MeshCode[] = [];
  lngRange.forEach((lng) =>
    latRange.forEach((lat) =>
      meshList.push(MeshRepository.lnglat2Mesh({lng, lat}, 4)),
    ),
  );

  // 各メッシュコード範囲が中心から半径距離以内にあれば採用
  const point = [center.lng(), center.lat()];
  return meshList.filter((code) => {
    return getDistance(code, point) < radius;
  });
}
