import {Dayjs} from 'dayjs';
import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import HyperLink from 'react-native-hyperlink';
import SVG, {Line} from 'react-native-svg';
import Swiper from 'react-native-web-swiper';
import {CouponUsage} from '../../API';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import noImage from '../../assets/no-image.png';
import {Shop, shopCategoryLabels} from '../../container';
import {Helpers} from '../../helper';
import {Colors, CommonStyles, trimaColor} from '../../theme';
import {AppText, Text} from '../Elements';
import {useResponsive} from '../Responsive';
import {DiscountLabel} from './DiscountLabel';
import {
  CvLinkDummyButton,
  PhoneDummyButton,
  PrimaryDummyButton,
} from './PrimaryDummyButton';
import {SmallDummyButtonMile} from './SmallDummyButton';
import {CouponInfo} from './types';

// 一覧表示/詳細画面で共通のクーポンヘッダー部分（店舗情報の表示）
export const Header: React.FC<{shop: Shop}> = ({shop}) => {
  const {isMobile} = useResponsive();
  return (
    <View
      style={
        isMobile
          ? [styles.header, styles.xsWidth]
          : [styles.header, styles.desktopWidth]
      }>
      <View style={styles.avatar}>
        <Image source={{uri: shop.icon}} style={styles.icon} />
      </View>
      <View style={styles.shopInfo}>
        <View style={styles.shopNameContainer}>
          <AppText style={styles.shopName} numberOfLines={1}>
            {shop.name}
          </AppText>
        </View>
        <AppText style={styles.shopCaption} numberOfLines={1}>{`${
          shopCategoryLabels[shop.category]
        } ${shop.pref && '|'} ${shop.pref}${shop.city}`}</AppText>
      </View>
      <InverseRadius isTop={false} />
    </View>
  );
};

// 一覧用画像
export const ImageMini = React.memo<{image?: SaaSImageContents}>(({image}) => {
  const source = image ? {uri: image.uri} : {uri: noImage};
  return (
    <View style={styles.imageMini}>
      <Image style={thumbnailSize} source={source} />
    </View>
  );
});

// 詳細画面用の画像表示
const DummyImages: SaaSImageContents[] = [{uri: noImage, key: 'no_image'}];
export const ImageSwiper = React.memo<{images?: SaaSImageContents[]}>(
  ({images}) => {
    const {isMobile} = useResponsive();
    const imageList = images && images.length ? images : DummyImages;
    // keyを変えてやらないとSwiperの更新がされないっぽいのでkeyを生成して渡す
    const swiperKey = new Date().getTime();
    const swappable = imageList.length > 1;
    return (
      <View
        style={
          isMobile
            ? styles.imageSwiperContentMobile
            : styles.imageSwiperContentDesktop
        }>
        <Swiper
          key={swiperKey}
          loop
          controlsEnabled={swappable}
          gesturesEnabled={() => swappable}
          controlsProps={{
            nextTitle: '',
            prevTitle: '',
            dotActiveStyle: styles.swiperDotActive,
          }}>
          {imageList.map((image) => (
            <Image
              style={
                isMobile ? styles.imageSwiperMobile : styles.imageSwiperDesktop
              }
              source={{uri: image.uri}}
              key={image.key}
            />
          ))}
        </Swiper>
      </View>
    );
  },
);

// 説明テキスト
const CouponText = React.memo<{text?: string}>(({text}) => {
  if (!text) {
    return null;
  }
  return (
    <View style={styles.marginTop}>
      <HyperLink
        linkStyle={{color: trimaColor.accent}}
        onPress={(url) => Helpers.openURL(url)}>
        <AppText style={styles.couponText}>{text}</AppText>
      </HyperLink>
    </View>
  );
});

// 利用条件
const CouponRule = React.memo<{rule?: string}>(({rule}) => {
  if (!rule) {
    return null;
  }
  return (
    <View style={styles.marginTop}>
      <HyperLink
        linkStyle={{color: trimaColor.accent}}
        onPress={(url) => Helpers.openURL(url)}>
        <AppText style={styles.couponRule}>{rule}</AppText>
      </HyperLink>
    </View>
  );
});

// 配信期限表示
const Limit = React.memo<{end?: Dayjs}>(({end}) => {
  return (
    <View style={styles.limitContainer}>
      <Text style={styles.limitText}>{`利用期限：${
        end ? end.format('YYYY/MM/DD') : '****/**/**'
      }`}</Text>
      <Text style={styles.limitText}>{` ${
        end ? end.format('HH:mm') : '**:**'
      }`}</Text>
    </View>
  );
});

const ButtonFooter = React.memo(() => {
  return (
    <View style={styles.buttonFooter}>
      <Text style={styles.buttonFooterText}>
        店舗でクーポンを利用してからタップしてください
      </Text>
    </View>
  );
});

// 逆丸の切り抜きを行うためのパーツ
const inverseRadiusSize = 6;
const dotHeight = 4;
export const InverseRadius = React.memo<{isTop: boolean}>(({isTop}) => {
  const {isMobile} = useResponsive();
  const vertical = isTop
    ? {top: -inverseRadiusSize}
    : {bottom: -inverseRadiusSize};
  return (
    <View style={[styles.inverseContainer, vertical]}>
      <View style={styles.circle} />
      <View style={styles.circle} />
      <View style={styles.dotBorder}>
        {/*上位要素で overflow: hidden でカットする前提で、長めの couponWidth で描画*/}
        <SVG width={isMobile ? couponWidth : 343} height={dotHeight}>
          <Line
            strokeLinecap="round"
            strokeDasharray={`0, ${dotHeight * 2}`} // 最初が0以外は楕円になる
            strokeDashoffset={dotHeight / 2} // 半円から始まらないように半分ずらす
            x1={0}
            x2={isMobile ? couponWidth : 343}
            y1={dotHeight / 2} // 上下中央寄せ
            y2={dotHeight / 2} // 上下中央寄せ
            stroke={trimaColor.background}
            strokeWidth={dotHeight}
          />
        </SVG>
      </View>
    </View>
  );
});

// 下部にマイル獲得の表示をおく
export const SummaryBase: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  return (
    <View style={[styles.bodyCommon, styles.summary]}>
      {children}
      <View style={styles.summaryCaption}>
        <SmallDummyButtonMile width={220} />
      </View>
      <InverseRadius isTop={true} />
    </View>
  );
};

// 一覧画面でのクーポン内容表示
export const Summary: React.FC<{
  coupon: CouponInfo;
  end?: Dayjs;
}> = ({coupon, end}) => {
  return (
    <SummaryBase>
      <View style={styles.summaryMain}>
        <ImageMini image={coupon.images[0]} />
        <View style={styles.summaryInfo}>
          <View style={styles.summaryInfo1}>
            <Text style={styles.couponNameMini} numberOfLines={2}>
              {coupon.name}
            </Text>
          </View>
          <View style={styles.summaryInfo2}>
            <DiscountLabel discount={coupon.discount} isSmall />
          </View>
          <Limit end={end} />
        </View>
      </View>
    </SummaryBase>
  );
};

export const Detail: React.FC<{
  coupon: CouponInfo;
  end?: Dayjs;
}> = ({coupon, end}) => {
  const {isMobile} = useResponsive();
  return (
    <View
      style={
        isMobile
          ? [styles.bodyCommon, styles.detail, styles.xsWidth]
          : [styles.bodyCommon, styles.detail, styles.desktopWidth]
      }>
      <ImageSwiper images={coupon.images} />
      <View style={styles.marginTop}>
        <AppText style={styles.couponNameBig} numberOfLines={2}>
          {coupon.name}
        </AppText>
      </View>
      <View style={CommonStyles.margin.top}>
        <DiscountLabel discount={coupon.discount} />
      </View>
      <CouponText text={coupon.text} />
      <CouponRule rule={coupon.rule} />
      <View style={styles.marginTop}>
        <Limit end={end} />
      </View>
      {coupon.cvLink?.url && (
        <View style={styles.marginTop}>
          <CvLinkDummyButton
            text={coupon.cvLink?.text}
            url={coupon.cvLink?.url}
          />
        </View>
      )}
      {coupon.cvPhone && (
        <View style={styles.marginTop}>
          <PhoneDummyButton phone={coupon.cvPhone} />
        </View>
      )}
      {coupon.barcode && (
        <View style={styles.marginTop}>
          <View
            style={
              isMobile ? styles.imageSwiperMobile : styles.imageSwiperDesktop
            }>
            <Image
              style={
                isMobile ? styles.imageSwiperMobile : styles.imageSwiperDesktop
              }
              source={{uri: coupon.barcode}}
            />
          </View>
        </View>
      )}
      {coupon.usage && coupon.usage === CouponUsage.ONETIME && (
        <View style={styles.marginTop}>
          <PrimaryDummyButton>使用済みに変更</PrimaryDummyButton>
          <ButtonFooter />
        </View>
      )}
      <InverseRadius isTop={true} />
    </View>
  );
};

const appleFontRatio = 0.935;
const couponWidth = 288; // W320端末（サイドマージン16*2分減算）の想定サイズ
const couponRadius = 16;
const iconSize = 40;
const thumbnailSize = {width: 120, height: 90};
const barcodeSize = {width: 256, height: 192};
const margin = 16;
const marginMiddle = 8;
const swipeImageWidth = couponWidth - margin * 2;
const swipeImageHeight = (swipeImageWidth * 3) / 4;
const swipeImageDesktopWidth = 358 - margin * 2;
const swipeImageDesktopHeight = (swipeImageDesktopWidth * 3) / 4;
const styles = StyleSheet.create({
  desktopWidth: {
    width: 390 - 16 * 2,
  },
  xsWidth: {
    maxWidth: 288,
  },
  marginTop: {
    marginTop: margin,
  },
  // ヘッダー関連
  header: {
    height: 72,
    backgroundColor: '#F3F3F3',
    paddingHorizontal: margin,
    flexDirection: 'row',
    alignItems: 'center',
    borderTopLeftRadius: couponRadius,
    borderTopRightRadius: couponRadius,
    overflow: 'hidden',
  },
  avatar: {
    width: iconSize,
    height: iconSize,
    borderRadius: iconSize / 2,
    overflow: 'hidden',
  },
  icon: {
    width: iconSize,
    height: iconSize,
  },
  shopInfo: {
    height: iconSize,
    marginLeft: marginMiddle,
    flex: 1,
    justifyContent: 'flex-end',
  },
  shopNameContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  shopName: {
    fontSize: 14 * appleFontRatio,
    lineHeight: 16,
  },
  shopCaption: {
    fontSize: 10 * appleFontRatio,
    lineHeight: 12,
    marginBottom: 2,
  },
  // クーポンボディ関連
  bodyCommon: {
    backgroundColor: Colors.lightgray,
    borderBottomLeftRadius: couponRadius,
    borderBottomRightRadius: couponRadius,
  },
  summary: {
    height: 170,
    justifyContent: 'space-between',
    alignItems: 'stretch',
    paddingTop: margin,
    paddingHorizontal: margin,
    paddingBottom: 6, // 下部のマイル表示が規定マージンより下部にある
  },
  detail: {
    alignItems: 'stretch',
    padding: margin,
  },
  // サマリの主要部分
  summaryMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  // サマリのマイル獲得表示部分
  summaryCaption: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mileCaption: {
    fontSize: 10,
    lineHeight: 12,
    fontWeight: 'normal',
  },
  // サマリの文字情報エリア
  summaryInfo: {
    marginLeft: marginMiddle,
    flex: 1,
    alignItems: 'stretch',
  },
  summaryInfo1: {
    height: 39,
    marginRight: -4,
  },
  couponNameMini: {
    fontSize: 14,
    lineHeight: 19,
  },
  couponNameBig: {
    fontSize: 20 * appleFontRatio,
    lineHeight: 25,
  },
  summaryInfo2: {
    height: 32,
    marginVertical: 2,
  },
  couponText: {
    fontSize: 16 * appleFontRatio,
    lineHeight: 20,
  },
  couponRule: {
    fontSize: 12 * appleFontRatio,
    lineHeight: 16,
  },
  limitContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  limitText: {
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 16,
  },
  buttonFooter: {
    marginTop: 6,
    marginHorizontal: 40,
  },
  buttonFooterText: {
    fontSize: 12,
    lineHeight: 16,
    textAlign: 'center',
  },
  // 画像表示関係
  imageMini: {
    ...thumbnailSize,
    overflow: 'hidden',
  },
  imageBarcode: {
    ...barcodeSize,
    overflow: 'hidden',
  },
  imageSwiperMobile: {
    overflow: 'hidden',
    width: swipeImageWidth,
    height: swipeImageHeight,
  },
  imageSwiperContentMobile: {
    overflow: 'hidden',
    width: swipeImageWidth,
    // swiperのdots位置調整
    height: swipeImageHeight + 30,
  },
  imageSwiperDesktop: {
    overflow: 'hidden',
    width: swipeImageDesktopWidth,
    height: swipeImageDesktopHeight,
  },
  imageSwiperContentDesktop: {
    overflow: 'hidden',
    width: swipeImageDesktopWidth,
    // swiperのdots位置調整
    height: swipeImageDesktopHeight + 30,
  },
  swiperDotActive: {
    backgroundColor: trimaColor.main,
  },
  // 逆丸切り抜き表示関係
  inverseContainer: {
    position: 'absolute',
    left: -inverseRadiusSize,
    right: -inverseRadiusSize,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: '#F3F3F3',
  },
  circle: {
    width: inverseRadiusSize * 2,
    height: inverseRadiusSize * 2,
    borderRadius: inverseRadiusSize,
    backgroundColor: trimaColor.background,
  },
  dotBorder: {
    position: 'absolute',
    top: inverseRadiusSize / 2 + 1,
    bottom: (inverseRadiusSize + 4) / 2 + 1,
    left: inverseRadiusSize * 2 + 10,
    right: inverseRadiusSize * 2 + 10,
    height: dotHeight,
    overflow: 'hidden',
  },
});
