import {faBookmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Colors, CommonStyles, trimaColor} from '../../theme';
import {Text} from '../Elements';
import {Mile} from './Mile';

/**
 * トリマアプリ側のスワイプボタンの擬似再現コンポーネント（ボタン機能はない）
 */
export const AppBookmarkDummyButton = React.memo(() => {
  const buttonWidth = {width: 94};
  return (
    <View style={[styles.container, buttonWidth]}>
      <View style={styles.shadow} />
      <View style={[styles.body, CommonStyles.flex.row]}>
        <FontAwesomeIcon
          style={{color: trimaColor.accent, marginRight: 8}}
          icon={faBookmark}
        />
        <Text style={styles.label}>保存済み</Text>
      </View>
    </View>
  );
});

export const SmallDummyButtonMile = React.memo<{
  width: number;
}>(({width}) => {
  const buttonWidth = {width};
  return (
    <View style={[styles.container, buttonWidth]}>
      <View style={styles.shadow} />
      <View style={[styles.body, CommonStyles.flex.row]}>
        <Text style={styles.label}>{'詳細を見て '}</Text>
        <Mile />
        <Text style={styles.label}>{' 50 獲得'}</Text>
      </View>
    </View>
  );
});

const buttonBaseHeight = 36;
const buttonShadowHeight = 4;
const buttonHeight = buttonBaseHeight + buttonShadowHeight;
const buttonBorderWidth = 1;
const styles = StyleSheet.create({
  container: {
    height: buttonHeight,
  },
  shadow: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonHeight,
    borderRadius: buttonHeight / 2,
    backgroundColor: trimaColor.shadow,
  },
  body: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: buttonBaseHeight,
    borderWidth: buttonBorderWidth,
    borderRadius: buttonHeight / 2,
    borderColor: trimaColor.shadow,
    backgroundColor: Colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    fontSize: 12,
    fontWeight: 'normal',
    color: trimaColor.accent,
  },
});
