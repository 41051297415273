import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {SaaSDeliveryTarget} from '../../API';
import {BRAKE_POINT} from '../../components';
import {
  BackLink,
  Container,
  FormBlock,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  VMargin,
} from '../../components/Elements';
import {TargetsPreview} from '../../components/TargetsPreview';
import {MainNavigationProp, MainRouteProp} from '../../navigation/MainScreen';
import {graphQLService} from '../../service';
import {CommonStyles, Message} from '../../theme';

type NProp = MainNavigationProp<'TargetsDetail'>;
type RProp = MainRouteProp<'TargetsDetail'>;

export const Detail: React.FC = React.memo(() => {
  const [target, setTarget] = React.useState<SaaSDeliveryTarget | undefined>();
  const [error, setError] = React.useState<Error | undefined>();
  const navigation = useNavigation<NProp>();
  const route = useRoute<RProp>();
  const id = route.params?.id;
  const used = route.params?.used;
  const gotoCreative = () =>
    navigation.navigate('CreativesCreate', {targetId: id});
  const gotoEdit = () =>
    navigation.navigate('TargetsEdit', {id: id, from: 'TargetsDetail'});
  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('TargetsMain');
    }
  };

  React.useEffect(() => {
    if (id) {
      graphQLService.getTarget(id).then(setTarget).catch(setError);
    } else {
      setError(Error('対象IDが指定されていません'));
    }
  }, [id]);

  // エラー時
  if (error) {
    return (
      <View>
        <Text>エラーが発生しました</Text>
        <Text>{error.message}</Text>
      </View>
    );
  }

  // 取得前
  if (!target) {
    return <ActivityIndicator size={40} />;
  }

  return (
    <Container>
      <MaxWidth maxWidth={BRAKE_POINT.desktop} style={CommonStyles.margin.all}>
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label={Message.BackToList} onClick={goBack} />
        </Box>
        <Menu>{target.title}</Menu>
        <VMargin />
        <FormBlock>
          <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
            <TargetsPreview.Area target={target} />
            <VMargin />
            <TargetsPreview.Attribute target={target} />
            <VMargin />
            <TargetsPreview.Summary target={target} noTitle />
            <VMargin />
          </MaxWidth>
        </FormBlock>
        <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
          <VMargin />
          {(!used || used === 'false') && (
            <View>
              <TrimaButton variant="contained" onClick={gotoEdit}>
                配信先の編集
              </TrimaButton>
              <VMargin />
            </View>
          )}
          <TrimaButton variant="contained" onClick={gotoCreative}>
            配信する
          </TrimaButton>
        </MaxWidth>
      </MaxWidth>
    </Container>
  );
});
