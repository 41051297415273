export default {
  // size
  caption: {
    fontSize: 12,
    lineHeight: 20,
  },
  base: {
    fontSize: 16,
    lineHeight: 24,
  },
  input: {
    fontSize: 16,
  },
  submenu: {
    fontSize: 20,
    lineHeight: 30,
  },
  menu: {
    fontSize: 20,
    lineHeight: 30,
  },
  // weight
  bold: {
    fontWeight: '700' as const,
  },
};
