import {CouponUsage, DiscountType, SaaSCoupon} from '../../API';
import {getImages} from '../../service';
import {SaaSImageContents} from '../../_proto/services/SaaSRepository';
import {FormData} from '../Coupon/schema';

// 各画面で使えるようにクーポン作成画面のデータorDBからのデータどちらもOKにする
export type CouponInfoInput =
  | SaaSCoupon
  | {data: FormData; images: SaaSImageContents[]; barcode: string};

export type Discount = {
  type: DiscountType;
  param: number[];
  freeText: string;
};

export type CvLink = {
  url: string;
  text: string;
};

export type CouponInfo = {
  name: string; // 商品名
  rule?: string; // 利用条件
  text?: string; // 詳しい説明
  usage: CouponUsage;
  discount: Discount;
  images: SaaSImageContents[];
  cvLink?: CvLink;
  cvPhone?: string;
  barcode?: string;
};

// 以下、型変換処理
const couponInfoDefault: CouponInfo = {
  name: '（製品名）',
  usage: CouponUsage.ONETIME,
  discount: {
    type: DiscountType.BY_HALF,
    param: [],
    freeText: '（フリーテキスト）',
  },
  images: [],
};

function getDiscount(data: FormData): Discount {
  const type = data.discountType;
  switch (type) {
    case DiscountType.BY_PERCENT:
      return {
        type,
        param: [data[DiscountType.BY_PERCENT] ?? 0],
        freeText: '',
      };
    case DiscountType.BY_PRICE:
      return {
        type,
        param: [data[DiscountType.BY_PRICE] ?? 0],
        freeText: '',
      };
    case DiscountType.FROM_PRICE:
      return {
        type,
        param: [data.priceFrom ?? 0, data.priceTo ?? 0],
        freeText: '',
      };
    case DiscountType.BY_TEXT:
      return {type, param: [], freeText: data.freeText ?? ''};
    case DiscountType.BY_FREE:
    case DiscountType.BY_HALF:
    default:
      return {...couponInfoDefault.discount, type};
  }
}

function getParam(param?: Array<number | null> | null): number[] {
  if (param && typeof param[0] === 'number') {
    return param as number[];
  }
  return couponInfoDefault.discount.param;
}

export async function convert(info?: CouponInfoInput): Promise<CouponInfo> {
  if (!info) {
    return couponInfoDefault;
  }
  if ('data' in info) {
    return {
      name: info.data.name ?? couponInfoDefault.name,
      rule: info.data.rule,
      text: info.data.text,
      usage: info.data.usage ?? couponInfoDefault.usage,
      discount: getDiscount(info.data),
      images: info.images,
      cvLink: {
        url: info.data.cvLinkUrl ?? '',
        text: info.data.cvLinkText ?? 'Webサイトを見る',
      },
      cvPhone: info.data.cvPhone,
      barcode: info.barcode ?? undefined,
    };
  } else {
    return {
      name: info.name ?? couponInfoDefault.name,
      rule: info.rule ?? undefined,
      text: info.text ?? undefined,
      usage: info.usage ?? couponInfoDefault.usage,
      discount: {
        type: info.discount?.type ?? couponInfoDefault.discount.type,
        param: getParam(info.discount?.param),
        freeText:
          info.discount?.freeText ?? couponInfoDefault.discount.freeText,
      },
      images: await getImages(info.imageUrls),
      cvLink: {
        url: info.cvLink?.url ?? '',
        text: info.cvLink?.text ?? 'Webサイトを見る',
      },
      cvPhone: info.cvPhone ?? undefined,
      barcode:
        (await getImages([info.barcodeUrl ?? null]))[0]?.uri ?? undefined,
    };
  }
}
