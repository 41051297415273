import React from 'react';
import {StyleSheet} from 'react-native';
import {Portal} from 'react-native-paper';
import {createContainer} from 'unstated-next';
import {Colors} from '../../../theme';
import {DialogBase, Text, TrimaButton, VMargin} from '../../Elements';

function useLimitCautionInner(): {
  showLimitCaution: () => void;
  hideLimitCaution: () => void;
  isShow: boolean;
} {
  const [isShow, setShow] = React.useState<boolean>(false);
  const showLimitCaution = React.useCallback<() => void>(
    () => setShow(true),
    [],
  );
  const hideLimitCaution = React.useCallback<() => void>(
    () => setShow(false),
    [],
  );
  return {
    isShow,
    showLimitCaution,
    hideLimitCaution,
  };
}

const LimitCationContainer = createContainer(useLimitCautionInner);

const LimitCautionDialog: React.FC = () => {
  const {isShow, hideLimitCaution} = LimitCationContainer.useContainer();
  return (
    <DialogBase
      visible={isShow}
      onDismiss={hideLimitCaution}
      dismissable={false}>
      <VMargin />
      <Text style={styles.accent}>指定したエリアが広すぎます</Text>
      <VMargin />
      <Text>
        居住地・勤務地/滞在履歴で選択した四角の数と、利用駅で選択した駅の数の
        <Text style={styles.accent}>合計</Text>
        は、
        <Text style={styles.accent}>1つの配信先に3000個まで</Text>
        になります。
      </Text>
      <Text>設定した項目を確認してください。</Text>
      <VMargin />
      <TrimaButton variant="outlined" onClick={hideLimitCaution}>
        戻る
      </TrimaButton>
    </DialogBase>
  );
};

// 上限超過ダイアログ提供ラッパー。ダイアログ表示範囲にしたいところを囲む
export const LimitCautionProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  return (
    <LimitCationContainer.Provider>
      <Portal.Host>
        {children}
        <LimitCautionDialog />
      </Portal.Host>
    </LimitCationContainer.Provider>
  );
};

// 上限超過ダイアログの表示用フック。LimitCautionProvider配下のコンポーネントからのみ利用可能。
export function useLimitCaution(): {showLimitCaution: () => void} {
  const {showLimitCaution} = LimitCationContainer.useContainer();
  return {showLimitCaution};
}

const styles = StyleSheet.create({
  accent: {
    color: Colors.accent,
  },
});
