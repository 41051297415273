import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {SubmitErrorHandler, useFormContext} from 'react-hook-form';
import {StyleSheet, View} from 'react-native';
import {TextInput} from 'react-native-paper';
import {PasswordForgotContainer} from '../../components/Auth/PasswordForgotContainer';
import {
  EmailFormData,
  PasswordForgotFormData,
  schemaEmailFormData,
  schemaPasswordForgotFormData,
} from '../../components/Auth/schema';
import {
  Header,
  HelperText,
  MaxWidth,
  RegisterContainer,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
} from '../../components/Elements';
import {Colors} from '../../theme';

// Todo: 疎通＆エラーメッセージ
const EmailSubmit: React.FC = () => {
  const {step, error, forgotPassword} = PasswordForgotContainer.useContainer();
  const [showError, setShowError] = React.useState('');
  const {
    handleSubmit,
    formState: {errors},
  } = useFormContext<EmailFormData>();
  React.useEffect(() => {
    if (!errors.email) {
      setShowError('');
    }
  }, [errors.email]);

  React.useEffect(() => {
    if (error) {
      // Todo エラーメッセージ
      setShowError(error.message);
      // Todo リトライ
    }
  }, [error]);

  const isLoading = step === 'passwordForgot';
  const onError: SubmitErrorHandler<EmailFormData> = () =>
    setShowError('フォームの入力に誤りがあります');
  return (
    <View>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(forgotPassword, onError)}
        disabled={isLoading}
        loading={isLoading}
        loadingPosition="start">
        SMSで確認コードを送る
      </TrimaLoadingButton>
      <HelperText type="error">{showError}</HelperText>
    </View>
  );
};

const PasswordSubmit: React.FC = () => {
  const navigation = useNavigation();
  const {step, error, forgotPasswordSubmit} =
    PasswordForgotContainer.useContainer();
  const [showError, setShowError] = React.useState('');
  const {
    handleSubmit,
    formState: {errors},
  } = useFormContext<PasswordForgotFormData>();
  React.useEffect(() => {
    if (!errors.newPassword && !errors.verificationCode) {
      setShowError('');
    }
  }, [errors.newPassword, errors.verificationCode]);

  React.useEffect(() => {
    if (error) {
      // Todo エラーメッセージ
      setShowError(error.message);
      // Todo リトライ
    }
  }, [error]);

  const isLoading = step === 'passwordForgotSubmit';
  const onError: SubmitErrorHandler<PasswordForgotFormData> = () =>
    setShowError('フォームの入力に誤りがあります');
  return (
    <View>
      <TrimaLoadingButton
        variant="contained"
        onClick={handleSubmit(
          (data) => forgotPasswordSubmit(data, navigation),
          onError,
        )}
        disabled={isLoading}
        loading={isLoading}
        loadingIndicator={'変更中...'}>
        パスワードを変更
      </TrimaLoadingButton>
      <HelperText type="error">{showError}</HelperText>
    </View>
  );
};

const PasswordForgotForm: React.FC = () => {
  return (
    <View>
      <MaxWidth maxWidth={586}>
        <VForm.Provider<EmailFormData> schema={schemaEmailFormData}>
          <VForm.Text<EmailFormData> name="email" label="メールアドレス" />
          <EmailSubmit />
        </VForm.Provider>
      </MaxWidth>
    </View>
  );
};

const PASSWORD_RULE = `・英文字 ( 大文字・小文字 )、数字を必ず含む8文字以上で作成してください。
・アルファベットの大文字と小文字は区別されます。`;

const MFAForm: React.FC = () => {
  const {phoneNumber, resetStep} = PasswordForgotContainer.useContainer();
  const [secureTextEntry, setSecureTextEntry] = React.useState<boolean>(true);

  return (
    <View>
      <MaxWidth maxWidth={586}>
        <Text>
          SMS(***-****-{phoneNumber.slice(-4)}
          )に記載されている確認コードと新しいパスワードを入力してください。
        </Text>
        <VMargin />
        <Text style={styles.accentText}>
          確認コードの有効期限は発行から1時間です。
        </Text>
        <VMargin />
        <VForm.Provider<PasswordForgotFormData>
          schema={schemaPasswordForgotFormData}>
          <VForm.Text<PasswordForgotFormData>
            name="newPassword"
            label="新しいパスワード"
            secureTextEntry={secureTextEntry}
            required
            autoComplete="off"
            autoCorrect={false}
            right={
              <TextInput.Icon
                size={24}
                style={styles.eye}
                name={secureTextEntry ? 'eye' : 'eye-off'}
                onPress={() => {
                  setSecureTextEntry(!secureTextEntry);
                }}
              />
            }
          />
          <VMargin />
          <VForm.Text<PasswordForgotFormData>
            name="verificationCode"
            label="確認コード"
            required
            autoComplete="off"
            autoCorrect={false}
          />
          <VMargin />
          <Text>{PASSWORD_RULE}</Text>
          <VMargin />
          <PasswordSubmit />
          <VMargin />
          <TrimaButton variant="outlined" onClick={() => resetStep()}>
            メールアドレスの入力に戻る
          </TrimaButton>
        </VForm.Provider>
      </MaxWidth>
    </View>
  );
};

const AccountSelector: React.FC = () => {
  const navigation = useNavigation();
  const {step, resetStep} = PasswordForgotContainer.useContainer();
  switch (step) {
    case 'init':
    case 'passwordForgot':
      return <PasswordForgotForm />;
    case 'mfa':
    case 'passwordForgotSubmit':
      return <MFAForm />;
    case 'done':
      resetStep();
      return (
        <View>
          <MaxWidth maxWidth={586}>
            <Text>パスワードの変更が完了しました</Text>
            <VMargin />
            <TrimaButton
              variant="outlined"
              onClick={() => navigation.navigate('SignIn')}>
              ログイン画面へ
            </TrimaButton>
          </MaxWidth>
        </View>
      );
    default:
      return null; // ここには来ないはず
  }
};

export const PasswordForgot: React.FC = React.memo(() => {
  return (
    <PasswordForgotContainer.Provider>
      <Header />
      <RegisterContainer label="パスワードの再設定">
        <AccountSelector />
      </RegisterContainer>
    </PasswordForgotContainer.Provider>
  );
});

const styles = StyleSheet.create({
  eye: {
    position: 'relative',
    top: 4,
  },
  accentText: {
    color: Colors.accent,
  },
});
