import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {AttrForm, WEEK_ARRAY} from '../../schema';
import {StayFilter, StayUser} from '../../types';

// 常にNGとなるフィルター
const NOT_PASSED: StayFilter = () => false;

/**
 * 滞在時間判定用フィルターを返す
 * @param areaStay フォームの値
 * @return 判定不要（全期間）の場合は undefined が返る
 */
function makeStayFilter(
  areaStay: AttrForm['areaStay'],
): StayFilter | undefined {
  if (
    !areaStay ||
    areaStay.hourTo === undefined ||
    areaStay.hourFrom === undefined
  ) {
    return NOT_PASSED; // 条件不正
  }
  const hourFrom = Number(areaStay.hourFrom);
  const hourTo = Number(areaStay.hourTo);
  if (hourFrom >= hourTo) {
    return NOT_PASSED;
  }
  // 有効な週/時間 を 0 から (7 * 24 - 1) までの数値で表現
  // 有効な曜日/時間帯が登録されたSetを用意し、判定を行う。
  const hoursArr = [...Array(hourTo - hourFrom)].map((_v, i) => i + hourFrom);
  const stayTimeTable = new Set<number>();
  WEEK_ARRAY.forEach((value, index) => {
    if (areaStay[value]) {
      hoursArr
        .map((v) => 24 * index + v)
        .forEach((v) => {
          stayTimeTable.add(v);
        });
    }
  });
  if (stayTimeTable.size === 0) {
    return NOT_PASSED; // 曜日なし
  }
  if (stayTimeTable.size === 7 * 24) {
    return undefined; // 全期間
  }
  return (user) => user.stay.some((v) => stayTimeTable.has(v));
}

/**
 * 滞在フィルタ利用フック。
 * フィルターが更新される時のみ返却オブジェエクトが更新される。
 */
export function useStayFilter(): {stayFilter?: StayFilter} {
  const {watch, getValues} = useFormContext<AttrForm>();
  const [stayFilter, setFilter] = useState<{
    filter?: StayFilter;
  }>({
    filter: makeStayFilter(getValues('areaStay')),
  });
  useEffect(() => {
    const subscription = watch((value, {name, type}) => {
      console.log(value, name, type);
      const filter = makeStayFilter(value.areaStay);
      setFilter({filter});
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  return {stayFilter: stayFilter.filter};
}

// 滞在履歴フィルターの判定とマーク
export function checkStay(user: StayUser, filter?: StayFilter): void {
  if (filter && !filter(user)) {
    user.areaNG = true;
  } else {
    delete user.areaNG;
  }
}
