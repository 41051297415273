import React from 'react';
import {View} from 'react-native';
import {SaaSShopRepository} from '../../_proto/services/SaaSShopRepository';
import {OwnerContainer, ShopContainer, ShopsContainer} from '../../container';
import {ExcludeVisitorsContainer} from '../../screens/Shops/ExcludeVisitors/ExcludeVisitorsContainer';
import {axiosHelper} from '../../service';
import {Message} from '../../theme';
import {
  DialogBase,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VMargin,
} from '../Elements';

export const ShopDeleteDialog: React.FC<{
  visible: boolean;
  onDismiss?(removed: boolean): void;
  onError?(): void;
  name?: string | null;
  id: string;
}> = ({visible, onDismiss, onError, name, id}) => {
  const [busy, setBusy] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string | undefined>();
  const {updateShops} = ShopContainer.useContainer();
  const {fetchShops} = ShopsContainer.useContainer();
  const {owner} = OwnerContainer.useContainer();
  const {deleteAllData} = ExcludeVisitorsContainer.useContainer();
  const removed = () => onDismiss && onDismiss(true);
  const cancel = () => onDismiss && onDismiss(false);
  const errored = () => onError && onError();
  const remove = async () => {
    setBusy(true);
    try {
      await deleteAllData(id);
      await SaaSShopRepository.remove({id});
      await updateShops();
      await fetchShops(owner?.id as string);
      removed();
    } catch (err: any) {
      setMessage(axiosHelper.commonErrorHandler(err));
    } finally {
      setBusy(false);
    }
  };
  const render = () => {
    // エラー時
    if (message) {
      return (
        <View>
          <Text>店舗の削除に失敗しました。</Text>
          <Text>{message}</Text>
          <VMargin />
          <TrimaButton variant="outlined" onClick={errored}>
            {Message.BackToList}
          </TrimaButton>
        </View>
      );
    }
    return (
      <View>
        <Text>{`[${name}] を削除しますか？`}</Text>
        <VMargin />
        <TrimaLoadingButton
          variant="contained"
          onClick={remove}
          disabled={busy}
          loading={busy}
          loadingPosition="start">
          削除
        </TrimaLoadingButton>
        <VMargin />
        <TrimaButton variant="outlined" onClick={cancel} disabled={busy}>
          キャンセル
        </TrimaButton>
      </View>
    );
  };

  return (
    <DialogBase
      visible={visible}
      onDismiss={cancel}
      title="店舗の削除"
      dismissable={false}>
      {render()}
    </DialogBase>
  );
};
