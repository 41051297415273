import dayjs from 'dayjs';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {Helpers} from '../../helper';
import {Colors, CommonStyles} from '../../theme';
import {Text, VMargin} from '../Elements';
import {getUsedMoney} from './schema';

// Todo Creates/Detail と同じなので共通化したいところ
export const CreativePreview: React.FC<{
  creative: {
    startAt: string;
    endAt: string;
    budget: number;
    balance: number;
  };
}> = ({creative}) => {
  const startAt = creative.startAt
    ? dayjs(creative.startAt).utcOffset(9).format('YYYY/MM/DD HH:mm')
    : undefined;
  const endAt = creative.endAt
    ? dayjs(creative.endAt).utcOffset(9).format('YYYY/MM/DD HH:mm')
    : undefined;
  const budget =
    creative.budget === undefined || creative.budget === null
      ? undefined
      : creative.budget === 0
      ? '上限なし'
      : `${Helpers.sepComma(creative.budget)}円まで`;
  const usedMoney = Helpers.sepComma(getUsedMoney(creative));

  return (
    <View>
      <Text>配信期間（広告有効期間）</Text>
      <VMargin />
      <Text>開始日時</Text>
      <Text>{startAt}</Text>
      <VMargin />
      <Text>終了日時</Text>
      <Text>{endAt}</Text>
      <VMargin />
      <Text>予算上限</Text>
      <Text>{budget}</Text>
      <VMargin />
      <View style={styles.used}>
        <Text>この配信の使用金額：</Text>
        <Text style={styles.count}>{usedMoney}</Text>
        <Text>円</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  used: {
    backgroundColor: Colors.base,
    ...CommonStyles.padding.all,
    ...CommonStyles.flex.row,
    ...CommonStyles.flex.center,
  },
  count: {
    width: 120,
    textAlign: 'center',
  },
});
