import API, {graphqlOperation, GraphQLResult} from '@aws-amplify/api';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {GetSaaSUserInfoQuery, UpdateSaaSUserInfoMutation} from '../API';
import {updateSaaSUserInfo} from '../graphql/mutations';
import {getSaaSUserInfo} from '../graphql/queries';

type SaaSUserInfoQuery = GetSaaSUserInfoQuery['getSaaSUserInfo'];

const useUserInfoContainer = () => {
  const [isCreateLoading, setCreateLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<SaaSUserInfoQuery>();
  const [error, setError] = useState<Error | undefined>();

  const fetchUserInfo = useCallback(async (id: string) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = (await API.graphql(
        graphqlOperation(getSaaSUserInfo, {id}),
      )) as GraphQLResult<GetSaaSUserInfoQuery>;
      if (!result.data) {
        throw new Error('[getSaaSUserInfo] result.data is undefined');
      }
      setUserInfo(result.data.getSaaSUserInfo);
      return result.data.getSaaSUserInfo;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  const update = useCallback(async (input: any) => {
    setCreateLoading(true);
    setError(undefined);
    try {
      const result = (await API.graphql(
        graphqlOperation(updateSaaSUserInfo, {
          input,
        }),
      )) as GraphQLResult<UpdateSaaSUserInfoMutation>;
      console.log(result);
      if (!result.data) {
        throw new Error('[updateSaaSUserInfo] result.data is undefined');
      }
      setUserInfo(result.data.updateSaaSUserInfo);
      return result.data.updateSaaSUserInfo;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setCreateLoading(false);
    }
  }, []);

  return {
    isCreateLoading,
    error,
    userInfo,
    fetchUserInfo,
    update,
    agentCode: userInfo?.agentCode ?? undefined,
  };
};

export const UserInfoContainer = createContainer(useUserInfoContainer);
