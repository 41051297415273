import {Mail} from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import {SubmitErrorHandler, useFormContext} from 'react-hook-form';
import {View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {AccessManageRole} from '../../../API';
import {BRAKE_POINT} from '../../../components';
import {AccessManageTable} from '../../../components/AccessManage/AccessManageTable';
import {
  BackLink,
  Container,
  FormBlock,
  HelperText,
  MaxWidth,
  Menu,
  Text,
  TrimaButton,
  TrimaLoadingButton,
  VForm,
  VMargin,
  WithHint,
  yup,
  yupUtil,
} from '../../../components/Elements';
import {AccessRoleContainer, OwnerContainer} from '../../../container';
import {useCustomNav} from '../../../helper';
import {Colors, CommonStyles} from '../../../theme';
import {AccessManageContainer} from '../AccessManageContainer';
import {Edit} from './Edit';

export type InviteUserFormData = {
  email?: string;
};

export const schemaInviteUserFormData: yup.SchemaOf<InviteUserFormData> = yup
  .object()
  .shape({
    email: yupUtil.email(),
  });

const AccessManageComponent = () => {
  const {owner} = OwnerContainer.useContainer();
  const {accounts, getData} = AccessManageContainer.useContainer();
  const [error, setError] = React.useState<Error | undefined>();

  React.useEffect(() => {
    async function fetchData() {
      if (owner?.id) {
        try {
          await getData(owner.id);
        } catch (err: any) {
          setError(err);
        }
      } else {
        setError(Error('対象IDが指定されていません'));
      }
    }
    fetchData();
  }, [owner, getData]);
  return (
    <FormBlock>
      <MaxWidth maxWidth={586}>
        <View>
          <Box fontWeight="fontWeightBold">所属ユーザー一覧</Box>
          {error ? (
            <Text>{error.message}</Text>
          ) : accounts.length === 0 ? (
            <ActivityIndicator size={60} />
          ) : (
            <View>
              <VMargin />
              <AccessManageTable
                accounts={accounts.filter((account: any) => account.id)}
                edit
              />
            </View>
          )}
        </View>
      </MaxWidth>
    </FormBlock>
  );
};

const ResponsiveDialog: React.FC<{
  open: boolean;
  onClose(): void;
}> = ({open, onClose}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog
      sx={{
        '& .MuiPaper-root': {
          backgroundColor: Colors.lightgray,
        },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        {'招待メールを送信しました'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            招待メールを受け取られた方には、24時間以内にメールに記載されたURLをクリックして、トリマビジネスアカウントを作成するよう、お伝えください。
          </p>
          <Box textAlign="center" m={1}>
            <Mail sx={{fontSize: 256}} />
          </Box>
          <TrimaButton variant="outlined" onClick={handleClose}>
            閉じる
          </TrimaButton>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const Submit: React.FC = () => {
  const {owner} = OwnerContainer.useContainer();
  const {create, isCreateLoading, error} = AccessManageContainer.useContainer();
  const [showError, setShowError] = React.useState<string | null>();
  const [open, setOpen] = React.useState(false);

  const {
    handleSubmit,
    formState: {errors},
    reset,
  } = useFormContext<InviteUserFormData>();
  React.useEffect(() => {
    if (!errors.email) {
      setShowError(null);
    }
  }, [errors.email]);

  const onSubmit = async (data: InviteUserFormData) => {
    setShowError(null);
    if (!owner?.id) {
      return;
    }
    await create({...data, ownerId: owner.id});
    reset({
      email: '',
    });
    setOpen(true);
  };
  const onError: SubmitErrorHandler<InviteUserFormData> = () => {
    setShowError('フォームの入力に誤りがあります');
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <View>
      <TrimaLoadingButton
        variant="outlined"
        onClick={handleSubmit(onSubmit, onError)}
        disabled={isCreateLoading}
        loading={isCreateLoading}
        loadingPosition="start">
        招待メールを送信する
      </TrimaLoadingButton>
      <HelperText type="error">{showError || error?.message}</HelperText>
      <ResponsiveDialog open={open} onClose={handleClose} />
    </View>
  );
};

export const Main: React.FC = React.memo(() => {
  const navigation = useCustomNav();
  const cancel = () => {
    navigation.goBack('SettingsMain');
  };
  const {accessRole} = AccessRoleContainer.useContainer();

  return (
    <AccessManageContainer.Provider>
      <Container>
        <MaxWidth
          maxWidth={BRAKE_POINT.desktop}
          style={CommonStyles.padding.all}>
          <Box mb={2} sx={{width: 'inherit'}}>
            <BackLink label="変更せずに戻る" onClick={cancel} />
          </Box>
          <WithHint id="companyUsers">
            <Menu>所属ユーザーの管理</Menu>
          </WithHint>
          <VMargin />
          {accessRole !== AccessManageRole.OWNER ? (
            <Typography>アクセス権限がありません</Typography>
          ) : (
            <View>
              <FormBlock>
                <MaxWidth maxWidth={586}>
                  <Box fontWeight="fontWeightBold">一般ユーザーを招待</Box>
                  <VMargin />
                  <Typography>一般ユーザーにできること</Typography>
                  <Typography>・広告/配信先の作成</Typography>
                  <Typography>・配信の開始、終了</Typography>
                  <Typography>・配信結果や分析内容の閲覧</Typography>
                  <VMargin />
                  <Typography>オーナーユーザーにできること</Typography>
                  <Typography>・一般ユーザーができること全て</Typography>
                  <Typography>・企業情報の変更</Typography>
                  <Typography>・支払方法の変更</Typography>
                  <VMargin />
                  <VMargin />
                  <Typography>
                    <Typography
                      component="span"
                      variant="inherit"
                      color="secondary">
                      追加したい方のメールアドレス
                    </Typography>
                    を入力して、招待メールを送信してください。
                  </Typography>
                  <VMargin />
                  <VForm.Provider<InviteUserFormData>
                    schema={schemaInviteUserFormData}>
                    <VForm.Text<InviteUserFormData>
                      name="email"
                      label="メールアドレス"
                    />
                    <Text>
                      招待メールに記載されている登録URLの有効期限は24時間です。招待したい方へご連絡の上、送信をお願いいたします。
                    </Text>
                    <Text>
                      迷惑メールフィルターが設定されている場合、@shop.trip-mile.comからの受信設定をお願いいたします。
                    </Text>
                    <VMargin />
                    <Submit />
                  </VForm.Provider>
                </MaxWidth>
              </FormBlock>
              <VMargin />
              <AccessManageComponent />
            </View>
          )}
        </MaxWidth>
      </Container>
    </AccessManageContainer.Provider>
  );
});

export const AccessManage = {
  Main,
  Edit,
};
