import {ExpandMore} from '@mui/icons-material';
import {
  Avatar,
  Box,
  ButtonBase,
  ClickAwayListener,
  Grow,
  ListItemAvatar,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import {DrawerNavigationHelpers} from '@react-navigation/drawer/lib/typescript/src/types';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import defaultIcon from '../assets/icon_shop.png';
import {ShopContainer, ShopGroupsContainer, ShopsContainer} from '../container';
import {Colors} from '../theme';

const HEIGHT = 52;
// const AVATAR_SIZE = 38;

export const ShopSelector: React.FC<{
  navigation: DrawerNavigationHelpers;
}> = () => {
  const {shopList} = ShopsContainer.useContainer();
  const {selectedShopGroup} = ShopGroupsContainer.useContainer();
  const {selected, setSelectedShop} = ShopContainer.useContainer();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [word, setWord] = React.useState<string>('');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <View style={styles.shopSelect}>
      <ButtonBase
        sx={{
          height: '100%',
          width: '100%',
          paddingLeft: 1,
        }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Avatar src={selected?.icon} />
        <Typography
          sx={{
            width: '100%',
            color: Colors.white,
            paddingLeft: 1,
            textAlign: 'left',
            fontSize: 14,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'inline-block',
          }}
          component="span"
          variant="h6"
        >
          {selected?.name}
        </Typography>
        <ExpandMore sx={{color: Colors.white, paddingRight: 2, fontSize: 36}} />
      </ButtonBase>
      <Popper
        style={{
          width: '100%',
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              width: '100%',
            }}
          >
            <Paper
              style={{
                maxHeight: 'calc(100vh - 56px - 53px - 53px - 52px)',
                width: '100%',
                overflow: 'auto',
                backgroundColor: Colors.headerShadow,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <Box pt={1} px={1}>
                    <TextField
                      sx={{
                        '& .MuiInputBase-root': {
                          backgroundColor: '#fcfcfb',
                          borderRadius: 1,
                          height: 48,
                        },
                      }}
                      id="outlined-basic"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      placeholder="絞り込み"
                      onChange={(event) => {
                        setWord(event.target.value);
                      }}
                    />
                  </Box>

                  <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {shopList
                      .filter((shop) => {
                        if (selectedShopGroup) {
                          return shop.groupId === selectedShopGroup?.id;
                        } else if (selected && selected.groupId) {
                          return shop.groupId === selected.groupId;
                        } else {
                          return shop.groupId === null;
                        }
                      })
                      .filter((shop) =>
                        word ? shop.name.includes(word) : true,
                      )
                      .map((shop) => {
                        return (
                          <MenuItem
                            sx={{
                              paddingLeft: 1,
                              paddingRight: 1,
                              '&.Mui-selected': {
                                backgroundColor: '#7E57C2',
                                '&:hover': {
                                  backgroundColor: '#4C3575',
                                },
                              },
                              '&:hover': {
                                backgroundColor: '#4C3575',
                              },
                            }}
                            selected={shop.id === selected?.id}
                            onClick={() => {
                              handleClose;
                              setSelectedShop(shop);
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                src={shop.icon ? shop.icon : defaultIcon}
                              />
                            </ListItemAvatar>
                            <Typography
                              variant="inherit"
                              style={{
                                color: Colors.white,
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                display: 'inline-block',
                              }}
                            >
                              {shop.name}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </View>
  );
};

const styles = StyleSheet.create({
  shopSelect: {
    height: HEIGHT,
    backgroundColor: Colors.headerShadow,
    zIndex: 1000,
  },
});
