import {
  BaseUsersBy,
  MeshList,
  StationCode,
  StationsByLine,
  TargetingUserCount,
} from './types';
import {AppConfig} from '../../_proto/config/AppConfig';
import {getTrainLineCodeFromStationCode} from './area/station/stationFunc';

/**
 * メッシュが空かどうか
 * @param meshList 検査対象
 */
export function isEmptyMeshList(meshList: MeshList): boolean {
  return Object.keys(meshList).length === 0;
}

/**
 * 条件を満たさないユーザー数かどうか（数値以外の場合もNG扱い）
 * @param count 検査対象のユーザーカウント
 */
export function isUnderLimit(count: TargetingUserCount): boolean {
  if (typeof count === 'string') {
    return true;
  }
  return count < AppConfig.MinTargetCount;
}

/**
 * 各エリア情報から対象ユーザー数をカウントする
 * @param usersBy 各エリアor駅単位のユーザーリスト
 * @param init 計算済み（string配列化）したユーザーがある場合は渡す
 */
export function calcUserCount(
  usersBy: BaseUsersBy[],
  init: string[] = [],
): TargetingUserCount {
  const sum = new Set<string>(init);
  usersBy.forEach((unit) => {
    Object.values(unit).forEach((users) => {
      for (let i = 0; i < users.length; i++) {
        // フィルターを通過しているユーザーを登録
        const user = users[i];
        if (!user.attrNG && !user.areaNG) {
          sum.add(user.id);
        }
      }
    });
  });
  return sum.size;
}

/**
 * 各エリア情報から対象ユーザーリストを返却する
 * @param usersBy 各エリアor駅単位のユーザーリスト
 */
export function calcUserList(usersBy: BaseUsersBy[]): string[] {
  const sum = new Set<string>();
  usersBy.forEach((unit) => {
    Object.values(unit).forEach((users) => {
      for (let i = 0; i < users.length; i++) {
        // フィルターを通過しているユーザーを登録
        const user = users[i];
        if (!user.attrNG && !user.areaNG) {
          sum.add(user.id);
        }
      }
    });
  });
  return Array.from(sum);
}

/**
 * 駅リストからプレビュー表示用の路線/駅リストへ変換
 * @param stations 駅リスト（旧コード）
 */
export function makeStationsByLineOnlyStationCode(
  stations: StationCode[],
): StationsByLine {
  stations.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  const ret: StationsByLine = {};
  stations.forEach((code) => {
    const line = getTrainLineCodeFromStationCode(code);
    if (line) {
      if (ret[line]) {
        ret[line].push(code);
      } else {
        ret[line] = [code];
      }
    }
  });
  return ret;
}

// メッシュリストからメッシュ数算出
export function getCountByMesh(meshList: MeshList): number {
  return Object.keys(meshList).length;
}

// 路線別駅情報から駅数算出
export function getCountBySbyL(stationsByLine: StationsByLine): number {
  let c = 0;
  Object.keys(stationsByLine).forEach((line) => {
    c += stationsByLine[line].length;
  });
  return c;
}
