import React from 'react';
// import {StyleSheet, View} from 'react-native';
import {Box} from '@mui/material';
import {useNavigation, useRoute} from '@react-navigation/native';
import {BackLink, Container, MaxWidth, Text} from '../../components/Elements';
import {DeliveryReportComponent} from '../../components/Report';
import {
  MainNavigationProp,
  MainRouteProp,
  MainScreenParams,
} from '../../navigation/MainScreen';
import {DeliveryType} from '../../service';
import {CommonStyles, Message} from '../../theme';

type NProp = MainNavigationProp<'ReportsDetail'>;
type RProp = MainRouteProp<'ReportsDetail'>;

function isInvalid(target: MainScreenParams['ReportsDetail']): boolean {
  return !target.id || !(target.type === 'COUPON' || target.type === 'NEWS');
}

export const Detail: React.FC = React.memo(() => {
  const route = useRoute<RProp>();
  const [target] = React.useState<MainScreenParams['ReportsDetail']>(
    route.params,
  );
  const navigation = useNavigation<NProp>();

  if (isInvalid(target)) {
    return <Text>対象の指定に誤りがあります</Text>;
  }

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('ReportsMain');
    }
  };

  return (
    <Container>
      <MaxWidth maxWidth={1448} style={CommonStyles.padding.all}>
        <Box mb={2} sx={{width: 'inherit'}}>
          <BackLink label={Message.BackToList} onClick={goBack} />
        </Box>
        <DeliveryReportComponent
          id={target.id as string}
          type={target.type as DeliveryType}
        />
      </MaxWidth>
    </Container>
  );
});
