import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography} from '@mui/material';
import {useNavigation} from '@react-navigation/native';
import dayjs from 'dayjs';
import React from 'react';
import {FlatList, View} from 'react-native';
import {ActivityIndicator} from 'react-native-paper';
import {SaaSDeliveryHistory, SaaSNewsHistory} from '../../API';
import {SaaSDeliveryRepository} from '../../_proto/services/SaaSDeliveryRepository';
import {BRAKE_POINT} from '../../components';
import {
  Container,
  List,
  MaxWidth,
  Menu,
  MinText,
  Text,
  TrimaButton,
  VMargin,
  WithHint,
} from '../../components/Elements';
import {ShopContainer} from '../../container';
import {Helpers} from '../../helper';
import {MainNavigationProp} from '../../navigation/MainScreen';
import {
  DeliveryStatus,
  dumpDiscount,
  getDeliveryStatus,
  graphQLService,
} from '../../service';
import {Colors, CommonStyles} from '../../theme';
import {Create} from './Create';
import {Detail} from './Detail';
import {Edit} from './Edit';

type NProp = MainNavigationProp<'CreativesMain'>;

type ListItem = (SaaSDeliveryHistory | SaaSNewsHistory) & {
  type: 'coupon' | 'news';
  id: string;
  title: string;
  name: string;
  price?: string;
  term: string;
  delivery: Promise<string>; // 遅延表示するためPromiseで渡す
  isActive: boolean;
  status: DeliveryStatus;
};

const DELIVERY_INIT_MSG = '配信数取得中　';

const Item: React.FC<{item: ListItem}> = ({item}) => {
  const [delivery, setDelivery] = React.useState<string>(DELIVERY_INIT_MSG);
  const navigation = useNavigation<NProp>();
  const gotoDetail = () =>
    navigation.navigate('CreativesDetail', {type: item.type, id: item.id});
  React.useEffect(() => {
    item.delivery
      .then(setDelivery)
      .catch(() => setDelivery('配信数の取得に失敗しました'));
  }, [item.delivery]);
  return (
    <List.Item onPress={gotoDetail}>
      <View>
        <List.Title>{item.title}</List.Title>
        <View style={CommonStyles.margin.top} />
        <Typography variant="caption" color={Colors.darkgray}>
          {item.name}
        </Typography>
        <Typography variant="caption" color={Colors.darkgray}>
          {item.price}
        </Typography>
        <Typography variant="caption" color={Colors.darkgray}>
          {item.term}
        </Typography>
        <View style={CommonStyles.flex.row}>
          <MinText>{delivery}</MinText>
          {delivery === DELIVERY_INIT_MSG && <ActivityIndicator size={12} />}
        </View>
        {item.status === 'limited' && (
          <Typography variant="caption" color="error">
            予算上限に達しています
          </Typography>
        )}
      </View>
    </List.Item>
  );
};

const ListCommon: React.FC<{
  errorMsg: string;
  activeList?: ListItem[];
  inactiveList?: ListItem[];
}> = ({errorMsg, activeList, inactiveList}) => {
  const navigation = useNavigation();
  const gotoCreate = () => navigation.navigate('CreativesCreate');
  return (
    <View>
      {errorMsg ? (
        <Text>{errorMsg}</Text>
      ) : activeList === undefined ? (
        <ActivityIndicator size={60} />
      ) : (
        <View>
          <TrimaButton
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={gotoCreate}>
            配信を新規作成
          </TrimaButton>
          <VMargin />
          <Text>配信中/配信開始前</Text>
          <VMargin />
          <View>
            <FlatList
              data={activeList}
              keyExtractor={(item) => item.id}
              ItemSeparatorComponent={List.Separator}
              renderItem={(item) => <Item item={item.item} />}
            />
          </View>
          <VMargin />
          <Text>配信終了</Text>
          <VMargin />
          <View>
            <FlatList
              data={inactiveList}
              keyExtractor={(item) => item.id}
              ItemSeparatorComponent={List.Separator}
              renderItem={(item) => <Item item={item.item} />}
            />
          </View>
        </View>
      )}
    </View>
  );
};

// 配信数表示の取得。利用側で例外はcatchすること。
async function getDelivery(
  type: 'coupon' | 'news',
  status: DeliveryStatus,
  id?: string,
): Promise<string> {
  const deliveryNum = await SaaSDeliveryRepository.getViewCount(type, id);
  return deliveryNum === 0 && status === 'before'
    ? '配信開始前です'
    : `${Helpers.sepComma(deliveryNum)}人が広告閲覧済み`;
}

async function getEmptyDelivery(): Promise<string> {
  return '';
}

const DUMMY_TEXT = {
  title: '（不明なタイトル）',
  name: '（取得エラー）',
  price: '（取得エラー）',
};

function convertCoupon(history: SaaSDeliveryHistory): ListItem {
  const {balance, budget, endAt, startAt, couponState, id} = history;
  const start = dayjs(startAt);
  const end = dayjs(endAt);
  if (
    (!balance && balance !== 0) ||
    (!budget && budget !== 0) ||
    !endAt ||
    !startAt ||
    !start.isValid() ||
    !end.isValid()
  ) {
    return {
      ...history,
      status: 'disabled',
      delivery: getEmptyDelivery(),
      isActive: false,
      name: '',
      term: '',
      title: '',
      type: 'coupon',
    };
  }

  const state = couponState;
  const status = getDeliveryStatus({balance, budget, start, end, state});

  // 配信終了かどうかを判定
  const isActive =
    status === 'active' || status === 'before' || status === 'limited';

  const delivery = getDelivery('coupon', status, id);

  const title = history.title ?? DUMMY_TEXT.title;
  let name = DUMMY_TEXT.name;
  let price = DUMMY_TEXT.price;

  if (history.couponDef) {
    // コピーされて情報が入っているので、取り出して表示する
    const parsed = JSON.parse(history.couponDef);
    if (parsed.name) {
      name = parsed.name;
    }
    if (parsed.discount) {
      price = dumpDiscount(parsed.discount);
    }
  }
  const term = `${Helpers.toJapanDay(startAt)}〜${Helpers.toJapanDay(endAt)}`;

  return {
    ...history,
    status,
    isActive,
    title,
    name,
    price,
    delivery,
    term,
    type: 'coupon',
  };
}

function sortFunc(a: ListItem, b: ListItem): number {
  return dayjs(a.createdAt) > dayjs(b.createdAt)
    ? -1
    : dayjs(a.createdAt) < dayjs(b.createdAt)
    ? 1
    : 0;
}

const CouponList: React.FC = () => {
  const {selected} = ShopContainer.useContainer();
  const [errorMsg, setError] = React.useState<string>('');
  const [activeList, setActive] = React.useState<ListItem[] | undefined>();
  const [inactiveList, setInactive] = React.useState<ListItem[] | undefined>();

  React.useEffect(() => {
    async function fetchData() {
      if (!selected?.id) {
        setError('店舗情報取得エラー');
        return;
      }
      const actives: ListItem[] = [];
      const inactives: ListItem[] = [];
      try {
        const couponList = await graphQLService.getDeliveryHistoryList(
          selected.id,
        );
        couponList.map(convertCoupon).forEach((value) => {
          if (value.id) {
            if (value.isActive) {
              actives.push(value);
            } else {
              inactives.push(value);
            }
          }
        });
        const newsList = await graphQLService.getNewsHistoryList(selected.id);
        newsList.map(convertNews).forEach((value) => {
          if (value.id) {
            if (value.isActive) {
              actives.push(value);
            } else {
              inactives.push(value);
            }
          }
        });
        setActive(actives.sort(sortFunc));
        setInactive(inactives.sort(sortFunc));
      } catch (err: any) {
        setError(`広告情報の取得エラー ${err}`);
      }
    }
    fetchData();
  }, [selected?.id]);

  return (
    <ListCommon
      errorMsg={errorMsg}
      activeList={activeList}
      inactiveList={inactiveList}
    />
  );
};

function convertNews(history: SaaSNewsHistory): ListItem {
  const {balance, budget, endAt, startAt, newsState, id} = history;
  const start = dayjs(startAt);
  const end = dayjs(endAt);
  if (
    (!balance && balance !== 0) ||
    (!budget && budget !== 0) ||
    !endAt ||
    !startAt ||
    !start.isValid() ||
    !end.isValid()
  ) {
    return {
      ...history,
      status: 'disabled',
      delivery: getEmptyDelivery(),
      isActive: false,
      name: '',
      term: '',
      title: '',
      type: 'news',
    };
  }

  const state = newsState;
  const status = getDeliveryStatus({balance, budget, start, end, state});

  // 配信終了かどうかを判定
  const isActive =
    status === 'active' || status === 'before' || status === 'limited';

  const delivery = getDelivery('news', status, id);

  const title = history.title ?? DUMMY_TEXT.title;
  let name = DUMMY_TEXT.name;

  if (history.newsDef) {
    // コピーされて情報が入っているので、取り出して表示する
    const parsed = JSON.parse(history.newsDef);
    if (parsed.name) {
      name = parsed.name;
    }
  }
  const term = `${Helpers.toJapanDay(startAt)}〜${Helpers.toJapanDay(endAt)}`;

  return {
    ...history,
    status,
    isActive,
    title,
    name,
    delivery,
    term,
    type: 'news',
  };
}

const Main: React.FC = React.memo(() => {
  return (
    <Container>
      <MaxWidth maxWidth={BRAKE_POINT.desktop} style={CommonStyles.margin.all}>
        <WithHint id="creativesMain">
          <Menu>配信管理</Menu>
        </WithHint>
        <VMargin />
        <MaxWidth maxWidth={CommonStyles.maxWidth.list}>
          <VMargin />
          <CouponList />
        </MaxWidth>
      </MaxWidth>
    </Container>
  );
});

export const Creatives = {
  Main,
  Create,
  Detail,
  Edit,
};
