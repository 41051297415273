import {useState} from 'react';
import {createContainer} from 'unstated-next';

export type AccessManageFormData = {
  email?: string;
  ownerId: string;
};

const useCreativesContainer = () => {
  const [targetArray, setTargetArray] = useState<Array<Array<any>>>([]);

  return {
    targetArray,
    setTargetArray,
  };
};

export const CreativesGroupContainer = createContainer(useCreativesContainer);
