import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {ShopContainer} from '../../container';
import {UserAnalyzeReport} from '../../service/report';
import {DataArea, Overlay, Text} from '../Elements';

function percent(top: number | undefined, bottom: number | undefined) {
  if (!bottom || !top) {
    // 各値が0や数値じゃない場合は計算しない
    return 0;
  }
  const ratio = Math.round((10000 * top) / bottom) / 100;
  return ratio;
}

export const DeliveryReportDemensionGraph: React.FC<{
  reports: UserAnalyzeReport[];
  onChange: (value: any) => void;
  dataState: 'loading' | 'error' | 'exist' | 'nodata';
}> = ({reports, onChange, dataState}) => {
  const {selected} = ShopContainer.useContainer();
  const [category, setCategory] = React.useState<string>('age_and_gender');
  const isEmpty = !reports || reports.length === 0;
  const allValue = reports[0];
  reports = reports?.map(
    ({listCount, viewCount, clickedUrlCount, visited, ...rest}) => {
      const clickRate = percent(clickedUrlCount, viewCount);
      const viewRate = percent(viewCount, listCount);
      const visitedRate = percent(visited, viewCount);
      const list4AllRate = percent(listCount, allValue.listCount);
      const click4AllRate = percent(clickedUrlCount, allValue.clickedUrlCount);
      const view4AllRate = percent(viewCount, allValue.viewCount);
      const visited4AllRate = percent(visited, allValue.visited);
      return {
        listCount,
        viewCount,
        clickedUrlCount,
        visited,
        clickRate,
        viewRate,
        visitedRate,
        list4AllRate,
        click4AllRate,
        view4AllRate,
        visited4AllRate,
        ...rest,
      };
    },
  );
  reports.shift();
  const letfFormatter = (value: number) => `${value}%`;
  const rightFormatter = (value: number) => `${value}%`;
  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: {value},
    } = event;
    setCategory(value);
    onChange(value);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Select
          sx={{bgcolor: 'white'}}
          value={category}
          onChange={handleChange}
        >
          <MenuItem value={'age_and_gender'}>年代・性別</MenuItem>
          <MenuItem value={'marriage'}>既婚／未婚</MenuItem>
          <MenuItem value={'annual_income'}>年収</MenuItem>
          <MenuItem value={'household_income'}>世帯年収</MenuItem>
          <MenuItem value={'family'}>同居している家族</MenuItem>
          <MenuItem value={'children'}>同居している子供の人数</MenuItem>
          <MenuItem value={'residence'}>居住形態</MenuItem>
          <MenuItem value={'job'}>職業</MenuItem>
          <MenuItem value={'interest'}>興味関心</MenuItem>
          <MenuItem value={'transportation'}>主な移動手段</MenuItem>
        </Select>
      </Stack>
      <Box p={1} />
      <DataArea>
        <ResponsiveContainer width="100%" height={450}>
          <ComposedChart
            data={reports}
            margin={{
              top: 16,
              bottom: 8,
              left: -16,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="label"
              fontSize={10}
              interval={0}
              angle={30}
              textAnchor="start"
              height={100}
            />
            <YAxis yAxisId="left" fontSize={10} tickFormatter={letfFormatter} />
            <YAxis
              yAxisId="right"
              fontSize={10}
              orientation="right"
              tickFormatter={rightFormatter}
            />
            <Tooltip />
            <Legend />
            <Bar
              yAxisId="right"
              dataKey="list4AllRate"
              fill={'#5B5B5B'}
              name="一覧表示"
              unit="%"
            />
            <Bar
              yAxisId="right"
              dataKey="view4AllRate"
              fill={'#B094E2'}
              name="広告閲覧"
              unit="%"
            />
            <Bar
              yAxisId="right"
              dataKey="click4AllRate"
              fill={'#0076FF'}
              name="リンククリック"
              unit="%"
            />
            {!selected?.legalUrl && (
              <Bar
                yAxisId="right"
                dataKey="visited4AllRate"
                fill={'#FF6F00'}
                name="来店"
                unit="%"
              />
            )}
            <Line
              yAxisId="left"
              name="閲覧率"
              dataKey="viewRate"
              stroke={'#7E57C2'}
              activeDot={{r: 6}}
              strokeWidth={2}
              isAnimationActive={false}
              unit="%"
            />
            <Line
              yAxisId="left"
              name="クリック率"
              dataKey="clickRate"
              stroke={'#0076FF'}
              activeDot={{r: 6}}
              strokeWidth={2}
              isAnimationActive={false}
              unit="%"
            />
            {!selected?.legalUrl && (
              <Line
                yAxisId="left"
                name="来店率"
                dataKey="visitedRate"
                stroke={'#FF6F00'}
                activeDot={{r: 6}}
                strokeWidth={2}
                isAnimationActive={false}
                unit="%"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
        {isEmpty && (
          <Overlay>
            <Text>データがありません。</Text>
          </Overlay>
        )}
        {dataState === 'loading' && (
          <Overlay>
            <CircularProgress />
          </Overlay>
        )}
      </DataArea>
    </>
  );
};
